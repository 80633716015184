import { useContext, useEffect, useRef, useState } from "react";
import DButton from "../shared/DButton";
import {
  VpnKey,
  AddCircle,
  Person,
  Home,
  Assignment,
  CreateNewFolder,
  Folder,
  Phone,
  Portrait,
  Send,
} from "@material-ui/icons";
import DInput from "../shared/DInput";
import {
  getCPF,
  mascaraCEP,
  mascaraRG,
} from "../../helpers/formatacao";
import { RedefinirSenha } from "./components/modalRedefinir/useModal";
import { AppContext } from "../../context/AppContext";
import DAutoComplete from "../shared/DAutoComplete";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./styles.scss";

// Static JSON Data
import orgaoEmissorCode from "../../assets/dados/orgaoEmissor.json";
import conselhosCode from "../../assets/dados/conselhos.json";
import racasCode from "../../assets/dados/racas.json";
import nacionalidadesCode from "../../assets/dados/nacionalidades.json";
import tiposLogradouro from "../../assets/dados/logradouro.json";
import sexosCode from "../../assets/dados/sexo.json";
import generosCode from "../../assets/dados/genero.json";
import {
  getUserAvatar,
  getUserInfoById,
  updateUserAvatar,
  removeUserAvatar,
  updateUser,
} from "../../api/user";
import { listCityByStateId } from "../../api/dominios";
import DDropDownSimple from "../shared/DDropDownSimple/DDropDownSimple";
import {
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import DMultAutoComplete from "../shared/DMultAutoComplete";
import conselhosDeMedico from "../../assets/dados/conselhosDeMedico.json";
import { getCep2 } from "../../helpers/apis";
import Conselhos from "./components/Conselhos/Conselhos";
import useError from "../../hooks/useError";
import {
  requiredTwoName,
  requiredOptionalTwoName,
  checkProfissionalDocuments,
  required,
  validaRG,
  CNSValido,
  validaCPF,
  checarData,
  requiredPassaporte,
  validaEmail,
} from "../../helpers/validacoes";
import DDateInput from "../shared/DDateInput";
import RemoverTelefone from "./components/modalRemoverTelefone";
import { resendCode } from "../../api/auth";
import orientacoesSexuaisCode from "./../../assets/dados/orientacoesSexuais.json";
import Telefone from "./components/Telefone";
import { cnsUnique, cpfUnique } from "./../../helpers/apis";

const useStyles = makeStyles((theme) => ({
  addTagButton: {
    borderRadius: 25,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: "none"
  },
  addGrayButton: {
      borderRadius: 25,
      borderWidth: 2,
      color: `${theme.palette.neutral.midgrey} !important`,
      borderColor: `${theme.palette.neutral.midgrey} !important`,
      textTransform: "none"
  },
  buttonConfirm: {
    color: theme.palette.support.error,
    background: "transparent"
  }
}));

function MeuPerfil(props) {
  const {
    getUserDominios,
    emailLogin,
    setEmailLogin,
    avatar,
    setAvatar,
  } = useContext(AppContext);

  // Toasts

  const notify = {
    avatar: {
      deleted: () => toast.success("Avatar removido com sucesso!"),
      updated: () => toast.success("Avatar atualizado com sucesso!"),
      error: () => toast.error("Erro ao atualizar avatar!"),
      error2: () => toast.error("Erro ao remover avatar!"),
    },
    user: {
      updated: () => toast.success("Usuário atualizado com sucesso!"),
      error: () => toast.error("Erro ao atualizar usuário!"),
    },
  };

  const classes = useStyles();

  const [id, setId] = useState(null);
  const [telefones, setTelefones] = useState([]);
  const [removerTelefone, setRemoverTelefone] = useState(null);
  const [loadingUpdateUser, setLoadingUpdateUser] = useState(false);

  const [fileUpload, setFileUpload] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingDeleteAvatar, setLoadingDeleteAvatar] = useState(false);

  const InputFileRef = useRef(null);

  // User Info
  const [isMedico, setIsMedico] = useState(false);
  const [apresentacao, setApresentacao] = useState("");
  const [fullName, setFullName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [dataNascimentoValida, setDataNascimentoValida] = useState(true);
  const [socialName, setSocialName] = useState("");
  const [prevSocialName, setPrevSocialName] = useState("");
  const [email, setEmail] = useState("");
  const [nacionalidade, setNacionalidade] = useState(1);
  const [nacionalidadeOrigin, setNacionalidadeOrigin] = useState("");
  const [nomeDaMae, setNomeDaMae] = useState("");
  const [maeDesconhecida, setMaeDesconhecida] = useState(true);
  const [especialidades, setEspecialidades] = useState([]);

  // Demographic_data
  const [estadoNascimento, setEstadoNascimento] = useState("");
  const [cidadeNascimento, setCidadeNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [genero, setGenero] = useState("");
  const [raca, setRaca] = useState("");
  const [racaDescription, setRacaDescription] = useState("");
  const [etnia, setEtnia] = useState("");
  const [orientacaoSexual, setOrientacaoSexual] = useState("");

  // Documents
  const [cpf, setCpf] = useState("");
  const [cns, setCns] = useState("");
  let [rg, setRg] = useState("");
  let [passport, setPassport] = useState("");
  let [naturalized, setNaturalized] = useState("");

  const [conselhosDeClasse, setConselhosDeClasse] = useState([]);

  //Identidade
  const [identidadeEmissao, setIdentidadeEmissao] = useState("");
  const [identidadeNumero, setIdentidadeNumero] = useState("");
  const [identidadeOrgaoEmissor, setIdentidadeOrgaoEmissor] = useState("");
  const [identidadeUf, setIdentidadeUf] = useState("");
  const [identidadeEmissaoValida, setIdentidadeEmissaoValida] = useState(true);
  const [dataNaturalizacao, setDataNaturalizacao] = useState("");
  const [dataNaturalizacaoValida, setDataNaturalizacaoValida] = useState(true);
  const [paisNascimento, setPaisNascimento] = useState("");
  const [passaporteNumero, setPassaporteNumero] = useState("");
  const [passaportePaisEmissor, setPassaportePaisEmissor] = useState("");
  const [passaporteDataEmissao, setPassaporteDataEmissao] = useState("");
  const [passaporteDataEmissaoValida, setPassaporteDataEmissaoValida] =
    useState(true);
  const [passaporteDataValidade, setPassaporteDataValidade] = useState("");
  const [passaportePaisNascimento, setPassaportePaisNascimento] = useState("");
  const [passaporteDataValidadeValida, setPassaporteDataValidadeValida] =
    useState(true);
  const [birthCitys, setBirthCitys] = useState([]);

  // Address
  const [logradouro, setLogradouro] = useState("");
  const [municipioEndereco, setMunicipioEndereco] = useState("");
  const [address, setAddress] = useState([]);
  const [cep, setCep] = useState("");

  // DominiosDATA

  const [estados, setEstados] = useState([]);

  const [etnias, setEtnias] = useState([]);

  const [cidades, setCidades] = useState([]);

  const [paises, setPaises] = useState([]);

  const [conselhoCbos, setConselhoCbos] = useState([]);

  const [orgaoEmissorRg, setOrgaoEmissorRg] = useState([]);

  const [_especialidades, _setEspecialidades] = useState([]);

  const [conselhosProfile, setConselhosProfile] = useState([]);
  const [novosConselhos, setNovosConselhos] = useState([]);
  const [documentType, setDocumentType] = useState([]);

  const conselhosFormat = conselhosCode.map((_conselho) => {
    return {
      value: _conselho.name,
      id: _conselho.id,
    };
  });

  const orientacoesSexuaisMap = orientacoesSexuaisCode.map(function (item) {
    return { id: item.id, value: item.name };
  });

  const sexosFormat = sexosCode.map((sexo) => {
    return {
      value: sexo.name,
      id: sexo.name,
    };
  });

  const generosFormat = generosCode.map((genero) => {
    return {
      value: genero.name,
      id: genero.id,
    };
  });

  const orgaoEmissorFormat = orgaoEmissorCode.map((orgaoEmissor) => {
    return {
      value: orgaoEmissor.name,
      id: orgaoEmissor.name,
    };
  });

  const racasFormat = racasCode.map((raca) => {
    return {
      value: raca.name,
      id: raca.id,
    };
  });

  const validateDate = {
    data_validade_passaporte: () => {
      if (passaporteDataValidade) {
        try {
          if (passaporteDataValidade == null || passaporteDataValidade == "")
            throw "campo vazio";

          if (
            passaporteDataValidade === "00/00/0000" ||
            passaporteDataValidade === "99/99/9999"
          )
            throw "não é uma data";

          if (passaporteDataValidade.length < 10) throw "Data inválida";

          var today = new Date();
          today.setHours(0, 0, 0, 0);

          var [day, month, year] = passaporteDataValidade.split("/");

          if (new Date(`${month}/${day}/${year}`) == "Invalid Date")
            throw "Data inválida";

          if (day == "00" || month == "00" || year == "0000")
            throw "Data inválida";

          if (!checarData(day, month, year)) throw "Data inválida";

          var _passaporteDataValidade = new Date(`${year},${month},${day}`);
          _passaporteDataValidade.setHours(0, 0, 0, 0);

          var [bDay, bMonth, bYear] = dataNascimento.split("/");
          var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

          var [cDay, cMonth, cYear] = passaporteDataEmissao.split("/");
          var _passaporteDataEmissao = new Date(`${cYear}-${cMonth}-${cDay}`);

          if (_passaporteDataValidade < _passaporteDataEmissao) {
            throw "Data não pode ser menor que a data de emissão.";
          }

          if (_passaporteDataValidade < _dataNascimento) {
            throw "Data não pode ser menor que a data de nascimento.";
          }

          if (_passaporteDataValidade < today) {
            throw "Data não pode ser menor que a data atual.";
          }
        } catch (err) {
          if (err == "campo vazio") return "Campo obrigatório";

          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
    data_emissao_passaporte: () => {
      if (passaporteDataEmissao) {
        try {
          if (passaporteDataEmissao == null || passaporteDataEmissao == "")
            throw "campo vazio";

          if (
            passaporteDataEmissao === "00/00/0000" ||
            passaporteDataEmissao === "99/99/9999"
          )
            throw "não é uma data";

          if (passaporteDataEmissao.length < 10) throw "Data inválida";

          var [day, month, year] = passaporteDataEmissao.split("/");

          if (new Date(`${month}/${day}/${year}`) == "Invalid Date")
            throw "Data inválida";

          if (day == "00" || month == "00" || year == "0000")
            throw "Data inválida";

          if (!checarData(day, month, year)) throw "Data inválida";

          var _passaporteDataEmissao = new Date(`${year}-${month}-${day}`);

          var [bDay, bMonth, bYear] = dataNascimento.split("/");
          var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

          if (_passaporteDataEmissao < _dataNascimento) {
            throw "Data não pode ser menor que a data de nascimento.";
          }

          if (_passaporteDataEmissao > new Date()) {
            throw "Data não pode ser maior que a data atual.";
          }

          if (day && month && year) {
            return undefined;
          } else {
            throw "Data inválida.";
          }
        } catch (err) {
          if (err == "campo vazio") return "Campo obrigatório";

          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
    data_emissao: () => {
      if (identidadeEmissaoValida) {
        try {
          if (identidadeEmissao == null || identidadeEmissao == "")
            throw "campo vazio";

          if (
            identidadeEmissao === "00/00/0000" ||
            identidadeEmissao === "99/99/9999"
          )
            throw "não é uma data";

          if (identidadeEmissao.length < 10) throw "Data inválida";

          var [day, month, year] = identidadeEmissao.split("/");

          if (new Date(`${month}/${day}/${year}`) == "Invalid Date")
            throw "Data inválida";

          if (day == "00" || month == "00" || year == "0000")
            throw "Data inválida";

          if (!checarData(day, month, year)) throw "Data inválida";

          var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

          var [bDay, bMonth, bYear] = dataNascimento.split("/");

          var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

          if (_identidadeEmissao < _dataNascimento) {
            throw "Data não pode ser menor que a data de nascimento.";
          }

          if (_identidadeEmissao > new Date()) {
            throw "Data não pode ser maior que a data atual.";
          }

          if (day && month && year) {
            return undefined;
          } else {
            throw "Data inválida.";
          }
        } catch (err) {
          if (err == "campo vazio") return "Campo obrigatório";

          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
    data_naturalizacao: () => {
      if (dataNaturalizacao) {
        try {
          if (dataNaturalizacao == null || dataNaturalizacao == "")
            throw "campo vazio";

          if (
            dataNaturalizacao === "00/00/0000" ||
            dataNaturalizacao === "99/99/9999"
          )
            throw "não é uma data";

          if (dataNaturalizacao.length < 10) throw "Data inválida";

          var [day, month, year] = dataNaturalizacao.split("/");

          if (new Date(`${month}/${day}/${year}`) == "Invalid Date")
            throw "Data inválida";

          if (day == "00" || month == "00" || year == "0000")
            throw "Data inválida";

          if (!checarData(day, month, year)) throw "Data inválida";

          var _dataNaturalizacao = new Date(`${year}-${month}-${day}`);

          if (_dataNaturalizacao > new Date()) {
            throw "Data não pode ser maior que a data atual.";
          }

          var [bDay, bMonth, bYear] = dataNascimento.split("/");

          var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

          if (_dataNaturalizacao < _dataNascimento) {
            throw "Data não pode ser menor que a data de nascimento.";
          }

          if (day && month && year) {
            return undefined;
          } else {
            throw "Data inválida.";
          }
        } catch (err) {
          if (err == "campo vazio") return "Campo obrigatório";

          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
    data_nascimento: () => {
      if (dataNascimentoValida) {
        try {
          if (dataNascimento == null || dataNascimento == "")
            throw "campo vazio";

          if (dataNascimento.length < 10) throw "Data inválida";

          if (
            dataNascimento === "00/00/0000" ||
            dataNascimento === "99/99/9999"
          )
            throw "não é uma data";

          var [day, month, year] = dataNascimento.split("/");

          if (new Date(`${month}/${day}/${year}`) == "Invalid Date")
            throw "Data inválida";

          if (day == "00" || month == "00" || year == "0000")
            throw "Data inválida";

          if (!checarData(day, month, year)) throw "Data inválida";

          var [bDay, bMonth, bYear] = dataNascimento.split("/");

          var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

          if (_dataNascimento > new Date()) {
            throw "Data não pode ser maior que a data atual.";
          }

          if (day && month && year) {
            return undefined;
          } else {
            // return "Data inválida.";
            throw "Data inválida.";
          }
        } catch (err) {
          if (err == "campo vazio") return "Campo obrigatório";

          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
  };

  const getBirthCitysUpdate = (stateId) => {
    listCityByStateId(stateId || estadoNascimento)
      .then((data) => {
        if (data.status) {
          const citysMap = data.cities.map((item) => {
            return { id: item.id, value: item.description };
          });

          setBirthCitys(citysMap);
        } else {
          // if -> some issue, list all
          const domainCitys = props.dominios.city.map((item) => {
            return { id: item.id, value: item.description };
          });

          setBirthCitys(domainCitys);
        }
      })
      .catch((err) => console.error(err));
  };

  const [redefinirSenhaModal, setRedefinirSenhaModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    var validators = {};

    // Informações pessoais
    validators.cpf = validaCPF;
    validators.email = validaEmail;
    validators.fullName = requiredTwoName;
    validators.socialName = requiredOptionalTwoName;
    validators.nacionalidade = required;

    // Identidade
    validators.dataNascimento = validateDate.data_nascimento;
    validators.cns = CNSValido;

    // Endereço
    validators.sexo = required;
    validators.genero = required;
    validators.orientacaoSexual = required;
    validators.raca = required;

    validators.telefones = () => {
      let valido = true;

      telefones.map((res) => {
        if (!res.observation) valido = false;

        if (!res.value) valido = false;
      });

      return valido ? undefined : "Campo obrigatório";
    };

    if (raca == 5) {
      // validators.etnia = required;
      validators.etnia = () => {
        if (etnia === "null" || !etnia) {
          return "Campo obrigatório.";
        } else {
          return undefined;
        }
      };
    }

    if (nacionalidade == 1) {
      // Identidade - Brasileiro
      validators.numeroIdentidade = validaRG;
      //validators.numeroIdentidade = required;
      validators.dataEmissao = validateDate.data_emissao;
      validators.orgaoEmissor = required;
      validators.ufIdentidade = required;
      validators.estadoNascimento = required;
      validators.cidadeNascimento = required;

      // Endereço - Brasileiro
      validators.logradouro = required;
      validators.numero = required;

      validators.cep = () => {
        var _cep = cep.replace(/[^0-9]/g, "");
        if (_cep.length < 8) {
          return _cep.length === 0 ? "Campo obrigatório" : "CEP inválido.";
        }
      };

      if (!maeDesconhecida) {
        validators.nomeDaMae = requiredTwoName;
      }
    } else if (nacionalidade == 2) {
      // Identidade - Naturalizado
      validators.dataNaturalizacao = validateDate.data_naturalizacao;
      validators.paisNascimento = required;

      // Endereço - Naturalizado
      validators.logradouro = required;
      validators.numero = required;
      validators.cep = () => {
        var _cep = cep.replace(/[^0-9]/g, "");
        if (_cep.length < 8) {
          return _cep.length === 0 ? "Campo obrigatório" : "CEP inválido.";
        }
      };
    } else if (nacionalidade == 3) {
      // Identidade - Estrangeiro
      validators.passaporteNumero = requiredPassaporte;
      validators.passaportePaisEmissor = required;
      validators.passaporteDataEmissao = validateDate.data_emissao_passaporte;
      validators.passaporteDataValidade = validateDate.data_validade_passaporte;
      validators.paisNascimento = required;
    }

    if (conselhosDeClasse.length > 0 || novosConselhos.length > 0) {
      validators.documents = checkProfissionalDocuments;
    }

    if (conselhosProfile && conselhosProfile.length > 0) {
      // Tenha conselhos no perfil
      if (conselhosDeClasse && conselhosDeClasse.length === 0) {
        // Não tenha conselhos já preenchidos
        if (novosConselhos && novosConselhos.length === 0) {
          // Não tenha conselhos novos preenchidos
          validators.documents = "Preencha pelo menos um conselho";
        }
      }
    }

    if (isMedico) {
      console.log(especialidades);
      validators.especialidades = required;
    }

    return validators;
  }

  function getDados() {
    var dados = {};

    var nationality;

    switch (nacionalidade) {
      case 1:
        nationality = "brasileiro";
        break;
      case 2:
        nationality = "naturalizado";
        break;
      case 3:
        nationality = "estrangeiro";
        break;
      default:
        nationality = null;
    }

    if (!maeDesconhecida) {
      dados.nomeDaMae = nomeDaMae;
    }

    // Informações pessoais

    var { value: value_cpf } = cpf;

    dados.cpf = value_cpf;
    dados.fullName = fullName;
    dados.socialName = genero != 4 ? socialName : '';
    dados.email = email;
    dados.nacionalidade = nationality;

    // Identidade

    // RG
    dados.numeroIdentidade = identidadeNumero;
    dados.dataEmissao = identidadeEmissao;
    dados.orgaoEmissor = identidadeOrgaoEmissor;
    dados.ufIdentidade = identidadeUf;
    dados.estadoNascimento = estadoNascimento;
    dados.cidadeNascimento = cidadeNascimento;

    //PASSPORT
    dados.passaporteNumero = passaporteNumero;
    dados.passaportePaisEmissor = passaportePaisEmissor;
    dados.passaporteDataEmissao = passaporteDataEmissao;
    dados.passaporteDataValidade = passaporteDataValidade;
    dados.passaportePaisNascimento = passaportePaisNascimento;

    //NATURALIZED
    dados.dataNaturalizacao = dataNaturalizacao;
    dados.paisNascimento = paisNascimento;

    dados.dataNascimento = dataNascimento;

    // cns
    var cns_value = cns.value || null;

    dados.cns = cns_value;

    // Endereço
    var { number } = address;

    dados.cep = cep.replace(/[^0-9]/g, "").slice(0, 8);
    dados.logradouro = logradouro;
    dados.numero = number;

    // Dados demográficos

    dados.sexo = sexo;
    dados.genero = genero;
    dados.raca = raca;
    dados.orientacaoSexual = orientacaoSexual;

    dados.etnia = etnia;

    var _notEmpty = novosConselhos.filter((x) => x);
    let documents;

    if (nacionalidade == 3) {
      passport = getDocumentsPassport();
    }

    if (nacionalidade == 2) {
      naturalized = getDocumentsNaturalized();
    }

    if (nacionalidade == 1) {
      rg = getDocumentsRg();
    }

    documents = [
      {
        type: "CPF",
        fields: [cpf],
      },
      {
        type: "CNS",
        fields: [cns],
      },
      {
        type: "RG",
        fields: rg,
      },
      {
        type: "PASSPORT",
        fields: passport,
      },
      {
        type: "NATURALIZED",
        fields: naturalized,
      },
      ..._notEmpty.map((x) => {
        return {
          type: x.conselho,
          fields: [
            {
              value: x.codigo,
            },
            {
              value: x.uf,
            },
            {
              value: x.cbo,
            },
            {
              value: x.conselho,
            },
          ],
        };
      }),
      ...conselhosDeClasse,
    ];

    dados.documents = documents;

    if (isMedico) dados.especialidades = especialidades;

    return dados;
  }

  const handleSetDominios = (dominios) => {
    const states =
      dominios &&
      dominios.state.map((item) => {
        return { id: item.id, value: item.code };
      });

    const countrys =
      dominios &&
      dominios.country.map((item) => {
        return { id: item.id, value: item.description };
      });

    const citys =
      dominios &&
      dominios.city.map((item) => {
        return { id: item.id, value: item.description };
      });

    const etnias =
      dominios &&
      dominios.etnia.map((item) => {
        return { id: item.id, value: item.description };
      });

    const cbos =
      dominios &&
      dominios.cbo.map((item) => {
        return {
          id: item.id,
          code: item.code,
          value: `(${item.code}) ${item.description}`,
        };
      });

    const speciality =
      dominios &&
      dominios.medical_speciality.map((item) => {
        return { id: item.id, value: item.description };
      });

    setDocumentType(dominios ? dominios.document_type : []);

    setPaises(countrys);

    setEtnias(etnias);

    setCidades(citys);

    setEstados(states);

    setConselhoCbos(cbos);

    _setEspecialidades(speciality);
  };

  const findConselhosFromDocument = (document) => {
    if (Array.isArray(document)) {
      const conselhos = conselhosCode.map((conselho) => {
        const conselhoFiltered = document.filter(
          (item) => item.type === conselho.name
        );

        if (conselhoFiltered && conselhoFiltered.length > 0) {
          return conselhoFiltered;
        }
      });

      return conselhos.filter((x) => x);
    }
  };

  const formatDocument = (document) => {
    if (typeof document !== "undefined") {
      return document.fields.map((x) => {
        return {
          id: x.id,
          value: x.value,
          user_document_id: x.document_type_field_id,
        };
      });
    }
  };

  const isMedicalProfile = (conselhos) => {
    if (conselhos && Array.isArray(conselhos)) {
      var hasMedicalProfile = false;

      conselhos.map((conselho) => {
        if (conselho.fields && Array.isArray(conselho.fields)) {
          const codigo_conselho = conselho.fields[conselho.fields.length - 1];

          if (codigo_conselho && codigo_conselho.value) {
            const _code = codigo_conselho.value.match(/\((.*?)\)/g, ""); // Pegando CBO (xxxxx)

            if (Array.isArray(_code) && _code[0]) {
              var _codigo_conselho = _code[0].replace(/[^a-zA-Z0-9]/g, "");

              if (conselhosDeMedico.includes(_codigo_conselho)) {
                setIsMedico(true);
                hasMedicalProfile = true;
              }
            }
          }
        }
      });

      if (!hasMedicalProfile) {
        setIsMedico(false);

        setEspecialidades(null);
      }
    }
  };

  useEffect(() => {
    if (error && Object.keys(error).length > 0) {
      if (document.getElementById(Object.keys(error)[0])) {
        document
          .getElementById(Object.keys(error)[0])
          .scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [error]);

  useEffect(() => {
    if (!estadoNascimento) return;

    getBirthCitysUpdate(estadoNascimento);
  }, [estadoNascimento]);

  const loadUser = () => {
    getUserInfoById(localStorage.getItem("token"), localStorage.getItem("uid"))
      .then((data) => {
        if (data.status) {
          console.log(data);
          if (data.user) {
            setId(data.user.id);
            setApresentacao(data.user.presentation);
            setFullName(data.user.full_name);
            setSocialName(data.user.social_name);
            setPrevSocialName(data.user.social_name);
            setEmail(data.user.email);
            setEmailLogin(data.user.email);
            setNomeDaMae(
              data.user.mother_name !== "null" ? data.user.mother_name : ""
            );
            setMaeDesconhecida(
              !data.user.mother_name || data.user.mother_name === "null"
            );

            switch (data.user.nationality) {
              case "brasileiro":
                setNacionalidade(1);
                setNacionalidadeOrigin(1);
                break;

              case "naturalizado":
                setNacionalidade(2);
                setNacionalidadeOrigin(2);
                break;

              case "estrangeiro":
                setNacionalidade(3);
                setNacionalidadeOrigin(3);
                break;

              default:
                setNacionalidade(null);
            }

            let dataAniversario = data.user.birth_date
              ? String(data.user.birth_date).replaceAll("-", "/")
              : null;

            setDataNascimento(dataAniversario);
          }

          if (data.speciality && Array.isArray(data.speciality)) {
            if (data.speciality.length > 0) {
              getUserDominios().then((dominios) => {
                const _specialitys =
                  dominios &&
                  dominios.medical_speciality.map((item) => {
                    return { id: item.id, value: item.description };
                  });

                let _speciality = data.speciality.map((x) => {
                  return _specialitys.find(
                    (_especialidade) =>
                      _especialidade.id == x.medical_speciality_id
                  );
                });

                _speciality = _speciality.filter((x) => x);
                setEspecialidades(_speciality);
              });
            }
          }

          if (data.document && Array.isArray(data.document)) {
            const cpf = formatDocument(
              data.document.find((x) => x.type === "CPF")
            );
            const cns = formatDocument(
              data.document.find((x) => x.type === "CNS")
            );
            rg = formatDocument(data.document.find((x) => x.type === "RG"));
            passport = formatDocument(
              data.document.find((x) => x.type === "PASSPORT")
            );
            naturalized = formatDocument(
              data.document.find((x) => x.type === "NATURALIZED")
            );

            var conselhos = [];

            findConselhosFromDocument(data.document).forEach((x) => {
              x.map((i) => {
                i.fields = i.fields.map((y) => {
                  y.value = y.value == "-" ? "" : y.value;
                  return y;
                });
                console.log(i);
                conselhos.push(i);
              });
            });

            isMedicalProfile(conselhos);
            setConselhosDeClasse(conselhos);
            setCpf(cpf[0]);
            setCns(cns[0]);
            setRg(rg);
            setPassport(typeof passport !== "undefined" ? passport : undefined);
            setNaturalized(
              typeof naturalized !== "undefined" ? naturalized : undefined
            );

            if (typeof passport !== "undefined") {
              let numeroPasport = passport.find((x) => x.user_document_id === 5)
                ? passport.find((x) => x.user_document_id === 5).value
                : null; //5
              let dataEmissaoPassport = passport.find(
                (x) => x.user_document_id === 6
              )
                ? passport.find((x) => x.user_document_id === 6).value
                : null; //6
              let paisEmissor = passport.find((x) => x.user_document_id === 7)
                ? passport.find((x) => x.user_document_id === 7).value
                : null; //7
              let dataValidade = passport.find((x) => x.user_document_id === 8)
                ? passport.find((x) => x.user_document_id === 8).value
                : null; //8

              if (numeroPasport != null) setPassaporteNumero(numeroPasport);

              if (paisEmissor != null) setPassaportePaisEmissor(paisEmissor);

              if (dataEmissaoPassport != null)
                setPassaporteDataEmissao(dataEmissaoPassport);

              if (dataValidade != null) setPassaporteDataValidade(dataValidade);
            }

            if (typeof naturalized !== "undefined") {
              let dataNaturalizacao = naturalized.find(
                (x) => x.user_document_id === 15
              )
                ? naturalized.find((x) => x.user_document_id === 15).value
                : null;
              let paisNascimento = naturalized.find(
                (x) => x.user_document_id === 16
              )
                ? naturalized.find((x) => x.user_document_id === 16).value
                : null;

              if (dataNaturalizacao != null)
                setDataNaturalizacao(dataNaturalizacao);

              if (paisNascimento != null) setPaisNascimento(paisNascimento);
            }

            if (typeof rg !== "undefined") {
              let numeroRg =
                rg && rg.find((x) => x.user_document_id === 2)
                  ? rg.find((x) => x.user_document_id === 2).value
                  : null;
              let dataEmissaoRg =
                rg && rg.find((x) => x.user_document_id === 3)
                  ? rg.find((x) => x.user_document_id === 3).value
                  : null;
              let orgaoEmissorRg =
                rg && rg.find((x) => x.user_document_id === 4)
                  ? rg.find((x) => x.user_document_id === 4).value
                  : null;
              let ufRg =
                rg && rg.find((x) => x.user_document_id === 60)
                  ? rg.find((x) => x.user_document_id === 60).value
                  : null;

              if (dataEmissaoRg != null) setIdentidadeEmissao(dataEmissaoRg);

              if (numeroRg != null) setIdentidadeNumero(numeroRg);

              if (orgaoEmissorRg != null)
                setIdentidadeOrgaoEmissor(orgaoEmissorRg);

              if (ufRg != null) setIdentidadeUf(ufRg);
            }
          }

          if (data.address && Array.isArray(data.address)) {
            setAddress(data.address[0]);

            const {
              code: estadoId,
              country_id: estadoCountryId,
              description: estadoValue,
            } = data.address[0].city.state;

            setLogradouro(data.address[0].address_type_id);
            setCep(data.address[0].zip_code);
            setMunicipioEndereco(data.address[0].city.description);
          }

          if (data.contact && Array.isArray(data.contact)) {
            setTelefones(data.contact);
          }

          if (data.demographic_data && Array.isArray(data.demographic_data)) {
            const demo_data = data.demographic_data[0];

            var GenreId;
            var BreedId;

            if (isNaN(demo_data.genre)) {
              // Gênero como descripition;
              const { id } = generosFormat.find(
                (x) => x.value === demo_data.genre
              );
              GenreId = id;
            } else {
              // Gênero como id;
              GenreId = demo_data.genre;
            }

            if (isNaN(demo_data.breed)) {
              // Raça como descripition;
              const { id } = racasFormat.find(
                (x) => x.value === demo_data.breed
              );
              BreedId = id;
            } else {
              // Raça como id;
              BreedId = demo_data.breed;
            }

            racasFormat.map(function (item) {
              if (item.id == demo_data.breed) setRacaDescription(item);
            });

            setRaca(BreedId);
            setEtnia(demo_data.etnia);
            setSexo(demo_data.sex);
            setGenero(GenreId);
            setEstadoNascimento(demo_data.state_birth);
            setCidadeNascimento(demo_data.city_birth);
            setOrientacaoSexual(demo_data.sexual_orientation);

            if (
              data.user.nationality == "brasileiro" ||
              data.user.nationality == "estrangeiro"
            )
              setPaisNascimento(demo_data.country_birth);
          }

          if (data.user.profiles.length > 0) {
            let new_conselhos_profile = [];
            data.user.profiles.map(function (profiles) {
              profiles.documents.map(function (documents) {
                new_conselhos_profile.push(documents);
              });
            });

            // Retira resultados duplicados
            new_conselhos_profile = new_conselhos_profile.filter(
              (value, index, self) => self.indexOf(value) === index
            );

            setConselhosProfile(new_conselhos_profile);
          }
        } else {
          setEmailLogin("admin@admin.com");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setEmailLogin("");

    loadUser();

    getUserDominios()
      .then((res) => {
        handleSetDominios(res);
      })
      .catch((err) => {
        console.error(err);
      });

    // Get user avatar
    getUserAvatar(localStorage.getItem("token"))
      .then((data) => {
        if (data.status) {
          setAvatar(data.avatar);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  function formatDate(date) {
    if (date != "null") {
      var day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      var month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      var year = date.getFullYear();

      return [year, month, day].join("-");
    } else {
      return "";
    }
  }

  function handleRemoveProfileAvatar() {
    if (loadingDeleteAvatar) return;

    let temp_avatar = avatar;
    setAvatar(null);
    setLoadingDeleteAvatar(true);

    removeUserAvatar(localStorage.getItem("token"))
      .then((data) => {
        if (data.status) {
          setLoadingDeleteAvatar(false);
        } else {
          setAvatar(temp_avatar);
          setLoadingDeleteAvatar(false);
          notify.avatar.error2();
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingDeleteAvatar(false);
        notify.avatar.error2();
        setAvatar(temp_avatar);
      });
  }

  function readB64Img(file, cb) {
    var reader = new FileReader();

    reader.onloadend = (e) => {
      cb(reader.result);
    };

    reader.readAsDataURL(file);
  }

  useEffect(() => {
    if (fileUpload) {
      if (!["image/jpeg", "image/png", "image/jpg"].includes(fileUpload.type))
        return;

      if (loadingUpload) return;

      setLoadingUpload(true);
      readB64Img(fileUpload, (base64) => setAvatar(base64));

      updateUserAvatar(localStorage.getItem("token"), fileUpload)
        .then((res) => {
          if (res.status) {
            setAvatar(res.avatar);
            setFileUpload(null);
            setLoadingUpload(false);
            notify.avatar.updated();
          } else {
            setLoadingUpload(false);
            setFileUpload(null);
            notify.avatar.error();
          }
        })
        .catch((err) => {
          setLoadingUpload(false);
          setFileUpload(null);
          notify.avatar.error();
          console.error(err);
        });
    }
  }, [fileUpload]);

  const getInitialsName = (value) => {
    if (!value) return;
    value = value.split(" ");

    // console.log(value);

    if(value.length > 0) {
      return value.length > 1 ? (value[0].charAt(0).toUpperCase() + value[value.length - 1].charAt(0).toUpperCase()) : value[0].charAt(0).toUpperCase();
    } else {
      return value;
    }
  };

  useEffect(() => {
    console.log(error);
    if (error.documents) {
      toast.error("Preencha pelo menos um documento.");
    }
  }, [error]);

  const getUfNameById = (ufId) => {
    if (!estados || !estados.length || !ufId) return;

    const uf = estados.find((x) => x.id === Number(ufId));

    return uf.value || "";
  };

  function handleUpdateUser() {
    if (!localStorage.getItem("token") || !localStorage.getItem("uid")) return;

    var data = getDados();

    if (loadingUpdateUser) return;

    setLoadingUpdateUser(true);

    if (!validarFormulario({ ...data })) return setLoadingUpdateUser(false);

    var nationality;

    switch (nacionalidade) {
      case 1:
        nationality = "brasileiro";
        break;
      case 2:
        nationality = "naturalizado";
        break;
      case 3:
        nationality = "estrangeiro";
        break;
      default:
        nationality = null;
    }

    const user = {
      presentation: apresentacao,
      name: fullName,
      social_name: genero != 4 ? socialName : "",
      birth_date: dataNascimento,
      email: email,
      nationality: nationality,
      mother_name: nomeDaMae || "null",
    };

    const demographic_data = {
      state_birth: estadoNascimento,
      city_birth: cidadeNascimento,
      country_birth: paisNascimento ? paisNascimento : 1,
      sexual_orientation_id: orientacaoSexual,
      sex: sexo,
      genre: genero,
      breed: raca,
      etnia: etnia || 1,
    };

    let documents = [];

    if (nacionalidadeOrigin != nacionalidade) {
      if (nacionalidade == 3) {
        passport = getDocumentsPassport();
      }

      if (nacionalidade == 2) {
        naturalized = getDocumentsNaturalized();
      }

      if (nacionalidade == 1) {
        rg = getDocumentsRg();
      }

      documents = [
        {
          type: "CPF",
          fields: [cpf],
        },
        {
          type: "CNS",
          fields: [cns],
        },
        {
          type: "RG",
          fields: rg,
        },
        {
          type: "PASSPORT",
          fields: passport,
        },
        {
          type: "NATURALIZED",
          fields: naturalized,
        },
        ...conselhosDeClasse,
      ];
    } else {
      documents = [
        {
          type: "CPF",
          fields: [cpf],
        },
        {
          type: "CNS",
          fields: [cns],
        },
        {
          type: "RG",
          fields: rg,
        },
        {
          type: "PASSPORT",
          fields: passport,
        },
        {
          type: "NATURALIZED",
          fields: naturalized,
        },
        ...conselhosDeClasse,
      ];
    }

    const _address = {
      address_name: address.address_name,
      zip_code: cep.replace(/[^0-9]/g, "").slice(0, 8),
      street: address.street,
      number: address.number,
      complement: address.complement,
      district: address.district,
      city_id: address.city_id,
      address_type_id: logradouro, // address.address_type_id // Logradouro id
    };

    const speciality = especialidades
      ? especialidades.map((x) => {
          return {
            medical_speciality_id: x.id,
          };
        })
      : [];

    const dados = {
      user,
      demographic_data,
      document: documents.map((x) => {
        if (Array.isArray(x.fields)) {
          x.fields = x.fields.map((y) => {
            y.value = !y.value || y.value == "" ? "-" : y.value;
            return y;
          });
        }
        return x;
      }),
      address: _address,
      contact: telefones.map((x) => {
        return {
          ...x,
          type: "telefone",
          observation: x.observation,
          value: x.value,
        };
      }),
      speciality,
      new_conselhos: novosConselhos,
    };

    if (maeDesconhecida) dados.user.mother_name = "";

    updateUser(
      localStorage.getItem("token"),
      localStorage.getItem("uid"),
      dados
    )
      .then((data) => {
        setNovosConselhos([]);
        setConselhosDeClasse([]);
        setLoadingUpdateUser(false);
        notify.user.updated();
        loadUser();
      })
      .catch((error) => {
        setLoadingUpdateUser(false);

        if (error.response) {
          const { data } = error.response;

          if (data) {
            if (data.details) {
              var _errors = Object.keys(data.details);

              handlingBackendErrors(_errors, data.details);
            }
          }
        }

        notify.user.error();
      });
  }

  function getDocumentsRg() {
    return [
      {
        user_document_id: 2,
        value: identidadeNumero,
      },
      {
        user_document_id: 3,
        value: identidadeEmissao,
      },
      {
        user_document_id: 4,
        value: identidadeOrgaoEmissor,
      },
      {
        user_document_id: 60,
        value: identidadeUf,
      },
    ];
  }

  function getDocumentsNaturalized() {
    return [
      {
        user_document_id: 15,
        value: dataNaturalizacao,
      },
      {
        user_document_id: 16,
        value: paisNascimento,
      },
    ];
  }

  function getDocumentsPassport() {
    return [
      {
        user_document_id: 5,
        value: passaporteNumero,
      },
      {
        user_document_id: 6,
        value: passaporteDataEmissao,
      },
      {
        user_document_id: 7,
        value: passaportePaisEmissor,
      },
      {
        user_document_id: 8,
        value: passaporteDataValidade,
      },
    ];
  }

  function handleAnchor(elementId) {
    try {
      document.getElementById(elementId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } catch (err) {
      console.log(err);
    }
  }

  function updatedCep(cep) {
    if (!cep) return;

    const _cep = cep.replace(/[^0-9]/g, "");

    if (_cep.length === 8) {
      getCep2(_cep)
        .then((data) => {
          if (data.status) {
            var _city = address.city;

            if (_city) {
              _city.state[0].code = data.estado_sigla || "";
            }

            let logradouro = tiposLogradouro.find(
              (res) => res.value === data.logradouro_tipo
            );
            setLogradouro(logradouro ? logradouro.id : null);
            setMunicipioEndereco(data.localidade);
            setAddress({
              ...address,
              street: data.logradouro,
              district: data.bairro,
              city: _city,
            });
          } else {
            setLogradouro(null);
            setMunicipioEndereco(null);
            setAddress({
              ...address,
              street: null,
              district: null,
              city: null,
            });
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }

  function fragmentPaisNascimentoPassaporte() {
    if (paises && (paisNascimento || nacionalidade != nacionalidadeOrigin)) {
      return (
        <>
          <Grid item md={12}>
            <DAutoComplete
              id="passaportePaisNascimento"
              label="País de nascimento"
              fullWidth
              placeholder="País de nascimento"
              error={error.paisNascimento}
              helperText={error.paisNascimento}
              defaultValue={paises.filter((res) => res.id == paisNascimento)[0]}
              options={paises}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              onChange={(event, newValue) => {
                delete error.paisNascimento;
                setPaisNascimento(newValue ? newValue.id : "");
              }}
            />
          </Grid>
        </>
      );
    }
  }

  function fragmentPaisNascimento() {
    if (paises && (paisNascimento || nacionalidade != nacionalidadeOrigin)) {
      return (
        <>
          <Grid item md={6}>
            <DAutoComplete
              id="paisNascimento"
              label="País de nascimento"
              fullWidth
              placeholder="País de nascimento"
              error={error.paisNascimento}
              helperText={error.paisNascimento}
              defaultValue={paises.filter((res) => res.id == paisNascimento)[0]}
              options={paises}
              getOptionLabel={(option) => option.value}
              getOptionSelected={(option, value) =>
                value && option.id === value.id
              }
              onChange={(event, newValue) => {
                delete error.paisNascimento;
                setPaisNascimento(newValue ? newValue.id : "");
              }}
            />
          </Grid>
        </>
      );
    }
  }

  function fragmentEstadoCidadeNascimento() {
    if (
      estados &&
      ((birthCitys.length > 0 && cidadeNascimento !== null) ||
        nacionalidade != nacionalidadeOrigin)
    ) {
      return (
        <>
          <div className="d-flex flex-column">
            <Grid item md={12}>
              <DDropDownSimple
                id="estadoNascimento"
                label="Estado de nascimento"
                placeholder="Estado de nascimento"
                fullWidth
                error={error.estadoNascimento}
                helperText={error.estadoNascimento}
                value={estadoNascimento}
                options={estados}
                onChange={(event) => {
                  delete error.estadoNascimento;
                  setEstadoNascimento(event);
                  setCidadeNascimento("");
                }}
              />
            </Grid>
          </div>
          <div className="d-flex flex-column">
            <Grid item md={12} style={{ marginTop: "25px" }}>
              {estadoNascimento && (
                <DDropDownSimple
                  id="cidadeNascimento"
                  label="Município de nascimento"
                  placeholder="Município de nascimento"
                  fullWidth
                  error={error.cidadeNascimento}
                  helperText={error.cidadeNascimento}
                  value={cidadeNascimento}
                  options={birthCitys}
                  onChange={(event) => {
                    delete error.cidadeNascimento;

                    setCidadeNascimento(event);
                  }}
                />
              )}
            </Grid>
          </div>
        </>
      );
    }
  }

  function fragmentPaisEmissorPassaporte() {
    if (passaportePaisEmissor || nacionalidade != nacionalidadeOrigin) {
      return (
        <>
          <DAutoComplete
            id="passaportePaisEmissor"
            label="País emissor do passaporte"
            placeholder="País emissor do passaporte"
            fullWidth
            error={error.passaportePaisEmissor}
            helperText={error.passaportePaisEmissor}
            defaultValue={() => {
              return paises.filter((res) => res.id == passaportePaisEmissor)[0];
            }}
            options={paises}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) =>
              value && option.id === value.id
            }
            onChange={(event, newValue) => {
              delete error.passaportePaisEmissor;
              setPassaportePaisEmissor(newValue ? newValue.id : "");
            }}
          />
        </>
      );
    }
  }

  useEffect(() => {
    // Update Specialities by CBO code change.
    var _notEmpty = novosConselhos.filter((x) => x);

    var conselhos = [
      ..._notEmpty.map((x) => {
        return {
          type: x.conselho,
          fields: [
            {
              value: x.conselho,
            },
            {
              value: x.codigo,
            },
            {
              value: x.conselho,
            },
            {
              value: x.uf,
            },
            {
              value: x.cbo,
            },
          ],
        };
      }),
      ...conselhosDeClasse,
    ];

    isMedicalProfile(conselhos);
  }, [novosConselhos, conselhosDeClasse]);

  function showDocumentsNationality(value) {
    switch (value) {
      case 1:
        return (
          <>
            <div className="identidade-input-box mb-2em">
              <div className="mb-2em">
                <DInput
                  id="numeroIdentidade"
                  label="Número de identidade"
                  placeholder="Placeholder"
                  className="mr-2em"
                  fixedLabel
                  type="text"
                  error={error.numeroIdentidade}
                  helperText={error.numeroIdentidade}
                  inputProps={{ maxLength: 12 }}
                  fullWidth={false}
                  onChange={(e) => {
                    delete error.identidadeNumero;
                    setIdentidadeNumero(mascaraRG(e.target.value));
                  }}
                  positionIcon="end"
                  value={mascaraRG(identidadeNumero)}
                />

                <DDateInput
                  error={error.dataEmissao}
                  helperText={error.dataEmissao}
                  id="dataEmissao"
                  label="Data de emissão"
                  value={identidadeEmissao}
                  minDate={
                    dataNascimento
                      ? String(dataNascimento).split("/").reverse().join("-")
                      : null
                  }
                  maxDate={new Date()}
                  onError={(e) => setErrors({ ...error, dataEmissao: e })}
                  minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                  maxDateMessage="A data de emissão não pode ser maior que a data atual"
                  onChange={(value, { isValid }) => {
                    setIdentidadeEmissao(value);
                    setIdentidadeEmissaoValida(isValid);
                    delete error.dataEmissao;
                  }}
                />
              </div>
              <div>
                <Grid container>
                  <Grid item md={12}>
                    <DInput
                      id="nomeDaMae"
                      label="Nome da mãe"
                      placeholder="Placeholder"
                      type="text"
                      error={error.nomeDaMae}
                      helperText={error.nomeDaMae}
                      disabled={maeDesconhecida}
                      fullWidth={true}
                      value={nomeDaMae}
                      onChange={(e) => {
                        delete error.nomeDaMae;

                        var _value = e.target.value.replace(
                          /[^a-zA-Zà-úÀ-Ú"' ]/g,
                          ""
                        );

                        setNomeDaMae(_value);
                      }}
                      positionIcon="end"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={maeDesconhecida}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={(e) => {
                            setMaeDesconhecida(!maeDesconhecida);

                            setNomeDaMae("");
                            delete error.nomeDaMae;
                          }}
                        />
                      }
                      label="Mãe desconhecida"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="d-flex flex-column">
                <DDropDownSimple
                  id="orgaoEmissor"
                  label="Órgão emissor"
                  placeholder="Órgão emissor"
                  fullWidth
                  error={error.orgaoEmissor}
                  helperText={error.orgaoEmissor}
                  value={identidadeOrgaoEmissor}
                  options={orgaoEmissorFormat}
                  onChange={(value) =>
                    setRg((prevState) => {
                      delete error.orgaoEmissor;
                      setIdentidadeOrgaoEmissor(value);
                    })
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <DDropDownSimple
                  id="ufIdentidade"
                  label="UF"
                  placeholder="UF"
                  fullWidth
                  error={error.ufIdentidade}
                  helperText={error.ufIdentidade}
                  value={identidadeUf}
                  options={estados}
                  onChange={(value) =>
                    setRg((prevState) => {
                      delete error.orgaoEmissor;
                      setIdentidadeUf(value);
                    })
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <DInput
                  id="cns"
                  label="Cartão Nacional de Saúde (CNS)"
                  placeholder="CNS"
                  type="text"
                  error={error.cns}
                  helperText={error.cns}
                  inputProps={{ maxLength: 15 }}
                  fullWidth={false}
                  fixedLabel
                  onChange={(e) => {
                    delete error.cns;

                    var _value = e.target.value.replace(/[^0-9]/g, "");

                    setCns({ ...cns, value: _value });
                  }}
                  positionIcon="end"
                  value={cns && cns.value}
                />
              </div>
              {fragmentEstadoCidadeNascimento()}
            </div>
          </>
        );
      case 2:
        return (
          <>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <DDateInput
                    id="dataNaturalizacao"
                    error={error.dataNaturalizacao}
                    helperText={error.dataNaturalizacao}
                    fullWidth
                    label="Data de naturalização"
                    minDate={
                      dataNascimento
                        ? String(dataNascimento).split("/").reverse().join("-")
                        : null
                    }
                    minDateMessage="A data de naturalização não pode ser menor que a data de nascimento"
                    maxDate={new Date()}
                    maxDateMessage="A data de naturalização não pode ser maior que a data atual"
                    value={dataNaturalizacao}
                    onError={(errorMessage) => {
                      setErrors({ ...error, dataNaturalizacao: errorMessage });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.dataNaturalizacao;
                      setDataNaturalizacao(arg1);
                      setDataNaturalizacaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
                {fragmentPaisNascimento()}
                <Grid item md={12}>
                  <div className="d-flex flex-column">
                    <DInput
                      id="cns"
                      label="Cartão Nacional de Saúde (CNS)"
                      placeholder="CNS"
                      type="text"
                      error={error.cns}
                      helperText={error.cns}
                      inputProps={{ maxLength: 15 }}
                      fullWidth={false}
                      fixedLabel
                      onChange={(e) => {
                        delete error.cns;

                        var _value = e.target.value.replace(/[^0-9]/g, "");

                        setCns({ ...cns, value: _value });
                      }}
                      positionIcon="end"
                      value={cns && cns.value}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      case 3:
        return (
          <>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <DInput
                    id="passaporteNumero"
                    label="Número do passaporte"
                    placeholder="Número do passaporte"
                    type="text"
                    error={error.passaporteNumero}
                    helperText={error.passaporteNumero}
                    positionIcon="end"
                    inputProps={{ maxLength: 9 }}
                    fullWidth
                    onChange={(e) => {
                      delete error.passaporteNumero;
                      setPassaporteNumero(e.target.value.replace(/[^0-9a-zA-Z]/g, ''));
                    }}
                    value={passaporteNumero}
                  />
                </Grid>
                <Grid item md={6}>
                  {fragmentPaisEmissorPassaporte()}
                </Grid>
                <Grid item md={6}>
                  <DDateInput
                    id="passaporteDataEmissao"
                    error={error.passaporteDataEmissao}
                    helperText={error.passaporteDataEmissao}
                    fullWidth
                    label="Data de emissão de passaporte"
                    minDate={
                      dataNascimento
                        ? String(dataNascimento).split("/").reverse().join("-")
                        : null
                    }
                    minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                    maxDate={new Date()}
                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                    value={passaporteDataEmissao}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        passaporteDataEmissao: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.passaporteDataEmissao;

                      localStorage.setItem("passaporteDataEmissao", e);

                      setPassaporteDataEmissao(arg1);
                      setPassaporteDataEmissaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <DDateInput
                    id="passaporteDataValidade"
                    error={error.passaporteDataValidade}
                    helperText={error.passaporteDataValidade}
                    fullWidth
                    label="Data de validade do passaporte"
                    minDate={
                      dataNascimento
                        ? String(dataNascimento).split("/").reverse().join("-")
                        : null
                    }
                    minDateMessage="A data de validade não pode ser menor que a data de nascimento"
                    // maxDate={ new Date() }
                    // maxDateMessage="A data de validade não pode ser maior que a data atual"
                    value={passaporteDataValidade}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        passaporteDataValidade: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.passaporteDataValidade;

                      localStorage.setItem("passaporteDataValidade", e);

                      setPassaporteDataValidade(arg1);
                      setPassaporteDataValidadeValida(arg2.isValid);
                    }}
                  />
                </Grid>
                {fragmentPaisNascimentoPassaporte()}
                <Grid item md={12}>
                  <div className="d-flex flex-column">
                    <DInput
                      id="cns"
                      label="Cartão Nacional de Saúde (CNS)"
                      placeholder="CNS"
                      type="text"
                      error={error.cns}
                      helperText={error.cns}
                      inputProps={{ maxLength: 15 }}
                      fullWidth={false}
                      fixedLabel
                      onChange={(e) => {
                        delete error.cns;

                        var _value = e.target.value.replace(/[^0-9]/g, "");

                        setCns({ ...cns, value: _value });
                      }}
                      positionIcon="end"
                      value={cns && cns.value}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      default:
        return <></>;
    }
  }

  function showDadosProfissionais() {
    return (
      <>
        <li
          className="sidebar-item"
          onClick={(e) => handleAnchor("dados_profissionais")}
        >
          <CreateNewFolder className="d-text-color" />
          <span>Dados profissionais</span>
        </li>
      </>
    );
  }

  function handlingBackendErrors(_errors, details) {
    if (Array.isArray(_errors) && _errors.length > 0) {
      var _error = {};

      if (_errors.includes("user.email")) {
        setErrors({ ...error, email: "Email já cadastrado." });
        _error.email = "Email já cadastrado.";
      }

      if (_errors.includes("document")) {
        if (Array.isArray(details.document) && details.document.length > 0) {
          for (const erro of details.document) {
            if (erro === "CPF inválido ou CPF já utilizado.") {
              _error.cpf = "CPF inválido ou CPF já utilizado.";
            }
          }
        }
      }

      setErrors({ ..._error });
    }
  }

  useEffect(() => {
    if(genero == 4) // cis
    {
      setSocialName("");
      setPrevSocialName(socialName)
    }
    else
    {
      setSocialName(prevSocialName);
    }
  }, [genero]);

  return (
    <>
      {emailLogin === "admin@admin.com" ? (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item className="mt-3">
              <Typography variant="h1">Importante</Typography>
              <Typography variant="body1">
                Não é possível editar as informações do usuário administrativo,
                utilize outro usuário.
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {redefinirSenhaModal ? (
            <RedefinirSenha
              reenviar={(type) => resendCode(type)}
              open={redefinirSenhaModal}
              setModal={(e) => setRedefinirSenhaModal(!redefinirSenhaModal)}
            />
          ) : (
            ""
          )}

          {removerTelefone != null && (
            <RemoverTelefone
              open={removerTelefone != null ? true : false}
              onRemove={(index) => {
                setTelefones((prevState) => {
                  let temp = [...prevState];

                  temp.splice(index, 1);

                  return temp;
                });

                setRemoverTelefone(null);
              }}
              index={removerTelefone}
              onClose={(e) => setRemoverTelefone(null)}
            />
          )}

          <div className="tema-light" id="my-profile-page">
            <main>
              <nav className="sidebar">
                <ul>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("foto")}
                  >
                    <Portrait className="d-text-color" />
                    <span>Foto e apresentação</span>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("dados_principais")}
                  >
                    <Folder className="d-text-color" />
                    <span>Dados principais</span>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("identidade")}
                  >
                    <Person className="d-text-color" />
                    <span>Identidade</span>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("telefones")}
                  >
                    <Phone className="d-text-color" />
                    <span>Telefones</span>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("endereco")}
                  >
                    <Home className="d-text-color" />
                    <span>Endereço</span>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={(e) => handleAnchor("dados_demograficos")}
                  >
                    <Assignment className="d-text-color" />
                    <span>Dados demográficos</span>
                  </li>
                  {(Array.isArray(conselhosDeClasse) &&
                    conselhosDeClasse.length) ||
                  (conselhosDeClasse && conselhosProfile.length > 0)
                    ? showDadosProfissionais()
                    : ""}
                </ul>
              </nav>
              <article>
                <header>
                  <h2 className="header-text">Meu perfil</h2>

                  <div>
                    {/* <DButton
                      variant="secondary"
                      className="redefinir-senha"
                      icon={<VpnKey fontSize="small" />}
                      size="md"
                      onClick={(e) => setConfirmationModal(!confirmationModal)}
                    >
                      Redefinir senha
                    </DButton> */}
                    <Button
                      variant="outlined"
                      startIcon={<VpnKey fontSize="small" />}
                      size="md"
                      className={`${confirmationModal ? classes.addGrayButton : classes.addTagButton} redefinir-senha`}
                      onClick={(e) => setConfirmationModal(!confirmationModal)}
                    >
                      Redefinir senha
                    </Button>
                    {/* open={confirmationModal} setModal={e => setConfirmationModal(!confirmationModal)} openRedefinir={e => {setRedefinirSenhaModal(true); setConfirmationModal(false)}}  */}
                    {confirmationModal && (
                      <DButton
                        variant="ghost"
                        size="md"
                        className={`${classes.buttonConfirm}`}
                        onClick={(e) => {
                          setRedefinirSenhaModal(true);
                          setConfirmationModal(false);
                        }}
                      >
                        Confirmar redefinição
                      </DButton>
                    )}
                  </div>
                </header>

                <section className="user-info" id="foto">
                  <span className="spacing subtitle d-text-color d-block">
                    Foto e apresentação
                  </span>
                  <div className="change-photo">
                    {avatar ? (
                      <img src={avatar} alt="avatar" />
                    ) : (
                      <div className="string-username-box">
                        <span>
                          {socialName == undefined || socialName == ""
                            ? fullName && getInitialsName(fullName)
                            : socialName && getInitialsName(socialName)}
                        </span>
                      </div>
                    )}

                    <div className="username">
                      <span>
                        {socialName == undefined || socialName == ""
                          ? fullName
                          : socialName}
                      </span>
                    </div>

                    <div className="buttons">
                      {avatar ? (
                        <button
                          style={{ fontFamily: "Roboto" }}
                          onClick={(e) => handleRemoveProfileAvatar()}
                        >
                          {loadingDeleteAvatar ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            "Remover foto de perfil"
                          )}
                        </button>
                      ) : (
                        ""
                      )}

                      <button
                        style={{ fontFamily: "Roboto" }}
                        onClick={(x) => {
                          if (InputFileRef.current) {
                            InputFileRef.current.click();
                            // setFile(InputFileRef.current.files[0]);
                            // setFileName(InputFileRef.current.files[0].name);
                            // setFileSize(InputFileRef.current.files[0].size);
                          }
                        }}
                      >
                        <span>
                          {loadingUpload ? (
                            <CircularProgress size={15} color="inherit" />
                          ) : (
                            "Alterar foto de perfil"
                          )}
                        </span>
                      </button>
                    </div>

                    <input
                      style={{ display: "none" }}
                      ref={InputFileRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => setFileUpload(e.target.files[0])}
                    />
                  </div>
                  <div className="textarea-box">
                    <span className="d-block">Apresentação</span>
                    <textarea
                      value={apresentacao}
                      style={{ fontFamily: "inherit" }}
                      onChange={(e) => setApresentacao(e.target.value)}
                      maxLength="255"
                      placeholder="Escreva uma breve apresentação para que seus pacientes te conheçam melhor"
                    ></textarea>
                    <span className="textarea-length d-block">
                      {apresentacao && apresentacao.length}/255
                    </span>
                  </div>
                </section>

                <section
                  className="pessoal-infos spacing-x spacing-bottom-0"
                  id="dados_principais"
                >
                  <div>
                    <div className="input-info">
                      <DInput
                        id="cpf"
                        label="CPF"
                        placeholder="Placeholder"
                        type="text"
                        fixedLabel
                        error={error.cpf}
                        helperText={error.cpf}
                        // success={true}
                        inputProps={{ maxLength: 14 }}
                        // required={true}
                        // disabled={true}
                        fullWidth={false}
                        // onClick={ e => console.log(e.target.value) }
                        onChange={(e) => {
                          delete error.cpf;
                          setCpf({ ...cpf, value: e.target.value });
                        }}
                        positionIcon="end"
                        value={cpf && getCPF(cpf.value)}
                      />
                      <DInput
                        id="email"
                        label="E-mail"
                        placeholder="Placeholder"
                        type="text"
                        error={error.email}
                        helperText={error.email}
                        inputProps={{ maxLength: 254 }}
                        // success={true}
                        // required={true}
                        // disabled={true}
                        fullWidth={false}
                        // onClick={ e => console.log(e.target.value) }
                        onChange={(e) => setEmail(e.target.value)}
                        positionIcon="end"
                        value={email}
                        icon={null}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="input-info">
                      <DInput
                        id="fullName"
                        label="Nome completo"
                        placeholder="Placeholder"
                        inputProps={{ maxLength: 100 }}
                        type="text"
                        error={error.fullName}
                        helperText={error.fullName}
                        // success={true}
                        // required={true}
                        // disabled={true}
                        fullWidth={false}
                        // onClick={ e => console.log(e.target.value) }
                        onChange={(e) => {
                          delete error.fullName;

                          var _value = e.target.value.replace(
                            /[^a-zA-Zà-úÀ-Ú"' ]/g,
                            ""
                          );

                          setFullName(_value);
                        }}
                        positionIcon="end"
                        value={fullName && fullName}
                      />

                      <DDropDownSimple
                        id="nacionalidade"
                        label="Nacionalidade"
                        placeholder="Nacionalidade"
                        error={error.nacionalidade}
                        helperText={error.nacionalidade}
                        value={nacionalidade}
                        options={nacionalidadesCode}
                        onChange={(value) => {
                          showDocumentsNationality(value);
                          setNacionalidade(value);
                          delete error.nacionalidade;
                        }}
                      />
                    </div>
                  </div>
                </section>

                <section class="pessoal-infos-social">
                  <div className="input-info">
                    {
                      // Nome social apenas para não cisgênero.
                      genero != 4 ? (
                        <DInput
                          id="socialName"
                          label="Nome social"
                          placeholder="Placeholder"
                          type="text"
                          inputProps={{ maxLength: 100 }}
                          fullWidth
                          error={error.socialName}
                          helperText={error.socialName}
                          onChange={(e) => {
                            delete error.socialName;

                            var _value = e.target.value.replace(
                              /[^a-zA-Zà-úÀ-Ú"' ]/g,
                              ""
                            );

                            setSocialName(_value);
                          }}
                          positionIcon="end"
                          value={socialName && socialName}
                        />
                      ) : ""
                    }

                    <DDateInput
                      id="dataNascimento"
                      error={error.dataNascimento}
                      helperText={error.dataNascimento}
                      label="Data de Nascimento"
                      value={dataNascimento}
                      maxDate={new Date()}
                      maxDateMessage="Data de nascimento não pode ser maior que a data atual"
                      onError={(e) =>
                        setErrors({ ...error, dataNascimento: e })
                      }
                      onChange={(value, { day, month, year, isValid }) => {
                        setDataNascimento(value);
                        setDataNascimentoValida(isValid);
                        delete error.dataNascimento;
                      }}
                    />
                  </div>
                </section>

                <hr className="spacing hr-d-color" style={{ opacity: 0.2 }} />

                <section id="identidade">
                  <span className="d-text-color subtitle d-block spacing">
                    Identidade
                  </span>

                  {showDocumentsNationality(nacionalidade)}
                </section>

                <hr className="hr-d-color" style={{ opacity: 0.2 }} />

                <Grid container className="spacing-x">
                  <Grid item md={12} style={{ marginBottom: 15 }}>
                    <Typography
                      variant="body1"
                      className="text-bold text-st text-primary"
                    >
                      Telefones
                    </Typography>
                  </Grid>
                  {telefones &&
                    telefones.map((telefone, index) => {
                      return (
                        <Grid item md={12}>
                          <Telefone
                            key={`telefone-componente-${index}`}
                            error={error.telefones ? true : false}
                            helperText={error.telefones ? error.telefones : ""}
                            index={index}
                            tipo={telefone.observation}
                            telefone={telefone.value}
                            onRemove={(e) => {
                              delete error.telefones;

                              let new_telefones = [...telefones];
                              new_telefones.splice(index, 1);
                              setTelefones(new_telefones);
                            }}
                            onUpdate={(dados) => {
                              delete error.telefones;

                              let new_telefones = [...telefones];
                              new_telefones[index] = {
                                observation: dados.tipo,
                                value: dados.telefone,
                              };
                              setTelefones(new_telefones);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  <Grid item md={12} style={{ marginTop: 24 }}>
                    <DButton
                      onClick={(e) => {
                        delete error.telefones;

                        setTelefones([...telefones, { type: "", value: "" }]);
                      }}
                      variant="ghost"
                      icon={<AddCircle fontSize="small" />}
                      size="md"
                    >
                      Adicionar novo telefone
                    </DButton>
                  </Grid>
                </Grid>

                <hr className="hr-d-color" style={{ opacity: 0.2 }} />

                <section id="endereco" name="endereco" className="spacing-x">
                  <span className="d-text-color d-block subtitle mb-2em">
                    Endereço
                  </span>

                  <div className="d-flex flex-row">
                    <div className="d-flex-1 d-flex mr-2em mb-2em">
                      <DInput
                        label="CEP"
                        type="text"
                        id="cep"
                        error={error.cep}
                        helperText={error.cep}
                        fixedLabel
                        fullWidth={false}
                        onChange={(e) => {
                          let new_cep = mascaraCEP(e.target.value);

                          setCep(new_cep);

                          if (new_cep.length < 10) {
                            setErrors({ ...error, cep: "CEP inválido." });
                          } else {
                            updatedCep(new_cep);
                            delete error.cep;
                          }
                        }}
                        onBlur={(e) => {
                          let new_cep = mascaraCEP(e.target.value);

                          if (new_cep && new_cep.length < 10) {
                            setErrors({ ...error, cep: "CEP inválido." });
                          } else {
                            delete error.cep;
                          }
                        }}
                        positionIcon="end"
                        value={cep}
                      />

                      <span
                        className="align-self-center d-text-color pointer"
                        onClick={(e) =>
                          window.open(
                            "https://buscacepinter.correios.com.br/app/endereco/index.php",
                            "_blank"
                          )
                        }
                      >
                        Não sei o CEP
                      </span>
                    </div>

                    <div className="d-flex-1">
                      <DDropDownSimple
                        id="logradouro"
                        label="Logradouro"
                        placeholder="Logradouro"
                        fullWidth
                        error={error.logradouro}
                        helperText={error.logradouro}
                        value={logradouro && logradouro}
                        options={tiposLogradouro}
                        onChange={(value) => {
                          delete error.logradouro;
                          setLogradouro(value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="endereco-info">
                    <div className="d-flex">
                      <DInput
                        label="Endereço"
                        type="text"
                        // error={true}
                        // success={true}
                        // required={true}
                        fixedLabel
                        className="mr-10 mb-5"
                        disabled={true}
                        fullWidth={true}
                        // onClick={ e => console.log(e.target.value) }
                        onChange={(e) => null}
                        positionIcon="end"
                        value={address && address.street}
                      />

                      <DInput
                        id="numero"
                        label="Número"
                        type="text"
                        error={error.numero}
                        helperText={error.numero}
                        // success={true}
                        // required={true}
                        fixedLabel
                        className="mr-2em"
                        disabled={false}
                        fullWidth={false}
                        // onClick={ e => console.log(e.target.value) }
                        onChange={(e) => {
                          delete error.numero;
                          var num = e.target.value.replace(/[^0-9]/g, "");

                          setAddress({ ...address, number: num });
                        }}
                        positionIcon="end"
                        value={address && address.number}
                      />
                    </div>

                    <DInput
                      label="Complemento (opcional)"
                      type="text"
                      // error={true}
                      // success={true}
                      // required={true}
                      inputProps={{ maxLength: 10 }}
                      disabled={false}
                      fixedLabel
                      fullWidth={false}
                      // onClick={ e => console.log(e.target.value) }
                      onChange={(e) =>
                        setAddress({ ...address, complement: e.target.value })
                      }
                      positionIcon="end"
                      value={address && address.complement}
                    />

                    <DInput
                      label="Bairro"
                      type="text"
                      // error={true}
                      // success={true}
                      // required={true}
                      disabled={true}
                      fixedLabel
                      className="mr-2em mb-5"
                      fullWidth={false}
                      // onClick={ e => console.log(e.target.value) }
                      onChange={(e) => null}
                      positionIcon="end"
                      value={address && address.district}
                    />
                    <DInput
                      label="Município"
                      type="text"
                      // error={true}
                      // success={true}
                      // required={true}
                      disabled={true}
                      fullWidth={false}
                      // onClick={ e => console.log(e.target.value) }
                      onChange={(e) => null}
                      positionIcon="end"
                      value={municipioEndereco && municipioEndereco}
                    />
                    <DInput
                      label="Estado"
                      type="text"
                      // error={true}
                      // success={true}
                      // required={true}
                      disabled={true}
                      fullWidth={false}
                      fixedLabel
                      className="mr-2em mb-5"
                      // onClick={ e => console.log(e.target.value) }
                      onChange={(e) => null}
                      positionIcon="end"
                      value={
                        address && address.city && address.city.state[0].code
                      }
                    />
                    <DInput
                      label="País"
                      type="text"
                      // error={true}
                      // success={true}
                      // required={true}
                      disabled={true}
                      fullWidth={false}
                      // onClick={ e => console.log(e.target.value) }
                      onChange={(e) => null}
                      positionIcon="end"
                      value="Brasil"
                    />
                  </div>
                </section>

                <hr className="hr-d-color" style={{ opacity: 0.2 }} />

                <section id="dados_demograficos" name="dados_demograficos">
                  <span className="d-text-color d-block subtitle spacing-x">
                    Dados demográficos
                  </span>
                  <div className="dados-demograficos spacing-x">
                    <DDropDownSimple
                      label="Sexo"
                      placeholder="Sexo"
                      error={error.sexo}
                      helperText={error.sexo}
                      id="sexo"
                      value={sexo && sexo}
                      options={sexosFormat}
                      onChange={(value) => {
                        setSexo(value);
                        delete error.sexo;
                      }}
                    />
                    <DDropDownSimple
                      id="genero"
                      label="Gênero"
                      placeholder="Gênero"
                      error={error.genero}
                      helperText={error.genero}
                      value={genero && genero}
                      options={generosFormat}
                      onChange={(valueId) => {
                        // let tmp_value = generosFormat.find(item => item.id === valueId);

                        // setGenero(tmp_value && tmp_value.value)
                        delete error.genero;

                        setGenero(valueId);
                      }}
                    />

                    <DDropDownSimple
                      id="orientacao-sexual"
                      label="Orientação sexual"
                      placeholder="Orientação sexual"
                      error={error.orientacaoSexual}
                      helperText={error.orientacaoSexual}
                      value={orientacaoSexual}
                      options={orientacoesSexuaisMap}
                      onChange={(valueId) => {
                        delete error.orientacaoSexual;

                        setOrientacaoSexual(valueId);
                      }}
                    />

                    <DDropDownSimple
                      id="raca"
                      label="Raça"
                      placeholder="Raça"
                      fullWidth
                      error={error.raca}
                      helperText={error.raca}
                      value={raca}
                      options={racasFormat}
                      onChange={(value) => {
                        delete error.raca;

                        if (value && value !== 5) {
                          delete error.etnia;
                          setEtnia(null);
                        }

                        setRaca(value);
                        if (value !== "")
                          setRacaDescription(
                            racasFormat.filter((res) => res.id === value)[0]
                          );
                        else setRacaDescription("");
                      }}
                    />

                    {etnias.length > 0 ? (
                      <DAutoComplete
                        id="etnia"
                        label="Etnia"
                        placeholder="Etnia"
                        fullWidth
                        disabled={
                          racaDescription !== ""
                            ? racaDescription.value !== "Indígena"
                            : true
                        }
                        error={error.etnia}
                        helperText={error.etnia}
                        // value={ etnias[0] }
                        value={
                          etnia && etnias.find((res) => res.id == Number(etnia))
                        }
                        options={etnias}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id == value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.etnia;

                          setEtnia(newValue ? newValue.id : "");
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </section>

                <section>
                  {(Array.isArray(conselhosDeClasse) &&
                    conselhosDeClasse.length) ||
                  (conselhosDeClasse && conselhosProfile.length > 0) ? (
                    <span
                      className="subtitle d-text-color"
                      id="dados_profissionais"
                    >
                      Conselhos de classe
                    </span>
                  ) : (
                    ""
                  )}

                  {documentType.length > 0 &&
                    conselhosDeClasse &&
                    conselhosDeClasse.map((conselho, index) => {
                      let document = documentType.find(
                        (res) => res.description === conselho["type"]
                      );

                      let tipo_id;
                      let codigo_id;
                      let numero_id;
                      let cbo_id;
                      let uf_id;

                      if (document) {
                        tipo_id = document.document_type_fields.find(
                          (res) => res.description === "tipo"
                        )
                          ? document.document_type_fields.find(
                              (res) => res.description === "tipo"
                            ).id
                          : null;
                        codigo_id = document.document_type_fields.find(
                          (res) => res.description === "codigo"
                        )
                          ? document.document_type_fields.find(
                              (res) => res.description === "codigo"
                            ).id
                          : null;
                        numero_id = document.document_type_fields.find(
                          (res) => res.description === "numero"
                        )
                          ? document.document_type_fields.find(
                              (res) => res.description === "numero"
                            ).id
                          : null;
                        cbo_id = document.document_type_fields.find(
                          (res) => res.description === "cbo"
                        )
                          ? document.document_type_fields.find(
                              (res) => res.description === "cbo"
                            ).id
                          : null;
                        uf_id = document.document_type_fields.find(
                          (res) => res.description === "uf"
                        )
                          ? document.document_type_fields.find(
                              (res) => res.description === "uf"
                            ).id
                          : null;
                      }

                      return (
                        <div
                          className="spacing-x mb-10 conselhos-de-classe"
                          key={index}
                        >
                          <div className="conselho d-flex flex-row">
                            <div className="d-flex-1">
                              <DAutoComplete
                                // id={ `CBO-${i}` }
                                label="Conselho de classe"
                                type="text"
                                fullWidth
                                disabled
                                // error={ error.conselhos }
                                // helperText={ error.conselhos }
                                defaultValue={{
                                  id: null,
                                  value: conselho["fields"].find(
                                    (x) => x.document_type_field_id == tipo_id
                                  )
                                    ? conselho["fields"].find(
                                        (x) =>
                                          x.document_type_field_id == tipo_id
                                      ).value
                                    : null,
                                }}
                                // defaultValue={ conselhoCbos.filter(x => x.value === conselho['fields'].find(x => x.document_type_field_id === 21).value)[0] }
                                options={conselhosFormat}
                                getOptionLabel={(option) => {
                                  return option.value;
                                }}
                                getOptionSelected={(option, value) => {
                                  return option.id === value.id;
                                }}
                                onChange={(event, newValue) => {
                                  setConselhosDeClasse((prevState) => {
                                    var _temp = prevState;
                                    // console.log(_temp[index], conselhosDeClasse, conselhoCbos)
                                    _temp[index].fields.find(
                                      (x) => x.document_type_field_id == tipo_id
                                    ).value = newValue.value;
                                    return [...prevState];
                                  });
                                }}
                              />
                            </div>
                            <div className="ml-1em grid grid-column-2">
                              <DInput
                                label="Número de registro"
                                type="text"
                                error={error.documents}
                                helperText={error.documents}
                                // error={true}
                                // success={true}
                                fixedLabel
                                // required={true}
                                className="mr-1em"
                                // disabled={true}
                                fullWidth={false}
                                inputProps={{ maxLength: 10 }}
                                // onClick={ e => console.log(e.target.value) }
                                onChange={(e) => {
                                  setConselhosDeClasse((prevState) => {
                                    var _temp = prevState;

                                    var _value = String(e.target.value).replace(/\D/g, "");

                                    _temp[index].fields.find(
                                      (x) =>
                                        x.document_type_field_id == numero_id
                                    ).value = _value;
                                    return [...prevState];
                                  });
                                }}
                                positionIcon="end"
                                value={
                                  conselho &&
                                  conselho.fields.find(
                                    (x) => x.document_type_field_id == numero_id
                                  )
                                    ? conselho.fields.find(
                                        (x) =>
                                          x.document_type_field_id == numero_id
                                      ).value
                                    : null
                                }
                              />
                              <DAutoComplete
                                // id={ `CBO-${i}` }
                                label="UF"
                                type="text"
                                fullWidth
                                error={error.documents}
                                helperText={error.documents}
                                // error={ error.conselhos }
                                // helperText={ error.conselhos }
                                defaultValue={{
                                  id: null,
                                  value: conselho["fields"].find(
                                    (x) => x.document_type_field_id == uf_id
                                  )
                                    ? conselho["fields"].find(
                                        (x) => x.document_type_field_id == uf_id
                                      ).value
                                    : null,
                                }}
                                // defaultValue={ conselhoCbos.filter(x => x.value === conselho['fields'].find(x => x.document_type_field_id === 21).value)[0] }
                                options={estados}
                                getOptionLabel={(option) => {
                                  return option.value;
                                }}
                                getOptionSelected={(option, value) => {
                                  return option.id === value.id;
                                }}
                                onChange={(event, newValue) => {
                                  setConselhosDeClasse((prevState) => {
                                    var _temp = prevState;
                                    // console.log(_temp[index], conselhosDeClasse, conselhoCbos)

                                    _temp[index].fields.find(
                                      (x) => x.document_type_field_id == uf_id
                                    ).value =
                                      newValue !== null ? newValue.value : null;

                                    return [...prevState];
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-block">
                            <DAutoComplete
                              // id={ `CBO-${i}` }
                              label="Código e descrição CBO"
                              placeholder="Código e descrição CBO"
                              fullWidth
                              error={error.documents}
                              helperText={error.documents}
                              // error={ error.conselhos }
                              // helperText={ error.conselhos }
                              defaultValue={{
                                id: null,
                                value: conselho["fields"].find(
                                  (x) => x.document_type_field_id == cbo_id
                                )
                                  ? conselho["fields"].find(
                                      (x) => x.document_type_field_id == cbo_id
                                    ).value
                                  : null,
                              }}
                              // defaultValue={ conselhoCbos.filter(x => x.value === conselho['fields'].find(x => x.document_type_field_id === 21).value)[0] }
                              options={conselhoCbos}
                              getOptionLabel={(option) => {
                                return option.value;
                              }}
                              getOptionSelected={(option, value) => {
                                return option.id === value.id;
                              }}
                              onChange={(event, newValue) => {
                                setConselhosDeClasse((prevState) => {
                                  var _temp = prevState;
                                  // console.log(_temp[index], conselhosDeClasse, conselhoCbos)
                                  _temp[index].fields.find(
                                    (x) => x.document_type_field_id == cbo_id
                                  ).value =
                                    newValue !== null ? newValue.value : null;
                                  return [...prevState];
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}

                  <>
                    {conselhosDeClasse &&
                      conselhosProfile &&
                      conselhosProfile.map((conselhoProfile, index) => {
                        if (
                          !conselhosDeClasse.find(
                            (res) => res.type === conselhoProfile
                          )
                        )
                          return (
                            <Conselhos
                              key={`conselhos-${index}`}
                              error={error.documents}
                              onChange={(dados) => {
                                let new_conselhos = [...novosConselhos];
                                new_conselhos[index] = { ...dados };

                                // isMedicalProfile(new_conselhos);

                                setNovosConselhos(new_conselhos);
                              }}
                              conselhosFormat={conselhosFormat}
                              estados={estados}
                              conselhoCbos={conselhoCbos}
                              conselho={conselhoProfile}
                            />
                          );
                      })}
                  </>
                </section>

                <section>
                  <div className="speciality mb-2em">
                    {isMedico ? (
                      <>
                        <span className="d-block">Especialidades</span>

                        <DMultAutoComplete
                          placeholder="Adicione ou remova especialidades"
                          labelColor="black"
                          label="Adicione ou remova especialidades"
                          error={error.especialidades}
                          helperText={error.especialidades}
                          // value={ _especialidades.length > 0 ? _especialidades.filter(res => ) : [] }
                          value={
                            especialidades && especialidades.length
                              ? especialidades.filter((res) =>
                                  _especialidades.find(
                                    (item) => item.id == res.id
                                  )
                                )
                              : []
                          }
                          options={_especialidades}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.especialidades;
                            setEspecialidades(newValue);
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </section>

                <section className="enviar-mudancas">
                  <DButton
                    variant="primary"
                    icon={!loadingUpdateUser ? <Send fontSize="small" /> : ""}
                    size="md"
                    onClick={(e) => {
                      cnsUnique(cns.value, id, localStorage.getItem("token"))
                        .then((res) => {
                          if (!res.status) {
                            let cns_message = res.message;
                            cpfUnique(
                              cpf.value,
                              id,
                              localStorage.getItem("token")
                            )
                              .then((res) => {
                                if (!res.status)
                                  setErrors({
                                    ...error,
                                    cpf: res.message,
                                    cns: cns_message,
                                  });
                                else setErrors({ ...error, cns: cns_message });
                              })
                              .catch((err) => console.log(err));
                          } else {
                            cpfUnique(
                              cpf.value,
                              id,
                              localStorage.getItem("token")
                            )
                              .then((res) => {
                                if (!res.status)
                                  setErrors({ ...error, cpf: res.message });
                                else handleUpdateUser();
                              })
                              .catch((err) => console.log(err));
                          }
                        })
                        .catch((err) => console.log(err));
                    }}
                  >
                    {loadingUpdateUser ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      "Enviar mudanças"
                    )}
                  </DButton>
                </section>
              </article>
            </main>
          </div>
        </>
      )}
    </>
  );
}

export default MeuPerfil;
