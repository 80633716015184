import axios from "axios";
import env from "react-dotenv";

class Configuration {

    async get(token){
        try {

            const {data} = await axios.get(`${env.API_URL}/configuration?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async putEnableOrDisableRecording(token, id, body){
        try {

            const {data} = await axios.put(`${env.API_URL}/configuration/${id}?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

}

export default new Configuration();