import React, {useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 14,
      width: 345,
      height: 20,
      padding: '8px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Roboto',
        'sans-serif',
        'Arial',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderColor: "#4A4AE9",
      },
    },
  }))(InputBase);

function Comorbidades(props)
{

    const [checked, setChecked] = useState(props.value.checked);
    const [observation, setObservation] = useState(props.value.observation);

    useEffect(() => {
        setChecked(props.value.checked);
        setObservation(props.value.observation);
    }, [props.value])

    const handleUpdate = (isChecked, obsText) => {
        setChecked(isChecked);
        setObservation(obsText);
        props.onUpdate({ checked: isChecked, observation: obsText })
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={ e => handleUpdate(!checked, observation) }
                            name={ props.label }
                            color="primary"
                        />
                    }
                    label={ props.label } />
            </Grid>
            { checked &&
                <Grid item md={12} container>
                    <Grid item md={1}>&nbsp;</Grid>
                    <Grid item md={11}>
                        <BootstrapInput 
                            placeholder="Observações" 
                            value={ observation }
                            onChange={e => handleUpdate(checked, e.target.value) } />
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

Comorbidades.defaultProps = {
    label: 'Comorbidade',
    value: {
        id: null,
        checked: false,
        observation: ""
    },
    onUpdate: function(isChecked, obsText) {
        console.log('onUpdate');
        console.log({isChecked, obsText})
    }
}

export default Comorbidades;