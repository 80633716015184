import { useState, useEffect } from "react";
import { Grid, Typography, Card, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, useTheme, Button } from "@material-ui/core"
import HistoryIcon from '@material-ui/icons/History';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import { Bio } from "./../../../api/biomedidas";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    card_subtitle: {
        color: "#272727",
        fontSize: 14,
        margin: "0 0 16px"        
    },
    card: {
        padding: 16,
        marginBottom: 0,
        position: "relative",
        width: 288
    },
    cardHistory: {
        padding: 16,
        marginBottom: 0,
        position: "relative",
        width: 288,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0
    },
    cardViewMode: {
        padding: "0px 16px 16px 16px",
        marginTop: -2,
        marginBottom: 0,
        position: "relative",
        width: 288,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0
    },
    card_title: {
        color: "#272727",
        fontSize: 16,
        margin: "0 0 0px"
    },

    card_subtitle: {
        color: "#272727",
        fontSize: 14,
        margin: "0 0 16px"
    },
    card_history: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        color: theme.palette ? theme.palette.primary.light : "#272727"
    },    
    card_more: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        borderRadius: 0
    },    
    contentTop: {
        marginBottom: 10
    },
    removeItem: {
        color: theme.palette ? theme.palette.support.error : "#272727"
    }
}));

function BiomedidaCard(props)
{
    const classes = useStyles();
    const theme = useTheme()
    const [edit, setEdit] = useState(true);
    const [history, setHistory] = useState(false);
    const [biomedidasHistory, setBiomedidasHIstory] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {

        if(props.profile && props.biomedida && props.biomedida.biomeasure_exam.biomeasure_exam_profile.find(profile => profile.profile_id == props.profile))
            setEdit(true);    
        else
            setEdit(false);
    }, [props.profile]);

    useEffect(() => {
        if(history) {
            Bio.getByPatient(localStorage.getItem('token'), props.patient).then(response => {
                if(response.data.status) {
                    let nova_biomedidas = response.data.biomeasure_exam_health_attendances.filter( biomedidas => biomedidas.biomeasure_exam_id === props.biomedida.biomeasure_exam_id && biomedidas.id !== props.biomedida.id );

                    if(nova_biomedidas.length <= 0)
                        toast.warn("Nenhum histórico encontrado.");

                    setBiomedidasHIstory(nova_biomedidas);
                }
            })
        } else {
            setBiomedidasHIstory([]);
        }
    }, [history]);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) => {

        setAnchorEl(null);
        props.onAction(action);
    };

    return (
        <>
            { props.biomedida &&    
                <div className='box_bio_exames'>
                    { !props.viewMode ?
                        <>
                            <div className='flex_bio_exames'>
                                <div className='fonte_titulo_bio_exames'>
                                    { props.biomedida.biomeasure_exam.name }
                                </div>
                                <div className='flex_end_bio_exames'>
                                    { props.biomedida.biomeasure_exam.show_history === 1 &&
                                        <div>
                                            <Button onClick={ e => setHistory(!history) } className="icon_history_bio_exames text-primary" startIcon={<HistoryIcon />} />
                                        </div>
                                    }       
                                    { edit &&                                         
                                        <div>
                                            <Button
                                                startIcon={<MoreHorizIcon className='icon_dot_bio_exames' />}
                                                aria-controls={open ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                            >
                                            </Button>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                                }}
                                            >
                                                <MenuItem onClick={ e => handleClose('edit')}>
                                                    <EditIcon style={{ color: '#006FB5', marginRight: '10px', fontSize: "18px" }} />
                                                    Editar
                                                </MenuItem>
                                                <MenuItem onClick={ e => handleClose('remove') }>
                                                    <DeleteIcon style={{ color: '#FF6B57', marginRight: '10px', fontSize: "18px" }} />
                                                    Excluir
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                        :
                        <div className='flex_bio_exames'>
                            <div className='fonte_titulo_bio_exames'>
                                <Grid container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <CalendarTodayIcon style={{color: theme.palette.primary.light }} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className="text-regular text-mt">Data: { props.biomedida.date_measurement }</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    }

                    <div className='fonte_valor_bio_exames mt10_new_tabs'>
                        <Grid container spacing={1} alignItems="flex-end">
                            { props.biomedida.biomeasure_exam_health_attendance_value.filter(value => value.biomeasure_exam_field !== null).map((value, index) => {
                                return <Grid key={ `view-field-type-${index}` } item>
                                    { value.biomeasure_exam_field.show_label === 1 && <h6 className={ classes.card_subtitle }>{ value.biomeasure_exam_field.label }</h6> }
                                    <Typography style={{ fontSize:18, color: "#272727", fontWeight: 400 }}>
                                        { value.value.replace(";", " - ") } { value.biomeasure_exam_field.show_measure === 1 ? value.biomeasure_exam_field.measure : "" }
                                    </Typography>
                                </Grid> 
                            }) }    
                        </Grid> 
                    </div>
                </div>
            }        

            { history && <>
                { biomedidasHistory.map((biomedida, index) => {
                    return <BiomedidaCard key={ `biomedida-card-history-${ index }` } last={ (biomedidasHistory.length - 1) === index } viewMode={ true } biomedida={ biomedida } />
                }) }
            </> } 
        </>
    )
}

BiomedidaCard.defaultProps = {
    last: false,
    viewMode: false,
    profile: null,
    patient: null,
    biomedida: null,    
    onAction: function(action) {
        console.log(action)
    }
}

export default BiomedidaCard;