import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./index.scss";

// Toast
import { toast } from "react-toastify";

// Routes
import { getUserInfoById } from '../../../api/user';
import { auth } from '../../../api/chatService';

export default function ModalCall(props) {
  // States
  const [open, setOpen]               = useState(false);
  const [nome, setNome]               = useState('');
  const [cpf, setCpf]                 = useState('');
  const [telefone, setTelefone]       = useState('');
  const [tokenJWT, setTokenJWT]       = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [srcCall, setSrcCall]         = useState('');

  useEffect( async () => {
    if (props.open && !chatStarted) {
      await getUserInfoById(localStorage.getItem("token"), localStorage.getItem("uid")).then( async (res) => {
        if (res.error) {
          toast.error("Ocorreu um erro ao abrir a chamada");
          console.log(res.error);
        } else {
          var json = {
            app: "telemed-sp-dev", 
            chave: "ec3a2fa3-9c60-4048-bd25-50ad8bd674a0", 
            usuario: res.document.find(res => res.type == 'CPF').fields[0].value,
            nome: res.user.name,
            celular: "+55" + res.contact.find(res => res.type == 'telefone').value.replace(/[^0-9]/g, ""),
            perfil: "usuario"
          } 

          if (json.usuario && json.celular && json.nome) {
            await auth(json).then( async (res) => {
              if (res.status_ret != 'NOK') {
                setNome(res.nome);
                setCpf(res.usuario);
                setTelefone(res.celular);
                setTokenJWT(res.tokenJwt);

                handleOpen();
                openCall(res.tokenJwt);

                setChatStarted(true);
              } else {
                toast.error("Ocorreu um erro ao abrir a chamada");
                console.log(res.erro);
              }
            }).catch( (err) => {
              toast.error("Ocorreu um erro ao abrir a chamada");
              console.log(err);
            });
          }
        }
      }).catch( (err) => {
        toast.error("Ocorreu um erro ao abrir a chamada");
        console.log(err);
      });
    } else if (props.open && chatStarted) {
      handleOpen();
      openCall(tokenJWT);
    }
  }, [props.open]);

  useEffect(() => {
    window.addEventListener('message', (e) => receiveMessage(e));
  }, []);

  const receiveMessage = (e) => {
    if (e.data.processo == 'encerramentoChamada') {
      handleClose(); 
      props.setOpenChat(true);
    } else {
      handleClose(); 
      props.setCloseModal(true);
    }
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function openCall(tokenJWT) {
    setSrcCall(
      `https://appwebchatduodoctorspdev.z15.web.core.windows.net/videocall.html?token=${tokenJWT}&protocolo=${props.dadosCall.chaveConversa}&tipoParticipante=1&isVideo=S&comHeader=N&nomeCall=${encodeURIComponent(props.dadosChat.type != 'usuario' ? 'Grupo: ' : '')}${encodeURIComponent(props.dadosChat.nome)}&tipoCall=${encodeURIComponent(props.dadosCall.tipoChamada == 1 ? 'Sequencial' : props.dadosCall.tipoChamada == 2 ? 'Geral' : props.dadosCall.tipoChamada == 3 ? 'Total' : '')}`
    );
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={e => {handleClose(); props.setCloseModal(true)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className='estilo_modal_call'>
          <div className='flex_call_iframe'>
            <iframe className='tamanho_iframe' src={ srcCall } frameborder="0" allow="camera; microphone; fullscreen; display-capture"></iframe>
          </div>
        </Box>
      </Modal>
    </div>
  );
}