import env from "react-dotenv";

function checkCnpj(token, cnpj) {
  return fetch(
    `${env.API_URL}/medicalunit/check-cnpj?token=${token}&cnpj=${cnpj}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function getUnidade(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getAllUnidades(token) {
  return fetch(`${env.API_URL}/medicalunit?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getAllUnidadesExecutantes(token) {
  return fetch(`${env.API_URL}/medicalunit/executantes?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getAllUnidadesArvore(token) {
  return fetch(`${env.API_URL}/medicalunit/arvore/list?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getAllUnidadesArvoreSearch(token, dados) {
  return fetch(
    `${env.API_URL}/medicalunit/arvore/list?token=${token}&search=${dados.search}&tipo=${dados.tipo}&medical_unit_id=${dados.medical_unit_id}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function getDominios(token) {
  return fetch(`${env.API_URL}/medicalunit/dominios?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function storeMedicalUnit(token, dados) {
  var resquest = {
    medicalUnit: {
      medical_unit_type: dados.typeMedicalUnit,
      company_name:
        dados.typeMedicalUnit === "Assistencial"
          ? dados.name
          : dados.companyName,
      establishment_type: dados.establishmentType,
      phone_number: dados.phone,
      email: dados.email,
      below_mother_medical_unit:
        dados.belowMotherMedicalUnit === "Sim" ? "yes" : "no",
      mother_medical_unit:
        dados.belowMotherMedicalUnit === "Sim" ? "yes" : "no",
      mother_medical_unit_id:
        dados.motherMedicalUnit !== "null" ? dados.motherMedicalUnit : null,
      business_name: dados.businessName,
      cnes: dados.cnes,
      cnpj: dados.cnpj,
      below_social_medical_unit: dados.vinculoOrganizacaoSocial,
      social_medical_unit_id: dados.socialMedicalUnit
    },
    address: {
      zip_code: dados.cep,
      address_name: dados.addressType,
      street: dados.address,
      number: dados.number,
      complement: dados.complement,
      district: dados.district,
      city_id: dados.cityId,
      state_id: dados.stateId,
    },
    executingMedicalUnitField: [
      {
        description: dados.funcao,
      },
    ],
    medicalUnitProfile: dados.profiles.map(function (item) {
      return { profile_id: item, description: "N/A" };
    }),
    medicalUnitAnamneseForm:
      dados.anamneses !== "null"
        ? dados.anamneses.map(function (item) {
            return { anamnese_form_id: item };
          })
        : [],
    executants:
      dados.executingMedicalUnits !== "null"
        ? dados.executingMedicalUnits
        : null,
  };

  return fetch(`${env.API_URL}/medicalunit?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(resquest),
  }).then((res) => res.json());
}

function updateMedicalUnitProfile(token, profiles, id) {
  return fetch(
    `${env.API_URL}/medicalunit/${id}/update-profile?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify({ profiles }),
    }
  ).then((res) => res.json());
}

function updateMedicalUnitAnamneses(token, anamneses, id) {
  return fetch(
    `${env.API_URL}/medicalunit/${id}/update-anamnese?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify({ anamneses }),
    }
  ).then((res) => res.json());
}

function updateMedicalUnit(token, dados) {
  var resquest = {
    medicalUnit: {
      medical_unit_type: dados.typeMedicalUnit,
      company_name:
        dados.typeMedicalUnit === "Assistencial"
          ? dados.name
          : dados.companyName,
      establishment_type: dados.establishmentType,
      phone_number: dados.phone,
      email: dados.email,
      business_name: dados.businessName,
      cnes: dados.cnes,
      cnpj: dados.cnpj,
    },
    address: {
      zip_code: dados.cep,
      address_name: dados.addressType,
      street: dados.address,
      number: dados.number,
      complement: dados.complement,
      district: dados.district,
      city_id: dados.cityId,
      state_id: dados.stateId,
    },
  };

  return fetch(`${env.API_URL}/medicalunit/${dados.id}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(resquest),
  }).then((res) => res.json());
}

function updateMedicalUnitLink(token, dados) {
  var resquest = {
    medicalUnit: {
      below_mother_medical_unit: dados.belowMotherMedicalUnit === "Sim" ? "yes" : "no",
      mother_medical_unit: dados.belowMotherMedicalUnit === "Sim" ? "yes" : "no",
      mother_medical_unit_id: dados.motherMedicalUnit !== "null" ? dados.motherMedicalUnit : null,
      below_social_medical_unit: dados.vinculoOrganizacaoSocial,
      social_medical_unit_id: dados.socialMedicalUnit
    },
    executingMedicalUnitField: [
      {
        description: dados.funcao,
      },
    ],
    executants:
      dados.executingMedicalUnits !== "null"
        ? dados.executingMedicalUnits
        : null,
  };

  return fetch(
    `${env.API_URL}/medicalunit/${dados.id}/update-link?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(resquest),
    }
  ).then((res) => res.json());
}

function validateCnes(token, cnes, id = undefined) {
  var url = `/medicalunit/validate-cnes${id !== undefined ? `/${id}` : ""}`;

  return fetch(`${env.API_URL}${url}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      cnes,
    }),
  }).then((res) => res.json());
}

function validateCNPJ(token, cnpj, id = undefined) {
  var url = `/medicalunit/validate-cnpj${id !== undefined ? `/${id}` : ""}`;

  return fetch(`${env.API_URL}${url}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify({
      cnpj,
    }),
  }).then((res) => res.json());
}

function deleteMedicalUnit(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

function getMedicalUnitPermission(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/list-permission?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function enableMedicalUnitPermissionCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-enabled?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function disableMedicalUnitPermissionCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-enabled?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

function enableMedicalUnitPermissionSequentialCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/sequential-call?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function disableMedicalUnitPermissionSequentialCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/sequential-call?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

function enableMedicalUnitPermissionGeneralGroup(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-in-general-group?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function disableMedicalUnitPermissionGeneralGroup(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-in-general-group?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

function enableMedicalUnitPermissionTotalGroup(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-in-total-group?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function disableMedicalUnitPermissionTotalGroup(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/call-in-total-group?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

function enableMedicalUnitPermissionDirectCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/direct-call?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function disableMedicalUnitPermissionDirectCall(token, id) {
  return fetch(`${env.API_URL}/medicalunit/${id}/direct-call?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

export {
  getAllUnidades,
  getAllUnidadesExecutantes,
  getAllUnidadesArvore,
  checkCnpj,
  getAllUnidadesArvoreSearch,
  getDominios,
  storeMedicalUnit,
  getUnidade,
  deleteMedicalUnit,
  updateMedicalUnit,
  updateMedicalUnitProfile,
  updateMedicalUnitAnamneses,
  updateMedicalUnitLink,
  validateCnes,
  validateCNPJ,
  getMedicalUnitPermission,
  enableMedicalUnitPermissionCall,
  disableMedicalUnitPermissionCall,
  enableMedicalUnitPermissionSequentialCall,
  disableMedicalUnitPermissionSequentialCall,
  enableMedicalUnitPermissionGeneralGroup,
  disableMedicalUnitPermissionGeneralGroup,
  enableMedicalUnitPermissionTotalGroup,
  disableMedicalUnitPermissionTotalGroup,
  enableMedicalUnitPermissionDirectCall,
  disableMedicalUnitPermissionDirectCall
};
