import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import Clear from "@mui/icons-material/Clear";
import DownloadIcon from '@mui/icons-material/Download';
import FormControl from '@mui/material/FormControl';
import { Divider, InputAdornment, OutlinedInput, TextField, Grid, makeStyles, useTheme, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from "@mui/material/FormHelperText"
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllMedicalSpecialties } from '../../../../../../api/medicalSpecialty';
import { listUserByMedicalSpeciality } from '../../../../../../api/user';
import { getAnamneseFormInterconsultation } from '../../../../../../api/anamnese';
import { storeInterconsultationByHealthAttendance, getHealthAttendanceByPageFilter } from '../../../../../../api/health_attendances';
import { useContext } from 'react';
import { AppContext } from '../../../../../../context/AppContext';
import { useNewHealthAttendance } from '../../../../../../context/NewHealthAttendance';
import { mascaraCNES } from '../../../../../../helpers/formatacao';
import { required } from '../../../../../../helpers/validacoes';
import DAutoComplete from "./../../../../../shared/DAutoComplete";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import useError from '../../../../../../hooks/useError';

const useStyles = makeStyles({
  autocomplete_input: {
    padding: "0 9px !important"
  },
  icon_open: {
    transform: "rotate(0deg)"
  },
  title_toast: {
    color: "#006FB5"
  }
});

function ModalInterconsulta(props) {
  const classes = useStyles();
  const theme = useTheme()
  const { healthAttendance, setHealthAttendance } = useNewHealthAttendance();
  const { userInfo } = useContext(AppContext);

  const [specialty, setSpecialty] = useState(null)
  const [specialties, setSpecialties] = useState([])
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [anamneseForm, setAnamneseForm] = useState([]);
  const [healthAttendanceAnamneseForm, setHealthAttendanceAnamneseForm] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const [error, validarFormulario] = useError({
    healthAttendanceAnamneseForm: required,
    selectedDoctor: required,
    specialty: required
  });

  const handleClose = () => props.onClose(true);

  useEffect(() => {
    getAllMedicalSpecialties(localStorage.getItem('token')).then(res => {
      if (res && res.length) {
        setSpecialties(res);
      }
    });
  }, []);

  async function listaMedicos(idSpeciality) {

    listUserByMedicalSpeciality(localStorage.getItem('token'), userInfo.unidade, idSpeciality).then(res => {
      if (res.status) {
        setDoctorsList(res.medicalUnitExecutant)
        getAnamneseFormInterconsultation(localStorage.getItem('token'), idSpeciality).then(res => {
          if (res) {
            setAnamneseForm(res.anamnese_forms);
            if (res.anamnese_forms.length === 0)
              toast.warn("Nenhum formulário de interconsulta encontrado.")
          } else
            toast.error("Erro ao consultar formulários de interconsulta.")
        })
      }
    });
  }

  const InterconsultToast = ({ onView, closeToast, protocolo }) => {
    return (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography color="primary" variant="h5" component="h3" classes={{ colorPrimary: classes.title_toast }}>Interconsulta solicitada</Typography>
            </Grid>
            <Grid item>
              <Clear fontSize="small" onClick={closeToast} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1" className="text-regular text-st">A sua interconsula gerou um protocolo #{protocolo}</Typography>
        </Grid>
        <Grid item md={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Typography onClick={onView} variant="body1" className="text-regular text-st text-link">Verificar solicitação</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };


  const handleConvidar = () => {

    storeInterconsultationByHealthAttendance(localStorage.getItem('token'), healthAttendance.id, healthAttendanceAnamneseForm.id, selectedDoctor).then(res => {
      if (res.status) {
        toast(<InterconsultToast
          protocolo={res.health_attendance_invite.health_attendance_id}
          onView={() => {
            window.location = "/solicitacao"
          }} />, {
          closeButton: false,
          theme: 'light',
          autoClose: 15000,
          hideProgressBar: true
        }
        );

        props.onClose(true)
      }
    });
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={form => {
          form.preventDefault();
          if (validarFormulario({ healthAttendanceAnamneseForm, selectedDoctor, specialty })) {
            handleConvidar();
          } else {
            toast.warn("Preencha todos os campos obrigatórios.")
            console.log(error)
          }
        }}>
          <Box sx className='estilo_modal_inter estilo_modal_inter_interconsulta'>
            <div className='flex_modal_inter'>
              <div className='fonte_titulo_modal_inter'>
                Solicitar Interconsulta
              </div>
              <div className='flex_end_modal_inter'>
                <Button onClick={handleClose}>
                  <CloseIcon className='icone_modal_inter' />
                </Button>
              </div>
            </div>
            <div className='box_modal_inter'>
              <div>
                <Autocomplete
                  id="especialidades"
                  options={specialties.filter(item => item.active && item)}
                  getOptionLabel={option => option.description}
                  renderInput={(params) => (
                    <TextField {...params} error={error.specialty} margin="normal" variant="outlined" placeholder="Escolha aqui a especialidade" />
                  )}
                  popupIcon={<SearchIcon style={{ color: theme.palette.primary.light }} />}
                  classes={{
                    inputRoot: classes.autocomplete_input,
                    popupIndicatorOpen: classes.icon_open
                  }}
                  onChange={(event, newValue) => {
                    delete error.specialty;
                    setSpecialty(newValue ? newValue.id : null);
                    listaMedicos(newValue ? newValue.id : null);
                  }}
                />
                {(doctorsList && doctorsList.length > 0) && <DAutoComplete
                  id="anamnese-from"
                  label="Selecione o formulário de anamnese"
                  placeholder="Formulário de anamnese"
                  fullWidth
                  error={error.healthAttendanceAnamneseForm}
                  helperText={error.healthAttendanceAnamneseForm}
                  options={anamneseForm.length > 0 ? anamneseForm.map(function (item) {
                    return { id: item.id, value: item.name };
                  })
                    : []}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  value={healthAttendanceAnamneseForm}
                  onChange={(event, newValue) => {
                    delete error.healthAttendanceAnamneseForm;
                    setHealthAttendanceAnamneseForm(newValue);
                  }}
                />}
              </div>
              <div className='mt20px'>
                <div className='fonte_titulo_modal_inter'>
                  Aqui estão os médicos disponíveis
                </div>
              </div>
              <div className='fonte_padrao_inter mt20px'>
                Selecione o médico que deseja:
              </div>
              <div className='flex_seletor_modal_inter'>
                <Button className='seletor_modal_inter'>
                  <CompareArrowsIcon className='icon_seletor_modal_inter' />
                  Qualquer médico
                </Button>
              </div>
              <div className='box_medicos_modal_inter'>
                <FormControl component="fieldset" error={error.selectedDoctor} className={`${error.selectedDoctor ? "form-control-error" : ""}`}>
                  <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    {doctorsList.length
                      ?
                      doctorsList.map(doctor => {
                        return (
                          <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px 8px 4px" }}>
                            <div style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                              <Radio
                                checked={selectedDoctor == doctor.id}
                                onClick={e => setSelectedDoctor(doctor.id)}
                                value={doctor.id}
                                name="medico"
                                style={{ color: "#4a4ae9" }}
                              />
                              <Typography style={{ fontSize: 12 }}>
                                {doctor.full_name}<br />
                                <strong>Unidade:</strong> {doctor.unidade}
                              </Typography>
                            </div>
                            <div>
                              <Typography style={{ fontSize: 12 }}>
                                <strong>CNES:</strong> {mascaraCNES(doctor.cnes)}
                              </Typography>
                            </div>
                          </li>
                        )
                      })
                      :
                      <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px 8px 15px" }}>
                        Não existem médicos disponíveis
                      </li>
                    }
                  </ul>
                  {error.selectedDoctor ? <FormHelperText>{error.selectedDoctor}</FormHelperText> : ''}
                </FormControl>
              </div>
            </div>
            <div className='alinha_botao_modal_inter mt50_modal_inter'>
              <Button className='botao_baixar_inter' type="submit">
                <PersonAddIcon />
                Convidar médico
              </Button>
            </div>
          </Box>

        </form>
      </Modal>
    </div>
  );
}

ModalInterconsulta.defaultProps = {
  onReset: function () {
    console.log('onReset')
  }
}

export default ModalInterconsulta;