import React, { useEffect } from 'react';
// import "../index.scss";
// import { TextField } from "@material-ui/core";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// import DButton from '../../DButton';
// import { Button, Grid, Divider } from '@material-ui/core';
import {userDominios as getDominios} from '../../../../api/dominios';
import {getAllUnidades} from '../../../../api/unidades';
// import { Create } from '@material-ui/icons';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormLabel from '@mui/material/FormLabel';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import Checkbox from '@mui/material/Checkbox';
// import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import DetailsMain from "../../../Paciente/components/PacienteDetails/components/DetailsMain"
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';



export default function DadosPrincipaisNewTab(props) {
  const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();
  const [userDominios, setUserDominios] = React.useState(null);
  const [unidadesRef, setUnidadesRef] = React.useState(null);
  const [unidadesVinculadas, setUnidadesVinculadas] = React.useState(null);

  useEffect(() => {
    // getDominios();
    getDominios().then(data => {
      console.log(data)
      setUserDominios(data);
    })
    .catch(err => {
      console.log(err)
    })

    getAllUnidades(localStorage.getItem("token")).then((res) => {
      var unidadesRef = [];
      var unidadesVinculadas = [];

      res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == "no") {
            unidadesRef.push(item);
          } else {
            unidadesVinculadas.push(item);
          }
        });

      setUnidadesRef(unidadesRef);
      setUnidadesVinculadas(unidadesVinculadas);
    });
  }, []);

  if(!userDominios || !patient || !unidadesRef || !unidadesVinculadas)
    return <div>Carregando paciente...</div>


  return <DetailsMain 
    unidadesRef={unidadesRef} 
    unidadesVinculadas={unidadesVinculadas} 
    userDominios={userDominios} 
    paciente={{patient: {...patient}}}
    />
  
  // return (
  //   <div className='fundo_new_tab'>
  //     <div className='main_flex_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Dados principais
  //       </div>
  //       <div className='flex_end_new_tab'>
  //         <Button className="botao_editar_new_tab" startIcon={<Create />}>
  //           Editar
  //         </Button>
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_2_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Nacionalidade</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_2_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="CPF" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Email" variant="standard" />
  //         </div>
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="N.R" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Data de nascimento" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Nome Completo" variant="standard" />
  //         </div>
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Sexo biológico</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Se identifica com o sexo biológico?</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Orientação Sexual</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Endereço
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Pais" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="CEP" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Logradouro" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Endereço" variant="standard" />
  //         </div>
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Numero" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Complemento" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Bairro" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">UF</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Município</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Telefones
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Telefone" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Tipo de telefone" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Apelido" variant="standard" />
  //       </div>
  //       <div>
  //         <Button className="botao_deletar_new_tab" startIcon={<DeleteForeverIcon className='size_icon_new_tab' />} />
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Telefone" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Tipo de telefone" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Apelido" variant="standard" />
  //       </div>
  //       <div>
  //         <Button className="botao_deletar_new_tab" startIcon={<DeleteForeverIcon className='size_icon_new_tab' />} />
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <Button className="botao_add_telefone_new_tab" startIcon={<AddCircleIcon />}>
  //         Adicionar telefone
  //       </Button>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Identidade
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Numero do RNE" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Data de emissão" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Orgão Emissor</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">UF</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Nome da mãe" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <div className='checkbox_new_tab'>
  //             <Checkbox className='checkbox_new_tab' />
  //             Mãe desconhecida
  //           </div>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Nome do pai" variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <div className='checkbox_new_tab'>
  //             <Checkbox className='checkbox_new_tab' />
  //             Pai desconhecido
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Carteira nacional de Saúde
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="CNS Principal " variant="standard" />
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="CNS 2" variant="standard" />
  //         </div>
  //       </div>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Dados demográficos
  //       </div>
  //     </div>
  //     <div className='mt_35_new_tab'>
  //       <div className='separa_inputs_new_tabs'>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">País de nascimento</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Raça</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //         <div className='flex_1_inputs'>
  //           <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //             <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Etnia</InputLabel>
  //             <Select
  //               IconComponent={KeyboardArrowDownIcon}
  //               labelId="demo-simple-select-standard-label"
  //               id="demo-simple-select-standard"
  //               value={age}
  //               onChange={handleChange}
  //               label="Selecione uma opção"
  //             >
  //               <MenuItem value="">
  //                 <em>None</em>
  //               </MenuItem>
  //               <MenuItem value={10}>Ten</MenuItem>
  //               <MenuItem value={20}>Twenty</MenuItem>
  //               <MenuItem value={30}>Thirty</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </div>
  //       </div>
  //     </div>
  //     <div className='main_flex_new_tab mt_35_new_tab'>
  //       <div className='fonte_new_tab'>
  //         Dados complementares
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Número do NIT-PIS-PASEP:" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="População especifica" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Responsável legal" variant="standard" />
  //       </div>
  //       <div className='flex_1_inputs'>
  //         <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Grau de relacionamento" variant="standard" />
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <div>
  //         <FormControlLabel className='radio_color_new_tab' value="end" control={<Radio />} label="Pessoa com deficiência" />
  //       </div>
  //       <div className='gap_icon_chair'>
  //         <AccessibleForwardIcon className='chair_icon_new_tab' />
  //       </div>

  //       <div className='flex_2_inputs'>
  //         <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //           <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Deficiências:</InputLabel>
  //           <Select
  //             IconComponent={KeyboardArrowDownIcon}
  //             labelId="demo-simple-select-standard-label"
  //             id="demo-simple-select-standard"
  //             value={age}
  //             onChange={handleChange}
  //             label="Selecione uma opção"
  //           >
  //             <MenuItem value="">
  //               <em>None</em>
  //             </MenuItem>
  //             <MenuItem value={10}>Ten</MenuItem>
  //             <MenuItem value={20}>Twenty</MenuItem>
  //             <MenuItem value={30}>Thirty</MenuItem>
  //           </Select>
  //         </FormControl>
  //       </div>
  //     </div>
  //     <div className='separa_inputs_new_tabs mt_35_new_tab'>
  //       <FormControlLabel className='radio_color_new_tab' value="end" control={<Radio />} label="Dados incompletos" />
  //       <Grid item md={5}>
  //         <FormControl variant="standard" sx={{ minWidth: '100%' }}>
  //           <InputLabel className='label_input_new_tab' id="demo-simple-select-standard-label">Motivo *:</InputLabel>
  //           <Select
  //             IconComponent={KeyboardArrowDownIcon}
  //             labelId="demo-simple-select-standard-label"
  //             id="demo-simple-select-standard"
  //             value={age}
  //             onChange={handleChange}
  //             label="Selecione uma opção"
  //           >
  //             <MenuItem value="">
  //               <em>None</em>
  //             </MenuItem>
  //             <MenuItem value={10}>Ten</MenuItem>
  //             <MenuItem value={20}>Twenty</MenuItem>
  //             <MenuItem value={30}>Thirty</MenuItem>
  //           </Select>
  //         </FormControl>
  //       </Grid>
  //     </div>
  //   </div>
  // )
}