import {
  Backdrop,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import { getCIAP, getCIPE, getCID10 } from "../../../../../../../../api/diagnosticHypothesis";
import { Box } from "@mui/system";
import DMultAutoComplete from "../../../../../../../shared/DMultAutoComplete";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
}));

const EBDiagnosticHypothesis = ({
  id,
  label,
  title,
  placeholder,
  value,
  helper_text,
  additional_data,
  erro,
  condicional,
  readOnly,
  answer,
  is_required
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();

  const [hypothesis, setHypothesis] = useState();
  const [hypotheses, setHypotheses] = useState();
  const [resultAnswerMap, setResultAnswerMap] = useState();
  const [loadingSearch, setLoadingSearch] = useState(false);


  useEffect(() => {
    setLoadingSearch(true);
    
    if (additional_data.construct.showCIAP) {
      getCIAP(localStorage.getItem("token"))
        .then((res) => {
          if (res.status) {
            setLoadingSearch(false);
            setHypotheses(
              res.ciap.map((item) => {
                return {
                  id: item.id,
                  code: item.cd_ciap,
                  value: `(${item.cd_ciap}) ${item.ds_ciap}`,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setLoadingSearch(false);
        });
    }

    if (additional_data.construct.showCIPE) {
      getCIPE(localStorage.getItem("token"))
        .then((res) => {
           if (res.status) {
            setLoadingSearch(false);
            setHypotheses(
              res.cipe.map((item) => {
                return {
                  id: item.id,
                  code: item.cod,
                  value: `(${item.cod}) ${item.termo}`,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setLoadingSearch(false);
        });
    }

    if (additional_data.construct.showCID10) {
      getCID10(localStorage.getItem("token"))
        .then((res) => {
           if (res.status) {
            setLoadingSearch(false);
            setHypotheses(
              res.cid.map((item) => {
                return {
                  id: item.id,
                  code: item.cid,
                  value: `(${item.cid}) ${item.descricao}`,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          setLoadingSearch(false);
        });
    }

    if(answer && answer.length > 0){
      let answerArray = [];
      if(Array.isArray(answer[0]))
      {
        answer[0].map(function (res) { 
          setHypothesis(res)
          answerArray.push(res);
        })
        setResultAnswerMap(answerArray)
      }
    }
  }, []);
  
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: "1em", display: "flex"}}
        >
          <div dangerouslySetInnerHTML={{ __html: `${label ? label : "Hipótese Diagnóstica"}` }} />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>{is_required === 1 ? '*' : ''}</span>
        </Typography>
        <Box>
          {loadingSearch ? (
            <>
              <CircularProgress size={15} color="inherit" /> Carregando...
            </>
          ) : (
            <>
              {hypotheses && hypotheses.length > 0 ? (
                <DMultAutoComplete
                  placeholder="Hipótese Diagnóstica"
                  label="Hipótese Diagnóstica"
                  labelColor="black"
                  variant="outlined"
                  options={hypotheses}
                  disabled={readOnly}
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  defaultValue={resultAnswerMap}
                  onChange={(e, newValue) => {
                    setHypothesis(newValue);
                    !readOnly ? handleInputSubmit({[id]: newValue}) : console.log('effect onChange');
                  }}
                  onBlur={ e => {
                    if(hypothesis !== null){
                      !readOnly ? handleInputSubmit({[id]: hypothesis}) : console.log('effect onBlur')
                    }
                  } }
                />
              ) : (
                "Não existem hipoteses diagnosticas cadastrados"
              )}
            </>
          )}
        </Box>
        <span style={{ fontSize: 12 }} className="text-light text-error">
          { erro[0] ? erro[0].value : '' }
        </span>
        <br />
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ""}
        </span>
      </div>
    </section>
  );
};

export default EBDiagnosticHypothesis;
