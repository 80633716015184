import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, SvgIcon  } from "@material-ui/core";
import { Person, People } from "@material-ui/icons";
import { ReactComponent as DoctorIcon } from "../../../../../../assets/img-icon/DoctorIcon.svg";

const useStyles = makeStyles((theme) => ({   
    chip: {
        color: theme.palette.neutral.white,
        margin: "0px 5px 5px 0px",
        backgroundColor: theme.palette.primary.light,
        padding: "2px 12px",
        boxSizing: "content-box"
    },
    label: {
        fontSize: 12,
        fontWeight: 500,
        padding: 0,
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "uppercase"
        }
    },
    avatar: {
        margin: "0 6px 0 0 !important",
        width: "20px !important",
        height: "16px !important",
    }
}));

function SolicitacaoSpeciality(props)
{
    const classes = useStyles();

    const { attendance } = {...props};

    return (
        <>
            {
                attendance && attendance.health_attendance_anamnese_form ?
                    <>
                        { attendance.health_attendance_anamnese_form.filter(h_anamnese => h_anamnese.anamnese_form && h_anamnese.anamnese_form.anamnese_form_medical_speciality !== null).map(h_anamnese => {
                            return <>
                                    { h_anamnese.anamnese_form && h_anamnese.anamnese_form.anamnese_form_medical_speciality.map(speciality => {
                                        
                                        return <Chip
                                                size="small"
                                                avatar={
                                                <DoctorIcon classes={{
                                                    root: classes.avatar
                                                }}
                                                />
                                            }
                                                label={ speciality.medical_speciality.description ? speciality.medical_speciality.description : "-" }
                                                color="teal"
                                                classes={{
                                                    root: classes.chip,
                                                    labelSmall: classes.label,
                                                    avatarSmall: classes.avatar
                                                }}
                                            />                           
                                    }) }
                                </>
                            }) }
                        </>
                :
                ''
            }
        </>
    )
}

export default SolicitacaoSpeciality;
