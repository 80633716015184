import { makeStyles } from '@material-ui/core/styles';
import { Grid, Container, CircularProgress, Backdrop } from "@material-ui/core";
import SolicitacaoGrid from './components/SolicitacaoGrid';
import { SolicitacaoFiltro } from './components/SolicitacaoFiltro';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DButton from "../../../shared/DButton";
import { useHistory } from 'react-router';
import PacienteCreate from "./../../../Paciente/components/PacienteCreate";
import { useState, useEffect } from 'react';
import { getAllUnidades } from '../../../../api/unidades';
import { usePatient } from "../../../../context/usePatient";
import { getPatientByPage } from "../../../../api/patient";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.transparency.transparent
  },
  gHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 24,
    fontSize: 16,
    color: theme.palette.primary.light,
    fontWeight: 500
  },
  gMessage: {
    fontSize: 14,
    color: theme.palette.neutral.midgrey,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 320px)"
  },
  addButton: {
    color: theme.palette.neutral.white,
    backgroundColor: theme.palette.primary.light
  }
}));



function SolicitacoesCreate(props) {
  const classes = useStyles();
  const history = useHistory();
  const [blkPacienteCreate1, setBlkPacienteCreate1] = useState(true);
  const [blkPacienteCreate2, setBlkPacienteCreate2] = useState(true);
  const [pacienteCreate, setPacienteCreate] = useState(false);
  const [unidadesRef, setUnidadesRef] = useState([]);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const cadastrarPaciente = () => history.push("/pacientes/cadastrar")

  const { setHealthPatients, setFirstFilter } = usePatient();

  useEffect(function(){

    setLoading1(false);    
    setLoading2(true);    
    setBlkPacienteCreate1(false);
    setFirstFilter(false);

    getAllUnidades(localStorage.getItem('token')).then(res => {
        var unidadesRef = [];
        var unidadesVinculadas = [];

        res.length && res.map((item) => {
            if (item.below_mother_medical_unit == 'no') {
                unidadesRef.push(item);
                setUnidadesRef(unidadesRef)
            } else {
                unidadesVinculadas.push(item);
                setUnidadesVinculadas(unidadesVinculadas)                
            }
        });

        setBlkPacienteCreate2(false);
        setLoading2(false);    
    }).catch(err => {
      console.log(err)
      setLoading2(false);    
    })

  }, [])

  return(
    <Grid className={ classes.bg }>
      <Backdrop style={{zIndex: 3000, color: 'white'}} open={ loading1 || loading2 }>
          <CircularProgress color="inherit" />
      </Backdrop>

      <Container style={{ marginTop: 80 }}>
        
        <Grid container>
          <Grid item className={ classes.gHeader } md={9}>
            Paciente:
            <SolicitacaoFiltro />
          </Grid>
          <Grid item className={classes.gHeader} md={3}>
            <DButton style={{ minWidth: "max-content" }} disabled={blkPacienteCreate1 || blkPacienteCreate2} variant="primary" onClick={cadastrarPaciente} icon={<AddCircleIcon fontSize="small" />} size="md">Cadastrar paciente</DButton>
          </Grid>

          <SolicitacaoGrid ver={ e => props.verdetalhe(true) } />
        </Grid>
        { pacienteCreate &&        
          <PacienteCreate   
                unidadesRef={ unidadesRef }
                unidadesVinculadas={ unidadesVinculadas }
                open={ true }
                onClose={ e => {                  
                    getPatientByPage(localStorage.getItem('token'), 1).then(res => {
                        if(res.status){
                            setHealthPatients(res.patient);
                        }
  
                        setPacienteCreate(false)
                    })                  
                } }
            />
        }
      </Container>
    </Grid>
  )
}

export default SolicitacoesCreate;