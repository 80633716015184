import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { mascaraNumero } from "../../../../../helpers/formatacao";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import {
  AssumirELigar,
  UnmuteCall,
  MuteCall,
  AbdicarAtendimento,
  LigarParaPaciente,
  Desligar,
  DesligarEFinalizar,
  Finalizar,
  LigarParaEspecialista,
} from "./Buttons";
import {
  AtendimentoContext,
  abdicarAtendimento,
  attendance,
} from "../../../../../context/AtendimentoContext";
import { useHealthAttendance } from "../../../../../context/HealthAttendanceContext";
import { useHistory } from "react-router-dom";
import "./index.scss";

const useStyles = makeStyles({
  bg: {
    backgroundColor: "#f4f4f4",
  },
  paciente__topo: {
    position: "absolute",
    left: 0,
    top: 98,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
    zIndex: 5,
  },
  paciente__tabs: {
    marginTop: 93,
  },
  paciente__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    fontWeight: 600,
  },
  paciente__button: {
    backgroundColor: "green !important",
  },
  MuiTabsflexContainer: {
    color: "#4a4ae9",
    boxShadow: "none",
    overflowY: "scroll",
  },
  MuiTabroot: {
    textTransform: "none",
    backgroundColor: "#e6e6e6",
    borderRadius: "8px 8px 0 0",
    marginLeft: 4,
    marginRight: 4,
    fontWeight: 700,
    fontSize: "16px",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    minWidth: 180,
  },
  MuiTabtextColorInherit: {
    opacity: 1,
  },
  Muiselected: {
    backgroundColor: "#fff",
    border: 0,
  },
  box: {
    background: "#FFFFFF",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "8px",
    marginTop: 141,
  },
});

function a11yProps(index) {
  return {
    id: "tab-${index}",
    "aria-controls": "tab-${index}",
  };
}

function PacienteDetails(props) {
  const classes = useStyles();

  const { acoes, setAcoes } = useHealthAttendance();

  useEffect(() => {
    console.log(acoes);
  }, [acoes]);

  const {
    hangUpCall,
    startVoipCall,
    atendimentoStatus,
    setAtendimentoStatus,
    callInfo,
    emAtendimento,
    setEmAtendimento,
    device,
    mute,
    isMuted,
    patientInfo,
    abdicarAtendimento,
    atenderScreen,
    setAtenderScreen,
    attendance,
    assumirAtendimento,
    setReadOnlyAnamneseForm,
  } = React.useContext(AtendimentoContext);

  const [timeElapse, setTimeElapse] = React.useState(0);

  const secondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    var seconds = Math.floor(seconds % 60);
    seconds = seconds < 10 ? (seconds = "0" + seconds) : seconds;
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (emAtendimento && atendimentoStatus == 2) {
      const interval = setInterval(() => {
        setTimeElapse((seconds) => seconds + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimeElapse(0);
    }
  }, [emAtendimento, atendimentoStatus]);

  useEffect(() => {
    setTimeElapse(0);
  }, [emAtendimento]);

  useEffect(() => {
    if (attendance) {

      console.log(callInfo)

      var user_Requesting = attendance.user_requesting;

      if (
        attendance &&
        attendance.user_id !== null &&
        (attendance.user_id == localStorage.getItem("uid") ||
          (user_Requesting &&
            user_Requesting.id == localStorage.getItem("uid")))
      ) {
        setEmAtendimento(true);
        setAtendimentoStatus(1);
        // setReadOnlyAnamneseForm(true);
      }
    }
  }, [attendance]);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function renderCallButton() {
    //**Trecho de código comentado, aguardando definição da regra de ligação para especialista */

    // if (
    //   attendance &&
    //   attendance.user_requesting &&
    //   attendance.user_requesting.id == localStorage.getItem("uid")
    // ) {
    //   return (
    //     <>
    //       <LigarParaEspecialista
    //         onClick={(e) => {
    //         //   setAtendimentoStatus(2);
    //         //   setEmAtendimento(true);
    //           // startVoipCall(); Ligar para executante
    //         }}
    //       />
    //     </>
    //   );
    // } else if (
    //   attendance &&
    //   attendance.user_id &&
    //   attendance.user_id == localStorage.getItem("uid")
    // ) {
    //   return (
    //     <>
    //       <LigarParaEspecialista
    //         onClick={(e) => {
    //         //   setAtendimentoStatus(2);
    //         //   setEmAtendimento(true);
    //           // startVoipCall(); Ligar para solicitante
    //         }}
    //       />
    //     </>
    //   );
    // }

    {
      if(callInfo && callInfo.patientPhone) {

        return (<LigarParaPaciente
          onClick={(e) => {
            setAtendimentoStatus(2);
            setEmAtendimento(true);
            startVoipCall(callInfo && callInfo.patientPhone);
          }}
        />)
      }
    }
  }

  return (
    <Grid item className={classes.paciente__topo}>
      <a
        href="#"
        className={classes.paciente__nome}
        onClick={(e) => props.back()}
      >
        <ArrowBackIosIcon color="primary" fontSize="large" />
        {patientInfo && patientInfo.full_name}
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ marginTop: 8 }}
      >
        <div
          style={{
            width: 245,
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
          v
        >
          <Button
            startIcon={<VideoCallIcon style={{ color: "#4a4ae9" }} />}
            style={{ fontWeight: 400, fontSize: 14, textTransform: "none" }}
            onClick={() => {
              props.videoCall(true);
              assumirAtendimento();
            }}
          >
            Chamada de vídeo
          </Button>
          <Button
            startIcon={<PhoneEnabledIcon style={{ color: "#4a4ae9" }} />}
            style={{ fontWeight: 400, fontSize: 14, textTransform: "none" }}
            onClick={(e) => {
              setAtendimentoStatus(1);
              handleClose();
              assumirAtendimento();
            }}
          >
            Chamada de telefone
          </Button>
        </div>
      </Popover>

      <div className="actions-end">

      <Button
            startIcon={<VideoCallIcon style={{ color: "#4a4ae9" }} />}
            style={{ fontWeight: 400, fontSize: 14, textTransform: "none" }}
            onClick={() => {
              alert("Videocall");
              props.videoCall(true);
              // assumirAtendimento();
            }}
          >
            Chamada de vídeo
          </Button>

        {atendimentoStatus == 0 ? (
          // <AssumirELigar onClick={e => setAtendimentoStatus(1)} />
          <AssumirELigar
            disabled={
              attendance &&
              attendance.user_id !== null &&
              attendance.user_id != localStorage.getItem("uid")
            }
            onClick={handleClick}
          />
        ) : (
          ""
        )}

        {atendimentoStatus == 1 ? (
          <>
            <AbdicarAtendimento
              onClick={(e) => {
                abdicarAtendimento();
              }}
            />
            {/* <AbdicarAtendimento onClick={e => abdicarAtendimento()} /> */}

            {renderCallButton()}
          </>
        ) : (
          ""
        )}

        {atendimentoStatus == 2 ? (
          <>
            <span className="call-info">
              {callInfo && callInfo.patientSocialName} -{" "}
              {callInfo &&
                callInfo.patientPhone &&
                mascaraNumero(callInfo.patientPhone)}
            </span>

            <span className="line-separator"></span>

            <span className="call-time">
              <strong>
                {timeElapse && timeElapse > 0
                  ? secondsToMinutes(timeElapse)
                  : "0:00"}
              </strong>
            </span>

            {isMuted ? (
              <UnmuteCall
                onClick={(e) => {
                  mute();
                }}
              />
            ) : (
              <MuteCall
                onClick={(e) => {
                  mute();
                }}
              />
            )}

            <Desligar
              onClick={(e) => {
                hangUpCall(device);
                setEmAtendimento(false);
                setAtendimentoStatus(1);
                setAcoes({ ...acoes, ligacao: true });
              }}
            />

            {acoes.finalizar && !acoes.ligacao && !acoes.submit ? (
              <DesligarEFinalizar
                onClick={(e) => {
                  hangUpCall(device);
                  setAcoes({ ...acoes, ligacao: true, submit: true });
                }}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {atendimentoStatus == 3 ? console.log("oi") : ""}

        {acoes.ligacao && acoes.finalizar ? (
          <Finalizar
            onClick={(e) => {
              setAcoes({ ...acoes, submit: true });
            }}
          />
        ) : (
          ""
        )}
      </div>
    </Grid>
  );
}

export default PacienteDetails;
