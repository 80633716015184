import {useState} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Button, Card, CardContent, Grid, Typography} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledButtonAction = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: 14,
        color: theme.palette.primary.light
    }
}))(Button);

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginBottom: 16,
        paddingRight: '10%'
    },
    card: {
        maxWidth: 570,
        backgroundColor: theme.palette.neutral.midgrey,
        cursor: 'pointer',
        marginLeft: '-5px'
    },
    cardAssistencial: {
        maxWidth: 570,
        backgroundColor: theme.palette.background.cloud,
        cursor: 'pointer',
        marginLeft: '-5px'
    },
    cardContent: {
        minWidth: 570,
        padding: 0,
        paddingBottom: '0 !important'
    },
    gridContent: {
        height: 59,
        padding: '10px 8px'
    },
    gridItem: {
        marginLeft: 10
    },
    gridItemLast: {
        marginLeft: 'auto'
    },
    secondaryInner: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 550,
        marginLeft: 25,
        borderLeft: `2px solid ${theme.palette.neutral.midgrey}`
    },
    secondaryInnerLast: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 550,
        marginLeft: 25,
        marginBottom: 8,
        borderLeft: 'unset'
    },
    secondary: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        marginLeft: 15,
    },
    containerChild: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 20
    },
    subContainerChild: {
        marginLeft: 45
    },
    lastItem: {
      marginTop: -50
    },
    flagLast: {
        width: 2,
        height: 15,
        background: theme.palette.background.cloud,
        marginTop: 0,
        paddingTop: 35
    },
    flag: {
        zIndex: 10,
        display: 'flex',
        width: 44
    },
    flagHr: {
        minWidth: 25,
        borderTop: 'unset',
        borderBottom: `2px solid ${theme.palette.neutral.midgrey}`
    },
    flagInner: {
        width: 13,
        height: 13,
        display: 'block',
    background: theme.palette.neutral.midgrey,
        border: `solid ${theme.palette.neutral.white} 2px`,
        borderRadius: 13
    },
    flagInnerSuccess: {
        width: 13,
        height: 13,
        display: 'block',
        background: theme.palette.primary.light,
        border: `solid ${theme.palette.neutral.white} 2px`,
        borderRadius: 13
    }
}))

function MedicalUnitCard(props) {

    const [open, setOpen] = useState(props.unidade.open)

    const classes = useStyles();
    const card = props.unidade.type === 'Administrativa' ? classes.card : classes.cardAssistencial

    function Child(props)
    {
        return (
            <>
                <div className={ props.lastItem ? classes.secondaryInnerLast : classes.secondaryInner }>
                    { props.lastItem ?
                        <>
                            <div className={ classes.flagLast }></div>
                            <div className={ classes.lastItem }>
                                { props.children }
                            </div>
                        </>
                        :
                        <>
                            { props.children }
                        </>
                    }
                </div>
            </>
        )
    }

    function ContainerMedicalUnit(props) {

        return (
            <Card classes={{ root: card }}>
                <CardContent classes={{root: classes.cardContent}}>
                    <Grid container alignItems="center" classes={{ container: classes.gridContent }}>
                        { props.unidade.children && props.unidade.children.length
                            ?
                                <>
                                    <Grid item onClick={ e => props.onOpen(true) } style={{ width: 30 }}>
                                        { open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" /> }
                                    </Grid>
                                </>
                            :
                                ''

                        }
                        <Grid item classes={{root: classes.gridItem}} style={{ width: 200  }}>
                            <Grid container direction="column">
                                <Typography variant="body1" className="text-regular text-est">{ `Unidade ${props.unidade.type}` }</Typography>
                                <Typography variant="body1" className="text-medium text-st" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>{ props.unidade.name }</Typography>
                               
                            </Grid>
                        </Grid>
                        { props.unidade.type !== 'Administrativa' &&
                        <Grid item classes={{root: classes.gridItem}} style={{ width: 125 }}>
                            <Typography variant="body1" className="text-regular text-est">CNES</Typography>
                            <Typography variant="body1" className="text-medium text-st">{ props.unidade.cnes }</Typography>
                        </Grid>
                        }
                        <Grid item classes={{root: classes.gridItem}} style={{ width: 110 }}>
                            <Typography variant="body1" className="text-regular text-est">Telefone</Typography>
                            <Typography variant="body1" className="text-medium text-st">{ props.unidade.phone }</Typography>
                        </Grid>
                        <Grid item classes={{root: classes.gridItemLast}}>
                            <StyledButtonAction onClick={ e => props.viewUnidade(props.unidade.id) } style={{ minWidth:40 }}>
                                <OpenInNewIcon fontSize="small" />
                            </StyledButtonAction>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    return (
        <>
            {   props.hasChild
                ?
                <Child lastItem={ props.lastItem }>
                    <Grid container>
                        <Grid item classes={{root: classes.containerChild}}>
                            <div className={ classes.flag }>
                                <hr className={ classes.flagHr }/>
                                <div className={ !open ? classes.flagInner : classes.flagInnerSuccess }></div>
                            </div>
                            <ContainerMedicalUnit viewUnidade={ unidade => props.viewUnidade(unidade) } onOpen={ e => setOpen(!open) } unidade={props.unidade} />
                        </Grid>
                    </Grid>
                    { (open && props.children) &&
                        <Grid container>
                            <Grid item classes={{root: classes.subContainerChild}}>
                                { props.children }
                            </Grid>
                        </Grid>
                    }
                </Child>
                :
                <Grid container classes={{root: classes.gridContainer}}>
                    <Grid item>
                        <ContainerMedicalUnit viewUnidade={ unidade => props.viewUnidade(unidade) } onOpen={ e => setOpen(!open) } unidade={props.unidade} />
                    </Grid>
                    { (open && props.children) &&
                        <Grid item>
                            { props.children }
                        </Grid>
                    }
                </Grid>
            }
        </>
    )
}

export default MedicalUnitCard;