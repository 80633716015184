import { Typography } from "@material-ui/core";
import {  makeStyles } from "@material-ui/core/styles";

const useStyles =  makeStyles((theme) => ({
    text: {
        color: theme.palette.primary.light,
    }
}));

const getValue = (type, fields) => {

    if(Array.isArray(fields) && fields.length > 0) {
        switch (type) {
            case 'CRM':         
            case 'CNS':         
            case 'COREN':         
            case 'CRN':         
            case 'CRO':         
            case 'CREFITO':         
            case 'CRP':         
            case 'CREFONO':         
            case 'RMS':                
                if(fields[1] && fields[3] && fields[4])
                    return `${fields[1].value} - ${fields[3].value} - ${fields[4].value}`;
                else
                    return `${fields[0].value}`;
            default:
                return fields[0].value;
        }
    } else {
        return;
    }

}

function Documents(props)
{
    const classes = useStyles();

    return <>
        <Typography variant="body1" className="text-regular">
            <strong className={classes.text}>{ props.type }: </strong>  { getValue(props.type, props.fields) }           
        </Typography>
    </>
}

Documents.defaultProps = {
    type: null,
    fields: []
}

export default Documents;