import env from 'react-dotenv';

function getAllGroups(token) {
    return fetch(`${env.API_URL}/group?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createGroup(token, dados) {
    return fetch(`${env.API_URL}/group?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateGroup(token, dados) {
    return fetch(`${env.API_URL}/group/${ dados.id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify({name: dados.name})
    }).then(res => res.json());
}

function deleteGroup(token, id) {
    return fetch(`${env.API_URL}/group/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

export { getAllGroups, createGroup, updateGroup, deleteGroup }