import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Checkbox,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { toast } from "react-toastify";

import CNSCard from "../../../../../../shared/CNSCard";
import DButton from "../../../../../../shared/DButton";

import useError from "./../../../../../../../hooks/useError";
import { validaLoteCNSCustom } from "./../../../../../../../helpers/validacoes";
import {
  updateCNSDocument,
  getPatientById,
} from "./../../../../../../../api/patient";
import { usePatient } from "./../../../../../../../context/PatientContext";

const useStyles = makeStyles((theme) => ({
  dados_titulo: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.primary.light,
    margin: 0,
  },
  dados_editButton: {
    color: theme.palette.primary.light,
    textTransform: "none",
    fontWeight: 400,
  },
  dados_saveButton: {
    color: theme.palette.primary.light,
    textTransform: "none",
    fontWeight: 400,
  },
  marginDefault: {
    marginTop: 0,
    marginBottom: 25,
  },
}));

function CartaoNacionalSaude(props) {
  const classes = useStyles();

  const { patient, setPatient, dadosCns, setDadosCns } = usePatient();

  const View = () => {
    let cont = 2;
    const [cns, setCNS] = useState([]);

    useEffect(() => {
      let cnses = [];

      if (patient.cns_principal)
        cnses.push({ value: patient.cns_principal, default: true });

      if (patient.cns_secundario && patient.cns_secundario.length > 0) {
        patient.cns_secundario.map((res) =>
          cnses.push({ value: res.value, default: false })
        );
      }

      setCNS(cnses);
    }, [patient]);

    return (
      <>
        <Toolbar />
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className="text-regular text-st"
              style={{ marginBottom: 10 }}
            >
              <strong>CNS Principal:</strong>{" "}
              {dadosCns && Array.isArray(dadosCns) && dadosCns.find(x => x.default) && dadosCns.find(x => x.default).value}
            </Typography>

            <>
              {dadosCns &&
                dadosCns
                  .filter((x) => x.default !== true)
                  .map((res, index) => {
                    return (
                      <Typography
                        variant="body1"
                        style={{ marginBottom: 10 }}
                        className="text-regular text-st"
                        key={`cns-secundario-${index}`}
                      >
                        <strong>CNS {cont++}:</strong> {res.value}
                      </Typography>
                    );
                  })}
            </>
          </Grid>
        </Grid>
      </>
    );
  };

  const Edit = () => {
    const [loading, setLoading] = useState(false);
    const [cns, setCNS] = useState([]);
    const [error, validarFormulario, setErrors] = useError({
      cns: validaLoteCNSCustom,
    });

    useEffect(() => {
        setErrors(props.error);
    }, [props.error])

    useEffect(() => {

      let cnses = [];

      if (patient.cns_principal)
        cnses.push({ value: patient.cns_principal, default: true });

      if (patient.cns_secundario && patient.cns_secundario.length > 0) {
        patient.cns_secundario.map((res) =>
          cnses.push({ value: res.value, default: false })
        );
      }

      setCNS(dadosCns);
      
    }, []);

    const addCNS = () => {
      
      let new_cns = [...cns];

      new_cns.push({ value: "", default: new_cns.length <= 0 });

      // new_cns.push({
      //   value: "",
      //   default: new_cns.length <= 0,
      // });

      setCNS(new_cns);

      setDadosCns((prevState) => {
        return [...prevState, {value: "", default: new_cns.length <= 0}];
      });
    };

    return (
      <form
        onSubmit={(form) => {
          form.preventDefault();
          if (validarFormulario({ cns: cns.map((res) => res.value) })) {
            setLoading(true);

            setDadosCns(cns)

            let body = {
              cns: cns.map((res) => {
                return {
                  type: "CNS",
                  fields: [
                    {
                      id: 9,
                      value: res.value,
                    },
                    {
                      id: 61,
                      value: res.default,
                    },
                  ],
                };
              }),
            };


            updateCNSDocument(
              localStorage.getItem("token"),
              body,
              patient.id
            ).then((res) => {
              setLoading(false);

              if (res.status) {
                toast.success("CNS atualizado com sucesso.");
                getPatientById(localStorage.getItem("token"), patient.id).then(
                  (res) => {
                    if (res.status) {
                      setPatient(res.details.patient);

                    }
                  }
                );
              }
            });
          } else {
            console.log(error);
          }
        }}
      >
        <Toolbar loading={loading} />
        <Grid container>
          <Grid item xs={12}>
            {cns &&
              cns.map((res, index) => {
                
                return (
                  <CNSCard
                    key={`cns-card-${index}`}
                    notDelete={index === 0}
                    isDefault={res.default}
                    value={res.value}
                    error={error.cns && index in error.cns}
                    helperText={
                      error.cns && index in error.cns ? error.cns[index] : ""
                    }
                    onBlur={ e => {

                      delete error.cns;

                      setDadosCns(prevState => {
                        let newCns = [...prevState];

                        if(e.default) {
                          if(newCns.find(x => x.default === true)) {
                            newCns.find(x => x.default === true).default = false;
                          }
                        }

                        newCns[index] = {
                          value: e.value,
                          default: e.default,
                        };

                        return [
                          ...newCns,
                        ];
                      })
                    } }
                    onChange={(e) => {
                      // let new_cns = [...dadosCns];

                      delete error.cns;

                      setCNS(prevState => {
                        let newCns = [...prevState];

                        if(e.default) {
                          if(newCns.find(x => x.default === true)) {
                            newCns.find(x => x.default === true).default = false;
                          }
                        }

                        newCns[index] = {
                          value: e.value,
                          default: e.default,
                        };

                        return [
                          ...newCns,
                        ];
                      });

                      
                    }}
                    onDelete={(e) => {
                      // let new_cns = [...dadosCns];

                      // if (res.default) {
                      //   new_cns[0].default = true;
                      // }

                      setCNS(prevState => {
                        let newCns = [...prevState];

                        if(newCns[index].default) {
                          if(newCns[0]) {
                            newCns[0].default = true;
                          }
                        }

                        newCns.splice(index, 1);


                        return newCns;
                      })

                      setDadosCns(prevState => {
                        let newCns = [...prevState];

                        if(newCns[index].default) {
                          if(newCns[0]) {
                            newCns[0].default = true;
                          }
                        }

                        newCns.splice(index, 1);


                        return newCns;
                      })

                      // new_cns.splice(index, 1);
                      // setCNS(new_cns);
                      // setDadosCns(new_cns);
                    }}
                  />
                );
              })}
            <DButton
              className={classes.marginDefault}
              type="button"
              variant="primary"
              size="sm"
              icon={<AddCircleIcon />}
              onClick={x => {
                var oldCns = [...cns];

                oldCns.push({ value: "", default: oldCns.length <= 0 });

                setCNS(oldCns);

              }}
            >
              Adicionar novo CNS
            </DButton>
          </Grid>
        </Grid>
      </form>
    );
  };

  const PatientCNS = () => {
    return <>{!props.editar ? <View /> : <Edit />}</>;
  };

  const LoadPage = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            <CircularProgress size={15} color="inherit" /> carregando...
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const Toolbar = (props) => {
    const { loading } = { ...props };

    return (
      <>
        <Grid container>
          <Grid item md={12} xs={12} style={{ marginBottom: 10 }}>
            <h5 className={classes.dados_titulo}>Cartão Nacional de Saúde</h5>
          </Grid>
        </Grid>
      </>
    );
  };

  return <>{patient ? <PatientCNS /> : <LoadPage />}</>;
}

export default CartaoNacionalSaude;
