import React, { useMemo } from "react";
import "../index.scss";
import {
  Chip,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Grid, Divider } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import { useState } from "react";
import { useNewHealthAttendance } from "../../../../context/NewHealthAttendance";
import { useEffect } from "react";
import DMultAutoComplete from "../../DMultAutoComplete";

export default function UnidadesVinculadasEdit(props) {
  const { patient, setPatient } = useNewHealthAttendance();
  const [unidadeVinculada, setUnidadeVinculada] = useState();

  useEffect(() => {
    if (patient) {
      setUnidadeVinculada(
        patient.patient_has_medical_unit.length > 0
          ? patient.patient_has_medical_unit.map((res) => {
              return {
                id: res.medical_unit_id,
                value: res.medical_unit.bussines_name
                  ? res.medical_unit.bussines_name
                  : res.medical_unit.company_name,
              };
            })
          : []
      );
    }
  }, [patient]);

  const unidadesVinculadas = useMemo(
    () =>
      props.unidadesVinculadas.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    []
  );

  console.log(props.unidadesVinculadas, unidadesVinculadas.length > 0)
  
  return (
    <div>
      {Array.isArray(unidadesVinculadas) && unidadesVinculadas.length ?  (
        <Grid item md={12}>
          <DMultAutoComplete
            id="unidadeVinculada"
            placeholder="Unidades vinculadas ao paciente"
            labelColor="black"
            fullWidth={true}
            // error={error.unidadeVinculada}
            // helperText={error.unidadeVinculada}
            defaultValue={unidadeVinculada}
            options={unidadesVinculadas}
            getOptionLabel={(option) => option.value}
            getOptionSelected={(option, value) =>
              value && option.id === value.id
            }
            onChange={(event, newValue) => {
              // delete error.unidadeVinculada;
              setUnidadeVinculada(newValue);
              props.onChange(newValue);
            }}
          />
        </Grid>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando unidade de referência...
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
