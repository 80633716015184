import React from 'react';
import ReactDOM from 'react-dom';
import AppContext from "./context/AppContext"
import ThemeContext from "./context/useThemeContext"
import App from './App';
import ErrorHandling from "./component/ErrorHandling"
import env from "react-dotenv";
import './index.css';

ReactDOM.render(
  <ThemeContext>
    <ErrorHandling isProd={env.NODE_ENV === 'production'}>
    <AppContext>
      <App />
    </AppContext>
  </ErrorHandling>
  </ThemeContext>
  ,
  document.getElementById('root')
);
