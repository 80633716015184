import React, { useState, useEffect } from 'react';
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PersonPinIcon from '../../../../../../assets/img-icon/person_pin.svg';
import StethoscopeIcon from '../../../../../../assets/img-icon/stethoscopeIcon.svg';
import ChatIcon from '@material-ui/icons/Chat';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';
import CallEndIcon from '@material-ui/icons/CallEnd';

import DButton from '../../../../../shared/DButton';

const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: theme.palette.background.cloud
    },
    paciente__topo: {
        position: "absolute",
        left: 0,
        top: 98,
        width: "100%",
        height: 61,
        display: "flex",
        padding: "10px 40px",
        background: theme.palette.background.snow,
        boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 18,
        zIndex: 5
    },
    paciente__nome: {
        height: 20,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        cursor: "pointer",
        color: "#000",
        fontWeight: 600
    },
    icon__button: {
        backgroundColor: theme.palette.primary.light,
        padding: 7,
        marginRight: "16px !important"
    },
    icon__button_message: {
        backgroundColor: theme.palette.primary.light,
        padding: 7,
        marginRight: "0px !important"
    },
    icon__badge: {
        height: 14,
        minWidth: 14,
        padding: 0
    },
    primaryColor: {
        color: theme.palette.primary.light,
    }
}));

function Header(props) {
    const classes = useStyles();

    const [isAudioMuted, setIsAudioMuted]       = useState(false);
    const [isVideoDisabled, setIsVideoDisabled] = useState(false);
    const [timeElapse, setTimeElapse]           = useState(0);
    const [onCall, setOnCall]                   = useState(true);

    const secondsToMinutes = seconds => {
        const minutes = Math.floor(seconds / 60);
        var seconds = Math.floor(seconds % 60);
        seconds = seconds < 10 ? seconds = "0" + seconds : seconds;
        return `${minutes}:${seconds}`
    }

    useEffect(() => {
        if (onCall) {
            const interval = setInterval(() => {
                setTimeElapse(seconds => seconds+1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setTimeElapse(0);
        }
    }, [onCall])

    function toggleAudio() {
        if (isAudioMuted) {
            props.onUnmute(true);
        } else {
            props.onMute(true);
        }

        setIsAudioMuted(!isAudioMuted);
    }

    function toggleVideo() {
        if (isVideoDisabled) {
            props.onEnableVideo(true);
        } else {
            props.onDisableVideo(true);
        }

        setIsVideoDisabled(!isVideoDisabled);
    }

    function toggleChat() {
        props.toggleChat(true);
    }

    return (
        <Grid item className={ classes.paciente__topo }>
            <span
                className={ classes.paciente__nome }
                onClick={ e => { props.onClose(true); setOnCall(false); } }
            >
                <ArrowBackIosIcon color="primary" fontSize="large" /> 
                { props.paciente.full_name }
            </span>
                
            <div className="actions-end">
                <div onClick={ e => props.openSideBar(true) } >
                    <IconButton className={ classes.icon__button }>
                        <img src={PersonPinIcon} />
                    </IconButton>
                </div>
                <IconButton className={ classes.icon__button }>
                    <img src={StethoscopeIcon} />
                </IconButton>

                <div onClick={ e => toggleChat() } >
                    <Badge color="secondary" badgeContent=" " classes={{ badge: classes.icon__badge }}>
                        <IconButton className={ classes.icon__button_message }>
                            <ChatIcon className={classes.primaryColor} />
                        </IconButton>
                    </Badge>
                </div>

                <div style={{ height: 40, width: 0, borderRight: "1px solid #949494", marginRight: 16, marginLeft: 16 }}></div>
                <Typography style={{ marginRight: 16 }}>{timeElapse && timeElapse > 0 ? secondsToMinutes(timeElapse) : "0:00"}</Typography>

                <div onClick={ e => toggleAudio() } >
                    <IconButton className={ classes.icon__button }><MicIcon style={ isAudioMuted ? { color: "#ad1717" } : { color: "#2a814e" } } /></IconButton>
                </div>

                <div onClick={ e => toggleVideo() } >
                    <IconButton className={ classes.icon__button }><VideocamIcon style={ isVideoDisabled ? { color: "#ad1717" } : { color: "#2a814e" } } /></IconButton>
                </div>
                <Button 
                    variant="outlined" 
                    style={{ color: "#d13d2e", borderColor: "#d13d2e", borderRadius: 20 }}
                    onClick={ e => { props.onHangUp(true); setOnCall(false); } }
                >
                    <PhoneDisabledIcon />
                </Button>
                <DButton 
                    icon={ 
                        <CallEndIcon 
                            fontSize="small" 
                            style={{ marginRight: 8 }} 
                        /> 
                    } 
                    size="sm" 
                    style={{ backgroundColor: "#d13d2e", color: "#fff", textTransform: "none", fontWeight: 400 }}
                    onClick={ e => { props.onClose(true); setOnCall(false); } }
                >
                    Desligar e finalizar
                </DButton>
            </div>
        </Grid>
    );
}

export default Header;