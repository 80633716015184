import env from 'react-dotenv';

function getConversationByAttendenceId(token, id) {
    return fetch(`${env.API_URL}/conversation/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createConversation(token, dados) {
    return fetch(`${env.API_URL}/conversation?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function addParticipant(token, dados) {
    return fetch(`${env.API_URL}/conversation/add-participant?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export { getConversationByAttendenceId, createConversation, addParticipant }