import env from 'react-dotenv';

function getList(token) {
    let url = `${env.API_URL}/sendgrid/group`;
    return fetch(url.replace("api/", ""), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },        
        method: 'get'
    }).then(res => res.json());
}

function getTemplateEmail(token, templateId, versionId) {
    let url = `${env.API_URL}/sendgrid/template/${templateId}/${versionId}`;
    return fetch(url.replace("api/", ""), {            
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },          
        method: 'get'
    }).then(res => res.json());
}

function saveTemplateEmail(token, dados) {
    let url = `${env.API_URL}/sendgrid/template`;
    return fetch(url.replace("api/", ""), {  
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },             
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}


function getAllCategories(token) {

    return getList(token).then((response) => {

        if(response.status){
            return response.groups.map((group) => {
                const emails = group.templates.map((temp) => {
                    return {
                        id: temp.id,
                        category_id:group.id,
                        versionId: temp.versionId,
                        title: temp.name,
                        body: "",
                        signature: "",
                        footer: "",
                        isLoading: false,
                        isToDelete: false,
                        variables: []
                    }
                });

                return {
                    id: group.id,
                    name: group.name,
                    isOptionList: null,
                    isOpened: false,
                    isEdit: false,                
                    isToDelete: false,
                    isLoading: false,
                    emails
                };
            });
        }

    });

    
}


function saveEmailCategory(token, dados){

    return saveTemplateEmail(token, dados, dados.id, dados.versionId).then((response) => {
        return res => res.json();
    });
}

export {
    getAllCategories,
    saveEmailCategory,
    getTemplateEmail
}