import React, {useState} from 'react';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import { FormHelperText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    ,
    },
    bottomLabelDiv: {
        display: 'flex',
        flexDirection: 'column',
        margin: "4px 0"
    },
    bottomLabel: {
        fontSize: 12
    }
}));

function DRadioColumn(props) {
    const classes = useStyles();
    const [outros, setOutros] = useState(props.others && props.value === 'Outros');

    function handleChange(event) {
        setOutros(props.others && event.target.value === 'Outros');
        props.onChange(event);
    }

    return (
        <FormControl component="fieldset" className={`Radio-root ${ props.error ? 'form-control-error mt-4' : '' } `}>
            <FormLabel component="legend" color="text.secondary">{ props.label }</FormLabel>
            <RadioGroup aria-label={ props.name }
                        name={ props.name }
                        value={props.value}
                        onChange={ handleChange }>
                <Grid container>
                    { props.options.map(function(item, index){
                        return (
                            <Grid item md={ props.column ? 12 / props.column : 12 }>
                                <FormControlLabel
                                    key={ index }
                                    disabled={ item.disabled }
                                    checked={ item.value == props.value }
                                    value={ item.value }
                                    control={<Radio classes={{ root: 'Mui-DRadio' }} />}
                                    label={ 
                                        <div className={`${item.note ? classes.bottomLabelDiv : ""}`}>
                                            <span className={ `${item.value == props.value && !props.withoutBold ? 'label-checked' : ''} ${props.labelClass ? props.labelClass : ""}` }>{ item.label }</span>
                                            {item.note ? <span className={ `${item.value == props.value && !props.withoutBold ? 'label-checked' : ''} ${props.labelClass ? props.labelClass : ""} ${classes.bottomLabel}` }>{ item.note }</span> : ''}
                                        </div>
                                    }
                                />
                            </Grid>
                        );
                    })}                    
                </Grid>

                { ( props.others ) && <FormControlLabel
                    value="Outros"
                    control={<Radio classes={{ root: 'Mui-DRadio' }} />}
                    label={ <span className={ `${'Outros' == props.value ? 'label-checked' : ''} label-outros ${props.labelClass ? props.labelClass : ""}` }>Outros</span> } /> }
            </RadioGroup>
            { outros ? <Input className="input-outros" placeholder="Outros" onChange={ event => props.onChangeOutros(event) } /> : '' }
            { props.helperText ? <FormHelperText>{ props.helperText }</FormHelperText> : '' }
        </FormControl>
    )
}

export default DRadioColumn;