import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from "@material-ui/lab";
import {
    Checkbox,
    DialogActions,
    DialogContent,
    Grid,
    Button,
    withStyles
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import DInput from "../../../shared/DInput";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Popover from '@material-ui/core/Popover';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import SaveIcon from '@material-ui/icons/Save';
import DButton from '../../../shared/DButton';
import { toast } from "react-toastify";
import { Box } from "@mui/system";

import { getProfileById, getProfilePermissionsById } from "../../../../api/profiles";

const useStyles = makeStyles((theme) => ({
    textHelperSelected: {"marginLeft":"50px","fontFamily":"Roboto","fontStyle":"normal","fontWeight":"bold","fontSize":"16px","lineHeight":"24px","display":"flex","alignItems":"center","color":`${theme.palette.primary.light}`},
    rootHide: {
        display: 'none'
    },
    title: {
        borderBottom: `1px solid ${theme.palette.neutral.midgrey} !important`
    },
    content: {
        height: 500
    },
    footer: {
        borderTop: `1px solid ${theme.palette.neutral.midgrey} !important`,
        padding: "16px 20px"
    },
    icon: {
        color: theme.palette.neutral.midgrey
    },
    doc_popover: {
        listStyle: "none",
        padding: 0,
        margin: "0 16px 0 0",
        width: "100%"
    },
    doc_popoverItem: {
        listStyle: "none",
        margin: 0,
        color: theme.palette.neutral.black,
        fontWeight: 400,
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        lineHeight: "21px"
    },
    accordion: {
        margin: "8px 0",
        background: theme.palette.neutral.lightgray, 
        boxShadow: "none",
        border: 0,
        borderRadius: 4,
        width: "100%"
    },
    accordion_heading: {
        fontWeight: "bold",
        color: theme.palette.neutral.black
    },
    subaccordion: {
        margin: "8px 0",
        background: theme.palette.neutral.lightgray, 
        boxShadow: "none",
        borderRadius: 4,
        width: "100%"
    },
    subaccordion_heading: {
        fontWeight: "bold",
        color: theme.palette.neutral.black,
        fontSize: 14
    },
    saveButton: {
        fontSize: "14px",
        borderRadius: 25,
        borderWidth: 2,
        color: theme.palette.background.snow, 
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        textTransform: "none",
        padding: "6px 18px"
    },
    backButton: {
        fontSize: "14px",
        borderRadius: 25,
        borderWidth: 2,
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        textTransform: "none",
        padding: "6px 18px",
        marginRight: 16
    }
}));

const SelectAllCheckbox = withStyles((theme) => ({
    root: {
      "&$checked": {
        color: theme.palette.support.check,
      }
    },
    checked: {}
  }))((props) => <Checkbox color="default" {...props} />);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
        fontSize: 20,
        marginTop: 3
    },
    arrow: {
        color: theme.palette.background.snow,
    }
}))(Tooltip);

export default function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [atualizarTela, setAtualizarTela] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [totalPermissions, setTotalPermissions] = useState([]);
    const [totalPermissionsProfile, setTotalPermissionsProfile] = useState([]);
    const [showTotal, setShowTotal] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [documentsName, setDocumentsName] = useState([]);
    const [perfilName, setPerfilName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [nameRequired, setNameRequired] = useState(false);
    const [docRequired, setDocRequired] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [editData, setEditData] = useState({});
    const [ctrlSubmit, setCtrlSubmit] = useState(false);

    useEffect(() => {

        if (props.editData && props.editData.status) {
            
            setShowTotal(true);

            let totals = [...totalPermissions]

            props.counterPermission.map( function(item) {
                //setTotalPermissions(prevState => [...prevState, [{"total": item.roles[0].role_permission_count}]]);
                if (typeof totals[item.id] === 'undefined') {
                totals[item.id] = item.roles[0].role_permission_count
                }
            });
    
            setTotalPermissions(totals);

            setDuplicate(props.editData.duplicate);

            getProfileById(localStorage.getItem('token'), props.editData.id).then(res => {
                setSelectedDocuments([]);
                setDocumentsName([]);

                if(res.status) {
                    setEditData(res);
                    setPerfilName(res.profile.description);

                    res.profileDocument.map((item) => {
                        setSelectedDocuments(prevState => [...prevState, item.document_type.id]);
                        setDocumentsName(prevState => [...prevState, item.document_type.description])
                    });
                }
            });

            getProfilePermissionsById(localStorage.getItem('token'), props.editData.id).then(res => {
                if (res.profile[0].profile_permissions.length) {
                    res.profile[0].profile_permissions.map( permission => {
                        setSelectedPermissions(prevState => [...prevState, permission.permission_id]);
                    });

                    setTotalPermissionsProfile(res.profilePermissionCounter);
                    setIsLoading(false);
                }
                if (res.profile[0].profile_permissions.length == 0) {
                    setIsLoading(false);
                }
            });

        } else {
            setIsLoading(false);
        }

        return () => {
            setEditData({}); // Cleanup // limpa após o componente ser desmontado
        }

    }, [props.editData, props.counterPermission]);
    
    const [expandMore, setExpandMore] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setExpandMore(true);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setExpandMore(false);
    };

    const openpop = Boolean(anchorEl);
    const id = openpop ? 'simple-popover' : undefined;

    function validarCampos() {

        let nameRequired = false;
        let docRequired = false;
        let perrmissionsRequired = false;

        // setIsLoading(true);

        if(perfilName.trim() == '' || (duplicate && editData.profile.description == perfilName)) {
            // setIsLoading(false);
            nameRequired = true;
            setNameRequired(nameRequired);
        }
        
        // if (selectedDocuments.length == 0) {
        //     // setIsLoading(false);
        //     docRequired = true;
        //     setDocRequired(docRequired);
        // } 
        
        if (selectedPermissions.length == 0) {
            perrmissionsRequired = true;
            toast.error("Selecione uma permissão.")
        }

        if (!nameRequired && !docRequired && !perrmissionsRequired) {
            if(props.editData.status){
                props.editProfile([perfilName, selectedPermissions, selectedDocuments]);
            } else {
                props.createProfile([perfilName, selectedPermissions, selectedDocuments]);
            }
        }
    }

    function showSelecionados(roleId)
    {

        return (totalPermissionsProfile[roleId] ?
            <>
            <Box display="inline" className={classes.textHelperSelected}>
            ({ totalPermissionsProfile[roleId] } de { totalPermissions[roleId] } selecionados)
            </Box>
            </> : ""
        );

    }

    function closeModal()
    {
        if(perfilName.length > 0 || documentsName.length > 0 || selectedPermissions.length > 0)
            props.onConfirmClose(true);
        else
            onClose(true);
    }

    return (
        (isLoading) ?
        <>
         <Dialog aria-labelledby="profile-create-title" scroll="body" onClose={ e => closeModal() } open={open} fullWidth={true} maxWidth = {'md'}>
                <DialogTitle id="profile-create-title" classes={{ root: classes.title }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h2" className="text-mt text-regular text-primary">{props.editData.status ?  duplicate ? 'Duplicar' : 'Editar' : 'Criar'} perfil</Typography>
                        </Grid>
                        <Grid item>
                            <Close onClick={ e => {
                                closeModal()
                            } } />
                        </Grid>
                    </Grid>
                </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: 400 }}
              >
                <Grid item className="text-center">
                  <CircularProgress
                    className="text-success"
                    style={{ fontSize: 64 }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            </Dialog>
        </>
        :
        <>
            <Dialog aria-labelledby="profile-create-title" scroll="body" onClose={ e => closeModal() } open={open} fullWidth={true} maxWidth = {'md'}>
                <DialogTitle id="profile-create-title" classes={{ root: classes.title }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h2" className="text-mt text-regular text-primary">{props.editData.status ?  duplicate ? 'Duplicar' : 'Editar' : 'Criar'} perfil</Typography>
                        </Grid>
                        <Grid item>
                            <Close onClick={ e => {
                                closeModal()
                            } } />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={ classes.content }>
                    <Grid container>
                        <Grid item md={8} style={{ paddingRight:16 }}>
                            <DInput
                                id="profileName"
                                label="Nome do perfil"
                                placeholder="Nome do perfil"
                                error={ nameRequired || props.registryDuplicaded }
                                helperText={ nameRequired  ? !duplicate ? 'Campo obrigatório' : 'Utilize um nome diferente' : props.registryDuplicaded ? 'O nome já esta cadastrado no sistema' : '' }
                                type="text"
                                onChange={ e => {
                                    if (nameRequired) {
                                        setNameRequired(false);
                                    }

                                    setPerfilName(e.target.value);
                                }}
                                value={ perfilName }
                            />
                        </Grid>
                        <Grid item md={4} style={{ display: "flex", alignItems: "flex-end"}}>
                            <DInput
                                id="documents"
                                label="Documentação que será exigida"
                                readOnly={ true }
                                error={ docRequired }
                                helperText={ docRequired ? 'Campo obrigatório' : '' }
                                type="text"
                                fullWidth={true}
                                positionIcon='end'
                                icon={ expandMore ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                                value={ documentsName.length ? documentsName.join(', ') : ''}
                                onClick={handleClick}
                            />
                            <Popover
                                id={id}
                                open={openpop}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <ul className={ classes.doc_popover }>
                                    <>
                                        { (props.documents && props.documents.length)
                                            ?
                                                <>
                                                    { props.documents.map(function(item){
                                                        if(item.id >= 7){
                                                            return (
                                                                <li className={ classes.doc_popoverItem }>
                                                                    <Checkbox  
                                                                        defaultChecked={ selectedDocuments.find( doc => doc == item.id ) ? true : false }
                                                                        onClick={ e => {
                                                                            setShowTotal(false);
                                                                            if (docRequired) {
                                                                                setDocRequired(false);
                                                                            }
                                                                            var docExists = selectedDocuments.find( doc => doc == item.id );

                                                                            if(docExists) {
                                                                                var index = selectedDocuments.indexOf(docExists);

                                                                                const spliceSelectedDocuments = [...selectedDocuments]
                                                                                const spliceDocumentsName     = [...documentsName]

                                                                                spliceSelectedDocuments.splice(index, 1);
                                                                                spliceDocumentsName.splice(index, 1);
                                                                                
                                                                                setSelectedDocuments(spliceSelectedDocuments);
                                                                                setDocumentsName(spliceDocumentsName);
                                                                            } else {
                                                                                setSelectedDocuments(prevState => [...prevState, item.id]);
                                                                                setDocumentsName(prevState => [...prevState, item.description]);
                                                                            }
                                                                        }}
                                                                    /> {item.description}
                                                                </li>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            :
                                                ''
                                        }
                                    </>
                                </ul>
                            </Popover>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop:32 }}>
                        <Typography>Selecione as funcionalidades que o perfil terá acesso</Typography>

                        { props.permission.map( function(permissionGroup, indexPermission) {

                            return (
                                <Accordion className={ classes.accordion }>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                <Typography className={classes.accordion_heading}>{permissionGroup.name} { (showTotal) ? showSelecionados(permissionGroup.id) : "" }</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            { permissionGroup.roles.map( function(groupRole) {
                                                return (
                                                    <Grid item md={12}>
                                                        <Accordion className={ classes.subaccordion }>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                            >
                                                                <Typography className={classes.subaccordion_heading}>{groupRole.name}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Grid container>
                                                                    <Grid item md={12}>
                                                                        <ul className={ classes.doc_popover }>
                                                                            <li className={ classes.doc_popoverItem }>
                                                                                <SelectAllCheckbox  
                                                                                    onClick={ e => {
                                                                                        setShowTotal(false);
                                                                                        if (e.target.checked) {
                                                                                           
                                                                                            groupRole.role_permission.map( function(rolePermission) {
                                                                                                var permissionExists = selectedPermissions.find( doc => doc == rolePermission.permission_id );

                                                                                                if (!permissionExists) {
                                                                                                    setSelectedPermissions(prevState => [...prevState, rolePermission.permission_id]);
                                                                                                }
                                                                                            });

                                                                                        } else {
                                                                                           
                                                                                            const splicePermissions = [...selectedPermissions]

                                                                                            groupRole.role_permission.map( function(rolePermission) {

                                                                                                var permissionExists = selectedPermissions.find( doc => doc == rolePermission.permission_id );

                                                                                                if (permissionExists) {
                                                                                                    var index = splicePermissions.indexOf(permissionExists);
                                                                                                    splicePermissions.splice(index, 1);
                                                                                                    setSelectedPermissions(splicePermissions);
                                                                                                }
                                                                                            });

                                                                                            setSelectedPermissions(splicePermissions);

                                                                                        }   
                                                                                    }}
                                                                                /> Selecionar tudo
                                                                            </li>
                                                                        </ul>
                                                                    </Grid>
                                                                    { groupRole.role_permission.map( function(rolePermission) {
                                                                        return (
                                                                            <Grid item md={6}>
                                                                                <ul className={ classes.doc_popover }>
                                                                                    <li className={ classes.doc_popoverItem }>
                                                                                        <Checkbox
                                                                                            defaultChecked={ selectedPermissions.find( doc => doc == rolePermission.permission_id ) ? true : false }
                                                                                            checked={ selectedPermissions.find( doc => doc == rolePermission.permission_id ) ? true : false }
                                                                                            onChange={ e => {
                                                                                                setShowTotal(false);
                                                                                                var permissionExists = selectedPermissions.find( doc => doc == rolePermission.permission_id );

                                                                                                if(permissionExists) {
                                                                                                    var index = selectedPermissions.indexOf(permissionExists);
                    
                                                                                                    const splicePermissions = [...selectedPermissions]
                                                                                                    splicePermissions.splice(index, 1);

                                                                                                    setSelectedPermissions(splicePermissions);
                                                                                                } else {
                                                                                                    setSelectedPermissions(prevState => [...prevState, rolePermission.permission_id]);
                                                                                                }
                                                                                            }}
                                                                                        /> {`${rolePermission.permission.name}`}
                                                                                    </li>
                                                                                </ul>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions classes={{root: classes.footer}}>
                    <Grid container spacing={1} className="tema-light" justifyContent="flex-end">
                        <Grid item>
                            <Button
                                size="sm"
                                variant="outlined"
                                className={ classes.backButton }
                                onClick={ e => {
                                    closeModal()
                                } }
                            >
                                Voltar
                            </Button>
                            <Button
                                size="sm"
                                startIcon={<SaveIcon style={{ fontSize: 20 }} />}
                                className={ classes.saveButton }
                                onClick={ e => {
                                    if(!props.loading) {
                                        validarCampos();
                                        setCtrlSubmit(true);
                                    }
                                } }
                            >
                                { props.loading ? <CircularProgress size={ 15 } color="inherit" /> : 'Salvar' }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};