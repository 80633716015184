import axios from "axios";
import env from "react-dotenv";

export function recoverid(dados) {

  return axios.post(`${env.API_URL}/recoverid`, {
    type_action: dados.typeAction,
    value: dados.typeAction === 'sms' ? dados.phone : dados.email
  }).then(res => res.data);
}

export function userid(code1, code2, code3, code4, code5, token) {

  return axios.post(`${env.API_URL}/userid`, {
    code: [code1, code2, code3, code4, code5].join("")
  }, {
    headers: {
        "X-Auth-Token": token,
        "content-type": "application/json"
    }
  }).then(res => res.data);
}

export function resendcode(token, type_action) {

  return axios.post(`${env.API_URL}/recoverid/resend`, {
    type_action
  }, {
    headers: {
        "X-Auth-Token": token,
        "content-type": "application/json"
    }
  }).then(res => res.data);
}
