import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import VisualizarExame from '../../../../../VisualizadorExames';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNewHealthAttendance } from '../../../../../../context/NewHealthAttendance';
import DButton from '../../../../DButton';
import ModalInterconsulta from '../ModalInterconsulta';
import ModalAbdicar from '../../../Components/ModalAbdicar';
import { Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import ModalPDF from '../ModalPDF';
import "./index.scss";
import { anamneseFormByFilter, createAnamnesePreForm } from '../../../../../../api/anamnese';
import { createHealthAttendance, listHealthAttendanceById, uploadHealthAttendanceFile } from '../../../../../../api/healthAttendance';
import { toast } from 'react-toastify';
import { required } from '../../../../../../helpers/validacoes';
import useError from '../../../../../../hooks/useError';
import { getAllSymptoms } from '../../../../../../api/symptom';
import { getAllComorbidities } from '../../../../../../api/comorbidity';
import { listMedicines } from '../../../../../../api/medicine';
import { useAnamneseFormFilter } from '../../../../../../context/AnamneseFormFilterContext';
import { PatientContext, usePatient } from '../../../../../../context/usePatient';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import LineCares from "../../../../../../api/lineCare";
import Alergias from "./../../../../../../assets/dados/alergias.json";
import ComorbidityFamily from "../../../../../../api/comorbidityFamilyHistory";
import DAutoComplete from '../../../../DAutoComplete';
import { AnamneseFormFilterProvider } from "./../../../../../../context/AnamneseFormFilterContext";
import PreAtendimento from "./componentes/PreAtendimento";

function ModalNovaSolicitacao(props) {
  
  const { healthAttendance } = useNewHealthAttendance();

  const handleClose = () => props.onClose(true);

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx className="estilo_modal_inter estilo_modal_inter_max">
          <div className="flex_modal_inter">
            <div className="fonte_titulo_modal_inter">&nbsp;</div>
            <div className="flex_end_modal_inter">
              <Button onClick={handleClose}>
                <CloseIcon className="icone_modal_inter" />
              </Button>
            </div>
          </div>
          <div className="fundo_new_tab">
            <AnamneseFormFilterProvider>
              <PreAtendimento onHealthAttendance={ e => props.onClose(true) }>
                <div className="box_info_solicitar_atendimento mt_35_new_tab">
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <DButton type="submit" className="botao_salvar_newtab background-color" size="sm">
                        Solicitar Atendimento
                      </DButton>
                    </Grid>
                  </Grid>
                </div>
              </PreAtendimento>
            </AnamneseFormFilterProvider>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

ModalNovaSolicitacao.defaultProps = {
  open: false,
  onClose: function() {
    console.log("onClose")
  }
}

export default ModalNovaSolicitacao;
