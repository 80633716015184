import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss";
import VideocamIcon from '@mui/icons-material/Videocam';

// Routes
import { 
  auth,
  getSalaChat,
  updateSalaChat,
} from '../../../api/chatService';
import { getUserInfoById } from '../../../api/user';

// Toast
import { toast } from "react-toastify";

export default function ModalIncomingCall(props) {
  const [open, setOpen] = useState(false);
  const [nome, setNome] = useState('');
  const [dadosCall, setDadosCall] = useState(null);
  const [dadosChat, setDadosChat] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [tokenJWT, setTokenJWT] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false); props.setCloseModal(true) };

  useEffect( async () => {
    if (props.open && !chatStarted) {
      await getUserInfoById(localStorage.getItem("token"), localStorage.getItem("uid")).then( async (res) => {
        if (res.error) {
          toast.error("Ocorreu um erro ao abrir a chamada");
          console.log(res.error);
        } else {
          var json = {
            app: "telemed-sp-dev", 
            chave: "ec3a2fa3-9c60-4048-bd25-50ad8bd674a0", 
            usuario: res.document.find(res => res.type == 'CPF').fields[0].value,
            nome: res.user.name,
            celular: "+55" + res.contact.find(res => res.type == 'telefone').value.replace(/[^0-9]/g, ""),
            perfil: "usuario"
          } 

          if (json.usuario && json.celular && json.nome) {
            await auth(json).then( async (res) => {
              if (res.status_ret != 'NOK') {
                setNome(res.nome);
                setTokenJWT(res.tokenJwt);
                setDadosChat(props.dadosChat);
                setDadosCall(props.dadosCall);

                handleOpen();

                setChatStarted(true);
              } else {
                toast.error("Ocorreu um erro ao abrir a chamada");
                console.log(res.erro);
              }
            }).catch( (err) => {
              toast.error("Ocorreu um erro ao abrir a chamada");
              console.log(err);
            });
          }
        }
      }).catch( (err) => {
        toast.error("Ocorreu um erro ao abrir a chamada");
        console.log(err);
      });
    } else if (props.open && chatStarted) {
      setDadosChat(props.dadosChat);
      setDadosCall(props.dadosCall);

      handleOpen();
    }
  }, [props.open]);

  async function openCall() {
    if (dadosCall.tipoChamada == 1) {
      await getSalaChat(tokenJWT).then( async (res) => {
        var chat = res.find(res => res.chaveConversa == dadosChat.id);

        if (chat.status != 0) {
          var payload = {
            operacao: "updatestatus",
            chaveConversa: dadosChat.id,
            Status: 0,
            tipoChamada: dadosCall.tipoChamada
          }
    
          await updateSalaChat(tokenJWT, payload).then(async (resUpdate) => {
            props.openCall(true);
            handleClose();
          }).catch((err) => {
            toast.error("Ocorreu um erro ao atender a chamada. Tente novamente.");
            console.log(err);
          });
        } else {
          toast.error("Esta chamada foi atendida por outra pessoa");
          handleClose();
        }
      }).catch( (err) => {
        console.log(err);
        toast.error("Ocorreu um erro ao atender a chamada. Tente novamente.");
        handleClose();
      });
    } else {
      props.openCall(true);
      handleClose();
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className='estilo_modal_chamada'>
          <div>
            <VideocamIcon className='icone_modal_chamada' />
          </div>
          <div className='mt20_modal_chamada fonte_descricao_modal_chamada'>
            Recebendo uma chamada de:
          </div>
          <div className='mt30_modal_chamada fonte_descricao_modal_chamada'>
            { dadosChat && dadosChat.nome }
          </div>
          <div className='alinha_botoes_modal_chamada mt50_modal_chamada'>
            <Button className='botao_cancelar_chamada' onClick={handleClose}>
              Recusar
            </Button>
            <Button className='botao_confirmar_chamada' onClick={ e => openCall() }>
              Aceitar
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}