import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#F4F4F4',
        marginTop: 5,
        height: '100%'
    }
}))

function Sidebar(props) {

    const classes = useStyles();

    return (
        <Grid container direction="column" alignItems="center" classes={{root: classes.root }}>
            <Grid item md={12}>{ props.children }</Grid>
        </Grid>
    )
}

export default Sidebar;