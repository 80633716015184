import axios from "axios";
import env from "react-dotenv";

function getAllUser(token, page) {
  return fetch(`${env.API_URL}/user/list?token=${token}&page=${page}`, {
    method: "post",
  }).then((res) => res.json());
}

function getMedicalUnitesById(token, profileId) {
  return axios
    .post(`${env.API_URL}/user/profiles?token=${token}`, {
      profile_id: profileId,
    })
    .then((res) => res.data)
    .then((data) => {
      //console.log(data);
      if (data.hasOwnProperty("status") && data.status == true) {
        return data;
      }
    });
}

export function getMedicalUnites(token) {
  return axios
    .post(`${env.API_URL}/medicalunit?token=${token}`)
    .then((res) => res.data);
}

export function getProfileId(token) {
  return axios
    .post(`${env.API_URL}/user/profiles?token=${token}`, {})
    .then((res) => res.data)
    .then((data) => {
      if (data.status == true) {
        if (Array.isArray(data.profiles)) {
          return data.profiles;
        }
      } else {
        throw data;
      }
    });
}

export function getPermissionSelect(token, profileId = undefined) {
  var body = {};

  if (profileId)
    body = {
      profile_id: profileId,
    };

  return axios.post(`${env.API_URL}/user/profiles?token=${token}`, body);
}

export async function filterConference(body) {
  const response = await axios.post(`${env.API_URL}/conference/filter-conference?token=${localStorage.getItem("token")}`, body)
  
  if(response instanceof Error)
  {
    throw response;
  }
  
  return response.data;
}

export async function filterVoip(body) {
  const response = await axios.post(`${env.API_URL}/call/filter-voip?token=${localStorage.getItem("token")}`, body);
 
  return response.data;
}

export function getVoipToken(token) {
  return axios
    .post(`${env.API_URL}/call/calling?token=${token}`)
    .then((res) => res.data);
}

export function getVoipCallToken(token) {
  return axios
    .post(`${env.API_URL}/call/token?token=${token}`)
    .then((res) => res.data);
}

export function createVoipConference(token, phoneNumber, roomName) {
  return axios
    .post(`${env.API_URL}/call/conference/create?token=${token}`, {
      phone_number: phoneNumber,
      nameRoom: roomName,
    })
    .then((res) => res.data);
}

/**
 * @param {*} token
 * @param {*} id
 * @param {object} data
 * @param {string?} data.to_type
 * @param {string?} data.to
 * @param {string} data.duration
 * @param {string?} data.health_attendance_id
 */
export function updateCall(token, id, data) {
  return axios
    .put(`${env.API_URL}/call/${id}?token=${token}`, data)
    .then((res) => res.data);
}

export function addParticipantVoipConference(
  token,
  {
    phoneNumber,
    nameParticipant,
    nameRoom,
    health_attendance_id,
    to_type = "patient",
  }
) {
  return axios
    .post(`${env.API_URL}/call/conference/add-participant?token=${token}`, {
      phone_number: phoneNumber,
      nameParticipant,
      nameRoom,
      health_attendance_id,
      to_type,
    })
    .then((res) => res.data);
}

export function updateUserAvatar(token, file) {
  var data = new FormData();

  data.append("file", file);

  return fetch(`${env.API_URL}/user/avatar?token=${token}`, {
    method: "post",
    body: data,
  }).then((res) => res.json());
}

export function removeUserAvatar(token) {
  return fetch(`${env.API_URL}/user/destroy-avatar?token=${token}`, {
    method: "delete",
  }).then((res) => res.json());
}

export function updateUser(token, userId, dados) {
  return axios
    .put(`${env.API_URL}/user/${userId}?token=${token}`, dados)
    .then((res) => res.data);
}

export function updateCustomUser(token, userId, dados) {
  return axios
    .patch(`${env.API_URL}/user/${userId}/update-user?token=${token}`, dados)
    .then((res) => res.data);
}

function createUserMedicalUnit(token, dados, id) {
  return fetch(`${env.API_URL}/user/${id}/profiles/add?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

export function updateUserProfile(token, dados, id) {
  return fetch(`${env.API_URL}/user/${id}/profiles/update?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteUserMedicalUnit(token, dados, id) {
  return fetch(`${env.API_URL}/user/${id}/profiles/delete?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteUser(token, id) {
  return getUserInfoById(token, id).then((data) => {
    if (data.status) {
      const { user_invite } = data.user;

      console.log("user-invite", user_invite);

      if (user_invite) {
        return fetch(
          `${env.API_URL}/user/${user_invite.id}/invite?token=${token}`,
          {
            method: "delete",
          }
        ).then((res) => res.json());
      } else {
        throw new Error("Usuário sem convite");
      }
    } else {
      throw new Error(data);
    }
  });
}

function blockUser(token, id) {
  return fetch(`${env.API_URL}/user/${id}/blocked?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

function unblockUser(token, id) {
  return fetch(`${env.API_URL}/user/${id}/unblocked?token=${token}`, {
    method: "put",
  }).then((res) => res.json());
}

/**
 * @param {*} token
 * @param {object} params
 * @param {*?} params.patient_id
 * @param {*?} params.health_attendance_id
 * @param {*?} params.search
 * @param {*?} params.type
 */
export function searchPrescriptions(token, params) {
  var uri = new URL(`${env.API_URL}/prescription/filter`);

  var params = getParams(params); // Separa os parâmetros a serem filtrados

  if (params && Array.isArray(params)) {
    // Monta o filtro para pesquisa de prescrições
    params.forEach((param) => {
      uri.searchParams.append(param.key, param.value);
    });
  }
  uri.searchParams.append("token", token);

  return axios
    .get(uri)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

export function getPrescriptions(token, healthAttendanceId) {
  return axios
    .get(
      `${env.API_URL}/prescription/${healthAttendanceId}/listByhealthAttendanceId?token=${token}`
    )
    .then((res) => res.data)
    .catch((err) => console.error(err));
}

export function getPrescriptionsByPatientId(token, patientId, page) {
  var url = "";

  if (page) {
    url = `${env.API_URL}/prescription/${patientId}/listByPatientId?page=${page}&token=${token}`;
  } else {
    url = `${env.API_URL}/prescription/${patientId}/listByPatientId?token=${token}`;
  }

  return axios.get(url).then((res) => res.data);
}

function resendInvite(token, id) {
  return fetch(`${env.API_URL}/user/${id}/resend-invite?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

export function initializePrescription(token, healthAttendanceId, cid10 = []) {
  return axios
    .post(`${env.API_URL}/prescription/initiate?token=${token}`, {
      healthAttendanceId: healthAttendanceId,
      cid10
    })
    .then((res) => {
      if (res.status === 404 || res.status === 400) throw new Error(res.data);

      return res.data;
    })
    .catch(err => {
      console.log(err);
      throw err;
    })
}

export function finishPrescription(token, prescriptionId, data) {
  return axios
    .post(
      `${env.API_URL}/prescription/${prescriptionId}/finish?token=${token}`,
      data
    )
    .then((res) => {
      if (res.status == 404) throw new Error(res.data);

      return res.data;
    });
}

export function finishPrescriptionDocument(token, prescriptionId, document) {
  return axios
    .post(
      `${env.API_URL}/prescription/${prescriptionId}/finishPrescriptionDocument?token=${token}`,
      document
    )
    .then((res) => res.data);
}

export function requestUpdatePassword(token) {
  return axios
    .get(`${env.API_URL}/user/request-update-password?token=${token}`)
    .then((res) => res.data);
}

export function updatePassword(token, code, password) {
  return axios
    .patch(`${env.API_URL}/user/update-password?token=${token}`, {
      code,
      password,
    })
    .then((res) => res.data);
}

export function verifyResetPasswordCode(token, code) {
  return axios
    .post(`${env.API_URL}/user/verify-request-code?token=${token}`, { code })
    .then((res) => res.data);
}

function getPossibleUserProfiles(token) {
  return axios
    .post(`${env.API_URL}/profile/list?token=${token}`)
    .then((res) => res.data)
    .then((data) => {
      if (data.status && data.status == true) {
        return data;
      } else {
        throw data.status;
      }
    })
    .catch((err) => console.error(err));
}

export function showAvatar(token) {
  return axios
    .get(`${env.API_URL}/user/show-avatar?token=${token}`)
    .then((res) => res.data);
}

export function uploadAvatar(token, image) {
  return axios
    .post(`${env.API_URL}/user/avatar?token=${token}`, {
      file: image,
    })
    .then((res) => res.data);
}

function getMe(token) {
  return fetch(`${env.API_URL}/me?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getMeLogin(token) {
  return fetch(`${env.API_URL}/me-login?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

export function getUserInfoById(token, id) {
  return axios
    .get(`${env.API_URL}/user/${id}/show?token=${token}`)
    .then((res) => res.data);
}

function getMeByInvite(token) {
  return fetch(`${env.API_URL}/user/verify-invite?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function createUser(token, dados) {
  console.log(dados);

  var documents = [
    {
      type: "CPF",
      fields: [
        {
          id: 1,
          value: dados.cpf,
        },
      ],
    },
    {
      type: "CNS",
      fields: [
        {
          id: 9,
          value: dados.cns,
        },
      ],
    },
  ];

  if (dados.nacionalidade == 1) {
    documents.push({
      type: "RG",
      fields: [
        {
          id: 2,
          value: dados.identidadeNumero.replace(/[^0-9]/gm, ""),
        },
        {
          id: 3,
          value: new Date(dados.identidadeEmissao).toLocaleDateString(),
        },
        {
          id: 4,
          value: dados.identidadeOrgaoEmissor,
        },
        {
          id: 60,
          value: dados.identidadeUF,
        },
      ],
    });
  }

  if (dados.nacionalidade == 2) {
    documents.push({
      type: "NATURALIZED",
      fields: [
        {
          id: 15,
          value: new Date(dados.dataNaturalizacao).toLocaleDateString(),
        },
        {
          id: 16,
          value: dados.paisNascimento,
        },
      ],
    });
  }

  if (dados.nacionalidade == 3) {
    documents.push({
      type: "PASSPORT",
      fields: [
        {
          id: 5,
          value: dados.passaporteNumero,
        },
        {
          id: 6,
          value: new Date(dados.passaporteDataEmissao).toLocaleDateString(),
        },
        {
          id: 7,
          value: dados.passaportePaisEmissor,
        },
        {
          id: 8,
          value: new Date(dados.passaporteDataValidade).toLocaleDateString(),
        },
      ],
    });
  }

  // documents.push(...dados.conselhos.map(function(item){
  //     return {
  //             "type": "CONSELHO",
  //             "fields": [
  //                 {
  //                     "id": 10,
  //                     "value": item.conselho
  //                 },
  //                 {
  //                     "id": 11,
  //                     "value": item.descricao
  //                 },
  //                 {
  //                     "id": 12,
  //                     "value": item.codigo
  //                 },
  //                 {
  //                     "id": 13,
  //                     "value": item.uf
  //                 },
  //                 {
  //                     "id": 14,
  //                     "value": item.descricao
  //                 }
  //             ]
  //         }
  // }));

  if (dados.conselhos) {
    documents.push(...dados.conselhos);
  }

  var nacionalidade;

  switch (parseInt(dados.nacionalidade)) {
    case 1:
      nacionalidade = "brasileiro";
      break;
    case 2:
      nacionalidade = "naturalizado";
      break;
    case 3:
      nacionalidade = "estrangeiro";
      break;
    default:
      nacionalidade = null;
      break;
  }

  if (dados.especialidade && Array.isArray(dados.especialidade)) {
    let _temp = dados.especialidade.map((x) => {
      return {
        medical_speciality_id: x.id,
      };
    });

    dados.especialidade = _temp;
  }

  var new_dados = {
    user: {
      presentation: dados.apresentacao,
      name: dados.nome,
      social_name: dados.nomeSocial,
      birth_date: new Date(dados.dataNascimento).toLocaleDateString(),
      email: dados.email,
      nationality: nacionalidade,
      password: dados.senha,
      mother_name: dados.nomeMae,
      username: dados.id
    },
    demographic_data: {
      state_birth: dados.estadoNascimento,
      city_birth: dados.municipioNascimento,
      sex: dados.sexo,
      genre: dados.genero,
      breed: dados.raca,
      etnia: dados.etnia,
      sexual_orientation_id: dados.orientacaoSexual,
    },
    contact: dados.telefones.map(function (telefone) {
      return {
        type: "telefone",
        value: telefone.telefone,
        observation: telefone.tipoTelefone,
      };
    }),
    address: {
      address_name: "Residêncial",
      zip_code: dados.cep.replace(/[^0-9]/gm, ""),
      street: dados.endereco,
      number: dados.numero,
      district: dados.bairro,
      city_id: dados.municipio,
      address_type_id: dados.logradouro,
      complement: dados.complemento,
    },
    document: documents,
    speciality: dados.especialidade,
  };

  return fetch(`${env.API_URL}/user/store-by-invite?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(new_dados),
  }).then((res) => res.json());
}

function getProfissionalByFilter(
  token,
  active,
  cpf,
  cns,
  name,
  profile,
  medical_unit
) {
  return fetch(
    `${env.API_URL}/user/list/filter?active=${active}&cpf=${cpf}&cns=${cns}&name=${name}&profile_id=${profile}&medical_unit_id=${medical_unit}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function getParams(params) {
  if (typeof params === "object") {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

function listUserByMedicalUnit(token, medical_unit) {
  return fetch(
    `${env.API_URL}/user/list/filter?medical_unit_id=${medical_unit}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function listUserByMedicalUnitNotPaginated(token, medical_unit) {
  return fetch(
    `${env.API_URL}/user/list/filter/not-paginated?medical_unit_id=${medical_unit}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function listChatUserByMedicalSpeciality(
  token,
  medicalUnitId,
  medicalSpecialityId
) {
  return fetch(
    `${env.API_URL}/user/list-medical-speciality/filter?medical_unit_id=${medicalUnitId}&medical_speciality_ids=${medicalSpecialityId}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

export function filterProfessional(token, paramsFilter) {
  var uri = new URL(`${env.API_URL}/user/list/filter`);

  if (paramsFilter && paramsFilter.id) {
    return axios
      .get(`${env.API_URL}/healthAttendance/${paramsFilter.id}?token=${token}`)
      .then((res) => res.data);
  }

  var params = getParams(paramsFilter); // Separa os parâmetros a serem filtrados

  if (params && Array.isArray(params)) {
    // Monta o filtro para pesquisa de profissionais
    params.forEach((param) => {
      uri.searchParams.append(param.key, param.value);
    });
  }

  uri.searchParams.append("token", token);

  return axios.get(uri).then((res) => res.data);
}

export function getUserAvatar(token) {
  return axios
    .get(`${env.API_URL}/user/show-avatar?token=${token}`)
    .then((res) => res.data);
}

function getAllUserStatus(token) {
  return fetch(`${env.API_URL}/user/total-status?&token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getMenusByProfileAndUnity(token, medical_unit_id, profile_id) {
  return fetch(
    `${env.API_URL}/acl?profile_id=${profile_id}&medical_unit_id=${medical_unit_id}&token=${token}`,
    {
      method: "post",
    }
  ).then((res) => res.json());
}

function listUserByMedicalSpeciality(
  token,
  medicalUnitId,
  medicalSpecialityId
) {
  return fetch(
    `${env.API_URL}/user/list-medical-speciality-by-medical-unit/filter?requester_medical_unit_id=${medicalUnitId}&medical_speciality_ids=${medicalSpecialityId}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function listMedicalUnitOnlineUsers(token, medicalUnitId) {
  return fetch(
    `${env.API_URL}/user/list-logged?medical_unit_id=${medicalUnitId}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function getDefaultPermission(token, perfilId) {
  let params = perfilId ? `profileId=${perfilId}&token=${token}` : `token=${token}`;
  return fetch(`${env.API_URL}/user/default-permission?${params}`, {
    method: "get",
  }).then((res) => res.json());
}

export {
  getAllUser,
  getMe,
  getMeLogin,
  getMeByInvite,
  createUser,
  createUserMedicalUnit,
  deleteUserMedicalUnit,
  blockUser,
  unblockUser,
  deleteUser,
  resendInvite,
  getMedicalUnitesById,
  getPossibleUserProfiles,
  getProfissionalByFilter,
  getAllUserStatus,
  getMenusByProfileAndUnity,
  listUserByMedicalSpeciality,
  listUserByMedicalUnit,
  listUserByMedicalUnitNotPaginated,
  listChatUserByMedicalSpeciality,
  listMedicalUnitOnlineUsers,
  getDefaultPermission,
};
