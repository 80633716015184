import lightBlue from "@material-ui/core/colors/lightBlue";
import { createMuiTheme } from "@material-ui/core";

const monthTheme = createMuiTheme({
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          marginTop: 22,
          border: "solid #F4F4F4 1px",
          borderRadius: 8,
          padding: 24
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: lightBlue.A200,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
            display: "block !important"            
        },
        daysHeader: {
            display: "none"
        },
        iconButton: {
            display: "none"
        },
      },
      MuiPickersSlideTransition: {
        transitionContainer: {
          textTransform: "capitalize"
        }
      },
      MuiPickersDay: {
        day: {
          color: "#4A4AE9",
          fontWeight: 700
        },
        daySelected: {
          backgroundColor: "#4A4AE9",
          color: '#FFF !important'
        },
        dayDisabled: {
          color: "#000",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: lightBlue["400"],
        },
      },
      MuiPickersBasePicker: {
        pickerView: {
            maxWidth: 365
        }
      }
    },
});

const weekTheme = createMuiTheme({
    overrides: {
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          marginTop: 22,
          border: "solid #F4F4F4 1px",
          borderRadius: 8,
          padding: 24
        }
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: lightBlue.A200,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          display: "none !important"
        },
        iconButton: {
            display: "none"
        }
      },
      MuiPickersSlideTransition: {
        transitionContainer: {
            textAlign: "center"
        }
      },
      MuiPickersDay: {
        day: {
          color: "#4A4AE9",
          fontWeight: 700
        },
        daySelected: {
          backgroundColor: "#4A4AE9",
          color: '#FFF !important'
        },
        dayDisabled: {
          color: "#000",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: lightBlue["400"],
        },
      },
    },
});

const yearTheme = createMuiTheme({
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        marginTop: 22,
        border: "solid #F4F4F4 1px",
        borderRadius: 8,
        padding: 24
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
          display: "none !important"            
      },
      daysHeader: {
          display: "none"
      },
      iconButton: {
          display: "none"
      },
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        textTransform: "capitalize"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#4A4AE9",
        fontWeight: 700
      },
      daySelected: {
        backgroundColor: "#4A4AE9",
        color: '#FFF !important'
      },
      dayDisabled: {
        color: "#000",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
          maxWidth: 365
      }
    }
  },
});

export { monthTheme, weekTheme, yearTheme }