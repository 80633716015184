import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Modal, Grid, Typography} from '@material-ui/core';
import {Clear} from "@material-ui/icons";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: 500,
            // top: "10%",
            // left: "50%",
            // marginLeft: "-152px",
            backgroundColor: theme.palette.background.snow,
            border: `1px solid ${theme.palette.neutral.midgrey}`,
            // boxShadow: theme.shadows[5],
            borderRadius: 8
        },
        headermodal: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            borderBottom: "solid transparent 1px"
        },
        footermodal: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: 16,
            borderTop: "solid rgba(199, 199, 199, 1) 1px"
        },
        crossIcon: {
            color: '#3A404C'
        }
    }),
);

function ConfirmCustom(props) {
    const classes =  useStyles();        
    const [modalStyle] = useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={ classes.paper }>
            <div className={ `${ classes.headermodal } tema-light` }>
                <Grid container justifyContent="flex-end">
                    { props.title && <Grid item>
                        <Typography classes={{ root: classes.textColorRed }} variant="body1" className="text-bold text-mt">{ props.title }</Typography>
                    </Grid> }                    
                    <Grid item>
                        <Clear onClick={e => props.onClose() } color="primary" classes={{ colorPrimary: classes.crossIcon }} />
                    </Grid>
                </Grid>
            </div>            
            {props.children}
            <ConfirmCustom />
            { props.footer &&
                <div className={ `${ classes.footermodal } tema-light` }>
                    { props.footer }
                </div>            
            }
        </div>
    );

    return (
        <div>
            <Modal
                open={props.open}
                onClose={e => props.onClose(true)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}

ConfirmCustom.defaultProps = {
    title: "",
    open: false,
    footer: null,
    onClose: function() {
        console.log("onClose");
    }
}

export default ConfirmCustom;