import { useState, useContext, useEffect } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Button, Container, Grid, Typography, useTheme } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { makeStyles } from "@material-ui/core/styles";
import { PatientContext, usePatient } from "../../../context/PatientContext";

const useStyles = makeStyles((theme) => ({
  sideBar: {
    minWidth: "20%",
    maxWidth: 228,
    marginLeft: "-20%",
    paddingBottom: 50,
    marginTop: "-15px",
    paddingTop: 15,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  sideBarOpen: {
    minWidth: "20%",
    maxWidth: 228,
    marginLeft: "0",
    zIndex: 10,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    borderRight: `solid ${theme.palette.primary.main}`,
    marginTop: "-15px",
    paddingTop: 15,
  },
  sideBarButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  sideBarButtonOpen: {
    left: "20%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  content: {
    minWidth: "80%",
    maxWidth: "80%",
    paddingBottom: 50,
    "@media(minWidth: 768)": {
      minWidth: "100%",
    },
  },
  contentFull: {
    minWidth: "100%",
    // paddingBottom: 50,
    width: "100%",
    overflow: "auto",
    height: "calc(100vh - 148px)", // 50px: footer, 72px: navbar, 26px: profile menu
  },
  footer: {
    width: "98%",
    height: 20,
    background: "#FFF",
    padding: "15px 1%",
    position: "fixed",
    bottom: 0,
    zIndex: 10,
  },
  hideSidebarButton: {
    display: "none",
  },
}));

function LayoutAdmin(props) {
  const classes = useStyles();

  const [sidebar, setSidebar] = useState(
    props.sidebarOpen ? props.sidebarOpen : false
  );

  const [notSidebar, setNotSidebar] = useState(
    props.notSidebar ? props.notSidebar : false
  );

  const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);

  useEffect(() => {
    setSidebar(false);
    if(props.notSidebar !== undefined) {
      setNotSidebar(props.notSidebar);
    }
  }, [props.notSidebar]);

  useEffect(() => {
    if(sideBarInternal !== undefined) {
      setNotSidebar(!sideBarInternal);
    }
  }, [sideBarInternal]);

  return (
    <>
      <Navbar active={props.active} open={(e) => setSidebar(true)} />
      <Container maxWidth={false} disableGutters={true}>
        <Grid container>
          <Grid
            item
            classes={{ root: sidebar ? classes.sideBarOpen : classes.sideBar }}
          >
            <Sidebar
              style={{ display: `${notSidebar ? "none" : "block"}` }}
              open={sidebar}
              onClose={(e) => setSidebar(false)}
            >
              {props.sidebar}
            </Sidebar>
          </Grid>
          <Grid
            item
            classes={{ root: sidebar ? classes.content : classes.contentFull }}
          >
            {props.children}
          </Grid>
        </Grid>
        {!notSidebar ? (
          <Button
            classes={{
              root: sidebar ? classes.sideBarButtonOpen : classes.sideBarButton,
            }}
            className={`menu-button-sidebar ${
              props.hideSidebarButton ? classes.hideSidebarButton : null
            }`}
            onClick={(e) => {
              props.onSideBar(!sidebar);
              setSidebar(!sidebar);
            }}
          >
            {sidebar ? <MenuOpenIcon /> : <MenuIcon />}
          </Button>
        ) : (
          ""
        )}
      </Container>
      <footer className={classes.footer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1" className="text-regular text-st">
              TeleMed24h &copy; {new Date().getFullYear()} - DEV Build 0.0.1
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              className="text-regular text-st text-link"
            >
              Termos de uso e Política de privacidade
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default LayoutAdmin;
