import { useState } from "react";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Container, Typography, Card, CardContent } from  "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import DButton from "../../../shared/DButton";
import DInput from "../../../shared/DInput";
import DRadio from "../../../shared/DRadio";
import { updateType } from "../../../../api/healthAttendanceType";

function HealthAttendanceTypeUpdate(props) {
    const [validacao, setValidacao] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState(props.selectedType[2]);
    const [descricao, setDescricao] = useState(props.selectedType[3]);
    const [active, setActive] = useState(props.selectedType[1]);

    const style = {
        light: {
            background: '#FFF',
            padding: '12px'
        },
        dark: {
            background: '#272727',
            padding: '12px'
        },
        card: {
            background: 'transparent'
        },
        titleDark: {
            color: '#FFF'
        }
    }

    function alterarTipo (event, nome) {     
        event.preventDefault();

        if(nome.length === 0) {
            setValidacao(true);
        } else {
            var dados = {
                name: nome,
                description: descricao,
                active: true
            }

            setLoading(true);

            updateType(localStorage.getItem('token'), dados, props.selectedType[0]).then(res => {
                document.getElementById("formUpdate").submit();
            });
        }
    }

    return(
        <>
            <Grid container alignItems="center">
                <Grid item md={3}><h1>Tipo de Atendimento</h1></Grid>
                <Grid item md={9}>
                    <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid container justifyContent="flex-end" item md={4}>
                            <DButton onClick={ e => props.voltar(true) } variant="primary" icon={ <ArrowBackIcon fontSize="small" /> } size="md">
                                Voltar
                            </DButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={ 0 } alignItems="center" justify="center" className="tema-light">
                <Grid item xs={10}>
                    <Card style={ style.card }>
                        <CardContent align="center">
                            <Typography variant="h3" align="center">Alterar Tipo de Atendimento</Typography>
                            <Typography variant="p">&nbsp;</Typography>
                            <form id='formUpdate' autoComplete="off">
                                <div style={{ width: '80%', height: 180 }}>
                                    <DInput
                                        id="nome"
                                        label="Nome"
                                        type="text"
                                        fullWidth={true}
                                        positionIcon="end"
                                        value={nome}
                                        onChange={ e => {setNome(e.target.value); setValidacao(false);} }
                                        error={ validacao }
                                        helperText={ validacao ? 'Campo obrigatório' : '' }
                                    />
                                    <DInput
                                        id="descricao"
                                        label="Descrição"
                                        type="text"
                                        fullWidth={true}
                                        positionIcon="end"
                                        value={descricao}
                                        onChange={ e => setDescricao(e.target.value) }
                                    />
                                    <div style={{ textAlign: 'left', paddingTop: '15px' }}>
                                        <DRadio
                                            name="ativo"
                                            label="Ativo"
                                            inline={true}
                                            value={active}
                                            options={[
                                                { value: 1, label: 'Sim' },
                                                { value: 0, label: 'Não' }
                                            ]}
                                            onChange={ e => setActive(e.target.value) }
                                        />
                                    </div>
                                </div>
                                {loading == true 
                                    ?
                                    <DButton variant="primary" size="md">
                                        <CircularProgress color="inherit" />
                                    </DButton>  
                                    : 
                                    <DButton type="submit" onClick={ e => alterarTipo(e, nome) } variant="primary" size="md">
                                        Alterar
                                    </DButton>
                                }
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default HealthAttendanceTypeUpdate;