import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Backdrop, Grid, AppBar, Toolbar, IconButton, Typography, Container } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 3000,
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        position: 'fixed',
        paddingLeft: 0,
        paddingRight: 0,
        top: 0,
        left: 0,
        background: 'white'
    },
    navbar: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.light
    }
}));

function FormsConstrutor(props)
{
    const classes = useStyles();

    function Navbar()
    {
        return (
            <div className={classes.navbar}>
                <AppBar position="static" color="transparent">
                    <Toolbar variant="dense">
                    <IconButton onClick={ e => props.onClose(true) } edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <ArrowBackIosIcon className={classes.icon} />
                    </IconButton>
                    <Typography variant="h4" color="inherit">
                        Construtor de formulários
                    </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }

    return (
        <Container classes={{root: classes.root}}>    
            <Navbar />
            <iframe src={props.url} width="100%" height="100%" frameborder="0" />
        </Container>
    )
}

export default FormsConstrutor;