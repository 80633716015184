import React, { useState, useEffect } from 'react';
import "../index.scss";
import { Button, useTheme } from '@material-ui/core';
import PhoneIcon from '@mui/icons-material/Phone';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { TextField } from "@material-ui/core";
import AnamneseHistorico from './Components/AnamneseHistorico' //Componente que abrirá ao clicar em Anamnese no bodytab histórico do atendimento
import LigacoesHistorico from './Components/LigacoesHistorico' //Componente que abrirá ao clicar em ligacões no bodytab histórico do atendimento
import CustomModalLigacao from './Components/CustomModalLigacao' //Componente modal ligações
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { getHistoryByPatient } from  "./../../../../api/patient";
import { ArrowDownward, ArrowDropDown, ExpandMore, SyncAlt, SyncRounded } from '@material-ui/icons';
import { FilterBarContainer } from './Components/styles';
import { filterConference, filterVoip, getUserInfoById } from '../../../../api/user';
import {toast} from 'react-toastify';
import { getFormatDate } from '../../../../helpers/formatacao';


export default function HistoricoNewTab(props) {

  const {patient} = useNewHealthAttendance();

  const [ligacoes, setLigacoes] = useState(false);
  const [histories, setHistories] = useState([]);
  const [anamnese, setAnamnese] = useState(false);

  useEffect(() => {
	  if(patient)
		getHistoryByPatient(localStorage.getItem("token"), patient.id).then(res => {
			if(res.status)
			{
				console.log(res)
				setHistories(res.histories);
			}
		})
  }, [patient])

  return (
    <div>
      { ligacoes ?
        <LigacoesHistorico />
        :
        <div className='fundo_new_tab'>
			
			
          <div className='main_flex_new_tab'>
            <div className='fonte_new_tab text-primary'>
              Histórico do atendimento
            </div>
            <div className='flex_end_new_tab'>
              <Button className="botao_editar_new_tab text-primary" onClick={ e => setLigacoes(true) } startIcon={<PhoneIcon />}>
                Ligações
              </Button>
            </div>
          </div>
		  { anamnese ?
		  	<AnamneseHistorico anamnese={ anamnese } />	  			
		  	:
		  	<>
				{ histories.map(history => <>
					<div className='main_flex_new_tab'>
						<div className='fonte_new_tab text-primary mt_35_new_tab'>
							{ history.date }
						</div>
						<div className='flex_end_new_tab'>
							<Button disabled={ !(history.anamnese && history.anamnese[0]) } onClick={ e => {
								if(history.anamnese && history.anamnese[0])
									setAnamnese(history.anamnese[0].data)
								} } className="botao_editar_new_tab text-primary" startIcon={<MenuBookIcon />}>
								Anamnese
							</Button>
						</div>
					</div>
					<div className='mt_35_new_tab separa_inputs_new_tabs'>
						<div className='flex_2_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Período" variant="standard" readOnly value={ history.period } />
						</div>
						<div className='flex_1_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Hora" variant="standard" readOnly value={ history.hour } />
						</div>
						<div className='flex_2_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Telefone Contatado" variant="standard" readOnly value={ history.contacted_phone } />
						</div>
					</div>
					<div className='mt_35_new_tab separa_inputs_new_tabs'>
						<div className='flex_2_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Contato de" variant="standard" readOnly value={ history.contacted_from } />
						</div>
						<div className='flex_1_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Contato por enfermeiro(a)" variant="standard" readOnly value={ history.contacted_from_permissions.join(", ") } />
						</div>
						<div className='flex_2_inputs'>
							<TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Motivo de finalização" variant="standard" readOnly value={ history.reason_termination } />
						</div>
					</div>
				</>) }			  
			  </>
			
		}
          
        </div>
      }

    </div>

  )
}