import React, { useMemo } from "react";
import "../index.scss";
import { Chip, TextField } from "@material-ui/core";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Grid, Divider } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import { useState } from "react";
import { useNewHealthAttendance } from "../../../../context/NewHealthAttendance";
import { useEffect } from "react";

export default function UnidadesVinculadasView(props) {
  const { patient, setPatient } = useNewHealthAttendance();
  const [unidadeVinculada, setUnidadeVinculada] = useState();

  useEffect(() => {
    if (patient) {
      setUnidadeVinculada(
        patient.patient_has_medical_unit.length > 0
          ? patient.patient_has_medical_unit.map((res) => {
              return {
                id: res.medical_unit_id,
                value: res.medical_unit.bussines_name
                  ? res.medical_unit.bussines_name
                  : res.medical_unit.company_name,
              };
            })
          : []
      );
    }
  }, [patient]);

  return (
    <div>
      <Grid item md={12}>
        <div className="main_div_tag_paciente_tab mt10_new_tabs">
          {unidadeVinculada && unidadeVinculada.length ? (
            <>
              {unidadeVinculada.map(function (item, i) {
                return (
                  <div className='tag_paciente_tab'>
                    { item.value ? item.value : "-" }
                    </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </div>
      </Grid>
    </div>
  );
}
