import { Container, Typography, Grid, Card, CardContent } from  "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import DInput from './../shared/DInput';

function Inputs(){

    const style = {
        light: {
            background: '#FFF',
            padding: '12px'
        },
        dark: {
            background: '#272727',
            padding: '12px'
        },
        card: {
            background: 'transparent'
        },
        titleDark: {
            color: '#FFF'
        }
    }

    return(
        <>
            <Container style={ style.dark }>
                <Typography variant="h3" style={ style.titleDark }>Inputs (Dark)</Typography>
                <Grid container spacing={ 3 } className="tema-dark">
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Default (Padrão)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-1"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Selected (Selecionado)</Typography>
                                <Typography variant="p">Clique para executar o efeito</Typography>
                                <DInput
                                    id="label-2"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Filled (Preenchido)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-3"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Required field (Campo obrigatório)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-4"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    error={true}
                                    // success={true}
                                    required={true}
                                    // disabled={true}
                                    helperText="Campo obrigatório"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Error (Erro)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-5"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Error message"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Success (sucesso)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-6"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Success message"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Desabilitado e preenchido</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-7"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6" style={ style.titleDark }>Disabled (Desabilitado)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-8"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container style={ style.light }>
                <Typography variant="h3">Inputs (Light)</Typography>
                <Grid container spacing={ 3 } className="tema-light">
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Default (Padrão)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-1"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Selected (Selecionado)</Typography>
                                <Typography variant="p">Clique para executar o efeito</Typography>
                                <DInput
                                    id="label-2"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Filled (Preenchido)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-3"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Required field (Campo obrigatório)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-4"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    error={true}
                                    // success={true}
                                    required={true}
                                    // disabled={true}
                                    helperText="Campo obrigatório"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value=""
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Error (Erro)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-5"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    error={true}
                                    // success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Error message"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Success (sucesso)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-6"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    success={true}
                                    // required={true}
                                    // disabled={true}
                                    helperText="Success message"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Desabilitado e preenchido</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-7"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <CardContent>
                                <Typography variant="h6">Disabled (Desabilitado)</Typography>
                                <Typography variant="p">&nbsp;</Typography>
                                <DInput
                                    id="label-8"
                                    label="Label"
                                    placeholder="Placeholder"
                                    type="text"
                                    // error={true}
                                    // success={true}
                                    // required={true}
                                    disabled={true}
                                    helperText="Assistive text (optional)"
                                    fullWidth={true}
                                    // onClick={ e => console.log(e.target.value) }
                                    // onChange={ e => console.log(e.target.value) }
                                    positionIcon="end"
                                    // value="Lorem ipsum"
                                    icon={ <ExpandMore /> } />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Inputs;