import React from 'react';
// import styled from "styled-components";
import ErrorPage from "../ErrorPage/ErrorPage";

// const Container = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
// `;

// const ErrorUI = (props) => {
//     return (
//         <Container>
//             <h1>Ops! algo de errado aconteceu.</h1>
//             <button onClick={props.voltar}>Voltar para a página inicial</button>
//         </Container>
//     );
// }

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, prod: props.isProd };
      
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      if(errorInfo && errorInfo.componentStack) 
      {
        console.log(error && error.toString())
        console.log(new Error(errorInfo.componentStack));
      }

      this.setState({hasError: true});
    }
  
    render() {
      if (this.state.hasError && this.state.prod) { // Se tem erro e está em `produção`.
          return <ErrorPage voltar={e => this.setState({hasError: false})}/>;
          // return <ErrorUI voltar={e => this.setState({hasError: false})} />;
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary