import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  createRoom,
  createConference,
  getConferenceByAttendenceId,
  joinRoom,
  conferenceAddParticipant,
  joinRoomPatient,
  joinRoomUser,
} from "../../../api/videocall";
import TwillioVideo from "twilio-video";
import { useNewHealthAttendance } from "../../../context/NewHealthAttendance";

export const Participant = ({ participant }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const videoRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (tracks) =>
    Array.from(tracks.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const trackSubscribed = (track) => {
      console.log("New track added: ", track);
      if (track.kind === "video")
        setVideoTracks((prevTracks) => [...prevTracks, track]);
      else setAudioTracks((prevTracks) => [...prevTracks, track]);
    };

    const trackUnsubscribed = (track) => {
      console.log("unsubscribed", track);
      if (track.kind === "video")
        setVideoTracks((tracks) => tracks.filter((v) => v !== track));
      else setAudioTracks((tracks) => tracks.filter((a) => a !== track));
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);

      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];

    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <div className="participant" style={{ height: "100%" }}>
      <video
        ref={videoRef}
        width="100%"
        height="100%"
        autoPlay={true}
        style={{ objectFit: "cover" }}
      />
      <audio ref={audioRef} autoPlay={true} />
    </div>
  );
};

/**
 * @description Cuida de toda a lógica relacionada ao VideoCall.
 */
export default function useVideoCall() {
  const { patient, healthAttendance } = useNewHealthAttendance();

  const [activeRoom, setActiveRoom] = useState(null);
  const [localMediaAvailable, setLocalMediaAvailable] = useState(false);
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [previewTracks, setPreviewTracks] = useState(null);
  const [participants, setParticipants] = useState([]);
  // const [conferenceId, setConferenceId] = useState(null); // está em roomInfo
  const [dominantSpeaker, setDominantSpeaker] = useState(null);
  const [roomInfo, setRoomInfo] = useState({
    roomName: null,
    token: null,
    conferenceId: null,
  });

  function leaveCall() {
      if (activeRoom) {
        try {
          activeRoom.disconnect();
        } catch (err) {
          console.log("Não foi possível sair da sala.", err);
        }
      }

    // props.onClose(true);
  }

  function onMute() {
    if (activeRoom && activeRoom.localParticipant) {
      activeRoom.localParticipant.audioTracks.forEach((value) => {
        value.track.disable();
      });
    }
  }

  function onUnmute() {
    if (activeRoom && activeRoom.localParticipant) {
      activeRoom.localParticipant.audioTracks.forEach((value) => {
        value.track.enable();
      });
    }
  }

  function disableVideo() {
    if (activeRoom) {
      if (activeRoom.localParticipant) {
        activeRoom.localParticipant.videoTracks.forEach((value) => {
          value.track.disable();
        });
      }
    }
  }

  function enableVideo() {
    if (activeRoom) {
      if (activeRoom.localParticipant) {
        activeRoom.localParticipant.videoTracks.forEach((value) => {
          value.track.enable();
        });
      }
    }
  }

  /**
   * @param {*} healthAttendanceId ID do atendimento
   * @param {*} patientId ID do paciente
   * @param {function} cb função que retorna erro ou sucesso sequencialmente
   * @typedef {object} IInitCallInfo
   * @property {string} roomName ID da sala
   * @property {string} conferenceId ID da conferência
   * @property {string} token token do atendimento
   * @returns {Promise<IInitCallInfo>}
   */
  async function initialize(healthAttendanceId, patientId, cb) {
    var roomName = undefined;
    var conferenceId = undefined;
    var token = undefined;

    console.log("[VIDEOCALL::initialize]")

    try {
      const resCreateRoom = await createRoom(localStorage.getItem("token"), {
        roomName: healthAttendanceId,
      });

      if (resCreateRoom && resCreateRoom.status) {
        const dataToCreateConference = {
          room: resCreateRoom.message,
          health_attendance_id: resCreateRoom.message,
        };

        const resCreateConference = await createConference(
          localStorage.getItem("token"),
          dataToCreateConference
        );

        console.log("VIDEOCALL::CREATE_CONFERENCE", resCreateConference);

        if (resCreateConference && resCreateConference.status) {
          // setRoomName(resCreateConference.conference.room);
          roomName = resCreateConference.conference.room;

          const resJoinRoom = await joinRoom(localStorage.getItem("token"), {
            roomName: resCreateConference.conference.room,
          });

          conferenceId = resCreateConference.conference.id;

          const dataToAddParticipant = {
            roomName: healthAttendanceId,
            user_id: localStorage.getItem("uid"),
            conference_id: conferenceId,
            health_attendance_id: parseInt(healthAttendanceId),
            // conference_token: resJoinRoom.message.accessToken
          };

          const resJoinRoomUser = await joinRoomUser(
            localStorage.getItem("token"),
            dataToAddParticipant
          );

          if (resJoinRoomUser && resJoinRoomUser.status) {
            const { accessToken } = resJoinRoomUser.message;

            cb(null, {
              roomName: healthAttendanceId,
              conferenceId: conferenceId,
              token: accessToken,
            });

            //  ADICIONA O PACIENTE A CHAMADA
            joinRoomPatient(localStorage.getItem("token"), {
              roomName: healthAttendanceId,
              patient_id: patientId,
              health_attendance_id: healthAttendanceId,
              conference_id: conferenceId,
            }).catch((err) => console.log(err));
          }
        } else {
          if (
            resCreateConference &&
            resCreateConference.message ===
              "Room eistente esse nome: " + resCreateRoom.message
          ) {
            const resGetParticipant = await getConferenceByAttendenceId(
              localStorage.getItem("token"),
              healthAttendanceId
            );

            console.log("VIDEOCALL::GET_CONFERENCE", resGetParticipant);

            if (resGetParticipant && resGetParticipant.status) {
              // setRoomName(resGetParticipant.conferences[0].room);
              roomName = resGetParticipant.conferences[0].room;

              const resJoinRoom = await joinRoom(
                localStorage.getItem("token"),
                {
                  roomName: roomName,
                }
              );

              console.log("[VIDEOCALL::CONFERENCE_ID]", resGetParticipant.conferences[0].id);

              if (resJoinRoom && resJoinRoom.status) {
                const dataToAddParticipant = {
                  roomName: roomName,
                  user_id: localStorage.getItem("uid"),
                  conference_id: resGetParticipant.conferences[0].id,
                  health_attendance_id: parseInt(
                    resGetParticipant.conferences[0].room
                  ),
                  // conference_token: resJoinRoom.message.accessToken
                };

                const resJoinRoomUser = await joinRoomUser(
                  localStorage.getItem("token"),
                  dataToAddParticipant
                );

                if (resJoinRoomUser && resJoinRoomUser.status) {
                  const { accessToken } = resJoinRoomUser.message;

                  cb(null, {
                    roomName: resGetParticipant.conferences[0].room,
                    conferenceId: resGetParticipant.conferences[0].id,
                    token: accessToken,
                  });

                  //  ADICIONA O PACIENTE A CHAMADA
                  const resJoinRoomPatient = await joinRoomPatient(localStorage.getItem("token"), {
                    roomName: healthAttendanceId,
                    patient_id: patientId,
                    health_attendance_id: healthAttendanceId,
                    conference_id: resGetParticipant.conferences[0].id,
                  }).catch((err) => console.log(err));

                  

                  const dataToAddParticipantPatient = {
                      conference_id: resGetParticipant.conferences[0].id,
                      patient_id: patientId,
                      conference_token: resJoinRoomPatient.message.accessToken
                  };

                  conferenceAddParticipant(localStorage.getItem("token"), dataToAddParticipantPatient);
                }
              }

              // if(resJoinRoom && resJoinRoom.status) {
              //     const dataToAddParticipant = {
              //         conference_id: resGetParticipant.conferences[0].id,
              //         patient_id: 151,
              //         conference_token: resJoinRoom.message.accessToken
              //     };

              //     const resAddParticipant = await conferenceAddParticipant(localStorage.getItem("token"), dataToAddParticipant);

              //     if(resAddParticipant && resAddParticipant.conference_token)
              //     {
              //         // join room patient
              //         const resJoinRoomPatient = await joinRoomPatient(
              //             localStorage.getItem("token"),
              //             {
              //                 roomName: healthAttendanceId,
              //                 patient_id: 151,
              //                 health_attendance_id: healthAttendanceId,
              //                 conference_id: resAddParticipant.conference_id,
              //             }
              //         );

              //         if(resJoinRoomPatient && resJoinRoomPatient.status)
              //         {
              //             console.log("[VIDEOCALL::CONFERENCE_ID]", resAddParticipant.conference_id);
              //             const dataToAddParticipantPatient = {
              //                 conference_id: resGetParticipant.conferences[0].id,
              //                 patient_id: 151,
              //                 conference_token: resJoinRoomPatient.message.accessToken
              //             };

              //             const resAddParticipantPatient = await conferenceAddParticipant(localStorage.getItem("token"), dataToAddParticipantPatient);

              //             if(resAddParticipantPatient && resAddParticipantPatient.conference_token)
              //             {
              //                 // success("Participante adicionado com sucesso!");
              //                 // setToken(resAddParticipantPatient.conference_token);
              //                 token = resAddParticipantPatient.conference_token;

              //                 // return {
              //                 //     roomName: roomName,
              //                 //     conferenceId: resGetParticipant.conferences[0].id,
              //                 //     token: token
              //                 // };
              //                 cb(null, {
              //                     roomName: roomName,
              //                     conferenceId: resGetParticipant.conferences[0].id,
              //                     token: token
              //                 })
              //             }
              //             else
              //             {
              //                 console.log("[VIDEOCALL::CONFERENCE_ADD_PARTICIPANT]", resAddParticipant);
              //                 cb("Não foi possível adicionar o participante a videoconferência!");
              //             }
              //         }
              //         else
              //         {
              //             console.log("[VIDEOCALL::JOIN_ROOM_PATIENT]", resJoinRoomPatient);
              //             cb("Não foi possível adicionar o participante a videoconferência!");
              //         }
              //     }
              //     else
              //     { //x
              //         console.log("[VIDEOCALL::CONFERENCE_ADD_PARTICIPANT]", resAddParticipant);
              //         cb("Não foi possível adicionar o participante a videoconferência!");

              //     }
              // }
              // else
              // {//x
              //     console.log("[VIDEOCALL::JOIN_ROOM]", resJoinRoom);
              //     cb("Não foi possível adicionar o participante a videoconferência!");

              // }
            } else {
              //x
              console.log(
                "[VIDEOCALL::GET_CONFERENCE_BY_ATTENDENCE_ID]",
                resGetParticipant
              );
              cb(
                "Não foi possível buscar o participante da videoconferência..."
              );
            }
          } else {
            //x

            console.log("[VIDEOCALL::CREATE_CONFERENCE]", resCreateConference);
            cb("Não foi possível criar a videoconferência!");
          }
        }
      } else {
        console.log(`[VIDEOCALL::createRoom] Error: ${resCreateRoom}`);
        cb("Ocorreu um erro ao criar a sala de videoconferência.");
      }
    } catch (err) {
      console.log(`[VIDEOCALL] Error: ${err}`);
      cb("Ocorreu um erro ao criar a sala de videoconferência.");
    }
  }

  // /**
  //  * @param {object} data
  //  * @param {string} data.roomName
  //  * @param {string} data.token
  //  */
  // function joinTwilioRoom({ roomName, token, conferenceId }) {
  //   console.log("[VIDEOCALL] Joining room '" + roomName + "'...");

  //   let connectOptions = {
  //     name: roomName,
  //   };

  //   console.log(
  //     "[VIDEOCALL] Joining room with '" + token + "' and options",
  //     connectOptions
  //   );

  //   setRoomInfo({
  //     roomName: roomName,
  //     token: token,
  //     conferenceId: conferenceId,
  //   });
  // }

  useEffect(() => {
    if (!healthAttendance || !patient) return;

    console.log(
      "[VIDEOCALL::INIT]",
      "HealthAttendanceID",
      healthAttendance.id,
      "PatientID",
      patient.id
    );

    initialize(healthAttendance.id, patient.id, (error, data) => {
      if (error) {
        console.log(error);
        return toast.error(error);
      }


      const { roomName, token, conferenceId } = data;

      setRoomInfo({
        roomName: roomName,
        token: token,
        conferenceId: conferenceId,
      });
    });

    return () => {
      setIsWaiting(true);
      setHasJoinedRoom(false);
      setLocalMediaAvailable(false);
      setActiveRoom(null);
    };
  }, []);

  useEffect(() => {
    if (!roomInfo.token) return;

    console.log("ROOMINFO ALTERADA")

    const participantConnected = (participant) => {
      console.log("[VIDEOCALL::participantConnected]: ", participant);

      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };
    const participantDisconnected = (participant) => {
      console.log("[VIDEOCALL::participantConnected]: ", participant);

      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    const dominantSpeakerChanged = (participant) => {
      console.log("[VIDEOCALL::dominantSpeakerChanged]: ", participant);

      setDominantSpeaker(participant);
    };

    const joinedRoom = (room) => {
      console.log("[VIDEOCALL::joinedRoom] Joined as: ", room.localParticipant);
      setHasJoinedRoom(true);
      setActiveRoom(room);
      setLocalMediaAvailable(true);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.on("dominantSpeakerChanged", dominantSpeakerChanged); // Participante com voz dominante

      room.participants.forEach(participantConnected);
    };

    console.log("[VIDEOCALL] Joining room '" + roomInfo.roomName + "'...");
    TwillioVideo.connect(roomInfo.token, {
      name: roomInfo.roomName,
      logLevel: "debug",
      dominantSpeaker: true,
    }).then(joinedRoom, (error) => {
      console.error("Could not connect to Twilio: ", error);
      if (error.message === "Requested device not found") {
        toast.error(
          "Não foi possível habilitar a webcam e/ou o microfone, verifique se os mesmos encontram-se conectados ao computador"
        );
      } else {
        console.error("Could not connect to Twilio: ", error);
        alert("Não foi possível conectar na chamada: " + error.message);
      }
    });
  }, [roomInfo]);

  return {
    activeRoom,
    localMediaAvailable,
    hasJoinedRoom,
    isWaiting,
    previewTracks,
    participants,
    onMute,
    onUnmute,
    disableVideo,
    enableVideo,
    leaveCall,
    roomInfo,
    dominantSpeaker,
  };
}
