import axios from "axios";
import env from "react-dotenv";

class Biomedidas {

    get(token) 
    {
        return axios.get(`${env.API_URL}/biomeasure/filter?token=${token}`);
    }

    create(token, body)
    {
        return axios.post(`${env.API_URL}/biomeasure?token=${token}`, body);
    }

    update(token, id, body)
    {
        return axios.put(`${env.API_URL}/biomeasure/${id}?token=${token}`, body);
    }

    destroy(token, id)
    {
        return axios.delete(`${env.API_URL}/biomeasure/${id}/destroy?token=${token}`);
    }

    createToHealthAttendance(token, body)
    {
        return axios.post(`${env.API_URL}/biomeasure/biomeasure-exam-health-attendance?token=${token}`, body);
    }

    getByHealthAttendance(token, health_attendance_id)
    {
        return axios.get(`${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${health_attendance_id}?token=${token}`);
    }

    destroyByHealthAttendance(token, id)
    {
        return axios.delete(`${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${id}?token=${token}`);
    }

    updateToHealthAttendanceById(token, body, id)
    {
        return axios.put(`${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/${id}?token=${token}`, body);
    }

    getByPatient(token, patient_id)
    {
        return axios.get(`${env.API_URL}/biomeasure/biomeasure-exam-health-attendance/patient/${patient_id}?token=${token}`);
    }
}

let Bio = new Biomedidas();

export { Bio };