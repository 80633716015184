import env from "react-dotenv";
import { Typography, Grid } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from "@material-ui/core/CircularProgress";


import DInput from "../../../shared/DInput";
import DButton from "../../../shared/DButton";
import {useEffect, useState, useRef} from "react";
import ReCAPTCHA from "react-google-recaptcha";

function FormTwoFactor(props){

    const [reenviar, setReenviar] = useState(30);
    const [reenviarSMS, setReenviarSMS] = useState(0);
    const [formValid, setFormValid] = useState(false);
    const [digito1, setDigito1] = useState('');
    const [digito2, setDigito2] = useState('');
    const [digito3, setDigito3] = useState('');
    const [digito4, setDigito4] = useState('');
    const [digito5, setDigito5] = useState('');

    useEffect(function(){

        var idInterval = constadorEmail();

        try {
            document.querySelector('input[name="digito_1"]').focus()
        } catch (err) {
            console.log('campo não encontrado.')
        }



        return function cleanUp() {
            clearInterval(idInterval);
        }
    }, []);

    function nextField(field) {
        try{
            document.querySelector(`input[name="${field}"]`).focus()
        } catch(err) {
            console.log(`campo ${field} não encontrado`)
        }
    }

    function constadorEmail() {
        var cont = 30;

        var idInterval = setInterval(function() {

            setReenviar(--cont);

            if(cont === 0)
                clearInterval(idInterval);
        }, 1000);

        return idInterval;
    }

    function contadorSMS() {
        var cont = 30;

        var idInterval = setInterval(function() {

            setReenviarSMS(--cont);

            if(cont === 0)
                clearInterval(idInterval);
        }, 1000);

        return idInterval;
    }

    function isFormValid() {
        return digito1 !== "" && digito2 !== "" && digito3 !== "" && digito4 !== "" && digito5 !== "";
    }

    const recaptchaRef = useRef();

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();

        if(isFormValid()) {
            props.verificar({ codigo: `${digito1}${digito2}${digito3}${digito4}${digito5}` });
        }
    }

    return(
        <form autoComplete="off" onSubmit={ form => {
            form.preventDefault();
            onSubmitWithReCAPTCHA();
        }}>
            <Grid container justifyContent="space-around">
                <Grid item xs={12} style={{ height: 427, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="text-mt text-regular" style={{ marginTop: 25 }}>Insira o código de 5 dígitos enviado para</Typography>
                            <Typography className="text-lt text-medium">{ localStorage.getItem('email') }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ height: 200 }}>
                        <Grid item xs={12} style={{ marginBottom: 50 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_1" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        setDigito1(e.target.value)
                                        nextField('digito_2')
                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_2" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        setDigito2(e.target.value)
                                        nextField('digito_3')
                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_3" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        setDigito3(e.target.value)
                                        nextField('digito_4')

                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_4" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        setDigito4(e.target.value)
                                        nextField('digito_5')

                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_5" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        setDigito5(e.target.value);
                                        setFormValid(true);
                                    } } />
                                </Grid>
                            </Grid>
                            { props.error ? <Grid container><Grid item md={12} className="text-error"><Typography className="text-st text-medium" style={{marginTop: 10}}>Código inválido</Typography></Grid></Grid> : '' }
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 25 }}>
                            { reenviar > 0
                                ? <Typography className="text-mt text-regular text-disabled">Enviar código novamente em 00:{ reenviar.toString().padStart(2, "0") }</Typography>
                                : <a href="#" onClick={ e => {
                                    props.reenviar('email');
                                    constadorEmail();
                                } } className="text-link"><Typography className="text-mt text-bold">{ props.reenviando ? <LinearProgress /> : 'Reenviar código por e-mail' }</Typography></a>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            { reenviarSMS > 0
                                ? <Typography className="text-mt text-regular text-disabled">Enviar código novamente em 00:{reenviarSMS.toString().padStart(2, "0")}</Typography>
                                : <a href="#" onClick={e => {
                                    props.reenviar('sms');
                                    contadorSMS();
                                }} className="text-link"><Typography className="text-mt text-bold">{props.reenviando ? <LinearProgress/> : 'Enviar código por SMS'}</Typography></a>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className="btn-toolbar">
                        <Grid item xs={6}>
                            <DButton variant="secondary" onClick={ e => props.voltar(true) }>Voltar</DButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DButton variant="primary" disabled={!formValid} type="submit">{ props.loading ? <CircularProgress color="inherit" /> : 'Avançar' }</DButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={ env.SITE_KEY }
            />
        </form>
    )
}

export default FormTwoFactor;