import { makeStyles, TextField, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import "./styles.scss";
import { required } from "../../../../../../../../helpers/validacoes";
import useError from "../../../../../../../../hooks/useError";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      "& .MuiFormHelperText-root.Mui-error": {
          width: '200px',
          margin: 0,
      }
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '15% !important',
    },
    displayErrorShow: {
      border: "1px solid #f44336",
    },
    
  }));

const EBBloodPressure = ({ id, title, label, placeholder, value, helper_text, is_required, is_read_only, erro, condicional, readOnly, answer }) => {
  const classes = useStyles();
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [systolic, setSystolic] = useState();
  const [diastolic, setDiastolic] = useState();
  const [data, setData] = useState('');
  const  [ showError ,  setShowError ]  =  useState (false);
  const  [ validationField ,  setValidationField ]  =  useState (false);
  
  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    var validators = {};

    if (systolic !== null || systolic.lenght > 0) {
      validators.diastolic = required;
      
    }
    if (diastolic !== null || diastolic.lenght > 0) {
      validators.systolic = required;
    }

    return validators;
  }

  function getData() {
    var dados = {
      systolic,
      diastolic,
    };

    return dados;
  }

  useEffect(() => {
    if(parseInt(systolic) < parseInt(diastolic)) {
      setShowError(true)
    } else {
      setShowError(false)
    }

    if(systolic && systolic.length < 2) {
      setValidationField(true)
    } else {
      setValidationField(false)
    }
    
    const data = {
      value: 
        { 
          systolic: systolic,
          diastolic: diastolic
        }
          
    }

    var dados = getData();

    if(data.value.systolic !== undefined && validarFormulario({ ...dados })){
      if(showError != true) {
        setData(data.value)
        // handleInputSubmit({[id]: data.value})
      }
    }
  },[systolic, diastolic])

  useEffect(() => {
    if(answer.length > 0){
      setSystolic(answer[0].systolic)
      setDiastolic(answer[0].diastolic)
    }
  },[])
  
  
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold mb-2"
            style={{ fontSize: "0.8em" }}
          >
            <div dangerouslySetInnerHTML={{ __html: `${label ? label : "Pressão Arterial (PA)"}` }} />
          </Typography>
        <Box className={classes.root}>
            <TextField
                id={id}
                // label={label ? label : ""}
                className={ showError ? classes.displayErrorShow  : '' }
                placeholder={placeholder ? placeholder : ""}
                readOnly={is_read_only === 1 ? true : false}
                required={is_required === 1 ? true : false}
                value={systolic}
                inputProps={{ maxLength: 3, minLength: 2 }}
                error={ erro[0] ? erro[0].value : '' || error.systolic}
                helperText={ erro[0] ? erro[0].value : '' || error.systolic}
                // error={ error.systolic}
                // helperText={  error.systolic}
                onChange={ e => {
                  if(erro !== null && erro.length > 0){
                    delete erro[0].value
                  }
                  delete error.systolic
                  setSystolic(e.target.value)
                } }
                className={classes.textField}
                type="text"
                variant="outlined"
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                InputProps={{
                  readOnly: readOnly,
                }}
            />
            <span className="text-regular text-info-input">
              x
            </span>
          <TextField
                id={id}
                // label={label ? label : ""}
                placeholder={placeholder ? placeholder : ""}
                value={diastolic ? diastolic : ""}
                inputProps={{ maxLength: 3, minLength: 2 }}
                error={error.diastolic}
                helperText={error.diastolic}
                onChange={ e => {
                  if(erro !== null && erro.length > 0){
                    delete erro[0].value
                  }
                  delete error.diastolic
                  setDiastolic(e.target.value)
                } }
                className={classes.textField}
                variant="outlined"
                type="text"
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                onBlur={ e => {
                  if(data !== null){
                    !readOnly ? handleInputSubmit({[id]: data}) : console.log('effect onBlur')
                  }
                } }
                InputProps={{
                  readOnly: readOnly,
                }}
            />
            <span className="text-regular text-info-input">
              mmHg
            </span>
          </Box>
          <span style={{ fontSize: 12 }} className="text-light">
            {helper_text ? helper_text : ""}
          </span>
          <span style={{ fontSize: 12, color: '#f44336' }} className="text-light">{showError ? 'Campo PA Sistólica menor que o PA Diastólica' : ''}</span>
          <span style={{ fontSize: 12, color: '#f44336' }} className="text-light">{validationField ? 'Campo PA Sistólica deve conter no mínimo 2 dígitos' : ''}</span>
        </div>
      </div>
    </section>
  );
};

export default EBBloodPressure;
