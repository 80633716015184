import {useState} from 'react';
import {Card, CardContent, Grid, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CreateIcon from '@material-ui/icons/Create';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: 15,
        paddingTop: 5,
        paddingBottom: 5,
        background: theme.palette.background.cloud
    },
    cardContent: {
        paddingTop: 10,
        paddingBottom: '10px !important',        
        borderLeft: 'solid 4px',
        borderColor: theme.palette.primary.light,
        marginLeft: 5
    },
    removeItem: {
        color: theme.palette.support.error,
    }
}))

function FormCard(props)
{
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) => {
        setAnchorEl(null);
        if(action !== 'close')
            props.onAction(action);

    };
    
    const classes = useStyles();

    return (        
        <Card classes={{root: classes.card}}>
            <CardContent classes={{root: classes.cardContent}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item md={5}>
                        <Typography variant="body1">{ props.name }</Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography style={{textTransform: 'capitalize'}} variant="body1"><strong>status: </strong>{ props.status }</Typography>
                    </Grid>
                    <Grid item md={4} style={{ display: "flex", justifyContent: "end" }}>                        
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={e => handleClose('close')}
                                PaperProps={{
                                    style: {
                                        width: '300px'
                                    },
                                }}>
                                <MenuItem onClick={ e => handleClose('construct')}>
                                    <ListItemIcon>
                                        <AddCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Construtor de formulários" />
                                </MenuItem> 
                                <MenuItem disabled={ props.status !== 'ativo' } onClick={ e => handleClose('condition')}>
                                    <ListItemIcon>
                                        <AddCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={ `${ props.status !== 'ativo' ? "Indisponível" : "Condicionais" }` } />
                                </MenuItem> 
                                <MenuItem onClick={ e => handleClose('edit')}>
                                    <ListItemIcon>
                                        <CreateIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Editar" />
                                </MenuItem>
                                <MenuItem disabled={ props.pendenteAprovacao == 0 } onClick={ e => handleClose('approved')}>
                                    <ListItemIcon>
                                        <CheckIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={ "Aprovar" } />
                                </MenuItem>   
                                {/* <MenuItem onClick={ e => handleClose('remove')}>
                                    <ListItemIcon>
                                        <CancelIcon fontSize="small" style={{color: '#E23F5D'}} />
                                    </ListItemIcon>
                                    <ListItemText classes={{primary: classes.removeItem}} primary="Excluir formulário" />
                                </MenuItem>                                */}
                            </Menu>
                        </div>         
                    </Grid>
                </Grid>
            </CardContent>
        </Card>              
    )
}

export default FormCard;