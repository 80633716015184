import Logo from "./../../../assets/img/Telemed24h_LOGO_BCO 1.svg";
import {
    Grid,
    useTheme,
  } from "@material-ui/core";


function LogoTheme(props) {

    const theme = useTheme();
    const addDefaultSrc = (ev) => {
        ev.target.src = Logo;
    }

    return(
        <>
            <Grid item className="logo-content">
                <img onError={addDefaultSrc} className="logo-config" src={theme.palette.logoPlataforma || Logo} />
            </Grid>
        </>
    )

}

export default LogoTheme;