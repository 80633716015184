import { useEffect, useState } from 'react';
import useError from "../../../../hooks/useError";
import { required } from "../../../../helpers/validacoes";
import DInput from '../../../shared/DInput';
import DAutoComplete from '../../../shared/DAutoComplete';

function Conselhos(props)
{
    const {conselho, estados, conselhoCbos, conselhosFormat} = props;

    const [error, validarFormulario, setErrors] = useError({
        cbo: required,
        uf: required
    })

    const [codigo, setCodigo] = useState("");
    const [uf, setUf] = useState("");
    const [cbo, setCBO] = useState("");

    const handleOnChange = (dados) => {
        if(validarFormulario({ ...dados })){
            if(dados.cbo.substring(0,2) == 22 && dados.codigo === "") {
                setErrors({ ...error, codigo: "Campo obrigatório" })
            } else {
                props.onChange({ ...dados })
            }
        }
    }

    useEffect(() => {
        // handleOnChange()
        console.log(error)
        console.log({codigo, uf, cbo})
    },[codigo, uf, cbo])

    return (
        <div className="spacing-x mb-10 conselhos-de-classe">
            <div className="conselho d-flex flex-row">
                <div className="d-flex-1">
                    <DAutoComplete
                        label="Conselho de classe"
                        type="text"
                        fullWidth
                        disabled
                        defaultValue={{id: conselho, value: conselho}}
                        options={conselhosFormat}
                        getOptionLabel={(option) => {
                            return option.value
                        }}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id
                        }}
                    />
                </div>
                <div className="ml-1em grid grid-column-2">
                    <DInput
                        label="Número de registro"
                        type="text"
                        fixedLabel
                        className="mr-1em"
                        error={ error.codigo }
                        helperText={ error.codigo }
                        inputProps={{ maxLength: 10 }}
                        onChange={e => {
                            setCodigo(e.target.value);
                            handleOnChange({ codigo: e.target.value, uf, cbo, conselho });
                        }}
                        positionIcon="end"                                                                        
                    />
                    <DAutoComplete
                        label="UF"
                        type="text"
                        fullWidth
                        error={ error.uf }
                        helperText={ error.uf }
                        options={estados}
                        getOptionLabel={(option) => {
                            return option.value
                        }}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id
                        }}
                        onChange={(event, newValue) => {
                            if(newValue)
                                setUf(newValue.value);
                            else
                                setUf("");

                            handleOnChange({ codigo, uf: newValue.value, cbo, conselho });
                        }}
                    />
                </div>
            </div>
            <div className="d-block">                                                        
                <DAutoComplete
                    label="Código e descrição CBO"
                    placeholder="Código e descrição CBO"
                    error={ error.cbo }
                    helperText={ error.cbo }
                    fullWidth
                    options={conselhoCbos}
                    getOptionLabel={(option) => {
                        return option.value
                    }}
                    getOptionSelected={(option, value) => {
                        return option.id === value.id
                    }}
                    onChange={(event, newValue) => {
                        if(newValue)
                            setCBO(newValue.code);
                        else
                            setCBO("")

                        handleOnChange({ codigo, uf, cbo: newValue.code, conselho });
                    }}
                />
            </div>
        </div>
    )
}

export default Conselhos;