import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { getInterconsultationInvite, assumirInterconsultation, abdicarInterconsultation } from "./../../../api/health_attendances";
import Confirm from "./components/Confirm";
import { Assumir } from "./components/modalcontent";
import { CancelarButtom, ConfirmarButtom } from "./components/Buttons";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import env from "react-dotenv";
import Pusher from 'pusher-js';

function InviteInterconsultation(props)
{

    const [invite, setInvite] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        
        getInterconsultationInvite(localStorage.getItem('token')).then(res => {
            if(res.status)
                setInvite(res.health_attendance_invite);
        })
        .catch(err => console.log(err))

        const pusher = new Pusher(env.KEY_PUSHER, {
            cluster: env.PUSHER_APP_CLUSTER
        });

        const channel = pusher.subscribe('interconsultation');

        channel.bind('new-invite', health_attendance_invite => {
            if(health_attendance_invite.user_id == localStorage.getItem('uid'))
                setInvite(health_attendance_invite);
        });

        return (() => {
            console.log('unsubscribe');
            pusher.unsubscribe('interconsultation');
            pusher.disconnect();
        })

    }, []);

    const handleAbdicar = () => {
        abdicarInterconsultation(localStorage.getItem("token"), invite.id).then(res => {
            if(res.status) {
                toast.success("Interconsulta abdicada com sucesso.");
                setInvite(null);
            } else {
                toast.error("Erro ao abdicar interconsulta.");
            }                
        })
        .catch(err => console.log(err))
    }

    const handleAssumir = () => {
        assumirInterconsultation(localStorage.getItem("token"), invite.id).then(res => {
            if(res.status) {
                localStorage.setItem('health_attendance_interconsultation', invite.health_attendance_id);
                toast.success("Interconsulta assumida com sucesso.");
                setInvite(null);
                setRedirect(true);
            } else {
                toast.error("Erro ao assumir interconsulta.");
            }                
        })
        .catch(err => console.log(err))
    }

    return (
        <>
            { redirect && <Redirect to="/atendimento-imediato?view=1" /> }
            { invite && <Confirm open={ true }
                    footer={ <Grid container justifyContent="center">
                                <Grid item md={7}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <CancelarButtom onClick={ handleAbdicar }>Cancelar</CancelarButtom>
                                        </Grid>
                                        <Grid item>
                                            <ConfirmarButtom onClick={ handleAssumir }>Assumir e iniciar</ConfirmarButtom>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> }
                ><Assumir protocolo={ invite.health_attendance_id } /></Confirm> }
        </>
    )
}

export default InviteInterconsultation;