import { useEffect, useState } from "react";
import Paciente from "./Paciente";
import { useAuthRoute } from "./../../context/AuthRouteContext";
import { getAllPatients } from "./../../api/patient";
import { PatientContext, usePatient } from "../../context/PatientContext";

function PacienteContent(props)
{
    const { auth, setAuth } = useAuthRoute();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getAllPatients(localStorage.getItem('token'), 1).then(res => {
            if(!res.status)
                setAuth({...auth, route: false})
            else    
                setLoaded(true)
        })
    }, [localStorage.getItem('token')]);

    return (
        <>
            { loaded && <Paciente  filteredData={ props.filteredData } /> }
        </>
    )
}

export default PacienteContent;