import axios from 'axios';
import env from 'react-dotenv';

export function getCIAP(token){
  return axios.get(`${env.API_URL_SERVICE}/diagnostic/ciap?token=${token}`).then(res => res.data);
}

export function getCIPE(token){
  return axios.get(`${env.API_URL_SERVICE}/diagnostic/cipe?token=${token}`).then(res => res.data);
}

export function getCID10(token){
  return axios.get(`${env.API_URL_SERVICE}/diagnostic/cid10?token=${token}`).then(res => res.data);
}

export function getSIGTAP(token){
  return axios.get(`${env.API_URL_SERVICE}/diagnostic/sigtap?token=${token}`).then(res => res.data);
}