import React, { useState, useEffect } from 'react';
import "../index.scss";
import { Button, Grid } from '@material-ui/core';
import { Create, Save } from '@material-ui/icons';
import { TextField } from "@material-ui/core";
import ReactQuill from "react-quill";
import CustomDateTimePicker from '../../CustomDateTimePicker';
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { createHealthAttendanceObservation, updateHealthAttendanceObservation } from "./../../../../api/healthAttendance";
import { toast } from "react-toastify";

export default function SolicitanteNewTab(props) {

  const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();

  const [editar, setEditar] = useState(false);

  const [solicitanteUnidade, setSolicitanteUnidade] = useState("");
  const [solicitanteConselho, setSolicitanteConselho] = useState("");
  const [solicitanteNome, setSolicitanteNome] = useState("");
  const [encerradoUnidade, setEncerradoUnidade] = useState("-");
  const [encerradoNome, setEncerradoNome] = useState("-");
  const [encerradoConselho, setEncerradoConselho] = useState("-");
  const [observacao, setObservacao] = useState({
      health_attendance_id: null,
      id: null,
      observation: "",
      user_id: localStorage.getItem('uid')
  });
  const [aberto, setAberto] = useState(null);
  const [encerrado, setEncerrado] = useState(null);
  
  useEffect(() => {
    
    if(healthAttendance) {
        if(healthAttendance.user_requesting) {
          setSolicitanteNome(healthAttendance.user_requesting.social_name ? healthAttendance.user_requesting.social_name : healthAttendance.user_requesting.full_name);
        } 
        
        if(healthAttendance.medical_unit) {
          setSolicitanteUnidade(healthAttendance.medical_unit.company_name);
        }

        var new_observation = {...observacao, id: null, observation: "", health_attendance_id: null, user_id: null };

        if(healthAttendance.health_attendance_observation && healthAttendance.health_attendance_observation.length > 0) {
          new_observation.id = healthAttendance.health_attendance_observation[0].id;
          new_observation.health_attendance_id = healthAttendance.id;
          new_observation.observation = healthAttendance.health_attendance_observation[0].observation;
        }

        var new_solicitante_conselho = "-";
        if(healthAttendance.conselhos && healthAttendance.conselhos.length > 0) {
            new_solicitante_conselho = `${healthAttendance.conselhos[0].tipo} - ${healthAttendance.conselhos[0].uf} - ${healthAttendance.conselhos[0].descricao}`;
        }        
        
        if(healthAttendance.health_attendance_status_id === 3) {
          
          var new_encerrado_conselho = "-";
          
          if(healthAttendance.encerrado_conselhos && healthAttendance.encerrado_conselhos.length > 0) {
              new_encerrado_conselho = `${healthAttendance.encerrado_conselhos[0].tipo} - ${healthAttendance.encerrado_conselhos[0].uf} - ${healthAttendance.encerrado_conselhos[0].descricao}`;
          }

          setEncerradoConselho(new_encerrado_conselho);

          if(healthAttendance.medical_unit)
            setEncerradoUnidade(healthAttendance.medical_unit.company_name);

          if(healthAttendance.user)
            setEncerradoNome(healthAttendance.user.social_name ? healthAttendance.user.social_name : healthAttendance.user.full_name);
        }

        setSolicitanteConselho(new_solicitante_conselho);
        setObservacao(new_observation); 

        setAberto(healthAttendance.formated_requested_at);
        
        if(healthAttendance.closed_at)
          setEncerrado(healthAttendance.formated_closed_at);
    }
  }, [healthAttendance]);

  const handleSalvar = () => {
    
    if(!observacao.id || observacao.id === null){

      createHealthAttendanceObservation(localStorage.getItem('token'), observacao.observation, localStorage.getItem('uid'), healthAttendance.id).then(res => {
        if(res.status) {
          toast.success("Observação atualizada com sucesso.");
          setEditar(false);
          setObservacao({...observacao, id: res.healthAttendanceObservation.id})
        } else {
          toast.error("Erro ao atualizar observação.");
        }
      });
    } else {
      updateHealthAttendanceObservation(localStorage.getItem('token'), observacao.observation, observacao.id, healthAttendance.id, localStorage.getItem('uid')).then(res => {
        if(res.status) {
          toast.success("Observação atualizada com sucesso.");
          setEditar(false);
        } else {
          toast.error("Erro ao atualizar observação.");
        }
      });
    }

  }

  return (
    <div className='fundo_new_tab'>
      <div className='main_flex_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Solicitante
        </div>
      </div>
      <div className='mt_35_new_tab separa_inputs_new_tabs'>
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Unidade" variant="standard" disabled value={ solicitanteUnidade } />
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Solicitante" variant="standard" disabled value={ solicitanteNome } />
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Conselho" variant="standard" disabled value={ solicitanteConselho } />
      </div>
      <div className='main_flex_new_tab mt_35_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Encerrado por
        </div>
      </div>
      <div className='mt_35_new_tab separa_inputs_new_tabs'>
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Unidade" variant="standard" disabled value={ encerradoUnidade } />
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Solicitante" variant="standard" disabled value={ encerradoNome } />
        <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Conselho" variant="standard" disabled value={ encerradoConselho } />
      </div>
      <div className='mt_35_new_tab'>
        <div className='main_flex_new_tab'>
          <div className='fonte_new_tab text-primary'>
            Observação
          </div>
          <div className='flex_end_new_tab'>
            { !editar ?
                <Button className="botao_editar_new_tab text-primary" onClick={ e => setEditar(true) } startIcon={<Create />}>
                  Editar
                </Button>  
                :
                <Button className="botao_editar_new_tab text-primary" onClick={ handleSalvar } startIcon={<Save />}>
                  Salvar
                </Button>
            }
            
          </div>
        </div>
        <div className="mt_35_new_tab box_editor_new_tab">
          <ReactQuill readOnly={ !editar } value={ observacao.observation } onChange={ e => {
              if(editar)
                setObservacao({...observacao, observation: e})
          } } />
        </div>
        <div className="mt_35_new_tab separa_datepickers_new_tabs">
          <Grid item md={2}>
            <CustomDateTimePicker value={ aberto } disabled
              title="Aberto"
            />
          </Grid>
          <Grid item md={2}>
            <CustomDateTimePicker value={ encerrado } disabled
              title="Encerrado"
            />
          </Grid>
        </div>
      </div>
    </div>
  )
}










