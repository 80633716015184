import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PregnantWomanIcon from "@material-ui/icons/PregnantWoman";
import ComputerIcon from "@material-ui/icons/Computer";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import gestante from "../../../../assets/img/gestante.svg";

import DButton from "../../../shared/DButton";

import Panel from "./components/Panel/Panel";
import Attendances from "./components/Attendance/Attendance";
import PrevPregnancies from "./components/PrevPregnancies/PrevPregnancies";
import { getAgeByDate } from "../../../../helpers/formatacao";
import FichaConsulta from "../../../AtendimentoImediato/AtendimentoList/components/AtendimentoGrid/modais/FichaConsulta";

import api_groupForm from "../../../../api/group-form";
import { addWeeks, format, subDays, subWeeks } from "date-fns";
import ptBR from "date-fns/esm/locale/pt-BR/index.js";
import { addDays } from "date-fns/esm";
import { EmptyGestation } from "./components/EmptyGestation";
import { EncerrarGestacaoModal } from "./components/Modais";
import { PatientCardInfo } from "./components/PatientCardInfo";
import AttendanceApi from "../../../../api/healthAttendance";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles({
  paciente__topo: {
    position: "absolute",
    left: 0,
    top: 88,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
  },
  paciente__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    fontWeight: 600,
  },
  finish__button: {
    backgroundColor: "#d13d2e !important",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#8b2a20 !important",
    },
  },
  box: {
    background: "#F4F4F4",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "8px",
    marginTop: 90,
    height: "calc(100vh - 340px)",
    overflow: "auto",
    paddingBottom: 40,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#272727",
    padding: "20px 32px",
    cursor: "pointer",
    borderRadius: 4,
    marginBottom: 12,
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  menuItemSelected: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#272727",
    padding: "20px 32px",
    cursor: "pointer",
    borderRadius: 4,
    marginBottom: 12,
    backgroundColor: "#f9f9f9",
  },
  tags: {
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 16px)",
    minHeight: 34,
    padding: 8,
    margin: "26px -4px",
    border: "1px solid #e6e6e6",
    borderRadius: 4,
  },
  profile: {
    backgroundColor: "#fff",
    borderRadius: 4,
    width: "100%",
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 500,
  },
  modal_title: {
    width: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 0 16px",
    fontSize: 16,
    fontWeight: 700,
    borderBottom: "1px solid #c7c7c7",
    color: "#4a4ae9",
  },
  modal_label: {
    fontSize: 14,
    fontWeight: 400,
    color: "#272727",
  },
});

function PacientePregnant(props) {
  const classes = useStyles();

  document.getElementById("root").style.backgroundColor = "#f4f4f4";

  // Control

  const [loadingMaePaulistana, setLoadingMaePaulistana] = useState(true);
  const [isComponentFullLoaded, setIsComponentFullLoaded] = useState(false); // Prevent insta click trigger

  const [loadingNewPregnancy, setLoadingNewPregnancy] = useState(false);

  const [openTerminatePregnancyModal, setOpenTerminatePregnancyModal] =
    useState(false);

  // Forms
  const [groupForm, setGroupForm] = useState(null);
  const [groupFormInput, setGroupFormInput] = useState(null);
  const [groupFormAnswer, setGroupFormAnswer] = useState(null);
  const [activeForm, setActiveForm] = useState(null);

  const [opennedForm, setOpennedForm] = useState(null);
  const [listOpenAttendance, setOpenAttendance] = useState(null);

  const [attendances, setAttendances] = useState([]);
  const [attendanceForms, setAttendanceForms] = useState(null);

  const [reasonTermination, setReasonTermination] = React.useState("");
  const [canTerminatePregnancy, setCanTerminatePregnancy] = useState(false);
  const [canCreateNewPregnancy, setCanCreateNewPregnancy] = useState(true);
  const [emptyGestation, setEmptyGestation] = useState(false);

  // Active Form / Patient Card Control
  const [userGroups, setUserGroup] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [isAltoRisco, setAltoRisco] = useState(false);
  const [dppDate, setDppDate] = useState("");
  const [semanasGestacao, setSemanasGestacao] = useState("");
  const [diasGestacao, setDiasGestacao] = useState("");
  const [statusAtendimentoAtual, setStatusAtendimentoAtual] = useState("");
  const [sisprenatal, setSisprenatal] = useState("");

  const [state, setState] = React.useState({
    menu: 1,
  });

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAttendancesByPatientCNS();
    props.triggerSidebar && props.triggerSidebar(true);

    if(!props.paciente || !props.paciente.patient)
    {
      console.log("Buscando paciente");
      const pid = window.location.href.split('/').pop();

      if(isNaN(pid))
          return

      props.getPaciente(pid);
    }

    return () => {
      setAttendances([]);
      props.triggerSidebar && props.triggerSidebar(false);
    };
  }, []);

  useEffect(() => {
    if (props.paciente) {
      setUserGroup(props.paciente.patient.patient_has_group);
      getFormsByPatientId(props.paciente.patient.id, () => null);

      api_groupForm
        .getAll()
        .then((res) => {
          if (res.status) {
            setGroupFormInput(res.groupForm[0]);
          }
        })
        .catch((err) => console.error(err));

      //verificando se a paciente possui resposta com gestacao de alto risco
      // api_groupForm
      //   .getInputsByPatientIdAndNameInput(
      //     props.paciente.patient.id,
      //     "gestante_alto_Risco"
      //   )
      //   .then((data) => {
      //     if (data.status && data.groupForm.length > 0) {
      //       data.groupForm.map((item) => {
      //         item.group_form_anwser.map((resposta) => {
      //           if (resposta.value === "1") {
      //             setAltoRisco(true);
      //           }
      //         });
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     if (error && error.request._isRedirect) {
      //       console.log(error && error.request);

      //       return axios.request({
      //         url: error.request.responseURL,
      //       });
      //     }
      //   });
    }
  }, [props]);

  const handleTerminatePregnancy = () => {
    const { group_form_input } = groupFormInput;

    var _form = group_form_input.find(
      (x) => x.name == "motivo_encerramento_gestacao"
    );

    setOpenForm(false);

    const _saveData = {
      group_form_input_id: _form.id,
      patient_id: opennedForm.patient_id,
      group_form_id: 4,
      value: reasonTermination,
      group_form_patient_id: opennedForm.id,
    };

    api_groupForm
      .createOrUpdateForm(_saveData)
      .then((res) => {
        if (!res.status) {
          throw new Error(res.error || "Não foi possível salvar");
        }
      })
      .catch(console.error);

    api_groupForm
      .updateFormPatient(opennedForm.id, 2)
      .then((data) => {
        if (data.status) {
          toast.success("Gestação encerrada com sucesso.");
          setOpenTerminatePregnancyModal(false);
          setOpenForm(false);
          setActiveForm(null);
          handleClose();
        } else {
          toast.error("Erro ao encerrar gestação.");
        }
      })
      .catch(console.error);
  };

  function getAttendancesByPatientCNS() {
    AttendanceApi.getHealthAttendanceFilter(localStorage.getItem("token"), {
      search: props.paciente.patient.cns_principal,
      group_id: 4,
    })
      .then((data) => {
        if (data.status) {
          setAttendances(data.healthAttendances.patient);
        }
      })
      .catch(console.error);
  }

  async function getFormsByPatientId(patientId, cb) {
    api_groupForm
      .getByPatientId(patientId)
      .then((data) => {
        if (data.status) {
          setAttendanceForms(data);

          const { groupForm } = data;

          if (groupForm && Array.isArray(groupForm) && groupForm.length > 0) {
            if (Array.isArray(groupForm[0].group_form_patient)) {
              const _activeForm = groupForm[0].group_form_patient.find(
                (x) => x.status === 1
              );

              if (_activeForm) {
                setActiveForm(_activeForm);
              } else {
                setActiveForm(null);
              }

              if (groupForm[0].group_form_patient.length === 0) {
                setEmptyGestation(true);
              } else {
                cb(data);
                setEmptyGestation(false);
              }
            }
          }
          //checkAltoRisco();
        }

        setIsComponentFullLoaded(true);
        setLoadingMaePaulistana(false);
      })
      .catch((error) => {
        console.log(error);
        setIsComponentFullLoaded(true);
        setLoadingMaePaulistana(false);
      });
  }

  useEffect(() => {
    // Reage a gestação ativa.
    if (activeForm) {
      setStatusAtendimentoAtual(
        activeForm.status === 1 ? "Ativo" : "Finalizado"
      );

      if (
        Array.isArray(activeForm.group_form_anwser) &&
        activeForm.group_form_anwser.length
      ) {
        var inputs = activeForm.group_form_anwser.map((x) => {
          return {
            ...x.group_form_input,
            value: x.value,
          };
        });

        var ActiveSisprenatal = null;
        var ActiveAltoRiscoValue = false;
        var ActiveDiasGestacaoValue = null;
        var ActiveSemanasGestacaoValue = null;

        if (inputs.find((x) => x.name === "sisprenatal")) {
          var ActiveSisprenatal = inputs.find(
            (x) => x.name === "sisprenatal"
          ).value;
        }

        if (inputs.find((x) => x.name === "gestante_alto_Risco")) {
          ActiveAltoRiscoValue = inputs.find(
            (x) => x.name === "gestante_alto_Risco"
          ).value;
        }

        if (inputs.find((x) => x.name === "idade_gestacional_dias")) {
          ActiveDiasGestacaoValue = inputs.find(
            (x) => x.name === "idade_gestacional_dias"
          ).value;
        }

        if (inputs.find((x) => x.name === "idade_gestacional_semanas")) {
          ActiveSemanasGestacaoValue = inputs.find(
            (x) => x.name === "idade_gestacional_semanas"
          ).value;
        }

        setDiasGestacao(ActiveDiasGestacaoValue);

        setSemanasGestacao(ActiveSemanasGestacaoValue);

        setSisprenatal(ActiveSisprenatal);

        if (ActiveAltoRiscoValue === "1") {
          setAltoRisco(true);
        } else {
          setAltoRisco(false);
        }
      }
    }
  }, [activeForm]);

  useEffect(() => {
    if (openForm) {
      setCanCreateNewPregnancy(false);
    } else {
      setCanCreateNewPregnancy(true);
      setCanTerminatePregnancy(false);
      getFormsByPatientId(props.paciente.patient.id, () => null);
      setStatusAtendimentoAtual("");
      setDiasGestacao("");
      setSemanasGestacao("");
      setDppDate("");
      setSisprenatal("");
    }
  }, [openForm]);

  function openAttendance(item, status) {
    setGroupFormAnswer(item);
    setStatusAtendimentoAtual(status);
    // setCanCreateNewPregnancy(false);

    api_groupForm
      .getInputsByPatientId(props.paciente.patient.id)
      .then((data) => {
        if (data.status) {
          setOpenForm(item.status);
          setGroupFormInput(data.groupForm[0]);
          setGroupForm(data.groupForm[0]);

          setOpennedForm(item);
          setOpenForm(true);
          if (item.status === 3) {
            setCanTerminatePregnancy(false);
          } else {
            setCanTerminatePregnancy(true);
          }
        } else {
          toast.error("Não foi possível abrir o atendimento.");
        }
      })
      .catch(console.error);
  }

  function createChipGroups() {
    return userGroups.map((item) => {
      return <Chip label={item.group.name} style={{ marginRight: 16 }} />;
    });
  }

  const calcDpp = (diasGestacao, semanasGestacao) => {
    if (diasGestacao === "" || semanasGestacao === "") return;

    var dias = diasGestacao;
    var semanas = semanasGestacao;

    var _base = addWeeks(new Date(), 42); // Adiciona 42 semanas a partir de hoje
    var _dppDays = subDays(_base, dias); // Subtrai os dias que já se passaram
    var _dpp = subWeeks(_dppDays, semanas); // Subtrai as semanas que já se passaram

    try {
      var _dppValue = format(_dpp, "dd/MM/yyyy", { locale: ptBR });

      return _dppValue;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  useEffect(() => {
    if (!isNaN(diasGestacao) && !isNaN(semanasGestacao)) {
      setDppDate(calcDpp(diasGestacao, semanasGestacao));
    }
  }, [diasGestacao, semanasGestacao]);

  function canCreateNewPregnancyCheck() {
    if (activeForm) {
      return false;
    } else {
      return true;
    }
  }

  function createNewPregnancy() {
    if (loadingNewPregnancy) return;
    if (!isComponentFullLoaded) return;

    setLoadingNewPregnancy(true);

    if (canCreateNewPregnancyCheck()) {
      if (props.paciente && props.paciente.patient.id) {
        api_groupForm
          .createNewFormPatient(4, props.paciente.patient.id, 1)
          .then((res) => {
            if (res.status) {
              getFormsByPatientId(props.paciente.patient.id, (data) => {
                setLoadingNewPregnancy(false);
                setOpenForm(true);
                toast.success("Gestação iniciada com sucesso.");

                try {
                  openAttendance(
                    data.groupForm[0].group_form_patient.find(
                      (x) => x.status === 1
                    )
                  );
                } catch (err) {
                  console.log(err, "OpenAttendance", data);
                }
              });
            } else {
              toast.error("Não foi possível criar uma gestação.");
            }

            setLoadingNewPregnancy(false);
          })
          .catch((err) => {
            console.error(err);
            setLoadingNewPregnancy(false);
            toast.error("Não foi possível criar uma gestação.");
          });
      } else {
        setLoadingNewPregnancy(false);
        toast.error("Não foi possível criar uma gestação.");
        console.error("Paciente nulo");
      }
    } else {
      toast.error("Finalize a gestação atual para iniciar uma nova.");
      setLoadingNewPregnancy(false);
    }
  }

  if(!props.paciente || !props.paciente.patient) 
  {
    return;
  }

  return (
    <>
      <Backdrop
        style={{ zIndex: 3000, color: "white", textAlign: "center" }}
        open={loadingMaePaulistana}
      >
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando listagem de atendimentos...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <Grid style={{ paddingTop: 2 }}>
        <Grid item className={classes.paciente__topo}>
          <NavLink
          to={'/pacientes'}
          style={{textDecoration: 'none'}}
          >
            <button
              className={classes.paciente__nome}
              onClick={(e) =>
                openForm ? setOpenForm(!openForm) : props.listar(true)
              }
              style={{
                fontSize: "18px",
                lineHeight: "27px",
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
            >
              <ArrowBackIosIcon color="primary" fontSize="large" />
              {props.paciente.patient.social_name != null &&
              props.paciente.patient.social_name !== ""
                ? props.paciente.patient.social_name
                : props.paciente.patient.full_name}

              {props.paciente.patient.pronoun ? `(${props.paciente.patient.pronoun.name})` : ""}
            </button>
          </NavLink>

          <div>
            {canCreateNewPregnancy ? (
              <DButton
                // disabled={activeForm}
                variant="secondary"
                icon={<AddCircleIcon fontSize="small" />}
                size="sm"
                style={{ marginRight: 16 }}
                onClick={(e) => createNewPregnancy()}
              >
                Nova gestação
              </DButton>
            ) : (
              ""
            )}
            {canTerminatePregnancy ? (
              <DButton
                disabled={!canTerminatePregnancy || opennedForm.status == 2}
                className={classes.finish__button}
                size="sm"
                onClick={(e) => setOpenTerminatePregnancyModal(true)}
              >
                Encerrar gestação
              </DButton>
            ) : (
              ""
            )}
          </div>
        </Grid>

        <EncerrarGestacaoModal
          handleTerminatePregnancy={(e) => handleTerminatePregnancy()}
          open={openTerminatePregnancyModal}
          handleClose={(e) => setOpenTerminatePregnancyModal(false)}
          setReason={(reason) => setReasonTermination(reason)}
        />

        {listOpenAttendance && (
          <FichaConsulta
            open={listOpenAttendance}
            attendance={listOpenAttendance}
            close={(e) => setOpenAttendance(false)}
            openPdf={(e) => null}
          />
        )}

        <Grid container spacing={3} alignItems="center">
          <Grid item md={4}>
            <Box className={classes.box} p={3}>
              <PatientCardInfo
                patientBirthDate={props.paciente.patient.birth_date}
                statusAtendimento={statusAtendimentoAtual}
                dpp={dppDate}
                sisprenatal={sisprenatal}
                altoRisco={isAltoRisco}
              />

              <div className={classes.tags}>{createChipGroups()}</div>

              <ul style={{ listStyle: "none", margin: "0 -4px", padding: 0 }}>
                <li
                  className={
                    state.menu == 2
                      ? classes.menuItemSelected
                      : classes.menuItem
                  }
                  onClick={() => {
                    setState({ ...state, menu: 2 });
                    setOpenForm(false);
                  }}
                >
                  <CalendarTodayIcon
                    style={{ color: "#4a4ae9", marginRight: 10 }}
                  />{" "}
                  Painel
                </li>
                <li
                  className={
                    state.menu == 1
                      ? classes.menuItemSelected
                      : classes.menuItem
                  }
                  onClick={() => {
                    setState({ ...state, menu: 1 });
                    setOpenForm(false);
                  }}
                >
                  <ComputerIcon style={{ color: "#4a4ae9", marginRight: 10 }} />{" "}
                  Atendimento
                </li>
                {/* <li className={ state.menu == 2 ? classes.menuItemSelected : classes.menuItem } onClick={() => setState({ ...state, menu:2 })}><ComputerIcon style={{ color: "#4a4ae9", marginRight:10 }} /> Atendimento</li> */}
              </ul>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box className={classes.box} p={3}>
              {/* <EmptyGestation /> */}

              {emptyGestation && state.menu == 2 ? <EmptyGestation /> : null}

              {/* Gestações */}
              {!openForm && state.menu == 2 && (
                <PrevPregnancies
                  dpp={dppDate}
                  attendances={attendanceForms}
                  attendancesLength={attendances && attendances.length}
                  openAttendance={(item, status) =>
                    openAttendance(item, status)
                  }
                  openAttendances={(e) => setState({ state, menu: 1 })}
                />
              )}

              {/* Atendimentos da gestação */}
              {state.menu == 1 && !openForm && attendances && (
                <Attendances
                  attendances={attendances}
                  openAttendance={(attendance, status) => {
                    setOpenAttendance(attendance);
                  }}
                />
              )}

              {/* Painel de edição */}
              {openForm && (
                <Panel
                  openForm={openForm}
                  toast={toast}
                  answerForm={groupFormAnswer}
                  groupForm={groupForm}
                  form={{
                    ...groupFormInput,
                    answer:
                      groupFormAnswer && groupFormAnswer.group_form_anwser,
                    opennedForm,
                  }}
                  status={opennedForm ? opennedForm.status : null}
                  paciente={props.paciente}
                  updateGestanteAltoRisco={(x) => setAltoRisco(x)}
                  updateSemanasGestacao={(x) => setSemanasGestacao(x)}
                  updateDiasGestacao={(x) => setDiasGestacao(x)}
                  updateSisprenatal={(e) => setSisprenatal(e)}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PacientePregnant;
