import React, { useState, useEffect } from 'react';
import { Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import moment from 'moment';
import {
    Backdrop,
    CircularProgress,
    Typography,
    Grid,
    Chip,
    Paper
} from "@material-ui/core";
import { getAllTrails } from '../../../api/trail';
import HistoricoFilter from './HistoricoFilter';

const useStyles = makeStyles((theme) => ({

    containerFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.neutral.midgrey
    },

    table: {
        minWidth: 650,
    },

    textColor: {
        color: `${theme.palette.neutral.black} !important`,
        fontSize: "14px"
    },

    chip: {
        margin: 4,
        color: `${theme.palette.neutral.black} !important`
    },
    resizeName: {
        maxWidth: '100px',
        overflow: "scroll"
    },
    icon: {
        fontSize: 15
    },

    pagination: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50
    },
    selected: {
        border: `1px solid ${theme.palette.primary.light} !important`,
        color: theme.palette.primary.light,
        fontWeight: "500 !important"
    },
    page: {
        fontWeight: 400,
        fontSize: 16,

    },
    ul: {
        "&>li:first-of-type>button": {
            border: `1px solid ${theme.palette.primary.light} !important`,
            borderRadius: "50%",
            color: theme.palette.primary.light
        },
        "&>li:last-of-type>button": {
            border: `1px solid ${theme.palette.primary.light} !important`,
            borderRadius: "50%",
            color: theme.palette.primary.light
        }
    },
    tableContainer: {
        overflowX: "auto"
    },
    marginBottom90: {
        marginBottom: "90px"
    },
    toolbar_top: { 
        zIndex: 3000, 
        color: 'white', 
        textAlign: 'center' 
    }
}));

export default function HistoricoGrid() {

    const classes = useStyles();
    const token = localStorage.getItem("token");
    const [historicos, setHistoricos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [showLoader, setShowLoader] = useState(false);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState({});

    const setHistoricoList = (dataParam, page, totalPageParam) => {
        setTotalPage(totalPageParam)
        setCurrentPage(page);
        setHistoricos(dataParam);
        setShowLoader(false);
    };

    const renderDate = (strDate) => {
        const dateObj = new Date(strDate);
        const momentObj = moment(dateObj);
        return momentObj.format('DD/MM/YYYY');
    }

    const renderHour = (strHour) => {
        const hourObj = new Date(strHour);
        const momentObj = moment(hourObj);
        return momentObj.format('HH:mm:ss');
    }

    return (
        <>
            <Grid container>
                <Grid item md={12}>
                    <Backdrop className={classes.toolbar_top} open={showLoader}>
                        <Grid container>
                            <Grid item md={12}>
                                <CircularProgress color="inherit" />
                            </Grid>
                            <Grid item md={12}>
                                <Typography variant="body1" className="text-regular text-st">Carregando listagem de ações...</Typography>
                            </Grid>
                        </Grid>
                    </Backdrop>

                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Container className={classes.containerFilter}>
                            <HistoricoFilter
                                callbackAfterFilter={setHistoricoList}
                                callbackSelectFilter={setFiltrosSelecionados}

                            />
                        </Container>

                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell classes={{ head: classes.textColor }}>Data</TableCell>
                                    <TableCell classes={{ head: classes.textColor }}>Hora</TableCell>
                                    <TableCell classes={{ head: classes.textColor }}>Tipo de Ação</TableCell>
                                    <TableCell classes={{ head: classes.textColor }}>Executor da Ação</TableCell>
                                    <TableCell classes={{ head: classes.textColor }}>Perfis</TableCell>
                                    <TableCell classes={{ head: classes.textColor }}>Unidades</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historicos.map((row, index) => (

                                    <TableRow key={`historicos-${index}`}>
                                        <TableCell component="th" scope="row">{renderDate(row.created_at)}</TableCell>
                                        <TableCell component="th" scope="row">{renderHour(row.created_at)}</TableCell>
                                        <TableCell component="th" scope="row">{row.description}</TableCell>
                                        <TableCell component="th" scope="row">{row.user?.full_name ?? "-"}</TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip label={row.profile?.description ?? "-"} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip label={row.medical_unit?.company_name ?? "-"} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid>
                        <Grid item md={12} className={classes.marginBottom90}>
                            <Pagination
                                count={totalPage}
                                page={currentPage}
                                classes={{ root: classes.pagination, ul: classes.ul }}
                                renderItem={
                                    (item) => <PaginationItem {...item}
                                        classes={{
                                            selected: classes.selected,
                                            page: classes.page
                                        }}
                                    />}
                                onChange={(event, page) => {
                                    setShowLoader(true);
                                    getAllTrails(token, page, filtrosSelecionados).then((response) => {
                                        setHistoricoList(response.data, page, response.last_page);
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
