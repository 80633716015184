import React, { useEffect, useContext, useState } from 'react';
import {Redirect} from "react-router-dom";
import { CircularProgress, Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import CancelIcon from '@material-ui/icons/Cancel';
import DAutocompleteCreate from "../../../../../shared/DAutoCompleteCreate/DAutoCompleteCreateAsync";
import DMultAutoComplete from "../../../../../shared/DMultAutoComplete";
import DDropdown from "../../../../../shared/DDropdown";
import LineCares from "../../../../../../api/lineCare"
import {getAllGroups} from "./../../../../../../api/groups"
import {getNextSchedulesPatient} from "./../../../../../../api/schedule"
import PacienteGroup from '../../../PacienteGroup';
import PacienteLineCare from '../../../PacienteLineCare';
import { format } from 'date-fns';
import { getUserInfoById } from '../../../../../../api/user';
import { getAllMedicalSpecialties } from '../../../../../../api/medicalSpecialty';
import { ptBR } from "date-fns/locale";
import { AppContext } from '../../../../../../context/AppContext';
import { getUnidade } from '../../../../../../api/unidades';
import { healthAttendanceByPatient, updateAttendanceStatus } from '../../../../../../api/healthAttendance';
import CardNextHealthAttendance from "./components/CardNextHealthAttendance";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  consultas_titulo: {
    fontWeight: 'bold !important',
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.primary.light,
    padding: "0 0 12px 0",
    margin: 0
  },
  consultas_subtitulo: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    padding: "12px 0",
    margin: 0
  },
  consultas_text: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px"
  },
  consultas_purpleCard: {
    width: "100%",
    height: 62,
    borderRadius: 4,
    background: "#9300D926",
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    position: "relative"
  },
  consultas_purpleBar: {
    width: 8,
    height: "100%",
    borderRadius: 4,
    background: "#9300D9",
    marginRight: 8
  },
  consultas_greenCard: {
    width: "100%",
    height: 62,
    borderRadius: 4,
    background: "#3C757926",
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    position: "relative"
  },
  consultas_greenBar: {
    width: 8,
    height: "100%",
    borderRadius: 4,
    background: "#3C7579",
    marginRight: 8
  },
  consultas_brownCard: {
    width: "100%",
    height: 62,
    borderRadius: 4,
    background: "#C5794226",
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    position: "relative"
  },
  consultas_brownBar: {
    width: 8,
    height: "100%",
    borderRadius: 4,
    background: "#C57942",
    marginRight: 8
  },
  consultas_button: {
    width: 30,
    height: "100%",
    borderRadius: 4,
    color: "#fff",
    background: "#4A4AE9",
    position: 'absolute',
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
    fontSize: 20,
    cursor: "pointer"
  },
  consultas_popover: {
    listStyle: "none",
    padding: 0,
    margin: "0 16px"
  },
  consultas_popoverItem: {
    listStyle: "none",
    margin: 0,
    padding: "12px 0",
    fontSize: 12,
    color: "#272727",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  }

}));

var cardColors = [
  'consultas_greenCard',
  'consultas_purpleCard',
  'consultas_brownCard'
]

function DetailsAppointments(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  const {userInfo, selectedProfile} = useContext(AppContext)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [loadingLineCares, setLoadingLineCares] = React.useState(false);
  const [nextPatientSchedules, setNextPatientSchedules] = React.useState(null);
  const [medicalSpecialties, setMedicalSpecialties] = React.useState(null);

  const [linhaDeCuidado, setLinhaDeCuidado] = React.useState(null)
  const [initialSelectedLineCares, setInitialSelectedLineCares] = React.useState(null);
  const [userCantViewHistory, setUserCantViewHistory] = React.useState(false);

  const [linhasDeCuidado, setLinhasDeCuidado] = React.useState([]);
  const [groups, setGroups] = React.useState([]);

  const [healthAttendances, setHealthAttendances] = useState([]);
  const [viewAttendance, setViewAttendance] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatLineCares = lineCares => {
    if(Array.isArray(lineCares)){
      return lineCares.map(lineCare => {
        return {
          id: lineCare.id,
          value: lineCare.name,
          // active: lineCare.active
        }
      });
    } else if(lineCares === null) {
      return null
    } else {
      return [lineCares];
    }
  }

  const refreshLineCares = () => {
    setLoadingLineCares(true);
    // setInitialSelectedLineCares(null);
    // setLinhaDeCuidado(null);
    // setLinhasDeCuidado([]);

    LineCares.listPatientLineCares(props.paciente.patient.id)
        .then(res => {

          if(res.status){
            var _lineCares = res.patientLineCare.map(x => {
              return {
                id: x.line_care.id,
                value: x.line_care.name,
                _id: x.id,
                preSelected: true,
              }
            });

            setLinhaDeCuidado(_lineCares)
            setInitialSelectedLineCares(_lineCares)
          } else {
            setLinhaDeCuidado(null)
            setInitialSelectedLineCares(null)
          }

          setLoadingLineCares(false);
        })
        .catch(err => {
          console.error(err);
          setLoadingLineCares(false);
        })
  }

  const handleSaveLineCare = (line_care_id) => {
    setLoadingLineCares(true)

    LineCares.vinculateNewLineCare(props.paciente.patient.id, line_care_id)
      .then(data => {
        refreshLineCares();
      })
      .catch(err => {
        console.error(err);
        refreshLineCares();
      })

  }

  const handleRemoveLineCare = (_lineCareId) => {
    setLoadingLineCares(true);
    
    LineCares.deletePatientLineCare(_lineCareId)
      .then(res => {
        refreshLineCares();

      })
      .catch(err => {
        refreshLineCares();

      })

  }
  
  const handleRemoveAllPatientLineCares = () => {
    setLoadingLineCares(true);

    LineCares.deleteAllPatientLineCares(props.paciente.patient.id)
      .then(res => {
        refreshLineCares();
      }
      )
      .catch(err => {
        refreshLineCares();
      })
  }

  const on_change = (
    event,
    newValue,
    reason,
    details,
  ) => {
    const selected_item = newValue[newValue.length - 1]

    if(reason === "remove-option"){
      if(details.option.id){
        if(Array.isArray(initialSelectedLineCares)){
          if(initialSelectedLineCares.find(x => x.id == details.option.id)){
            const removed_lineCare = initialSelectedLineCares.find(x => x.id == details.option.id);

            if(removed_lineCare._id){
              handleRemoveLineCare(removed_lineCare._id);
            }
          }
        }
      }
    } else if(reason === "select-option"){
      if(details.option){
        handleSaveLineCare(details.option.id)
      }
    } else if(reason === "clear"){
      handleRemoveAllPatientLineCares()
    }
  }

  const checkSchoolMedicalUnit = data => {
    if(data && data.status) {
      var medical_unit_data = data.details;

      if(medical_unit_data.medicalUnit && medical_unit_data.medicalUnit.establishment_type === "Escola") {
        setUserCantViewHistory(true)
      }
    }

  }

  useEffect(() => {
    if(props.paciente && props.paciente.patient){
      refreshLineCares();
      healthAttendanceByPatient(localStorage.getItem('token'), props.paciente.patient.id).then(res => {
        if(res.status)
          setHealthAttendances(res.health_attendances);
      });
    }

    LineCares.listLineCare().then(res => {
      if(res && res.status){
        let _lineCares = formatLineCares(res.lineCare.data);

        setLinhasDeCuidado(_lineCares);
      }
    }).catch(err => console.error(err))

    

    getAllMedicalSpecialties(localStorage.getItem('token')).then(res => {
        setMedicalSpecialties(res)
    }).catch(err => console.error(err))


    getUserInfoById(localStorage.getItem('token'), 56).then(console.log)

  }, []);

  useEffect(() => {
    if(userInfo && userInfo.unidade != null) {
      getUnidade(localStorage.getItem("token"), userInfo.unidade).then(checkSchoolMedicalUnit).catch(err => console.error(err))
    }
  }, [selectedProfile, userInfo])

  const handleCancelAttendance = (healthAttendanceId) => {
      toast.warn("Cancelando atendimento.");
      updateAttendanceStatus(localStorage.getItem('token'), healthAttendanceId, {active: false, reason: "Cancelamento via painel de paciente"}).then(data => {
        if(data.status) {
          toast.success("Atendimento cancelado com sucesso.");
          toast.warn("Atualizando próximas consultas.");
          healthAttendanceByPatient(localStorage.getItem('token'), props.paciente.patient.id).then(res => {
            if(res.status) {
              toast.success("Próximas consultas atualizadas com sucesso.");
              setHealthAttendances(res.health_attendances);
            }
          });
        }else
          toast.error("Erro ao cancelar atendimento.");
      }).catch(err => {
          toast.error("Não foi possível cancelar o atendimento.");
      });
  }

  const handleViewAttendance = (healthAttendanceId) => {
    localStorage.setItem('health_attendance_interconsultation', healthAttendanceId);
    setViewAttendance(true);
  }
  
  return <>
    { viewAttendance && <Redirect to="/atendimento-imediato?view=1" /> }
    <div style={{ height: '100%', paddingBottom: 48 }}>
      { props.paciente && <PacienteGroup onGroup={ props.onGroup } paciente={ props.paciente } pregnancy={e => props.pregnancy()} /> }
      <hr />
      { props.paciente && <PacienteLineCare paciente={ props.paciente } /> }
      <hr />
      <h5 className={ classes.consultas_titulo }>Próximas consultas</h5>
      { (healthAttendances && healthAttendances.length > 0) && healthAttendances.map((healthAttendance, index) => {

        var _exibirTitle = index > 0 && (new Date(healthAttendance.requested_at)).toLocaleDateString() === (new Date(healthAttendances[index - 1].requested_at)).toLocaleDateString() ? false : true;

        return <CardNextHealthAttendance onCancel={ e => handleCancelAttendance(healthAttendance.id) } onView={ e => handleViewAttendance(healthAttendance.id) }
          key={ `CardNextHealthAttendance-${index}` } title={ _exibirTitle } healthAttendance={ healthAttendance } />
      } ) }          
    </div>        
  </>
}

export default DetailsAppointments;