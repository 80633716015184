import React, {useEffect} from 'react';
import PacienteGrid from "./components/PacienteGrid";
import PacienteFilter from "./components/PacienteFilter";
import { Grid } from "@material-ui/core";
import AddCircle from '@material-ui/icons/AddCircle';
import DButton from "../../../shared/DButton";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { PatientContext, usePatient } from "../../../../context/PatientContext";

const useStyles = makeStyles((theme) => ({
    blackColor: {
        color: "#272727"
    }
}));

function PacienteList(props) {

    const classes = useStyles();
    const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);

    useEffect(function(){
        if(props.pacientes)
            setSideBarInternal(true)
    }, []);

    useEffect(() => {
        props.updatePatientList();
    }, []);

    return(
        <> 
            <Grid container alignItems="center">
                <Grid item md={3}><h3 className={classes.blackColor}>Pacientes</h3></Grid>
                <Grid item md={9}>
                    <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item md={8}>
                            <PacienteFilter 
                                filteredData={ data => props.filteredData(data)}
                            />
                        </Grid>                        
                        <Grid item md={3}>   
                            <NavLink 
                            style={{textDecoration: 'none'}}
                            to="/pacientes/cadastrar">
                                <DButton variant="primary" icon={ <AddCircle fontSize="small" /> } size="lg">
                                    Cadastrar paciente
                                </DButton>
                            </NavLink>
                        </Grid>                        
                    </Grid>
                </Grid>
            </Grid>
            <PacienteGrid
                detalhes={ e => props.detalhes(e) }
                pacientes={ props.pacientes }
                loading={props.loadingPacientes}
                pagination={ props.pagination }
                onPage={ props.onPage }
                page={ props.page }
            />
        </>
    )
}

export default withRouter(PacienteList);