import { TextField } from "@material-ui/core";

function Alcoholic(props)
{
    function getType()
    {
        if (typeof props.preForm !== 'undefined') {
            switch(props.preForm.alcoholic) {
                case 'Ocasional':
                case 'Muito Frequente':
                case 'Raro':
                case 'Frequente':
                    return `${props.preForm.alcoholic}, ${props.preForm.alcoholic_period}, tipo de bebida: ${props.preForm.alcoholic_type}`;
                default:
                    return "Abstinente";
            }
        }
    }

    // return <Typography variant="body1" className="text-regular text-st">{ getType() }</Typography>
    return <TextField className='input_new_solicitante_new_tab' id="standard-basic" disabled value={getType()} label="Uso de bebida alcoolica" variant="standard" />
}

export default Alcoholic;