import { Link } from 'react-router-dom';
import { Container, Typography } from  "@material-ui/core";

function Home(){

    return (
        <Container>
            <Typography variant="h1">Home</Typography>
            <ul>
                <li>
                    <Link to="/botoes">Botões</Link>
                </li>
                <li>
                    <Link to="/inputs">Inputs</Link>
                </li>
                <li>
                    <Link to="/textareas">Textareas</Link>
                </li>
                <li>
                    <Link to="/switches">Switches</Link>
                </li>
                <li>
                    <Link to="/radios">Radios</Link>
                </li>
                <li>
                    <Link to="/titulos">titulos</Link>
                </li>
            </ul>
        </Container>
    )
}

export default Home;