import axios from "axios";
import env from "react-dotenv";
import { getBrowserData } from "../helpers/browser";

function checkAuth(token) {
  return fetch(`${env.API_URL}/check-token?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function auth(dados) {
  var body = new FormData();
  body.append("email", dados.email);
  body.append("password", dados.senha);

  let strBrowserData = getBrowserData();

  body.append("browser", strBrowserData);
  body.append("password", dados.senha);

  return fetch(`${env.API_URL}/auth`, {
    method: "post",
    body,
  }).then((res) => res.json());
}

export async function checkIsValidUserId(userid) {
  try {
    const { data } = await axios.post(`${env.API_URL}/verify-username`, {
      username: userid,
    });

    return data;
  } catch (error) {
    console.log(error);

    throw error;
  }
}

export async function authId(dados) {
  try {
    const { data } = await axios.post(`${env.API_URL}/authid`, {
      username: dados.id,
      password: dados.senha,
      browser: getBrowserData(),
    });

    return data;
  } catch (error) {
    return error.response.data;
  }
}

function resendCode(type) {
  var body = new FormData();
  body.append("sms", type === "sms");

  return fetch(
    `${env.API_URL}/two-factor-resend?token=${localStorage.getItem("token")}`,
    {
      method: "post",
      body,
    }
  ).then((res) => res.json());
}

function verificarCodigo(dados) {
  var body = new FormData();
  body.append("code", dados.codigo);

  let strBrowserData = getBrowserData();

  body.append("browser", strBrowserData);

  return fetch(
    `${env.API_URL}/two-factor?token=${localStorage.getItem("token")}`,
    {
      method: "post",
      body,
    }
  ).then((res) => res.json());
}

function recoverPassword(dados) {
  var body = new FormData();
  body.append("email", dados.email);

  return fetch(`${env.API_URL}/auth-recover`, {
    method: "post",
    body,
  }).then((res) => res.json());
}

function refreshPassword(senha, token) {
  var body = new FormData();
  body.append("password", senha);

  return fetch(`${env.API_URL}/auth-refresh?token=${token}`, {
    method: "post",
    body,
  }).then((res) => res.json());
}

function executeLogout(token) {
  return fetch(`${env.API_URL}/logout?token=${token}`, {
    method: "post",
  }).then((res) => res.json());
}

export {
  auth,
  resendCode,
  verificarCodigo,
  recoverPassword,
  refreshPassword,
  checkAuth,
  executeLogout,
};
