import { useEffect, useState } from 'react';
import Solicitacoes from "./Solicitacoes";
import { useAuthRoute } from "./../../context/AuthRouteContext";
import { getAllHealthAttendanceRequest } from "./../../api/health_attendances";

function SolicitacoesContent()
{

    const { auth, setAuth } = useAuthRoute();

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getAllHealthAttendanceRequest(localStorage.getItem('token'), localStorage.getItem('uid')).then(res => {
            setLoaded(true);
        }).catch(err => {
            switch(err.response.status){
                case 403:
                    setAuth({...auth, route: false});
                    break;
            }
        })
    }, [localStorage.getItem('token')]);

    return (
        <>
            { loaded && <Solicitacoes /> }
        </>
    )
}

export default SolicitacoesContent;