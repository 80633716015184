import {
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core";
import DAutoComplete from "../../../shared/DAutoComplete/DAutoComplete";
import DRadio from "../../../shared/DRadio";
import DRadioColumn from "../../../shared/DRadioColumn";
import DMultAutoComplete from "../../../shared/DMultAutoComplete";
import { useEffect, useState } from "react";
import useError from "../../../../hooks/useError";
import { getAllComorbidities } from "../../../../api/comorbidity";
import AntecedentesFamiliares from "./AntecedentesFamiliares";
import Comorbidades from "./Comorbidades";
import Tabagismo from "./Tabagismo";
import DrogasIlicitas from "./DrogasIlicitas";
import LineCares from "../../../../api/lineCare";
import Alergias from "./../../../../assets/dados/alergias.json";
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import DAutoCompleteControlled from "../../DAutoCompleteControlled";
import { SwitchAccessShortcutAddSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  bg: {},
  card: {
    width: "100%",
    backgroundColor: "#f9f9f9",
  },
  alergias: {
    marginTop: theme.spacing(2),
  },
  medicoescard: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderRadius: "4px",
    marginTop: 16,
    fontSize: 14,
    boxShadow:
      "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
  },
  medicoescards: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "10px",
    color: "#000 !important",
  },
  root: {
    display: "inline !important",
  },
  label: {
    color: "#000000 !important",
  },
  buttonSymptom: {
    minWidth: "15px !important",
    height: "34px !important",
    borderRadius: "100px !important",
    fontSize: "14px !important",
  },
  input_root: {
    width: "50px !important",
    marginRight: 16,
    "& input": {
      padding: 8,
      fontSize: 14,
    },
  },
  input_root_tempo: {
    width: "40px !important",
    height: "36px !important",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    "& input": {
      width: "40px !important",
      height: "36px !important",
      padding: "8px 12px 7px 12px",
      boxSizing: "border-box",
      fontSize: 14,
    },
  },
  input_root_tempo_maior: {
    width: "49px !important",
    height: "36px !important",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    "& input": {
      width: "49px !important",
      height: "36px !important",
      padding: "8px 13px 7px 12px",
      boxSizing: "border-box",
      fontSize: 14,
    },
  },
  autocomplete: {
    fontSize: 14,
  },
  gHeader: {
    paddingLeft: "12px !important",
    width: "18%",
    alignItems: "flex-end",
    display: "flex",
    position: "absolute !important",
    paddingBottom: "16px !important",
    top: "155px !important",
    fontSize: 16,
    color: theme.palette.primary.light,
  },
  icon: {
    cursor: "pointer",
  },
  labelError: {
    color: "#D13D2E",
    fontSize: 14,
  },
  labelInitial: {
    color: "initial",
    fontSize: 14,
  },
  checkboxInitial: {
    color: "initial",
  },
  checkboxError: {
    color: "#D13D2E",
  },
}));

const drinkOptions = [
  {
    value: "Abstinente",
    label: "Abstinente",
    note: "1 vez ao ano ou nunca",
    width: 170,
  },
  { value: "Raro", label: "Raro", note: "menos de 1 vez por mês", width: 170 },
  {
    value: "Ocasional",
    label: "Ocasional",
    note: "de 1 a 3 vezes por mês",
    width: 170,
  },
  {
    value: "Frequente",
    label: "Frequente",
    note: "de 1 a 4 vezes por semana",
    width: 170,
  },
  {
    value: "Muito Frequente",
    label: "Muito Frequente",
    note: "bebe todos os dias",
    width: 170,
  },
];

const timeOptions = [
  { id: 1, value: "Ano(s)" },
  { id: 2, value: "Mês(es)" },
  { id: 3, value: "Dia(s)" },
]

function AntecedentesEdit(props)
{
    const classes = useStyles();
    const theme = useTheme()

    const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();

    const drugsListOptions = [
      { id: 1, value: "Chá de Ayahuasca" },
      { id: 2, value: "Cocaína" },
      { id: 3, value: "Crack" },
      { id: 4, value: "Ecstasy" },
      { id: 5, value: "Haxixe" },
      { id: 6, value: "Heroína" },
      { id: 7, value: "Lança-perfume" },
      { id: 8, value: "LSD" },
      { id: 9, value: "Maconha" },
      { id: 10, value: "MDMA" },
      { id: 11, value: "Merla" },
      { id: 12, value: "Oxi" },
      { id: 13, value: "Quetamina" },
      { id: 14, value: "Skank" },
      { id: 15, value: "Outra" },
  ]

  const useDrugsListOptions = [
      { id: 1, value: "Aspirada" },
      { id: 2, value: "Injetada" },
      { id: 3, value: "Inalada" },
      { id: 4, value: "Oral" },
      { id: 5, value: "Sublingual" },
      { id: 6, value: "Fumada" },
  ]

    let user_id = localStorage.getItem("uid");
  
    const [lineOfCare, setLineOfCare] = useState();
    const [lineOfCareType, setLineOfCareType] = useState("");
    const [foodAllergiesOptions, setFoodAllergiesOptions] = useState(Alergias);
    const [foodAllergies, setFoodAllergies] = useState([]);
    const [drugAllergies, setDrugAllergies] = useState([]);
    const [comorbiditiesData, setComorbiditiesData] = useState([]);
    const [comorbidity, setComorbidity] = useState([]);
    const [comorbidityFamilyAF, setComorbidityFamilyAF] = useState([]);
    const [physycalActivity, setPhysycalActivity] = useState("");
    const [smoking, setSmoking] = useState("");
    const [smoker, setSmoker] = useState({
      field_1: {
        time: null,
        period: null,
      },
      field_2: {
        amount: null,
      },
    });
    const [exSmoker, setExSmoker] = useState({
      field_1: {
        time: null,
        period: null,
      },
      field_2: {
        time: null,
        period: null,
      },
    });
    const [smoking_amount, setSmokingAmount] = useState(null);
    const [smoking_period, setSmokingPeriod] = useState(null);
    const [smoking_period_without, setSmokingPeriodWithout] = useState(null);
    const [alcoholic, setAlcoholic] = useState("");
    const [alcoholic_type, setAlcoholicType] = useState(null);
    const [alcoholicPeriod, setAlcoholicPeriod] = useState({
      field_1: {
        time: null,
        period: null,
      },
    });  
    const [drugs, setDrugs] = useState("Não");
    const [drugsList, setDrugsList] = useState([
      {
        type: null,
        use: null,
        inputValueType: "",
        inputValueUse: "",
      },
    ]);  
    const [drinkstate, setdrinkState] = useState({
      checkedA: false,
      checkedB: false,
    });

    const [linhasDeCuidado, setLinhasDeCuidado] = useState([])

    const [error, validarFormulario, setErrors] = useError({
      alcoholic: function () {
        if (!alcoholic || alcoholic === "Abstinente") 
          return undefined;
        else {
          return (!drinkstate.checkedA && !drinkstate.checkedB) || alcoholicPeriod.field_1.time <= 0 || !alcoholicPeriod.field_1.period
            ? "Campo inválido"
            : undefined;
        }
      },
      drugs: function () {
        let error = false;
        if(drugs === "Não" || drugs === "" || !drugs)
          return undefined;
        else {        
          drugsList.map((dlist) => {
            if (!dlist.type || !dlist.use) error = true;
          });
    
        return error ? "Campo obrigatório" : undefined;
        }
      },
      smoking: function () {
        switch (smoking) {
        case "Ex-fumante":
          return exSmoker.field_1.time <= 0 ||
          !exSmoker.field_1.period ||
          exSmoker.field_2.time <= 0 ||
          !exSmoker.field_2.period
          ? "Campo inválido"
          : undefined;
          break;
        case "Fumante":
          return smoker.field_1.time <= 0 ||
          !smoker.field_1.period ||
          smoker.field_2.amount <= 0
          ? "Campo inválido"
          : undefined;
        default:
          return undefined;
        }
      }
    });

    useEffect(() => {
        if(healthAttendance) {
            if(healthAttendance.line_care) {
              setLineOfCare({ id: healthAttendance.line_care.id, value: healthAttendance.line_care.name })
              setLineOfCareType(healthAttendance.line_care.name);
            }

            setPhysycalActivity(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].physical_activity : "");
            setSmoking(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].smoking : "");
            setSmokingAmount(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].smoking_amount : "");
            setSmokingPeriod(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].smoking_period : "");
            setSmokingPeriodWithout(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].smoking_period_without : "");
            setAlcoholic(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].alcoholic : "");

            if(healthAttendance.anamnese_pre_form[0].alcoholic_period) {
              var new_alcoholic_period = {
                field_1: {
                  time: healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].alcoholic_period.split(" ")[0] : null,
                  period: healthAttendance.anamnese_pre_form ? timeOptions.find(time => time.value === healthAttendance.anamnese_pre_form[0].alcoholic_period.split(" ")[1]) : null,
                }
              }
              setAlcoholicPeriod(new_alcoholic_period);
            }


            var new_alcoholic_type = healthAttendance.anamnese_pre_form && healthAttendance.anamnese_pre_form[0].alcoholic_type ? healthAttendance.anamnese_pre_form[0].alcoholic_type.split(", ") : [];

            setdrinkState({
              checkedA: new_alcoholic_type.indexOf('Fermentada') >= 0,
              checkedB: new_alcoholic_type.indexOf('Destilada') >= 0,
            })

            setAlcoholicType(healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].alcoholic_type : null);

            if(healthAttendance.anamnese_pre_form && healthAttendance.anamnese_pre_form[0].anamnese_pre_form_drugs && healthAttendance.anamnese_pre_form[0].anamnese_pre_form_drugs.length > 0){
                setDrugs("Sim");
                setDrugsList(healthAttendance.anamnese_pre_form[0].anamnese_pre_form_drugs.map(drugs => {
                  return {
                    type: drugs.drug_type,
                    use: drugs.drug_use,
                    inputValueType: drugs.drug_type,
                    inputValueUse: drugs.drug_use,
                  }
                }))
            } else {
              setDrugs("Não");
              setDrugsList([{
                type: null,
                use: null,
                inputValueType: "",
                inputValueUse: "",
              }])
            }

            getAllComorbidities(localStorage.getItem("token")).then((res) => {
              if (res.status) {
                setComorbiditiesData(...comorbiditiesData, res.comorbidity);
                setComorbidity(
                  res.comorbidity.map((comorbidity) => {

                    var checked_c = healthAttendance.anamnese_pre_form
                      .filter(pre_form => pre_form.anamnese_pre_comorbidity.length > 0 && pre_form.anamnese_pre_comorbidity.find(comorbidity_af => comorbidity_af.comorbidity_id === comorbidity.id))
                      .map(pre_form => pre_form.anamnese_pre_comorbidity.filter(comorbidity_af => comorbidity_af.comorbidity_id === comorbidity.id));

                    var new_value = {
                      id: comorbidity.id,
                      checked: checked_c.length > 0,
                      observation: checked_c.length > 0 ? checked_c[0][0].observation : "",
                    }

                    return new_value;
                  })
                );
                setComorbidityFamilyAF(
                  res.comorbidity.map((comorbidity) => {

                    var checked_c_af = healthAttendance.anamnese_pre_form
                      .filter(pre_form => pre_form.anamnese_pre_comorbidity_family_history.length > 0 && pre_form.anamnese_pre_comorbidity_family_history.find(comorbidity_af => comorbidity_af.comorbidity_family_history_id === comorbidity.id))
                      .map(pre_form => pre_form.anamnese_pre_comorbidity_family_history.filter(comorbidity_af => comorbidity_af.comorbidity_family_history_id === comorbidity.id));

                    var new_value = {
                      id: comorbidity.id,
                      checked: checked_c_af.length > 0,
                      observation: checked_c_af.length > 0 ? checked_c_af[0][0].observation : "",
                    }

                    return new_value;
                  })
                );
              } else {
                setComorbiditiesData(...comorbiditiesData, []);
              }
            }).catch((err) => console.error(err));
    
            LineCares.getAllLineCare(localStorage.getItem('token')).then(res => {
    
                if (res.status) {
    
                    setLinhasDeCuidado(res.lineCare.filter(function (item) {
                        return item.active === 1;
                    }).map(function (item) {
                        return {
                            id: item.id,
                            value: item.name
                        } 
                    }))
    
                }
            })
        }
    }, [healthAttendance])
  
    const handledrinkChange = (event) => {
      setdrinkState({ ...drinkstate, [event.target.name]: event.target.checked });
    };

    function getData() {
      let food_allergy = foodAllergies.map(function (food) {
        return {
          id: food.id,
          name: food.value,
        };
      });
  
      let medicine_allergy = drugAllergies.map(function (drug) {
        return {
          id: drug.id,
          name: drug.value,
        };
      });
  
      var data;
  
      data = {
        comorbidity: comorbidity
          .filter((y) => y.checked)
          .map((x) => {
            return {
              id: x.id,
              observation: x.observation,
            };
          }),
        comorbidity_family_history: comorbidityFamilyAF
          .filter((y) => y.checked)
          .map((x) => {
            return {
              id: x.id,
              observation: x.observation,
            };
          }),
        lineOfCare,
        food_allergy,
        medicine_allergy,
        physical_activity: physycalActivity,
        smoking,
        smoking_amount, // string "20 por dia"
        smoking_period, // string "6 anos (Periodo fumando)"
        smoking_period_without, // string | null  2 anos (Periodo sem fumar)
        alcoholic,
        alcoholic_type: null, // string "destilada" / "Destilada, Alguma coisa"
        alcoholic_period: null, // string "16 anos"
        drugs,
        drugs_list: drugsList
      };
  
      if (smoking == "Ex-fumante") {
        data.smoking_period = `${exSmoker.field_1.time} ${
          exSmoker.field_1.period ? exSmoker.field_1.period.value : ""
        } (Período fumando)`;
        data.smoking_period_without = `${exSmoker.field_2.time} ${
          exSmoker.field_2.period ? exSmoker.field_2.period.value : ""
        } (Período sem fumar)`;
      } else if (smoking == "Fumante") {
        data.smoking_period = `${smoker.field_1.time} ${
          smoker.field_1.period ? smoker.field_1.period.value : ""
        }`;
        data.smoking_period_without = `${smoker.field_2.amount} por dia`;
        data.smoking_amount = smoker.field_2.amount;
      }
  
      if (drugs == "Não") {
        data.drugs_list = [];
      }
  
      if (alcoholic != "Abstinente") {
        data.alcoholic_type = `${
          drinkstate.checkedA
            ? drinkstate.checkedB
              ? "Fermentada, "
              : "Fermentada"
            : ""
        }${drinkstate.checkedB ? "Destilada" : ""}`;
        data.alcoholic_period = `${alcoholicPeriod.field_1.time} ${
          alcoholicPeriod.field_1.period
            ? alcoholicPeriod.field_1.period.value
            : ""
        }`;
      }
  
      return data;
    }

    return (
    <form onSubmit={ form => {
        form.preventDefault();      

        let data = getData();

        try {
          if (validarFormulario({ ...data })) {
            props.onSave({...data, anamnese_pre_form_id: healthAttendance && healthAttendance.anamnese_pre_form ? healthAttendance.anamnese_pre_form[0].id : null});
          } else {
            console.log('formulario invalido');
          }
        } catch (e) {
          
        }
    } }>
      <div className='main_flex_new_tab'>
				<div className='fonte_new_tab text-primary'>
					Antecedentes pessoais
				</div>
				<div className='flex_end_new_tab'>
					{ props.children }
				</div>
			</div>
      <div className='mt_35_new_tab'>
          <Box className="title-section">
              <Typography variant={"h5"} style={{ color: theme.palette.primary.light }}>
                  Linhas de cuidado
              </Typography>
          </Box>
          <Box style={{ paddingTop: 10, display: "grid" }}>
              <DAutoCompleteControlled
                  fullWidth
                  placeholder="Linhas de cuidado"
                  label="Linhas de cuidado"
                  labelColor="black"
                  options={ linhasDeCuidado }
                  getOptionLabel={(option) => option.value}
                  getOptionSelected={(option, value) =>
                    value && option.id === value.id
                  }
                  value={ lineOfCare }
                  inputValue={ lineOfCareType }
                  openText="Visualizar listagem"
                  onInputChange={(event, newInputValue) => {
                      setLineOfCareType(newInputValue);
                  }}
                  onChange={(e, newValue) => {
                      setLineOfCare(newValue);
                  }}
                  style={{ maginBottom: 16 }}
                />
          </Box>
          <Box className="title-section">
              <Typography variant={"h5"} style={{ color: theme.palette.primary.light }}>
                  Comorbidades
              </Typography>
          </Box>
          <Box style={{ paddingTop: 10, display: "grid" }}>
              {comorbiditiesData.length > 0 ? (
                  <>
                  {comorbiditiesData.map((comorbiditie, index) => (
                      <Comorbidades
                      key={`comorbidades-${index}`}
                      label={comorbiditie.description}
                      value={comorbidity[index]}
                      onUpdate={(dados) => {
                          let new_comorbidity = [...comorbidity];
                          new_comorbidity[index].checked = dados.checked;
                          new_comorbidity[index].observation =
                          dados.observation;
                          setComorbidity(new_comorbidity);
                      }}
                      />
                  ))}
                  </>
              ) : (
                  <Typography
                  variant="body"
                  className="text-regular text-st"
                  >
                  Não existem comorbidades cadastradas
                  </Typography>
              )}
          </Box>
          <Box className="title-section">
              <Typography variant="h5" style={{ color: theme.palette.primary.light }}>
                  Antecedentes Familiares (AF)
              </Typography>
          </Box>
          <Box style={{ paddingTop: 10, display: "grid" }}>
              {comorbiditiesData.length > 0 ? (
                  <>
                  {comorbiditiesData.map((comorbiditie, index) => (
                      <AntecedentesFamiliares
                          key={`antecedentes-familiares-${index}`}
                          label={comorbiditie.description}
                          value={comorbidityFamilyAF[index]}
                          onUpdate={(dados) => {
                              let new_comorbidity_family = [
                              ...comorbidityFamilyAF,
                              ];
                              new_comorbidity_family[index].checked =
                              dados.checked;
                              new_comorbidity_family[index].observation =
                              dados.observation;
                              setComorbidityFamilyAF(new_comorbidity_family);
                          }}
                      />
                  ))}
                  </>
              ) : (
                  <Typography
                  variant="body"
                  className="text-regular text-st"
                  >
                  Não existem comorbidades cadastradas
                  </Typography>
              )}
          </Box>
          <Box style={{ marginTop: 32, padding: "0 0", width: 308 }}>
              <Typography variant="h6" style={{ marginTop: 16 }}>
                  Atividade Física
              </Typography>
              <div style={{ padding: "0 0 0 26px" }}>
                  <DRadio
                      name="physycal-activity"
                      onChange={(e) => {
                          setPhysycalActivity(e.target.value);
                      }}
                      value={physycalActivity}
                      labelClass={classes.label}
                      options={[
                          { value: "Não pratica", label: "Não pratica" },
                          {
                          value: "de 1 a 2 vezes por semana",
                          label: "de 1 a 2 vezes por semana",
                          },
                          {
                          value: "de 3 a 4 vezes por semana",
                          label: "de 3 a 4 vezes por semana",
                          },
                          {
                          value: "de 5 ou mais vezes por semana",
                          label: "de 5 ou mais vezes por semana",
                          },
                      ]}
                      />
              </div>
          </Box>
          <Box style={{ marginTop: 32, padding: "0 0", width: 408 }}>
              <Tabagismo
                  error={error.smoking}
                  onChange={(tipo, tempo, tempoParado, quantidade) => {
                  delete error.smoking;
                  setSmoking(tipo);
                  switch (tipo) {
                      case "Ex-fumante":
                      let new_ex_smoker = {
                          field_1: {
                          period: tempo.tipo,
                          time: tempo.valor,
                          },
                          field_2: {
                          period: tempoParado.tipo,
                          time: tempoParado.valor,
                          },
                      };
                      setExSmoker(new_ex_smoker);
                      break;
                      case "Fumante":
                      let new_smoker = {
                          field_1: {
                          period: tempo.tipo,
                          time: tempo.valor,
                          },
                          field_2: {
                          amount: quantidade,
                          },
                      };
                      setSmoker(new_smoker);
                      break;
                  }
                  }}
              />
          </Box>
          <Box style={{ marginTop: 32, padding: "0", width: 408 }}>
              <Typography variant="h6" style={{ marginTop: 16 }}>
                  Uso de bebida alcoólica
              </Typography>
              <div style={{ padding: "0 0 0 26px" }}>
                  <DRadioColumn
                      column={2}
                      name="alcoholic-beverage-use"
                      onChange={(e) => setAlcoholic(e.target.value)}
                      value={alcoholic}
                      labelClass={classes.label}
                      options={drinkOptions}
                      />
              </div>
              {alcoholic && alcoholic != "Abstinente" ? (
                  <Grid container style={{ marginTop: 16 }}>
                      <Grid
                          item
                          sm={4}
                          style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 8,
                          }}
                      >
                          Tipo de bebida:
                      </Grid>
                      <Grid item sm={8} style={{ marginBottom: 8 }}>
                          <FormGroup row>
                              <FormControlLabel
                                  control={
                                  <Checkbox
                                      classes={{
                                      colorSecondary:
                                          error.alcoholic &&
                                          !drinkstate.checkedA &&
                                          !drinkstate.checkedB
                                          ? classes.checkboxError
                                          : classes.checkboxInitial,
                                      }}
                                      checked={drinkstate.checkedA}
                                      onChange={handledrinkChange}
                                      name="checkedA"
                                  />
                                  }
                                  label="Fermentada"
                                  classes={{
                                  label:
                                      error.alcoholic &&
                                      !drinkstate.checkedA &&
                                      !drinkstate.checkedB
                                      ? classes.labelError
                                      : classes.labelInitial,
                                  }}
                                  onChange={(e) => {
                                  setAlcoholicType(null);
                                  }}
                              />
                              <FormControlLabel
                                  control={
                                  <Checkbox
                                      classes={{
                                      colorSecondary:
                                          error.alcoholic &&
                                          !drinkstate.checkedA &&
                                          !drinkstate.checkedB
                                          ? classes.checkboxError
                                          : classes.checkboxInitial,
                                      }}
                                      checked={drinkstate.checkedB}
                                      onChange={handledrinkChange}
                                      name="checkedB"
                                  />
                                  }
                                  label="Destilada"
                                  classes={{
                                  label:
                                      error.alcoholic &&
                                      !drinkstate.checkedA &&
                                      !drinkstate.checkedB
                                      ? classes.labelError
                                      : classes.labelInitial,
                                  }}
                                  onChange={(e) => {
                                  setAlcoholicType(null);
                                  }}
                              />
                          </FormGroup>
                      </Grid>
                      <Grid
                          item
                          sm={4}
                          style={{ display: "flex", alignItems: "center" }}
                      >
                          Há quanto tempo?
                      </Grid>
                      <Grid item sm={8}>
                          <TextField
                              label=""
                              variant="outlined"
                              placeholder="00"
                              inputProps={{ maxLength: 2 }}
                              error={
                                  error.alcoholic &&
                                  alcoholicPeriod.field_1.time <= 0
                              }
                              value={alcoholicPeriod.field_1.time}
                              onChange={(e) => {
                                  var value = e.target.value.replace(/[^0-9]/g, "");
                                  setAlcoholicPeriod({
                                  ...alcoholicPeriod,
                                  field_1: {
                                      ...alcoholicPeriod.field_1,
                                      time: value,
                                  },
                                  });
                              }}
                              classes={{
                                  root: classes.input_root_tempo,
                              }}
                              />
                          <DAutoCompleteControlled
                              label=""
                              placeholder=""
                              labelColor="black"
                              openText="Visualizar listagem"
                              style={{ marginTop: -16, width: 120 }}
                              classes={{ input: classes.autocomplete }}
                              error={
                                  error.alcoholic && !alcoholicPeriod.field_1.period
                              }
                              value={alcoholicPeriod.field_1.period}
                              onChange={(e, x) => {
                                  setAlcoholicPeriod({
                                  ...alcoholicPeriod,
                                  field_1: {
                                      ...alcoholicPeriod.field_1,
                                      period: x,
                                  },
                                  });
                              }}
                              options={ timeOptions }
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) =>
                                  value && option.id === value.id
                              }
                              />
                      </Grid>
                  </Grid>
              ) : (
                  ""
              )}
          </Box>
          <Box style={{ marginTop: 32, padding: "0", width: 408 }}>
              <Typography variant="h6" style={{ marginTop: 16 }}>
                  Uso de drogas ilícitas
              </Typography>
              <div style={{ padding: "0 0 0 10px" }}>
                  <DRadio
                      inline
                      name="drugs-use"
                      onChange={(e) => setDrugs(e.target.value)}
                      value={drugs}
                      labelClass={classes.label}
                      options={[
                          { value: "Não", label: "Não" },
                          { value: "Sim", label: "Sim" },
                      ]}
                      />
              </div>
              {drugsList && drugs == "Sim" ? (
                  <Grid container>
                      <Grid item md={12} st>
                          {drugsList.map((item, index) => {
                              return (
                                  <DrogasIlicitas
                                      key={`DrogasIlicitas-${index}`}
                                      error={error.drugs}
                                      index={index}
                                      value={item}
                                      totalDrugs={drugsList.length}
                                      onRemoveDrug={(e) => {
                                          let new_drugs_list = [...drugsList];
                                          new_drugs_list.splice(index, 1);
                                          setDrugsList(new_drugs_list);
                                      }}
                                      onAddDrug={(e) => {
                                          let new_drugs_list = [...drugsList];
                                          new_drugs_list.push({
                                          type: null,
                                          use: null,
                                          inputValueType: "",
                                          inputValueUse: "",
                                          });
                                          setDrugsList(new_drugs_list);
                                      }}
                                      onChange={(
                                          type,
                                          use,
                                          inputValueType,
                                          inputValueUse
                                      ) => {
                                          delete error.drugs;

                                          let new_drugs_list = [...drugsList];
                                          new_drugs_list[index].type = type;
                                          new_drugs_list[index].use = use;
                                          new_drugs_list[index].inputValueType =
                                          inputValueType;
                                          new_drugs_list[index].inputValueUse =
                                          inputValueUse;
                                          setDrugsList(new_drugs_list);
                                      }}
                                  />
                              );
                          })}
                      </Grid>
                  </Grid>
              ) : (
                  ""
              )}
          </Box>
      </div>
    </form>
    )
}

AntecedentesEdit.defaultProps = {
  onSave: function(data_form) {
    console.log(data_form);
  }
}

export default AntecedentesEdit;