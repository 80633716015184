import { useState, useEffect, useRef } from "react";
import { useNewHealthAttendance } from "./../../context/NewHealthAttendance";
import { getPatientById } from "./../../api/patient";
import FloatingChat from "../AtendimentoImediato/components/floatingChat";
import Toolbar from "./components/Toolbar";
import Content from "./components/Content";
import { getAllUnidades } from "../../api/unidades";
import { userDominios as getUserDominios } from "../../api/dominios";
import { getUserInfoById } from "../../api/user";
import VideoCall from "../shared/VideoCall";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { Participant } from "../shared/VideoCall/useVideoCall";

function AtendimentoImediato(props) {
  const [userDominios, setUserDominios] = useState(null);
  const [user, setUser] = useState(null);
  const [unidadesRef, setUnidadesRef] = useState(null);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState(null);
  const [featuredVideo, setFeaturedVideo] = useState(null);

  const {
    healthAttendance,
    setHealthAttendance,
    patient,
    setPatient,
    setFinish,
    setFinished,
    setFinishLock,
  } = useNewHealthAttendance();
  useEffect(() => {
    setFinishLock(true);
    setFinish(false);
    setFinished(false);
    setHealthAttendance(props.healthAttendance);

    getPatientById(
      localStorage.getItem("token"),
      props.healthAttendance.patient.id
    ).then((res) => {
      if (res.status) setPatient(res.details.patient);
    });

    getUserInfoById(
      localStorage.getItem("token"),
      localStorage.getItem("uid")
    ).then((res) => {
      console.log("USER", res);
      if (res.status) setUser(res.user);
    });

    getUserDominios().then((res) => {
      setUserDominios(res);
    });

    getAllUnidades(localStorage.getItem("token")).then((res) => {
      if (Array.isArray(res)) {
        setUnidadesRef(res.filter((x) => x.below_mother_medical_unit === "no"));
        setUnidadesVinculadas(
          res.filter((x) => x.below_mother_medical_unit !== "no")
        );
      }
    });

    localStorage.removeItem("health_attendance_interconsultation");
  }, []);

  const [openChat, setOpenChat] = useState(false);
  const [openVideoCall, setOpenVideoCall] = useState(true);

  useEffect(() => {
    console.log("featuredVideo", featuredVideo);
  }, [featuredVideo]);

  if (!unidadesRef || !unidadesVinculadas || !user || !userDominios || !patient)
    return (
      <Backdrop
        open={true}
        style={{ zIndex: 99999, color: "white", textAlign: "center" }}
      >
        <Grid container>
          <Grid item md={12}>
            <CircularProgress style={{ color: "inherit" }} />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">
              Carregando atendimento...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
    );

  return (
    <>
      {openVideoCall ? (
        <>
          <Toolbar
            onFinish={(e) => setFinish(true)}
            onBack={(e) => {
              setHealthAttendance(null);
              props.onBack(true);
            }}
            onChat={(e) => setOpenChat(!openChat)}
            onVideoCall={(e) => setOpenVideoCall(!openVideoCall)}
          />

          {openChat && patient && healthAttendance && (
            <FloatingChat
              openChat={openChat}
              idUser={localStorage.getItem("uid")}
              videocall
              idAtendimento={healthAttendance && healthAttendance.id}
            />
          )}
        </>
      ) : (
        <VideoCall
          idAtendimento={healthAttendance.id}
          paciente={{ patient: patient }}
          attendance={healthAttendance}
          solicitante={
            healthAttendance &&
            (healthAttendance.user_requesting
              ? healthAttendance.user_requesting.full_name
              : null)
          }
          idUser={user.id}
          userDominios={userDominios}
          unidadesRef={unidadesRef}
          unidadesVinculadas={unidadesVinculadas}
          patientData={patient}
          // toggleModal={(e) => setModalFidi(!modalFidi)}
          toggleModal={(e) => null}
          getPDF={(e) => null}
          // getPDF={(e) => getPDF(e)}
          setModalAtendimentoCmd={(e) => null}
          // setModalAtendimentoCmd={(e) => props.setModalAtendimentoCmd(true)}
          onFinish={(e) => setOpenVideoCall(!openVideoCall)}
          setFeaturedVideo={setFeaturedVideo}
        >
          {featuredVideo && <Participant participant={featuredVideo} />}
        </VideoCall>
      )}

      <Content onFinished={(e) => props.onBack(true)} />
    </>
  );
}

AtendimentoImediato.defaultProps = {
  onBack: function () {
    console.log("onBack");
  },
  solicitante: "João da silva",
};

export default AtendimentoImediato;
