import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss";
import InfoIcon from '@mui/icons-material/Info';
import { Divider, Grid, makeStyles, TextField } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const useStyles = makeStyles((theme) => ({
  titleModalAbdicar: {
    color: theme.palette.support.error,
    fontSize: "16px",
    fontWeight: "700",
  },
  subtitleModalAbdicar: {
    color: theme.palette.primary.light,
    fontSize: "16px",
    fontWeight: "700",
  },
}));

export default function ModalAbdicar() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles();

  return (
    <div>
      <Button onClick={handleOpen}>Teste</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className='estilo_modal_abdicar'>
          <div className='flex_modal_abdicar'>
            <div className={classes.titleModalAbdicar} id="modal-modal-title">
              Abdicar Atendimento
            </div>
            <div className='flex_end_modal_abdicar'>
              <Button onClick={handleClose}>
                <CloseIcon className='icone_modal_abdicar' />
              </Button>
            </div>
          </div>
          <div className='mt20_modal_abdicar'>
            <Divider variant="fullWidth" className='divide_new_tab' />
          </div>
          <div className={`mt20_modal_abdicar ${classes.subtitleModalAbdicar}`}>
            Motivo
          </div>
          <div className='mt10_modal_abdicar'>
            <FormControlLabel className='radio_color_abdicar' value="end" control={<Radio />} label="Paciente não encontrado" />
          </div>
          <div className='mt10_modal_abdicar'>
            <FormControlLabel className='radio_color_abdicar' value="end" control={<Radio />} label="Paciente recusa atendimento" />
          </div>
          <div className='mt10_modal_abdicar'>
            <FormControlLabel className='radio_color_abdicar' value="end" control={<Radio />} label="Dificuldade de comunicação" />
          </div>
          <div className='mt10_modal_abdicar flex_pai_divide_modal_abdicar'>
            <div>
              <FormControlLabel className='radio_color_abdicar' value="end" control={<Radio />} label="" />
            </div>
            <div className='w_100_abdicar'>
              <TextField className='input_modal_abdicar' id="standard-basic" label="Outro" variant="standard" />
            </div>
          </div>
          <div className='mt20_modal_abdicar'>
            <Divider variant="fullWidth" className='divide_new_tab' />
          </div>
          <div className='alinha_botoes_modal_abdicar mt20_modal_abdicar'>
            <Button className='botao_voltar_abdicar'>
              Voltar
            </Button>
            <Button className='botao_confirmar_abdicar'>
              Abdicar atendimento
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}