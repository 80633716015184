import TextField from "@material-ui/core/TextField";
import {Autocomplete as MAutocomplete, createFilterOptions } from "@material-ui/lab";
import React from "react";
import Error from "@material-ui/icons/Error";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {FormControl, FormHelperText} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    disabled: {
        opacity: '0.25!important'
    },
    root: {
        minWidth: '100%'
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textInitial: {
        textTransform: 'initial'
    }
}));

function DAutoComplete(props) {

    const classes = useStyles();

    const properts = {...props};

    delete properts.label;
    delete properts.error;
    delete properts.helperText;
    delete properts.renderInput;

    return (
        <FormControl className={ `${ props.fullWidth ? classes.root : "" }  ${ props.error ? 'form-control-error' : '' }` }>
            <MAutocomplete { ...properts }
                noOptionsText={props.noOptionsText}
               classes={{option: props.capitalize ? classes.textCapitalize : classes.textInitial, input: props.capitalize ? classes.textCapitalize : classes.textInitial }}
               className={ `${ props.disabled ? classes.disabled : "" }` }
               popupIcon={ props.error ? <Error /> : <ExpandMore style={{color: '#2E3192'}} /> }
               renderInput={(params) => (
                   <TextField {...params} variant="standard" label={<>{props.labelColor ? (<span style={{ color: props.labelColor }}>{props.label}</span>) : props.label} {props.required === true ? (<span style={{ color: 'red' }}>*</span>) : ''}</>} placeholder={ props.placeholder } />
               )}
            />
            { props.helperText ? <FormHelperText>{ props.helperText }</FormHelperText> : '' }
        </FormControl>
    )
}


DAutoComplete.defaultProps = {
    noOptionsText: "Nenhuma opção",
};

export default DAutoComplete;