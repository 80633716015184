import { IconButton, Typography, useTheme } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import DDateInput from "../../../../../../../shared/DDateInput";
import { format } from "date-fns";

function AgendaSemana(props) {
  const theme = useTheme()
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 278,
            margin: "0 auto",
            borderRadius: 8,
            padding: 24,
            backgroundColor: "#ffffff",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            {props.selectedWeekDays &&
              props.selectedWeekDays.map((data, index) => {
                if (data.day === "dom") {
                  return (
                    <li
                      style={
                        data.isToday
                          ? {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 28,
                              padding: "4px 10px",
                              backgroundColor: theme.palette.primary.light,
                              color: "#fff",
                              borderRadius: 8,
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 32,
                            }
                      }
                    >
                      <Typography style={{ fontSize: 12, width: 25 }}>
                        {data.day}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 14,
                          width: 18,
                          fontWeight: 700,
                          margin: "0 24px",
                        }}
                      >
                        {data.dayNumber}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        {data.scheduleCommitment.length} Compromissos
                      </Typography>
                    </li>
                  );
                } else {
                  return (
                    <li
                      style={
                        data.isToday
                          ? {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 28,
                              padding: "4px 10px",
                              backgroundColor: theme.palette.primary.light,
                              color: "#fff",
                              borderRadius: 8,
                              marginLeft: index !== 0 && index !== 6 ? 10 : 10
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: data.day === "sab" ? 0 : 28,
                              marginLeft: index !== 0 && index !== 6 ? 15 : 10
                            }
                      }
                    >
                      <Typography style={{ fontSize: data.isToday ? 12 : 10, width: 25, textAlign: "right" }}>
                        {data.day}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: data.isToday ? 14 : 12,
                          width: 18,
                          color: data.isToday ? "#fff" : theme.palette.primary.light,
                          fontWeight: 700,
                          margin: "0 24px",
                        }}
                      >
                        {data.dayNumber}
                      </Typography>
                      { data.isToday ? <Typography>|&nbsp;</Typography> : "" }
                      <Typography style={{ fontSize: data.isToday ? 12 : 10 }}>
                        { props.totais[index] } Compromissos
                      </Typography>
                    </li>
                  );
                }
              })}
          </ul>
          <div
            style={{
              height: 355,
              marginTop: -12,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <IconButton onClick={e => props.onUpDate(e)}>
              <ExpandLessIcon fontSize="small" style={{ color: theme.palette.primary.light }} />
            </IconButton>
            <IconButton onClick={e => props.onDownDate(e)}>
              <ExpandMoreIcon fontSize="small" style={{ color: theme.palette.primary.light }} />
            </IconButton>
          </div>
        </div>
      </div>

      <div hidden={props.calendar !== 1}>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            borderRadius: 8,
            overflow: "hidden",
            backgroundColor: "#ffffff",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date()}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div hidden={props.calendar !== 2}>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            backgroundColor: "#fff",
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography style={{ fontSize: 14, margin: "16px 28px 0" }}>
            {format(new Date().setMonth(new Date().getMonth() - 1), "MMMM", {
              locale: ptBR,
            })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date().setMonth(new Date().getMonth() - 1)}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div
          style={{
            width: 326,
            margin: "24px auto",
            backgroundColor: theme.palette.background.snow,
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              margin: "16px 28px 0",
              padding: "4px 16px",
              color: theme.palette.neutral.white,
              backgroundColor: theme.palette.primary.light,
              borderRadius: 8,
              fontSize: 14,
            }}
          >
            {format(new Date(), "MMMM", { locale: ptBR })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date()}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            backgroundColor: theme.palette.background.snow,
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography style={{ fontSize: 14, margin: "16px 28px 0" }}>
            {format(new Date().setMonth(new Date().getMonth() + 1), "MMMM", {
              locale: ptBR,
            })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              // autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date().setMonth(new Date().getMonth() + 1)}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  );
}

AgendaSemana.defaultProps = {
    onUpDate: function(event) {
      console.log('onUpDate')
      console.log(event)
    },
    onDownDate: function(event) {
      console.log('onDownDate')
      console.log(event)
    },
}

export default AgendaSemana;