import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Card, CardContent, Grid, InputBase, CircularProgress, InputAdornment } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Error from '@material-ui/icons/Error';
import { Save } from '@material-ui/icons';
import DButton from "./../../../../../shared/DButton";
import Construtor from '../../../../../../api/construtor';
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    card: {
      marginBottom: 8,
    },
    cardContent: {
        paddingBottom: '5px !important'
    },    
    contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px'
    },
    titleRemove: {
        borderBottom: 'none'
    },
    buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px'
    },
    buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px'
    },
    errorColor: {
        color: theme.palette.support.error
    }
}));

function CardEdit(props)
{

    const classes = useStyles();
    const [conditional, setConditional] = useState(props.conditional.conditional);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    function updateConditional()
    {

        if(conditional.name.length < 1) {
            setError(true);
            return;
        }

        setError(false);

        setLoading(true);

        Construtor.updateConditional(localStorage.getItem('token'), conditional.id, {name: conditional.name}).then(res => {
            if(res.status) {
                toast.success(res.message)
                props.conditional.setConditional(conditional)
                props.conditional.setEdit(false)

            } else {
                toast.error(res.message)
            }

            setLoading(false);
        }).catch(res => {
            
            if (typeof res.response.data.details.message !== undefined)
                toast.error(res.response.data.details.message)
            else
                toast.error('Erro inesperado.')

            setLoading(false);
            
        })
    }

    return (
        <Card classes={{root: classes.card}}>
            <CardContent classes={{root: classes.cardContent}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item md={8}>
                        <InputBase
                            fullWidth
                            disabled={ loading }
                            defaultValue={conditional.name}
                            value={conditional.name}
                            onChange={ e => setConditional({ ...conditional, name: e.target.value }) }
                            endAdornment={ error ? <InputAdornment position="end"><Error className={classes.errorColor} /></InputAdornment> : '' }
                            inputProps={{ 'aria-label': 'name' }}
                        />                            
                    </Grid>
                    <Grid item md={4}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <DButton disabled={ loading } onClick={ updateConditional } size="sm" type="button" variant="ghost" className="btn-success" icon={ loading ? <CircularProgress size={20} color="inherit" /> : <Save size="sm" /> }>
                                    { loading ? 'Atualizando' : 'Salvar alterações' }
                                </DButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <hr />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CardEdit;