import {Grid, Typography} from "@material-ui/core";
import {CheckCircle, ErrorRounded, InfoRounded as Warning} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

function IDInstructions(props) {

    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: '#67738B',
        },
        tooltip: {
            maxWidth: 150,
            fontSize: 14,
            lineHeight: '21px',
            backgroundColor: '#67738B',
        }
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className="text-sm text-regular">Por favor não utilizar:</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.dontHaveUppercase ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Letras maiúsculas
                </Typography>
            </Grid>
            <Grid item md={6} xs={6}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.dontExceedsCharacters ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Mais do que 15 caracteres
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.dontHaveSpaces ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Espaços
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.dontHaveOffensiveWords ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Palavras ofensivas
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.dontHaveSpecialCharacter ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Caracteres especiais
                    <BootstrapTooltip title="Caracteres especiais: ! @ # $ % & * ( ) - + | \ / ? { } [ ]" placement="right-start">
                        <Warning style={{width: '16px', height: '16px'}} className="color-mid-gray icon-right" />
                    </BootstrapTooltip>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default IDInstructions;