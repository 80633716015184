import React, { useState } from 'react';
import "../index.scss";
import UltimosAtendimentosNewTab from '../../NewTabs/UltimosAtendimentosNewTab';

function SubmenuNewCardsUltimos(props) {
  const [tab, setTab] = useState(0)
  const tabList = [
    <UltimosAtendimentosNewTab onReset={ e => props.onReset(true) } />,
  ]


  return (
    <div className='main_flex_submenu_new_cards'>
      <div>
        {tabList[tab]}
      </div>
    </div>
  )
}

SubmenuNewCardsUltimos.defaultProps = {
  onReset: function() {
    console.log("onReset");
  }
}

export default SubmenuNewCardsUltimos;


