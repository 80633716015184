import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Typography, Card, CardContent, Grid } from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import DButton from '../../../../shared/DButton'; 
import DInput from '../../../../shared/DInput'; 
import fieldConditionReject from "./../../../../../assets/dados/fieldConditionReject.json";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useContionals } from '../../../../../context/ConditionalsContext';
import { toast } from "react-toastify";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
        fontSize: 20,
        marginTop: 3
    },
    arrow: {
        color: '#FFF'
    }
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    backDrop: {
        zIndex: 3000,
        color: '#FFF'
    },
    card: {
        background: '#F9F9F9',
        marginBottom: 15
    },
    cardContent: {
        paddingBottom: '16px !important'
    },
    icon: {
        color: '#949494'
    },
    iconSelect: {
        color: `${theme.palette.primary.light} !important`
    },
    iconButton: {
        cursor: 'pointer',
        color: theme.palette.support.error
    },
    formControl: {
        minWidth: 120,
        width: '100%',
        marginBottom: 15
    },
    formControlMiddle: {
        minWidth: 120,
        maxWidth: 250,
        width: '100%',
        marginBottom: 15
    },
    selectEmpty: {
    marginTop: theme.spacing(2),
    }
}));

function AddFormCondition(props)
{

    const {fields, setPendingSave} = useContionals();
    const [showOperador, setShowOperador] = useState(false);
    const classes = useStyles();    

    function ConditionForm(props)
    {

        const [id, setId] = useState(props.condition.id);
        const [fieldId, setFieldId] = useState(props.condition.field_id);
        const [operator, setOperator] = useState(props.condition.operator);
        const [compareFieldId, setCompareFieldId] = useState(props.condition.compare_field_id);
        const [contain, setContain] = useState(props.condition.contain);
        const [compareOptions, setCompareOptions] = useState(props.condition.compareOptions);
        const [sizeMultiple, setSizeMultiple] = useState(1);

        useEffect(() => {
            if(fieldId) {
                var new_options = [];
                var selected_field = fields.find(field => field.id == fieldId);

                if(selected_field && selected_field.additional_data && selected_field.additional_data.construct && selected_field.additional_data.construct.options && selected_field.additional_data.construct.options.length > 0) {

                    switch (selected_field.additional_data.construct.element) {
                        case 'Checkboxes':
                            setSizeMultiple(selected_field.additional_data.construct.options.length);
                            break;                    
                        default:
                            setSizeMultiple(1)
                            break;
                    }

                    new_options = selected_field.additional_data.construct.options.map(option => {
                        return {
                            id: option.value,
                            value: option.text
                        }
                    })
                } 

                setCompareOptions(new_options);
            }
        }, [fieldId])

        function update(dados) {
            props.onUpdate(dados)
        }

        function fragmentOperador()
        {

            if (showOperador || fieldId) {

                return <>
                    <FormControl className={ `${classes.formControlMiddle} ${ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].fieldId ? 'form-control-error' : '' }` } autoComplete="off">
                        <InputLabel id="select-operator-label">Selecione o operador</InputLabel>
                        <Select
                            autoWidth
                            disabled={props.loading}
                            IconComponent={ () => <ExpandMore color="primary" classes={{ colorPrimary: classes.iconSelect }} /> }
                            labelId="select-operator-label"
                            id="select-operator"
                            value={ operator }
                            error={ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].operator ? props.error[props.conditionKey].operator : false }
                            onChange={ e => {
                                setOperator(e.target.value);
                                setContain([]);
                                update({id, fieldId, operator: e.target.value, compareFieldId, contain: [], compareOptions});
                                setPendingSave(true);
                            }}>
                        <MenuItem value="preenchido">Está preenchido</MenuItem>
                        <MenuItem value="branco">Está em branco</MenuItem>
                        <MenuItem value="igual">É igual a</MenuItem>
                        <MenuItem value="diferente">É diferente de</MenuItem>
                        <MenuItem value="contem">Contém</MenuItem>
                        <MenuItem value="nao_contem">Não contém</MenuItem>
                        </Select>
                        { props.error && props.error[props.conditionKey] && props.error[props.conditionKey].operator ? <FormHelperText>{ props.error[props.conditionKey].operator }</FormHelperText> : '' }
                    </FormControl>
                </>;

            }

        }

        return (
            <Grid item md={12}>
                <Card classes={{root: classes.card}}>
                    <CardContent>
                    <Grid container>
                            <Grid item md={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item style={{ display: (props.conditionKey > 0) ? "inherit" : "none" }}>
                                        <DeleteIcon classes={{root: classes.iconButton}} onClick={ e => props.onRemove(true) } />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12}>
                                <FormControl  className={ `${classes.formControl} ${ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].fieldId ? 'form-control-error' : '' }` } autoComplete="off">
                                    <InputLabel id="select-field-label">Selecione o campo</InputLabel>
                                    <Select
                                        disabled={props.loading}
                                        IconComponent={ () => <ExpandMore color="primary" classes={{ colorPrimary: classes.iconSelect }} /> }
                                        labelId="select-field-label"
                                        id="select-field"
                                        value={ fieldId }
                                        error={ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].fieldId ? props.error[props.conditionKey].fieldId : false }
                                        onChange={ e => {

                                            var new_options = [];
                                            var selected_field = fields.find(field => field.id == e.target.value);
                                            
                                            if(selected_field && selected_field.additional_data && selected_field.additional_data.construct && selected_field.additional_data.construct.options && selected_field.additional_data.construct.options.length > 0) {
                                                
                                                new_options = selected_field.additional_data.construct.options.map(option => {
                                                    return {
                                                        id: option.value,
                                                        value: option.text
                                                    }
                                                })
                                            } 

                                            setFieldId(e.target.value);
                                            update({id, fieldId: e.target.value, operator, compareFieldId, contain: [], compareOptions: new_options});                                            
                                            setPendingSave(true);
                                            setShowOperador(true);
                                        }}>
                                        { fields.map(function(item, index) {
                                            if (!fieldConditionReject.includes(item.value)) {
                                                return <MenuItem key={`menu-item-field-${item.id}-${props.key}`} value={item.id}>{item.value}</MenuItem>
                                            }
                                        }) }
                                    </Select>
                                    { props.error && props.error[props.conditionKey] && props.error[props.conditionKey].fieldId ? <FormHelperText className="text-error">{ props.error[props.conditionKey].fieldId }</FormHelperText> : '' }
                                </FormControl>
                                
                                { fragmentOperador() }

                                { operator === 'contem' || operator === 'nao_contem' ?
                                    <DInput disabled={props.loading} 
                                        type="text" 
                                        label="Valor do campo" 
                                        name="valor_campo" 
                                        error={ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].contain ? props.error[props.conditionKey].contain : false }
                                        helperText={ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].contain ? props.error[props.conditionKey].contain : false }
                                        onBlur={ e => update({id, fieldId, operator, compareFieldId, contain: [e.target.value]}) } onChange={ e => {
                                            setContain([e.target.value]);
                                            setPendingSave(true);
                                        } } value={ contain && Array.isArray(contain) ? contain.join(" ") : "" } fullWidth />
                                    :
                                    ''
                                }
                                
                                {  operator === 'igual' || operator === 'diferente' ? 
                                    <FormControl  className={ `${classes.formControl} ${ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].compareFieldId ? 'form-control-error' : '' }` } autoComplete="off">
                                        <InputLabel id="select-compare-field-label">Selecione uma opção de campo</InputLabel>
                                        <Select
                                            multiple
                                            inputProps={{size: sizeMultiple}}
                                            disabled={props.loading}
                                            IconComponent={ () => <ExpandMore color="primary" classes={{ colorPrimary: classes.iconSelect }} /> }
                                            labelId="select-compare-field-label"
                                            id="select-compare-field"
                                            value={ contain }
                                            // error={ props.error && props.error[props.conditionKey] && props.error[props.conditionKey].compareFieldId ? props.error[props.conditionKey].compareFieldId : false }
                                            onChange={ e => {
                                                // setCompareFieldId(e.target.value);
                                                update({id, fieldId, operator, contain: e.target.value, compareOptions});
                                                setPendingSave(true);
                                            }}>
                                            
                                            { compareOptions && Array.isArray(compareOptions) && compareOptions.map(item => {
                                                return <MenuItem key={`menu-item-compare-field-${item.id}-${props.key}`} value={item.id}>{item.value}</MenuItem>
                                            }) }
                                        </Select>
                                        {/* { props.error && props.error[props.conditionKey] && props.error[props.conditionKey].compareFieldId ? <FormHelperText>{ props.error[props.conditionKey].compareFieldId }</FormHelperText> : '' } */}
                                    </FormControl>
                                    :
                                    ''
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>                
            </Grid>
        )
    }

    function addCondition()
    {
        props.conditions.setFormConditionalsCondition([...props.conditions.formConditionalsCondition, {
            id: null,
            field_id: null,
            operator: null,
            compare_field_id: null,
            contain: []
        }])
    }

    function removeCondition(index)
    {
        let conditions = [...props.conditions.formConditionalsCondition];
        conditions.splice(index, 1)
        props.conditions.setFormConditionalsCondition(conditions)
        toast.info('Para confirmar a exclusão utilize o botão salvar condicional.');
    }

    function updateConditions(condition, key)
    {
        const old_conditions = [...props.conditions.formConditionalsCondition];

        old_conditions[key] = {
            id: condition.id,
            field_id: condition.fieldId,
            operator: condition.operator,
            compare_field_id: condition.compareFieldId,
            contain: condition.contain,
            compareOptions: condition.compareOptions,
            compateOptionsMultiple: condition.compateOptionsMultiple
        }

        props.conditions.setFormConditionalsCondition(old_conditions)
    }

    return (
        <>
            <Grid container>            
                <Grid item md={12}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography style={{ marginTop: 15, marginBottom: 15 }} className="text-primary" variant="h5" gutterBottom>Condição</Typography>
                        </Grid>
                        <Grid item>
                            <LightTooltip title={
                                <Typography variant="body1" className="text-regular text-st">Indique a condição que dispara a regra cadastrada</Typography>
                            } placement="right" arrow>
                                <ErrorIcon color="primary" classes={{ colorPrimary: classes.icon }} />
                            </LightTooltip>
                        </Grid>
                    </Grid>
                </Grid>
                { props.conditions.formConditionalsCondition.map(function(item, index){
                    return <ConditionForm loading={props.loading} 
                        error={props.error}
                        conditionKey={index}
                        onRemove={ e => removeCondition(index) }
                        onUpdate={ condition => updateConditions(condition, index) } 
                        key={`condition-form-key-${ index }`} 
                        condition={item} />
                }) }                
                <Grid item md={12}>
                    <DButton disabled={props.loading} style={{marginTop: 15, marginBottom: 15}} type="button" variant="ghost" size="sm"
                            icon={ <AddCircleIcon /> } onClick={ e => addCondition()}>Adicionar condição</DButton>
                </Grid>
            </Grid>            
        </>            
    )
}

export default AddFormCondition;