import { useContext, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormsGrid from "./FormsGrid";
import FormsCondition from "./FormsCondition";
import { CircularProgress, Backdrop } from "@material-ui/core";
import Construtor from "./../../../../api/construtor";
import { useTheme } from "../../../../context/ThemeContext";
import { useContionals, ConditionalsProvider } from "../../../../context/ConditionalsContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContext } from "../../context/theme-context";
import { useAuthRoute } from "./../../../../context/AuthRouteContext";
import FormsHeader from "./FormsHeader/FormsHeader";
import { TramOutlined } from "@material-ui/icons";
import Pusher from 'pusher-js';
import FormsConstrutor from "./FormsConstrutor/FormsConstrutor";
import construtor from "./../../../../api/construtor";
import env from "react-dotenv";

const useStyles = makeStyles((theme) => ({
    backDrop: {
        zIndex: 3000,
        color: '#FFF'
    }
}));

function FormsContent(props)
{
    const classes = useStyles();

    const [forms, setForms] = useState([]);
    const {theme, setTheme} = useTheme();
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [remove, setRemove] = useState(false);
    const [formId, setFormId] = useState(false);
    const [formConstrutor, setFormConstrutor] = useState(undefined);
    const [construtorOpen, setConstrutorOpen] = useState(false);
    const [loadPage, setLoadPage] = useState(false);

    const { auth, setAuth } = useAuthRoute();

    useEffect(function(){

        if(!theme.additionalData.action || theme.additionalData.action === 'grid') {
            setLoading(true);
            Construtor.getList(localStorage.getItem('token')).then(res => {
                if(res.status)
                    setForms(res.anamnese_forms.data)

                setLoadPage(true)

                setLoading(false);
            }).catch(err => {
                switch(err.response.status){
                    case 403:
                        setAuth({...auth, route: false})
                        break;
                }
            });
        }
        
    }, [props.construtorOpen])

    useEffect(() => {        

    }, [])

    function _handleAction(dados)
    {
        
        if(dados.action === 'edit')
        {
            setEdit(true);
            setFormId(dados.formId);
        } 

        else if(dados.action === 'remove')  {
            setRemove(true)
            setFormId(dados.formId)
        }

        else if(dados.action === 'construct') {
            
            toast.success('Abrindo construtor.');

            // setConstrutorOpen(true);

            Construtor.open(localStorage.getItem('token'), dados.formId).then(res => {
                if(res.status){
                    toast.success('Processo finalizado com sucesso.')                    
                    props.onOpenConstrutor(dados.formId)
                }else{
                    toast.error(res.message)
                    // setConstrutorOpen(false);
                    props.onCloseConstrutor(false)
                }
            }).catch(err => {
                console.log(err)
                toast.error('Erro inesperado.')
                // setConstrutorOpen(false);
                props.onCloseConstrutor(false)
            });

        } 

        else if(dados.action === 'approved') {

            Construtor.approvedForm(localStorage.getItem('token'), dados.formId).then(res => {
                if(res.status){
                    toast.success('Formulário aprovado com sucesso.')
                        setLoading(true);
                        Construtor.getList(localStorage.getItem('token')).then(res => {
                            if(res.status)
                                setForms(res.anamnese_forms.data)
            
                            setLoadPage(true)
            
                            setLoading(false);
                        });                   
                }else{
                    toast.error(res.message)
                }
            }).catch(err => {
                console.log(err)
                toast.error('Erro inesperado.')
            });

        } else {
            setTheme({sidebar: dados.action === 'grid', additionalData: {
                action: dados.action, 
                formId: dados.formId
            }})
        }
    }

    function BackDropConstrutor()
    {
        return (
            <Backdrop className={ classes.backDrop } open={ true }>
                <CircularProgress color="inherit" />&nbsp;Construtor de formulários em operação
            </Backdrop>
        )
    }

    return (
        <>
            { loading &&
                <Backdrop className={ classes.backDrop } open={ true }>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }

            { props.construtorOpen && <BackDropConstrutor /> }

            { (!theme.additionalData.action || theme.additionalData.action === 'grid') 
                && <>
                    <FormsHeader edit={ edit } onRemove={ remove } forms={setForms} listForms={forms} formId={ formId } onClose={e => {setFormId(null); setEdit(false)} } onUpdated={e => {setFormId(null); setEdit(false)}} onCloseMdDelete={e => setRemove(false)} />
                    <FormsGrid forms={ forms } onAction={ dados => _handleAction(dados) } />
                </> }

            { theme.additionalData && theme.additionalData.action === 'condition' 
                &&  <FormsCondition formId={ theme.additionalData.formId } onAction={ dados => _handleAction(dados) } /> }

            { props.construtor ? <FormsConstrutor onClose={ e => {
                props.onCloseConstrutor(true)
            } } url={ `${ env.API_CONSTRUTOR }?token=${ localStorage.getItem('token') }&form=${ props.construtor }` } /> : '' }
        </>
    )
}

export default FormsContent;