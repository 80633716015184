import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, RadioGroup, FormControlLabel, TextField, Radio, Checkbox, FormGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card_subtitle: {
        color: theme.palette.neutral.black,
        fontSize: 14,
        margin: "0 0 16px"
    },
    edit_input: {
        backgroundColor: theme.palette.background.snow,
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    },
    radio: {
        color: theme.palette.primary.light
    }
}));

function OptionComponent(props)
{

    const classes = useStyles();
    const [value, setValue] = useState(null);
    const [values, setValues] = useState(props.options.map(option => {
        return {
            id: option.id,
            value: option.value,
            checked: false
        }
    }));

    useEffect(() => {
        setValues(props.options.map(option => {
            return {
                id: option.id,
                value: option.value,
                checked: false
            }
        }));
    }, [props.options])

    return (<Grid item>
        { props.showName && <h6 className={ classes.card_subtitle }>{ props.name }</h6> }        
        { props.multiple ?
                <Grid container>
                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup row>
                            { values.map((option, index) => <FormControlLabel key={`option-checkbox-${index}`} value={ option.value } control={<Checkbox
                                    checked={ option.checked }
                                    onChange={ e => {
                                        let new_values = [...values]
                                        new_values[index].checked = !option.checked;
                                        setValues(new_values);
                                        props.onChange(new_values.filter(val => val.checked).map(val => val.value));
                                    } }
                                />} 
                                label={ option.value } /> ) }
                        </FormGroup>
                    </Grid>
                </Grid>
            :
            <Grid container alignItems='baseline'>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <RadioGroup aria-label="gender" name="gender" row={true} value={value} onChange={ e => {
                        setValue(e.target.value);
                        props.onChange([e.target.value]);
                    } }>
                        { props.options.map((option, index) => <FormControlLabel key={`option-radio-${index}`} value={ option.value } control={ <Radio color="primary" /> } label={ option.value } /> ) }
                    </RadioGroup>
                </Grid>
            </Grid>            
        }        
    </Grid>)
}

OptionComponent.defaultProps = {
    showName: false,
    name: null,
    multiple: false,
    options: [],
    onChange: function(value) {
        console.log(value)
    }
}

export default OptionComponent;