import React from "react";
import "./index.scss";
import { Button } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PrintIcon from "@mui/icons-material/Print";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ModalInterconsulta from "./Components/ModalInterconsulta";
import ModalPDF from "./Components/ModalPDF";
import { useNewHealthAttendance } from "../../../../context/NewHealthAttendance";
import { useEffect } from "react";
import { getPatientById } from "../../../../api/patient";
import ModalNovaSolicitacao from "./Components/ModalNovaSolicitacao";

function FloatingBar(props) {
  const [modalInterconsulta, setModalInterconsulta] = useState(false);
  const [modalPdf, setModalPdf] = useState(false);
  const [modalNovoAtendimento, setModalNovoAtendimento] = useState(false);

  const { startPrescription, healthAttendance, patient } = useNewHealthAttendance();

  const [groups, setGroups] = useState([]);
  const [lineCares, setLineCares] = useState([]);
  
  useEffect(
    function () {
      if (patient) {
        getPatientById(localStorage.getItem("token"), patient.id)
          .then((data) => {
            
            if (data.hasOwnProperty("status")) {
              if (data.status) {
                if (
                  data.details.patient &&
                  data.details.patient.patient_has_group &&
                  data.details.patient.patient_has_group.length > 0
                ) {
                  setGroups(
                    data.details.patient.patient_has_group
                      .filter((res) => res.group !== null)
                      .map(function (item) {
                        return {
                          id: item.group_id,
                          value: item.group.name,
                        };
                      })
                  );
                }
                if (
                  data.details.patient &&
                  data.details.patient.patient_line_care &&
                  data.details.patient.patient_line_care.length > 0
                ) {
                  setLineCares(
                    data.details.patient.patient_line_care
                      .filter(function (item) {
                        return (
                          item.line_care !== null && item.line_care.active === 1
                        );
                      })
                      .map(function (item) {
                        return {
                          id: item.line_care_id,
                          value: item.line_care.name,
                        };
                      })
                  );
                }
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
    [patient]
  );

  return (
    <div className="fundo_floating_bar">
      <div className="flex_1">
        <Tooltip arrow title="Nova Solicitação" placement="left-start">
          <Button
            className="botao_floating_bar text-primary"
            onClick={(e) => setModalNovoAtendimento(!modalNovoAtendimento)}
            startIcon={<AddCircleIcon className="icone_floating_bar" />}
          />
        </Tooltip>
      </div>
      <div className="flex_1">
        <Divider variant="fullWidth" className="separador_floating_bar " />
      </div>
      <div className="flex_1">
        <Tooltip arrow title="Solicitar Interconsulta" placement="left-start">
          <Button
            className="botao_editar_new_tab text-primary"
            onClick={(e) => setModalInterconsulta(!modalInterconsulta)}
            startIcon={<PersonAddIcon className="icone_floating_bar" />}
          />
        </Tooltip>
      </div>
      <div className="flex_1">
        <Divider variant="fullWidth" className="separador_floating_bar " />
      </div>
      <div className="flex_1">
        <Tooltip arrow title="Criar PDF" placement="left-start">
          <Button
            className="botao_editar_new_tab text-primary"
            onClick={(e) => setModalPdf(!modalPdf)}
            startIcon={<PrintIcon className="icone_floating_bar" />}
          />
        </Tooltip>
      </div>
      {modalInterconsulta ? (
        <ModalInterconsulta
          onReset={ e => {
            props.onReset(true);
            setModalNovoAtendimento(false);
          } }
          open={modalInterconsulta}
          onClose={(_) => setModalInterconsulta(!modalInterconsulta)}
        />
      ) : (
        ""
      )}
      {modalPdf ? (
        <ModalPDF open={modalPdf} setModal={(_) => setModalPdf(!modalPdf)} />
      ) : (
        ""
      )}
      {modalNovoAtendimento && <ModalNovaSolicitacao open={modalNovoAtendimento} onClose={e => setModalNovoAtendimento(false)} />}
    </div>
  );
}

FloatingBar.defaultProps = {
  onReset: function() {
    console.log('onReset')
  }
}

export default FloatingBar;
