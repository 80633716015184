import React, { Component } from "react";
import { Redirect } from "react-router";

import SolicitacoesList from "./components/SolicitacoesList/SolicitacoesList";
import { Header } from "./components/SolicitacoesList/components/Header";

import SolicitacoesCreate from "./components/SolicitacoesCreate/SolicitacoesCreate";
import { HeaderCreate } from "./components/SolicitacoesCreate/components/HeaderCreate";

import { Backdrop, CircularProgress } from "@material-ui/core";
import SolicitacoesPatient from "./components/SolicitacoesPatient/SolicitacoesPatient";
import { HeaderPatient } from "./components/SolicitacoesPatient/components/HeaderPatient";
import { getAllUnidades } from "../../api/unidades";
import { userDominios } from "../../api/dominios";
import { getPatientById } from "../../api/patient";
import { getMe } from "../../api/user";
import { PatientContext } from "../../context/usePatient";

import { AnamneseFormFilterProvider } from "./../../context/AnamneseFormFilterContext";

import { withRouter } from "react-router";

class Solicitacoes extends Component {

  static contextType = PatientContext;

  constructor(props) {
    super(props);

    this.state = {
        isList: true,
        isCadastro: false,
        isDetail: false,
        login: true,
        checkCalendar: false,
        unidadesRef: [],
        unidadesVinculadas: [],
        userDominios: {},
        historyAttendance: false, // True: em andamento, False: Histórico
        componentLoaded: false,
        loadingEvent: false,
        pid: true,
        reload: false
    }
  }

  async componentDidMount() {

      const {idPatient, setIdPatient} = this.context;

      this.handleRedirectProps(this.props);

      getMe(localStorage.getItem('token')).then(res => !res.id ? this.setState({...this.state, login: false}) : '')
      .catch(console.error);

      const promise1 = await userDominios().then(res => {
        this.setState({ ...this.state, userDominios: res });
      });

      const promise2 = await getAllUnidades(localStorage.getItem('token')).then(res => {
        var unidadesRef = [];
        var unidadesVinculadas = [];

        res.length && res.map((item) => {
            if (item.below_mother_medical_unit == 'no') {
                unidadesRef.push(item);
                this.setState({ ...this.state, unidadesRef: unidadesRef })
            } else {
                unidadesVinculadas.push(item);
                this.setState({ ...this.state, unidadesVinculadas: unidadesVinculadas })
            }
        });
    });

    Promise.all([promise1, promise2]).then(() => {
      this.setState({ ...this.state, componentLoaded: true });
    })
    .catch(err => {
      console.err(err);
      this.setState({ ...this.state, componentLoaded: true });
    })

    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('pid');
    if(pid && !idPatient) {
      setIdPatient(pid);
      this.setState({...this.state, isList: false, isCadastro: false, isDetail: true});
    } else {
      this.setState({...this.state, pid: false});
    }
  }

  handleRedirectProps(props){
    if(props.location && props.location.state){
      this.setState({...this.state, ...props.location.state});
    }
  }

  render() {

    return (
      <>
      { !this.state.login ? <Redirect to="/login" /> : '' }

      { this.state.reload ? <Redirect to="/solicitacoes" /> : '' }

      <AnamneseFormFilterProvider>
        {
          this.state.loadingEvent && (
            <Backdrop style={{zIndex: 3000, color: '#FFF'}} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )
        }

        { this.state.isList && !this.state.pid
          ?
          <>
          {
            !this.state.componentLoaded && (
              <Backdrop style={{zIndex: 3000, color: '#FFF'}} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )
          }
            <Header 
              cadastrar={ e => this.setState({...this.state, isList: false, isCadastro: true, isDetail: false}) } 
              openCalendar={ value => {this.setState({...this.state, checkCalendar: value})} }
              checkedCalendar={this.state.checkCalendar}
              historyAttendance={this.state.historyAttendance}
              setHistoryAttendance={x => this.setState({...this.state, historyAttendance: x})}
            />
            <SolicitacoesList checkedCalendar={this.state.checkCalendar} 
              historyAttendance={this.state.historyAttendance}
              setHistoryAttendance={x => this.setState({...this.state, historyAttendance: x})}
              unidadesRef={this.state.unidadesRef}
              unidadesVinculadas={this.state.unidadesVinculadas}
              userDominios={this.state.userDominios}
            />
          </>
          :
            ''
        }

        { this.state.isCadastro
          ?
          <>
            <HeaderCreate listar={ e => {
              this.setState({...this.state, isList: true, isCadastro: false, isDetail: false, pid: false})
            } } />
            <SolicitacoesCreate verdetalhe={ e => this.setState({...this.state, isList: false, isCadastro: false, isDetail: true}) } />
          </>
          :
            ''
        } 

        { this.state.isDetail
          ?
          <>
            <HeaderPatient cadastrar={ e => this.setState({...this.state, isList: false, isCadastro: true, isDetail: false}) } />
            <SolicitacoesPatient onHealthAttendance={ e => {this.setState({...this.state, isDetail: false, isList: true, isCadastro: false, checkCalendar: false, historyAttendance: false, pid: false})} } />
          </>
          :
            ''
        }
      </AnamneseFormFilterProvider>

      </>
    )
  }
}

export default withRouter(Solicitacoes);