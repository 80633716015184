import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormBuilderContext } from "../../../../../../../context/FormBuilderContext";
import DInput from "../../../../../../shared/DInput";

const EBSubtitle = ({ id, label, placeholder, value, helper_text, condicional, additional_data }) => {
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: "1em" }}
        >
          <div dangerouslySetInnerHTML={{ __html: `${additional_data.construct.showTitle ? label : ""}` }} />
        </Typography>
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ""}
        </span>
      </div>
    </section>
  );
};

export default EBSubtitle;
