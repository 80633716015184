import env from 'react-dotenv';

function getAllTrails(token, page, dados) {
    return fetch(`${env.API_URL}/trail?page=${page}&token=${token}`, {
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function getAllActions(token) {
    return fetch(`${env.API_URL}/trail/actions?token=${token}`, {
        method: 'get'
    }).then(res => res.json());
}

function getAllProfiles(token) {
    return fetch(`${env.API_URL}/trail/profiles?token=${token}`, {
        method: 'get'
    }).then(res => res.json());
}

function getAllUnidades(token) {
    return fetch(`${env.API_URL}/trail/unidades?token=${token}`, {
        method: 'get'
    }).then(res => res.json());
}

function getAllUsers(token) {
    return fetch(`${env.API_URL}/trail/users?token=${token}`, {
        method: 'get'
    }).then(res => res.json());
}

export { getAllTrails, getAllActions, getAllProfiles, getAllUsers, getAllUnidades }