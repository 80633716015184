import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Grid,
  Backdrop,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddBoxIcon from "@material-ui/icons/AddBox";

import DButton from "../../../shared/DButton";
import { getAllUnidades } from "../../../../api/unidades";
import {GetOutModal} from "../PacienteCreate/components/Modais";
import PacienteCreate from "../PacienteCreate/NewPacienteCreate";
import { Container, PacienteCreateContainer } from "./styles";
import { PatientContext, usePatient } from "../../../../context/PatientContext";

const useStyles = makeStyles((theme) => ({
  paciente__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.neutral.white,
    fontWeight: 600,
  },
  paciente__topo: {
    position: "absolute",
    left: 0,
    top: 98,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: theme.palette.background.snow,
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
    zIndex: 5,
  },
}));

export default function CreatePaciente(props) {
  const classes = useStyles();

  const [getOutModal, setGetOutModal] = useState(false);
  const [reset, setReset] = useState(true);

  const [unidadesVinculadas, setUnidadesVinculadas] = useState(null);
  const [unidadesRef, setUnidadesRef] = useState(null);
  const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);

  useEffect(() => {
    getAllUnidades(localStorage.getItem("token")).then((res) => {
      var unidadesRef = [];
      var unidadesVinculadas = [];

      res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == "no") {
            unidadesRef.push(item);
          } else {
            unidadesVinculadas.push(item);
          }
        });

      setUnidadesVinculadas(unidadesVinculadas);
      setUnidadesRef(unidadesRef);
      setSideBarInternal(false)
    });
  }, []);

  useEffect(() => {
    if (!reset) setReset(true);
  }, [reset]);

  return (
    <>
      <Container>
        <Grid item className={classes.paciente__topo}>
          <button
            className={classes.paciente__nome}
            onClick={(e) => setGetOutModal(!getOutModal)}
            style={{
              fontSize: "18px",
              lineHeight: "27px",
              border: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <ArrowBackIosIcon color="primary" fontSize="large" />
            Cadastrar paciente
          </button>
        </Grid>

        <GetOutModal
          open={getOutModal}
          onClose={(x) => setGetOutModal(!getOutModal)}
          onGetOut={(_) => props.listar()}
        />

        <PacienteCreateContainer>
          {reset && unidadesRef && unidadesVinculadas ? (
            <PacienteCreate
              unidadesRef={unidadesRef}
              unidadesVinculadas={unidadesVinculadas}
              onGetOut={(_) => props.listar()}
              reset={(_) => setReset(!reset)}
            />
          ) : (
            <Backdrop
              style={{ zIndex: 3000, color: "white", textAlign: "center" }}
              open={!(unidadesRef && unidadesVinculadas)}
            >
              <Grid container>
                <Grid item md={12}>
                  <CircularProgress color="inherit" />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="body1" className="text-regular text-st">
                    Carregando cadastro de paciente...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
          )}
        </PacienteCreateContainer>
      </Container>
    </>
  );
}
