import { useState, useContext, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Clear,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
  Redo,
  Search,
  TextFields,
  Undo,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import DButton from "../../../../shared/DButton";
import DDropzone from "../../../../shared/DDropzone";
import { getPrescriptions } from "../../../../../api/user";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AtendimentoContext } from "../../../../../context/AtendimentoContext";
import {
  listHealthAttendanceById,
  listHealthAttendanceFiles,
  uploadHealthAttendanceFile,
} from "../../../../../api/healthAttendance";
import formJSON from "./formElement.json";
import Elements from "./ElementsBuilder/Elements";
import { FormBuilderContext } from "../../../../../context/FormBuilderContext";
import { useHealthAttendance } from "../../../../../context/HealthAttendanceContext";
import { useNewHealthAttendance } from "./../../../../../context/NewHealthAttendance";
import "./../styles.scss";
import construtor from "../../../../../api/construtor";
import {
  anamneseFormAnswer,
  anamneseFormDischarge,
  getAnamneseFormAnswer,
  getFormConditionals,
  getFormAnswer
} from "../../../../../api/anamnese";
import useError from "../../../../../hooks/useError";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getValidators, getFieldsToAnswerToConditional } from "./helper.functions";
import { useStyles } from "./theme";
import Version from "./Version"

function Anamnese(props) {

	const { healthAttendance, setHealhAttendance, finish, setFinish, finished, setFinished } = useNewHealthAttendance();

	const [file, setFile] = useState(null);
	const [textArea, setTextArea] = useState(``);
	const [healthAttendanceFile, setHealthAttendanceFiles] = useState(null);
	const { acoes, setAcoes } = useHealthAttendance();

	const classes = useStyles();

	useEffect(() => {
		if(finish && !finished)
			handleSubmit();
	}, [finish]);

	const {
		atendimentoInfo,
		setDadosAnamnese,
		dadosAnamnese,
		startPrescription,
		patientPrescriptions,
		setPatientPrescriptions,
		emAtendimento,
		setEmAtendimento,
		setAtendimentoStatus,
		readOnlyAnamneseForm,
	} = useContext(AtendimentoContext);

	const [elements, setElements] = useState(null);
	const [elementsForValidate, setElementsForValidate] = useState(null);
	const [loading, setLoading] = useState(null);

	const { fields, page_label } = elements ?? {};
	const [data, setData] = useState([]);
	// const [fields, setFields] = useState([]);

	const [error, validarFormulario, setErrors] = useError();
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [sendFirstReply, setSendFirstReply] = useState(false);
	const [loadingFinishDischarge, setLoadingFinishDischarge] = useState(false);
	const [idFormAnwser, setIdFormAnwser] = useState(0);
	const [errorField, setErrorField] = useState([]);
	const [answerToConditional, setAnswerToConditional] = useState([]);
	const [formId, setFormId] = useState(); //Anamnse Form ID
	const [formularioId, setFormularioId] = useState(); //Form ID
	const [nameForm, setNameForm] = useState();
	const [lineOfCare, setLineOfCare] = useState();

	const [formAnswers, setFormAnswers] = useState();
	const [flagHandleConditionals, setFlagHandleConditionals] = useState(false);
	const [answerLoaded, setAnswerLoaded] = useState(false);
	const [version, setVersion] = useState(null);
	const [formAnswerId, setFormAnswerId] = useState(null);
	const [cid10, setCid10] = useState([]);
	const [triggerFilterCid10, setTriggerFilterCid10] = useState(null)

	const history = useHistory();

	const filterCid10 = (fieldAnswer) => {
		
		var _cid10 = fieldAnswer.filter(field_answer => field_answer.field_trashed && field_answer.field_trashed.label === "Hipótese Diagnóstica" && Array.isArray(field_answer.answer_data) && field_answer.answer_data.length > 0)
						.map(field_answer => field_answer.answer_data.filter(answer_data => answer_data.code).map(answer_data => {
							
							var _code = answer_data.code;

							return `${_code.substr(0,_code.length -1)}.${_code.substr(_code.length -1)}`;
						}));
		
		props.onCid10(_cid10[0]);
		setCid10(_cid10[0]);
	}

	useEffect(() => {
		if (file) {
			uploadHealthAttendanceFile(localStorage.getItem("token"),file,atendimentoInfo.id).then((response) => {
				props.toast.success("Arquivo enviado com sucesso!");
			}).catch((error) => {
				console.log(error);
			});
		}
	}, [file]);

	useEffect(() => {
		if (atendimentoInfo && atendimentoInfo.id) {
			getPrescriptions(localStorage.getItem("token"), atendimentoInfo.id).then((data) => {
				if (data.error) {
					throw data.error;
				}
				setPatientPrescriptions(data.details);
			})
			.catch((err) => console.error(err));
		}
	}, [atendimentoInfo]);

	useEffect(() => {
		if(formAnswerId && formAnswerId > 0) {
			getFormAnswer(localStorage.getItem('token'), formAnswerId).then(res => {
				if(res.status) {
					filterCid10(res.details);
				}
			})
		}
	},[formAnswerId, triggerFilterCid10])

	useEffect(() => {
		if (props.idAtendimento && emAtendimento)
			listHealthAttendanceById(localStorage.getItem("token"),props.idAtendimento).then((res) => {
				if (res.status) {
					if (res.healthAttendance.health_attendance_anamnese_form.length > 0) {

						var health_attendance_anamnese_form = res.healthAttendance.health_attendance_anamnese_form[res.healthAttendance.health_attendance_anamnese_form.length - 1];
						setFormularioId(health_attendance_anamnese_form.anamnese_form.form_id)
						setFormId(health_attendance_anamnese_form.anamnese_form_id);
						setNameForm(health_attendance_anamnese_form.anamnese_form.name);
						
						if (res.healthAttendance.line_care != null) {
							setLineOfCare(res.healthAttendance.line_care.name);
						}

						if (health_attendance_anamnese_form.closed_at != null) {
							setIsReadOnly(true);
						}
					}

					if (res.healthAttendance.anamnese_form_answer.length > 0 && res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1] !== null) {
						setFormAnswers(res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1].data);
						setFormAnswerId(res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1].form_answer_id);
						setData(res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1].data);
						setTriggerFilterCid10(new Date());
						setIdFormAnwser(res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1].id);
						var _version = res.healthAttendance.anamnese_form_answer[res.healthAttendance.anamnese_form_answer.length - 1].version;
						if(_version)
							setVersion(_version);
						else
							construtor.show(localStorage.getItem("token"), health_attendance_anamnese_form.anamnese_form_id).then((res) => {
								if (res.status && res.form) {
									setVersion(res.form.version);
								}
							});						
					} else {
						setIdFormAnwser(null);

						construtor.show(localStorage.getItem("token"), health_attendance_anamnese_form.anamnese_form_id).then((res) => {
							if (res.status && res.form && res.form.status !== "rascunho") {
								setVersion(res.form.version);
								setData(res.form.fields.map(field => {
									return {
										id: field.reference_id.toString(),
										value: ""
									};
								}))
							}
						});						
					}

				} 

				
				setAnswerLoaded(true);
			})
			.catch((err) => console.error(err));
			
	}, [emAtendimento]);

	useEffect(() => {

		setLoading(true);

		
		if (formId) {

			if(healthAttendance.health_attendance_status_id !== 3) {	

				construtor.showByVersion(localStorage.getItem("token"), null, formId).then((res) => {
				
					if (res.status && res.form && res.form.status !== "rascunho") {
						setElements({ fields: res.form.fields.map(field => {
							field.id = field.reference_id;
							return field;
						}) });				
						setElementsForValidate(res.form.fields.map((item) => {
							return {
								id: item.reference_id.toString(),
								readOnly: item.is_read_only === 1 ? true : false,
								required: item.is_required === 1 ? true : false,
							};
						}));
					}
	
					if (res.status && res.form.status === "rascunho")
						toast.warn('Formulário indisponível. Solicite a ativação do formulário ao suporte!');
	
					setLoading(false);
				});
	
			} else if(version) {			
				// se o formulário já estiver finalizado, utilizamos a versão que foi salva na ultima resposta
				construtor.showByVersion(localStorage.getItem("token"), version, formId).then((res) => {
				
					if (res.status && res.form && res.form.status !== "rascunho") {
						setElements({ fields: res.form.fields.map(field => {
							field.id = field.reference_id;
							return field;
						}) });				
						setElementsForValidate(res.form.fields.map((item) => {
							return {
								id: item.reference_id.toString(),
								readOnly: item.is_read_only === 1 ? true : false,
								required: item.is_required === 1 ? true : false,
							};
						}));
					}
	
					if (res.status && res.form.status === "rascunho")
						toast.warn('Formulário indisponível. Solicite a ativação do formulário ao suporte!');
	
					setLoading(false);
				});	
			}
		}
		

	}, [formId, version]);
  
	/**
	 * Condiciona a primeira abertura do formulario a respeitar as condicionais, antes mesmo do usuario inserir o primeiro input.
	 */   
	useEffect(() => {
		if(answerLoaded && formId !== undefined && data.length > 0 && idFormAnwser !== 0 && healthAttendance.health_attendance_status_id === 2) {
			anamneseFormAnswer(localStorage.getItem("token"), idFormAnwser, {
				health_attendance_id: props.idAtendimento,
				anamnese_form_id: formId,
				data: data			
			}).then((res) => {
				if (res.status) {
					setAnswerToConditional(res.fields);					
					setFormAnswerId(res.details ? res.details.form_answer_id : null);
				}
				setAnswerLoaded(false);
			});
		} 

	}, [formId, data, answerLoaded, idFormAnwser])

	useEffect(() => {
		setData(getFieldsToAnswerToConditional(fields));
		setEmAtendimento(true);
	}, []);

	useEffect(() => {
		if (formId && data.length > 0 && (healthAttendance && healthAttendance.health_attendance_status_id === 2)) {
			try {
				if (data && idFormAnwser == null && !sendFirstReply) {
					setLoadingFinishDischarge(true);

					anamneseFormAnswer(localStorage.getItem("token"), null, {
						health_attendance_id: props.idAtendimento,
						anamnese_form_id: formId,
						data: data,
					}).then((res) => {
						if (res && res.status) {
							setSendFirstReply(true);
							setAnswerToConditional(res.fields);
							setIdFormAnwser(res.details.id ? res.details.id : null);
							setFormAnswerId(res.details ? res.details.form_answer_id : null);
							setTriggerFilterCid10(new Date());
						}
						setLoadingFinishDischarge(false);
					});
				} else if (idFormAnwser != null && idFormAnwser != 0) {

					setLoadingFinishDischarge(true);
					anamneseFormAnswer(localStorage.getItem("token"), idFormAnwser, {
						health_attendance_id: props.idAtendimento,
						anamnese_form_id: formId,
						data: data,
					}).then((res) => {
						if (res.status) {
							setAnswerToConditional(res.fields);
							setFormAnswerId(res.details ? res.details.form_answer_id : null);
							setTriggerFilterCid10(new Date());
						}
						setLoadingFinishDischarge(false);
					});
				}
			} catch (error) {
				console.log(error);
				toast.error("Erro ao realizar ação.");
			}
		} 
	}, [formId, data]);

	const handleSubmit = () => {

		setErrorField([]);

		if (!getValidators(setErrorField, data, elementsForValidate)) {
			try {
				if (data) {
					toast.success("Enviando respostas.");

					anamneseFormDischarge(localStorage.getItem("token"), idFormAnwser, {
						health_attendance_id: props.idAtendimento,
						anamnese_form_id: formId,
					}).then((res) => {
						if (res.status) {
							setIsReadOnly(true);
							setEmAtendimento(false);
							setAtendimentoStatus(0);
							setSendFirstReply(false);
							setFinished(true);
							props.returnListHealthAttendance(true);
							toast.success("Processo realizado com sucesso.");
						}
					});					
				}
			} catch (error) {
				toast.error("Erro ao realizar ação.");
			}
		} else {
			setFinish(false);
		}
	};

  const handleInputSubmit = (answer) => {
    const result = {
      id: Object.keys(answer)[0],
      value: answer[Object.keys(answer)[0]],
    };

    const old_data = [...data];

    let new_field = true;

    old_data.map(function (item, index) {
      if (item.id === result.id) {
        old_data[index] = result;
        new_field = false;
      }
    });
    if (new_field) {
      old_data.push(result);
    }

    setData(old_data);
  };

	return (
		<>
			<Version version={version} />
			<Typography variant="body1" className="text-bold text-primary" style={{ fontSize: "1em", marginTop: 0, marginBottom: 13 }}>
				{lineOfCare ? `${lineOfCare} - ` : ""} {nameForm ? nameForm : ""}
			</Typography>
			<Box className={classes.box}>
				<aside>
					<div className="container-boxe">
						<FormBuilderContext.Provider value={{ handleInputSubmit }}>
							{loading && 
								<Backdrop className={classes.backDrop} open={true}>
									<CircularProgress color="inherit" />&nbsp;Carregando informações e o formulário de atendimento
								</Backdrop>
							}
							<div className="container-boxe">
								<div className="builder-form mb-3">
									<form autoComplete="off">
										{fields && fields.map((field, i) => 
											<Elements
												key={i}
												field={field}
												errorField={errorField}
												conditional={answerToConditional}
												readOnly={isReadOnly}
												formAnswers={formAnswers}
											/>
										)}
									</form>
								</div>
							</div>
						</FormBuilderContext.Provider>
					</div>
				</aside>
			</Box>
		</>
	);
}

Anamnese.defaultProps = {
    toast: null,
    idAtendimento: null,
	onCid10: function(values) {
		console.log(values, 'onCid10');
	},
    returnListHealthAttendance: function() {
      console.log('returnListHealthAttendance');
    }
}

export default Anamnese;