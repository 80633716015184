import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons"
import DSelectMutiple from "./../../../../../shared/DSelectMutiple";

const useStyles = makeStyles((theme) => ({
    card_subtitle: {
        color: theme.palette.neutral.black,
        fontSize: 14,
        margin: "0 0 16px"
    },
    content: {
        height: 76
    }
}));

function ListComponent(props)
{

    const classes = useStyles();

    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue([])
    }, [props.multiple]);

    return (<Grid item>                        
        <Grid container classes={{root: classes.content}}>
            { props.showName && <Grid item md={12}><h6 className={ classes.card_subtitle }>{ props.name }</h6></Grid> }
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                { props.multiple ?
                    <DSelectMutiple 
                        label={ props.name }
                        style={{ minWidth: 230 }}
                        onChange={ e => {
                            setValue(e);
                            props.onChange(e);
                        } }                                
                        value={ value }
                        options={ props.options } />
                    :
                    <Select
                        labelId="select-type-label"
                        id="select-type"
                        style={{ minWidth: 230 }}
                        value={ value }
                        multiple={ props.multiple }
                        IconComponent={() => <ExpandMore style={{color: '#2E3192'}} /> }
                        onChange={ e => {
                            let new_value = props.multiple ? e.target.value : [e.target.value];
                            setValue(new_value);
                            props.onChange(new_value);
                        } }>
                        { props.options.map((option, index) => <MenuItem key={ `list-component-${index}` } value={ option.value }>{ option.value }</MenuItem> ) }
                    </Select>                
                }
            </Grid>
        </Grid>         
    </Grid>)
}

ListComponent.defaultProps = {
    showName: false,
    name: null,
    multiple: false,
    options: [],
    onChange: function(value) {
        console.log(value)
    }
}

export default ListComponent;