// Esse componente está sendo utilizado por outra tela ./solicitacoes
import {
  Card,
  CardContent,
  Checkbox,
  Radio,
  FormControlLabel,
  Grid,
  Typography,
  DialogActions,
  DialogContent,
  withStyles,
  Button,
  Divider,
} from "@material-ui/core";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, useContext, useMemo } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import CheckboxUnchecked from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckboxChecked from "@material-ui/icons/CheckBox";

import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { CheckCircleOutline, Close, LocalHospital } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import useError from "../../../../hooks/useError";
import racasCode from "./../../../../assets/dados/racas.json";
import pronomeCode from "./../../../../assets/dados/pronomes.json";
import orientacoesSexuaisCode from "./../../../../assets/dados/orientacoesSexuais.json";
import generoCode from "./../../../../assets/dados/genero.json";
import deficienciasCode from "./../../../../assets/dados/deficiencia.json";
import populacaoEspecCode from "./../../../../assets/dados/populacaoEspec.json";
import orgaoEmissorCode from "./../../../../assets/dados/orgaoEmissor.json";
import tiposTelefoneCode from "./../../../../assets/dados/tiposTelefone.json";
import justificativaDadosIncompletosCode from "./../../../../assets/dados/justificativasDadosIncompletos.json";
import sexoCode from "./../../../../assets/dados/sexo.json";
import logradourosCode from "./../../../../assets/dados/logradouro.json";
import CircularProgress from "@material-ui/core/CircularProgress";
import DAutoComplete from "../../../shared/DAutoComplete";
import DMultAutoComplete from "../../../shared/DMultAutoComplete";
import {
  mascaraCPF,
  mascaraRG,
  mascaraCEP,
  mascaraPIS,
  mascaraNumero,
  mascaraRNE,
} from "../../../../helpers/formatacao";
import {
  required,
  requiredTwoName,
  requiredOptionalTwoName,
  validaCPF,
  validaEmail,
  validaRG,
  validaLoteCNS,
  validaPIS,
  validaCEP,
  isValidDate,
  validaRNE
} from "../../../../helpers/validacoes";
import { getCep, getCep2 } from "../../../../helpers/apis";
import { createPatient, listPronouns } from "../../../../api/patient";
import { getRelationShipDegree, userDominios } from "../../../../api/dominios";
import DButton from "../../../shared/DButton";
import DInput from "../../../shared/DInput";
import DDateInput from "../../../shared/DDateInput";
import DDropDownSimple from "../../../shared/DDropDownSimple";
import StepLine from "./components/Step";
import Tooltip from "@material-ui/core/Tooltip";
import { Container, Draggable } from "react-smooth-dnd";
// import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  getStates,
  getCitys,
  getEtnias,
  getCountrys,
} from "../../../../api/dominios";
import { getUnidade } from "../../../../api/unidades";
import { AppContext } from "../../../../context/AppContext";
import { UnidadeCard } from "./styles";
import { SuccessfulRegistratonPatient } from "./components/Modais";


const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: `1px solid ${theme.palette.neutral.midlightgray}`,
  },
  root: {
    // height: 450,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral.midlightgray}`,
    paddingTop: 16,
    paddingBottom: 16,
  },
  marginDefault: {
    marginTop: 0,
    marginBottom: 25,
  },
  addCnsButton: {
    marginTop: 0,
    marginBottom: 25,    
    // backgroundColor: "transparent!important",
    borderColor: "transparent!important",
  },
  linha: {
    border: `1px solid ${theme.palette.primary.light}`,
    marginTop: 0,
    marginBottom: 32,
  },
  icon: {
    color: theme.palette.neutral.midgrey,
  },
  hide: {
    display: "none",
  },
  buttonModal: {
    minWidth: "unset !important",
    height: "33px !important",
    padding: "6px 18px !important",
    width: "225px",
    height: "50px!important",
  },
  labelBlack: {
    color: theme.palette.neutral.black,
  },
  identidadeRadioMargin: {
    marginTop: 20,
  },
  primaryColor:{
    color: theme.palette.primary.light
  }
}));

function validaTel(tel) {
  var errors = [];

  if (Array.isArray(tel)) {
    tel.forEach((item, index) => {
      var telObj = { index: null, phone: null, name: null };

      if (!item.phone || item.phone.length <= 0) {
        telObj.phone = "Campo obrigatório";
      } else if (
        item.phone.length < 14 ||
        
        item.phone == "(00) 00000-0000" ||
        item.phone == "(11) 11111-1111" ||
        item.phone == "(22) 22222-2222" ||
        item.phone == "(33) 33333-3333" ||
        item.phone == "(44) 44444-4444" ||
        item.phone == "(55) 55555-5555" ||
        item.phone == "(66) 66666-6666" ||
        item.phone == "(77) 77777-7777" ||
        item.phone == "(88) 88888-8888" ||
        item.phone == "(99) 99999-9999"
      ) {

        telObj.phone = "Telefone inválido";
      }

      if (!item.name || item.name.length <= 0) {
        telObj.name = "Campo obrigatório";
      }

      if (!item.type || item.type.length <= 0) {
        telObj.type = "Campo obrigatório";
      }

      telObj.index = index;
      errors.push(telObj);
    });

    if (errors.length) {
      if (errors.filter((x) => x.phone || x.name || x.type).length > 0) {
        return errors;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } else {
    return null;
  }
}

function PacienteCreate(props) {
  const classes = useStyles();
  const { userInfo } = useContext(AppContext);

  const [activeMedicalUnit, setActvieMedicalUnit] = useState(null);

  // Dados pessoais
  const [cpf, setCPF] = useState("");
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [nomeSocial, setNomeSocial] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [dataNascimentoValida, setDataNascimentoValida] = useState(true);
  const [idade, setIdade] = useState(0);
  const [nacionalidade, setNacionalidade] = useState("");
  const [seIdentificaSexo, setSeIdentificaSexo] = useState(null);
  const [genero, setGenero] = useState("");
  const [orientacaoSexual, setOrientacaoSexual] = useState(null);
  const [sexo, setSexo] = useState("");
  const [pronome, setPronome] = useState(null);

  // Identidade
  const [identidadeNumero, setIdentidadeNumero] = useState("");
  const [identidadeEmissao, setIdentidadeEmissao] = useState("");
  const [identidadeEmissaoValida, setIdentidadeEmissaoValida] = useState(true);
  const [identidadeOrgaoEmissor, setIdentidadeOrgaoEmissor] = useState("");
  const [identidadeUF, setIdentidadeUF] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [maeDesconhecida, setMaeDesconhecida] = useState(false);

  // Identidade - Naturalizado
  const [identidadeNaturalNumero, setIdentidadeNaturalNumero] = useState(null);
  const [identidadeNaturalEmissao, setIdentidadeNaturalEmissao] =
    useState(null);
  const [identidadeNaturalOrgaoEmissor, setIdentidadeNaturalOrgaoEmissor] =
    useState(null);
  const [identidadeNaturalUF, setIdentidadeNaturalUF] = useState(null);

  const [dataNaturalizacao, setDataNaturalizacao] = useState("");
  const [dataNaturalizacaoValida, setDataNaturalizacaoValida] = useState(true);
  const [paisNascimento, setPaisNascimento] = useState("");

  // Identidade - Estrangeiro
  const [passaporteNumero, setPassaporteNumero] = useState("");
  const [passaportePaisEmissor, setPassaportePaisEmissor] = useState("");
  const [passaporteDataEmissao, setPassaporteDataEmissao] = useState("");
  const [passaporteDataEmissaoValida, setPassaporteDataEmissaoValida] =
    useState(true);
  const [passaporteDataValidade, setPassaporteDataValidade] = useState("");
  const [passaporteDataValidadeValida, setPassaporteDataValidadeValida] =
    useState(true);
  const [passaportePaisNascimento, setPassaportePaisNascimento] = useState("");

  // Carteira Nacional de saúde (CNS)
  const [cns, setCns] = useState([""]);
  const [cnsPrincipal, setCnsPrincipal] = useState(0);
  const [qtdCns, setQtdCns] = useState([]);

  // Dados demográficos
  const [municipiosByEstadoNascimento, setMunicipiosByEstadoNascimento] =
    useState(null); // Municípios de acordo com estado selecionado.
  const [municipioNascimento, setMunicipioNascimento] = useState(null); // Município selecionado
  const [estadoNascimento, setEstadoNascimento] = useState(null); // Estado selecionado
  const [raca, setRaca] = useState(null);
  const [etnia, setEtnia] = useState(null);

  // Gravidez
  const [qtdVezesGravidez, setQtdVezesGravidez] = useState("");
  const [filhosVivos, setFilhosVivos] = useState("");
  const [dtUltimaMenstruacao, setDtUltimaMenstruacao] = useState("");
  const [dtUltimaMenstruacaoValida, setDtUltimaMenstruacaoValida] =
    useState(true);
  const [teveAborto, setTeveAborto] = useState(false);
  const [qtdVezesAborto, setQtdVezesAborto] = useState("");
  const [dtUltimoAborto, setDtUltimoAborto] = useState("");
  const [dtUltimoAbortoValida, setDtUltimoAbortoValida] = useState(true);

  //Dados incompletos
  const [justificativaDadoIncompleto, setJustificativaDadoIncompleto] =
    useState("");
  const [anoEstimadoDeNascimento, setAnoEstimadoDeNascimento] = useState("");

  // Dados complementares
  const [responsavelLegal, setResponsavelLegal] = useState(null);
  const [responsavelLegalDocumento, setResponsavelLegalDocumento] =
    useState(null);

  const [
    responsavelLegalDocumentoEmissao,
    setResponsavelLegalDocumentoEmissao,
  ] = useState(null);
  const [responsavelEmissaoValida, setResponsavelEmissaoValida] =
    useState(true);
  const [grauDeRelacionamento, setGrauDeRelacionamento] = useState(null);
  const [numeroPis, setNumeroPis] = useState("");
  const [seriePis, setSeriePis] = useState("");
  const [ufPis, setUfPis] = useState("");
  const [temDeficiencia, setTemDeficiencia] = useState(false);
  const [deficiencia, setDeficiencia] = useState([]);
  const [populacaoEspec, setPopulacaoEspec] = useState("");
  const [nomePai, setNomePai] = useState("");
  const [paiDesconhecido, setPaiDesconhecido] = useState(false);
  const [temDeficienciaIntelectual, setTemDeficienciaIntelectual] =
    useState(false);

  // Telefones
  const [telefones, setTelefones] = useState([
    { id: 1, phone: "", type: "", name: "" },
  ]);
  const [qtdTelefones, setQtdTelefones] = useState([1]);

  // Unidades
  const [unidadeRef, setUnidadeRef] = useState("");
  const [unidadeVinculada, setUnidadeVinculada] = useState([]);

  // Endereço
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [logradouro, setLogradouro] = useState(null);
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [municipiosByEstadoEndereco, setMunicipiosByEstadoEndereco] =
    useState(null);
  const [idMunicipio, setIdMunicipio] = useState("");

  // Passos do formulário
  const [step, setStep] = useState(0);
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);

  // switches
  const [loading, setLoading] = useState(false);
  const [expandMore, setExpandMore] = useState(false);
  const [dadosIncompletos, setDadosIncompletos] = useState(false);
  const [cadastrado, setCadastrado] = useState(false);

  const [states, setStates] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [citys, setCitys] = useState([]);
  const [etnias, setEtnias] = useState([]);
  const [componentDepsLoaded, setComponentDepsLoaded] = useState(false);
  const [relationshipDegrees, setRelationshipDegrees] = useState(null);

  // static bd data

  const [pronomeMap, setPronomeMap] = useState([]);

  useEffect(() => {
    getRelationShipDegree(localStorage.getItem("token"))
      .then((data) => {
        if (data.status) {
          if (Array.isArray(data.relationship_degrees)) {
            setRelationshipDegrees(
              data.relationship_degrees.map((x) => {
                return {
                  id: x.id,
                  value: x.description,
                };
              })
            );
          }
        }
      })
      .catch(console.log);

    console.time("userDominios");
    userDominios()
      .then((res) => {
        console.timeEnd("userDominios");
        if (res.status) {
          setStates(
            res.state.map((item) => {
              return { id: item.id, value: item.code };
            })
          );

          setCitys(
            res.city.map((item) => {
              return { id: item.id, value: item.description, ...item };
            })
          );

          setMunicipiosByEstadoEndereco(
            res.city.map((item) => {
              return { id: item.id, value: item.description, ...item };
            })
          );

          setEtnias(
            res.etnia.map((item) => {
              return { id: item.id, value: item.description };
            })
          );

          setCountrys(
            res.country.map((item) => {
              return { id: item.id, value: item.description };
            })
          );
        }

        setComponentDepsLoaded(true);
      })
      .catch((err) => {
        setComponentDepsLoaded(true);

        userDominios()
          .then((_retryRes) => {
            if (_retryRes.status) {
              setStates(
                _retryRes.state.map((item) => {
                  return { id: item.id, value: item.code };
                })
              );

              setCitys(
                _retryRes.city.map((item) => {
                  return { id: item.id, value: item.description, ...item };
                })
              );

              setEtnias(
                _retryRes.etnia.map((item) => {
                  return { id: item.id, value: item.description };
                })
              );

              setCountrys(
                _retryRes.country.map((item) => {
                  return { id: item.id, value: item.description };
                })
              );
            }

            setComponentDepsLoaded(true);
          })
          .catch((err) => {
            setComponentDepsLoaded(true);
          });

        setComponentDepsLoaded(true);
        toast.error("Erro ao carregar dominios");
      });

    if (userInfo && userInfo.unidade) {
      getUnidade(localStorage.getItem("token"), userInfo.unidade)
        .then((data) => {
          if (data.status) {
            setActvieMedicalUnit(data.details);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    
    listPronouns()
    .then(data => {
      if (data.status) {
        const {pronoun} = data;

        if(pronoun && Array.isArray(pronoun))
        {
          setPronomeMap(pronoun.map(x => {
            return {
              id: x.id,
              value: x.name
            }
          }))
        }
      }
    })
    .catch(err => {
      toast.error(err.message || "Erro ao buscar pronomes [500]");
    })

    return () => {
      setComponentDepsLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (cns && cns.length) {
      if (cns[cnsPrincipal] === undefined) {
        // Antigo cns principal removido;

        setCnsPrincipal(cns.length - 1);
      }
    } else {
      setCnsPrincipal(0);
    }
  }, [cns]);

  const unidadesRef = useMemo(
    () =>
      props.unidadesRef.map((item) => {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [props.unidadesRef]
  );

  const racasMap = useMemo(
    () =>
      racasCode.map((item) => {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const unidadesVinculadas = useMemo(
    () =>
      props.unidadesVinculadas.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    [props.unidadesVinculadas]
  );

  const deficienciasMap = useMemo(
    () =>
      deficienciasCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const tiposTelefoneMap = useMemo(
    () =>
      tiposTelefoneCode.map(function (item) {
        return { id: item, value: item };
      }),
    []
  );

  const populacaoEspecMap = useMemo(
    () =>
      populacaoEspecCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const orientacoesSexuaisMap = useMemo(
    () =>
      orientacoesSexuaisCode.sort((a, b) => a.name > b.name ? 1 : -1).map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const generosMap = useMemo(() =>
    generoCode.sort((a, b) => a.name > b.name ? 1 : -1).map(function (item) {
      return { id: item.id, value: item.name };
    })
    , 
    []
  );

  const orgaoEmissorMap = useMemo(
    () =>{

      if(nacionalidade == 2){
        return [{ id: 'Policia Federal', value: 'Polícia Federal'}];
      }

      return orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      })
    },
    [nacionalidade]
  );

  const justificativaDadosIncompletosMap = useMemo(
    () =>
      justificativaDadosIncompletosCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const sexoMap = useMemo(
    () =>
      sexoCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const logradourosMap = useMemo(() =>
    logradourosCode.map((item) => {
      return { ...item };
    }),
    []
  );

  useEffect(() => {
    if (citys) {
      // Na alteração do estado pega os municípios para seleção.
      setMunicipiosByEstadoNascimento(
        citys.filter((x) => x.state_id === estadoNascimento)
      );
    }
  }, [estadoNascimento]);

  useEffect(() => {

    if(estado == null || estado == ""){
      setMunicipio(null);
    }
    if (citys) {
      // Na alteração do estado pega os municípios para seleção.
      setMunicipiosByEstadoEndereco(citys.filter((x) => x.state_id === estado));
    }
  }, [estado]);

  const nacionalidades = useMemo(
    () => [
      { id: 1, value: "Brasileiro" },
      { id: 2, value: "Naturalizado" },
      { id: 3, value: "Estrangeiro" },
    ],
    []
  );

  const validateDate = {
    dataNascimento: () => {
      if (dataNascimentoValida) {
        var [day, month, year] = dataNascimento.split("/");

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_dataNascimento > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else if(day || month || year){
          return "Data inválida.";
        } else {
          return "Campo obrigatório.";
        }
      } else {
        return 'Data inválida.';
      }
    },
    dataEmissaoIdentidade: () => {
      if (identidadeEmissaoValida) {
        var [day, month, year] = identidadeEmissao.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else if(day || month || year){
          return "Data inválida.";
        } else {
          return "Campo obrigatório.";
        }
      } else {
        return "Data inválida.";
      }
    },
    dataEmissaoResponsavel: () => {
      if (
        !required(responsavelLegalDocumentoEmissao) &&
        responsavelLegalDocumentoEmissao.length >= 10
      ) {
        var _respEmissao = new Date(
          `${responsavelLegalDocumentoEmissao
            .split("/")
            .reverse()
            .join("-")} 00:00:00`
        );

        if (String(_respEmissao) === "Invalid Date") {
          return "Data inválida.";
        }

        let [day, month, year] = responsavelLegalDocumentoEmissao.split("/");
        
        if(!isValidDate(day, month, year)) {
          return 'Data inválida.'
        }

        if (
          _respEmissao.getTime() >
          new Date(new Date().setHours(0, 0, 0, 0)).getTime()
        ) {
          return "A data de emissão não pode ser maior que a data atual";
        }

      } else {
        return "Campo obrigatório.";
      }
    },
    dataNaturalizacao: () => {
      if (dataNaturalizacaoValida && dataNaturalizacao !== "") {
        var [day, month, year] = dataNaturalizacao.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else {
          return "Data inválida.";
        }
      } else {
        return "Campo obrigatório.";
      }
    },
    dataEmissaoPassaporte: () => {
      if (passaporteDataEmissaoValida) {
        var [day, month, year] = passaporteDataEmissao.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        var [dayP, monthP, yearP] = passaporteDataValidade.split("/");
        var _identidadeEmissaoValidade = new Date(`${yearP}-${monthP}-${dayP}`);
        if (_identidadeEmissao > _identidadeEmissaoValidade) {
          return "Data não pode ser maior que a data de validade do documento.";
        } 

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }

          return undefined;
        } else {
          if(passaporteDataEmissao === '') {
              return 'Campo obrigatório.'
          }
          return "Data inválida.";
        }
      } else {
        return "Campo obrigatório.";
      }
    },
    dataValidadePassaporte: () => {
      if (passaporteDataValidadeValida) {


        var [day, month, year] = passaporteDataValidade.split("/");

        var _identidadeEmissaoValidade = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissaoValidade < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        var [dayP, monthP, yearP] = passaporteDataEmissao.split("/");
        var _identidadeEmissao = new Date(`${yearP}-${monthP}-${dayP}`);
        if (_identidadeEmissaoValidade < _identidadeEmissao) {
          return "Data não pode ser menor que a data de emissão.";
        } 

        if (_identidadeEmissaoValidade < new Date()) {
          return "Data não pode ser menor que a data atual.";
        }        

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else {
          if(passaporteDataValidade === '') {
              return 'Campo obrigatório.'
          }
          return "Data inválida.";
        }
      } else {
        return "Campo obrigatório.";
      }
    },
    dataUltimaMenstruacao: () => {
      if (dtUltimaMenstruacaoValida) {
        var [day, month, year] = dtUltimaMenstruacao.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else {
          if(dtUltimaMenstruacao === '') {
              return 'Campo obrigatório.'
          }
          return "Data inválida.";
        }
      } else {
        return "Campo obrigatório.";
      }
    },
    dataUltimoAborto: () => {
      if (dtUltimoAborto || dtUltimoAborto != "") {
        var [day, month, year] = dtUltimoAborto.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          if(!isValidDate(day, month, year)) {
            return 'Data inválida.'
          }
          return undefined;
        } else {
          return "Data inválida.";
        }
      } else {
        if (teveAborto && qtdVezesAborto > 0) {
          return "Campo obrigatório";
        }

        return undefined;
      }
    },
  };

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function novoTelefone() {
    delete error.telefones;

    var new_qtd_telefones = [...qtdTelefones, 1];

    setQtdTelefones(new_qtd_telefones);
    setTelefones([
      ...telefones,
      { id: new_qtd_telefones.length, phone: "", type: "", name: "" },
    ]);
  }

  function novoCns() {
    delete error.cns;

    // var new_qtd_cns = [...qtdCns, 1];

    // setQtdCns(new_qtd_cns);
    setCns((x) => [...x, ""]);
  }

  function getValidators() {
    var validators = {};

    if (!dadosIncompletos) {
      validators = {};

      if (step === 0) {
        // Dados pessoais

        // <Identidade

        // <Dados principais;
        validators.dataNascimento = validateDate.dataNascimento;
        validators.raca = required;
        validators.nacionalidade = required;

        if (nacionalidade != 3) {
            validators.cpf = validaCPF;
        }

        validators.email = validaEmail;
        validators.nome = requiredTwoName;
        validators.genero = required;

        validators.sexo = required;
        validators.seIdentificaSexo = (value) => {
          return value !== null && value != "" ? undefined : "Campo obrigatório";
        }
          
        validators.orientacaoSexual = required;

        if (seIdentificaSexo == false) {          
          validators.pronome = required;
          validators.nomeSocial = requiredOptionalTwoName;
        }

        if (!maeDesconhecida) {
          validators.nomeMae = requiredTwoName;
        }

        if (idade < 18) {
          validators.responsavelLegal = required;
          validators.grauDeRelacionamento = required;
          validators.responsavelLegalDocumento = validaRG;
          validators.responsavelLegalDocumentoEmissao =
            validateDate.dataEmissaoResponsavel;
        }

        // </ Identidade
      }

      if (step === 1) {
        // Contato
        // Telefones
        validators.telefones = validaTel;
      }

      if (step === 2) {
        // Endereço
        if (nacionalidade != 3) {
          // Naturalizado ou Brasileiro
          validators.cep = validaCEP;
          validators.endereco = required;
          // validators.complemento = required;
          validators.logradouro = required;
          validators.bairro = required;
          validators.municipio = required;
          validators.estado = required;
          validators.numero = required;
        }

        if (nacionalidade == 3) {
          // Estrangeiro
          validators.paisNascimento = required;
        }
      }

      // Carteira Nacional de saúde (CNS)
      validators.cns = validaLoteCNS;

      // Dados complementares
      if (!paiDesconhecido) {
        validators.nomePai = requiredTwoName;
      }

      if (nacionalidade == 1) {
        // Identidade

        validators.identidadeNumero = validaRG;
        validators.identidadeEmissao = validateDate.dataEmissaoIdentidade;
        validators.identidadeOrgaoEmissor = required;
        validators.identidadeUF = required;

        validators.estadoNascimento = required;
        validators.municipioNascimento = required;

        // Dados complementares
        validators.numeroPis = validaPIS;
      } else if (nacionalidade == 2) {
      
        // Identidade - Naturalizado
        // validators.dataNaturalizacao = validateDate.dataNaturalizacao;
        validators.paisNascimento = required;
        validators.identidadeNaturalNumero = validaRNE;
        validators.identidadeNaturalEmissao = required;
        validators.identidadeNaturalOrgaoEmissor = required;
        validators.identidadeNaturalUF = required;
      
      } else if (nacionalidade == 3) {
        // Identidade - Estrangeiro
        validators.passaporteNumero = required;
        validators.passaportePaisEmissor = required;
        validators.passaporteDataEmissao = validateDate.dataEmissaoPassaporte;
        validators.passaporteDataValidade = validateDate.dataValidadePassaporte;
        // validators.passaportePaisNascimento = required;
        validators.identidadeNaturalNumero = validaRNE;
        validators.identidadeNaturalEmissao = required;
        validators.identidadeNaturalOrgaoEmissor = required;
        validators.identidadeNaturalUF = required;
      }

      if (raca == 5) {
        validators.etnia = required;
      }

      if (temDeficiencia) {
        validators.deficiencia = required;
      }

      if (sexo == "Feminino") {
        validators.qtdVezesGravidez = required;
      }

      var dt = moment(dataNascimento, "DD/MM/YYYY");
      var dt_birthday = moment(`${dt.format('D/M')}/${moment().format('YYYY')}`, "DD/MM/YYYY");
      var diff_days = moment.duration(dt_birthday.diff(moment())).asDays();

      if (sexo == "Feminino" && idade >= 15 && Math.ceil(diff_days) < 0) {
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;
      }

      if (qtdVezesGravidez > 0) {
        validators.filhosVivos = required;

        if (teveAborto) {
          validators.qtdVezesAborto = required;
          validators.dtUltimoAborto = validateDate.dataUltimoAborto;
        }
      }

      if (filhosVivos || qtdVezesAborto) {      

        if (teveAborto && qtdVezesAborto > qtdVezesGravidez) {

          validators.qtdVezesAborto = () => "Quantidade de abortos não pode ser superior que a de gravidez";

        } else if ( teveAborto && (parseInt(filhosVivos) + parseInt(qtdVezesAborto)) > qtdVezesGravidez ) {

          validators.qtdVezesGravidez = () => "Quantidade de filhos vivos e de abortos não condizem com a quantidade de gravidez";          
        }

      }
    } else {
      // Dados incompletos'''

      // Dados pessoais
      validators.sexo = required;
      validators.justificativaDadoIncompleto = required;
      validators.anoEstimadoDeNascimento = (valor) => {
        if (valor == "" || valor === undefined || !valor) {
          return "Campo obrigatório";
        }

        if (Number(valor) < 1900 || Number(valor) > new Date().getFullYear()) {
          return Number(valor) < 1900
            ? "Data não pode ser menor que 1900."
            : "Data não pode ser maior que a data atual.";
        }

        return valor.length < 1 ? "Campo obrigatório" : undefined;
      };

      if (step === 2) {
        // Endereço

        if (nacionalidade == 3) {
          validators.paisNascimento = required;
        } else {
          validators.cep = validaCEP;
          validators.numero = required;
          validators.endereco = required;
          validators.logradouro = required;
          validators.bairro = required;
          validators.municipio = required;
          validators.estado = required;          
        }
      }
    }

    return validators;
  }

  useEffect(() => {
    if (!temDeficiencia) return setTemDeficienciaIntelectual(false);

    if (deficiencia.find((x) => x === "Deficiência intelectual")) {
      setTemDeficienciaIntelectual(true);
    } else {
      setTemDeficienciaIntelectual(false);
    }
  }, [deficiencia, temDeficiencia]);

  function getDados() {
    var dados = {
      // Dados principais
      cpf,
      email,
      nome,
      nomeSocial,
      dataNascimento,
      nacionalidade,
      sexo,
      seIdentificaSexo,
      genero,
      pronome,

      // Identidade
      identidadeNumero,
      identidadeEmissao,
      identidadeOrgaoEmissor,
      identidadeUF,
      nomeMae,
      maeDesconhecida,

      // Identidade - Naturalizado
      dataNaturalizacao,
      paisNascimento,
      identidadeNaturalNumero,
      identidadeNaturalEmissao,
      identidadeNaturalOrgaoEmissor,
      identidadeNaturalUF,

      // Identidade - Estrangeiro
      passaporteNumero,
      passaportePaisEmissor,
      passaporteDataEmissao,
      passaporteDataValidade,
      passaportePaisNascimento,

      // Carteira Nacional de saúde (CNS)
      cns,
      cnsPrincipal,

      // Dados demográficos
      estadoNascimento,
      municipioNascimento,
      raca,
      etnia,
      orientacaoSexual,

      // Gravidez
      qtdVezesGravidez,
      filhosVivos,
      dtUltimaMenstruacao,
      teveAborto,
      qtdVezesAborto,
      dtUltimoAborto,

      // Dados complementares
      numeroPis,
      seriePis,
      ufPis,
      temDeficiencia,
      deficiencia,
      populacaoEspec,
      nomePai,
      paiDesconhecido,
      grauDeRelacionamento,
      responsavelLegalDocumento,
      responsavelLegalDocumentoEmissao,
      responsavelLegal,

      // Telefones
      telefones,

      // Unidades
      unidadeRef,
      unidadeVinculada,

      // Endereço
      cep,
      endereco,
      numero,
      complemento,
      bairro,
      logradouro,
      estado,
      municipio,
      idMunicipio,

      //Dados Incompletos
      justificativaDadoIncompleto,
      dadosIncompletos,
      anoEstimadoDeNascimento,
    };

    if (nacionalidade == 1) {
      dados.passaporteNumero = "null";
      dados.passaportePaisEmissor = "null";
      dados.passaporteDataEmissao = "null";
      dados.passaporteDataValidade = "null";
      dados.passaportePaisNascimento = "null";
      dados.dataNaturalizacao = "null";
    }

    if (nacionalidade == 2) {
      dados.identidadeNumero = "null";
      dados.identidadeEmissao = "null";
      dados.identidadeOrgaoEmissor = "null";
      dados.identidadeUF = "null";
      dados.passaporteNumero = "null";
      dados.passaportePaisEmissor = "null";
      dados.passaporteDataEmissao = "null";
      dados.passaporteDataValidade = "null";
      dados.passaportePaisNascimento = "null";
    }

    if (nacionalidade == 3) {
      dados.identidadeNumero = "null";
      dados.identidadeEmissao = "null";
      dados.identidadeOrgaoEmissor = "null";
      dados.identidadeUF = "null";
      dados.numeroPis = "null";
      dados.seriePis = "null";
      dados.ufPis = "null";
      dados.dataNaturalizacao = "null";
    }

    if (maeDesconhecida) {
      dados.nomeMae = "null";
    }

    if (paiDesconhecido) {
      dados.nomePai = "null";
    }

    return dados;
  }

  useEffect(() => {
    if (Object.keys(error).length) {
      if (Object.keys(error)[0] == "nomePai") {
        setExpandMore(true);

        if (Object.keys(error).hasOwnProperty("scrollIntoView")) {
          setTimeout(function () {
            if (document.getElementById(Object.keys(error)[0])) {
              document.getElementById(Object.keys(error)[0]).scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          }, 1000);
        }
      } else {

        if (
          Object.keys(error).find(
            (x) => x === "responsavelLegal" || x === "grauDeRelacionamento"
          )
        ) {
          setExpandMore(true);
        }

        if (document.getElementById(Object.keys(error)[0])) {
          document.getElementById(Object.keys(error)[0]).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      }
    }
  }, [error]);

  function onStep(step) {
    setStep(step);
  }

  function formatDate(date) {
    if (date && date != "") {
      var day = date.substring(0, 2);
      var month = date.substring(3, 5);
      var year = date.substring(6);
      return [year, month, day].join("-");
    } else {
      return "";
    }
  }

  function removeFormat(value) {
    return value.replace(/[\s.-]*/gim, "");
  }

  function criarPaciente(dados) {
    var documents = [];
    var deficiencies = [];
    var contacts = [];
    var units = [];
    var pregnancy = {};

    dados.cns.map((res) => {
      documents.push({
        type: "CNS",
        fields: [
          {
            id: 9,
            value: res,
          },
          {
            id: 61,
            value: res === dados.cns[dados.cnsPrincipal],
          },
        ],
      });
    });

    if (dados.nacionalidade == 3) {
      documents.push({
        type: "PASSPORT",
        fields: [
          {
            id: 5,
            value: dados.passaporteNumero,
          },
          {
            id: 6,
            value: formatDate(dados.passaporteDataEmissao),
          },
          {
            id: 7,
            value: dados.passaportePaisEmissor,
          },
          {
            id: 8,
            value: formatDate(dados.passaporteDataValidade),
          },
        ],
      });

      if (typeof dados.cpf != "undefined") {

        documents.push({
          type: "CPF",
          fields: [
            {
              id: 1,
              value: dados.cpf,
            },
          ],
        });

      }
      
    } else {
      documents.push({
        type: "CPF",
        fields: [
          {
            id: 1,
            value: dados.cpf,
          },
        ],
      });

      if (dados.nacionalidade == 1) {
        documents.push(
          {
            type: "RG",
            fields: [
              {
                id: 2,
                value: dados.identidadeNumero,
              },
              {
                id: 3,
                value: formatDate(dados.identidadeEmissao),
              },
              {
                id: 4,
                value: dados.identidadeOrgaoEmissor,
              },
              {
                id: 60,
                value: dados.identidadeUF,
              },
            ],
          },
          {
            type: "PIS",
            fields: [
              {
                id: 57,
                value: dados.numeroPis,
              },
              {
                id: 58,
                value: dados.seriePis,
              },
              {
                id: 59,
                value: dados.ufPis,
              },
            ],
          }
        );
      }

      if (dados.nacionalidade == 2) {
        
        var _rneDocument = {
          type: "RNE",
          fields: [
            {
              id: 62,
              value: dados.identidadeNaturalNumero,
            },
            {
              id: 63,
              value: formatDate(dados.identidadeNaturalEmissao),
            },
            {
              id: 64,
              value: dados.identidadeNaturalOrgaoEmissor,
            },
            {
              id: 65,
              value: dados.identidadeNaturalUF
            },
          ],
        };

        documents.push(_rneDocument);
        
        // documents.push({
        //   type: "NATURALIZED",
        //   fields: [
        //     {
        //       id: 15,
        //       value: (typeof dados.dataNaturalizacao != 'undefined') ? formatDate(dados.dataNaturalizacao) : null,
        //     },
        //     {
        //       id: 16,
        //       value: dados.paisNascimento,
        //     },
        //   ],
        // });

      }
    }

    if (dados.sexo == "Feminino") {
      pregnancy = {
        quantity: dados.qtdVezesGravidez,
        born_alive: dados.filhosVivos,
        last_mestruation_date: formatDate(dados.dtUltimaMenstruacao),
        abortion: dados.teveAborto,
        quantity_abortion:
          dados.qtdVezesAborto === "" ? null : dados.qtdVezesAborto,
        last_abortion_date:
          formatDate(dados.dtUltimoAborto) ||
          new Date().toISOString().split("T")[0],
      };
    }

    if (Array.isArray(dados.deficiencia) && dados.deficiencia.length) {
      dados.deficiencia.map((item) => {
        deficiencies.push({ value: item.value });
      });
    }

    dados.telefones.map((item) => {
      contacts.push({
        name: item.name,
        phone: item.phone,
        type: item.type,
        observation: item.type,
      });
    });

    dados.unidadeVinculada.map((item) => {
      units.push({ medical_unit_id: item.id });
    });

    if (dadosIncompletos && paisNascimento === "") {
      dados.paisNascimento = null;
    }

    var dadosPost = {
      patient: {
        email: dados.email,
        full_name: dados.nome,
        social_name: dados.nomeSocial,
        nationality: dados.nacionalidade,
        birth_date: formatDate(dados.dataNascimento),
        biological_sex: dados.sexo,
        profession: null,
        marital_status: null,
        pronoun_id: dados.pronome,
        etnia_id: dados.etnia,
        biological_sex_identifies: dados.seIdentificaSexo,
        sexual_orientation_id: dados.orientacaoSexual,
        breed_id: dados.raca,
        genre_id: dados.genero,
        specific_population_id: dados.populacaoEspec
          ? dados.populacaoEspec
          : null,
        relationship_degree_id: dados.grauDeRelacionamento,
        legal_responsible: dados.responsavelLegal,
        legal_responsible_document: dados.responsavelLegalDocumento,
        legal_responsible_document_date: dados.responsavelLegalDocumentoEmissao,
        medical_unit_id: dados.unidadeRef ? dados.unidadeRef : null,
        father_name: dados.paiDesconhecido ? null : dados.nomePai,
        mother_name: dados.maeDesconhecida ? null : dados.nomeMae,
        father_know: !dados.paiDesconhecido,
        mother_know: !dados.maeDesconhecida,
        incomplete_data: dadosIncompletos,
        incomplete_data_reason: justificativaDadoIncompleto
          ? justificativaDadoIncompleto
          : null,
        birth_year: dadosIncompletos ? anoEstimadoDeNascimento : null,
      },
      patientAddress: {
        zip_code: removeFormat(dados.cep),
        address_name: dados.endereco,
        street: dados.endereco,
        number: dados.numero,
        complement: dados.complemento,
        district: dados.bairro,
        city_id: dados.idMunicipio || null,
        country_id: dados.paisNascimento,
        address_type_id: logradouro,
      },
      patientPregnancy: pregnancy,
      patientDeficiencies: deficiencies,
      patientContact: contacts,
      patientDocument: documents,
      patientHasGroup: [],
      patientHasMedicalUnit: units,
    };

    if (dados.nacionalidade == 1) {
      dadosPost.patientAddress.country_id = 45;
      dadosPost.patient.country_birth = 45;
      dadosPost.patient.city_birth = municipioNascimento;
      dadosPost.patient.state_birth = estadoNascimento;
    }

    if (dados.nacionalidade == 2) {
      dadosPost.patient.country_birth = paisNascimento;
    }

    if (dados.nacionalidade == 3) {
      dadosPost.patientAddress.country_id = paisNascimento;
      dadosPost.patient.country_birth = paisNascimento;
    }

    setLoading(true);

    createPatient(localStorage.getItem("token"), dadosPost).then((res) => {
      if (res.status) {
        setCadastrado(true);

        setLoading(false);
      } else {
        setLoading(false);

        if (res.details) {
          // var errors = {};

          Object.entries(res.details).forEach(function (item, i) {
            toast.error(
              "Erro, não foi possível cadastrar o paciente: " + item[1]
            );
          });
        } else {
          toast.error("Erro, não foi possível cadastrar o paciente.");
        }
      }
    });
  }

  function applyDrag(arr, dragResult) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }

    return result;
  }

  function removeAccents(text) {
    const accentsMap = new Map([
      ["A", "Á|À|Ã|Â|Ä"],
      ["a", "á|à|ã|â|ä"],
      ["E", "É|È|Ê|Ë"],
      ["e", "é|è|ê|ë"],
      ["I", "Í|Ì|Î|Ï"],
      ["i", "í|ì|î|ï"],
      ["O", "Ó|Ò|Ô|Õ|Ö"],
      ["o", "ó|ò|ô|õ|ö"],
      ["U", "Ú|Ù|Û|Ü"],
      ["u", "ú|ù|û|ü"],
      ["C", "Ç"],
      ["c", "ç"],
      ["N", "Ñ"],
      ["n", "ñ"],
    ]);

    const reducer = (acc, [key]) =>
      acc.replace(new RegExp(accentsMap.get(key), "g"), key);

    return [...accentsMap].reduce(reducer, text);
  }

  useEffect(() => {
    if (step !== 0) {
      setStep(0);
      toast.info("Revise os campos obrigatórios.");
    } else {
      if (dadosIncompletos && step === 0) {
        var _invalidIncompleteFields = {};

        if (anoEstimadoDeNascimento === "") {
          _invalidIncompleteFields.anoEstimadoDeNascimento =
            "Campo obrigatório";
        }

        if (sexo === "") {
          _invalidIncompleteFields.sexo = "Campo obrigatório";
        }

        if (justificativaDadoIncompleto === "") {
          _invalidIncompleteFields.justificativaDadoIncompleto =
            "Campo obrigatório";
        }

        setErrors(_invalidIncompleteFields);
      }
    }

    if (!dadosIncompletos) {
      setJustificativaDadoIncompleto("");
      delete error.justificativaDadoIncompleto;
      setAnoEstimadoDeNascimento("");
      delete error.anoEstimadoDeNascimento;
    }
  }, [dadosIncompletos]);

  useEffect(() => {
    // Caso não seja estrangeiro, autopreenche endereço com endereço da unidade no caso de dados incompletos, do contrário os apaga.
    if (dadosIncompletos) {
      if (activeMedicalUnit && activeMedicalUnit.address) {
        const { number, zip_code } = activeMedicalUnit.address;

        if (nacionalidade == 3) {
          setCep("");
          setNumero("");
          setEndereco("");
          setMunicipio("");
          setEstado("");
          setBairro("");
        } else {
          // Autopreenchimento de endereço
          setCep(mascaraCEP(zip_code));
          setNumero(number);
        }
      }
    }
  }, [nacionalidade, dadosIncompletos]);

  useEffect(() => {
    
    if (!cep || !cep.length) return;

    var new_cep = cep.replace(/[^0-9]/gm, "");

    if (new_cep.length == 8) {
      
      getCep2(new_cep)
      .then((res) => {
        if (res.erro) {
          var new_erro = {};
          new_erro.cep = "CEP inexistente 2";
          setErrors(new_erro);

          setBairro("");
          setEndereco("");
          setEstado("");
          setMunicipio("");
          setIdMunicipio("");
        } else {

          var stateSelect = states.find((x) => x.value === res.estado_sigla)
          var municipioSelec = citys.filter(
            (city) =>
              city.value == removeAccents(res.localidade.toUpperCase()) && city.state_id == stateSelect.id
          )[0];
         
          if (municipioSelec.id) {
            setBairro(res.bairro);
            //setEndereco(res.logradouro);
            setEstado(
              states.find((x) => x.value === res.estado_sigla)
                ? states.find((x) => x.value === res.estado_sigla).id
                : null
            );
            setLogradouro(
              logradourosCode.find((x) => x.value === res.logradouro_tipo)
                ? logradourosCode.find((x) => x.value === res.logradouro_tipo).id
                : null
            );
            setMunicipio(municipioSelec.id);
            setIdMunicipio(municipioSelec.id);
          }
        }
      })
      .catch(console.error);
    }
  }, [cep]);

  console.log(nacionalidade)
  console.log(nacionalidade !== 3 )

  return (
    <>
      <SuccessfulRegistratonPatient
        open={cadastrado}
        onGetOut={props.onGetOut}
        onCreateAnotherPatient={(_) => {
          props.reset();
          setCadastrado(false);
          setStep(0);
        }}
        patientName={nome || nomeSocial}
      />

      <Grid container>
        {componentDepsLoaded ? (
          <>
            <Grid container className="tema-light">
              <Grid item md={12}>
                <StepLine step={step} />
                <hr />
              </Grid>
            </Grid>

            {/* Dados pessoais */}
            <Grid
              container
              className={`tema-light ${classes.root} ${
                step !== 0 ? classes.hide : ""
              }`}
              spacing={3}
            >
              <Grid item md={12} style={{ marginTop: "1em" }}>
                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  gutterBottom
                >
                  Dados principais
                </Typography>
              </Grid>
              <Grid item md={3}>
                <DDropDownSimple
                  primaryColorSelectIcon
                  id="nacionalidade"
                  label="Nacionalidade"
                  placeholder="Nacionalidade"
                  fullWidth
                  labelColor="black"
                  required={!dadosIncompletos}
                  error={error.nacionalidade}
                  helperText={error.nacionalidade}
                  labelClass={classes.labelBlack}
                  value={nacionalidade}
                  options={nacionalidades}
                  onChange={(event) => {
                    delete error.nacionalidade;
                    setStep2(true);
                    setNacionalidade(event);
                  }}
                />
              </Grid>
              <Grid item md={3}>
                <DInput
                  id="cpf"
                  label="CPF"
                  placeholder="CPF"
                  labelColor="black"
                  // required={true}
                  required={nacionalidade == 3 ? false : !dadosIncompletos}
                  type="text"
                  error={error.cpf}
                  helperText={error.cpf}
                  positionIcon="end"
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.cpf;
                    setCPF(mascaraCPF(e.target.value));
                  }}
                  value={cpf}
                />
              </Grid>
              <Grid item md={6}>
                <DInput
                  id="nome"
                  label="Nome completo"
                  placeholder="Nome completo"
                  labelColor="black"
                  // required={true}
                  required={!dadosIncompletos}
                  type="text"
                  inputProps={{ maxLength: 100 }}
                  positionIcon="end"
                  error={error.nome}
                  helperText={error.nome}
                  fullWidth
                  onChange={(e) => {
                    delete error.nome;
                    var _value = e.target.value.replace(
                      /[^a-zA-Zà-úÀ-Ú"' ]/g,
                      ""
                    );

                    setNome(_value);
                  }}
                  value={nome}
                />
              </Grid>
              <Grid
                item
                md={3}
                className={dadosIncompletos || !step1 ? classes.hide : ""}
              >
                <DDateInput
                  id="dataNascimento"
                  error={error.dataNascimento}
                  positionIcon="end"
                  helperText={error.dataNascimento}
                  fullWidth
                  label={
                    <>
                      {
                        <span style={{ color: "black" }}>
                          Data de nascimento
                        </span>
                      }{" "}
                      <span style={{ color: "red" }}>*</span>{" "}
                    </>
                  }
                  maxDate={new Date()}
                  maxDateMessage="A data de nascimento não pode ser maior que a data atual"
                  value={dataNascimento}
                  onError={(errorMessage) => {
                    setErrors({ ...error, dataNascimento: errorMessage });
                  }}
                  onChange={(arg1, arg2, e) => {
                    delete error.dataNascimento;

                    var anoAtual = new Date().getFullYear();
                    var anoNasc = arg2.year;

                    setIdade(anoAtual - anoNasc);

                    if (arg2.isValid && arg1 && arg1.length === 10) {
                      if (
                        new Date(
                          `${arg2.year}-${arg2.month}-${arg2.day} 00:00:00`
                        ).getTime() <
                        new Date(
                          new Date(
                            new Date().setYear(new Date().getFullYear() - 18)
                          ).setHours(0, 0, 0, 0)
                        ).getTime()
                      ) {
                        // Idade é menor que 18 baseado no dia;mes;ano

                        if (anoAtual - anoNasc === 18) {
                          setIdade(17); // compensação para o erro de idade por dia
                        }
                      }
                    }

                    setDataNascimento(arg1);
                    setDataNascimentoValida(arg2.isValid);
                  }}
                />
              </Grid>
              <Grid
                item
                md={3}
                className={!dadosIncompletos ? classes.hide : ""}
              >
                <DInput
                  id="anoEstimadoDeNascimento"
                  error={error.anoEstimadoDeNascimento}
                  helperText={error.anoEstimadoDeNascimento}
                  label={"Ano estimado de nascimento"}
                  labelColor="black"
                  // required={true}
                  inputProps={{ maxLength: 4 }}
                  required={dadosIncompletos}
                  type="text"
                  positionIcon="end"
                  fullWidth
                  onChange={(e) => {
                    delete error.anoEstimadoDeNascimento;

                    if (e.target.value.length === 4) {
                      if (Number(e.target.value) > new Date().getFullYear()) {
                        setErrors({
                          ...error,
                          anoEstimadoDeNascimento:
                            "O ano estimado de nascimento não pode ser maior que o ano atual",
                        });
                      }

                      if (Number(e.target.value) < 1900) {
                        setErrors({
                          ...error,
                          anoEstimadoDeNascimento:
                            "O ano estimado de nascimento não pode ser menor que 1900",
                        });
                      }
                    }

                    setAnoEstimadoDeNascimento(
                      e.target.value.replace(/[^0-9]/g, "")
                    );
                  }}
                  value={anoEstimadoDeNascimento}
                />
              </Grid>

              <Grid item md={3}>
                <DDropDownSimple
                  primaryColorSelectIcon
                  id="sexo"
                  label="Sexo biológico"
                  placeholder="Sexo biológico"
                  fullWidth
                  labelColor="black"
                  required={true}
                  error={error.sexo}
                  helperText={error.sexo}
                  value={sexo}
                  options={sexoMap}
                  onChange={(event) => {
                    delete error.sexo;
                    setSexo(event);
                  }}
                />
              </Grid>

              <Grid item md={3}>
                <DDropDownSimple
                  primaryColorSelectIcon
                  id="seIdentificaSexo"
                  label="Se identifica com sexo biológico?"
                  placeholder="Se identifica com sexo biológico?"
                  fullWidth
                  labelColor="black"
                  required={!dadosIncompletos}
                  error={error.seIdentificaSexo}
                  helperText={error.seIdentificaSexo}
                  value={seIdentificaSexo}
                  options={[
                    { id: true, value: "Sim" },
                    { id: false, value: "Não" },
                  ]}
                  onChange={(event) => {
                    delete error.seIdentificaSexo;
                    setSeIdentificaSexo(event);

                    if(event === true) 
                    {
                      setGenero(4);
                    }
                    else
                    {
                      setGenero(null);
                    }
                  }}
                />
              </Grid>

              <Grid item md={3}>
                <DDropDownSimple
                  primaryColorSelectIcon
                  id="orientacaoSexual"
                  label="Orientação sexual"
                  placeholder="Orientação sexual"
                  fullWidth
                  required={!dadosIncompletos}
                  labelColor="black"
                  error={error.orientacaoSexual}
                  helperText={error.orientacaoSexual}
                  value={orientacaoSexual}
                  options={orientacoesSexuaisMap}
                  onChange={(event) => {
                    delete error.orientacaoSexual;
                    setOrientacaoSexual(event);
                  }}
                />
              </Grid>

              <Grid item md={3}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="genero"
                      label="Gênero"
                      placeholder="Gênero"
                      fullWidth
                      required={!dadosIncompletos}
                      labelColor="black"
                      error={error.genero}
                      helperText={error.genero}
                      value={genero}
                      options={generosMap}
                      onChange={(event) => {
                        delete error.genero;
                        setGenero(event);

                        if (event === 4) 
                        {
                          setSeIdentificaSexo(true);
                        }
                      }}
                    />
                  </Grid>
              {seIdentificaSexo !== null && seIdentificaSexo === false && (
                <>                
                  <Grid item md={3}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="pronome"
                      label="Pronome"
                      placeholder="Pronome"
                      fullWidth
                      required={!dadosIncompletos}
                      labelColor="black"
                      error={error.pronome}
                      helperText={error.pronome}
                      value={pronome}
                      options={pronomeMap}
                      onChange={(event) => {
                        delete error.pronome;
                        setPronome(event);
                      }}
                    />
                  </Grid>

                      <Grid item md={6}>
                        <DInput
                          id="nomeSocial"
                          label="Nome social"
                          inputProps={{ maxLength: 100 }}
                          placeholder="Nome social"
                          fixedLabel
                          labelColor="black"
                          type="text"
                          positionIcon="end"
                          fullWidth
                          error={error.nomeSocial}
                          helperText={error.nomeSocial}
                          onChange={(e) => {
                            var _value = e.target.value.replace(
                              /[^a-zA-Zà-úÀ-Ú"' ]/g,
                              ""
                            );

                            setNomeSocial(_value);
                          }}
                          value={nomeSocial}
                        />
                      </Grid>
                </>
              )}

              {/* Ponto de controle */}

              <Grid item md={6}>
                <DInput
                  id="email"
                  label="E-mail"
                  placeholder="E-mail"
                  labelColor="black"
                  // required={true}
                  inputProps={{ maxLength: 254 }}
                  required={!dadosIncompletos}
                  type="text"
                  error={error.email}
                  helperText={error.email}
                  positionIcon="end"
                  fullWidth
                  onChange={(e) => {
                    delete error.email;

                    if (e.target.value.length <= 254) {
                      setEmail(e.target.value);
                      setStep1(true);
                    }
                  }}
                  value={email}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  gutterBottom
                >
                  Dados demográficos
                </Typography>
              </Grid>

              <Grid item md={12}>
                <Grid container spacing={3}>
                  {nacionalidade === 1 && (
                    <>
                      <Grid item md={6}>
                        <DDropDownSimple
                          primaryColorSelectIcon
                          id="estadoNascimento"
                          label="Estado de nascimento"
                          placeholder="Estado de nascimento"
                          fullWidth
                          required={!dadosIncompletos}
                          labelColor="black"
                          error={error.estadoNascimento}
                          helperText={error.estadoNascimento}
                          value={estadoNascimento}
                          options={states}
                          onChange={(event) => {
                            delete error.estadoNascimento;
                            setEstadoNascimento(event);

                            if (typeof municipioNascimento === "number") {
                              // Previamente selecionado;
                              setMunicipioNascimento(""); // reset;
                            }

                            if (typeof event === "string" && event === "") {
                              setMunicipioNascimento(""); // reset;
                            }
                          }}
                        />
                      </Grid>

                      <Grid item md={6}>
                        {municipiosByEstadoNascimento && (
                          <DDropDownSimple
                            primaryColorSelectIcon
                            id="municipioNascimento"
                            label="Município de nascimento"
                            placeholder="Município de nascimento"
                            fullWidth
                            required={!dadosIncompletos}
                            labelColor="black"
                            error={error.municipioNascimento}
                            helperText={error.municipioNascimento}
                            value={municipioNascimento}
                            options={municipiosByEstadoNascimento}
                            onChange={(event) => {
                              delete error.municipioNascimento;
                              setMunicipioNascimento(event);
                            }}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                  {(nacionalidade === 2) && (
                  <Grid item md={6}>
                    <DAutoComplete
                      id="paisNascimento"
                      label="País de nascimento"
                      placeholder="País de nascimento"
                      labelColor="black"
                      // required={true}
                      required={!dadosIncompletos}
                      fullWidth
                      error={error.paisNascimento}
                      helperText={error.paisNascimento}
                      defaultValue={
                        countrys &&
                        countrys.length &&
                        countrys.filter((res) => res.id == paisNascimento)[0]
                      }
                      options={countrys}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        value && option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        delete error.paisNascimento;
                        setPaisNascimento(newValue ? newValue.id : "");
                      }}
                    />
                  </Grid>

                  )}

                  <Grid item md={6}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="raca"
                      label="Raça"
                      placeholder="Raça"
                      fullWidth
                      required={!dadosIncompletos}
                      labelColor="black"
                      error={error.raca}
                      helperText={error.raca}
                      value={raca}
                      options={racasMap}
                      onChange={(event) => {
                        delete error.raca;
                        delete error.etnia;
                        setRaca(event);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="etnia"
                      label="Etnia"
                      placeholder="Etnia"
                      fullWidth
                      disabled={raca != 5}
                      required={!dadosIncompletos && raca == 5}
                      labelColor="black"
                      error={error.etnia}
                      helperText={error.etnia}
                      value={etnia}
                      options={etnias}
                      onChange={(event) => {
                        delete error.etnia;

                        setEtnia(event);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={12} className={!nacionalidade ? classes.hide : ""}>
                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  gutterBottom
                >
                  Identidade
                </Typography>
              </Grid>
              {/* Brasileiro */}

              {!!(nacionalidade == 1) && (
                <>
                  <Grid item md={12}>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <DInput
                          id="identidadeNumero"
                          label="Número de identidade"
                          placeholder="Número de identidade"
                          labelColor="black"
                          // required={true}
                          required={!dadosIncompletos}
                          type="text"
                          error={error.identidadeNumero}
                          positionIcon="end"
                          helperText={error.identidadeNumero}
                          fullWidth={true}
                          value={identidadeNumero}
                          onChange={(e) => {
                            delete error.identidadeNumero;
                            setIdentidadeNumero(mascaraRG(e.target.value));
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DDateInput
                          id="identidadeEmissao"
                          error={error.identidadeEmissao}
                          helperText={error.identidadeEmissao}
                          fullWidth
                          required={!dadosIncompletos}
                          labelColor="black"
                          label="Data de emissão"
                          minDate={
                            dataNascimento
                              ? String(dataNascimento)
                                  .split("/")
                                  .reverse()
                                  .join("-")
                              : null
                          }
                          minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                          maxDate={new Date()}
                          maxDateMessage="A data de emissão não pode ser maior que a data atual"
                          value={identidadeEmissao}
                          onError={(errorMessage) => {
                            setErrors({
                              ...error,
                              identidadeEmissao: errorMessage,
                            });
                          }}
                          onChange={(arg1, arg2, e) => {
                            delete error.identidadeEmissao;

                            setIdentidadeEmissao(arg1);
                            setIdentidadeEmissaoValida(arg2.isValid);
                          }}
                        />
                      </Grid>
                      {states.length > 0 && (
                        <Grid item md={5}>
                          <DDropDownSimple
                            primaryColorSelectIcon
                            id="identidadeOrgaoEmissor"
                            label="Órgão emissor"
                            placeholder="Órgão emissor"
                            fullWidth
                            required={!dadosIncompletos}
                            labelColor="black"
                            error={error.identidadeOrgaoEmissor}
                            helperText={error.identidadeOrgaoEmissor}
                            value={identidadeOrgaoEmissor}
                            options={orgaoEmissorMap}
                            onChange={(event) => {
                              delete error.identidadeOrgaoEmissor;
                              setIdentidadeOrgaoEmissor(event);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item md={1}>
                        <DDropDownSimple
                          primaryColorSelectIcon
                          id="identidadeUF"
                          label="UF"
                          placeholder="Estado"
                          fullWidth
                          required={!dadosIncompletos}
                          labelColor="black"
                          error={error.identidadeUF}
                          helperText={error.identidadeUF}
                          value={identidadeUF}
                          options={states}
                          onChange={(event) => {
                            delete error.identidadeUF;
                            setIdentidadeUF(event);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {!!(nacionalidade == 2 || nacionalidade == 3) && (
                <>
                  <Grid item md={12}>
                    <Grid container spacing={3}>
                      <Grid item md={3}>
                        <DInput
                          id="identidadeNaturalNumero"
                          label="Número do RNE"
                          placeholder="Número do RNE"
                          labelColor="black"
                          // required={true}
                          required={!dadosIncompletos}
                          type="text"
                          error={error.identidadeNaturalNumero}
                          positionIcon="end"
                          helperText={error.identidadeNaturalNumero}
                          fullWidth={true}
                          value={identidadeNaturalNumero}
                          onBlur={(e) => {   
                            if(nacionalidade == 3) return;
                            const v = validaRNE(e.target.value);
                            setErrors({
                              ...error,
                              identidadeNaturalNumero: v,
                            });         
                          }}
                          onChange={(e) => {                   
                            delete error.identidadeNaturalNumero;
                            setIdentidadeNaturalNumero(mascaraRNE(e.target.value)); 
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <DDateInput
                          id="identidadeNaturalEmissao"
                          error={error.identidadeNaturalEmissao}
                          helperText={error.identidadeNaturalEmissao}
                          fullWidth
                          required={!dadosIncompletos}
                          labelColor="black"
                          label="Data de emissão"
                          minDate={
                            dataNascimento
                              ? String(dataNascimento)
                                  .split("/")
                                  .reverse()
                                  .join("-")
                              : null
                          }
                          minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                          maxDate={new Date()}
                          maxDateMessage="A data de emissão não pode ser maior que a data atual"
                          value={identidadeNaturalEmissao}
                          onError={(errorMessage) => {
                            setErrors({
                              ...error,
                              identidadeNaturalEmissao: errorMessage,
                            });
                          }}
                          onChange={(arg1, arg2, e) => {
                            delete error.identidadeNaturalEmissao;

                            setIdentidadeNaturalEmissao(arg1);
                            // setIdentidadeEmissaoValida(arg2.isValid);
                          }}
                        />
                      </Grid>
                      {states.length > 0 && (
                        <Grid item md={5}>
                          <DDropDownSimple
                            primaryColorSelectIcon
                            id="identidadeNaturalOrgaoEmissor"
                            label="Órgão emissor"
                            placeholder="Órgão emissor"
                            fullWidth
                            required={!dadosIncompletos}
                            labelColor="black"
                            error={error.identidadeNaturalOrgaoEmissor}
                            helperText={error.identidadeNaturalOrgaoEmissor}
                            value={identidadeNaturalOrgaoEmissor}
                            options={orgaoEmissorMap}
                            onChange={(event) => {
                              delete error.identidadeNaturalOrgaoEmissor;
                              setIdentidadeNaturalOrgaoEmissor(event);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item md={1}>
                        <DDropDownSimple
                          primaryColorSelectIcon
                          id="identidadeNaturalUF"
                          label="UF"
                          placeholder="Estado"
                          fullWidth
                          required={!dadosIncompletos}
                          labelColor="black"
                          error={error.identidadeNaturalUF}
                          helperText={error.identidadeNaturalUF}
                          value={identidadeNaturalUF}
                          options={states}
                          onChange={(event) => {
                            delete error.identidadeNaturalUF;
                            setIdentidadeNaturalUF(event);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Naturalizado */}
              {/* <Grid
                item
                md={12}
                className={nacionalidade != 2 ? classes.hide : ""}
              >
                <Grid container spacing={3}>
                  <Grid item md={6}>
                        <DInput
                            id="numeroDoRne"
                            label="Número do RNE"
                            placeholder="Número do RNE"
                            labelColor="black"
                            // required={true}
                            required={!dadosIncompletos}
                            type="text"
                            error={error.numeroDoRne}
                            positionIcon="end"
                            helperText={error.numeroDoRne}
                            fullWidth={true}
                            value={numeroDoRne}
                            onChange={(e) => {
                              delete error.numeroDoRne;
                              setNumeroDoRne(e.target.value);
                            }}
                          />
                    <DDateInput
                      id="dataNaturalizacao"
                      error={error.dataNaturalizacao}
                      helperText={error.dataNaturalizacao}
                      fullWidth
                      label={
                        <>
                          {
                            <span style={{ color: "black" }}>
                              Data de naturalização
                            </span>
                          }{" "}
                          {!dadosIncompletos && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </>
                      }
                      minDate={
                        dataNascimento
                          ? String(dataNascimento)
                              .split("/")
                              .reverse()
                              .join("-")
                          : null
                      }
                      minDateMessage="A data de naturalização não pode ser menor que a data de nascimento"
                      maxDate={new Date()}
                      maxDateMessage="A data de naturalização não pode ser maior que a data atual"
                      value={dataNaturalizacao}
                      onError={(errorMessage) => {
                        setErrors({
                          ...error,
                          dataNaturalizacao: errorMessage,
                        });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.dataNaturalizacao;

                        setDataNaturalizacao(arg1);
                        setDataNaturalizacaoValida(arg2.isValid);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DAutoComplete
                      id="paisNascimento"
                      label="País de nascimento"
                      placeholder="País de nascimento"
                      labelColor="black"
                      // required={true}
                      required={!dadosIncompletos}
                      fullWidth
                      error={error.paisNascimento}
                      helperText={error.paisNascimento}
                      defaultValue={
                        countrys &&
                        countrys.length &&
                        countrys.filter((res) => res.id == paisNascimento)[0]
                      }
                      options={countrys}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        value && option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        delete error.paisNascimento;
                        setPaisNascimento(newValue ? newValue.id : "");
                        setStep3(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid
                item
                md={12}
                className={nacionalidade != 3 ? classes.hide : ""}
              >
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <DInput
                      id="passaporteNumero"
                      label="Número do passaporte"
                      placeholder="Número do passaporte"
                      labelColor="black"
                      required={!dadosIncompletos}
                      // required={true}
                      type="text"
                      error={error.passaporteNumero}
                      helperText={error.passaporteNumero}
                      positionIcon="end"
                      fullWidth={true}
                      inputProps={{ maxLength: 9 }}
                      onChange={(e) => {
                        delete error.passaporteNumero;
                        setPassaporteNumero(e.target.value.replace(/[^0-9a-zA-Z]/g, ''));
                      }}
                      value={passaporteNumero}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="passaportePaisEmissor"
                      label="País emissor do passaporte"
                      placeholder="País emissor do passaporte"
                      fullWidth
                      required={!dadosIncompletos}
                      labelColor="black"
                      error={error.passaportePaisEmissor}
                      helperText={error.passaportePaisEmissor}
                      value={passaportePaisEmissor}
                      options={countrys}
                      onChange={(event) => {
                        delete error.passaportePaisEmissor;
                        setPassaportePaisEmissor(event);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DDateInput
                      id="passaporteDataEmissao"
                      error={error.passaporteDataEmissao}
                      helperText={error.passaporteDataEmissao}
                      fullWidth
                      label={
                        <>
                          {
                            <span style={{ color: "black" }}>
                              Data de emissão do passaporte
                            </span>
                          }{" "}
                          {!dadosIncompletos && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </>
                      }
                      minDate={
                        dataNascimento
                          ? String(dataNascimento)
                              .split("/")
                              .reverse()
                              .join("-")
                          : null
                      }
                      minDateMessage="A data de emissão não pode ser menor que a data de nascimento"
                      maxDate={new Date()}
                      maxDateMessage="A data de emissão não pode ser maior que a data atual"
                      value={passaporteDataEmissao}
                      onError={(errorMessage) => {
                        setErrors({
                          ...error,
                          passaporteDataEmissao: errorMessage,
                        });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.passaporteDataEmissao;

                        setPassaporteDataEmissao(arg1);
                        setPassaporteDataEmissaoValida(arg2.isValid);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DDateInput
                      id="passaporteDataValidade"
                      error={error.passaporteDataValidade}
                      helperText={error.passaporteDataValidade}
                      fullWidth
                      label={
                        <>
                          {
                            <span style={{ color: "black" }}>
                              Data de validade do passaporte
                            </span>
                          }{" "}
                          {!dadosIncompletos && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </>
                      }
                      minDate={
                        dataNascimento
                          ? String(dataNascimento)
                              .split("/")
                              .reverse()
                              .join("-")
                          : null
                      }
                      minDateMessage="A data de validade não pode ser menor que a data de nascimento"
                      value={passaporteDataValidade}
                      onError={(errorMessage) => {
                        setErrors({
                          ...error,
                          passaporteDataValidade: errorMessage,
                        });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.passaporteDataValidade;

                        setPassaporteDataValidade(arg1);
                        setPassaporteDataValidadeValida(arg2.isValid);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} className={!nacionalidade ? classes.hide : ""}>
                <DInput
                  id="nomeMae"
                  label="Nome da mãe"
                  placeholder="Nome da mãe"
                  labelColor="black"
                  type="text"
                  disabled={maeDesconhecida}
                  required={!dadosIncompletos && !maeDesconhecida}
                  inputProps={{ maxLength: 100 }}
                  error={error.nomeMae}
                  helperText={error.nomeMae}
                  positionIcon="end"
                  fullWidth={true}
                  onChange={(e) => {
                    if (e.target.value !== undefined) setStep3(true);

                    delete error.nomeMae;
                    setNomeMae(e.target.value);
                  }}
                  value={nomeMae}
                />

                <FormControlLabel
                  className={classes.identidadeRadioMargin}
                  control={
                    <Checkbox
                      defaultChecked={maeDesconhecida}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={(e) => {
                        setStep3(true);
                        setMaeDesconhecida(!maeDesconhecida);

                        if (!maeDesconhecida) {
                          delete error.nomeMae;
                          setNomeMae("");
                        }
                      }}
                    />
                  }
                  label="Mãe desconhecida"
                />
              </Grid>

              <Grid item md={6} className={!nacionalidade ? classes.hide : ""}>
                <DInput
                  id="nomePai"
                  label="Nome do pai"
                  placeholder="Nome do pai"
                  labelColor="black"
                  type="text"
                  disabled={paiDesconhecido}
                  inputProps={{ maxLength: 100 }}
                  required={!dadosIncompletos && !paiDesconhecido}
                  error={error.nomePai}
                  helperText={error.nomePai}
                  positionIcon="end"
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.nomePai;
                    setNomePai(e.target.value);
                  }}
                  value={nomePai}
                />
                <FormControlLabel
                  className={classes.identidadeRadioMargin}
                  control={
                    <Checkbox
                      defaultChecked={paiDesconhecido}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={(e) => {
                        setPaiDesconhecido(!paiDesconhecido);

                        if (!paiDesconhecido) {
                          delete error.nomePai;
                          setNomePai("");
                        }
                      }}
                    />
                  }
                  label="Pai desconhecido"
                />
              </Grid>

              <Grid
                item
                md={12}
                style={{ display: "flex" }}
                justifyContent="space-between"
              >
                <Typography
                  className="text-bold"
                  variant="h5"
                  style={{ marginTop: "20px", display: "inline-block" }}
                  gutterBottom
                >
                  Cartão Nacional de Saúde
                </Typography>

                <DButton
                  className={`${classes.addCnsButton}`}
                  type="button"
                  variant="primary"
                  size="sm"
                  style={{
                    // backgroundColor: "transparent!important",
                    borderColor: "transparent!important",
                    padding: "16px",
                  }}
                  icon={<AddCircleIcon />}
                  onClick={novoCns}
                >
                  Adicionar novo CNS
                </DButton>
              </Grid>
              <Grid container spacing={6} style={{ margin: "-10px" }}>
                {cns &&
                  cns.map(function (item, i) {
                    return (
                      <Grid item md={6}>
                        <Card
                          key={`cns-component-${i}`}
                          className="bg-light-gray mb-3"
                        >
                          <CardContent style={{ padding: "16px" }}>
                            <Grid
                              item
                              md={12}
                              spacing={3}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "30px",
                                margin: "0px 10px",
                              }}
                            >
                              <>
                                <Grid item md={6}>
                                  <DInput
                                    id="cns"
                                    label="CNS"
                                    placeholder="CNS"
                                    labelColor="black"
                                    // required={true}
                                    required={!dadosIncompletos}
                                    type="text"
                                    inputProps={{ maxLength: 15 }}
                                    error={error.cns && error.cns[i]}
                                    helperText={error.cns && error.cns[i]}
                                    fullWidth={true}
                                    onBlur={(e) => {
                                      const item = cns.filter(c => c == e.target.value);
                                      if (item.length > 1){
                                        let tempCNS = [...cns];                                        
                                        tempCNS[i] = "";
                                        setCns(tempCNS);
                                        toast.warning("Já existe um CNS informado com este número.");
                                        return;
                                      }
                                    }}
                                    onChange={(e) => {
                                      error.cns && delete error.cns[i];

                                      setCns((prevState) => {
                                        let _cns = [...prevState];

                                        _cns[i] = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        );

                                        return _cns;
                                      });
                                    }}
                                    positionIcon="end"
                                    value={cns[i]}
                                  />
                                </Grid>
                                <Grid item md={5}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        value={i}
                                        name="cnsPrincipal"
                                        color="primary"
                                        checkedIcon={<CheckboxChecked />}
                                        icon={<CheckboxUnchecked />}
                                        checked={
                                          cnsPrincipal == i ? true : false
                                        }
                                        onChange={(e) => {
                                          setCnsPrincipal(i);
                                        }}
                                        positionIcon="end"
                                      />
                                    }
                                    label="Definir como principal"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={1}
                                  style={{
                                    margin: "15px 0px",
                                    padding: "0px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      padding: "0px!important",
                                      minWidth: "0px!important",
                                    }}
                                    onClick={(e) => {
                                      delete error.cns;

                                      if(cns?.length == 1){
                                        toast.warning("É necessário ter ao menos um CNS");
                                        return;
                                      }

                                      setCns((prevState) => {
                                        let _cns = [...prevState];

                                        _cns.splice(i, 1);

                                        return _cns;
                                      });                   

                                      if (cnsPrincipal == i) {
                                        setCnsPrincipal(0);
                                      }
                                    }}
                                  >
                                    <DeleteForeverIcon
                                      color="error"
                                      style={{
                                        height: 24,
                                        width: 24,
                                      }}
                                    />
                                  </Button>
                                </Grid>
                              </>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid
                item
                md={12}
                className={sexo != "Feminino" ? classes.hide : ""}
              >
                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  gutterBottom
                >
                  Gravidez
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className={sexo != "Feminino" ? classes.hide : ""}
              >
                <Grid container spacing={3}>
                  <Grid item md={6}>
                    <DInput
                      id="qtdVezesGravidez"
                      label="Quantas vezes já engravidou"
                      placeholder="Quantas vezes já engravidou"
                      labelColor="black"
                      type="text"
                      required={
                        !dadosIncompletos &&
                        (sexo == "Feminino" && idade >= 15 ? true : false)
                      }
                      error={error.qtdVezesGravidez}
                      helperText={error.qtdVezesGravidez}
                      fullWidth
                      onChange={(e) => {
                        delete error.qtdVezesGravidez;

                        if (e.target.value.length <= 2) {
                          setQtdVezesGravidez(
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }
                      }}
                      positionIcon="end"
                      value={qtdVezesGravidez}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DInput
                      id="filhosVivos"
                      label="Filhos nascidos vivos"
                      placeholder="Filhos nascidos vivos"
                      labelColor="black"
                      required={!dadosIncompletos && qtdVezesGravidez > 0}
                      type="text"
                      error={error.filhosVivos}
                      helperText={error.filhosVivos}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.filhosVivos;

                        if (e.target.value.length <= 2) {
                          setFilhosVivos(e.target.value.replace(/[^0-9]/g, ""));
                        }
                      }}
                      positionIcon="end"
                      value={filhosVivos}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DDateInput
                      id="dtUltimaMenstruacao"
                      error={error.dtUltimaMenstruacao}
                      helperText={error.dtUltimaMenstruacao}
                      fullWidth
                      required={!dadosIncompletos && qtdVezesGravidez > 0}
                      label={
                        <>
                          {
                            <span style={{ color: "black" }}>
                              Data da última menstruação
                            </span>
                          }
                        </>
                      }
                      minDate={
                        dataNascimento
                          ? String(dataNascimento)
                              .split("/")
                              .reverse()
                              .join("-")
                          : null
                      }
                      minDateMessage="A data da ultima menstruação não pode ser menor que a data de nascimento"
                      maxDate={new Date()}
                      maxDateMessage="A data da ultima menstruação não pode ser maior que a data atual"
                      value={dtUltimaMenstruacao}
                      onError={(errorMessage) => {
                        setErrors({
                          ...error,
                          dtUltimaMenstruacao: errorMessage,
                        });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.dtUltimaMenstruacao;

                        setDtUltimaMenstruacao(arg1);
                        setDtUltimaMenstruacaoValida(arg2.isValid);
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={teveAborto}
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={(e) => {
                            setTeveAborto(!teveAborto);
                          }}
                        />
                      }
                      label="Já teve algum aborto"
                    />
                  </Grid>
                  <Grid item md={6} className={!teveAborto ? classes.hide : ""}>
                    <DInput
                      id="qtdVezesAborto"
                      label="Quantas vezes já abortou"
                      placeholder="Quantas vezes já abortou"
                      labelColor="black"
                      type="text"
                      required={!dadosIncompletos && teveAborto}
                      error={error.qtdVezesAborto}
                      helperText={error.qtdVezesAborto}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.qtdVezesAborto;

                        if (e.target.value.length <= 2) {
                          setQtdVezesAborto(
                            e.target.value.replace(/[^0-9]/g, "")
                          );
                        }
                      }}
                      positionIcon="end"
                      value={qtdVezesAborto}
                    />
                  </Grid>
                  <Grid item md={6} className={!teveAborto ? classes.hide : ""}>
                    <DDateInput
                      id="dtUltimoAborto"
                      error={error.dtUltimoAborto}
                      helperText={error.dtUltimoAborto}
                      fullWidth
                      required={
                        !dadosIncompletos && teveAborto && qtdVezesAborto > 0
                      }
                      label={
                        <>
                          {
                            <span style={{ color: "black" }}>
                              Data do último aborto
                            </span>
                          }
                        </>
                      }
                      minDate={
                        dataNascimento
                          ? String(dataNascimento)
                              .split("/")
                              .reverse()
                              .join("-")
                          : null
                      }
                      minDateMessage="A data do último abordo não pode ser menor que a data de nascimento"
                      maxDate={new Date()}
                      maxDateMessage="A data do último abordo não pode ser maior que a data atual"
                      value={dtUltimoAborto}
                      onError={(errorMessage) => {
                        setErrors({ ...error, dtUltimoAborto: errorMessage });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.dtUltimoAborto;

                        setDtUltimoAborto(arg1);
                        setDtUltimoAbortoValida(arg2.isValid);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Grid item md={12}></Grid>
                <span
                  onClick={(e) => setExpandMore(!expandMore)}
                  className="text-mt text-regular text-pointer text-primary"
                  style={
                    !expandMore
                      ? {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: 30,
                        }
                      : {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }
                  }
                >
                  {!expandMore
                    ? "Mostrar dados complementares"
                    : "Esconder dados complementares"}
                  &nbsp;&nbsp;&nbsp;
                  {!expandMore ? <ExpandMore /> : <ExpandLess />}
                </span>
              </Grid>
              <Grid
                item
                md={12}
                className={!expandMore ? classes.hide : ""}
                style={!expandMore ? { marginTop: 32 } : {}}
              >
                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  style={{ marginTop: "20px", display: "inline-block" }}
                  gutterBottom
                >
                  Dados complementares
                </Typography>

                <Grid
                  container
                  spacing={1}
                  className="tema-light"
                  justifyContent="flex-start"
                  style={{ margin: "10px 0px 10px 10px" }}
                >
                  <Grid item md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={temDeficiencia}
                          color="primary"
                          inputProps={{ "aria-label": "secondary radio" }}
                          icon={<CircleUnchecked />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                          onClick={(e) => {
                            setTemDeficiencia(!temDeficiencia);

                            if (temDeficiencia) {
                              setDeficiencia([]);
                              delete error.deficiencia;
                            }
                          }}
                        />
                      }
                      label="Pessoa com deficiência"
                    />
                  </Grid>

                  <Grid item md={3}>
                    {temDeficiencia === false ? (
                      <DInput
                        id="deficiencia"
                        label="Deficiências"
                        placeholder="Deficiências"
                        labelColor="black"
                        disabled={true}
                        required={false}
                        type="text"
                        fullWidth={true}
                        onChange={(e) => null}
                        positionIcon="end"
                        value={null}
                      />
                    ) : (
                      <DMultAutoComplete
                        id="deficiencia"
                        label="Deficiências"
                        placeholder="Deficiências"
                        disabled={!temDeficiencia}
                        labelColor="black"
                        error={error.deficiencia}
                        helperText={error.deficiencia}
                        required={temDeficiencia}
                        options={deficienciasMap}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        inputProps={{ onChange: (ev) => console.log(ev) }}
                        onChange={(event, newValue) => {
                          delete error.deficiencia;
                          setDeficiencia(newValue);
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item md={3}>
                    <DInput
                      id="numeroPis"
                      label="Número do NIT-PIS-PASEP"
                      placeholder="Número do NIT-PIS-PASEP"
                      labelColor="black"
                      type="text"
                      error={error.numeroPis}
                      helperText={error.numeroPis}
                      positionIcon="end"
                      fullWidth={true}
                      value={numeroPis}
                      onChange={(e) => {
                        delete error.numeroPis;
                        setNumeroPis(mascaraPIS(e.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="populacaoEspec"
                      label="População específica"
                      placeholder="População específica"
                      fullWidth
                      labelColor="black"
                      error={error.populacaoEspec}
                      helperText={error.populacaoEspec}
                      labelClass={classes.labelBlack}
                      value={populacaoEspec}
                      options={populacaoEspecMap}
                      onChange={(event) => {
                        delete error.populacaoEspec;
                        setPopulacaoEspec(event);
                      }}
                    />
                  </Grid>
                </Grid>

                <Typography
                  className="text-primary text-bold"
                  variant="h5"
                  style={{ marginTop: "20px", display: "inline-block" }}
                  gutterBottom
                >
                  Responsável legal
                </Typography>

                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <DInput
                      id="responsavelLegal"
                      label="Responsável legal"
                      placeholder="Responsável legal"
                      labelColor="black"
                      type="text"
                      required={!dadosIncompletos && idade < 18}
                      error={error.responsavelLegal}
                      inputProps={{ maxLength: 100 }}
                      helperText={error.responsavelLegal}
                      positionIcon="end"
                      fullWidth={true}
                      value={responsavelLegal}
                      onChange={(e) => {
                        delete error.responsavelLegal;
                        setResponsavelLegal(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {relationshipDegrees != null && (
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="grauDeRelacionamento"
                        label="Grau de relacionamento"
                        placeholder="Grau de relacionamento"
                        fullWidth
                        labelColor="black"
                        required={!dadosIncompletos && idade < 18}
                        error={error.grauDeRelacionamento}
                        helperText={error.grauDeRelacionamento}
                        labelClass={classes.labelBlack}
                        value={grauDeRelacionamento}
                        options={relationshipDegrees}
                        onChange={(event) => {
                          delete error.grauDeRelacionamento;
                          setGrauDeRelacionamento(event);
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <DInput
                      id="responsavelLegalDocumento"
                      label="Número de identidade"
                      placeholder="Número de identidade"
                      labelColor="black"
                      type="text"
                      required={!dadosIncompletos && idade < 18}
                      error={error.responsavelLegalDocumento}
                      inputProps={{ maxLength: 100 }}
                      helperText={error.responsavelLegalDocumento}
                      positionIcon="end"
                      fullWidth={true}
                      value={responsavelLegalDocumento}
                      onChange={(e) => {
                        delete error.responsavelLegalDocumento;
                        setResponsavelLegalDocumento(mascaraRG(e.target.value));
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <DDateInput
                      id="responsavelLegalDocumentoEmissao"
                      error={error.responsavelLegalDocumentoEmissao}
                      helperText={error.responsavelLegalDocumentoEmissao}
                      fullWidth
                      required={
                        !dadosIncompletos &&
                        (idade < 18 || temDeficienciaIntelectual)
                      }
                      labelColor="black"
                      label="Data de emissão"
                      maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                      maxDateMessage="A data de emissão não pode ser maior que a data atual"
                      value={responsavelLegalDocumentoEmissao}
                      onError={(errorMessage) => {
                        setErrors({
                          ...error,
                          responsavelLegalDocumentoEmissao: errorMessage,
                        });
                      }}
                      onChange={(arg1, arg2, e) => {
                        delete error.responsavelLegalDocumentoEmissao;
                        setResponsavelEmissaoValida(arg2.isValid);
                        setResponsavelLegalDocumentoEmissao(arg1);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Contato */}
            <Grid
              container
              className={`tema-light ${classes.root} ${
                step !== 1 ? classes.hide : ""
              }`}
              spacing={3}
            >
              <Grid item md={12}>
                <Grid
                  item
                  md={12}
                  style={{ display: "flex" }}
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                    style={{ marginTop: 20 }}
                  >
                    Telefones
                  </Typography>

                  <DButton
                    className={`${classes.addCnsButton}`}
                    type="button"
                    variant="primary"
                    size="sm"
                    style={{
                      // backgroundColor: "transparent!important",
                      borderColor: "transparent!important",
                      padding: "4px 29px 4px 29px!important",
                      fontSize: "14px!important",
                    }}
                    icon={
                      <AddCircleIcon
                        style={{ width: "15px", height: "15px" }}
                      />
                    }
                    onClick={novoTelefone}
                  >
                    Adicionar telefone
                  </DButton>
                </Grid>

                <Grid container spacing={3}>
                  {telefones &&
                    Array.isArray(telefones) &&
                    telefones.map((item, i) => {
                      return (
                        <>
                          <Grid item md={5} spacing={3}>
                            <Card
                              key={`telefones-component-${i}`}
                              className="bg-light-gray mb-3"
                            >
                              <CardContent
                                style={{
                                  padding: "16px 27.5px",
                                  display: "flex",
                                  justifyContent: "spaceBetween",
                                  alignItems: "center",
                                  alignContent: "spaceBetween",
                                  gap: 10,
                                }}
                              >
                                {telefones.length === 1 ? (
                                  <>
                                    <Grid item md={4}>
                                      <DInput
                                        id="telefones"
                                        label="Telefone"
                                        placeholder="Telefone"
                                        labelColor="black"
                                        // required={true}
                                        required={!dadosIncompletos}
                                        type="text"
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].phone
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].phone
                                        }
                                        fullWidth={true}
                                        onChange={(e) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];

                                          new_telefone[i].phone = mascaraNumero(
                                            e.target.value
                                          );

                                          setTelefones(new_telefone);
                                        }}
                                        positionIcon="end"
                                        value={telefones[i].phone}
                                      />
                                    </Grid>
                                    <Grid item md={4}>
                                      <DDropDownSimple
                                        primaryColorSelectIcon
                                        id={`tipo-telefone-${i}`}
                                        label="Tipo de telefone"
                                        placeholder="Tipo de telefone"
                                        fullWidth
                                        labelColor="black"
                                        required={!dadosIncompletos}
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].type
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].type
                                        }
                                        labelClass={classes.labelBlack}
                                        value={
                                          telefones[i] && telefones[i].type
                                        }
                                        options={tiposTelefoneMap}
                                        onChange={(event) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];
                                          new_telefone[i].type = event;
                                          setTelefones(new_telefone);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item md={4}>
                                      <DInput
                                        id={`apelido-telefone-${i}`}
                                        label="Apelido"
                                        placeholder="Apelido"
                                        inputProps={{ maxLength: 10 }}
                                        labelColor="black"
                                        // required={true}
                                        required={!dadosIncompletos}
                                        type="text"
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].name
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].name
                                        }
                                        fullWidth={true}
                                        onChange={(e) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];
                                          new_telefone[i].name = e.target.value;
                                          setTelefones(new_telefone);
                                        }}
                                        positionIcon="end"
                                        value={
                                          telefones[i] && telefones[i].name
                                        }
                                      />
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid item md={4}>
                                      <DInput
                                        id="telefones"
                                        label="Telefone"
                                        placeholder="Telefone"
                                        labelColor="black"
                                        required={!dadosIncompletos}
                                        type="text"
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].phone
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].phone
                                        }
                                        fullWidth={true}
                                        onChange={(e) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];
                                          new_telefone[i].phone = mascaraNumero(
                                            e.target.value
                                          );
                                          setTelefones(new_telefone);
                                        }}
                                        positionIcon="end"
                                        value={telefones[i].phone}
                                      />
                                    </Grid>

                                    <Grid item md={4}>
                                      <DDropDownSimple
                                        primaryColorSelectIcon
                                        id={`tipo-telefone-${i}`}
                                        label="Tipo de telefone"
                                        placeholder="Tipo de telefone"
                                        fullWidth
                                        fixedLabel
                                        labelColor="black"
                                        required={!dadosIncompletos}
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].type
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].type
                                        }
                                        labelClass={classes.labelBlack}
                                        value={
                                          telefones[i] && telefones[i].type
                                        }
                                        options={tiposTelefoneMap}
                                        onChange={(event) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];
                                          new_telefone[i].type = event;
                                          setTelefones(new_telefone);
                                        }}
                                      />
                                    </Grid>
                                    <Grid item md={3}>
                                      <DInput
                                        id={`apelido-telefone-${i}`}
                                        label="Apelido"
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="Apelido"
                                        labelColor="black"
                                        fixedLabel
                                        required={!dadosIncompletos}
                                        type="text"
                                        error={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].name
                                        }
                                        helperText={
                                          error.telefones &&
                                          error.telefones[i] &&
                                          error.telefones[i].name
                                        }
                                        fullWidth={true}
                                        onChange={(e) => {
                                          delete error.telefones;
                                          var new_telefone = [...telefones];
                                          new_telefone[i].name = e.target.value;
                                          setTelefones(new_telefone);
                                        }}
                                        positionIcon="end"
                                        value={telefones && telefones[i].name}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={1}
                                      style={{
                                        margin: "15px 0px",
                                        padding: "0px",
                                      }}
                                    >
                                      <Button
                                        onClick={(e) => {
                                          delete error.telefones;

                                          var new_telefone = [...telefones];
                                          var new_qtd_telefones = [
                                            ...qtdTelefones,
                                          ];

                                          new_telefone.splice(i, 1);
                                          new_qtd_telefones.splice(i, 1);

                                          setTelefones(new_telefone);
                                          setQtdTelefones(new_qtd_telefones);
                                        }}
                                      >
                                        <DeleteForeverIcon
                                          color="error"
                                          style={{
                                            height: "24px",
                                            width: "24px",
                                          }}
                                        />
                                      </Button>
                                    </Grid>
                                  </>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>

                          {i % 2 === 0 && i !== 1 && (
                            <Grid item md={2}>
                              <Divider
                                style={{ margin: "0px 50%" }}
                                orientation="vertical"
                              />
                            </Grid>
                          )}
                        </>
                      );
                    })}
                </Grid>

                <Grid item md={12}>
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Unidades
                  </Typography>
                </Grid>
                <Grid item md={12} className={classes.marginDefault}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <DAutoComplete
                        id="unidadeRef"
                        label="Unidade de referência"
                        placeholder="Unidade de referência"
                        labelColor="black"
                        fullWidth={true}
                        error={error.unidadeRef}
                        helperText={error.unidadeRef}
                        defaultValue={
                          unidadesRef.filter((res) => res.id == unidadeRef)[0]
                        }
                        options={unidadesRef}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.unidadeRef;
                          setUnidadeRef(newValue ? newValue.id : "");
                        }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <DMultAutoComplete
                        id="unidadeVinculada"
                        label="Unidades vinculadas ao paciente"
                        placeholder="Unidades vinculadas ao paciente"
                        labelColor="black"
                        error={error.unidadeVinculada}
                        helperText={error.unidadeVinculada}
                        defaultValue={
                          unidadesVinculadas.filter(
                            (res) => res.id == unidadeVinculada
                          )[0]
                        }
                        options={unidadesVinculadas}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.unidadeVinculada;

                          setUnidadeVinculada(newValue);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Endereço */}
            <Grid
              container
              className={`tema-light ${classes.root} ${
                step !== 2 ? classes.hide : ""
              }`}
              spacing={3}
            >
              <Grid item md={12}>
                <Grid item md={12} style={{ margin: "15px 0px" }}>
                  {activeMedicalUnit &&
                    activeMedicalUnit.medicalUnit &&
                    nacionalidade != 3 && (
                      <UnidadeCard>
                        <div>
                          <span>
                            Unidade{" "}
                            {activeMedicalUnit.medicalUnit.medical_unit_type ||
                              "-"}
                          </span>
                          <span>
                            <b>
                              {activeMedicalUnit.medicalUnit.business_name ||
                                "-"}
                            </b>
                          </span>
                        </div>
                        <div>
                          <span>CNES</span>
                          <span>
                            {activeMedicalUnit.medicalUnit.cnes || "-"}
                          </span>
                        </div>
                        <div>
                          <span>Telefone</span>
                          <span>
                            {activeMedicalUnit.medicalUnit.phone_number
                              ? mascaraNumero(
                                  activeMedicalUnit.medicalUnit.phone_number
                                )
                              : "-"}
                          </span>
                        </div>
                      </UnidadeCard>
                    )}
                </Grid>
                <Grid item md={12}>
                  <Typography
                    className="text-primary text-bold"
                    variant="h5"
                    gutterBottom
                  >
                    Endereço{" "}
                    {nacionalidade === 3
                      ? "residencial estrangeiro"
                      : activeMedicalUnit &&
                        dadosIncompletos &&
                        activeMedicalUnit.address
                      ? "da unidade de saúde"
                      : ""}
                  </Typography>
                  <Typography variant="p" gutterBottom>
                    &nbsp;
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  {countrys.length > 0 && nacionalidade === 3 && (
                    <Grid item md={12}>
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="paisNascimento"
                        label="País de nascimento"
                        placeholder="País de nascimento"
                        fullWidth
                        labelColor="black"
                        required={!dadosIncompletos || nacionalidade == 3}
                        error={error.paisNascimento}
                        helperText={error.paisNascimento}
                        labelClass={classes.labelBlack}
                        value={paisNascimento}
                        options={countrys}
                        onChange={(event) => {
                          delete error.paisNascimento;
                          setPaisNascimento(event);
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item md={2} xs={2}>
                        <a
                          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                          style={{ fontSize: 14 }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-mt text-regular text-link"
                        >
                          Não sei o CEP
                        </a>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <DInput
                          id="cep"
                          label="CEP"
                          placeholder="CEP"
                          labelColor="black"
                          required={nacionalidade != 3}
                          type="text"
                          error={error.cep}
                          helperText={error.cep}
                          positionIcon="end"
                          fullWidth={true}
                          onChange={(e) => {
                            delete error.cep;
                            setCep(mascaraCEP(e.target.value));

                            if (e.target.value.length >= 10) {
                              getCep(e.target.value)
                                .then((res) => {
                                  if (res.erro) {
                                    var new_erro = {};
                                    new_erro.cep = "CEP inexistente";
                                    setErrors(new_erro);

                                    setBairro("");
                                    setEndereco("");
                                    setEstado("");
                                    setMunicipio("");
                                    setIdMunicipio("");
                                  } else {
                                    var municipioSelec = citys.filter(
                                      (city) =>
                                        city.value ==
                                        removeAccents(
                                          res.localidade.toUpperCase()
                                        )
                                    )[0];

                                    if (municipioSelec.id) {
                                      setBairro(res.bairro);
                                      setEndereco(res.logradouro);
                                      setEstado(res.uf);
                                      setMunicipio(res.localidade);
                                      setIdMunicipio(municipioSelec.id);
                                    }
                                  }
                                })
                                .catch(console.error);
                            }
                          }}
                          value={cep}
                        />
                      </Grid>
                      <Grid item md={5} xs={5}>
                        {/* <DInput
                          id="logradouro"
                          label="Logradouro"
                          placeholder="Logradouro"
                          labelColor="black"
                          type="text"
                          error={error.logradouro}
                          helperText={error.logradouro}
                          positionIcon="end"
                          fullWidth={true}
                          onChange={(e) => {
                            delete error.logradouro;
                            setLogradouro(e.target.value);
                          }}
                          value={endereco}
                        /> */}

                        <DDropDownSimple
                          primaryColorSelectIcon
                          id="logradouro"
                          label="Logradouro"
                          placeholder="Logradouro"
                          fullWidth
                          labelColor="black"
                          required={!dadosIncompletos && nacionalidade != 3}
                          error={error.logradouro}
                          helperText={error.logradouro}
                          value={logradouro}
                          options={logradourosMap}
                          onChange={(event) => {
                            delete error.logradouro;
                            setLogradouro(event);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <DInput
                      id="endereco"
                      label="Endereço"
                      placeholder="Endereço"
                      required={!dadosIncompletos && nacionalidade != 3}
                      labelColor="black"
                      type="text"
                      error={error.endereco}
                      helperText={error.endereco}
                      inputProps={{ maxLength: 60 }}
                      positionIcon="end"
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.endereco;
                        setEndereco(e.target.value);
                      }}
                      value={endereco}
                    />
                  </Grid>

                  <Grid item md={4} xs={4}>
                    <DInput
                      id="bairro"
                      label="Bairro"
                      placeholder="Bairro"
                      labelColor="black"
                      required={!dadosIncompletos && nacionalidade != 3}
                      type="text"
                      positionIcon="end"
                      error={error.bairro}
                      helperText={error.bairro}
                      inputProps={{ maxLength: 30 }}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.bairro;
                        setBairro(e.target.value);
                      }}
                      value={bairro}
                    />
                  </Grid>

                  <Grid item md={2} xs={2}>
                    <DInput
                      id="numero"
                      label="Número"
                      placeholder="Número"
                      labelColor="black"
                      required={!dadosIncompletos && nacionalidade != 3}
                      // required={!dadosIncompletos}
                      type="text"
                      error={error.numero}
                      positionIcon="end"
                      helperText={error.numero}
                      inputProps={{ maxLength: 8 }}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.numero;

                        setNumero(e.target.value);
                      }}
                      value={numero}
                    />
                  </Grid>

                  <Grid item md={5}>
                    <DInput
                      id="complemento"
                      label="Complemento"
                      placeholder="Complemento"
                      // required={!dadosIncompletos && nacionalidade != 3}
                      labelColor="black"
                      type="text"
                      // error={error.complemento}
                      // helperText={error.complemento}
                      positionIcon="end"
                      fullWidth={true}
                      inputProps={{ maxLength: 10 }}
                      onChange={(e) => {
                        setComplemento(e.target.value);
                      }}
                      value={complemento}
                    />
                  </Grid>

                  <Grid item md={4}>
                    <DDropDownSimple
                      primaryColorSelectIcon
                      id="estado"
                      label="Estado"
                      placeholder="Estado"
                      fullWidth
                      labelColor="black"
                      required={!dadosIncompletos && nacionalidade != 3}
                      error={error.estado}
                      helperText={error.estado}
                      value={estado}
                      options={states}
                      onChange={(event) => {
                        delete error.estado;                      
                        setEstado(event);
                      }}
                    />
                  </Grid>

                  {municipiosByEstadoEndereco && (
                    <Grid item md={3}>
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="municipio"
                        label="Município"
                        placeholder="Município"
                        fullWidth
                        labelColor="black"
                        required={!dadosIncompletos && nacionalidade != 3}
                        error={error.municipio}
                        helperText={error.municipio}
                        value={municipio}
                        options={municipiosByEstadoEndereco}
                        onChange={(event) => {
                          delete error.municipio;
                          setMunicipio(event);
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item md={12} xs={12}>
                    <hr
                      style={{
                        backgroundColor: "#7795E4",
                        color: "#7795E4",
                        borderColor: "#7795E4",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            className={`${classes.root}`}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={12} style={{ textAlign: "center" }}>
              <CircularProgress size={15} color="inherit" />
              <Typography variant="body1" className="text-mt text-regular">
                Carregando formulário
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          spacing={1}
          className="tema-light"
          justifyContent="flex-start"
          style={{ margin: "35px 0px 0px" }}
        >
          <Grid item md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={dadosIncompletos}
                  color="primary"
                  inputProps={{ "aria-label": "secondary radio" }}
                  icon={<CircleUnchecked />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  onClick={(e) => {
                    setDadosIncompletos(!dadosIncompletos);

                    if (!dadosIncompletos) {
                      setStep2(true);
                      setStep3(true);
                      setStep4(true);

                      if (activeMedicalUnit && activeMedicalUnit.address) {
                        const { number, zip_code } = activeMedicalUnit.address;

                        if (nacionalidade == 3) {
                          setCep("");
                          setNumero("");
                        } else {
                          if (cep === "") {
                            setCep(mascaraCEP(zip_code));
                          }

                          if (numero === "") {
                            setNumero(number);
                          }
                        }
                      }
                    } else {
                      delete error.justificativaDadoIncompleto;
                    }
                  }}
                />
              }
              label="Dados incompletos"
            />
          </Grid>

          <Grid item md={3}>
            <DDropDownSimple
              primaryColorSelectIcon
              id="justificativaDadoIncompleto"
              label="Motivo"
              placeholder="Motivo"
              fullWidth
              labelColor="black"
              required={dadosIncompletos}
              error={error.justificativaDadoIncompleto}
              helperText={error.justificativaDadoIncompleto}
              value={justificativaDadoIncompleto}
              options={justificativaDadosIncompletosMap}
              onChange={(event) => {
                delete error.justificativaDadoIncompleto;
                setJustificativaDadoIncompleto(event);
              }}
            />
          </Grid>
        </Grid>

        {/* Finalização cadastro */}
        {/* <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: 300, wordBreak: "break-word" }}
      >
        <Grid item className="text-center">
          <CheckCircleOutline
            className="text-success"
            style={{ fontSize: 64 }}
          />
          <Typography
            className="text-mt text-regular"
            style={{ color: "#2A814E", wordBreak: "break-word" }}
          >
            Cadastro do paciente efetuado com sucesso
          </Typography>
          <Typography variant="p" gutterBottom>
            &nbsp;
          </Typography>
          <Typography
            className="text-regular"
            variant="body2"
            style={{ wordBreak: "break-word" }}
          >
            {nome}
          </Typography>
        </Grid>
      </Grid> */}
      </Grid>
      <Grid
        container
        spacing={1}
        className="tema-light"
        justifyContent="flex-end"
        style={{ marginBottom: "-140px", marginTop: 50, paddingBottom: 50 }}
      >
        <Grid item>
          <DButton
            disabled={loading}
            className={`${step === 0 ? classes.hide : classes.buttonModal}`}
            type="button"
            variant="secondary"
            size="sm"
            onClick={(e) => setStep(step - 1)}
          >
            Voltar
          </DButton>
        </Grid>
        <Grid item>
          <DButton
            disabled={loading || !componentDepsLoaded}
            multiClass={
              step === 2
                ? `btn-success ${classes.buttonModal}`
                : classes.buttonModal
            }
            variant="primary"
            size="sm"
            onClick={(e) => {
              var dados = getDados();

              if (validarFormulario({ ...dados })) {
                if (step === 2) {
                  criarPaciente(dados);
                } else {
                  onStep(step + 1);
                }
              }
            }}
          >
            {loading ? (
              <CircularProgress size={15} color="inherit" />
            ) : (
              <> {step === 2 ? "Finalizar cadastro" : "Avançar"} </>
            )}
          </DButton>
        </Grid>
      </Grid>
    </>
  );
}

export default PacienteCreate;
