import {useState, useContext, useEffect} from "react";
import {Typography, Container, Card, CardContent, Grid, CircularProgress, Backdrop} from "@material-ui/core";
import DCard from "./../../../../shared/DCard";
import FormCard from "./components/FormCard";
import DContainer from "./../../../../shared/DContainer"
import {makeStyles, withStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    background: { 
        paddingTop: 40, 
        background: theme.palette.background.snow
    }
}))

function FormsGrid(props)
{

    const classes = useStyles();

    return (
        <DContainer className={classes.background}>
            { props.forms.length === 0 ?
                <DCard>
                    <CardContent>
                        <Typography variant="body1">Ainda não há formulários cadastrados</Typography>
                    </CardContent>
                </DCard>
                :
                props.forms.map(function(item, index){
                    return (
                        <FormCard key={`form-card-${index}`} name={item.name} status={ item.form ? item.form.status : '-' } pendenteAprovacao={item.form ? item.form.pending_approval : '-'} onAction={ action => {
                            switch (action) {
                                case 'approved':
                                    props.onAction({ formId: item.form_id, action });                                    
                                    break;                            
                                default:
                                    props.onAction({ formId: item.id, action });
                                    break;
                            }
                        } } />
                    )
                })
             }
        </DContainer>
    )
}

export default FormsGrid;