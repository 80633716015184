import {Component} from "react";
import Unidades from './Unidades';
import Especialidades from "./Especialidades";
import Grupos from './Grupos';
import Perfis from './Perfis';
import Comorbidades from './Comorbidades';
import Sintomas from './Sintomas';
import Linhas from './Linhas';
import Forms from './Forms';
import Faq from '../Faq/Faq';
import Biomedidas from './Biomedidas';
import {getMe} from "../../api/user";
import {Redirect} from "react-router-dom";
import Gravacoes from "./Gravacoes";
import Ligacoes from "./Ligacoes";
import Temas from "./Temas";
import EmailConfig from "../EmailConfig/EmailConfig";
import TermosUso from "../TermosUso";

class Configuracao extends Component {

    constructor(props) {
        super(props);

        this.state = {
            login: false
        }
    }

    componentDidMount() {
        getMe(localStorage.getItem('token')).then(res => {
            if(res.error)
                this.setState({ login: true });
            // else
            //     console.log(res);
        })
        .catch(err => console.log(err))
    } 

    render() {

        return (
            <>
                { this.state.login ? <Redirect to="/" /> : '' }
                { this.props.active === 'unidades' && <Unidades /> }
                { this.props.active === 'especialidades' && <Especialidades /> }
                { this.props.active === 'linhas' && <Linhas /> }
                { this.props.active === 'grupos' && <Grupos /> }
                { this.props.active === 'perfis' && <Perfis /> }
                { this.props.active === 'comorbidades' && <Comorbidades /> }
                { this.props.active === 'sintomas' && <Sintomas /> }
                { this.props.active === 'forms' && <Forms /> }
                { this.props.active === 'faq' && <Faq /> } 
                { this.props.active === 'email' && <EmailConfig /> } 
                { this.props.active === 'biomedidas' && <Biomedidas /> }
                { this.props.active === 'gravacoes' && <Gravacoes /> }
                { this.props.active === 'ligacoes' && <Ligacoes /> }
                { this.props.active === 'temas' && <Temas /> }
                { this.props.active === 'termosUso' && <TermosUso /> }

            </>
        )
    }
}

export default Configuracao;