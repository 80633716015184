import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { Person, Save } from "@material-ui/icons";
import { Clear } from "@material-ui/icons";
import { Box } from "@mui/system";
import DInput from "../../../../shared/DInput";
import Input from '@material-ui/core/Input';
import DAutoComplete from "../../../../shared/DAutoComplete";
import DRadio from "../../../../shared/DRadio";
import { getAllUnidades } from "../../../../../api/unidades";
import DButton from "../../../../shared/DButton";
import useError from "../../../../../hooks/useError";
import { required } from "../../../../../helpers/validacoes";
import construtor from "../../../../../api/construtor";
import CBO from "../../../../../api/cbo";
import DMultAutoComplete from "../../../../shared/DMultAutoComplete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LineCares from "../../../../../api/lineCare"
import { getAllMedicalSpecialties } from "../../../../../api/medicalSpecialty";
import { getSIGTAP } from "../../../../../api/diagnosticHypothesis";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 590,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  medicoescard: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.cloud,
    padding: "10px",
    borderRadius: "6px",
    boxShadow: "2px 4px 1px -2px rgba(0, 0, 0, 0.27)",
  },
  medicoescards: {
    width: 540,
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  },
  displayFaixaEtaria: {
    display: "flex",
    alignItems: "center"
  }
}));

export default function FormsCreateModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [name, setName] = useState("");
  const [typeAttendance, setTypeAttendance] = useState("");
  const [consultationForm, setConsultationForm] = useState();
  const [medicalUnits, setMedicalUnits] = useState([]); //list de unidades
  const [medicalUnit, setMedicalUnit] = useState([]); //unidade selecionada
  const [cbo, setCBO] = useState(); //CBO selecionado
  const [cbos, setCBOs] = useState([]); //list de CBOS
  const [specialty, setSpecialty] = useState(); //especialidade selecionado
  const [specialties, setSpecialties] = useState([]); //list de especialidades
  const [ageGroupInit, setAgeGroupInit] = useState(null); 
  const [ageGroupEnd, setAgeGroupEnd] = useState(null); 
  const [sex, setSex] = useState(null); 
  const [sexes, setSexes] = useState([{id: 1, description: 'Ambos'}, {id: 2, description: 'Masculino'}, {id: 3, description: 'Feminino'}]); //list de especialidades
  const [lineOfCares, setLineOfCares] = useState([]);
  const [lineOfCare, setLineOfCare] = useState([]);
  const [lineOfCareCheck, setLineOfCareCheck] = useState();
  const [procedure, setProcedure] = useState(null); 
  const [procedures, setProcedures] = useState([]); 
  
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const notify = {
    form: {
      create: () => toast.success("Formulário criado com sucesso!"),
      error: () => toast.error("Erro ao criar o formulário!"),
    },
  };

  useEffect(function () {
    getAllUnidades(localStorage.getItem("token")).then((res) => {
      if (!res.error && res.length > 0) {
        setMedicalUnits(res.filter(item => item.medical_unit_type === 'Assistencial'));
      }
    });

    CBO.get(localStorage.getItem("token")).then((res) => {
      if (res.status) {
        setCBOs(res.cbo);
      }
    });

    LineCares.listLineCare(localStorage.getItem("token"))
      .then(res => {
        if(res.status){
          setLineOfCares(res.lineCare.data.map(x => {
            return {id: x.id, value: x.name, active: x.active}
          }));
        }
      });

    getAllMedicalSpecialties(localStorage.getItem('token')).then(res => {
      if (res && res.length) {
          setSpecialties(res);
      }
    });
    setLoadingSearch(true);
    getSIGTAP(localStorage.getItem("token"))
        .then((res) => {
           if (res.status) {
            setLoadingSearch(false);
            setProcedures(
              res.sigtap.map((item) => {
                return {
                  id: item.id,
                  code: item.cd_procedimento_sus,
                  value: `(${item.cd_procedimento_sus}) ${item.ds_procedimento}`,
                };
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
      
  }, []);

  function getAllForms(){
    construtor.getList(localStorage.getItem('token')).then(res => {
        if(res.status)
            props.forms(res.anamnese_forms.data)
    });
  }

  const cbosMap = cbos.map((item) => {
    return {
      id: item.id,
      code: item.code,
      value: `(${item.code}) ${item.description}`,
    };
  });
  
  let medicalUnitArray = []
  let lineOfCareArray = []

  const medicalUnitsMap = medicalUnit.map((item) => {
    medicalUnitArray.push( item.id);
  });

  if(lineOfCare.length > 0) {
    const lineOfCaresMap = lineOfCare.map((item) => {
      lineOfCareArray.push( item.id);
    });
  }

  function closeModal() {
    setName("");
    setCBO('');
    setLineOfCareCheck('');
    setMedicalUnit([]);
    setTypeAttendance('');
    setConsultationForm('');

    props.onClose(true);
  }

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {

    var validators = {
      name: required,
      cbo: required,
      typeAttendance: required,
      consultationForm: required,
      lineOfCare: required,
      lineOfCareCheck: required
    };

    if (lineOfCareCheck == "1") {
      validators.lineOfCare = required;
    }
   
    return validators;

  }

  function getData() {
    var dados = {
      name,
      cbo,
      medicalUnit,
      specialty,
      lineOfCareCheck,
      lineOfCare,
      lineOfCareArray,
      typeAttendance,
      consultationForm,
      procedure,
      sex,
      ageGroupInit,
      ageGroupEnd
    };

    return dados;
  }

  function createForm(dados) {
    
    var dadosPost = {
      name: dados.name,
      cbo_id: parseInt(dados.cbo),
      medical_speciality_id: dados.specialty,
      type_service: dados.typeAttendance,
      line_care: lineOfCareCheck,
      array_medical_unit: medicalUnitArray,
      array_line_care: lineOfCareArray,
      consultation_form: parseInt(dados.consultationForm),
      incomplete_data: 0,
      procedure_id: dados.procedure,
      active: 1,
      sex: dados.sex,
      age_group_init: dados.ageGroupInit,
      age_group_end: dados.ageGroupEnd      
    };

    setLoading(true);

    construtor
      .createForm(localStorage.getItem("token"), dadosPost)
      .then((res) => {
        if (res.status) {
          notify.form.create();

          getAllForms();

          closeModal();
          setLoading(false)
        } else if (res.details.name){
          toast.warning("Já existe um formulário com este nome.");
          setLoading(false);
          return;
  
        } else {
          notify.form.error();
        }
      }).catch(err => {
        
        notify.form.error();
        setLoading(false)
        
    })
    
  }

  const body = (
    <form
      autoComplete="off"
      onSubmit={(form) => {
        form.preventDefault();

        var dados = getData();

        if (lineOfCareCheck == 0) {
          dados.lineOfCare = [1];
        }
        if (validarFormulario({ ...dados })) {
          createForm(dados);
        } else {
          console.log(error);
          toast.error("Erro: não foi possível inserir o registro.");
        }
      }}
    >
     
          <div style={modalStyle} className={`${classes.paper}`}>
            <>
              <div className={classes.headermodal}>
                <h2
                  className="text-primary MuiTypography-h6 text-mt text-bold"
                  style={{ alignItems: "center", display: "flex" }}
                >
                  Novo Formulário
                </h2>
                <Clear
                  className={classes.clearClass}
                  onClick={(e) => {
                    // props.onClose(true);
                    closeModal();
                  }}
                />
              </div>
              <Grid container justifyContent="center" className="tema-light">
              {loadingSearch ? (
                  <>
                    <CircularProgress size={15} color="inherit" /> Carregando...
                  </>
                ) : (
                <>
                  <Box style={{ marginTop: 5, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Nome</strong>
                        <DInput
                          id="nome-form"
                          label="Nome"
                          labelColor="black"
                          placeholder="Nome"
                          type="text"
                          inputProps={{ maxLength: 100 }}
                          error={error.name}
                          helperText={error.name}
                          fullWidth={true}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          value={name}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Ocupação que utilizará o formulário</strong>
                        {cbosMap ? (
                          <DAutoComplete
                            id="cbo-form"
                            label="Selecione o CBO"
                            placeholder=""
                            labelColor="black"
                            error={error.cbo}
                            helperText={error.cbo}
                            fullWidth={true}
                            options={cbosMap}
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              delete error.cbo;
                              setCBO(newValue ? newValue.id : "");
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Procedimento</strong>
                        {procedures ? (
                          <DAutoComplete
                            id="cbo-form"
                            label="Selecione o Procedimento"
                            placeholder=""
                            labelColor="black"
                            disabled={procedures.length == 0 ? "true" : null}
                            error={error.procedure}
                            helperText={error.procedure}
                            fullWidth={true}
                            options={procedures}
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              delete error.procedure;
                              setProcedure(newValue ? newValue.id : "");
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Unidades executantes</strong>
                        {medicalUnits ? (
                          <DMultAutoComplete
                            label="Selecione as unidades"
                            labelColor="black"
                            error={error.medicalUnit}
                            helperText={error.medicalUnit}
                            options={medicalUnits.map(function (item) {
                              return { id: item.id, value: item.company_name };
                            })}
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              // delete error.medicalUnit;
                              setMedicalUnit(newValue);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Especialidade</strong>
                        {specialties ? (
                          <DAutoComplete
                            id="cbo-form"
                            label="Selecione a especialidade"
                            placeholder=""
                            labelColor="black"
                            error={error.specialty}
                            helperText={error.specialty}
                            fullWidth={true}
                            options={specialties}
                            getOptionLabel={(option) => option.description}
                            getOptionSelected={(option, value) =>
                              value && option.id === value.id
                            }
                            onChange={(event, newValue) => {
                              delete error.specialty;
                              setSpecialty(newValue ? newValue.id : "");
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Sexo</strong>
                        <DAutoComplete
                          id="sex-form"
                          label="Selecione o sexo"
                          placeholder=""
                          labelColor="black"
                          error={error.sex}
                          helperText={error.sex}
                          fullWidth={true}
                          value={
                            sex && sexes.filter((res) => res.id === 1)
                              ? sexes.filter((res) => res.id === sex)[0]
                              : ""
                          }
                          options={sexes}
                          getOptionLabel={(option) => option.description}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.sex;
                            setSex(newValue ? newValue.id : "");
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Faixa Etária</strong>
                        <Box className={classes.displayFaixaEtaria}>
                          <Grid item md={6}>
                            <Input
                              id="ageGroupInit"
                              label="Idade Inicial"
                              placeholder="Idade Inicial"
                              onChange={(e) => {
                                const val = /^[0-9]*$/.test(e.target.value);
                                if (val) {
                                  setAgeGroupInit(e.target.value);
                                }
                              }}
                              value={ageGroupInit}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <Input
                              id="age_group_end"
                              label="Idade Final"
                              placeholder="Idade Final"
                              onChange={(e) => {
                                const val = /^[0-9]*$/.test(e.target.value);
                                if (val) {
                                  setAgeGroupEnd(e.target.value);
                                }
                              }}
                              value={ageGroupEnd}
                            />
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Linha de cuidado</strong>
                        <DRadio
                          inline
                          name="line-of-care-form"
                          error={error.lineOfCareCheck}
                          helperText={error.lineOfCareCheck}
                          onChange={(e) => setLineOfCareCheck(e.target.value)}
                          value={lineOfCareCheck}
                          options={[
                            { value: "0", label: "Não" },
                            { value: "1", label: "Sim" },
                          ]}
                        />
                        <Box>
                          {lineOfCareCheck === "1" ? (
                            <DMultAutoComplete
                              placeholder="Linhas de cuidado"
                              label="Linhas de cuidado"
                              labelColor="black"
                              error={error.lineOfCare}
                              helperText={error.lineOfCare}
                              options={lineOfCares}
                              getOptionLabel={(option) => option.value}
                              getOptionSelected={(option, value) =>
                                value && option.id === value.id
                              }
                              // defaultValue={ linhaDeCuidado && linhaDeCuidado.length ? linhaDeCuidado.filter(res => linhasDeCuidado.map(item => res.id == item.id)) : []}
                              onChange={(e, newValue) => {
                                setLineOfCare(newValue);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ marginTop: 15, marginBottom: 15 }}>
                    <Box className={classes.medicoescards}>
                      <Box className={classes.medicoescard}>
                        <strong>Modelo de formulário</strong>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: -21,
                          }}
                        >
                          <h5 style={{ marginRight: 15 }}>
                            Caráter de atendimento:
                          </h5>
                          <DRadio
                            inline
                            error={error.typeAttendance}
                            helperText={error.typeAttendance}
                            name="type-attendance-form"
                            onChange={(e) => setTypeAttendance(e.target.value)}
                            value={typeAttendance}
                            options={[
                              { value: "imediato", label: "Imediato" },
                              {
                                value: "monitoramento",
                                label: "Monitoramento",
                              },
                              { value: "orientacao", label: "Orientação" },
                              { value: "triagem", label: "Triagem" },
                            ]}
                          />
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <h5 style={{ marginRight: 15 }}>
                            Formulário de interconsulta?
                          </h5>
                          <DRadio
                            inline
                            name="form-interconsult"
                            error={error.consultationForm}
                            helperText={error.consultationForm}
                            onChange={(e) =>
                              setConsultationForm(e.target.value)
                            }
                            value={consultationForm}
                            options={[
                              { value: "0", label: "Não" },
                              { value: "1", label: "Sim" },
                            ]}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Grid item md={11} sm={6}>
                    <Box style={{ marginBottom: 15, float: "right" }}>
                      <DButton
                        type="submit"
                        size="sm"
                        variant="primary"
                        icon={<Save fontSize="small" />}
                      >
                        {loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          "Salvar"
                        )}
                      </DButton>
                    </Box>
                  </Grid>
                </>
                )}
              </Grid>
            </>
          </div>
        
    </form>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={ e => closeModal() }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
         
        {body}
        
      </Modal>
    </div>
  );
}
