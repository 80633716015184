import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { userDominios } from "../api/dominios";
import { getAllTypes } from "../api/healthAttendanceType";

import { getAllPatients, getPatientById } from "../api/patient";
import { getAllUnidades } from "../api/unidades";
import { getMe } from "../api/user";
import { anamneseFormAnswer } from "../api/anamnese";
import { getAllHealthAttendance, getHealthAttendance, getAllHealthAttendanceRequest } from "../api/health_attendances";
import { toast } from "react-toastify";

export const PatientContext = createContext({});

export function PatientProvider({ children }) {
  const [firstFilter, setFirstFilter] = useState(false);
  const [healthPatients, setHealthPatients] = useState([]);
  const [login, setLogin] = useState(true);
  const [isList, setIsList] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);

  const [idPatient, setIdPatient] = useState(0);
  const [patientData, setPatientData] = useState([]);

  const [userDominio, setUserDominio] = useState([]);
  const [unidadesRef, setUnidadesRef] = useState([]);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState([]);
  const [healthAttendances, setHealthAttendances] = useState([]);
  const [totaisByDateHealthAttendances, setTotaisByDateHealthAttendances] = useState([]);
  

  const [filteredAttendances, setFilteredAttendances] = useState({
    open: [],
    assumed: [],
    finished: [],
    canceled_today: []
  });

  const [healthAttendanceTypes, setHealthAttendanceTypes] = useState();
  const [idHealthAttendanceTypes, setIdHealthAttendanceTypes] = useState(0);
  const [loadingHealthPatients, setLoadingHealthPatients] = useState(false);

  const preForm = useRef();
  const [loading, setLoading] = useState();

  const [paginate, setPaginate] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 0,
    total: 0
  })

  const [searchText, setSearchText] = useState("");

  const getHealthAttendances = async (user_requesting = null, historyAttendance = false) => {
    try {

      const res = user_requesting ? await getAllHealthAttendanceRequest(localStorage.getItem("token")) : await getAllHealthAttendance(localStorage.getItem("token"));

      if(res.status){
        setHealthAttendances(res.healthAttendances.patient);

        if(res.totais)
          setTotaisByDateHealthAttendances(res.totais);

        if(res.healthAttendances && Array.isArray(res.healthAttendances.patient)){
          setFilteredAttendances({
            open: res.healthAttendances.patient.filter(attendance => attendance.health_attendance_status_id === 1), // Aberto
            assumed: res.healthAttendances.patient.filter(attendance => attendance.health_attendance_status_id === 2), // Assumido
            finished: res.healthAttendances.patient.filter(attendance => {
              var result = false;

              if(attendance.health_attendance_status_id === 3){

                if(historyAttendance){
                  result = true;
                } else {
                  if(attendance.requested_at && (new Date()).toDateString() == (new Date(attendance.requested_at)).toDateString())
                  result = true;
                }
              }

              return result;
            }), // Finalizado,
            canceled_today: res.healthAttendances.patient.filter(attendance => {
              var result = false;
              
              if(attendance.health_attendance_status_id === 4) {
                if(attendance.canceled_at && (new Date()).toDateString() == (new Date(attendance.canceled_at)).toDateString())
                  result = true;
              }

              return result;
            }), // Cancelado,
          });
        }

        getAllTypes(localStorage.getItem("token")).then((res) => {
          if (res.healthAttendanceTypes.data.length === 0) {
            res.healthAttendanceTypes.data = [
              {
                name: "-",
                description: "-",
                active: "-",
              },
            ];
          }
          // console.log("Types", res);
          setHealthAttendanceTypes(res.healthAttendanceTypes.data);
        })

        
        // console.log(res);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  }

  const getAttendancesByFilter = async (filter, user_requesting = null, historyAttendance = false) => {
    if(typeof filter !== 'object') return;
    

    // console.log("Filtrando por", filter)

    try {
      const res = user_requesting ? await getAllHealthAttendanceRequest(localStorage.getItem("token"), filter.requested_at, filter.search) : await getHealthAttendance(localStorage.getItem("token"), filter);

      if(res.status){

        if(res.totais)
          setTotaisByDateHealthAttendances(res.totais);

        setHealthAttendances(res.healthAttendances.patient);
        // console.log("Res. filtro", res.healthAttendances);

        if(res.healthAttendances && Array.isArray(res.healthAttendances.patient)){
          setFilteredAttendances({
            open: res.healthAttendances.patient.filter(attendance => attendance.health_attendance_status_id === 1), // Aberto
            assumed: res.healthAttendances.patient.filter(attendance => attendance.health_attendance_status_id === 2), // Assumido
            finished: res.healthAttendances.patient.filter(attendance => {

              var result = false;

              if(attendance.health_attendance_status_id === 3){

                if(historyAttendance){
                  result = true;
                } else {
                  if(attendance.requested_at && (new Date()).toDateString() == (new Date(attendance.requested_at)).toDateString())
                  result = true;
                }
              }

              return result;

            }), // Finalizado,
            canceled_today: res.healthAttendances.patient.filter(attendance => {
              var result = false;
              
              if(attendance.health_attendance_status_id === 4) {
                if(historyAttendance){
                  result = true;
                }else {
                  if(attendance.canceled_at && (new Date()).toDateString() == (new Date(attendance.canceled_at)).toDateString())
                    result = true;
                }
              }

              return result;
            }), // Cancelado,
          });
        }

        getAllTypes(localStorage.getItem("token")).then((res) => {
          if (res.healthAttendanceTypes.data.length === 0) {
            res.healthAttendanceTypes.data = [
              {
                name: "-",
                description: "-",
                active: "-",
              },
            ];
          }
          // console.log("Types", res);
          setHealthAttendanceTypes(res.healthAttendanceTypes.data);
        })

        
        // console.log(res);
      }
    } catch (error) {
      // console.log(error);
    }
  }


  useEffect(() => {
    async function fetchData() {
      try {
        getMe(localStorage.getItem("token")).then((res) => {
          if (res.error) 
            setLogin(false);
          else {
            // console.log(res);
          }
        });
      } catch (e) {
        // console.error(e);
      }

      userDominios().then((res) => {
        setUserDominio(res);
      });

      // getHealthAttendances();

      getAllUnidades(localStorage.getItem("token")).then((res) => {
        let unidadesRef = [];
        let unidadesVinculadas = [];

        res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == "no") {
            unidadesRef.push(item);
            setUnidadesRef(unidadesRef);
          } else {
            unidadesVinculadas.push(item);
            setUnidadesVinculadas(
               unidadesVinculadas,
            );
          }
        });
      });
    }
    fetchData();
    listPatients();
  }, []);

  const listPatients = async () => {
    await getAllPatients(localStorage.getItem("token")).then((res) => {
      var pacientes = [];

      if ((res.patient && res.patient.length === 0) || !res.status) {
        pacientes.push({
          id: '-', 
          full_name: '-', 
          cpf: '-', 
          cns: '-', 
          birth_date: '-', 
          pcd: '-', 
          group_description: '-'
        });

        setHealthPatients(pacientes);
      } else {
        res.patient.length &&
          res.patient.map((item) => {
            var pacienteObj = {};

            pacienteObj.id                  = item.id;
            pacienteObj.full_name           = item.name;
            pacienteObj.birth_date          = item.birth_date;
            pacienteObj.cns                 = item.cns;
            pacienteObj.cpf                 = item.cpf;
            pacienteObj.pcd                 = item.deficiencies;
            pacienteObj.group_description   = item.hasGroup;

            pacientes.push(pacienteObj);
            setHealthPatients(pacientes);
          });
      }

      setHealthPatients(pacientes);
    });
  };

  const getPatientId = useMemo(
    () =>
    getPatientById(localStorage.getItem("token"), idPatient)
    .then((data) => {
      if (data.hasOwnProperty("status")) {
        if (data.status) {
          setPatientData(data.details);
        }
      }
    })
    .catch((err) => console.error(err)),
    [idPatient]
  );

  const getType = useMemo(
    () =>
      getAllTypes(localStorage.getItem("token")).then((res) => {

        if (res.status && res.healthAttendanceTypes.data.length === 0) {
          res.healthAttendanceTypes.data = [
            {
              name: "-",
              description: "-",
              active: "-",
            },
          ];
        }
        if(res.status)
          setHealthAttendanceTypes(res.healthAttendanceTypes.data);
      }),
    []
  );

  async function saveFormAnswer(anamneseId, health_attendance_id, discharge_id, data){

    console.log("saveFormAnswer 3");

    anamneseFormAnswer(localStorage.getItem("token"), anamneseId, {
      health_attendance_id,
      discharge_id,
      data: JSON.stringify(data) || ""
    })
  }

  return (
    <PatientContext.Provider
      value={{
        saveFormAnswer,
        healthPatients,
        login,
        isList,
        setIsList,
        setHealthPatients,
        selectedValue,
        setSelectedValue,
        idPatient,
        setIdPatient,
        patientData,
        setPatientData,
        userDominio,
        unidadesRef,
        unidadesVinculadas,
        healthAttendanceTypes,
        setIdHealthAttendanceTypes,
        loadingHealthPatients,
        setLoadingHealthPatients,
        preForm,
        setLoading,
        loading,
        setHealthAttendances,
        healthAttendances,
        filteredAttendances,
        getAttendancesByFilter,
        getHealthAttendances,
        paginate, 
        setPaginate,
        searchText, 
        setSearchText,
        firstFilter, 
        setFirstFilter,
        totaisByDateHealthAttendances, 
        setTotaisByDateHealthAttendances
      }}
    >
      {children}
    </PatientContext.Provider>
  );
}

export function usePatient() {
  const context = useContext(PatientContext);

  return context;
}
