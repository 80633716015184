import axios from "axios";
import env from "react-dotenv";

function listAllUnidades(token) {
  return fetch(`${env.API_URL}/medicalunit?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function listAllPerfis(token) {
  return fetch(`${env.API_URL}/profile/list?token=${token}`, {
    method: "post",
  }).then((res) => res.json());
}

/**
 * @param {boolean} ignoreLocalStorage - Ignora o valor em localStorage forçando a busca novamente da api.
 */
async function userDominios(ignoreLocalStorage = false) {
  if (localStorage.getItem("user_dominios") && ignoreLocalStorage === false) {
    try {
      return JSON.parse(localStorage.getItem("user_dominios"));
    } catch (e) {
      console.log(e);
      throw new Error(e); // Devolvendo o erro para tratamento em tela.
    }
  } else {
    try {
      const user_dominios = await axios.get(`${env.API_URL}/user-dominios`);

      const { data } = user_dominios;

      localStorage.setItem("user_dominios", JSON.stringify(data));

      return data;
    } catch (e) {
      console.log(e);
      throw new Error(e); // Devolvendo o erro para tratamento em tela.
    }
  }
}

export async function getRelationShipDegree(token) {
  const { data } = await axios.get(
    `${env.API_URL}/relationship-degree/list?token=${token}`
  );

  return data;
}

export function listCityByStateId(stateId) {
  return axios
    .get(`${env.API_URL}/city?state_id=${stateId}`)
    .then((res) => res.data);
}

export function getCityById(cityId) {
  return axios.get(`${env.API_URL}/city/${cityId}`).then((res) => res.data);
}

export function getStatesList() {
  return axios.get(`${env.API_URL}/api/state`).then((res) => res.data);
}

export function getModelInvite(token) {
  return axios
    .get(`${env.API_URL}/model-invite?token=${token}`)
    .then((res) => res.data);
}

export function getStates(token) {
  return axios
    .get(`${env.API_URL}/state?token=${token}`)
    .then((res) => res.data);
}

export function getCitys(token) {
  return axios
    .get(`${env.API_URL}/city?token=${token}`)
    .then((res) => res.data);
}

export function getCountrys(token) {
  return axios
    .get(`${env.API_URL}/country?token=${token}`)
    .then((res) => res.data);
}

export function getEtnias(token) {
  return axios
    .get(`${env.API_URL}/ethnicity?token=${token}`)
    .then((res) => res.data);
}

export { listAllUnidades, listAllPerfis, userDominios };
