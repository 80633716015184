import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PregnantWomanIcon from "@material-ui/icons/PregnantWoman";
import gestante from "../../../../../../assets/img/gestante.svg";
import ErrorIcon from "@material-ui/icons/Error";

import { getAgeByDate } from "../../../../../../helpers/formatacao";

const useStyles = makeStyles((theme) => ({
  paciente__topo: {
    position: "absolute",
    left: 0,
    top: 88,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: theme.palette.background.snow,
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
  },
  paciente__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.neutral.white,
    fontWeight: 600,
  },
  finish__button: {
    backgroundColor: `${theme.palette.support.error} !important`,
    color: theme.palette.neutral.white,
    textTransform: "none",
    "&:hover": {
      backgroundColor: `${theme.palette.support.error} !important`,
    },
  },
  box: {
    background: theme.palette.background.snow,
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "8px",
    marginTop: 90,
    height: "calc(100vh - 340px)",
    overflow: "auto",
    paddingBottom: 40,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.neutral.black,
    padding: "20px 32px",
    cursor: "pointer",
    borderRadius: 4,
    marginBottom: 12,
    "&:hover": {
      backgroundColor: theme.palette.background.cloud,
    },
  },
  menuItemSelected: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.neutral.black,
    padding: "20px 32px",
    cursor: "pointer",
    borderRadius: 4,
    marginBottom: 12,
    backgroundColor: theme.palette.background.cloud,
  },
  tags: {
    display: "flex",
    alignItems: "center",
    width: "calc(100% - 16px)",
    minHeight: 34,
    padding: 8,
    margin: "26px -4px",
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 4,
  },
  profile: {
    backgroundColor: theme.palette.background.snow,
    borderRadius: 4,
    width: "100%",
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 500,
  },
  modal_title: {
    width: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 0 16px",
    fontSize: 16,
    fontWeight: 700,
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    color: theme.palette.primary.light,
  },
  modal_label: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.neutral.black,
  },
  containerClass: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    textAlign: "center",
    padding: 10,
  },
  pregnantWomanIconClass: {
    color: theme.palette.primary.light, 
    marginRight: 10
  },
  errorColor:{ 
    marginTop: 2, 
    color: theme.palette.support.error,
  }
}));

export function PatientCardInfo(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid
        item
        md={6}
        className={classes.containerClass}
      >
        <img src={gestante} style={{ margin: 4, height: 140 }} />
        <div className={classes.profile}>
          <PregnantWomanIcon className={classes.pregnantWomanIconClass} />
          {props.sisprenatal}
        </div>
      </Grid>
      <Grid item md={6}>
        <table style={{ width: "100%", padding: 8 }}>
          {props.altoRisco ? (
            <tr>
              <td style={{ textAlign: "right", padding: 4 }}>
                <ErrorIcon
                  fontSize="small"
                  className={classes.errorColor}
                />
              </td>
              <td style={{ fontWeight: 500, fontSize: 14, padding: 4 }}>
                Alto risco
              </td>
            </tr>
          ) : (
            ""
          )}
          <tr>
            <td
              style={{
                fontWeight: 500,
                fontSize: 14,
                textAlign: "right",
                padding: 4,
              }}
            >
              Status:
            </td>
            <td style={{ fontSize: 14, padding: 4 }}>
              {props.statusAtendimento}
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: 500,
                fontSize: 14,
                textAlign: "right",
                padding: 4,
              }}
            >
              Idade:
            </td>
            <td style={{ fontSize: 14, padding: 4 }}>
              {getAgeByDate(props.patientBirthDate)} anos
            </td>
          </tr>
          <tr>
            <td
              style={{
                fontWeight: 500,
                fontSize: 14,
                textAlign: "right",
                padding: 4,
              }}
            >
              DPP:
            </td>
            <td style={{ fontSize: 14, padding: 4 }}>{props.dpp}</td>
          </tr>
        </table>
        {props.diasGestacao && props.semanasGestacao ? (
          <Typography style={{ textAlign: "center" }}>
            <span style={{ fontSize: 24, fontWeight: 500 }}>
              {props.semanasGestacao}
            </span>{" "}
            semanas
            <br />
            <span
              style={{
                fontSize: 24,
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              {props.diasGestacao}
            </span>{" "}
            dias
          </Typography>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
