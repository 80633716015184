import { makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import EBHr from "../EBHr";

const useStyles = makeStyles({
  displayErrorShow: {
    border: "1px solid #f44336",
  },
});

const FieldDefaultObservation = ({
  id,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer,
}) => {
  const { handleChange, handleInputSubmit } = useContext(FormBuilderContext);
  const classes = useStyles();
  const [observation, setObservation] = useState('');
  const  [ showError ,  setShowError ]  =  useState (false);

  // useEffect(() => {
  //   const data = {
  //     value: observation
  //   }
  //   if(data.value !== ''){
  //     handleInputSubmit({[id]: data.value})
  //   }
  // },[observation])

  useEffect(() => {
    if(erro[0] && erro[0].value && observation === '') {
      setShowError(true)
    }
    else if(erro[0] === undefined || observation.length > 0) {
      setShowError(false)
    }
    
  },[erro[0], observation])

  useEffect(() => {
    if(answer.length > 0){
      setObservation(answer[0])
    }
  },[])

  const style = {
    width: "100%",
  };

  
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
          <div >
            <Typography
              variant="body1"
              className="text-bold text-primary"
              style={{ fontSize: "1em" }}
            >
              Observações
            </Typography>
            <div className="textarea-box mb-4">
              <ReactQuill theme="snow" 
                id={id}
                className={ showError ? classes.displayErrorShow : '' }
                value={observation || ''} 
                onChange={setObservation}
                error={ erro[0] ? erro[0].value : '' }
                helperText={ erro[0] ? erro[0].value : '' }
                readOnly={readOnly}
                onBlur={e => { !readOnly ? handleInputSubmit({[id]: observation}) : console.log('effect onBlur') }}
                style={style}
                />
            </div>
            <span style={{ fontSize: 12 }} className="text-regular">
              {showError ? 'Este campo é obrigatório' : ""}
            </span>
            <EBHr />
          </div>
      </div>
    </section>
  );
};

export default FieldDefaultObservation;
