import env from 'react-dotenv';

function getAllProfiles(token) {
    return fetch(`${env.API_URL}/profile/list?token=${ token }`, {
        method: 'post'
    }).then(res => res.json());
}

function getAllProfilesNotPaginate(token) {
    return fetch(`${env.API_URL}/profile?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getAllPermissions(token) {
    return fetch(`${env.API_URL}/role/list?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getAllPermissionsByProfile(token, profileId) {
    return fetch(`${env.API_URL}/role/list/profile/${profileId}?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getProfileById(token, id) {
    return fetch(`${env.API_URL}/profile/${ id }/show?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getProfilePermissionsById(token, id) {
    return fetch(`${env.API_URL}/profile/${ id }/permission/list?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createProfile(token, dados) {
    return fetch(`${env.API_URL}/profile?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateProfile(token, dados) {
    return fetch(`${env.API_URL}/profile/${ dados.id }/update?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteProfile(token, id) {
    return fetch(`${env.API_URL}/profile/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

function getProfilePermission(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/list-permission?token=${token}`, {
      method: "get",
    }).then((res) => res.json());
  }
  
  function enableProfilePermissionCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-enabled?token=${token}`, {
      method: "put",
    }).then((res) => res.json());
  }
  
  function disableProfilePermissionCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-enabled?token=${token}`, {
      method: "delete",
    }).then((res) => res.json());
  }
  
  function enableProfilePermissionSequentialCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/sequential-call?token=${token}`, {
      method: "put",
    }).then((res) => res.json());
  }
  
  function disableProfilePermissionSequentialCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/sequential-call?token=${token}`, {
      method: "delete",
    }).then((res) => res.json());
  }
  
  function enableProfilePermissionGeneralGroup(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-in-general-group?token=${token}`, {
      method: "put",
    }).then((res) => res.json());
  }
  
  function disableProfilePermissionGeneralGroup(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-in-general-group?token=${token}`, {
      method: "delete",
    }).then((res) => res.json());
  }
  
  function enableProfilePermissionTotalGroup(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-in-total-group?token=${token}`, {
      method: "put",
    }).then((res) => res.json());
  }
  
  function disableProfilePermissionTotalGroup(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/call-in-total-group?token=${token}`, {
      method: "delete",
    }).then((res) => res.json());
  }
  
  function enableProfilePermissionDirectCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/direct-call?token=${token}`, {
      method: "put",
    }).then((res) => res.json());
  }
  
  function disableProfilePermissionDirectCall(token, id) {
    return fetch(`${env.API_URL}/profile/${id}/direct-call?token=${token}`, {
      method: "delete",
    }).then((res) => res.json());
  }

export { 
    getAllProfiles, 
    getAllPermissions, 
    getProfileById, 
    getProfilePermissionsById, 
    createProfile, 
    updateProfile, 
    deleteProfile, 
    getAllProfilesNotPaginate, 
    getAllPermissionsByProfile,
    getProfilePermission,
    enableProfilePermissionCall,
    disableProfilePermissionCall,
    enableProfilePermissionSequentialCall,
    disableProfilePermissionSequentialCall,
    enableProfilePermissionGeneralGroup,
    disableProfilePermissionGeneralGroup,
    enableProfilePermissionTotalGroup,
    disableProfilePermissionTotalGroup,
    enableProfilePermissionDirectCall,
    disableProfilePermissionDirectCall
}