import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DButton from '../../../../../shared/DButton';

import AddCircle from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import "../styles.scss"
import DadosPaciente from "../../../../PacienteDetails/components/DadosPaciente"
import {
    AnamnesePreFormByHealthAttendanceId
} from "../../../../../../api/anamnesePreForm"
import { toast } from 'react-toastify';
import { AtendimentoContext } from '../../../../../../context/AtendimentoContext';


const useStyles = makeStyles({
    table: {
        minWidth: 1800
    },
    td: {
        whiteSpace: "nowrap",
        padding: "0 12px",
        border: 0
    },
    popover: {
        pointerEvents: 'none',
        marginLeft: 8
    },
    paper: {
        padding: 8,
        fontSize: 12,
        minWidth: 350,
        whiteSpace: "nowrap"
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50
    },
    selected: {
        backgroundColor: "#ffffff !important",
        border: "1px solid #4a4ae9 !important",
        color: "#4a4ae9",
        fontWeight: "500 !important"
    },
    page: {
        fontWeight: 400,
        fontSize: 16,
        
    },
    ul: {
        "&>li:first-of-type>button": {
            border: "1px solid #4a4ae9 !important",
            borderRadius: "50%",
            color: "#4a4ae9"
        },
        "&>li:last-of-type>button": {
            border: "1px solid #4a4ae9 !important",
            borderRadius: "50%",
            color: "#4a4ae9"
        }
    },
    rootDialog: {
        display: "flex",
        margin: 0,
        padding: "0 0 0 16px",
        height: 48,
        alignItems: "center",
        flexFlow: "row",
        justifyContent: "space-between",
        minWidth: 500
    },
    titleDialog: {
        fontSize: 20,
        fontWeight: 700,
        color: "#4a4ae9"
    },
    closeButton: {
        color: "#949494",
    },
    txtinput: {
        marginTop: 0
    },
    rootDialog2: {
        display: "flex",
        margin: 0,
        padding: "0 8px 0 0",
        height: 48,
        alignItems: "center",
        flexFlow: "row",
        justifyContent: "space-between",
        minWidth: 640
    },
    titleDialog2: {
        fontSize: 16,
        fontWeight: 700,
        color: "#4a4ae9"
    },
    customMaxWidth: {
        maxWidth: "none",
        padding: "20px"
    },
    accordionRoot: {
        
    },
    accordionTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: "#4a4ae9"
    },
    accordionText: {
        fontSize: 14
    }
});
  
const DialogContent = withStyles((theme) => ({
    root: {
      padding: 16,
    },
}))(MuiDialogContent);

export default function DialogModal(props){
    const classes = useStyles();
    const {attendance} = useContext(AtendimentoContext);

    const [patientData, setPatientData] = useState(null);
    const [formMessageErr, setFormMessageErr] = useState(null);

    useEffect(() => {
        if(props.attendance){
            AnamnesePreFormByHealthAttendanceId(localStorage.getItem('token'), props.attendance.id)
            .then(data => {
                if(Array.isArray(data) && data.length > 0){
                    setFormMessageErr(false);
                    setPatientData(data[0]);
                    toast.info("Dados do paciente carregados");
                    console.log(data)
                } else {
                    setFormMessageErr(false);
                    toast.error("Formulário anamnese não encontrado");
                }
            })
            .catch(err => console.error(err));
        } else {
            setFormMessageErr(true);
            toast.error("Não foi possível carregar os dados do atendimento.");
            props.close && props.close();
        }
    }, [props.attendance]);
    
    return (
        <Dialog onClose={ props.close } open={ props.open } classes={{ paperScrollPaper: classes.customMaxWidth }}>
                <MuiDialogTitle disableTypography className={ classes.rootDialog2 }>
                    <Typography className={classes.titleDialog2}>Ficha da consulta - ({props.attendance && props.attendance.id})</Typography>
                    <IconButton className={classes.closeButton} onClick={ props.close }>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </MuiDialogTitle>

                {
                    formMessageErr === null && <CircularProgress style={{ color: "black", alignSelf: "center" }} />
                }

                {
                    formMessageErr === false ? (
                        <DadosPaciente back={props.close} attendance={props.attendance} anamnesePreForm={patientData}/>
                    ) : ""
                }
                
                
        </Dialog>
    )
}