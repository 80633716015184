import { useEffect, useState } from 'react';
import AtendimentoImediato from "./AtendimentoImediato";
import { getHealthAttendanceFilter } from "./../../api/healthAttendance";
import { useAuthRoute } from "./../../context/AuthRouteContext";
import { NewHealthAttendanceProvider } from "./../../context/NewHealthAttendance";
import { useMenus } from "../../context/MenuContext";

function AtendimentoImediatoContent(props)
{

    const { auth, setAuth } = useAuthRoute();
    const { menus, setAtendimentoIndex, atendimentoIndex } = useMenus();


    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getHealthAttendanceFilter(localStorage.getItem('token'), 1).then(res => {
            setLoaded(true)
        }).catch(err => {
            switch(err.response.status){
                case 403:
                    setAuth({...auth, route: false})
                    break;
            }
        })
    }, [localStorage.getItem('token')]);

    useEffect(() => {
        return () => {
            props.onSideBar(true);
        }
    }, [])

    return (
        <NewHealthAttendanceProvider>
            { loaded && <AtendimentoImediato setAtendimentoIndex={setAtendimentoIndex} atendimentoIndex={atendimentoIndex} sidebar={ e => props.onSideBar(e) } /> }
        </NewHealthAttendanceProvider>
    )
}

export default AtendimentoImediatoContent;