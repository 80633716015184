import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/lab';
import ptBR from "date-fns/locale/pt-BR";

function CustomDateTimePicker(props) {

  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <DateTimePicker
        disabled={ props.disabled }
        label={props.title}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField className='input_new_solicitante_new_tab' variant="standard"  {...params} />}
      />
    </LocalizationProvider>
  );
}

CustomDateTimePicker.defaultProps = {
  disabled: false,
  value: null
}

export default CustomDateTimePicker;