import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import MuiDialogContent from "@material-ui/core/DialogContent";
import { getFormatDate } from "../../../../../../helpers/formatacao";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "@material-ui/icons/Info";
import {MonitorIcon, PrintIcon, DriveFileIcon, OpenNewIcon} from "../../../../../shared/SvgComponents/Icons";
import triagem from "../../../../../../assets/img-icon/triagem.svg";
import Info from "../../../../../../assets/img-icon/Info.svg";
import openNewIcon from "../../../../../../assets/img-icon/openNewIcon.svg";
// import DriveFileIcon from "../../../../../../assets/img-icon/driveFileIcon.svg";
import DoctorIcon from "../../../../../../assets/img-icon/DoctorIcon.svg";

import "../styles.scss";

import HealthAttendanceStatus from "../componentes/HealthAttendanceStatus";
import { useHistory } from "react-router";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import { Person, People } from "@material-ui/icons";
import { Popover } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 1800,
  },
  popoverCard: {
    border: "1px solid #cccccc52",
    backgroundColor: "#cccccc52",
    borderRadius: "8px",
    padding: "6px",
  },
  td: {
    whiteSpace: "nowrap",
    padding: "0 12px",
    border: 0,
  },
  popover: {
    pointerEvents: "none",
    marginLeft: 8,
  },
  paper: {
    padding: "15px 10px",
    fontSize: 12,
    minWidth: 312,
    whiteSpace: "nowrap",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: 50,
  },
  selected: {
    backgroundColor: "#ffffff !important",
    border: "1px solid #4a4ae9 !important",
    color: "#4a4ae9",
    fontWeight: "500 !important",
  },
  page: {
    fontWeight: 400,
    fontSize: 16,
  },
  ul: {
    "&>li:first-of-type>button": {
      border: "1px solid #4a4ae9 !important",
      borderRadius: "50%",
      color: "#4a4ae9",
    },
    "&>li:last-of-type>button": {
      border: "1px solid #4a4ae9 !important",
      borderRadius: "50%",
      color: "#4a4ae9",
    },
  },
  rootDialog: {
    display: "flex",
    margin: 0,
    padding: "0 0 0 16px",
    height: 48,
    alignItems: "center",
    flexFlow: "row",
    justifyContent: "space-between",
    minWidth: 500,
  },
  titleDialog: {
    fontSize: 20,
    fontWeight: 700,
    color: "#4a4ae9",
  },
  closeButton: {
    color: "#949494",
  },
  txtinput: {
    marginTop: 0,
  },
  rootDialog2: {
    display: "flex",
    margin: 0,
    padding: "0 8px 0 16px",
    height: 48,
    alignItems: "center",
    flexFlow: "row",
    justifyContent: "space-between",
    minWidth: 640,
  },
  titleDialog2: {
    fontSize: 16,
    fontWeight: 700,
    color: "#4a4ae9",
  },
  customMaxWidth: {
    maxWidth: "none",
  },
  accordionRoot: {},
  accordionTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "#4a4ae9",
  },
  accordionText: {
    fontSize: 14,
  },
  listButton: {
    filter:
      "invert(22%) sepia(95%) saturate(2407%) hue-rotate(236deg) brightness(94%) contrast(95%)",
  },
  typeIcon : {
    filter: "invert(28%) sepia(91%) saturate(6312%) hue-rotate(225deg) brightness(80%) contrast(129%)"
  },
  iconPrimary: {
    color: theme.palette.primary.light,
  }
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} placement="top" {...props} />;
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: 16,
  },
}))(MuiDialogContent);

const rating_colors = {
  1: "#fc5d32", // Grave
  2: "#ade7d2", // Leve
  3: "#f6d876", // Moderado
  4: "#000000", // Sem avaliação
};

// function getPatientDocuments(patient_documents){

//     var documents = {
//         cpf: "",
//         rg: "",
//     }

//     if(Array.isArray(patient_documents) && patient_documents.length){
//         var _cpf = patient_documents.find(x => x.document_type.description === "CPF");
//         var _rg = patient_documents.find(x => x.document_type.description === "RG");

//         documents.cpf = _cpf ? _cpf.patient_document_fields && _cpf.patient_document_fields.length ? _cpf.patient_document_fields[0].value : "" : "";
//         documents.rg = _rg ? _rg.patient_document_fields && _rg.patient_document_fields.length ? _rg.patient_document_fields[0].value : "" : "";
//     }

//     return documents;
// }

export default function AttendancesList(props) {
  const classes = useStyles();
  const history = useHistory();
  const rating =
    props.healthAttendance.rating_health_attendance &&
    props.healthAttendance.rating_health_attendance.length
      ? props.healthAttendance.rating_health_attendance[0]
      : null;
  const healthAttendance = props.healthAttendance;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  function getConselhoMedico(document) {
    var doc = null;

    if (document && typeof document === "object") {
      if (document[4] && document[4].values) {
        doc = document[4];

        if (doc && doc.values) {
          var [_conselho, _cod_conselho, _, _uf_conselho, _cbo_conselho] =
            doc.values;

          return `
                        ${_conselho.value ? _conselho.value : ""} - ${
            _uf_conselho.value ? _uf_conselho.value : ""
          } ${_cod_conselho.value ? _cod_conselho.value : ""}`;
        }
      }
    }
  }

  function tipoConsulta() {
    if (healthAttendance) {
      if (healthAttendance.interconsultation == 1) {
        return (
          <>
            <BootstrapTooltip title="Interconsulta">
              <People className={classes.iconPrimary} />
            </BootstrapTooltip>
          </>
        );
      } else if (healthAttendance.service_character == "1") {
        return (
          <>
            <BootstrapTooltip title="Consulta imediata">
              <Person className={classes.iconPrimary} />
            </BootstrapTooltip>
          </>
        );
      } else if (healthAttendance.service_character == "2") {
        return (
          <>
            <BootstrapTooltip title="Monitoramento">
              <IconButton>
                <MonitorIcon className={classes.iconPrimary} />
              </IconButton>
            </BootstrapTooltip>
          </>
        );
      } else if (healthAttendance.service_character == "3") {
        return (
          <>
            <BootstrapTooltip title="Orientação">
                <InfoIcon style={{ fill: "blue" }} />
            </BootstrapTooltip>
          </>
        );
      }
      else if (healthAttendance.service_character == "4") {
        return (
          <>
            <BootstrapTooltip title="Triagem">
              <IconButton>
                <img src={triagem} className={classes.typeIcon} />
              </IconButton>
            </BootstrapTooltip>
          </>
        );
      } else if (healthAttendance.service_character == "5") {
        return (
          <>
            <BootstrapTooltip title="Consulta agendada">
              <Person className={classes.iconPrimary} />
            </BootstrapTooltip>
          </>
        );
      }
    }
  }

  return (
    <>
      <Popover
        id="popover1"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverCard} style={{ marginBottom: "10px" }}>
          <span>
            <b>Solicitante</b>:{" "}
            {healthAttendance &&
              healthAttendance.user_requesting &&
              (healthAttendance.user_requesting.social_name || healthAttendance.user_requesting.full_name)}
          </span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {healthAttendance &&
            healthAttendance.user_requesting &&
            Array.isArray(
              healthAttendance.user_requesting.user_medical_speciality
            ) &&
            healthAttendance.user_requesting.user_medical_speciality.length ? (
              <span style={{ marginRight: "15px" }}>
                <b>
                  {healthAttendance &&
                    healthAttendance.user_requesting &&
                    healthAttendance.user_requesting.user_medical_speciality.map(
                      (x) => {
                        return x.medical_speciality.description;
                      }
                    )}
                </b>
              </span>
            ) : (
              ""
            )}
            <span>
              <b>Conselho</b>:{" "}
              {healthAttendance.documentUserExecuting &&
                getConselhoMedico(healthAttendance.documentUserExecuting)}
            </span>
          </div>
        </div>
        <div className={classes.popoverCard}>
          <span>
            <b>Executante</b>:{" "}
            {healthAttendance &&
              healthAttendance.user &&
              (healthAttendance.user.social_name || healthAttendance.user.full_name)}
          </span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {healthAttendance &&
            healthAttendance.user &&
            Array.isArray(healthAttendance.user.user_medical_speciality) &&
            healthAttendance.user.user_medical_speciality.length ? (
              <span style={{ marginRight: "15px" }}>
                <b>
                  {healthAttendance &&
                    healthAttendance.user &&
                    healthAttendance.user.user_medical_speciality.map((x) => {
                      return x.medical_speciality.description;
                    })}
                </b>
              </span>
            ) : (
              ""
            )}
            <span>
              <b>Conselho</b>:{" "}
              {healthAttendance.documentUserRequesting &&
                getConselhoMedico(healthAttendance.documentUserRequesting)}
            </span>
          </div>
        </div>
      </Popover>
      <TableRow>
        <TableCell className={classes.td}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HealthAttendanceStatus
              status={healthAttendance.health_attendance_status_id}
            />
          </div>
        </TableCell>
        <TableCell className={classes.td}>
          {healthAttendance && healthAttendance.id}
        </TableCell>

        <TableCell className={classes.td}>
          {healthAttendance &&
            healthAttendance.health_attendance_anamnese_form[
              healthAttendance.health_attendance_anamnese_form.length - 1
            ].anamnese_form.name}
        </TableCell>
        <TableCell className={classes.td}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {tipoConsulta()}
          </div>
        </TableCell>
        <TableCell className={classes.td}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {healthAttendance &&
              healthAttendance.user &&
              (healthAttendance.user.social_name || healthAttendance.user.full_name )}

            {healthAttendance && healthAttendance.user ? (
              <InfoIcon
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ color: "#868686", width: 16, marginLeft: 8 }}
              />
            ) : (
              "-"
            )}

            {/* <InfoIcon style={{ color: "#4A4AE9", width: 16, marginLeft: 8 }} /> */}
          </div>
        </TableCell>
        <TableCell className={classes.td}>          
          <Typography variant="body1" className="text-regular text-st">{healthAttendance.patient && (healthAttendance.patient.social_name || healthAttendance.patient.full_name)} {healthAttendance.patient.pronoun ? `(${healthAttendance.patient.pronoun.name})` : ""}</Typography>
          <Typography variant="body1" className="text-light text-est">{healthAttendance.patient && healthAttendance.patient.cpf}</Typography>
        </TableCell>
        {/* <TableCell className={classes.td}>{healthAttendance.patient && getPatientDocuments(healthAttendance.patient.patient_document).cpf}</TableCell> */}
        <TableCell className={classes.td}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {rating ? (
              <>
                <FiberManualRecordIcon
                  style={{
                    marginRight: 4,
                    fill:
                      rating && rating.rating_health_id
                        ? rating_colors[rating.rating_health_id]
                        : rating_colors[4],
                  }}
                />
                {rating.rating_health.description || ""}
              </>
            ) : (
              "-"
            )}
          </div>
        </TableCell>
        <TableCell className={classes.td}>
          {healthAttendance &&
            healthAttendance.line_care &&
            healthAttendance.line_care.name}
        </TableCell>
        <TableCell className={classes.td}>
          {healthAttendance.patient && healthAttendance.formated_requested_at
            ? `${getFormatDate(
                new Date(healthAttendance.formated_requested_at),
                "dd/MM/yyyy 'às' H:mm:ss"
              )}`
            : "-"}
        </TableCell>
        <TableCell className={classes.td}>
          {healthAttendance.patient && healthAttendance.updated_at
            ? `${getFormatDate(
                new Date(healthAttendance.updated_at),
                "dd/MM/yyyy 'às' H:mm:ss"
              )}`
            : "-"}
        </TableCell>
        {/* <TableCell className={classes.td} align="center">{healthAttendance.patient && healthAttendance.updated_at && `${getFormatDate(new Date(healthAttendance.updated_at), "dd/MM/yyyy 'às' H:mm:ss")}`}</TableCell> */}
        <TableCell className={classes.td}>
          {healthAttendance &&
            healthAttendance.health_attendance_status_id !== 4 && (
              <>
                <BootstrapTooltip
                  title="Atender"
                  onClick={(e) => props.atender(healthAttendance)}
                >
                  <IconButton className={classes.iconPrimary}>
                    {/* <img src={openNewIcon} /> */}
                    <OpenNewIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title="Criar PDF"
                  onClick={(e) =>
                    window.open(
                      `${window.location.origin}/visualizar-exame/${healthAttendance.id}`,
                      "_blank"
                    )
                  }
                >
                  <IconButton style={{ margin: "0 8px" }} className={classes.iconPrimary}>
                    {/* <img src={printIcon} /> */}
                    <PrintIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title="Visualizar"
                  onClick={(e) => {
                    props.setAttendance(healthAttendance);
                    props.handleDialogOpen(true);
                  }}
                >
                  <IconButton className={classes.iconPrimary}>
                    <MonitorIcon className={classes.iconPrimary} />
                  </IconButton>
                </BootstrapTooltip>
              </>
            )}
          {healthAttendance &&
            healthAttendance.health_attendance_status_id === 4 && (
              <>
                <BootstrapTooltip
                  title="Finalizado"
                  onClick={(e) => {
                    props.setAttendance(healthAttendance);
                    props.handleDialogOpen(true);
                  }}
                >
                  <IconButton>
                    {/* <img
                      className={classes.listButton}
                      width="18"
                      height="19"
                      src={DriveFileIcon}
                    /> */}
                    <DriveFileIcon 
                      className={classes.listButton}
                      width="18"
                      height="19"/>
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title="Criar PDF"
                  onClick={(e) =>
                    window.open(
                      `${window.location.origin}/visualizar-exame/${healthAttendance.id}`,
                      "_blank"
                    )
                  }
                >
                  <IconButton style={{ margin: "0 8px" }} className={classes.iconPrimary}>
                    {/* <img src={printIcon} /> */}
                    <PrintIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip
                  title="Visualizar"
                  onClick={(e) => {
                    props.setAttendance(healthAttendance);
                    props.handleDialogOpen(true);
                  }}
                >
                  <IconButton className={classes.iconPrimary}>
                    <MonitorIcon className={classes.iconPrimary} />
                  </IconButton>
                </BootstrapTooltip>
              </>
            )}
        </TableCell>
      </TableRow>
    </>
  );
}
