import env from 'react-dotenv';

function getAllTypes(token) {
    return fetch(`${env.API_URL}/healthAttendanceType?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getAll(token) {
    return fetch(`${env.API_URL}/healthAttendanceType/all?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createType(token, dados) {
    return fetch(`${env.API_URL}/healthAttendanceType?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateType(token, dados, id) {
    return fetch(`${env.API_URL}/healthAttendanceType/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteType(token, id) {
    return fetch(`${env.API_URL}/healthAttendanceType/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

export { getAll, getAllTypes, createType, updateType, deleteType }

export default { getAllTypes, createType, updateType, deleteType }