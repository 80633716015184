
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles({
  box: {
    background: "#FFFFFF",
    boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "0 8px 8px 8px",
    marginLeft: 4
  }
});

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  
  return (
      <div
        className={ classes.box }
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

export default TabPanel;