import * as React from 'react';
import env from "react-dotenv";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, makeStyles } from '@material-ui/core';
import "./index.scss";
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { useEffect } from 'react';
import { ExameLaboratoriais } from '../../../../api/above';
import ModalLaudo from '../../NewTabs/VisualizadorClinNewTab/Components/ModalLaudo';
import ExamModal from '../../../../component/shared/ExamModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4F4F4F",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#F4F4F4",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const useStyles = makeStyles((theme) => ({
  iconResultadoTabelaVacinas: {
      color: `${theme.palette.primary.light} !important`,
  },
  iconResultadoTabelaGestacao: {
      color: `${theme.palette.primary.light} !important`,
  },
}));

export default function CustomizedTables() {
  const {healthAttendance} = useNewHealthAttendance();
  const [exames, setExames] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [exame, setExame] = React.useState(null);
  const [exameOpen, setExameOpen] = React.useState(null);
  const classes = useStyles();
  
  useEffect(() => {
    if(healthAttendance) {
      ExameLaboratoriais.getListExamsByHealthAttendanceId(localStorage.getItem("token"), healthAttendance.id).then(
        (data) => {
          if (data.status) {
            setExames(data.details)
          }
        }
      );
    }
  }, [healthAttendance]);

  function formatDate(date) {
    if(date && date != 'null') {
        date = typeof date != 'object' ? new Date(date) : null;
        var day = date.getDate() >= 10 ? date.getDate() : '0'+ date.getDate();
        var month = (date.getMonth() + 1) >= 10 ? date.getMonth() : '0'+ date.getMonth();
        var year = String(date.getFullYear()).slice(2);
        
        return [day, month, year].join('/');
    } else {
        return ''
    }
  }

  return (
    <>
      {(exames && exames.length) &&
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
              <StyledTableCell align="left">Solicitação</StyledTableCell>
              <StyledTableCell align="left">Procedimento</StyledTableCell>
              <StyledTableCell align="left">Lateralidade</StyledTableCell>
              <StyledTableCell align="center">Tipos de exame</StyledTableCell>
              <StyledTableCell align="center">Resultado</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exames &&
                exames.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                      {item.date && formatDate(item.date)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.health_Unit}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.laterality}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item.exam_type}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button onClick={(e) => setExame(item)} className={classes.iconResultadoTabelaGestacao} startIcon={<LaunchIcon />}> </Button>
                        <Button onClick={(e) => setExameOpen(item.id)} className={classes.iconResultadoTabelaVacinas} startIcon={<DownloadIcon />}></Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer> }
        { exame && <ModalLaudo exame={ exame } onClose={ e => setExame(null) } /> }
        { exameOpen && <ExamModal onClose={ e => setExameOpen(null) } open={ true } src={ `${env.API_URL}/prodam/previousImagingExams/${exameOpen}?token=${localStorage.getItem("token")}` } /> }
    </>
  );
}
