import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Backdrop, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import MuiDialogContent from '@material-ui/core/DialogContent';
import AttendancesList from './componentes/AttendancesList';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import "./styles.scss"

import DialogConsulta from "./modais/FichaConsulta"
import ObservationModal from './modais/Observacao';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DialogObservation from './modais/DialogObservation';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 1800
    },
    td: {
        whiteSpace: "nowrap",
        padding: "0 12px",
        border: 0
    },
    popover: {
        pointerEvents: 'none',
        marginLeft: 8
    },
    paper: {
        padding: 8,
        fontSize: 12,
        minWidth: 350,
        whiteSpace: "nowrap"
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        marginTop: 50
    },
    selected: {
        backgroundColor: "#ffffff !important",
        border: `1px solid ${theme.palette.primary.main} !important`,
        color: theme.palette.primary.main,
        fontWeight: "500 !important"
    },
    page: {
        fontWeight: 400,
        fontSize: 16,
        
    },
    ul: {
        "&>li:first-of-type>button": {
            border: `1px solid ${theme.palette.primary.main} !important`,
            borderRadius: "50%",
            color: theme.palette.primary.main,
        },
        "&>li:last-of-type>button": {
            border: `1px solid ${theme.palette.primary.main} !important`,
            borderRadius: "50%",
            color: theme.palette.primary.main,
        }
    },
    rootDialog: {
        display: "flex",
        margin: 0,
        padding: "0 0 0 16px",
        height: 48,
        alignItems: "center",
        flexFlow: "row",
        justifyContent: "space-between",
        minWidth: 500
    },
    titleDialog: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    closeButton: {
        color: "#949494",
    },
    txtinput: {
        marginTop: 0
    },
    rootDialog2: {
        display: "flex",
        margin: 0,
        padding: "0 8px 0 16px",
        height: 48,
        alignItems: "center",
        flexFlow: "row",
        justifyContent: "space-between",
        minWidth: 640
    },
    titleDialog2: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.palette.primary.main,
    },
    customMaxWidth: {
        maxWidth: "none"
    },
    accordionRoot: {
        
    },
    accordionTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
    },
    accordionText: {
        fontSize: 14
    }
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
}));
  
function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} placement="top" {...props} />;
}

  
const DialogContent = withStyles((theme) => ({
    root: {
      padding: 16,
    },
}))(MuiDialogContent);

export default function AtendimentoGrid(props) {
    const classes = useStyles();
    const history = useHistory();

    const [attendance, setAttendance] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [openConsulta, setOpenConsulta] = React.useState(true);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const [openDialog, setOpen] = React.useState(false);

    
    const handleDialogOpen = () => {
        setOpen(true)
        // setOpenConsulta(true)
    };
    const handleDialogClose = (value) => {
        setOpen(false);
        
    };

    const [openDialog2, setOpen2] = React.useState(false);
    const handleDialogOpen2 = () => {
        setOpen2(true);
    };
    const handleDialogClose2 = (value) => {
        setOpen2(false);
    };

    const openPdf = (healthId) => history.push(`/visualizar-exame/${healthId}`);
    
    return (
        <>
            <Popover
                id="popover1"
                className={ classes.popover }
                classes={{
                    paper: classes.paper,
                }}
                open={ open }
                anchorEl={ anchorEl }
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                onClose={ handlePopoverClose }
                disableRestoreFocus
            >
                <div style={{ padding: "4px 8px", backgroundColor: "#f4f4f4", borderRadius: 4, margin: 4 }}>
                    Profissional: <span style={{ fontWeight: 300 }}>Dr. Carlos Almeida Lopes</span>
                    <Grid container>
                        <Grid item sm={4}>Pediatra</Grid>
                        <Grid item sm={8}>Conselho: <span style={{ fontWeight: 300 }}>CRM-UF 666777</span></Grid>
                    </Grid>
                </div>

                <div style={{ padding: "4px 8px", backgroundColor: "#f4f4f4", borderRadius: 4, margin: 4 }}>
                    Profissional: <span style={{ fontWeight: 300 }}>Dr. André Khalil Müller</span>
                    <Grid container>
                        <Grid item sm={4}>Pneumologista</Grid>
                        <Grid item sm={8}>Conselho: <span style={{ fontWeight: 300 }}>CRM-UF 666777</span></Grid>
                    </Grid>
                </div>
            </Popover>

            
            
            {/* <DialogConsulta close={e => handleDialogClose()} open={openDialog} attendance={attendance} openPdf={e => openPdf(e)} /> */}
            {
              attendance && openDialog && <DialogObservation open={openDialog} close={e => handleDialogClose()} attendance={attendance}/>
            }

            { props.healthAttendances && props.healthAttendances.length > 0 ?
                <>
                    <TableContainer style={{ height: "calc(100vh - 350px)" }}>
                        <Table className={classes.table} aria-label="pacientes table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.td}>&nbsp;</TableCell>
                                    <TableCell className={classes.td}>Protocolo</TableCell>
                                    <TableCell className={classes.td}>Atendimento</TableCell>
                                    <TableCell className={classes.td} align="center">Consulta</TableCell>
                                    <TableCell className={classes.td}>Profissional</TableCell>
                                    <TableCell className={classes.td}>Paciente</TableCell>
                                    <TableCell className={classes.td}>Quadro</TableCell>
                                    <TableCell className={classes.td}>Linha de Cuidado</TableCell>
                                    <TableCell className={classes.td}>Solicitação</TableCell>
                                    <TableCell className={classes.td}>Atualização</TableCell>
                                    <TableCell className={classes.td}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {
                                        props.healthAttendances && Array.isArray(props.healthAttendances) ? props.healthAttendances.map((healthAttendance, index) => {

                                            if(localStorage.getItem("health_attendance_interconsultation") && localStorage.getItem("health_attendance_interconsultation") == healthAttendance.id)
                                                props.atender(healthAttendance);

                                            return (
                                                <AttendancesList 
                                                    key={index} 
                                                    healthAttendance={healthAttendance} 
                                                    atender={e => props.atender(e)} 
                                                    openPdf={e => openPdf(e)} 
                                                    setAttendance={e => {setAttendance(e); props.setAttendance(e)}} 
                                                    handleDialogOpen={e => {handleDialogOpen()}}
                                                />
                                            ) 
                                        }) 
                                        : 
                                            ""
                                    }                            
                                </>

                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Pagination
                        count={props.paginateInfo ? props.paginateInfo.last_page : 1}
                        page={props.page}
                        classes= {{ root: classes.pagination, ul: classes.ul }}
                        renderItem={
                            (item)=> <PaginationItem {...item} 
                            classes={{
                                selected: classes.selected,
                                page: classes.page
                            }}
                        />}
                        onChange={(event, page) => props.setPage(page)}
                    />
                </>
                :
                <>
                    { props.loading ?
                        <Backdrop style={{zIndex: 3000, color: 'white', textAlign: 'center'}} open={props.loading}>
                            <Grid container>
                                <Grid item md={12}>
                                    <CircularProgress color="inherit" />                
                                </Grid>
                                <Grid item md={12}>
                                    <Typography variant="body1" className="text-regular text-st">Carregando listagem de atendimentos...</Typography>
                                </Grid>
                            </Grid>
                        </Backdrop>
                        :
                        <Card>
                            <CardContent>
                                <Typography variant='h4'>Nenhum resultado encontrado</Typography>
                            </CardContent>
                        </Card>
                    }
                </>
            }

        </>
    );
}
