import {useEffect, useState} from "react";
import { Grid, Typography } from "@material-ui/core"

function PatientInfo(props)
{

    useEffect(() => {
        console.log(props.patientInfo);
    }, [])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <h3 style={{ fontSize: 18, color: "#2a814e", margin: 0 }}>Dados do paciente</h3>
            </Grid>
            <Grid item>
                <Grid container direction="column">                    
                    <Grid item>
                        <Typography><strong>Nome social:</strong> { props.patientInfo.social_name }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Pronome:</strong> { props.patientInfo.pronoun ? props.patientInfo.pronoun.name : "Não informado" }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Nome completo:</strong> { props.patientInfo.full_name }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Sexo:</strong> { props.patientInfo.biological_sex }</Typography>
                    </Grid>
                    <Grid item>
                        { <Typography><strong>Genêro:</strong> { props.patientInfo.genre && (Array.isArray(props.patientInfo.genre) && typeof props.patientInfo.genre[0] !== 'undefined') ? props.patientInfo.genre[0].description : "Não informado" }</Typography> }
                    </Grid>
                    <Grid item>
                        { <Typography><strong>Orientação sexual:</strong> { props.patientInfo.sexual_orientation && (Array.isArray(props.patientInfo.sexual_orientation) && typeof props.patientInfo.sexual_orientation[0] !== 'undefined') ? props.patientInfo.sexual_orientation[0].description : "Não informado" }</Typography> }
                    </Grid>
                    <Grid item>
                        <Typography><strong>Idade:</strong> { props.patientInfo.age }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Raça:</strong> { props.patientInfo.breed && (Array.isArray(props.patientInfo.breed) && typeof props.patientInfo.breed[0] !== 'undefined') ? props.patientInfo.breed[0].description : "Não informado" }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Nome da mãe:</strong> { props.patientInfo.mother_name ? props.patientInfo.mother_name : "Não informado" }</Typography>
                    </Grid>            
                </Grid>
            </Grid>
            <Grid item>
                <h3 style={{ fontSize: 16, color: "#2a814e", margin: 0 }}>Deficiências</h3>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    { (props.patientInfo.patient_deficiencies && Array.isArray(props.patientInfo.patient_deficiencies)) && props.patientInfo.patient_deficiencies.map(patient_deficiencies  => 
                        <Grid item>
                            <Typography>{ patient_deficiencies.value }</Typography>
                        </Grid> ) }
                </Grid>
            </Grid>
            <Grid item>
                <h3 style={{ fontSize: 16, color: "#2a814e", margin: 0 }}>Documentação</h3>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    { (props.patientInfo.patient_document && Array.isArray(props.patientInfo.patient_document)) && props.patientInfo.patient_document.map(patient_document => 
                        <Grid item>
                            <Typography><strong>{ patient_document.document_type ? patient_document.document_type.description : "Tipo não informado" }:</strong> { patient_document.patient_document_fields && Array.isArray(patient_document.patient_document_fields) && patient_document.patient_document_fields[0].value !== "" ? patient_document.patient_document_fields[0].value : "Não informado" }</Typography>
                        </Grid> ) }
                </Grid>
            </Grid>
            <Grid item>
                <h3 style={{ fontSize: 16, color: "#2a814e", margin: 0 }}>Endereço</h3>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item>
                        <Typography><strong>Rua:</strong> { props.patientInfo.patient_address && Array.isArray(props.patientInfo.patient_address) ? props.patientInfo.patient_address[0].street : "Não informado" }</Typography>
                    </Grid>            
                    <Grid item>
                        <Typography><strong>Bairro:</strong> { props.patientInfo.patient_address && Array.isArray(props.patientInfo.patient_address) ? props.patientInfo.patient_address[0].district : "Não informado" }</Typography>
                    </Grid>            
                    <Grid item>
                        <Typography><strong>Cidade:</strong> { props.patientInfo.patient_address && Array.isArray(props.patientInfo.patient_address) && props.patientInfo.patient_address[0].city ? props.patientInfo.patient_address[0].city[0].description : "Não informado" }</Typography>
                    </Grid>
                    <Grid item>
                        <Typography><strong>Número:</strong> { props.patientInfo.patient_address && Array.isArray(props.patientInfo.patient_address) ? props.patientInfo.patient_address[0].number : "Não informado" }</Typography>
                    </Grid>            
                    <Grid item>
                        <Typography><strong>CEP:</strong> { props.patientInfo.patient_address && Array.isArray(props.patientInfo.patient_address) ? props.patientInfo.patient_address[0].zip_code : "Não informado" }</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <h3 style={{ fontSize: 16, color: "#2a814e", margin: 0 }}>Contatos</h3>
            </Grid>
            <Grid item>
                <Grid container direction="column">
                    <Grid item><Typography><strong>E-mail:</strong> { props.patientInfo.email }</Typography></Grid>
                    { (props.patientInfo.patient_contact && Array.isArray(props.patientInfo.patient_contact)) && props.patientInfo.patient_contact.map(patient_contact  => 
                        <Grid item>
                            <Typography><strong>{ patient_contact.observation } ({ patient_contact.name }):</strong> { patient_contact.phone }</Typography>
                        </Grid> ) }
                </Grid>
            </Grid>
        </Grid>
    )
}

PatientInfo.defaultProps = {
    patientInfo: null
}

export default PatientInfo;