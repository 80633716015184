import { makeStyles, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../../../../../context/FormBuilderContext";
import DInput from "../../../../../../shared/DInput";

const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiInput-input': {
      width: '200px !important',
      height: '30px !important',
      border: 0,
    },
    '& .MuiInput-underline': {
      width: '200px !important',
      height: '30px !important'
    }
  },
}));

const EBNumber = ({ id, label, placeholder, value, helper_text, is_required, is_read_only, erro, additional_data, condicional, readOnly, answer }) => {
  const { handleChange, handleInputSubmit } = useContext(FormBuilderContext);
  const [input, setInput] = useState();
  const classes = useStyles();

  const handleInputChange = (e) => {
    setInput(e.target.value)
  }

  useEffect(() => {
    if(answer.length > 0){
      setInput(answer[0])
    }
  },[])
  
  
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
      <Typography
        variant="body1"
        className="text-bold text-primary"
        style={{ fontSize: "1em", display: "flex"}}
      >
        <div dangerouslySetInnerHTML={{ __html: `${additional_data.construct.showTitle ? label : ""}` }} />
        <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>{is_required === 1 ? '*' : ''}</span>
      </Typography>
      <TextField
          id={id}
          // label={additional_data.construct.showTitle ? additional_data.construct.showTitle : ""}
          placeholder={placeholder ? placeholder : ""}
          value={input}
          className={classes.textField}
          readOnly={readOnly}
          required={is_required === 1 ? true : false}
          error={ erro[0] ? erro[0].value : '' }
          helperText={ erro[0] ? erro[0].value : '' }
          type="number"
          positionIcon="end"
          onChange={ e => {
            if(erro !== null && erro.length > 0){
              delete erro[0].value
            }
            handleInputChange(e)
          } }
          onBlur={e => {!readOnly ? handleInputSubmit({[id]: input}) : console.log('effect onBlur')}}
        />
        <br/>
        <span style={{ fontSize: 12 }} className="text-light">{helper_text ? helper_text : ""}</span>
      </div>
    </section>
  );
};

export default EBNumber;
