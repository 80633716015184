import React, { useState } from 'react';
import "../index.scss";
import PrescricaoNewTab from '../../NewTabs/PrescricaoNewTab';

export default function SubmenuNewCardsPrescricao(props) {
  const [tab, setTab] = useState(0)
  const tabList = [
    <PrescricaoNewTab />,
  ]


  return (
    <div className='main_flex_submenu_new_cards'>
      <div>
        {tabList[tab]}
      </div>
    </div>
  )
}




