import { useState, useEffect } from "react";

import { Box, Grid, Button,
    Checkbox,
    FormControlLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { toast } from "react-toastify";

import DInput from "../../../../../../shared/DInput";
import DDateInput from '../../../../../../shared/DDateInput';
import DMultAutoComplete from "../../../../../../shared/DMultAutoComplete";
import DAutoComplete from "../../../../../../shared/DAutoComplete";

import useError from "./../../../../../../../hooks/useError";
import { required, requiredOptionalTwoName, validaRG } from "./../../../../../../../helpers/validacoes";
import { updateDocument, getPatientById, updatePatient } from "./../../../../../../../api/patient";

const useStyles = makeStyles((theme) => ({
    dados_titulo: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
        color: theme.palette.primary.light,
        margin: 0
    },
    dados_editButton: {
        color: theme.palette.primary.light,
        textTransform:'none',
        fontWeight: 400
    },
    dados_saveButton: {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: 400
    }
}));

function IdentidadeEstrangeiro(props)
{
    const classes = useStyles();

    const [editar, setEditar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState(props.patient);

    useEffect(() => {
        getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
            if(res.status) {
                setPatient(res.details.patient);
            }
        }).catch(console.error)
    }, []);

    const countrys = props.dominios.country.map((item) => {
        return { id: item.id, value: item.description };
    });

    function formatDate(date) {
        if(date && date != '') {
            var day   = date.substring(0,2);
            var month = date.substring(3,5);
            var year  = date.substring(6);
            
            return [year, month, day].join('-');
        } else {
            return ''
        }
    }

    const renderPassaportePaisEmissor = passport => {
        if(passport && passport.value && passport.value.pais_emissor.value) {
            return passport.value.pais_emissor.value.description;
        } else {
            return '-';
        }
    }

    const View = (props) => {
        return (
            <>
                { props.patient.passport &&                
                    <Grid container>
                        <Grid item md={6}>
                            <h5 className={ classes.dados_titulo }>Identidade</h5>
                        </Grid>
                        <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                            <Button variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton } onClick={e => setEditar(true) }>
                                <EditIcon fontSize="small" />
                                <Box ml={1}>Editar</Box>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Número do passaporte:</strong> { props.patient.passport.value && props.patient.passport.value.numero ? props.patient.passport.value.numero.value : '-' }
                        </Grid>
                        <Grid item md={6}>
                            <strong>Data de emissão do passaporte:</strong> { props.patient.passport.value.data_emissao.value.view }
                        </Grid>
                        <Grid item xs={6}>
                            <strong>País emissor do passaporte:</strong> { renderPassaportePaisEmissor(props.patient.passport) }
                        </Grid>
                        <Grid item md={6}>
                            <strong>Data de validade do passaporte:</strong> { props.patient.passport.value.data_validade.value.view }
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Nome da mãe:</strong> { props.patient.mother_know ? (props.patient.mother_name || null) : "Desconhecida" }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const Edit = (props) => {
        const [identidadeDataEmissao, setIdentidadeDataEmissao]               = useState("");
        const [passaporteDataEmissaoValida, setPassaporteDataEmissaoValida]   = useState(true);
        const [identidadeDataValidade, setIdentidadeDataValidade]             = useState("");
        const [passaporteDataValidadeValida, setPassaporteDataValidadeValida] = useState(true);
        const [identidadePaisEmissor, setIdentidadePaisEmissor]               = useState(props.patient.passport.value.pais_emissor.value ? props.patient.passport.value.pais_emissor.value.id : null);
        const [identidadeNumero, setIdentidadeNumero]                         = useState("");
        const [nomeMae, setNomeMae]                                           = useState("");
        const [maeDesconhecida, setMaeDesconhecida]                           = useState(!props.patient.mother_know);

        const validateDate = {
            dataEmissaoPassaporte: () => {
                if (passaporteDataEmissaoValida) {
                    var [day, month, year] = identidadeDataEmissao.split("/");
    
                    var _identidadeEmissao = new Date(`${year}-${month}-${day}`);
    
                    if(_identidadeEmissao > new Date()) {
                        return "Data não pode ser maior que a data atual."
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        return "Data inválida.";
                    }
                } else {
                    return "Data inválida.";
                }
            },
            dataValidadePassaporte: () => {
                if (passaporteDataValidadeValida) {
                    var [day, month, year] = identidadeDataValidade.split("/");
    
                    var _identidadeDataValidade = new Date(`${year}-${month}-${day}`);
    
                    if(_identidadeDataValidade > new Date()) {
                        return "Data não pode ser maior que a data atual."
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        return "Data inválida.";
                    }
                } else {
                    return "Data inválida.";
                }
            }
        }

        const [error, validarFormulario, setErrors] = useError({
            identidadeDataEmissao: validateDate.dataEmissaoPassaporte,
            identidadeDataValidade: validateDate.dataValidadePassaporte,
            identidadePaisEmissor: required,
            identidadeNumero: required,
            nomeMae: requiredOptionalTwoName
        })

        useEffect(() => {
            if(props.patient.passport) {
                setIdentidadeDataEmissao(props.patient.passport.value.data_emissao.value.view)
                setIdentidadeDataValidade(props.patient.passport.value.data_validade.value.view)
                setIdentidadePaisEmissor(props.patient.passport.value.pais_emissor.value ? props.patient.passport.value.pais_emissor.value.id : null)
                setIdentidadeNumero(props.patient.passport.value.numero.value)
                setNomeMae(props.patient.mother_name)      
                setMaeDesconhecida(props.patient.mother_know === 0 ? true : false);
            }
        }, [])

        return (
            <form onSubmit={ form => {
                form.preventDefault();
                if(validarFormulario({
                    identidadeDataEmissao,
                    identidadeDataValidade,
                    identidadePaisEmissor,
                    identidadeNumero,
                    nomeMae
                })){

                    setLoading(true);

                    const fields = [
                        {
                            id: props.patient.passport.value.data_emissao.id,
                            value: formatDate(identidadeDataEmissao)
                        },
                        {
                            id: props.patient.passport.value.data_validade.id,
                            value: formatDate(identidadeDataValidade)
                        },
                        {
                            id: props.patient.passport.value.pais_emissor.id,
                            value: identidadePaisEmissor
                        },
                        {
                            id: props.patient.passport.value.numero.id,
                            value: identidadeNumero
                        }
                    ];

                    getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                        if(res.status) {
                            var _patient = res.details.patient;

                            if(_patient && typeof _patient === 'object') {

                                _patient.mother_know = maeDesconhecida ? false : true;

                                updatePatient(localStorage.getItem("token"), {..._patient}, props.patient.id)
                                    .then(() => {
                                        updateDocument(localStorage.getItem('token'), { mother_name: nomeMae ? nomeMae : null, fields }, props.patient.passport.id).then(res => {

                                            if(res.status) {
                                                toast.success('Identidade atualizada com sucesso.')
                                                getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                                                    if(res.status) {
                                                        setPatient(res.details.patient);
                                                    }
                                                }).catch(console.error)
                                            }
                    
                                            setEditar(false);
                                            setLoading(false);
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            setEditar(false);
                                            setLoading(false);
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);

                                        setEditar(false);
                                        setLoading(false);
                                    })

                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setEditar(false);
                        setLoading(false);
                    })

                   
                } else {
                    toast.warn("Preencha todos os campos corretamente.")
                }
            } }>
                <Grid container>
                    <Grid item md={6}>
                        <h5 className={ classes.dados_titulo }>Identidade</h5>
                    </Grid>
                    <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                        <Button type="submit" variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton }>
                            <SaveIcon fontSize="small" />
                            <Box ml={1}>{ loading ? <CircularProgress size={ 15 } color="inherit" /> : 'Salvar' }</Box>
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Número do passaporte:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <DInput
                                    id="numero-do-passaporte"
                                    placeholder="Número do passaporte"
                                    style={{ marginTop: 5 }}
                                    type="text"
                                    inputProps={{maxLength: 9}}
                                    error={ error.identidadeNumero }
                                    helperText={ error.identidadeNumero }
                                    fullWidth
                                    onChange={ e => {
                                        delete error.identidadeNumero;
                                        setIdentidadeNumero(e.target.value.replace(/[^0-9a-zA-Z]/g, ''));
                                    } }
                                    value={ identidadeNumero }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Data de emissão do passaporte:</strong> 
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <DDateInput 
                                    id="identidade-data-emisao"
                                    error={ error.identidadeDataEmissao }
                                    helperText={ error.identidadeDataEmissao }
                                    fullWidth
                                    label=""
                                    maxDate={ new Date() }
                                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                                    value={ identidadeDataEmissao }
                                    onError={(errorMessage) => {
                                        setErrors({ ...error, identidadeDataEmissao: errorMessage })
                                    }}
                                    onChange={(arg1, arg2, e) => {
                                        delete error.identidadeDataEmissao
                                    
                                        setIdentidadeDataEmissao(arg1);
                                        setPassaporteDataEmissaoValida(arg2.isValid);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>                    
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Data de validade do passaporte:</strong> 
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <DDateInput 
                                    id="identidade-data-validade"
                                    error={ error.identidadeDataValidade }
                                    helperText={ error.identidadeDataValidade }
                                    fullWidth
                                    label=""
                                    maxDate={ new Date() }
                                    maxDateMessage="A data de validade não pode ser maior que a data atual"
                                    value={ identidadeDataValidade }
                                    onError={(errorMessage) => {
                                        setErrors({ ...error, identidadeDataValidade: errorMessage })
                                    }}
                                    onChange={(arg1, arg2, e) => {
                                        delete error.identidadeDataValidade
                                    
                                        setIdentidadeDataValidade(arg1);
                                        setPassaporteDataValidadeValida(arg2.isValid);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>País emissor do passaporte:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <div className='input-info'>
                                        <DAutoComplete
                                            id="identidade-pais-emissor"
                                            placeholder="País emissor do passaporte"
                                            labelColor='black'
                                            fullWidth
                                            error={ error.identidadePaisEmissor }
                                            helperText={ error.identidadePaisEmissor }
                                            defaultValue={ countrys.filter( oe => oe.id == identidadePaisEmissor )[0] }
                                            options={ countrys }
                                            getOptionLabel={(option) => option.value}
                                            getOptionSelected={(option, value) => value && option.id === value.id}
                                            onChange={(event, newValue) => {
                                                delete error.identidadePaisEmissor;
                                                setIdentidadePaisEmissor(newValue ? newValue.id : "");
                                            }}
                                        />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Nome da mãe:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <DInput
                                    id="NomeMae"
                                    placeholder="Nome da mãe"
                                    disabled={maeDesconhecida}
                                    style={{ marginTop: 5 }}
                                    type="text"
                                    error={ error.nomeMae }
                                    helperText={ error.nomeMae }
                                    fullWidth
                                    onChange={ e => {
                                        delete error.nomeMae;
                                        setNomeMae(e.target.value);
                                    } }
                                    value={ nomeMae }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Mãe desconhecida</strong>
                            </Grid>
                            <FormControlLabel
                        control={
                            <Checkbox
                            defaultChecked={ maeDesconhecida }
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={ e => {
                                setMaeDesconhecida(e.target.checked);

                                if(!maeDesconhecida) {
                                    setNomeMae('');
                                    delete error.nomeMae;
                                }
                            }}
                            />
                        }
                        label=""
                        />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <>
            { patient ?
                <>
                    { editar ? <Edit patient={ patient } /> : <View patient={ patient } /> }
                </>
                :
                ''
            }
        </>
    )
}

export default IdentidadeEstrangeiro;