import { TextField } from "@material-ui/core";

function Smoking(props)
{
    function getType()
    {
        if (typeof props.preForm !== 'undefined') {
            switch(props.preForm.smoking) {
                case 'Fumante':
                    return `Fumante por ${props.preForm.smoking_period} cigarros: ${props.preForm.smoking_period_without}`;
                case 'Ex-fumante':
                    return `Ex-fumante, ${props.preForm.smoking_period}, ${props.preForm.smoking_period_without}`;
                default:
                    return "Não fumante";
            }
        }
    }

    return <TextField className='input_new_solicitante_new_tab' id="standard-basic" label="Tabagismo" disabled value={getType()} variant="standard" />
}

export default Smoking;