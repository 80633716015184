import React from 'react';
import "../index.scss";
import { TextField } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create, Save } from '@material-ui/icons';
import { toast } from "react-toastify";
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { useState } from 'react';
import useError from '../../../../hooks/useError';
import GroupsView from './GroupsView';
import LineCareView from './LineCareView';
import LineCareEdit from './LineCareEdit';
import GroupsEdit from './GroupsEdit';
import { required } from '../../../../helpers/validacoes';
import CancelIcon from '@material-ui/icons/Cancel';


export default function GruposNewTab(props) {
  const { healthAttendance, setHealthAttendance, patient, setPatient } = useNewHealthAttendance();
  const [edit, setEdit] = useState(false);

  const [groups, setGroups] = useState();
  const [lineCares, setLineCares] = useState();
  

  return (
    <div className='fundo_new_tab'>
      <div className='main_flex_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Grupo de paciente
        </div>
        <div className='flex_end_new_tab'>
          { !edit ? 
            <Button className="botao_editar_new_tab text-primary" onClick={ e => setEdit(true) } startIcon={<Create />}>
              Editar
            </Button> :
            <Button className="botao_editar_new_tab text-primary text-error" onClick={ e => setEdit(false) } startIcon={<CancelIcon />}>
              Cancelar
            </Button> }  
        </div>
      </div>
      <div className='mt_35_new_tab separa_inputs_new_tabs'>
        <Grid item md={12}>
          
            { !edit ? <GroupsView /> : <GroupsEdit close = { e => setEdit(false)} /> }
        </Grid>
      </div>
      <div className='main_flex_new_tab mt_35_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Linha de cuidado
        </div>
      </div>
      <div className='mt_35_new_tab'>
        <div className='main_div_tag_paciente_tab'>
        { !edit ? <LineCareView /> : <LineCareEdit close = { e => setEdit(false)} /> }
        </div>
      </div>
    </div>
  )
}