import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';

const IOSSwitch = withStyles((theme) =>
    createStyles({
        root: {
            width: 44,
            height: 25,
            padding: 2
        },
        switchBase: {
            padding: 3,
            '&$checked': {
                padding: 3,
                transform: 'translateX(18px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: theme.palette.primary.light,
                    opacity: 1,
                    border: `1px solid ${theme.palette.primary.light}`
                },
            },
            '&$focusVisible $thumb': {
                color: theme.palette.primary.light,
                border: '2px solid #fff',
            },
            color: theme.palette.primary.light
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.primary.light}`,
            backgroundColor: '#FFF',
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {}
    }),
)(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checkedIcon={<CheckCircle fontSize="small" style={{ marginTop: 1 }}  />}
            icon={<Cancel fontSize="small" style={{ marginTop: 1 }}  />}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked
            }}
            {...props}
        />
    );
});

const customClass = makeStyles({
    root: {
      flexDirection: 'row-reverse',
      marginLeft: 0
    },
    label: {
        fontSize: 14,
        marginTop: 5,
        marginRight: 10
    },
});

function DSwitch(props) {

    const classes = customClass();

    return (
        <FormControlLabel control={<IOSSwitch disabled={props.disabled} checked={props.checked} onChange={props.onChange} name={ props.name } />}
            label={ props.label }
            classes={ {
                label: classes.label,
                root: classes.root
            } }
        />
    )
}

export default DSwitch;