import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Chip, Grid, Link, Typography } from "@material-ui/core";
import { Clear, Edit, Send } from "@material-ui/icons";
import DButton from "../../../shared/DButton";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  gridButton: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
  },
  gridLink: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  headerModalSuccess: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    margin: 20,
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  }  
}));

export default function MdResendInformationPatient(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);

  function sendSMS() {
    //try catch if send true open modal success
    setOpenAlertDanger(true);
  }

  function handleCloseAlertDanger() {
    setOpenAlertDanger(false);
  }

  function sendMail() {
    //try catch if send true open modal success
    setOpenAlertSuccess(true);
  }

  function handleCloseAlertSuccess() {
    setOpenAlertSuccess(false);
  }

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography
            className="simple-modal-title text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Send className={classes.icon} />
            Reenviar informações da teleconsulta para o paciente
          </Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              props.onClose(true);
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light">
          <>
            <Grid item md={11} sm={6}>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>Paciente </strong>{" "}
                {"Maria Eleonora da Silva Alves de Gumarães"}
              </Typography>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>CPF:</strong> {"222.222.222-11"}
              </Typography>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>E-mail </strong> {"mariaelo.sag@gmail.com"}
              </Typography>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>Celular: </strong> {"11 99999-9999"}
              </Typography>
            </Grid>

            <Grid item md={11} sm={6} className={classes.gridButton}>
              <DButton variant="primary" size="sm" onClick={sendMail}>
                Enviar e-mail
              </DButton>
              <DButton variant="primary" size="sm" onClick={sendSMS}>
                Enviar SMS
              </DButton>
            </Grid>
            <Grid item md={11} sm={6} className={classes.gridLink}>
              <Edit className={classes.icon} />
              <Link>Editar dados do Paciente</Link>
            </Grid>
          </>
        </Grid>
      </>
    </div>
  );

  const alertSMS = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography
            className="simple-modal-title-alert-danger text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            SMS não enviado
          </Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              handleCloseAlertDanger();
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light">
          <>
            <Grid item md={11} sm={6}>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                {
                  "O número de celular está incorreto, corrija nos dados do paciente e tente novamente."
                }
              </Typography>
            </Grid>
            <Grid item md={11} sm={6} className={classes.gridLink}>
              <Edit className={classes.icon} />
              <Link>Editar dados do Paciente</Link>
            </Grid>
          </>
        </Grid>
      </>
    </div>
  );
  const alertSuccess = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headerModalSuccess}>
          <Typography
            className="simple-modal-title-alert-success text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            E-mail enviado com sucesso
          </Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              handleCloseAlertSuccess();
            }}
          />
        </div>
      </>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openAlertDanger}
        onClose={handleCloseAlertDanger}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alertSMS}
      </Modal>
      <Modal
        open={openAlertSuccess}
        onClose={handleCloseAlertSuccess}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alertSuccess}
      </Modal>
    </div>
  );
}
