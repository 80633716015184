import env from "react-dotenv";

function checkPatientDocument(documentValue, token) {
  return fetch(`${env.API_URL}/document/patient/check?documentValue=${documentValue}&token=${ token }`, {
    method: "get",
  }).then((res) => res.json());
}

function checkUserDocument(documentValue, token) {
    return fetch(`${env.API_URL}/document/user/check?documentValue=${documentValue}&token=${ token }`, {
      method: "get",
    }).then((res) => res.json());
  }

export { checkPatientDocument, checkUserDocument };