import { useEffect } from "react";
import { IconButton, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import DDateInput from "../../../shared/DDateInput";
import { format } from "date-fns";
import { makeStyles, useTheme } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  primaryMainBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  primaryMainText: {
    backgroundColor: theme.palette.primary.main,
  },
  primaryLightBackground: {
    backgroundColor: theme.palette.primary.light,
    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      color: "white",
    },
  },
  primaryDark: {
    backgroundColor: theme.palette.primary.dark,

    color: "white",

    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
  },
}));

export default function AgendaSemana(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div hidden={props.calendar !== 0}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 278,
            margin: "0 auto",
            borderRadius: 8,
            padding: 24,
            backgroundColor: "#ffffff",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
            {props.selectedWeekDays &&
              props.selectedWeekDays.map((data, index) => {
                if (data.day === "dom") {
                  return (
                    <li
                      style={
                        data.isToday
                          ? {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 28,
                              padding: "4px 10px",
                              color: "#fff",
                              borderRadius: 8,
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 32,
                            }
                      }
                      className={
                        data.isToday ? classes.primaryLightBackground : ""
                      }
                    >
                      <Typography style={{ fontSize: 12, width: 25 }}>
                        {data.day}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 14,
                          width: 18,
                          fontWeight: 700,
                          margin: "0 24px",
                        }}
                      >
                        {data.dayNumber}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        {data.scheduleCommitment.length} Compromissos
                      </Typography>
                    </li>
                  );
                } else {
                  return (
                    <li
                      style={
                        data.isToday
                          ? {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 28,
                              padding: "4px 10px",
                              color: "#fff",
                              borderRadius: 8,
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              marginBottom: data.day === "sab" ? 0 : 28,
                              marginLeft: data.day === "sab" ? 0 : 10,
                            }
                      }
                      className={
                        data.isToday ? classes.primaryLightBackground : ""
                      }
                    >
                      <Typography
                        style={{ fontSize: 12, width: 25, textAlign: "right" }}
                      >
                        {data.day}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 14,
                          width: 18,
                          color: data.isToday
                            ? "#fff"
                            : classes.primaryMainText,
                          fontWeight: 700,
                          margin: "0 24px",
                        }}
                      >
                        {data.dayNumber}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        {data.scheduleCommitment.length} Compromissos
                      </Typography>
                    </li>
                  );
                }
              })}
          </ul>
          <div
            style={{
              height: 355,
              marginTop: -12,
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <IconButton>
              <ExpandLessIcon
                fontSize="small"
                style={{ color: classes.primaryMainText }}
              />
            </IconButton>
            <IconButton>
              <ExpandMoreIcon
                fontSize="small"
                style={{ color: classes.primaryMainText }}
              />
            </IconButton>
          </div>
        </div>
      </div>

      <div hidden={props.calendar !== 1}>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            borderRadius: 8,
            overflow: "hidden",
            backgroundColor: "#ffffff",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={ props.selectedDate }
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div hidden={props.calendar !== 2}>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            backgroundColor: "#fff",
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography style={{ fontSize: 14, margin: "16px 28px 0" }}>
            {format(new Date().setMonth(new Date().getMonth() - 1), "MMMM", {
              locale: ptBR,
            })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date().setMonth(new Date().getMonth() - 1)}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div
          style={{
            width: 326,
            margin: "24px auto",
            backgroundColor: "#fff",
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography
            style={{
              fontSize: 14,
              margin: "16px 28px 0",
              padding: "4px 16px",
              color: "#fff",
              backgroundColor: theme.palette.primary.main,
              borderRadius: 8,
              fontSize: 14,
            }}
          >
            {format(new Date(), "MMMM", { locale: ptBR })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date()}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div
          style={{
            width: 326,
            margin: "0 auto",
            backgroundColor: "#fff",
            borderRadius: 8,
            overflow: "hidden",
            boxShadow:
              "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
          }}
        >
          <Typography style={{ fontSize: 14, margin: "16px 28px 0" }}>
            {format(new Date().setMonth(new Date().getMonth() + 1), "MMMM", {
              locale: ptBR,
            })}
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              // autoOk
              disableToolbar="false"
              orientation="landscape"
              variant="static"
              openTo="date"
              value={new Date().setMonth(new Date().getMonth() + 1)}
              onChange={(e) => props.setSelectedDate(e)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  );
}
