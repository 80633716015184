import { useState, useEffect } from "react";
import { Grid } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { Bio } from "./../../../api/biomedidas";
import { toast } from "react-toastify";
import BiomedidaCard from "./BiomedidaCard";
import { getMe } from "./../../../api/user";

function Biomedidas(props)
{

    const [biomedidas, setBiomedidas] = useState([]);   
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        Bio.getByHealthAttendance(localStorage.getItem('token'), props.healthAttendanceId).then(res => {
            if(res.data.status)
                setBiomedidas(res.data.biomeasure_exam_health_attendances);
        })

        getMe(localStorage.getItem('token')).then(response => {
            if(response.token_details)
                setProfile(response.token_details.acl.profile_id);
        })
    }, [])

    useEffect(() => {
        if(props.updateList) {
            Bio.getByHealthAttendance(localStorage.getItem('token'), props.healthAttendanceId).then(res => {
                if(res.data.status) {
                    setBiomedidas(res.data.biomeasure_exam_health_attendances);
                    props.onUpdated(true);
                }
            })
        }
    }, [props.updateList])

    const handleRemove = (id) => {

        toast.warn("Removendo biomedida.");

        Bio.destroyByHealthAttendance(localStorage.getItem('token'), id).then(res => {
            if(res.data.status) {
                toast.success("Biomedida removida com sucesso.");
                Bio.getByHealthAttendance(localStorage.getItem('token'), props.healthAttendanceId).then(res => {
                    if(res.data.status)
                        setBiomedidas(res.data.biomeasure_exam_health_attendances);
                    else {
                        toast.error("Erro inesperado ao listar biomedidas.");
                        setBiomedidas([]);
                    }
                })
            } else {
                toast.error("Erro inesperado ao remover.");
                setBiomedidas([]);
            }
        })
    }

    const handleAction = (action, biomedida) => {
        switch(action){
            case "remove":
                handleRemove(biomedida.id);
                break;
            case "edit":
                props.onEdit(biomedida)
                break;                
        }
    }

    return(<>
        <Grid container spacing={1} alignItems="flex-end">
            { biomedidas.map((bio, index) => {
                return <Grid item md={3}>
                    <BiomedidaCard key={ `biomedidacard-${index}` }                         
                        profile={profile} 
                        patient={ props.patientId } 
                        biomedida={ bio } 
                        onAction={ action => handleAction(action, bio) } />
                </Grid>
            }) }
        </Grid>
    </>)
}

Biomedidas.defaultProps = {
    updateList: false,
    healthAttendanceId: null,
    patientId: null,
    onUpdated: function() {
        console.log('onUpdated');
    },
    onEdit: function(biomedida) {
        console.log(biomedida);
    }
}

export default Biomedidas;