import axios from 'axios';
import env from "react-dotenv";

export async function listVacinas(token, healthAttendanceId){
        const res = await axios.get(`${env.API_URL}/above/${healthAttendanceId}/vaccinationList?token=${token}`);
        return res.data;
}

export async function vacinaById(token, vaccinationId){
    const res = await axios.get(`${env.API_URL}/above/vaccination/${vaccinationId}?token=${token}`);
    return res.data;
}

export async function includeVacinas(token, healthAttendanceId, data){
    // Example body of data.
    // {
    //     "itens": [
    //         {
    //             "health_establishment": null,
    //             "application": "2010-01-12",
    //             "vaccine": "DUPLA ADULTO",
    //             "dosage": "Primeira Dose",
    //             "lot": "0810148",
    //             "valid_lot": "2010-09-30",
    //             "provider": "",
    //             "professional": "CARLA NORMANDA CRUZ",
    //             "profissional_cns": "801440212665777",
    //             "previous_lot": "",
    //             "other_health_establishment": ""
    //         },
    //         {
    //             "health_establishment": null,
    //             "application": "2010-01-12",
    //             "vaccine": "DUPLA ADULTO",
    //             "dosage": "Primeira Dose",
    //             "lot": "0810148",
    //             "valid_lot": "2010-09-30",
    //             "provider": "",
    //             "professional": "CARLA NORMANDA CRUZ",
    //             "profissional_cns": "801440212665777",
    //             "previous_lot": "",
    //             "other_health_establishment": ""
    //         }
    //     ]
    // }


    const res = axios.post(`${env.API_URL}/above/${healthAttendanceId}/vaccinationInclude?token=${token}`, data);
    return res.data
}

export default {
    listVacinas,
    includeVacinas,
    vacinaById
}