import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chat as ChatIcon,
  AttachFile,
  Send,
  CollectionsOutlined,
  Delete,
} from "@material-ui/icons";
import { Backdrop, CircularProgress } from "@material-ui/core";

// User router
import { getUserInfoById } from "../../../../api/user";

// Conversation API
import {
  createConversation,
  getConversationByAttendenceId,
  addParticipant,
} from "../../../../api/conversations";

// Twilio
const { Client } = require("@twilio/conversations");

const useStyles = makeStyles({
  flutuantDiv: {
    position: "fixed",
    bottom: 40,
    right: "25px",
    zIndex: "20",
  },

  flutuantButton: {
    backgroundColor: "#4a53ea",
    borderRadius: "50%",
    border: "1px solid #4a53ea",
    color: "white",
    padding: "11px 13px",

    cursor: "pointer",
  },

  badge: {
    position: "absolute",
    right: "-5px",
    border: "1pt solid red",
    background: "red",
    padding: "2px 6px",
    color: "white",
    borderRadius: "50%",
    fontSize: "12px",
  },
});


export default function FloatingChat(props) {
  const classes = useStyles();

  const [openChat, setOpenChat] = useState(false);
  const [scrollDiv, setScrollDiv] = useState(React.createRef());
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [userName, setUserName] = useState(null);
  const [sid, setSid] = useState(null);
  const [serviceSid, setServiceSid] = useState(null);
  const [tokenJWT, setTokenJWT] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [file, setFile] = useState(null);

  const inputFileRef = useRef(null);

  // *** LÓGICA PARA START DO CHAT ***
  useEffect(async () => {
    setLoading(true);

    try {
      await getToken(); // Obtém o token JWT
    } catch {
      console.error("Erro ao obter o token JWT");
      setLoading(false);
      return;
    }
  }, []);

  useEffect(() => {
    if (props.idUser) {
      getUserInfoById(localStorage.getItem("token"), props.idUser).then(
        (res) => {
          setUserName(res.user.full_name);
        }
      );
    }
  }, [props.idUser]);

  useEffect(() => {
    if (props.openChat) {
      setOpenChat(true);
    } else {
      setOpenChat(false);
    }
  }, [props.openChat]);

  useEffect(() => {
    async function init() {
      console.log("token", tokenJWT);
      if (!tokenJWT) {
        return;
      } // Se não tiver token, não inicia o chat

      const client = new Client(tokenJWT); // Criação do cliente do Twilio Conversations

      console.log("client", client);

      if (!client) {
        setLoading(false);
        return;
      } // Se não retornar um client, não inicia o chat

      client.on("tokenAboutToExpire", async () => {
        const token = await getToken();
        client.updateToken(token); // Atualiza o token
      });

      client.on("tokenExpired", async () => {
        const token = await getToken();
        client.updateToken(token); // Atualiza o token
      });

      try {
        // Obtém a conversa pelo sid
        console.log("getConversation", sid);
        const conversationObj = await client.getConversationBySid(sid);

        console.log("joinConversation", conversationObj);

        // Inicia a conversa
        joinConversation(conversationObj);
      } catch (err) {
        try {
          console.log("err", err);
          // Cria uma nova conversa
          const conversationObj = await client.createConversation({
            friendlyName: sid,
            attributes: { isPrivate: false },
            uniqueName: userName + localStorage.getItem("uid"),
          });

          console.log("createConversation", conversationObj);

          // Inicia a conversa
          joinConversation(conversationObj);
        } catch (err) {
          console.log("createConversationErr", err);
          setLoading(false);

          console.error("Erro ao criar a conversa", err);
          return;
        }
      }
    }

    init();
  }, [tokenJWT]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleOldMessages = data => {
    const {items} = data;

    setMessages(items || []);
  }

  async function joinConversation(conversation) {
    await conversation
      .join() // Entra na conversa
      .then((r) => {
        console.log("conversation", r);
        setConversation(conversation);
        setLoading(false);

        conversation.getMessages().then(handleOldMessages)

        conversation.on("messageAdded", handleMessageAdded);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);

        if (e.message == "Conflict") {
          setConversation(conversation);

          conversation.on("messageAdded", handleMessageAdded);
          conversation.getMessages().then(handleOldMessages)
        } else {
          setConversation(conversation);
          conversation.on("messageAdded", handleMessageAdded);
          conversation.getMessages().then(handleOldMessages)
        }
      });
  }

  function handleMessageAdded(message) {
    console.log(message);
    setMessages((prev) => [...prev, message]); // Adiciona a mensagem na lista de mensagens
  }

  function scrollToBottom() {
    const scrollHeight = scrollDiv.current.scrollHeight;
    const height = scrollDiv.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    const currentScroll = scrollDiv;

    currentScroll.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    setScrollDiv(currentScroll);
  }

  async function getToken() {
    // Aqui entra a rota para busca do token de inicialização do chat
    var dadosPost = {
      health_attendance_id: props.idAtendimento,
    };

    // Cria a conversa
    const resCreate = await createConversation(
      localStorage.getItem("token"),
      dadosPost
    );

    // Se a conversa foi criada com sucesso
    if (resCreate.status === true) {
      setSid(resCreate.conversation.conversation_sid);
      setServiceSid(resCreate.conversation.chat_service_id);
      setConversationId(resCreate.conversation.id);

      var dadosPostParticipant = {
        health_attendance_id: props.idAtendimento,
        conversation_id: resCreate.conversation.id,
        conversation_sid: resCreate.conversation.conversation_sid,
        chat_service_id: resCreate.conversation.chat_service_id,
      };

      // Adiciona o participante na conversa
      const resUpdate = await addParticipant(
        localStorage.getItem("token"),
        dadosPostParticipant
      );

      if (resUpdate.status) {
        setTokenJWT(resUpdate.conversationParticipant.token);

        return resUpdate.conversationParticipant.token;
      } else {
        return false;
      }
    } else if (resCreate.status === false) {
      // Se já existe uma conversa para este atendimento
      console.log("resCreate", resCreate);
      if (
        resCreate.error ===
        "Já existe um registro de conversation para este atendimento, utilize a rota de pesquisa."
      ) {
        // Busca a conversa pelo atendimento
        const resGet = await getConversationByAttendenceId(
          localStorage.getItem("token"),
          props.idAtendimento
        );
        console.log("resGet", resGet);

        // Se a conversa foi encontrada
        if (resGet.status) {
          setSid(resGet.conversation[0].conversation_sid);
          setServiceSid(resGet.conversation[0].chat_service_id);
          setConversationId(resGet.conversation[0].id);

          // Se não existe nenhum participante na conversa
          if (resGet.conversation[0].conversation_participant.length == 0) {
            var dadosPostParticipantNew = {
              health_attendance_id: props.idAtendimento,
              conversation_id: resGet.conversation[0].id,
              conversation_sid: resGet.conversation[0].conversation_sid,
              chat_service_id: resGet.conversation[0].chat_service_id,
            };

            // Adiciona o participante na conversa
            const resUpdate = await addParticipant(
              localStorage.getItem("token"),
              dadosPostParticipantNew
            );

            // Se o participante foi adicionado com sucesso
            if (resUpdate.status) {
              setTokenJWT(resUpdate.conversationParticipant.token);

              return resUpdate.conversationParticipant.token;
            } else {
              return false;
            }
          } else {
            setTokenJWT(
              resGet.conversation[0].conversation_participant[0].token
            );

            return resGet.conversation[0].conversation_participant[0].token;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function sendMessage() {
    if (file) {
      // Envia a mensagem com o arquivo
      var formData = new FormData();
      formData.append("userfile", file);

      conversation
        .sendMessage(formData)
        .then((message) => {
          // setMessages(prev => [...prev, message]);
          setFile(null);
        })
        .catch((err) => {
          if (err && err.code === 413) {
            alert("Arquivo muito grande");
          } else {
            alert("Erro ao enviar mensagem");
          }
          console.log(err);
        });
    } else if (text) {
      conversation.sendMessage(String(text).trim()); // Envia a mensagem para o servidor do Twilio
      setText("");
    }
  }

  function fileMessage(media) {
    media.getContentTemporaryUrl().then((url) => {
      if (
        media.contentType === "image/jpeg" ||
        media.contentType === "image/png" ||
        media.contentType === "image/gif" ||
        media.contentType === "image/jpg" ||
        media.contentType === "application/pdf"
      ) {
        // this.openImageViewer(url);
        console.log("openImageViewer", url);
        window.open(url, "_blank");
      } else {
        const anchorEl = document.createElement("a");

        anchorEl.href = url;
        anchorEl.target = "_blank";
        anchorEl.rel = "noopener";

        // setTimeout is needed in order to open files in iOS Safari.
        setTimeout(() => {
          anchorEl.click();
        });
      }
    });
  }

  function isOwnMessage(author, msg) {
    // console.log(author, userName)
    return author == userName ? true : false; // Se o participante é o próprio
  }

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 99999 }}>
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      <div
        id="chat-box"
        className={props.notFlutuant ? "not-flutuant" : "flutuant"}
        style={openChat ? { display: "flex" } : { display: "none" }}
      >
        <div className="chat" ref={scrollDiv}>
          {messages &&
            messages.map((message) => (
              <>
                {isOwnMessage(message.author, message) ? (
                  <div className="message-content">
                    {message.media ? (
                      <div
                        className="message-box attachment text-pointer"
                        onClick={(e) => fileMessage(message.media)}
                      >
                        <AttachFile className="text-primary" />
                        <span className="message">
                          {message.media.state
                            ? message.media.state.filename
                            : "Anexo"}
                        </span>
                      </div>
                    ) : (
                      <div className="message-box">
                        <span className="message">{message.body}</span>
                      </div>
                    )}

                    <span className="message-time">
                      {new Date(
                        message.dateCreated.toISOString()
                      ).toLocaleString()}
                    </span>
                  </div>
                ) : (
                  <div className="message-content from">
                    <span className="username">{message.author}</span>

                    {message.media ? (
                      <div className="message-box from attachment text-pointer">
                        <AttachFile className="text-primary" />
                        <span className="message">
                          {message.media.state
                            ? message.media.state.filename
                            : "Anexo"}
                        </span>
                      </div>
                    ) : (
                      <div className="message-box from">
                        <span className="message">{message.body}</span>
                      </div>
                    )}

                    <span className="message-time">
                      {new Date(
                        message.dateCreated.toISOString()
                      ).toLocaleString()}
                    </span>
                  </div>
                )}
              </>
            ))}
        </div>
        <div className="chat-actions">
          <input
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            hidden
            ref={inputFileRef}
          />
          {file ? (
            <input
              type="text"
              maxLength="255"
              // value={text}
              placeholder={file.name || "Arquivo anexado"}
              disabled
            />
          ) : (
            <input
              type="text"
              placeholder="Digite sua mensagem"
              maxLength="255"
              value={text}
              disabled={!conversation}
              onChange={(event) => setText(event.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  setText(event.target.value);
                }
              }}
            />
          )}
          <div className="action-buttons text-primary">
            {file ? (
              <button
                className="delete-share"
                onClick={(e) => {
                  e.preventDefault();

                  setFile(null);
                }}
                disabled={!conversation}
              >
                <Delete style={{ color: "red" }} />
              </button>
            ) : (
              <button
                className="share"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("share");

                  if (inputFileRef && inputFileRef.current) {
                    inputFileRef.current.click();
                  }
                }}
                disabled={!conversation}
              >
                <AttachFile className="text-primary" />
              </button>
            )}
            <button
              className="send"
              onClick={(e) => sendMessage()}
              disabled={!conversation}
            >
              <Send className="text-primary" />
            </button>
          </div>
        </div>
      </div>

      {!props.videocall ? (
        <>
          <div
            className={classes.flutuantDiv}
            onClick={(e) => setOpenChat(!openChat)}
          >
            <span className={classes.badge}>2</span>
            <div>
              <button className={classes.flutuantButton}>
                <ChatIcon />
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
