import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import SimpleModal from "../Modal";
import { getTermofUse } from "./../../../api/methods";
import { CircularProgress } from "@material-ui/core";

function FooterLogin(props) {

    const [term, setTerm] = useState(false);

    useEffect(function(){
        getTermofUse().then(res => {
            if(res && res.status)
                setTerm(res.term.term);
        })
    }, []);

    const TermContent = () => (
        <div dangerouslySetInnerHTML={{ __html: term }} />
    );

    return (
        <>
            <SimpleModal title="Termo de Uso e Política de Privacidade" fullWidth={true} open={ props.modal } onClose={ e => props.onCloseModal(true) }>
                <Grid container justifyContent="center" alignItems="center" style={{ height: 539, overflowY: 'auto', padding: 15 }}>
                    <Grid item>
                        { term ? <TermContent /> : <CircularProgress /> }
                    </Grid>
                </Grid>
            </SimpleModal>
        </>
    )
}

export default FooterLogin;