import {useState, useContext, useEffect} from "react";
import { format } from 'date-fns'
import AtendimentoGrid from "./components/AtendimentoGrid";
import AtendimentoFilter from "./components/AtendimentoFilter";
import { Grid } from "@material-ui/core";
import AddCircle from '@material-ui/icons/AddCircle';
import DButton from "../../shared/DButton";
import DButtonCustom from "../../shared/DButtonCustom";
import { makeStyles, withStyles } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AtendimentoContext } from "../../../context/AtendimentoContext"
import { AppContext } from "../../../context/AppContext";
import { getHealthAttendanceByPage, getAllHealthAttendance, getHealthAttendanceByPageFilter } from '../../../api/health_attendances';
import { Link, Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

const useStyles = makeStyles({
    table: {
        minWidth: 1800,
    },
    paciente__topo: {
        position: "absolute",
        left: 0,
        top: 88,
        width: "100%",
        height: 61,
        display: "flex",
        padding: "10px 40px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 18
    },
    paciente__tabs: {
        marginTop: 93
    },
    paciente__nome: {
        height: 20,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "#000",
        fontWeight: 600
    },
    icon: {
        fontSize: 15
    }
});

const NovaSolicitacaoButton = withStyles({
    root: {
        height: 41,
        width: 176,
        minWidth: 176,
        borderRadius: 100,
        paddingTop: '10px !important',
        paddingRight: '22px !important',
        paddingBottom: '10px !important',
        paddingLeft: '22px !important'           
    },
    label: {
        width: 130.5,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '21px',
        letterSpacing: 0,
        textAlign: 'center',
        textTransform: 'initial',
        minWidth: 'max-content',
        textDecoration: 'none',
        textIndent: 'initial',
        '&:hover': {
            textIndent: 'initial'
        },
        '&:active': {
            textIndent: 'initial'
        },
        '&:focus': {
            textIndent: 'initial'
        }
    }
  })(DButton);

function AtendimentoList(props) {
    const classes = useStyles();
    document.body.style.backgroundColor = "#ffffff";
    const {setAttendance, setEmAtendimento, setAtendimentoStatus} = useContext(AtendimentoContext);
    const {AttendanceFilter, setAttendancesList, lockAdministrativeUnitMenu, updateAcl, setUpdateAcl, totais, setTotais, applyFilter, setApplyFilter} = useContext(AppContext);

    const [healthAttendances, setHealthAttendances] = useState(null);
    const [paginateInfo, setPaginateInfo] = useState(null);
    const [filteredAttendances, setFilteredAttendances] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)

    const history = useHistory();

    function filterAttendances () {
            var filtered = [];
        
            if(AttendanceFilter.status.todos){
                return setFilteredAttendances(healthAttendances)
            } else{

                var _linecare = AttendanceFilter.linhaDeCuidado;
                var _quadro = AttendanceFilter.quadro;

                if(AttendanceFilter.status.aberto){
                    var _abertos = healthAttendances.filter(attendance => attendance.health_attendance_status_id == 1);

                    // setFilteredAttendances(_abertos);
                    filtered.push(..._abertos);
                    // setAttendancesList(_abertos);
                } else if(AttendanceFilter.status.assumido){
                    var _assumidos = healthAttendances.filter(attendance => attendance.health_attendance_status_id == 2);

                    // setFilteredAttendances(_assumidos);
                    filtered.push(..._assumidos);
                    // setAttendancesList(_assumidos);
                } else if(AttendanceFilter.status.finalizado){
                    // cancelados/finalizados
                    var _finalizados = healthAttendances.filter(attendance => attendance.health_attendance_status_id == 3 || attendance.health_attendance_status_id == 4);

                    // setFilteredAttendances(_finalizados);
                    filtered.push(..._finalizados);
                    // setAttendancesList(_finalizados);
                } /* else if(!Object.values(AttendanceFilter).find(x => Object.values(x).find(y => y))){
                    setFilteredAttendances(healthAttendances)
                } */

                Object.keys(_quadro).forEach(key => {
                    if(_quadro[key]){
                        switch(key){
                            case "grave":
                                var _graves = healthAttendances.filter(attendance => attendance.rating_health_attendance[0] && attendance.rating_health_attendance[0].rating_health_id == 1);
                                filtered.push(..._graves);
                                break;
                            case "moderado":
                                var _moderados = healthAttendances.filter(attendance => attendance.rating_health_attendance[0] && attendance.rating_health_attendance[0].rating_health_id == 3);
                                filtered.push(..._moderados);
                                break;
                            case "leve":
                                var _leves = healthAttendances.filter(attendance => attendance.rating_health_attendance[0] && attendance.rating_health_attendance[0].rating_health_id == 2);
                                filtered.push(..._leves);
                                break;
                            case "nao_indicativo":
                                var _nao_indicativo = healthAttendances.filter(attendance => attendance.rating_health_attendance[0] && attendance.rating_health_attendance[0].rating_health_id == 4);
                                filtered.push(..._nao_indicativo);
                                break;
                            default:
                                break;
                        }
                    }
                });

                Object.keys(_linecare).forEach((x, i) => {
                    if(_linecare[x]){ // filtro ativo
                        var _linecareFilter = healthAttendances.filter(attendance => attendance.line_care && attendance.line_care.id == x);

                        filtered.push(..._linecareFilter);
                    }
                })

                if(AttendanceFilter.period.from && AttendanceFilter.period.to) {
                    try {
                        var _periodos = healthAttendances.filter(attendance => {
                            var compare = (new Date(attendance.created_at));
                            var data1Split = AttendanceFilter.period.from.split('/');
                            var data2Split = AttendanceFilter.period.to.split('/');
                            var data1 = new Date(data1Split[2],data1Split[1] - 1, data1Split[0])
                            var data2 = new Date(data2Split[2],data2Split[1] - 1, data2Split[0])
                            

                            return compare >= data1 && compare <= data2;
                        })
                    } catch(err) {
                        var _periodos = []
                    }


                    filtered.push(..._periodos);
                    // setFilteredAttendances(_periodos);
                    // setAttendancesList(_periodos);
                }

                if(filtered.length > 0){
                    filtered = [...new Set(filtered)]; // removendo duplicados
                    setFilteredAttendances(filtered);
                    // setFilteredAttendances(filtered);
                } else {
                    setFilteredAttendances(healthAttendances);
                }
        }
    }

    useEffect(() => {
        // if(AttendanceFilter && healthAttendances) filterAttendances();
        if(applyFilter) {
            setPage(1);
            getHealthAttendanceByPageFilter(localStorage.getItem("token"), 1, AttendanceFilter).then(res => {
                if(res.status){
                    setHealthAttendances(res.healthAttendances.patient);
                    setAttendancesList(res.healthAttendances.patient);
                    setPaginateInfo(res.healthAttendances.paginate);
                    setTotais({ ...totais, status: { ...totais.status, 
                        todos: res.healthAttendances.totals[0],
                        assumido: res.healthAttendances.totals[2],
                        aberto: res.healthAttendances.totals[1],
                        finalizado: res.healthAttendances.totals[3]
                    },
                    quadro: { ...totais.quadro,
                        todos: res.healthAttendances.totals[5],
                        leve: res.healthAttendances.totals[6],
                        moderado: res.healthAttendances.totals[7],
                        grave: res.healthAttendances.totals[8],
                        nao_indicativo: res.healthAttendances.totals[9]
                    }})
                }
                setLoading(false);
            })
            .catch(err => console.error(err));

            setApplyFilter(false);
        }

        
    }, [applyFilter]);

    useEffect(() => {
        if(updateAcl) {
            setLoading(true);
            setPage(1)
            getAllHealthAttendance(localStorage.getItem("token"))
                .then(res => {
                    if(res.status){
                        setHealthAttendances(res.healthAttendances.patient);
                        setAttendancesList(res.healthAttendances.patient);
                        setPaginateInfo(res.healthAttendances.paginate);
                        setTotais({ ...totais, status: { ...totais.status, 
                            todos: res.healthAttendances.totals[0],
                            assumido: res.healthAttendances.totals[2],
                            aberto: res.healthAttendances.totals[1],
                            finalizado: res.healthAttendances.totals[3]
                        },
                        quadro: { ...totais.quadro,
                            todos: res.healthAttendances.totals[5],
                            leve: res.healthAttendances.totals[6],
                            moderado: res.healthAttendances.totals[7],
                            grave: res.healthAttendances.totals[8],
                            nao_indicativo: res.healthAttendances.totals[9]
                        }
                     });
                    }
                    setLoading(false);
                    setUpdateAcl(false);
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        }
    },[updateAcl])

    useEffect(() => {
        if(localStorage.getItem('health_attendance_interconsultation')) {
            
            setFilteredAttendances(null);
            setPage(1)
    
            getHealthAttendanceByPageFilter(localStorage.getItem("token"), 1, {...AttendanceFilter, search: localStorage.getItem('health_attendance_interconsultation')})
                .then(res => {
                    if(res.status){
                        setHealthAttendances(res.healthAttendances.patient);
                        setAttendancesList(res.healthAttendances.patient);
                        setPaginateInfo(res.healthAttendances.paginate);
                        setTotais({ ...totais, status: { ...totais.status, 
                            todos: res.healthAttendances.totals[0],
                            assumido: res.healthAttendances.totals[2],
                            aberto: res.healthAttendances.totals[1],
                            finalizado: res.healthAttendances.totals[3]
                        },
                        quadro: { ...totais.quadro,
                            todos: res.healthAttendances.totals[5],
                            leve: res.healthAttendances.totals[6],
                            moderado: res.healthAttendances.totals[7],
                            grave: res.healthAttendances.totals[8],
                            nao_indicativo: res.healthAttendances.totals[9]
                        }})
                    }
                    setLoading(false);
                })
                .catch(err => console.error(err));
        }
    }, [localStorage.getItem('health_attendance_interconsultation')])


    function getHealthAttendancePage(page){
        setFilteredAttendances(null);
        setPage(page)

        getHealthAttendanceByPageFilter(localStorage.getItem("token"), page, AttendanceFilter)
            .then(res => {
                if(res.status){
                    setHealthAttendances(res.healthAttendances.patient);
                    setAttendancesList(res.healthAttendances.patient);
                    setPaginateInfo(res.healthAttendances.paginate);
                    setTotais({ ...totais, status: { ...totais.status, 
                        todos: res.healthAttendances.totals[0],
                        assumido: res.healthAttendances.totals[2],
                        aberto: res.healthAttendances.totals[1],
                        finalizado: res.healthAttendances.totals[3]
                    },
                    quadro: { ...totais.quadro,
                        todos: res.healthAttendances.totals[5],
                        leve: res.healthAttendances.totals[6],
                        moderado: res.healthAttendances.totals[7],
                        grave: res.healthAttendances.totals[8],
                        nao_indicativo: res.healthAttendances.totals[9]
                    }})
                }
                setLoading(false);
            })
            .catch(err => console.error(err));
    }

    return(
        <>
        {lockAdministrativeUnitMenu ? <Redirect to="/meu-perfil" /> : ''}
        <Grid style={{ paddingTop: 90 }}>
            <Grid item className={ classes.paciente__topo }>
                <Grid container>
                    <Grid item md={6} style={{ display: "flex", alignItems: "center" }}>
                        <a
                            href="#"
                            className={ classes.paciente__nome }
                            onClick={ e => {
                                e.preventDefault();

                                if(history.length > 1){
                                    history.goBack();
                                }

                            }}
                        >
                            <ArrowBackIosIcon color="primary" fontSize="large" /> Atendimento imediato
                        </a>
                    </Grid>
                    <Grid item md={6} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Link style={{textDecoration: 'none'}} to={{
                            pathname: "/solicitacao",
                            state: {
                                isList: false, isCadastro: true, isDetail: false
                            }
                        }} >
                            <NovaSolicitacaoButton variant="primary" icon={ <AddCircle classes={{fontSizeSmall: classes.icon}} fontSize="small" /> } size="md" style={{ marginRight: 24 }}>
                                Nova solicitação
                            </NovaSolicitacaoButton>
                        </Link>
                        <AtendimentoFilter setPagination={e => setPaginateInfo(e)} setAttendances={e => {setHealthAttendances(e); setAttendancesList(e); setPage(1)}} clearFilteredData={e => setFilteredAttendances(null)} />
                    </Grid>
                </Grid>
            </Grid>            
            <AtendimentoGrid
                // detalhes={ e => props.detalhes(e) }
                // pacientes={ props.pacientes }
                loading={loading}
                paginateInfo={ paginateInfo }
                page={page}
                setPage={e => {
                    getHealthAttendancePage(e)
                }}
                healthAttendances={ healthAttendances }
                // filteredAttendances={filteredAttendances}
                atender={e => {props.atender(e); setAttendance(e); setAtendimentoStatus(0); setEmAtendimento(false) }}
                setAttendance={e => setAttendance(e)}

                
            />      
        </Grid>
    </>
    )
}

export default AtendimentoList;