import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

export const Item = styled.div`
        position: relative;
        background: #fff;
        width: 418px;
        height: 68px;
        padding: 16px;
        border-radius: 8px;
`;

export const Time = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    text-align: right;
    font-size: 10px;
    color: #949494;
`;

export const Message = styled(Typography)`
        color: #4f4f4f;
        font-size: 14;
        font-weight: 500;
`;

export const Note = styled(Typography)`
    color: #949494;
    font-size: 12px;
    font-weight: 500;

`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const Avatar = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #e6e6e6;
    object-fit: cover;
    overflow: hidden;
    margin-right: 10px;
`;