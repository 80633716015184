import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from '@material-ui/core/styles';
import HistoricoGrid from './HistoricoGrid';

const useStyles = makeStyles((theme) => ({

  historico__topo: {
    position: "absolute",
    left: 0,
    top: 88,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: theme.palette.background.grass,
    boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18
  },
  historico__tabs: {
    marginTop: 93
  },
  historico__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    fontWeight: 600
  },
  icon: {
    fontSize: 15
  },
  toolbarContent: { 
    display: "flex", 
    alignItems: "center" 
  },
  paddingTop90: { 
    paddingTop: "90px !important" 
  }

}));

export default function HistoricoList(props) {

  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid>
        <Grid className={classes.paddingTop90}>
          <Grid item className={classes.historico__topo}>
            <Grid container>
              <Grid item md={12} className={classes.toolbarContent}>
                <a
                  href="#"
                  className={classes.historico__nome}
                  onClick={e => {
                    e.preventDefault();
                    if (history.length > 1) {
                      history.goBack();
                    }
                  }}
                >
                  <ArrowBackIosIcon color="primary" fontSize="large" /> Histórico de Ações
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <HistoricoGrid />
      </Grid>

    </>
  )
}
