import React, { useState, useEffect } from 'react';
import "../index.scss";
import { Button } from '@material-ui/core';
import { Create, Save } from '@material-ui/icons';
import AntecedentesView from "./AntecedentesView";
import AntecedentesEdit from "./AntecedentesEdit";
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { updateComorbidity, updateComorbidityFamilyHistory, update } from "./../../../../api/anamnesePreForm";
import { updateHealthAttendance } from "./../../../../api/healthAttendance";
import { getByHealthAttendanceId } from "./../../../../api/health_attendances";
import { toast } from "react-toastify";

export default function AntecedentesNewTab(props) {

	const [edit, setEdit] = useState(false);
	const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();

	const handleSalvar = (data_form) => {

		console.log(data_form);

		var body_pre_form = {
			physical_activity: data_form.physical_activity,
			smoking: data_form.smoking,
			smoking_amount: data_form.smoking_amount,
			smoking_period: data_form.smoking_period,
			smoking_period_without: data_form.smoking_period_without,
			alcoholic: data_form.alcoholic,
			alcoholic_type: data_form.alcoholic_type,
			alcoholic_period: data_form.alcoholic_period,
			drugs: data_form.drugs,
			drugs_list: data_form.drugs_list
		}
		
		console.log(body_pre_form);

		var token = localStorage.getItem("token");

		update(token, data_form.anamnese_pre_form_id, body_pre_form).then(res => {
			if(res.status){
				updateComorbidity(token, data_form.anamnese_pre_form_id, data_form.comorbidity).then(res => {
					if(res.status) {
						updateComorbidityFamilyHistory(token, data_form.anamnese_pre_form_id, data_form.comorbidity_family_history).then(res => {
							if(res.status) {
								if(data_form.lineOfCare) {
									updateHealthAttendance(token, healthAttendance.id, {patient_id: patient.id, line_care_id: data_form.lineOfCare.id }).then(res => {
										if(res.status) {
											getByHealthAttendanceId(token, healthAttendance.id).then(res => {
												if(res.status) {
													toast.success("Antecedentes atualizados com sucesso.");
													setHealthAttendance(res.healthAttendance);
													setEdit(false);
												} else {
												  toast.error("Erro ao atualizar exibição das alergias.");      
												}
											});
										} else {
											toast.error("Erro ao atualizar antecedentes familiares.")
										}
									});
								} else {
									getByHealthAttendanceId(token, healthAttendance.id).then(res => {
										if(res.status) {
											toast.success("Antecedentes atualizados com sucesso.");
											setHealthAttendance(res.healthAttendance);
											setEdit(false);
										} else {
										  toast.error("Erro ao atualizar exibição das alergias.");      
										}
									});
								}
							} else {
								toast.error("Erro ao atualizar antecedentes familiares.")
							}
						});
					} else {
						toast.error("Erro ao atualizar comorbidades.")
					}
				});
			} else {
				toast.error("Erro ao atualizar habitos sociais.")
			}
		})

		
	}

	return (
		<div className='fundo_new_tab'>
			<div className='mt_35_new_tab'>
				{ !edit ? 	
					<AntecedentesView>
						<Button className="botao_editar_new_tab text-primary" onClick={ e => setEdit(true) } startIcon={<Create />}>
							Editar
						</Button>		
					</AntecedentesView> 
						: 
					<AntecedentesEdit onSave={ handleSalvar }>
						<Button className="botao_editar_new_tab text-primary" type="submit" startIcon={<Save />}>
							Salvar
						</Button>					
					</AntecedentesEdit>
				}        
			</div>
		</div >
	)
}