import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss";
import CloseIcon from '@mui/icons-material/Close';

function CustomModalLigacao(props) {
  const [open, setOpen] = React.useState(props.open);  

  return (
    <div>
      <Modal
        open={open}
        onClose={e => props.onClose(true)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box md className='estilo_modal_ligacao'>
          <div className='alinha_botao_modal_ligacao'>
            <Button onClick={ e => props.onClose(true) }>
              <CloseIcon className='icone_modal_ligacao' />
            </Button>
          </div>
          { props.children }
        </Box>
      </Modal>
    </div>
  );
}

CustomModalLigacao.defaultProps = {
    open: false,
    onClose: function() {
      console.log('onClose')
    }
}

export default CustomModalLigacao;