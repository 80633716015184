import React from "react";
import { Grid } from "@material-ui/core";

import Card from "./components/Card";

function PrevPregnancies(props) {
  return (
    <Grid container spacing={1}>
      {props.attendances &&
        props.attendances.groupForm[0].group_form_patient.map((item, index) => {
          return (
            <Grid item md={6}>
              <Card
                title={
                  index + 1 === 1
                    ? "Primeira Gestação"
                    : index + 1 + "ª Gestação"
                }
                exams="28"
                groupForm={item}
                attendance={props.attendancesLength}
                childBirth={props.dpp}
                openForm={(e) => props.openAttendance(item, "Ativo")}
                click={(e) => props.openAttendances()}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default PrevPregnancies;
