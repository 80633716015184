import {useContext, useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { KeyboardArrowDown, PregnantWoman, Launch, KeyboardArrowUp, Edit, Save, CheckCircle, Visibility, GetApp, OpenInNew } from "@material-ui/icons";
import AddCircle from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from  'clsx';

import { DownloadIcon } from "../../../../shared/Icon/Icon";
import DButton from "../../../../shared/DButton";
import {getPatientById} from "../../../../../api/patient";
import {Vacinacao, MinhaSaude} from "../../../../../api/above";
import genero from "../../../../../assets/dados/genero.json";

import {getAgeByDate} from "../../../../../helpers/formatacao";

import Input from '@material-ui/core/Input';

import "./styles.scss";
import { AtendimentoContext } from "../../../../../context/AtendimentoContext";

import {AddButton, DTable, TextHightlight1} from "./styles"
import { listHealthAttendanceFiles, getHealthAttendanceFile, history } from "../../../../../api/healthAttendance";
import api_preform from "../../../../../api/anamnesePreForm";
import lineCares from "../../../../../api/lineCare";
import useDebounce from "../../../../../hooks/useDebounce";
import DAutoComplete from "../../../../shared/DAutoComplete";
import { toast } from "react-toastify";
import Observation from "../../../components/Observation";
import HealthAttendanceStatus from "../../../AtendimentoList/components/AtendimentoGrid/componentes/HealthAttendanceStatus";
import SaveIcon from '@material-ui/icons/Save';
import DInput from "../../../../shared/DInput";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { withStyles } from "@material-ui/styles";
import Biomedidas from "../../../../shared/Biomedidas/Biomedidas";

const useStyles = makeStyles((theme) => ({
    dtable: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr"
    },
    ulist: {
        listStyleType: "none",
    },
    accordionRoot: {
        background: theme.palette.background.cloud,
    },
    accordionTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.light
    },
    AccordionDetails: {
        flexDirection: "column"
    },
    accordionText: {
        fontSize: 14
    },
    subtitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.light,
        marginBottom: 16,
        marginTop: 32
    },
    subsubtitle: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.primary.light,
        margin: 0,
    },
    highlightedText: {
        color: "#000!important",
        padding: "20px 0px 0px",
        fontSize: 14,
    },
    separator: {
        border: 0,
        borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
        margin: "32px 0"
    },
    highlightedBox: {
        padding: "5px",
        border: `1px solid ${theme.palette.neutral.midgrey}`,
        borderRadius: "24px",
        background: "#ccc",
        margin: "0px 5px",
        color: "black",
        fontWeight: "500",
    },
    textColor: {
        color: theme.palette.neutral.black,
        fontWeight: "700",
    },
    primaryFill: {
        fill: theme.palette.primary.light
    }
}));
const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "18px",
        color: theme.palette.neutral.black,
        padding: "6px 12px"
    },
    body: {
        fontSize: 14,
        padding: "6px 12px"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    // root: {
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    // },
}))(TableRow);

const attendanceStatus = {
    1: "Aberto",
    2: "Assumido",
    3: "Finalizado",
    4: "Cancelado"
}

function DadosPaciente (props) {
    const classes = useStyles();
    const {patientInfo} = useContext(AtendimentoContext);
    const attendance = props.attendance;
    
    const [patientData, setPatientData] = useState(props.patientData);
    const [biomedidasExames, setBiomedidasExames] = useState(null);
    const [addBiomedidasExames, setAddBiomedidasExames] = useState(false);
    const [newBiomedidasExames, setNewBiomedidasExames] = useState({});
    const [amountBioMedidas, setAmountBioMedidas] = useState(0);
    const [patientLineCares, setPatientLineCares] = useState(null);

    const [vacinas, setVacinas] = useState(null);
    
    const [editAntecedentes, setEditAntecedentes] = useState(false);
    const [editDataSentByPatients, setEditDataSentByPatients] = useState(false);

    const [healthAttendanceFiles, setHealthAttendanceFiles] = useState(null);

    const [anamnesePreForm, setAnamnesePreForm] = useState(props.anamnesePreForm);

    const [antecedentes, saveAntecedentes] = useState(null);
    const [statusAttendanceFinished, setStatusAttendanceFinished] = useState(null);
    const [typeStatusAttendance, setTypeStatusAttendance] = useState();
    const [medicalUnit, setMedicalUnit] = useState();
    const [userRequesting, setUserRequesting] = useState();
    
    const [dateOpenAttendance, setDateOpenAttendance] = useState();
    const [dateCloseAttendance, setDateClosenAttendance] = useState();
    const [groupPatient, setGroupPatient] = useState();
    
    const [historyAttendance, setHistoryAttendance] = useState();
    const [historyAttendanceStatus, setHistoryAttendanceStatus] = useState(false);
    const [historyAttendanceUser, setHistoryAttendanceUser] = useState();
    const [historyAttendanceSpeciality, setHistoryAttendanceSpeciality] = useState();
    const [historyAttendanceConselho, setHistoryAttendanceConselho] = useState();
    const [historyAttendanceCondutaClinica, setHistoryAttendanceCondutaClinica] = useState();

    // const [editarDadosPaciente, setEditarDadosPaciente]           = useState(false);
    // const [nome, setNome] = useState();
    // const [idade, setIdade] = useState();
    // const [sexo, setSexo]  = useState();
    // const [usoContinuo, setUsoContinuo]  = useState();

    const debounceAntecedentes = useDebounce(antecedentes, 1000);    

    useEffect(() => {
        if(attendance){
            updateHealthList();

            getPatientById(localStorage.getItem("token"), attendance.patient.id)
                .then(data => {
                    if(data.status){
                        setPatientData(data.details.patient);
                    } else {
                        toast.error("Falha ao buscar paciente.");
                    }
                })
                .catch(err => {
                    toast.error("Falha ao buscar paciente.");
                    console.error(err);
                })

            lineCares.listPatientLineCares(attendance.patient_id)
                .then(data => {
                    if(data.status){
                        setPatientLineCares(data.patientLineCare)
                    } 
                })
                .catch(console.error)

            // Vacinacao.listVacinas(localStorage.getItem("token"), attendance.id).then(data => {
            //     if(data.status){
            //         setVacinas(data.details);
            //     }
            // });

            listHealthAttendanceFiles(localStorage.getItem('token'), attendance.id )
            .then(data => {
                if(data.status){
                    setHealthAttendanceFiles(data.healthAttendanceFile);
                }
            }).catch(err => {
                console.error(err);
            });

            history(localStorage.getItem("token"), attendance.id)
            .then(data => {
                if(data.status){
                    let lastAttendance = data.history.historyHealthAttendance[data.history.historyHealthAttendance.length - 1]
                    
                    setHistoryAttendance(lastAttendance)
                    setHistoryAttendanceUser(lastAttendance.user)
                    setHistoryAttendanceCondutaClinica(lastAttendance.anamneseHistory[lastAttendance.anamneseHistory.length - 1].value)
                    setHistoryAttendanceSpeciality(lastAttendance.anamneseFormAnswer.user.user_medical_speciality.map((res => {
                        return res.medical_speciality.description
                    })))
                    setHistoryAttendanceConselho(lastAttendance.conselho[lastAttendance.conselho.length -1])
                    
                    if (lastAttendance.closed_at !== undefined || lastAttendance.closed_at !== "" || lastAttendance.closed_at !== null) {
                        setHistoryAttendanceStatus(true)
                    } else {
                        setHistoryAttendanceStatus(false)
                    }
                    
                }
            })
            .catch(err => console.error(err))
        } else {
            toast.error(`Falha ao recuperar dados do atendimento, tente novamente.`);
        }
    }, [])

    // useEffect(() => {
    //     if(patientData != null){
    //         setNome(patientData && patientData.full_name)
    //         setIdade(patientData && getAgeByDate(patientData.birth_date))
    //         setSexo(patientData && patientData.biological_sex)
    //     } else {
    //         toast.error(`Falha ao recuperar dados do atendimento, tente novamente.`);
    //     }
        
    // }, [])


    useEffect(() => {
        if(attendance){
            setDateOpenAttendance(attendance.created_at);
            if(attendance.closed_at != null && attendance.user_id != null && attendance.health_attendance_status_id == 3)
            {
                setStatusAttendanceFinished(true)
                setTypeStatusAttendance(attendance.health_attendance_status.description)
                setDateClosenAttendance(attendance.closed_at)
            }
            else {
                setTypeStatusAttendance(attendance.health_attendance_status.description)
            }
            setMedicalUnit(
                attendance.health_attendance_anamnese_form[
                    attendance.health_attendance_anamnese_form.length - 1].anamnese_form.anamnese_form_medical_unit.map(res => {
                        return  res.medical_unit.bussines_name ? res.medical_unit.bussines_name : res.medical_unit.company_name
                        
                    })
              );
            setUserRequesting(attendance.user_requesting ? attendance.user_requesting.full_name : '')
            // setGroupPatient(attendance.group ? attendance.group.name : '')
            getPatientById(localStorage.getItem('token'), attendance.patient_id).then(res => {
                if(res.status) {
                    if(res.details.patient && res.details.patient.patient_has_group && Array.isArray(res.details.patient.patient_has_group)) {
                        setGroupPatient(res.details.patient.patient_has_group.map(group => {
                            return group.group ? group.group.name : group.id
                        }).join(", "))
                    }

                    if(res.details.patient && res.details.patient.patient_line_care && Array.isArray(res.details.patient.patient_line_care))
                        setPatientLineCares(res.details.patient.patient_line_care);
                }
            })
            
        } else {
            toast.error(`Falha ao recuperar dados do atendimento, tente novamente.`);
        }
        // if(anamnesePreForm) {
        //         setUsoContinuo(anamnesePreForm && anamnesePreForm.anamnese_pre_medicine.map((item, index) => {
        //             return item.name || ""
        //     }))
        // }
    }, [])

    function formatDate(date) {
        if(date && date != 'null') {
            date = typeof date != 'object' ? new Date(date) : null;
            var day = date.getDate() >= 10 ? date.getDate() : '0'+ date.getDate();
            var month = (date.getMonth() + 1) >= 10 ? date.getMonth() : '0'+ date.getMonth();
            var year = String(date.getFullYear()).slice(2);
            
            return [day, month, year].join('/');
        } else {
            return ''
        }
    }

    function getDateHours(date){
        if(date && date != 'null'){
            date = typeof date != 'object' ? new Date(date) : date;
            var hours = date.getHours() >= 10 ? date.getHours() : '0'+ date.getHours();
            var minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0'+ date.getMinutes();
            var seconds = date.getSeconds() >= 10 ? date.getSeconds() : '0'+ date.getSeconds();
            return [hours, minutes].join(':');
        }
    }

    function getConselhoMedico(document){
        var doc = null;
        
        if(document && typeof document === "object"){
            if(document[4] && document[4].values){
                doc = document[4]; 

                if(doc && doc.values){
                    var [_conselho, _cod_conselho, _, _uf_conselho, _cbo_conselho] = doc.values;
    
                    return `
                        ${_conselho.value ? _conselho.value : ""} - ${_uf_conselho.value ? _uf_conselho.value : ""} ${_cod_conselho.value ? _cod_conselho.value : ""}`;
                }
            }
        }
    }

    useEffect(() => {
        if(newBiomedidasExames.type){
            setAmountBioMedidas(0);

            setNewBiomedidasExames({...newBiomedidasExames, type: newBiomedidasExames.type, fields: null});
        }
    }, [newBiomedidasExames.type])

    useEffect(() => {
        if(debounceAntecedentes){
            if(debounceAntecedentes.item){
                api_preform.updateComorbidity(localStorage.getItem("token"), debounceAntecedentes.item.anamnese_pre_form_id, {
                    id: debounceAntecedentes.item.comorbidity_id,
                    observation: debounceAntecedentes.item.observation
                }).then(data => {
                    if(data.status){
                        console.log("/** os dados de", debounceAntecedentes.item.comorbidity_id, "foram atualizados com sucesso **/");
                    }
                })
                .catch(err => {
                    console.error(err);

                });
            }else if(debounceAntecedentes.type){ // Hábitos sociais
                console.log(debounceAntecedentes.type);
            }
        }
    }, [debounceAntecedentes]);

    const handleOpenFile = (file) => {
        getHealthAttendanceFile(localStorage.getItem("token"), file.id)
            .then(data => {
                if(!data.status && !data.healthAttendanceFile) return;

                let _filename = data.healthAttendanceFile.file.split("/").pop();
                let link = document.createElement('a');
                link.href = data.temporalyUrlFile;
                link.target = "_blank";
                link.download = _filename;
                link.click();
                link.remove();
            })
            .catch(err => console.error(err))
    }

    const handleSaveForm = () => {

        api_preform.updateComorbidity(localStorage.getItem("token"), anamnesePreForm.id, anamnesePreForm.anamnese_pre_comorbidity.map(x => {
            return {
                id: x.comorbidity_id,
                observation: x.observation
            }
        }))
            .then(data => {
                console.log("Upd", data)
            })
            .catch(err => {
                console.error(err);
            })
    }

    const medicoesOptions = [
        {
            id: 0,
            name: "Medição IMC",
        },
        {
            id: 1,
            name: "Pressão arterial"
        },
        {
            id: 2,
            name: "Glicemia capilar",
        },
        {
            id: 3,
            name: "Medicamentos",
        },
        {
            id: 4,
            name: "Doenças crônicas",
        },
        {
            id: 5,
            name: "Alergias",
        }
    ]

    const updateHealthList = () => {
        MinhaSaude.GetMyHealthList(localStorage.getItem("token"), attendance.id)
            .then(data => {
                if(data.status){
                    setBiomedidasExames(data.details.itens);
                }
            })
    }

    const handleSaveBiomedidasExames = (data) => {

        // console.log("Saving", data.type, data);

        if(data.type && (data.type.id != undefined || data.type.id != null)){
            setNewBiomedidasExames(null);
            setAmountBioMedidas(0);

            switch(data.type.id){
                case 0:
                    MinhaSaude.includeBMIMeasurement(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
                    break;
                case 1:
                    MinhaSaude.includeHealthPressureMeasurement(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
                    break;
                case 2:
                    MinhaSaude.includeCapillaryBloodGlucose(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
                    break;
                case 3:
                    MinhaSaude.includeHealtDrugList(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
                    break;
                case 4:
                    MinhaSaude.includeHealthChronicDiseases(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
                    break;
                case 5:
                    MinhaSaude.includeHealthAllergyList(localStorage.getItem("token"), attendance.id, data.fields)
                        .then(updateHealthList)
                        .catch(console.error);
            }

        }


    }

    const renderType = (typeId, type) => {
        switch(typeId){
            case 0:
                return (
                    <>
                        <Input 
                            type="text" 
                            placeholder="Peso kg"
                            maxLength="255" 
                            value={newBiomedidasExames.fields && newBiomedidasExames.fields.weight_kg}
                            onChange={e => {
                                setNewBiomedidasExames(prevState => {
                                    return {...prevState, fields: {...prevState.fields, weight_kg: e.target.value}};
                                })
                            }}
                        />
                        
                        <Input 
                            type="text" 
                            placeholder="Altura cm"
                            maxLength="255" 
                            value={newBiomedidasExames.fields && newBiomedidasExames.fields.height_cm}
                            onChange={e => {
                                setNewBiomedidasExames(prevState => {
                                    return {
                                        ...prevState,
                                        fields: {
                                            ...prevState.fields,
                                            height_cm: e.target.value
                                        }       
                                    }
                                })
                            }}
                        />
                    </>
                )
            case 1:
                return (
                    <>
                         <Input 
                            type="text" 
                            placeholder="PA Sistólica mmhg"
                            maxLength="255" 
                            value={newBiomedidasExames.fields && newBiomedidasExames.fields.pa_systolic_mmhg}
                            onChange={e => {
                                setNewBiomedidasExames(prevState => {
                                    return {...prevState, fields: {...prevState.fields, pa_systolic_mmhg: e.target.value}};
                                })
                            }}
                        />
                        
                        <Input 
                            type="text" 
                            placeholder="PA Diastólica mmhg"
                            maxLength="255" 
                            value={newBiomedidasExames.fields && newBiomedidasExames.fields.pa_diastolica_mmhg}
                            onChange={e => {
                                setNewBiomedidasExames(prevState => {
                                    return {
                                        ...prevState,
                                        fields: {
                                            ...prevState.fields,
                                            pa_diastolica_mmhg: e.target.value
                                        }       
                                    }
                                })
                            }}
                        />
                    </>
                )
            case 2:
                return (
                    <>
                        <Input 
                            type="text" 
                            placeholder="Mg dl"
                            maxLength="255" 
                            value={newBiomedidasExames.fields && newBiomedidasExames.fields.mgdl}
                            onChange={e => {
                                setNewBiomedidasExames(prevState => {
                                    return {...prevState, fields: {...prevState.fields, mgdl: e.target.value}};
                                })
                            }}
                        />
                        
                        <DAutoComplete
                          label=""
                          placeholder="Em jejum"
                          labelColor="black"
                          fullWidth={true}
                          style={{ marginTop: -16 }}
                          classes={{ input: classes.autocomplete }}
                          onChange={(event, newValue) => {
                            setNewBiomedidasExames(prevState => {
                                return {...prevState, fields: {...prevState.fields, In_fasting: newValue ? newValue.id : ''}};
                            })
                        }}
                          options={[
                            { id: 0, value: "sem jejum" },
                            { id: 1, value: "com jejum" },
                          ]}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        {
                            [...new Array(amountBioMedidas)].map((x, index) => {
                                return (
                                    <>
                                    <hr/>
                                        <Input 
                                            type="text" 
                                            placeholder="Medicamento"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] &&  newBiomedidasExames.fields[index].name}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], name: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                        <Input 
                                            type="text" 
                                            placeholder="Princípio ativo"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] && newBiomedidasExames.fields[index].active_principle}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], active_principle: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                    </>
                                )
                            })
                        }

                        <AddButton onClick={e => setAmountBioMedidas(amountBioMedidas+1)}>+</AddButton>
                    </>
                )
            case 4:
                return (
                    <>
                        {
                            [...new Array(amountBioMedidas)].map((x, index) => {
                                return (
                                    <>
                                    <hr/>
                                        <Input 
                                            type="text" 
                                            placeholder="Doença crônica"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] && newBiomedidasExames.fields[index].name}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], name: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                        <Input 
                                            type="text" 
                                            placeholder="Observação"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] && newBiomedidasExames.fields[index].obs}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], obs: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                    </>
                                )
                            })
                        }
                        <AddButton onClick={e => setAmountBioMedidas(amountBioMedidas+1)}>+</AddButton>
                    </>
                )
            case 5:
                return (
                    <>
                        {
                            [...new Array(amountBioMedidas)].map((x, index) => {
                                return (
                                    <>
                                    <hr/>
                                        <Input 
                                            type="text" 
                                            placeholder="Alergia"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] && newBiomedidasExames.fields[index].name}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], name: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                        <Input 
                                            type="text" 
                                            placeholder="Tipo"
                                            maxLength="255" 
                                            value={newBiomedidasExames.fields && newBiomedidasExames.fields[index] && newBiomedidasExames.fields[index].type}
                                            onChange={e => {
                                                setNewBiomedidasExames(prevState => {

                                                    var _fields = prevState.fields;

                                                    _fields = Array.isArray(_fields) ? _fields : [];

                                                    _fields[index] = {..._fields[index], type: e.target.value};

                                                    return {...prevState, fields: _fields};
                                                })
                                            }}
                                        />
                                    </>
                                )
                            })
                        }
                        <AddButton onClick={e => setAmountBioMedidas(amountBioMedidas+1)}>+</AddButton>
                    </>
                )
        }
    }

    const renderBioMedidas = (data, type) => {
        // console.log("Medidas", data)

        switch(type){
            case "allergyList":
                return data.map((allergy, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Alergias
                            </span>
                            <span className="item">
                                {allergy.name}
                            </span>
                        </div>
                    )
                })
            case "drugList":
                return data.map((medicines, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Medicamentos
                            </span>
                            <span className="item">
                                {medicines.name}
                            </span>
                            <span className="item">
                                Princípio ativo: {medicines.active_principle}
                            </span>
                        </div>
                    )
                })
            case "chronicDiseasesLIst":
                return data.map((chronicDiseases, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Doença crônica
                            </span>
                            <span className="item">
                                {chronicDiseases.name}
                            </span>
                            <span className="item">
                                Observação: {chronicDiseases.obs}
                            </span>
                        </div>
                    )
                })
            case "pressureMeasurementLIst":
                return data.map((pressureMeasurement, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Pressão arterial
                            </span>
                            <span className="item">
                                PA Sistólica mmhg {pressureMeasurement.pa_systolic_mmhg}
                            </span>
                            <span className="item">
                                PA Diastólica mmhg {pressureMeasurement.pa_diastolica_mmhg}
                            </span>
                        </div>
                    )
                })
            case "capillaryBloodGlucoseLIst":
                return data.map((capillaryBloodGlucose, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Glicemia capilar
                            </span>
                            <span className="item">
                                mgdl {capillaryBloodGlucose.mgdl}
                            </span>
                            <span className="item">
                                {capillaryBloodGlucose.In_fasting == 0 ? "sem jejum" : "com jejum"}
                            </span>
                        </div>
                    )
                })
            case "bmimEasurementLIst":
                return data.map((bmimEasurementLIst, index) => {
                    return (
                        <div className="medicoes-card" key={index}>
                            <span className="head">
                                Medição IMC
                            </span>
                            <span className="item">
                                Peso KG {bmimEasurementLIst.weight_kg}
                            </span>
                            <span className="item">
                                Altura CM {bmimEasurementLIst.height_cm}
                            </span>
                        </div>
                    )
                })
        }

    }

    const renderAlcoholicField = anamneseForm => {
        if(anamneseForm.alcoholic && anamneseForm.alcoholic_type && anamneseForm.alcoholic_period) {
            return `${anamneseForm.alcoholic}, ${anamneseForm.alcoholic_type} há ${anamneseForm.alcoholic_period}`;
        } else if(anamneseForm.alcoholic) {
            return `${anamneseForm.alcoholic}`;
        } else {
            return "Não informado"
        }
    }


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <div style={{ display:"flex", alignItems: "center", justifyContent:"space-between" }}>
                    <h5 className={ classes.subtitle } style={{ marginTop: 0, marginBottom: 5 }}>Solicitante</h5>
                    
                </div>
                </Grid>
                <Grid item xs={12}>

                    <strong>Unidade:</strong> {medicalUnit && medicalUnit.length > 0 ? medicalUnit[0] : ''}
                    <strong style={{ marginLeft: 16 }}>Solicitante:</strong> { userRequesting ? userRequesting : '' }
                    <strong style={{ marginLeft: 16 }}>Conselho:</strong>  {attendance.documentUserRequesting && getConselhoMedico(attendance.documentUserRequesting)}
                </Grid>
                {
                    statusAttendanceFinished ? (
                        <>
                            <Grid item xs={12}>
                            <h5 className={ classes.subtitle } style={{ marginTop: 0, marginBottom: 5 }}>Encerrado por</h5>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Unidade:</strong> {medicalUnit && medicalUnit.length > 0 ? medicalUnit[0] : ''}
                            <strong style={{ marginLeft: 16 }}>Solicitante:</strong> { userRequesting ? userRequesting : '' }
                            <strong style={{ marginLeft: 16 }}>Conselho:</strong> {attendance.documentUserRequesting && getConselhoMedico(attendance.documentUserRequesting)}
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{fontWeight: "300!important"}}>
                                <strong style={{fontSize: "14px"}}>Status:</strong> <HealthAttendanceStatus width={17} height={17} status={attendance && attendance.health_attendance_status_id} />&nbsp;
                                {attendance && attendanceStatus[attendance.health_attendance_status_id]}
                            </span>
                        </Grid>
                        </>
                    ) : ""
                }
                        <Grid item xs={12}>
                            <h5 style={{color: "#000 !important", fontSize: "16px", fontWeight: 700, padding: "16px 0 10px 0", marginTop: 0, marginBottom: 0}}>Observações</h5>
                            <div>
                                {
                                    attendance && <Observation attendance={attendance} />
                                }
                            </div>
                        </Grid>

                        <Grid item sm={6}>
                            <Typography style={{ fontSize: 14 }}>Aberto</Typography>
                            <div style={{ marginTop: 8, display:"flex", alignItems:"center", fontSize: 14 }}>
                                <DateRangeIcon fontSize="small" style={{ color:"#4a4ae9", marginRight: 20 }} />
                                <strong style={{ fontWeight: 600 }}>{dateOpenAttendance && formatDate(dateOpenAttendance)} {dateOpenAttendance && getDateHours(dateOpenAttendance)}</strong>
                            </div>
                        </Grid>
                        { statusAttendanceFinished ? (
                            <Grid item sm={6}>
                                <Typography style={{ fontSize: 14 }}>Encerrado</Typography>
                                <div style={{ marginTop: 8, display:"flex", alignItems:"center", fontSize: 14 }}>
                                    <DateRangeIcon fontSize="small" style={{ color:"#4a4ae9", marginRight: 20 }} />
                                    <strong style={{ fontWeight: 600 }}>{dateCloseAttendance && formatDate(dateCloseAttendance)} {dateCloseAttendance && getDateHours(dateCloseAttendance)}</strong>
                                </div>
                            </Grid>
                            ) : ""
                        }
                    
                          
            </Grid>
            { historyAttendanceStatus ? (
            <Accordion defaultExpanded="true" elevation={0} className={ classes.accordionRoot }>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#4a4ae9" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography className={ classes.accordionTitle }>Histórico de atendimento</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                    <Typography className={ classes.accordionText } style={{ display: "flex "}}>
                        <FiberManualRecordIcon style={{ color: "#4a4ae9", marginRight: 5, height: 20 }} />
                        <strong>Encerrado por:</strong> {historyAttendance && formatDate(historyAttendance.closed_at)} {historyAttendance && getDateHours(historyAttendance.closed_at)}
                    </Typography>
                    <Grid container style={{ marginTop: 8, marginLeft: 10, borderLeft: "1px solid", paddingLeft: 18 }}>
                        <Grid item sm={4}>
                            <Typography className={ classes.accordionText }><strong>Por:</strong> {historyAttendanceUser != null ? historyAttendanceUser : ''}</Typography>
                        </Grid>
                        <Grid item sm={3}> 
                            <Typography className={ classes.accordionText }><strong>{historyAttendanceConselho != null ? `${historyAttendanceConselho.description}-${historyAttendanceConselho.uf}`  : ''}</strong> {historyAttendanceConselho != null ? historyAttendanceConselho.value : ''}</Typography>
                        </Grid>
                        <Grid item sm={4}>
                            <Typography className={ classes.accordionText }><strong>Especialidade:</strong> {historyAttendanceSpeciality.length > 0 ? historyAttendanceSpeciality[0] : ''}</Typography>
                        </Grid>
                        <Grid item sm={3} style={{ marginTop: 8 }}>
                            <Typography className={ classes.accordionText }><strong>Conduta clínica:</strong></Typography>
                        </Grid>
                        <Grid item sm={9} style={{ marginTop: 8 }}>
                            <Typography className={ classes.accordionText }>{historyAttendanceCondutaClinica != null ? historyAttendanceCondutaClinica : ''}</Typography>
                        </Grid>
                        {/* <Grid item sm={12} style={{ marginTop: 8 }}>
                            <DButton variant="card" size="sm" icon={ <AddCircle style={{ height: 16 }} /> }>Ver histórico completo</DButton>
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            ) : "" } 
            
                <div style={{ display:"flex", alignItems: "center", justifyContent:"space-between" }}>
                    <h5 className={ classes.subtitle }>Paciente</h5>
                </div>
                
                <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <strong>Nome: </strong>{ patientData && patientData.full_name }
                        </Grid>
                        <Grid item xs={12}>

                            <strong>Idade:</strong> {patientData && getAgeByDate(patientData.birth_date) + " anos"}
                            <strong style={{ marginLeft: 16 }}>Sexo biológico:</strong> { patientData && patientData.biological_sex }
                         </Grid>
                         <Grid item xs={12}>
                            <span className="head-title"><strong>Uso contínuo:</strong></span>
                            {
                                props.anamnesePreForm && props.anamnesePreForm.anamnese_pre_medicine.map((item, index) => {
                                        return <span key={index} className={classes.highlightedBox}>{item.name || ""}</span>
                                })
                            }
                        </Grid>
                         <Grid item xs={12}>
                            <DButton variant="secondary" icon={ <Launch fontSize="small" /> } size="sm" onClick={ e => props.toggleModal()}> Visualizar FIDI</DButton>
                        </Grid>
                    </Grid>

                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    <Box>
                        <h5 className={ classes.subtitle }>Grupo de paciente</h5>
                        <div>
                            { groupPatient ? groupPatient : <Typography className="text-regular text-st">Não informado</Typography> }
                        </div>
                    </Box>

                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    <Box>
                        <h5 className={ classes.subtitle }>Linhas de Cuidado</h5>
                        <div>
                            {
                                patientLineCares && Array.isArray(patientLineCares) && patientLineCares.length > 0 ? patientLineCares.map((item, index) => {
                                    return (
                                            <span 
                                                className="text-box" 
                                                key={index}
                                                style={{ border: 0, backgroundColor: "#f4f4f4", margin: "5px", borderRadius: "5px", padding: "5px", display: "inline-block"}}
                                            >
                                                {item.line_care && item.line_care.name}
                                            </span>
                                    )
                                }) : <Typography className="text-regular text-st">Não informado</Typography>
                            }
                        </div>
                    </Box>

                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    <h5 className={ classes.subtitle }>Alergias</h5>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{wordBreak: "break-all"}}>
                            <span className="head-title"><strong>Alimentar: </strong></span>
                            {
                                props.anamnesePreForm && Array.isArray(props.anamnesePreForm.anamnese_pre_food_allergy) && props.anamnesePreForm.anamnese_pre_food_allergy.length > 0 ? props.anamnesePreForm.anamnese_pre_food_allergy.map((item, index) => {
                                    return (
                                        <span className="text-box" key={index}>{ index > 0 ? " - " : "" }{item.name}</span>
                                    )
                                }) : <Typography className="text-regular text-st">Não informado</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <span className="head-title"><strong>Medicamentosa: </strong></span>
                            {
                                props.anamnesePreForm && Array.isArray(props.anamnesePreForm.anamnese_pre_medicine_allergy) && props.anamnesePreForm.anamnese_pre_medicine_allergy.length > 0 ? props.anamnesePreForm.anamnese_pre_medicine_allergy.map((item, index) => {
                                    return (
                                        <span className="text-box" key={index}>{ index > 0 ? " - " : "" }{item.name}</span>
                                    )
                                }) : <Typography className="text-regular text-st">Não informado</Typography>
                            }
                        </Grid>
                    </Grid>

                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    <Box>
                        <div style={{ display:"flex", alignItems: "center", justifyContent:"space-between" }}>
                            <h5 className={ classes.subtitle }>Dados enviados pelo paciente</h5>
                            <div style={{ marginTop: 24}}>
                                {/* <Button
                                    style={{ color:"#4a4ae9", textTransform: "none", fontWeight: 400 }}
                                    startIcon={editDataSentByPatients ? <Save style={{fill: "#03e016"}}/> : <EditIcon className={classes.primaryFill} />}
                                    onClick={e => {
                                        setEditDataSentByPatients(!editDataSentByPatients);
                                    }}
                                >
                                    {editDataSentByPatients ? "Salvar" : "Editar"}
                                </Button> */}
                            </div>
                        </div>
                        <div>
                            <h6 className={ classes.subsubtitle } style={{ marginTop: 24}}>Sintomas</h6>
                            <Grid container>
                            {
                                props.anamnesePreForm && Array.isArray(props.anamnesePreForm.anamnese_pre_symptom) && props.anamnesePreForm.anamnese_pre_symptom.length > 0 ? props.anamnesePreForm.anamnese_pre_symptom.map((item, index) => {
                                    return (
                                        <Grid item xs={12} key={index} style={{ marginTop: 24}}>
                                            <strong>{item.days} dias: </strong>
                                            <TextHightlight1>
                                                {item.symptom ? item.symptom.description : ''}
                                            </TextHightlight1>
                                        </Grid>
                                    )
                                }) : <Typography className="text-regular text-st">Não informado</Typography>
                            }
                            </Grid>
                            <h6 className={ classes.subsubtitle } style={{ marginTop: 24}}>Arquivos anexados no atendimento</h6>
                            <Grid container>
                                
                                {
                                    healthAttendanceFiles && Array.isArray(healthAttendanceFiles) && healthAttendanceFiles.length > 0 ? healthAttendanceFiles.map((item, index) => {
                                            if(item.type == "Exames") return;
                                            return (
                                            <>
                                                <Button
                                                    style={{ color:"#4a4ae9", textTransform: "none", fontWeight: 400 }}
                                                    startIcon={<DownloadIcon style={{fill: "#4a11e6"}} />}
                                                    onClick={e => handleOpenFile(item)}
                                                >
                                                    {attendance && `${attendance.patient.full_name.split(" ")[0]}-${attendance.patient.id}-${index}`}
                                                </Button>

                                                {index % 2 === 0 && <div className={ classes.separator }></div>}
                                            </>
                                        )
                                    }) : <Typography className="text-regular text-st">Nenhum arquivo enviado</Typography>
                                }


                                {
                                    healthAttendanceFiles && healthAttendanceFiles.find(x => x.type == "Exames") ? <Grid item xs={12}>Exames:</Grid> : ""}

                                <Grid item xs={12}>
                                    {
                                        healthAttendanceFiles && healthAttendanceFiles.map((item, index) => {
                                            if(item.type != "Exames") return;

                                            return (
                                                <>
                                                    <Button
                                                        style={{ color:"#4a4ae9", textTransform: "none", fontWeight: 400 }}
                                                        startIcon={<DownloadIcon style={{fill: "#4a11e6"}} />}
                                                        onClick={e => handleOpenFile(item)}
                                                    >
                                                        {attendance && `${attendance.patient.full_name.split(" ")[0]}-${attendance.patient.id}-${index}`}
                                                    </Button>
            
                                                    {index % 2 === 0 && <div className={ classes.separator }></div>}
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                    
                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    <div style={{ display:"flex", alignItems: "center", justifyContent:"space-between" }}>
                        <h5 className={ classes.subtitle }>Antecedentes Familiares</h5>
                        {/* <div style={{ marginTop: 24}}>
                            <Button
                                style={{ color:"#4a4ae9", textTransform: "none", fontWeight: 400 }}
                                startIcon={editAntecedentes ? <Save style={{fill: "#03e016"}}/> : <EditIcon className={classes.primaryFill} />}
                                onClick={e => {
                                    setEditAntecedentes(!editAntecedentes);
                                }}
                            >
                                {editAntecedentes ? "Salvar" : "Editar"}
                            </Button>
                        </div> */}
                    </div>

                    <div>                        
                        {
                            anamnesePreForm && anamnesePreForm.anamnese_pre_comorbidity_family_history.map((item, index) => { 
                                if(editAntecedentes){
                                    return (
                                        <>
                                            <Input 
                                                type="text" 
                                                placeholder="Edite o campo" 
                                                maxLength="255" 
                                                value={anamnesePreForm && anamnesePreForm.anamnese_pre_comorbidity_family_history[index].observation}
                                                onChange={e => {

                                                    setAnamnesePreForm(prevState => {

                                                        var _temp = prevState;

                                                        _temp.anamnese_pre_comorbidity_family_history[index].observation = e.target.value;

                                                        return _temp;
                                                    })


                                                    saveAntecedentes({
                                                        item,
                                                        value: e.target.value,
                                                        event: e
                                                    })
                                                }}
                                            />
                                            <br />
                                        </>
                                    )
                                }

                                return (
                                    <TextHightlight1 key={index}>
                                        <strong>{item.observation}</strong>
                                    </TextHightlight1>
                                )
                            })
                        }
                    </div>

                    <h6 className={ classes.subsubtitle } style={{marginBottom: "10px"}}>Comorbidades</h6>

                        <div>
                            { !props.anamnesePreForm || !props.anamnesePreForm.anamnese_pre_comorbidity || props.anamnesePreForm.anamnese_pre_comorbidity.length <= 0 ? <Typography className="text-regular text-st">Não informado</Typography> : "" }
                            {       
                                props.anamnesePreForm && props.anamnesePreForm.anamnese_pre_comorbidity.map((item, index) => {

                                    if(editAntecedentes){
                                        return (
                                            <>
                                                <Input 
                                                    type="text" 
                                                    // placeholder="Edição de campos"
                                                    maxLength="255" 
                                                    value={anamnesePreForm && anamnesePreForm.anamnese_pre_comorbidity[index].observation}
                                                    onChange={e => {

                                                        setAnamnesePreForm(prevState => {

                                                            var _temp = prevState;

                                                            _temp.anamnese_pre_comorbidity[index].observation = e.target.value;

                                                            return _temp;
                                                        })


                                                        saveAntecedentes({
                                                            item,
                                                            value: e.target.value,
                                                            event: e
                                                        })
                                                    }}
                                                />
                                                <br />
                                            </>
                                        )
                                    }

                                    return (
                                        <TextHightlight1 key={index}>
                                            <strong>{ item.comorbidity ? item.comorbidity.description : "-" } OBS: {item.observation}</strong>
                                        </TextHightlight1>
                                    )
                                })
                            }
                        </div>

                    <div>
                    {/* {
                        patientData && patientData.anamnese_pre_comorbidity.map((item, index) => {
                            return (
                                <TextHightlight1 key={index}>
                                    <strong>{item.observation}</strong>
                                </TextHightlight1>
                            )
                        })
                    } */}
                    </div>

                    <h6 className={ classes.subsubtitle } style={{margin: "10px 0 10px 0"}}>Hábitos sociais</h6>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <strong>Atividade Prática: </strong>{props.anamnesePreForm && props.anamnesePreForm.physical_activity ? props.anamnesePreForm.physical_activity : "Não informado"}<br />
                        </Grid>  
                        {props.anamnesePreForm && props.anamnesePreForm.smoking == "Ex-fumante" ? (
                            <Grid item xs={12}> 
                                <strong>Tabagismo: </strong>{props.anamnesePreForm && `${props.anamnesePreForm.smoking}, por ${props.anamnesePreForm.smoking_period} e parou por ${props.anamnesePreForm.smoking_period_without}` }  <br />
                            </Grid>
                            ) : ""
                        }
                        {props.anamnesePreForm && props.anamnesePreForm.smoking == "Fumante" ? (
                            <Grid item xs={12}> 
                                <strong>Tabagismo: </strong>{props.anamnesePreForm && `${props.anamnesePreForm.smoking}, por ${props.anamnesePreForm.smoking_period} e a quantidade de cigarros é de ${props.anamnesePreForm.smoking_period_without}` }  <br />
                            </Grid>
                            ) : ""
                        }
                        {props.anamnesePreForm && props.anamnesePreForm.smoking == "Não fumante" ? (
                            <Grid item xs={12}> 
                                <strong>Tabagismo: </strong>{props.anamnesePreForm && props.anamnesePreForm.smoking}<br />
                            </Grid> 
                            ) : ""
                        }
                        { props.anamnesePreForm && !props.anamnesePreForm.smoking ? <Grid item xs={12}> 
                                <strong>Tabagismo: </strong>Não informado<br />
                            </Grid> : "" }
                        <Grid item xs={12}> 
                            <strong>Uso de bebida alcoólica: </strong>{props.anamnesePreForm && renderAlcoholicField(props.anamnesePreForm)}<br />
                        </Grid>  
                        {props.anamnesePreForm && props.anamnesePreForm.drugs == "Sim" ? (
                        <Grid item xs={12}>     
                               <strong>Uso de drogas ilícitas: Sim</strong> <br />
                               {props.anamnesePreForm.anamnese_pre_form_drugs && props.anamnesePreForm.anamnese_pre_form_drugs.map(x => `${x.drug_type || ""}: ${x.drug_use || ""}`).join(', ')}
                        </Grid>
                         ) : 
                            <Grid item xs={12}>     
                                <strong>Uso de drogas ilícitas: </strong>{props.anamnesePreForm && props.anamnesePreForm.drugs ? props.anamnesePreForm.drugs : "Não informado"}<br />
                            </Grid>
                        }
                    </Grid>

                    
                    {/* <div className={ classes.separator }></div>

                    <Grid container>
                        <Grid item md={12}>
                            <div style={{ display:"flex", alignItems: "center", justifyContent:"space-between" }}>
                                <h5 className={ classes.subtitle }>Biomedidas e exames</h5>
                                <div style={{ marginTop: 24}}>
                                    <Button
                                        style={{ color:"#4a4ae9", textTransform: "none", fontWeight: 400, marginRight: 16 }}
                                        startIcon={<AddCircle style={{ fill:"#4a4ae9" }} />}
                                        onClick={e => props.onNewBioMedida(true)}>
                                        Adicionar
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <Biomedidas healthAttendanceId={ attendance.id } patientId={ attendance.patient ? attendance.patient.id : null } onEdit={ biomedida => props.onEditBiomedida(biomedida) } />
                        </Grid>
                    </Grid> */}

                    <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} />

                    {/* {  vacinas && vacinas.length ? ( */}
                        {/* <h5 className={classes.subtitle}>Vacinas</h5>
                        <div>
                            <TableContainer >
                                <Table className={classes.table} aria-label="vacinasTable">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" classes={{ head: classes.textColor }}>Data</TableCell>
                                            <TableCell align="left" classes={{ head: classes.textColor }}>Vacina</TableCell>
                                            <TableCell align="left" classes={{ head: classes.textColor }}>Dose</TableCell>
                                            <TableCell align="left" classes={{ head: classes.textColor }}>Lote</TableCell>
                                            <TableCell align="right" classes={{ head: classes.textColor }}>Resultado</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        vacinas && vacinas.map((item, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell>{item.application}</StyledTableCell>
                                                    <StyledTableCell>{item.vaccine}</StyledTableCell>
                                                    <StyledTableCell>{item.dosage}</StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        <Visibility className={classes.primaryFill} />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                )
                                        })
                                    }                                            
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className={ classes.separator } style={{ marginTop: 8, marginBottom: 16 }}></div>
                        </div> */}
                    {/* // ) : '' } */}

                    {/* {  vacinas && vacinas.length ? ( */}
                        {/* <div>
                            <h5 className={classes.subtitle}>Resultados de exames de imagens anteriores</h5>
                            <TableContainer >
                                <Table className={classes.table} aria-label="resultExamesImagensAnteriores table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell classes={{ head: classes.textColor }}>Data</TableCell>
                                            <TableCell align="center" classes={{ head: classes.textColor }}>Unidade</TableCell>
                                            <TableCell align="center" classes={{ head: classes.textColor }}>Solicitante</TableCell>
                                            <TableCell align="right" classes={{ head: classes.textColor }}>Resultado</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow >
                                            <StyledTableCell>{"11/02/2022"}</StyledTableCell>
                                            <StyledTableCell>{"Pref. São Paulo"}</StyledTableCell>
                                            <StyledTableCell>{"Equipe Multiprofissional (CRM:1879-SP)"}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <GetApp className={classes.primaryFill} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> */}
                    {/* ) : '' } */}
                    {/* <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} /> */}

                    {/* {  vacinas && vacinas.length ? ( */}
                        {/* <div>
                            <h5 className={classes.subtitle}>Exames laboratoriais</h5>
                            <TableContainer >
                                <Table className={classes.table} aria-label="resultExamesLaboratoriaisTable">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell classes={{ head: classes.textColor }}>Solicitação</TableCell>
                                            <TableCell align="center" classes={{ head: classes.textColor }}>Procedimento</TableCell>
                                            <TableCell align="center" classes={{ head: classes.textColor }}>Lateralidade</TableCell>
                                            <TableCell align="center" classes={{ head: classes.textColor }}>Tipos de Exames</TableCell>
                                            <TableCell align="right" classes={{ head: classes.textColor }}>Resultado</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow >
                                            <StyledTableCell>{"10/02/2022"}</StyledTableCell>
                                            <StyledTableCell>{"Ultrassonografia"}</StyledTableCell>
                                            <StyledTableCell>{"-"}</StyledTableCell>
                                            <StyledTableCell>{"Patológico"}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <OpenInNew className={classes.primaryFill} />
                                                <GetApp className={classes.primaryFill} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div> */}
                    {/* ) : '' } */}

                    {/* <hr style={{ border:0, borderBottom: "1px solid #e6e6e6", margin: "43px 0 0 0" }} /> */}

                    {/* {  vacinas && vacinas.length ? ( */}
                        {/* <>
                            <h5 className={classes.subtitle}>Exames enviados pelo paciente</h5>
                            <div style={{ margin: "0 0 20px 0" }}>
                                <TableContainer >
                                    <Table className={classes.table} aria-label="resultExamesLaboratoriaisTable">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell classes={{ head: classes.textColor }}>Data</TableCell>
                                                <TableCell align="center" classes={{ head: classes.textColor }}>Unidade</TableCell>
                                                <TableCell align="center" classes={{ head: classes.textColor }}>Pedido</TableCell>
                                                <TableCell align="center" classes={{ head: classes.textColor }}>Solicitante</TableCell>
                                                <TableCell align="right" classes={{ head: classes.textColor }}>Resultado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow >
                                                <StyledTableCell>{"09/02/2022"}</StyledTableCell>
                                                <StyledTableCell>{"Pref. São Paulo"}</StyledTableCell>
                                                <StyledTableCell>{"72274741"}</StyledTableCell>
                                                <StyledTableCell>{"Equipe Multiprofissional"}</StyledTableCell>
                                                <StyledTableCell align="right">
                                                    <OpenInNew className={classes.primaryFill} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </> */}
                    {/* ) : '' } */}


            <div style={{ textAlign: "right" }}>
                <DButton onClick={e => props.back && props.back()} variant="secondary" size="sm" style={{ marginRight: 16 }}>Voltar</DButton>
                <DButton onClick={e => attendance && window.open(`${window.location.origin}/visualizar-exame/${attendance.id}`, '_blank')} variant="primary" icon={ <PrintIcon fontSize="small" /> } size="sm">Criar PDF</DButton>
            </div>
        </>
    )
}

DadosPaciente.defaultProps = {
    onEditBiomedida: function(biomedida) {
        console.log(biomedida);
    },
    onNewBioMedida: function() {
        console.log('onNewBioMedida');
    },
    toggleModal: function () {
        console.log('toggleModal');
    }
}

export default DadosPaciente;