import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, IconButton, SvgIcon  } from "@material-ui/core";
import { Person, People } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
import triagem from "../../../../../../assets/img-icon/triagem.svg";

const useStyles = makeStyles((theme) => ({    
    chip: {
        color: theme.palette.neutral.white,
        backgroundColor: theme.palette.advanced.main,
        margin: "0px 5px 5px 0px",
        padding: "2px 12px 2px 8px",
        boxSizing: "content-box"
    },
    label: {
        fontSize: 12,
        fontWeight: 500,
        padding: 0
    },
    avatar: {
        background: "transparent !important",
        margin: "0 2px 0 0 !important",
        width: "24px !important",
        height: "24px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const Monitoring = (props) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.3335 12.0001H8.66683V14.0001H7.3335V12.0001Z" fill="white"/>
            <path d="M10.6668 14.0001V15.3335H5.3335V14.0001H10.6668Z" fill="white"/>
            <path d="M13.3332 12.0001H2.6665C2.13623 11.9996 1.62783 11.7887 1.25288 11.4138C0.877917 11.0388 0.667033 10.5304 0.666504 10.0001V2.66681C0.667033 2.13654 0.877917 1.62814 1.25288 1.25318C1.62783 0.878222 2.13623 0.667339 2.6665 0.666809H13.3332C13.8634 0.667339 14.3718 0.878222 14.7468 1.25318C15.1218 1.62814 15.3326 2.13654 15.3332 2.66681V10.0001C15.3326 10.5304 15.1218 11.0388 14.7468 11.4138C14.3718 11.7887 13.8634 11.9996 13.3332 12.0001ZM2.6665 2.00014C2.48969 2.00014 2.32012 2.07038 2.1951 2.1954C2.07008 2.32043 1.99984 2.49 1.99984 2.66681V10.0001C1.99984 10.177 2.07008 10.3465 2.1951 10.4715C2.32012 10.5966 2.48969 10.6668 2.6665 10.6668H13.3332C13.51 10.6668 13.6796 10.5966 13.8046 10.4715C13.9296 10.3465 13.9998 10.177 13.9998 10.0001V2.66681C13.9998 2.49 13.9296 2.32043 13.8046 2.1954C13.6796 2.07038 13.51 2.00014 13.3332 2.00014H2.6665Z" fill="white"/>
            <path d="M10.6668 10.0001L9.39083 5.74681L8.66683 8.66681L7.79416 6.66681H7.5735L6.66683 9.33348L5.8955 6.62214L5.5395 7.33348H3.3335V6.66681H5.1275L6.00016 4.66681L6.71216 7.14214L7.0935 6.00014H8.20616L8.53083 6.64881L9.3335 2.66681L10.6668 8.00014L11.0935 6.66681H12.6668V7.33348H11.5735L10.6668 10.0001Z" fill="white"/>
    </svg>)
}

function SolicitacaoTipo(props)
{
    const classes = useStyles();

    const tipo = {
        consulta: {
            icon: <Person />,
            label: 'Consulta'
        },
        interconsulta: {
            icon: <People />,
            label: 'Interconsulta'
        },
        monitoramento: {
            icon: <div><Monitoring /></div>,
            label: 'Monitoramento'
        },
        orientacao: {
            icon: <div><InfoIcon /></div>,
            label: 'Orientação'
        },
        triagem: {
            icon: <div>
                    <IconButton>
                        <img src={triagem} />
                    </IconButton>
                </div>,
            label: 'Triagem'
        },
    }

    const [chipType, setChipType] = useState(null);

    useEffect(() => {
        if(props.healthAttendance.interconsultation == 1)
            setChipType(tipo.interconsulta)
        else {
            if(props.healthAttendance.service_character == 2)
                setChipType(tipo.monitoramento)
            else if(props.healthAttendance.service_character == 3) 
                setChipType(tipo.orientacao)
            else if(props.healthAttendance.service_character == 4) 
                setChipType(tipo.triagem)
            else
                setChipType(tipo.consulta)
        }
    },[props])

    return (
        <>
            { chipType && <Chip
                size="small"
                avatar={ chipType.icon }
                label={ chipType.label }
                color="primary"
                classes={{
                    root: classes.chip,
                    labelSmall: classes.label,
                    avatarSmall: classes.avatar
                }}
            /> }
        </>        
    )
}

export default SolicitacaoTipo;
