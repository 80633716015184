import axios from 'axios';
import env from 'react-dotenv';

function getAllComorbidities(token) {
    return axios.get(`${env.API_URL}/comorbidity/list?token=${ token }`)
              .then(res => res.data);
}

function createComorbidity(token, dados) {
    return fetch(`${env.API_URL}/comorbidity?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateComorbidity(token, dados) {
    return fetch(`${env.API_URL}/comorbidity/${ dados.id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'            
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function putStatusComorbidity(token, dados) {
    return fetch(`${env.API_URL}/comorbidity/${ dados.id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteComorbidity(token, id) {
    return fetch(`${env.API_URL}/comorbidity/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

export { getAllComorbidities, createComorbidity, updateComorbidity, deleteComorbidity, putStatusComorbidity }