import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import DAutoComplete from "../../../shared/DAutoComplete";
import DButton from "../../../shared/DButton";
import { getAllGroups } from "./../../../../api/groups";
import { updateGroup, getPatientById } from "./../../../../api/patient";
import { toast } from "react-toastify";
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 20
    },
    consultas_titulo: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: "24px",
      color: theme.palette.primary.light,
      padding: "4px 0 0 0",
      margin: 0
    },
    removeItem: {
        color: theme.palette.support.error,
        fontWeight: "500 !important"
    },
    buttonSave: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        border: `1px solid ${theme.palette.background.cloud} !important`,
        fontSize: 14,
        padding: "6px !important",
        color: '#2A814E !important',
        background: `${theme.palette.background.cloud} !important`,
        fontWeight: "400 !important"
    },
    buttonAdd: {
        minWidth: 110,
        height: 33,
        borderRadius: 100,
        border: `1px solid ${theme.palette.background.cloud} !important`,
        fontSize: 14,
        padding: "6px !important",
        // background: '#F9F9F9 !important',
        fontWeight: "400 !important"
    },
    errorColor: {
        color: theme.palette.support.error
    }
}));

function PacienteGroup(props)
{
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeGroup, setActiveGroup] = useState(false);  
    const [error, setError] = useState(false);
    
    const classes = useStyles();
    const open = Boolean(anchorEl);

    useEffect(function(){

        getPatientById(localStorage.getItem('token'), props.paciente.patient.id).then(res => {
            if(res.status) {
                if(res.details.patient.patient_has_group.length > 0) {
                    setGroup(res.details.patient.patient_has_group.filter(res => res.group !== null).map(item => item.group_id));
                }
            }
        })

        getAllGroups(localStorage.getItem('token')).then(res => {
            if(!res.status) {
                setGroups(res.map(function(item){
                    return {
                        id: item.id,
                        value: item.name
                    }
                }))
            }
        })
    }, [])

    const handleGroup = (event, index) => {
        
        const g = [...group];

        g[index] = event;

        setGroup(g);
        setActiveGroup(event ? true : false);
    }

    const handleRemove = (index) => {

        const g = [...group];
        g.splice(index, 1)
        setGroup(g)
    }

    const saveGroup = (body) => {

        setLoading(true)

        setActiveGroup(false)

        let error_form = false;

        group.map((item) => {
            if(!item || item === 'null' || item === undefined || item === "novo-id"){
                setError("Campo obrigatório")
                error_form = true;  
            } 
        });

        if(!error_form){
            updateGroup(localStorage.getItem('token'), props.paciente.patient.id, body).then(res => {
                setLoading(false)
                if(res.status)
                    toast.success(res.message ? res.message : 'Operação realizada com sucesso.')
                else
                    toast.error(res.message ? res.message : 'Erro inesperado.')
    
                if(props.saveClose)
                    props.onClose({
                        group,
                        groups,
                        close: true
                    })
    
            }).catch(err => {
                setLoading(false)
                toast.error(err ? err : 'Erro inesperado.')
            })
        } else {
            setLoading(false)
        }
    }

    const GroupComponent = (props) => {

        const [anchorEl, setAnchorEl] = useState(null);      
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
    
        const handleClose = (action, index) => {
            
            setAnchorEl(null);
            
            switch(action) {
                case 'acessar':
                    props.onGroup(true)
                    break;
                case 'gestante':
                case 'Gestante':
                    break;
                case 'remover':         
                    props.onRemove(index)
                    break;
                default:
                    break;
            }
        };

        var activeForm = groups.find(x => x.id === props.value);
        
        var form_name = activeForm ? String(activeForm.value).toLowerCase() : "";

        const getPregnantLink = () => {
            let pid = window.location.href.split('/').pop();
            if(isNaN(pid))
            {
                if(!props.paciente || (props.paciente && !props.paciente.patient))
                {
                    return;
                }
                else
                {
                    pid = props.paciente.patient.id;
                }
            }

            let route = `/pacientes/mae-paulistana/${pid}`

            return route;
        }

        return (
            <Grid item md={12}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item md={10} style={{paddingBottom: 15}}>
                        <DAutoComplete 
                            id={ `groups-${props.index}` }
                            label="Grupo"
                            placeholder="Grupos"
                            fullWidth
                            error={ error && (!props.value || props.value === "novo-id") }
                            helperText={ error && (!props.value || props.value === "novo-id") ? error : '' }
                            getOptionDisabled={(option) => group.indexOf(option.id) > -1 && props.value !== option.id }
                            value={ groups.filter( res => res.id == props.value )[0] }
                            options={ groups }
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) => value && option.id === value.id}
                            onChange={(event, newValue) => {
                                props.onChange(newValue ? newValue.id : null)
                            }} />
                    </Grid>
                    <Grid item>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id={ `long-menu-${props.index}` }
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={e => handleClose('close')}
                            PaperProps={{
                                style: {
                                    width: '300px'
                                },
                            }}>
                            {
                                String(form_name).toLowerCase() === 'gestante' ? (
                                    
                                    <NavLink
                                    to={getPregnantLink}
                                    style={{ textDecoration: 'none' }}
                                    >
                                        <MenuItem onClick={ e => handleClose(form_name)}>
                                            <ListItemIcon>
                                                <AddCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                                <ListItemText primary="Acessar" />
                                        </MenuItem>
                                    </NavLink>
                                ) : ""
                            }
                            <MenuItem onClick={ e => handleClose('remover', props.index)}>
                                <ListItemIcon>
                                    <CancelIcon fontSize="small" className={classes.errorColor} />
                                </ListItemIcon>
                                <ListItemText classes={{primary: classes.removeItem}} primary="Excluir grupo de paciente" />
                            </MenuItem>                               
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <form autoComplete="off" onSubmit={ e => {
                e.preventDefault();

                saveGroup({
                    groups: group.filter(x => x != 'novo-id')
                });

            }}>
                <Grid container classes={{root: classes.container}}>
                    <Grid item md={5}>
                        <h5 className={ classes.consultas_titulo }>
                            Grupo de paciente
                        </h5>
                    </Grid>
                    <Grid item md={7}>
                        <Grid container spacing={1} justifyContent='flex-end'>
                            <Grid item>
                                {
                                    activeGroup ? <DButton disabled={loading} className={classes.buttonSave} type="submit" variant="primary" size="none">
                                    { loading ? <CircularProgress size={15} /> : "Salvar" }
                                </DButton> : null
                                }
                            </Grid>
                            <Grid item>
                                <DButton onClick={ e => {
                                    if(group.length < groups.length) {
                                        const g = group            
                                        setGroup([...g, 'novo-id'])
                                        setActiveGroup(g)
                                        setError("");
                                    } else {
                                        toast.error('Não existem mais grupos a serem adicionados.')
                                    }
                                } } disabled={loading} className={classes.buttonAdd} type="button" variant="primary" size="none" >Adicionar</DButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    { group.length > 0 
                        ? 
                            <>
                                { group.map(function(item, index){
                                    return <GroupComponent value={ item } onGroup={ props.onGroup } pregnancy={props.pregnancy} onRemove={ e => {handleRemove(e); setActiveGroup(true)} } onChange={ event => handleGroup(event, index) } key={ `group-component-${index}` } index={index} />
                                }) }
                            </>
                        :
                            ""
                    }                    
                </Grid>
            </form>
        </>
    )
}

export default PacienteGroup;