//Import core
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { filterProfessionalSchedule } from "../../../../api/schedule";
import useDebounce from "../../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
}));

function AgendaFilter(props) {
  const classes = useStyles();

  const [filterValue, setFilterValue] = useState("");

  const filterValueDebounced = useDebounce(filterValue, 500);

  async function listarPacientes() {
    var filter = {};

    // if(/^.{11,11}$/.test(filterValueDebounced.replace(/[^0-9]/g, ''))) { // Match xxx.xxx.xxx-xx and xxxxxxxxxxx
    //     filter.cpf = filterValueDebounced
    // }

    // if(/^.{15,15}$/.test(filterValueDebounced.replace(/[^0-9]/g, ''))) { // Match CNS
    //     filter.cns = filterValueDebounced
    // }

    // filter.user_id = 106 || localStorage.getItem("uid");
    filter.user_id = localStorage.getItem("uid");

    if (filterValueDebounced) {
      filter.search = filterValueDebounced;
    }

    filterProfessionalSchedule(localStorage.getItem("token"), filter)
      .then((data) => {
        if (data.status) {
          props.setScheduleList(data.schedule);
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    listarPacientes();
  }, [filterValueDebounced]);

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
      autoComplete="off"
    >
      <OutlinedInput
        id="outlined"
        type="text"
        placeholder="Buscar por nome, ID, CNS ou CPF"
        labelWidth={0}
        value={filterValue}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            listarPacientes();
          }
        }}
        onChange={(e) => {
          setFilterValue(
            e.target.value
              .toString()
              .replace(/[`´¨~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/]/gi, "")
          );
        }}
        startAdornment={
          <InputAdornment position="start">
            <IconButton onClick={(e) => listarPacientes()} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default AgendaFilter;
