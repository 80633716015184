import React, {useState} from "react";
import {Autocomplete as MAutocomplete, createFilterOptions } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {FormControl, FormHelperText} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Error from "@material-ui/icons/Error";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '100%'
    },
    autoComplete: {
        minHeight: 36
    },
    label: {
      fontSize: 14,
      marginBottom: 5
    },
    chip: {
        height: 25,
        background: '#F4F4F4 !important',
        border: 'none !important',
        zIndex: 10
    },
    deleteIcon:{
        fontSize: 9,
        color: '#4F4F4F !important'
    },
    endAdornment:{
        top: "calc(50% - 14px)",
        left: 0,
        position: "absolute",
        width: "100%",
        justifyContent: "space-between"
    },
    clearIndicator: {
        padding: 4,
        visibility: "hidden",
        position: "absolute",
        right: 0
    },
    input: {
        padding: "0 32px"
    }

}));



function DAutoCompleteCreate(props) {

    const [opcoes, setOpcoes] = useState(props.options);

    const classes = useStyles();

    const properts = {...props};

    delete properts.label;
    delete properts.error;
    delete properts.helperText;
    delete properts.renderInput;
    delete properts.value;
    delete properts.options;
    delete properts.onNewChange;

    const filterOptions = (options, params) => {

        const filtered = filter(options, params);

        if (params.inputValue !== '' && filtered.length < 1) {

            var new_list = [{
                value: params.inputValue,
                inputValue: `Criar: ${params.inputValue}`,
            }]

            new_list.push(...filtered)

            return new_list;
        } else {

            return filtered;
        }
    }

    return (
        <FormControl className={ `${ classes.root } ${ props.error ? 'form-control-error' : '' } form-control-create-tag` }>
            { props.label ? <label className={ classes.label }>{ props.label }</label> : '' }
            <MAutocomplete { ...properts }
               classes={{
                   root: classes.autoComplete,
                   endAdornment: classes.endAdornment,
                   clearIndicator: classes.clearIndicator,
                   inputRoot: classes.input
               }}
               multiple
               autoSelect
               options={ opcoes }
               value={ props.value }
               filterOptions={ filterOptions }
               getOptionLabel={(option) => option.inputValue ? option.inputValue : option.value}
               getOptionSelected={(option, value) => value && option.id === value.id}
               popupIcon={ props.error ? <Error /> : <AddCircleIcon color="primary" classes={{
                   colorPrimary: 'MuiSvgIcon-popupIcon'
               }} /> }
               renderTags={(value, getTagProps) =>
                   value.map((option, index) => (
                       <Chip label={option.value} deleteIcon={ <CloseOutlined /> } {...getTagProps({ index })}
                            classes={{
                               root: classes.chip,
                               deleteIcon: classes.deleteIcon
                            }} />
                   ))
               }
               renderInput={(params) => (
                   <TextField
                       {...params}
                       variant="standard"
                       placeholder={ props.placeholder }
                       
                    //    InputProps={{
                    //     startAdornment: <InputAdornment position="start"><AddCircleIcon color="primary" classes={{
                    //         colorPrimary: 'MuiSvgIcon-popupIcon'
                    //     }} /></InputAdornment>,
                    //  }}
                   />
               )}
               onKeyDown={ e => {
                   if(e.key === 'Enter') {

                       var value = e.target.value;

                       var new_id = opcoes.length + 1;
                       var new_especialidades = [ ...opcoes, {id: new_id, value, new_option: true }];

                       setOpcoes(new_especialidades);
                       props.onNewChange([ ...props.value, {id: new_id, value, new_option: true }]);
                   }
               }}
               onChange={(event, newValue) => {

                   var key = newValue.length - 1;

                   if(newValue[key] && newValue[key].inputValue !== undefined) {

                       var new_id = opcoes.length + 1;
                       var new_especialidades = [ ...opcoes, {id: new_id, value: newValue[key].value, new_option: true }];

                       setOpcoes(new_especialidades);
                       props.onNewChange([ ...props.value, {id: new_id, value: newValue[key].value, new_option: true }]);
                   } else {
                       props.onNewChange(newValue);
                   }
               }}
            />
            { props.helperText ? <FormHelperText>{ props.helperText }</FormHelperText> : '' }
        </FormControl>
    )
}

const filter = createFilterOptions();

export default DAutoCompleteCreate;