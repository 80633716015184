import env from 'react-dotenv';

function getAllQuestions(token) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/question/all?token=${ token }`, {
        headers: { 'x-auth-token': token },
        method: 'get'
    }).then(res => res.json());
}

function getAllQuestionsByTitle(token, title) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/question/all/${ title }?token=${ token }`, {
        headers: { 'x-auth-token': token },
        method: 'get'
    }).then(res => res.json());
}

function getQuestionById(token, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.
    
    return fetch(`${api_url}/faq/question/${ id }?token=${ token }`, {
        headers: { 'x-auth-token': token },
        method: 'get'
    }).then(res => res.json());
}

function createQuestion(token, dados) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/question?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function notifyNewQuestion(token, id)
{
    return fetch(`${env.API_URL}/faq/new/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function updateQuestion(token, dados, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/question/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteQuestion(token, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/question/${ id }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'delete'
    }).then(res => res.json());
}

export { 
    getAllQuestions,
    getAllQuestionsByTitle,
    getQuestionById,
    createQuestion,
    updateQuestion,
    deleteQuestion,
    notifyNewQuestion
}