/* eslint-disable no-use-before-define */
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import axios from 'axios';
import fetchIntercept from 'fetch-intercept';
import FaqUser from "./component/FaqUser/FaqUser";
import "./assets/sass/app.scss";
import { useContext, useEffect } from "react";
import LayoutAdmin from "./component/shared/LayoutAdmin";
import LayoutAdminContext from "./component/shared/LayoutAdminContext";
import ProfissionalContent from "./component/Profissional/ProfissionalContent.jsx";
import Paciente from "./component/Paciente";
import PacienteContent from "./component/Paciente/PacienteContent";
import Agenda from "./component/Agenda";
import HealthAttendanceType from "./component/HealthAttendanceType";
import AutoCadastro from "./component/AutoCadastro";
import Autocomplete from "./component/Demo/autocomplete";
import Configuracao from "./component/Configuracao";
import Relatorio from "./component/Relatorio";
import NaoAutorizado from "./component/NaoAutorizado";
import MeuPerfil from "./component/MeuPerfil";
import { useState } from "react";
import AtendimentoImediatoContent from "./component/AtendimentoImediato/AtendimentoImediatoContent";
import AtendimentoSideBar from "./component/AtendimentoImediato/AtendimentoSideBar";
import VisualizarExame from "./component/AtendimentoImediato/components/VisualizadorExames";

import ErrorPage from "./component/ErrorPage/ErrorPage";

import SideBar from "./component/Profissional/components/SideBar";
import SideBarPacientes from "./component/Paciente/components/SideBar";
import { Sidebar as SidebarConfiguracao } from "./component/Configuracao/component/Sidebar";

import SolicitacoesContent from "./component/Solicitacoes/SolicitacoesContent";
import MdPatients from "./component/Solicitacoes/components/Modais/MdPatients";
import { SideBarFilterProfessionalProvider } from "./context/useFilterProfessional";
import AtendimentoContextProvider from "./context/AtendimentoContext";
import { PatientFilterProvider } from "./context/PatientFilterContext";
import { PatientProvider } from "./context/usePatient";
import { PatientContext, PatientProvider as PatientCtxProvider, usePatient } from "./context/PatientContext";
import { SolicitacaoProvider } from "./context/SolicitacaoContext";
import { ThemeProvider } from "./context/ThemeContext";
import { AuthRouteProvider } from "./context/AuthRouteContext";
import NotificationCentral from "./component/shared/Notifications/Panel";
import { AppContext } from "./context/AppContext";
import { ToastContainer, toast } from "react-toastify";
import Anamnese from "./component/AtendimentoImediato/PacienteDetails/components/Anamnese";
import TelaEmBranco from "./component/TelaEmBranco";
import FloatingButton from "./component/shared/FloatingButton";
import ModalChat from "./component/shared/ModalChat";
import ModalCall from "./component/shared/ModalCall";
import ModalIncomingCall from "./component/shared/ModalIncomingCall";
import HistoricoAcoes from "./component/Relatorios/HistoricoAcoes";
import PowerBI from "./component/PowerBI";
import { useAppTheme } from "./context/useThemeContext";
import { getList as getListTheme, palette } from './api/temas';
import InviteInterconsultation from "./component/shared/InviteInterconsultation";
import { MenuProvider } from "./context/MenuContext";
import NotifyPopUp from './component/shared/Notifications/PopUp/NotifyPopUp';
import CreatePaciente from "./component/Paciente/components/CreatePaciente";

function InnerApp() {

    const [configuracao, setConfiguracao] = useState("forms");
    const [patientFilter, setPatientFilter] = useState([]);
    const { openNotifications, setOpenNotifications } = useContext(AppContext);
    const [notSidebarAtendimento, setNotSidebarAtendimento] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    
    const [chatOpen, setChatOpen] = useState(false);
    const [callOpen, setCallOpen] = useState(false);
    const [incomingCallOpen, setIncomingCallOpen] = useState(false);
    const [dadosCall, setDadosCall] = useState(null);
    const [dadosChat, setDadosChat] = useState(null);
    const [callStarted, setCallStarted] = useState(false);   
    const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);   

    useEffect(() => {

        axios.interceptors.response.use(response => {

            if(response.data.status_code && response.data.status_code === 401)
                toast.error("Funcionalidade não permitida.");

            return response;
        }, error => {
    
            return error;
        });
    
        const unregister = fetchIntercept.register({
            request: function (url, config) {
                // Modify the url or config here
                return [url, config];
            },
        
            requestError: function (error) {
                // Called when an error occured during another 'request' interceptor call
                return Promise.reject(error);
            },
        
            response: function (response) {

                // Modify the reponse object                           
                response.clone().json().then(res => {
                    if(res.status_code && res.status_code === 401) {
                        toast.error("Funcionalidade não permitida.");
                    }
                });                
    
                return response;
            },
        
            responseError: function (error) {
                // Handle an fetch error
                return Promise.reject(error);
            }
        });

    }, [])    

     

  return (
    <>

      <NotifyPopUp/>
      <NotificationCentral
        open={openNotifications}
        back={(e) => setOpenNotifications(false)}
      />
      <ToastContainer />

        <FloatingButton setOpenModal={e => { setChatOpen(true) }} />
        <InviteInterconsultation />
        <ModalChat 
            open={ chatOpen }
            callStarted={ callStarted }
            setCloseModal={ e => { setChatOpen(false) } } 
            openCall={ (status, dadosCall, dadosChat) => { 
                setDadosCall(dadosCall);
                setDadosChat(dadosChat);
                setChatOpen(false);
                setCallOpen(true);
                setCallStarted(true);
            }} 
            incomingCall={ (status, dadosCall, dadosChat) => { 
                setDadosCall(dadosCall);
                setDadosChat(dadosChat);
                setChatOpen(false);
                setIncomingCallOpen(true);
            }}
        />
        <ModalCall 
            open={ callOpen }
            dadosChat={ dadosChat }
            dadosCall={ dadosCall } 
            setOpenChat={ e => {
                setCallOpen(false);
                setCallStarted(false);
                setChatOpen(true);
            }}
            setCloseModal={ e => { 
                setCallOpen(false); 
                setCallStarted(false);
            }}
        />
        <ModalIncomingCall 
            open={ incomingCallOpen }
            dadosChat={ dadosChat }
            dadosCall={ dadosCall }
            openCall={ (status) => { 
                setIncomingCallOpen(false);
                setCallOpen(true);
                setCallStarted(true);
            }}
            setCloseModal={ e => { setIncomingCallOpen(false) }}
        />
        <Switch>
            <Route path="/atendimento-imediato">
                <LayoutAdmin notSidebar={notSidebarAtendimento} sidebar={<AtendimentoSideBar />} onSideBar={e => console.log('onSidebar')}>                        
                    <PatientCtxProvider>
                        <PatientProvider>
                            <AtendimentoContextProvider>
                                <AtendimentoImediatoContent onSideBar={e => setNotSidebarAtendimento(!e)} />
                            </AtendimentoContextProvider>
                        </PatientProvider>
                    </PatientCtxProvider>
                </LayoutAdmin>
            </Route>
            <Route path="/construtor">
                <LayoutAdmin sidebar={<AtendimentoSideBar />} onSideBar={e => console.log('onSidebar')}>
                    <AtendimentoContextProvider>
                        <Anamnese />
                    </AtendimentoContextProvider>
                </LayoutAdmin>
            </Route>
            <Route path="/meu-perfil">
                <LayoutAdmin notSidebar>
                    <MeuPerfil />
                </LayoutAdmin>
            </Route>
            <Route path="/healthattendancetype">
                <LayoutAdmin notSidebar>
                    <HealthAttendanceType />
                </LayoutAdmin>
            </Route>
            <Route path="/profissional">
                <SideBarFilterProfessionalProvider>
                    <LayoutAdmin onSideBar={e => console.log('onSidebar')} active="profissionais" sidebar={<SideBar />}>
                        <ProfissionalContent />
                    </LayoutAdmin>
                </SideBarFilterProfessionalProvider>
            </Route>
            <Route path="/pacientes">
                <PatientCtxProvider>
                    <PatientFilterProvider>
                        <LayoutAdmin
                            onSideBar={e => setSideBarOpen(e)}
                            active="pacientes"
                            sidebar={
                                <SideBarPacientes
                                    sideBarOpen={sideBarOpen}
                                    filteredData={(data) => setPatientFilter(data)}
                                />
                            } >
                            <PacienteContent filteredData={{ details: patientFilter, time: new Date() }} />
                        </LayoutAdmin>
                    </PatientFilterProvider>
                </PatientCtxProvider>
            </Route>
            <Route path="/pacientes/cadastro">
                <PatientCtxProvider>
                    <PatientFilterProvider>
                        <LayoutAdmin
                            active="pacientes"
                            notSidebar
                            >
                            <CreatePaciente listar={() => null} />
                        </LayoutAdmin>
                    </PatientFilterProvider>
                </PatientCtxProvider>
            </Route>
            <Route path="/configuracao">
                <ThemeProvider>
                    <LayoutAdminContext
                        active="configuracoes"
                        sidebarOpen={true}
                        sidebar={
                            <SidebarConfiguracao
                                onChange={(newActive) => setConfiguracao(newActive)}
                                active={configuracao}
                            />
                        }
                    >
                        <Configuracao active={configuracao} />
                    </LayoutAdminContext>
                </ThemeProvider>
            </Route>
            <Route path="/solicitacao">
                <LayoutAdmin active="solicitacao" notSidebar>
                    <PatientCtxProvider>
                        <PatientProvider>
                            <SolicitacoesContent />
                        </PatientProvider>
                    </PatientCtxProvider>
                </LayoutAdmin>
            </Route>
            <Route path="/agenda">
                <LayoutAdmin notSidebar>
                    <Agenda />
                </LayoutAdmin>
            </Route>
            <Route path="/faq-user">
                <LayoutAdmin notSidebar>
                    <FaqUser />
                </LayoutAdmin>
            </Route>
            <Route path="/tela">
                <LayoutAdmin notSidebar>
                    <TelaEmBranco />
                </LayoutAdmin>
            </Route>

            <Route path="/historico-acoes">
            <LayoutAdmin notSidebar>
                <HistoricoAcoes />
            </LayoutAdmin>
            </Route>

            <Route path="/power-bi">
            <LayoutAdmin notSidebar>
                <PowerBI />
            </LayoutAdmin>
            </Route>
        </Switch>
    </>
  );
}

export default InnerApp;
