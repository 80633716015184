function getClasses(className, disabled){
    var classe = [];

    if(className)
        classe = [className.split(' ')];

    if(disabled)
        classe.push('disabled');

    return classe.join(' ');
}

function getSize(prefix, size){
    if(!size)
        return `${prefix}-default`;
    else{
        switch (size){
            case 'none':
                return "";
            case 'sm':
                return `${prefix}-sm`;
            case 'md':
                return `${prefix}-md`;
            default:
                return `${prefix}-default`;
        }
    }
}

function getvariant(variant){
    if(!variant)
        return 'primary';
    else{
        switch (variant){
            case 'primary':
                return 'btn-primary';
            case 'secondary':
                return 'btn-secondary';
            case 'ghost':
                return 'btn-ghost';
            case 'card':
                return 'btn-card';
            case 'outlined':
                return 'btn-outlined';
            default:
                return 'btn-primary';
        }
    }
}

function getType(type){
    switch (type){
        case 'password':
            return 'password';
        case 'date':
            return 'date';
        case 'number':
            return 'number';
        default:
            return 'text';
    }
}

function getEvents(props){
    let events = {};
    for(var e in props){
        switch (e){
            case 'onChange':
                events.onChange = props[e];
                break;
            case 'onClick':
                events.onClick = props[e];
                break;
            case 'onBlur':
                events.onBlur = props[e];
                break;
            default:
                break;
        }
    }

    return events;
}

export {
    getSize,
    getClasses,
    getvariant,
    getType,
    getEvents
}