import { React, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from "@material-ui/core";
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Header from "./Header";
import Configuracao from './components/Configuracao';
import NovoCampo from './components/NovoCampo';
import PreviewCampo from './components/PreviewCampo';

import { getAllProfilesNotPaginate } from './../../../../api/profiles';

const useStyles = makeStyles((theme) => ({
    MuiTabsflexContainer: {
        color: `${theme.palette.primary.light} !important`,
        boxShadow: "none",
        background: "white"
    },
    MuiTabroot: {
        textTransform: "none",
        backgroundColor: theme.palette.neutral.midgrey,
        borderRadius: "8px 8px 0 0",
        marginLeft: 4,
        marginRight: 4,
        fontWeight:  700,
        fontSize: "16px",
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        minWidth: 180,
        color: theme.palette.neutral.black
    },
    MuiTabtextColorInherit: {
        opacity: 1
    },
    Muiselected: {
        backgroundColor: theme.palette.background.snow,
        border: 0,
        color: `${theme.palette.primary.light} !important`,
    },
    box: {
        background: theme.palette.background.snow,
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        borderRadius: 8,
        padding: 24,
        marginLeft: 4,
        backgroundColor: theme.palette.background.cloud,
        height: "calc(100% - 80px)",
        overflow: "auto"
    },

    gridSection: {
        alignSelf: "flex-start",
        height: "100%"
    },

    container: {
        overflow: "visible",
        backgroundColor: "white",
        marginTop: 90,
        padding: 0,
        height: "calc(100vh - 240px)"

    },

    empty: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 18,
        color: theme.palette.neutral.black,
        fontWeight: 500
    },

    card: {
        padding: 16,
        marginBottom: 24,
        position: "relative"
    },

    card_title: {
        color: theme.palette.neutral.black,
        fontSize: 16,
        margin: "0 0 16px"
    },

    card_subtitle: {
        color: theme.palette.neutral.black,
        fontSize: 14,
        margin: "0 0 16px"
    },

    card_delete: {
        position: "absolute",
        top: 2,
        right: 8,
        color: `${theme.palette.support.error} !important`,
    },

    card_history: {
        position: "absolute",
        top: 2,
        right: 8,
        color: `${theme.palette.primary.light} !important`,
    },

    card_switch_container: {
        display: "flex",
        alignItems: "center",
        margin: "16px 0 0 -12px",
        height: 30
    },

    card_outline: {
        padding: 16,
        marginBottom: 24,
        border: `1px solid ${theme.palette.neutral.midgrey}`,
        borderRadius: 4,
        fontSize: 18
    },

    edit_input: {
        backgroundColor: theme.palette.background.snow,
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    },

    barCharRound: { 
        fontSize: 72, 
        color: theme.palette.neutral.midgrey, 
        marginBottom: 20 
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{ children }</div>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tab-${index}`,
    };
}

function BiomedidasCreate(props) {
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const [config, setConfig] = useState({
        name: null,
        viewHistory: false,
        profiles: []
    });

    const [campos, setCampos] = useState([]);

    const [error, setError] = useState({
        errorConfig: false,
        errorCampos: []
    });

    const getFieldType = (type) => {
        switch(type) {
            case 2:
                return 'lista';
            case 3:
                return 'opcoes';
            case 4:
                return 'verdadeiro_falso';
            default:
                return 'numero';
        }
    }

    useEffect(() => {
        if(props.biomedida) {            
            
            getAllProfilesNotPaginate(localStorage.getItem('token')).then(response => {
                let profiles = []
                profiles = response.profiles.map((profile, index) => {
                    return {
                        id: profile.id,
                        description: profile.description,
                        checked: props.biomedida.biomeasure_exam_profile.find(p => p.profile_id === profile.id) ? true : false
                    }
                });

                setConfig({
                    name: props.biomedida.name,
                    viewHistory: props.biomedida.show_history === 1,
                    profiles
                })

                setCampos(props.biomedida.biomeasure_exam_field.map(field => {
                    return {
                        id: field.id,
                        type: getFieldType(field.biomeasure_exam_field_type_id),
                        name: field.label,
                        showName: field.show_label === 1,
                        unit: field.measure,
                        showUnit: field.show_measure === 1,
                        multiple: field.multiple === 1,
                        options: field.biomeasure_exam_field_option.map(option => {
                            return {
                                id: option.option,
                                value: option.option
                            }
                        })
                    }
                }))
            });
        } else {
            
            getAllProfilesNotPaginate(localStorage.getItem('token')).then(response => {
                let profiles = response.profiles.map((profile, index) => {
                    return {
                        id: profile.id,
                        description: profile.description,
                        checked: false
                    }
                });

                setConfig({
                    name: null,
                    viewHistory: false,
                    profiles
                })                
            });   
        }
    }, [props.biomedida])
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };  

    return (
        <>
            <Container className={classes.container}>
                <Header config={ config } 
                        campos={ campos } 
                        id={ props.biomedida ? props.biomedida.id : null }
                        biomedida={ props.biomedida }
                        biomedidas={ props.biomedidas }
                        onError={ (errorConfig, errorCampos) => setError({errorConfig, errorCampos}) }
                        back={e => props.listar()} />

                <Grid container spacing={3} alignItems="center" style={{ height: "100%"}}>
                    <Grid item md={6} className={classes.gridSection}>
                        <AppBar className={ classes.paciente__tabs } color="default" position="static" elevation={0}>
                            <Tabs
                                classes={{
                                    flexContainer: classes.MuiTabsflexContainer
                                }}
                                value={ value }
                                onChange={ handleChange }
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab
                                    classes={{
                                        root: classes.MuiTabroot, 
                                        textColorInherit: classes.MuiTabtextColorInherit,
                                        selected: classes.Muiselected
                                    }}
                                    label="Configurações"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        root: classes.MuiTabroot, 
                                        textColorInherit: classes.MuiTabtextColorInherit,
                                        selected: classes.Muiselected
                                    }}
                                    label="Campos"  
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} className={ classes.box }>
                            <Configuracao 
                                error={ error.errorConfig } 
                                config={ config } 
                                onChange={ (name, viewHistory, profiles) => {                                    
                                    setConfig({name, viewHistory, profiles})
                                } } />
                        </TabPanel>
                        <TabPanel value={value} index={1} className={ classes.box }>
                            <NovoCampo 
                                error={ error.errorCampos }
                                campos={ campos } 
                                onChange={ campos => setCampos(campos) } />                            
                        </TabPanel>
                    </Grid>

                    <Grid item md={6} className={classes.gridSection}>
                        <h4 className="text-primary" style={{ fontSize: 16, margin: "16px 0 14px 0" }}>Visualização</h4>
                        { campos.length > 0 ?
                            <div className={ classes.box }>
                                <PreviewCampo campos={campos} />                            
                            </div>
                            :
                            <div className={ classes.empty }>
                                <BarChartRoundedIcon className={classes.barCharRound} />
                                Para visualizar a medição clínica,<br />adicione pelo menos um campo
                            </div>
                        }
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

BiomedidasCreate.defaultProps = {
    biomedida: null,
    biomedidas: []
}

export default BiomedidasCreate;