import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Card from "./components/Card";

const useStyles = makeStyles({});

function Attendance(props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      {props.attendances &&
        props.attendances.map((item, index) => {
          return (
            <Grid item md={6} key={index}>
              <Card
                attendance={item}
                identificador={index + 1}
                openAttendance={(e) => props.openAttendance(item)}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default Attendance;
