import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import Logo from "../../../../../assets/img/LogoTelemed.png";
import {Button, Grid, withStyles} from "@material-ui/core";
import {
    BarChart, 
    Build, 
    CalendarTodayRounded, 
    Help, 
    Person, 
    VerifiedUser, 
    LocalHospital, 
    PersonRounded,
    CreditCard,
    CalendarToday as Calendar
} from "@material-ui/icons";
import {CogIcon, DoctorIcon, LogoutIcon} from "../../../../shared/Icon/Icon";
import {Redirect, useHistory} from "react-router-dom";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: 225
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMenuDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: 72
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    active: {
        background: 'rgba(255, 255, 255, 0.16) !important',
        borderBottomColor: '#FFF !important'
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        height: '100%',
        color: '#FFF',
        backgroundColor: 'transparent',
        border: 'solid 2px transparent',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.16);',
            boxShadow: 'none',
            borderBottomColor: '#FFF'
        },
    },
}))(Button);

export default function Navbar(props) {
    const classes = useStyles();
    const [redirect, setRedirect] = React.useState(0);

    return (
        <div className={classes.grow} id='navbar-visualizador'>
            <AppBar position="static" classes={{root: 'bg-primary'}}>
                <Toolbar>

                    <Typography className={classes.title} variant="h6" noWrap>
                        <img src={Logo} alt="Telemed 24h" width={160} />
                    </Typography>
                    <div className={classes.grow} />
                    <Grid className={`${classes.sectionMenuDesktop} nav-items`} alignItems="center">
                        <Typography >
                            Resultado de exames anteriores
                        </Typography>

                        <Typography>
                            <Person /> &nbsp;&nbsp;
                            Sara Dias Pinheiro
                        </Typography>
                        <Typography>
                            <CreditCard /> &nbsp;&nbsp;
                                CNS: 123321-1
                        </Typography>
                        <Typography>
                            <Calendar /> &nbsp;&nbsp;
                            Data: 08/10/2021
                        </Typography>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}
