import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { getAllUser, getProfissionalByFilter } from "../api/user";

export const SideBarFilterProfessionalContext = createContext({});

export function SideBarFilterProfessionalProvider({ children }) {
  const [waiting, setWaiting] = useState();
  const [released, setReleased] = useState();
  const [blocked, setBlocked] = useState();
  const [all, setAll] = useState();
  const [name, setName] = useState("");
  const [cpf, setCPF] = useState("");
  const [loadingProfissionais, setLoadingProfissionais] = useState(false);
  const [profissionais, setProfissionais] = useState([]);
  const [profissionaisListForFilter, setProfissionaisListForFilter] = useState([]);
  const [totaisStatus, setTotaisStatus] = useState([
    { active: 1, total: 0 },
    { active: 2, total: 0 },
    { active: 3, total: 0 },
    { active: 4, total: 0 }
  ]);
  const [profissionaisPaginate, setProfissionaisPaginate] = useState(null);
  const [activeFilter, setActiveFilter] = useState({
    active: '',
    cpf: '',
    cns: null,
    name: '',
    profile_id: '',
    medical_unit_id: ''
  });

  useEffect(() => {
    try {
      getProfessionalsByFilter();
    }
    catch (error) {
      console.log(error);
    }
  }, []);

  async function getProfessionalsByFilter() {
    await getProfissionalByFilter(localStorage.getItem("token"),[all || waiting, released, blocked],
      activeFilter.cpf,
      activeFilter.cns,
      activeFilter.name,
      activeFilter.profile_id,
      activeFilter.medical_unit_id
    ).then((res) => {
      setProfissionais(res.users);
      setProfissionaisPaginate(res.paginate)
    });
  }

  return (
    <SideBarFilterProfessionalContext.Provider
      value={{
        profissionais,
        setProfissionais,
        all,
        waiting,
        released,
        blocked,
        setWaiting,
        setReleased,
        setBlocked,
        setAll,
        setName,
        setCPF,
        cpf,
        name,
        profissionaisListForFilter,
        getProfessionalsByFilter,
        loadingProfissionais,
        setLoadingProfissionais,
        totaisStatus, 
        setTotaisStatus,
        activeFilter, 
        setActiveFilter,
        profissionaisPaginate, 
        setProfissionaisPaginate
      }}
    >
      {children}
    </SideBarFilterProfessionalContext.Provider>
  );
}

export function useFilterProfessional() {
  const context = useContext(SideBarFilterProfessionalContext);

  return context;
}
