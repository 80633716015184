import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import {
    Checkbox,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    Grid,
    withStyles
} from "@material-ui/core";
import {CheckCircleOutline, Close} from "@material-ui/icons";
import StepLine from "./components/Step";
import DRadio from "../../../shared/DRadio";
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMore from "@material-ui/icons/ExpandMore";
import DInput from "../../../shared/DInput";
import Tooltip from '@material-ui/core/Tooltip';
import establishmentTypeJson from './../../../../assets/dados/establishmentType.json';
import logradouroCode from "./../../../../assets/dados/logradouro.json";
import DAutoComplete from "../../../shared/DAutoComplete";
import {getAllUnidades, getDominios, validateCNPJ, checkCnpj} from "../../../../api/unidades";
import {getCep, getCep2} from "../../../../helpers/apis";
import DButton from "../../../shared/DButton";
import DDropdown from "../../../shared/DDropdown";
import DDropDownSimple from "../../../shared/DDropDownSimple";
import useError from "../../../../hooks/useError";
import {required, validarCNPJ} from "../../../../helpers/validacoes";
import CircularProgress from "@material-ui/core/CircularProgress";
import {mascaraCNPJ, mascaraNumero, mascaraCEP, nameRegex} from "../../../../helpers/formatacao";
import {validaEmail} from "../../../../helpers/validacoes";
import { toast } from "react-toastify";

const useStyles = makeStyles({
    rootHide: {
        display: 'none'
    },
    title: {
        borderBottom: 'solid 1px #C7C7C7'
    },
    content: {
        width: 545,
        height: 450
    },
    footer: {
        borderTop: 'solid 1px #C7C7C7',
        paddingTop: 16,
        paddingBottom: 16
    },
    icon: {
        color: '#949494'
    },
    formLabel: {
        color: '#272727',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px'
    },
    actionButton: {
        padding: "16px!important"
    }
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
        fontSize: 20,
        marginTop: 3
    },
    arrow: {
        color: '#FFF'
    }
}))(Tooltip);

function SimpleDialog(props) {
    
    const classes = useStyles();
    
    const { onClose, open } = props;
    const [step, setStep] = useState(0);
    
    const [typeMedicalUnit, setTypeMedicalUnit] = useState('Assistencial');
    const [cnes, setCnes] = useState('');
    const [labelCnes, setLabelCnes] = useState('CNES');
    const [cnpj, setCNPJ] = useState('');
    const [name, setName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [establishmentType, setEstablishmentType] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [cep, setCEP] = useState('');
    const [addressType, setAddressType] = useState('');
    const [addressTypeName, setAddressTypeName] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [cityId, setCityId] = useState('');
    const [state, setState] = useState('');
    const [stateId, setStateId] = useState('');
    const [complement, setComplement] = useState('');
    const [belowMotherMedicalUnit, setBelowMotherMedicalUnit] = useState('Não');
    const [motherMedicalUnit, setMotherMedicalUnit] = useState('');
    const [funcao, setFuncao] = useState('Executante');
    const [vinculoOrganizacaoSocial, setVinculOrganizacaoSocial] = useState("0");
    const [executingMedicalUnits, setExecutingMedicalUnits] = useState([]);
    const [administrativeMedicalUnits, setAdministrativeMedicalUnits] = useState(props.administrativeMedicalUnits);
    const [socialMedicalUnit, setSocialMedicalUnit] = useState('');
    const [profiles, setProfiles] = useState([]);
    const [anamneses, setAnamneses] = useState([]);
    const [formBlock, setFormBlock] = useState(true);
    
    useEffect(function(){
        
        return function cleanUp() {
            setNewMedicalUnit()
        }
    }, [])
    
    useEffect(getFullAddressByCep, [cep])
    
    function validaCnesCnpj(cnes, cnpj) {
        
        if (cnes !== '' && cnpj !== '') {
            
            if (typeMedicalUnit !== "Administrativa") {
                
                validateCNPJ(localStorage.getItem('token'), cnpj).then(res => {
                    if(!res.status){
                        setFormBlock(true)
                        delete error.cnes
                        setErrors({...error, cnpj: 'CNPJ inválido'})

                        if(document.getElementById("cnpj")) {
                            document.getElementById("cnpj").scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest"
                            })
                        }
                    }else{
                        delete error.cnes
                        delete error.cnpj
                        setFormBlock(false)
                    }
                });
                
            } else {
                
                //Para unidades administrativas, CNES opcional

                validateCNPJ(localStorage.getItem('token'), cnpj).then(res => {
                    if(!res.status){
                        setFormBlock(true)
                        delete error.cnes
                        setErrors({...error, cnpj: 'CNPJ inválido'})
                    }else{
                        delete error.cnes
                        delete error.cnpj
                        setFormBlock(false)
                    }
                })
                
            }
            
        } else {
            console.log("typeMedicalUnit", typeMedicalUnit);
            if (typeMedicalUnit !== "Administrativa")
            setFormBlock(true)
        }
    }

    const nacionalidades = [
        { id: 1, value: 'Brasileiro' },
        { id: 3, value: 'Estrangeiro' },
        { id: 2, value: 'Naturalizado' }
    ];

    const getErrorMessage = (type) => {
        var drinks = {
            address: "Endereço é obrigatório",
            cep: "CEP é obrigatório",
            city: "Cidade obrigatório",
            cnpj: "CNPJ é obrigatório",
            district: "Bairro é obrigatório",
            email: "E-mail é obrigatório",
            name: "Nome é obrigatório",
            number: "Número é obrigatório",
            phone: "Telefone é obrigatório",
            state: "Estado é obrigatório",
            profiles: "Ao menos um Perfil é obrigatório",
            anamneses: "Ao menos um formulário de anamnese é obrigatório",
            default: ""            
        };
        return (drinks[type] || drinks['default']);
    }
    
    const [error, validarFormulario, setErrors] = useError({
        typeMedicalUnit: required,
        // cnes: (cnes) => {
        //     if(establishmentType !== "Escola" && typeMedicalUnit !== "Administrativa") return required(cnes);
        // },
        cnpj: required,
        name: required,
        businessName: (businessName) => {
            if(typeMedicalUnit === "Administrativa") return required(businessName);
        },
        companyName: required,
        establishmentType: (establishmentType) => {
            if(typeMedicalUnit === "Assistencial") return required(establishmentType);
        },
        phone: required,
        email: required,
        cep: required,
        addressType: required,
        address: required,
        number: required,
        district: required,
        city: required,
        state: required,
        belowMotherMedicalUnit: required,
        motherMedicalUnit: required,
        funcao: required,
        executingMedicalUnits: required,
        profiles: required,
        anamneses: required,
        socialMedicalUnit: (socialMedicalUnit) => { 
            if (vinculoOrganizacaoSocial == "1") return required(socialMedicalUnit);
         }
    });
    
    useEffect(() => {
        try {
            console.log('error', error);
            if (Object.keys(error).length && error.cnes != null) {
                const elementByID = document.getElementById(Object.keys(error)[0]);
                if(elementByID){
                    document.getElementById(Object.keys(error)[0]).scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest"
                    });
                }

                const initValue = <p>Não foi possível cadastrar a unidade. Verifique os campos obrigatórios</p>;
                const msg = Object.entries(error)?.reduce((m, err) => {
                    return (
                        <>                        
                            {m}
                            <p style={{margin:0}}>{ getErrorMessage(err[0]) }</p>
                        </>
                    )
                }, initValue);

                toast.error(msg, { timeout: 1000000000 });
            }
            
        } catch(err) {
            console.log(err, 'erro linha 406')    
        }
    }, [error]);
    
    function setNewMedicalUnit()
    {
        setStep(0);
        setTypeMedicalUnit('Assistencial');
        setCnes('');
        setCNPJ('');
        setName('');
        setBusinessName('');
        setCompanyName('');
        setEstablishmentType('');
        setPhone('');
        setEmail('');
        setCEP('');
        setAddressType('');
        setAddressTypeName('');
        setAddress('');
        setNumber('');
        setDistrict('');
        setCity('');
        setState('');
        setComplement('');
        setBelowMotherMedicalUnit('Não');
        setMotherMedicalUnit('');
        setFuncao('Executante');
        setExecutingMedicalUnits([]);
        setProfiles([]);
        setAnamneses([]);
    }
    
    function handleExecutingMedicalUnits(e) {
        
        delete error.executingMedicalUnits
        
        if(e.indexOf('reset') > -1) {
            
            setExecutingMedicalUnits([]);
        } else if(e.indexOf('todos') > -1) {
            
            setExecutingMedicalUnits(['todos', ...props.executingMedicalUnits]);
        } else {
            
            setExecutingMedicalUnits(e);
        }
    }
    
    function handleProfiles(e) {
        
        if(e.target.checked) {
            setProfiles([...profiles, e.target.value])
        } else {
            var old_profiles = [...profiles];
            old_profiles.splice(profiles.indexOf(e.target.value), 1);
            setProfiles(old_profiles);
        }
    }
    
    function handleAnamneses(e) {
        
        if(e.target.checked) {
            setAnamneses([...anamneses, e.target.value])
        } else {
            var old_anamneses = [...anamneses];
            old_anamneses.splice(anamneses.indexOf(e.target.value), 1);
            setAnamneses(old_anamneses);
        }
    }
    
    function getDados() {
        var dados = {
            typeMedicalUnit,
            cnes,
            cnpj,
            name,
            businessName,
            companyName,
            establishmentType,
            phone,
            email,
            cep,
            addressType,
            address,
            number,
            district,
            city,
            cityId,
            state,
            stateId,
            complement,
            belowMotherMedicalUnit,
            motherMedicalUnit,
            funcao,
            executingMedicalUnits: executingMedicalUnits.filter(e => e !== 'todos'),
            profiles,
            anamneses,
            vinculoOrganizacaoSocial,
            socialMedicalUnit
        }
        
        dados.cnpj = dados.cnpj.toString().replace(/[^0-9]/g, '')
        dados.cep = dados.cep.toString().replace(/[^0-9]/g, '')
        dados.phone = dados.phone.toString().replace(/[^0-9]/g, '')
        
        if (step === 0) {
            dados.profiles = 'null'
            dados.anamneses = 'null'
        }
        
        if (typeMedicalUnit === 'Assistencial') {
            
            //dados.businessName = 'null'
            dados.businessName = null;
            dados.companyName = 'null'
        } else {
            
            dados.funcao = 'null'
            dados.name = 'null'
            //dados.establishmentType = 'null'
            dados.establishmentType = null;
        }
       
        if (vinculoOrganizacaoSocial === '0') {
            dados.socialMedicalUnit = null;
        }

        if (belowMotherMedicalUnit === 'Não') {
            dados.motherMedicalUnit = 'null'
        }
        
        if (funcao === 'Executante') {
            dados.executingMedicalUnits = 'null'
        }
        
        if (dados.typeMedicalUnit === 'Administrativa'){
            dados.anamneses = 'null'
        }

        
        return dados
    }
    
    function removeAccents(text) {
        const accentsMap = new Map([
            ["A", "Á|À|Ã|Â|Ä"],
            ["a", "á|à|ã|â|ä"],
            ["E", "É|È|Ê|Ë"],
            ["e", "é|è|ê|ë"],
            ["I", "Í|Ì|Î|Ï"],
            ["i", "í|ì|î|ï"],
            ["O", "Ó|Ò|Ô|Õ|Ö"],
            ["o", "ó|ò|ô|õ|ö"],
            ["U", "Ú|Ù|Û|Ü"],
            ["u", "ú|ù|û|ü"],
            ["C", "Ç"],
            ["c", "ç"],
            ["N", "Ñ"],
            ["n", "ñ"]
        ]);
        
        const reducer = (acc, [key]) => acc.replace(new RegExp(accentsMap.get(key), "g"), key);
        
        return [...accentsMap].reduce(reducer, text);
    }
    
    function getFullAddressByCep() {
        try {
            
            if(cep.length >= 10) {

                getCep2(cep)
                    .then(data => {
                        if(data.status) {

                            delete error.address
                            delete error.district
                            delete error.city
                            delete error.state
                            
                            var citySelec  = props.citys.find( city => city.description == removeAccents(data.localidade.toUpperCase()));
                            var stateSelec = props.states.find( city => city.code == data.estado_sigla);
                            let logradouro = logradouroCode.find(res => res.value === data.logradouro_tipo);

                            setAddressType(logradouro ? logradouro.id : null);
                            setAddressTypeName(logradouro ? logradouro.value : null);
                            
                            setDistrict(data.bairro);
                            setAddress(data.logradouro);
                            setState(data.estado_sigla);
                            
                            if (citySelec.id) {
                                setCity(data.localidade);
                                setCityId(citySelec.id);
                            }
                            
                            if (stateSelec.id) {
                                setState(data.estado_sigla);
                                setStateId(stateSelec.id);
                            }

                        } else {
                            setDistrict('');
                            setAddress('');
                            setState('');
                            setStateId('');
                            setCity('');
                            setCityId('');
                            toast.error(data.message);
                        }
                    })
                    .catch(err => {
                        toast.error(err);
                    })
                
                // getCep(cep).then(res => {
                    
                //     if (res.erro) {
                //         setDistrict('');
                //         setAddress('');
                //         setState('');
                //         setStateId('');
                //         setCity('');
                //         setCityId('');
                        
                //         var new_erro = {};
                //         new_erro.cep = 'CEP inexistente';
                //         setErrors(new_erro);
                        
                //     } else {
                        
                //         delete error.address
                //         delete error.district
                //         delete error.city
                //         delete error.state
                        
                //         var citySelec  = props.citys.find( city => city.description == removeAccents(res.localidade.toUpperCase()))
                //         var stateSelec = props.states.find( city => city.code == res.uf)
                        
                //         setDistrict(res.bairro);
                //         setAddress(res.logradouro);
                //         setState(res.uf);
                        
                //         if (citySelec.id) {
                //             setCity(res.localidade);
                //             setCityId(citySelec.id);
                //         }
                        
                //         if (stateSelec.id) {
                //             setState(res.uf);
                //             setStateId(stateSelec.id);
                //         }
                //     }
                // })
                // .catch(err => console.error(err))
            }
            
        } catch (err) {
            setDistrict('');
            setAddress('');
            setState('');
            setStateId('');
            setCity('');
            setCityId('');
            
            var new_erro = {};
            new_erro.cep = 'CEP inexistente';
            setErrors(new_erro);
            
        }
    }
    
    function selectSingleOrganizacaoSocial()
    {

        return <>
            <Grid item md={12}>
                    <DDropDownSimple
                        id="nacionalidade"
                        label="Selecionar a organização social vinculada"
                        placeholder="Selecionar a organização social vinculada"
                        fullWidth
                        error={ error.socialMedicalUnit }
                        helperText={ error.socialMedicalUnit }
                        value={ socialMedicalUnit }
                        options={administrativeMedicalUnits.map(function (item) {
                            return { id: item.id, value: item.company_name };
                        })}
                        onChange={(event) => {
                            delete error.socialMedicalUnit
                            setSocialMedicalUnit(event)
                        }}                            
                    />
            </Grid>
           
        </>

    }

    function DialogCreated(props) {
        
        return (
            <Dialog aria-labelledby="medical-unity-create-title" scroll="body" disableBackdropClick={true} open={ props.open }>
            <DialogTitle id="medical-unity-create-title" classes={{ root: classes.title }}>
            <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
            <Typography variant="h2" className="text-mt text-regular text-primary">Cadastrar unidade</Typography>
            </Grid>
            <Grid item>
            <Close onClick={ e => props.onClose(true) } />
            </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent classes={{root: classes.content}}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: 400 }}>
            <Grid item className="text-center">
            <CheckCircleOutline className="text-success" style={{fontSize: 64}} />
            <Typography variant="h3" className="text-success">Cadastro da unidade</Typography>
            <Typography variant="h3" className="text-success">Realizado com sucesso.</Typography>
            <Typography className="text-mt text-regular mt-3">{ props.name }</Typography>
            </Grid>
            </Grid>
            </DialogContent>
            <DialogActions classes={{root: classes.footer}}>
            <Grid container spacing={1} className="tema-light" justifyContent="flex-end">
            <Grid item>
            <DButton type="button" variant="secondary" size="sm" onClick={ e => {
                props.onClose && props.onClose(true)
            } }>
            Voltar para configurações
            </DButton>
            </Grid>
            <Grid item>
            <DButton type="button" variant="primary" size="sm" onClick={ e => {
                setNewMedicalUnit()
                props.onNewMedicalUnit(true)
            } }>
            Adicionar mais uma unidade
            </DButton>
            </Grid>
            </Grid>
            </DialogActions>
            </Dialog>
            )
        }
        
        return (
            <>
            { props.created
                ?
                <DialogCreated name={ name ? name : companyName } open={ true } onClose={e => props.onClose(true)} disableBackdropClick={true} onNewMedicalUnit={ e => props.onNewMedicalUnit(true) } />
                :
                <Dialog aria-labelledby="medical-unity-create-title" scroll="body" disableBackdropClick={true} open={open}>
                <form autoComplete="off" onSubmit={ async form => {
                    
                    form.preventDefault();                    
                    var dados = getDados()                    
                    props.setErrors(setErrors);
                    
                    if(step === 0) {

                        if(validarFormulario({ ...dados })) {

                            setFormBlock(false)
                            setStep(1)
                            
                            // checkCnpj(localStorage.getItem('token'), cnpj).then(res => {
                            //     if(!res.status){
                            //         setFormBlock(true)
                            //         setErrors({...error, cnpj: 'CNPJ já cadastrado.'});
                            //     }else{
                            //         if(validarCNPJ(cnpj)){
                            //             delete error.cnpj
    
                            //             setFormBlock(false)
                            //             setStep(1)
                            //         } else {
                            //             setFormBlock(true)
                            //             setErrors({...error, cnpj: 'CNPJ inválido.'});
                            //         }

                            //     }
                            // })
                        }

                    } else if(step === 1) {

                        if (validarFormulario({ ...dados })) {
                            props.novaUnidade(dados);
                        }
                    }
                }}>
                <DialogTitle id="medical-unity-create-title" classes={{ root: classes.title }}>
                <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                <Typography variant="h2" className="text-mt text-regular text-primary">Cadastrar unidade</Typography>
                </Grid>
                <Grid item>
                <Close onClick={ e => props.onClose(true) } />
                </Grid>
                </Grid>
                </DialogTitle>
                <DialogContent classes={{root: classes.content}}>
                <Grid container spacing={3} justifyContent="center" className="tema-light">
                <Grid item md={12}>
                <StepLine step={ step } />
                </Grid>
                <Grid item md={12}>
                <hr />
                </Grid>
                { step === 0 || props.error ?
                    <>
                    <Grid item md={12}>
                    <Typography className="text-primary" variant="h6">Tipo de unidade</Typography>
                    </Grid>
                    <Grid item md={12}>
                    <DRadio inline
                    name="type_medical_unit"
                    onChange={ e => {
                        
                        setTypeMedicalUnit(e.target.value) 
                        
                        if (e.target.value == 'Administrativa') { 
                            setLabelCnes("CNES (opcional)") 
                            setErrors({...error, cnes: null});
                            setFormBlock(false);
                        } else {
                            setLabelCnes("CNES")
                        }
                        
                    }}
                    value={ typeMedicalUnit }
                    labelClass={ classes.formLabel }
                    withoutBold
                    options={[
                        { value: 'Assistencial', label: 'Assistencial' },
                        { value: 'Administrativa', label: 'Administrativa' }
                    ]} />
                    </Grid>
                    <Grid item md={12}>
                    <hr />
                    </Grid>
                    <Grid item md={12}>
                    <Typography className="text-primary" variant="h6">Dados da unidade</Typography>
                    </Grid>
                    <Grid item md={6}>
                    <Grid container justifyContent="space-between" alignItems="flex-end">
                    <Grid item md={10}>
                    <DInput
                    id="cnpj"
                    label="CNPJ"
                    placeholder="CNPJ"
                    error={ error.cnpj }
                    helperText={ error.cnpj }
                    positionIcon="end"
                    type="text"
                    fullWidth={true}
                    onChange={ e => {
                        delete error.cnpj
                        setCNPJ(mascaraCNPJ(e.target.value))
                        if (typeMedicalUnit == "Administrativa" || (typeMedicalUnit == "Assistencial" && cnes != ""))
                            setFormBlock(false)
                    } }
                    onBlur={ e => {
                        validaCnesCnpj(cnes, e.target.value)
                    }}
                    value={ cnpj } />
                    </Grid>
                    <Grid item md={2}>
                    {
                        !error.cnpj && (
                            <LightTooltip title={
                                <Typography variant="body1" className="text-regular text-st">Cadastro Nacional de<br />Pessoa Jurídica</Typography>
                            } placement="right" arrow>
                            
                            <ErrorIcon color="primary" classes={{ colorPrimary: classes.icon }} />
                            </LightTooltip>
                            )
                        }
                        </Grid>
                        </Grid>
                        </Grid>
                        <Grid item md={6}>
                        <Grid container justifyContent="space-between" alignItems="flex-end">
                        <Grid item md={10}>
                        <DInput
                        id="cnes"
                        label={ labelCnes } 
                        placeholder="CNES"
                        type="text"
                        inputProps={{maxLength: 7}}
                        error={ error.cnes }
                        helperText={ error.cnes }
                        positionIcon="end"
                        fullWidth={true}
                        onChange={ e => {
                            delete error.cnes
                            
                            var _value = e.target.value.replace(/\D/g, '') 
                            
                            setCnes(_value)
                        } }
                        onBlur={ e => {
                            validaCnesCnpj(e.target.value, cnpj)
                        }}
                        value={ cnes } />    
                        </Grid>
                        <Grid item md={2}>
                        {
                            !error.cnes && (
                                <LightTooltip title={
                                    <Typography variant="body1" className="text-regular text-st">Cadastro Nacional de<br /> Estabelecimentos de Saúde</Typography>
                                } placement="right" arrow>
                                <ErrorIcon color="primary" classes={{ colorPrimary: classes.icon }} />
                                </LightTooltip>
                                )
                            }
                            </Grid>
                            </Grid>
                            </Grid>
                            { typeMedicalUnit === 'Assistencial' ?
                            <>
                            <Grid item md={12}>
                            <DInput
                            id="name"
                            label="Nome"
                            placeholder="Nome"
                            type="text"
                            error={ error.name }
                            helperText={ error.name }
                            positionIcon="end"
                            fullWidth={true}
                            inputProps={{maxLength: 100}}
                            onChange={ e => {
                                delete error.name
                                
                                var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")
                                
                                setName(_value);
                                
                                // setName(e.target.value)
                            } }
                            value={ name } />
                            </Grid>
                            <Grid item md={12}>
                            <DAutoComplete
                            id="establishmentType"
                            label="Tipo de estabelecimento"
                            placeholder="Tipo de estabelecimento"
                            fullWidth
                            error={ error.establishmentType }
                            helperText={ error.establishmentType }
                            options={ establishmentTypeJson }
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) => value && option.id === value.id}
                            onChange={(event, newValue) => {
                                delete error.establishmentType
                                
                                setEstablishmentType(newValue ? newValue.id : '')
                                
                                if (newValue && newValue.value === "Escola") { 
                                    setLabelCnes("CNES (opcional)") 
                                } else {
                                    setLabelCnes("CNES")
                                }
                                
                                if(newValue && newValue.value === "Escola"){ // Remove erro de cnes & desbloqueia o form.
                                    setErrors({...error, cnes: null});
                                    setFormBlock(false);
                                }
                            }}
                            />
                            </Grid>
                            </> :
                            <>
                            <Grid item md={12}>
                            <DInput
                            id="businessName"
                            label="Nome empresarial"
                            placeholder="Nome empresarial"
                            positionIcon="end"
                            type="text"
                            inputProps={{ maxLength: 100 }}
                            error={ error.businessName }
                            helperText={ error.businessName }
                            fullWidth={true}
                            onChange={ e => {
                                delete error.businessName
                                setBusinessName(e.target.value)
                            } }
                            value={ businessName } />
                            </Grid>
                            <Grid item md={12}>
                            <DInput
                            id="companyName"
                            label="Nome fantasia"
                            placeholder="Nome fantasia"
                            positionIcon="end"
                            type="text"
                            error={ error.companyName }
                            inputProps={{ maxLength: 100 }}
                            helperText={ error.companyName }
                            fullWidth
                            onChange={ e => {
                                delete error.companyName
                                setCompanyName(e.target.value)
                            } }
                            value={ companyName } />
                            </Grid>
                            </>
                        }
                        <Grid item md={12}>
                        <DInput
                        id="phone"
                        label="Telefone"
                        placeholder="Telefone"
                        positionIcon="end"
                        type="text"
                        error={ error.phone }
                        helperText={ error.phone }
                        fullWidth
                        onChange={ e => {
                            delete error.phone
                            setPhone(mascaraNumero(e.target.value))
                        } }
                        value={ phone } />
                        </Grid>
                        <Grid item md={12}>
                        <DInput
                        id="email"
                        label="E-mail"
                        placeholder="E-mail"
                        type="email"
                        error={ error.email }
                        helperText={ error.email }
                        positionIcon="end"
                        fullWidth
                        inputProps={{maxLength: 254}}
                        onChange={ e => {
                            delete error.email
                            
                            var _value = e.target.value.replace(/[à-úÀ-Ú"'´`~ ]/g, "")
                            
                            setEmail(_value)
                            
                        } }
                        onBlur={ e => {
                            if(validaEmail(e.target.value)){
                                setErrors({...error, email: "E-mail inválido."})
                            }
                        }}
                        value={ email } />
                        </Grid>
                        <Grid item md={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item md={7}>
                        <DInput
                        id="CEP"
                        label="CEP"
                        placeholder="CEP"
                        type="text"
                        error={ error.cep }
                        helperText={ error.cep }
                        positionIcon="end"
                        fullWidth
                        onChange={ e => {
                            delete error.cep
                            setCEP(mascaraCEP(e.target.value))
                            getFullAddressByCep(e.target.value)
                            
                        } }
                        value={ cep } />
                        </Grid>
                        <Grid item md={5}>
                        <Grid container justifyContent="flex-end">
                        <Grid item>
                        <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" className="text-mt text-regular text-link">Não sei o CEP</a>
                        </Grid>
                        </Grid>
                        </Grid>
                        </Grid>
                        </Grid>
                        <Grid item md={4}>
                        <DAutoComplete
                            id="addressType"
                            label="Logradouro"
                            placeholder="Logradouro"
                            fullWidth
                            error={ error.addressType }
                            helperText={ error.addressType }
                            options={ logradouroCode }
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) => value && option.id === value.id}
                            value={ logradouroCode.find(item => item.id == addressType) }
                            inputValue={ addressTypeName }
                            onInputChange={(event, newInputValue) => {
                                setAddressTypeName(newInputValue);
                            }}
                            onChange={(event, newValue) => {
                                delete error.addressType
                                setAddressType(newValue ? newValue.id : '')
                            }}
                        />
                        </Grid>
                        <Grid item md={8}>
                        <DInput
                        id="address"
                        label="Endereço"
                        placeholder="Endereço"
                        type="text"
                        error={ error.address }
                        helperText={ error.address }
                        positionIcon="end"
                        fullWidth
                        inputProps={{maxLength: 60}}
                        onChange={ e => {
                            delete error.address
                            
                            var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")
                            
                            setAddress(_value)
                        } }
                        value={ address } />
                        </Grid>
                        <Grid item md={4}>
                        <DInput
                        id="number"
                        label="Número"
                        placeholder="Número"
                        type="text"
                        error={ error.number }
                        helperText={ error.number }
                        positionIcon="end"
                        fullWidth
                        inputProps={{maxLength: 8}}
                        onChange={ e => {
                            delete error.number
                            
                            var _value = e.target.value.replace(/[^a-zA-Z0-9à-úÀ-Ú"' ]/g, "")
                            
                            setNumber(_value);
                            
                            // setNumber(_value)
                        } }
                        value={ number } />
                        </Grid>
                        <Grid item md={8}>
                        <DInput
                        id="complement"
                        label="Complemento (opcional)"
                        placeholder="Complemento"
                        type="text"
                        error={ error.complement }
                        helperText={ error.complement }
                        positionIcon="end"
                        fullWidth
                        inputProps={{maxLength: 10}}
                        onChange={ e => {
                            delete error.complement
                            
                            var _value = e.target.value.replace(/[^a-zA-Z0-9à-úÀ-Ú"' ]/g, "")
                            
                            setComplement(_value)
                        } }
                        value={ complement } />
                        </Grid>
                        <Grid item md={12}>
                        <DInput
                        id="district"
                        label="Bairro"
                        placeholder="Bairro"
                        type="text"
                        error={ error.district }
                        helperText={ error.district }
                        positionIcon="end"
                        fullWidth
                        inputProps={{maxLength: 30}}
                        onChange={ e => {
                            delete error.district
                            
                            var _value = e.target.value.replace(/[^a-zA-Z0-9à-úÀ-Ú"' ]/g, "")
                            
                            setDistrict(_value);
                            
                            
                        } }
                        value={ district } />
                        </Grid>
                        <Grid item md={6}>
                        
                        <DInput
                        id="state"
                        label="Estado"
                        placeholder="Estado"
                        labelColor='black'
                        type="text"
                        disabled={true}
                        // error={ error.state }
                        // helperText={ error.state }
                        fullWidth={true}
                        onChange={ e => {
                            delete error.state
                            setState(e.target.value)
                        } }
                        value={ state }
                        positionIcon="end"
                        // icon={ <ExpandMore /> }
                        />
                        
                        </Grid>
                        <Grid item md={6}>
                        <DInput
                        id="city"
                        label="Municipio"
                        placeholder="Municipio"
                        labelColor='black'
                        type="text"
                        disabled={true}
                        // error={ error.city }
                        // helperText={ error.city }
                        fullWidth={true}
                        onChange={ e => {
                            delete error.city
                            setCity(e.target.value)
                        } }
                        value={ city }
                        positionIcon="end"
                        // icon={ <ExpandMore /> }
                        />
                        </Grid>
                        <Grid item md={12}>
                        <hr />
                        </Grid>
                        <Grid item md={12}>
                        <Typography className="text-primary" variant="h6">Vínculos da unidade</Typography>
                        </Grid>
                        <Grid item md={12}>
                        <Grid container justifyContent="space-between" alignItems="flex-start">
                        <Grid item md={8}>
                        <DRadio inline
                        label="Esta unidade está abaixo de uma unidade mãe?"
                        name="below_mother_medical_unit"
                        onChange={ e => setBelowMotherMedicalUnit(e.target.value) }
                        value={ belowMotherMedicalUnit }
                        options={[
                            { value: 'Não', label: 'Não' },
                            { value: 'Sim', label: 'Sim', disabled: props.medicalUnits.length < 1 }
                        ]} />
                        </Grid>
                        <Grid item md={4}>
                        <LightTooltip title={
                            <Typography variant="body1" className="text-regular text-st">A unidade mãe é a unidade<br />responsável </Typography>
                        } placement="right" arrow>
                        <ErrorIcon color="primary" classes={{ colorPrimary: classes.icon }} />
                        </LightTooltip>
                        </Grid>
                        </Grid>
                        </Grid>
                        { belowMotherMedicalUnit === 'Sim' &&
                        <Grid item md={12}>
                        <DAutoComplete
                        id="mother-medical-unit"
                        label="Unidade mãe"
                        placeholder="Selecione unidade mãe"
                        fullWidth
                        error={ error.motherMedicalUnit }
                        helperText={ error.motherMedicalUnit }
                        options={ props.medicalUnits.map(function(item){ return { id: item.id, value: item.company_name } }) }
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) => value && option.id === value.id}
                        onChange={(event, newValue) => {
                            delete error.motherMedicalUnit
                            setMotherMedicalUnit(newValue ? newValue.id : '')
                        }}
                        />
                        </Grid>
                    }
                    { typeMedicalUnit === 'Assistencial' &&
                    <>
                    <Grid item md={12}>
                    <DRadio inline
                    label="Qual será a função dessa unidade?"
                    name="funcao"
                    onChange={ e => setFuncao(e.target.value) }
                    value={ funcao }
                    options={[
                        { value: 'Solicitante', label: 'Solicitante', disabled: props.executingMedicalUnits.length < 1 },
                        { value: 'Executante', label: 'Executante' },
                        // { value: 'Ambas', label: 'Ambas', disabled: props.executingMedicalUnits.length < 1 }
                    ]} />
                    </Grid>
                    { funcao === 'Solicitante' || funcao === 'Ambas' ?
                    <Grid item md={12}>
                    <DDropdown id="other-mother-medical-unit"
                        label="Unidades executantes"
                        placeholder="Selecione unidades executantes"
                        onChange={ handleExecutingMedicalUnits }
                        value={ executingMedicalUnits }
                        options={ props.executingMedicalUnits.map(function(item){ console.log("item create", item); return { id: item.id, value: item.company_name } }) }
                        error={ error.executingMedicalUnits }
                        helperText={ error.executingMedicalUnits } />
                    </Grid>
                    : ''
                    }
                 </>
                }
                <Grid item md={12}>
                    <DRadio inline
                    label="Está vinculada a uma organização social?"
                    name="vinculoOrganizacaoSocial"
                    onChange={ e => setVinculOrganizacaoSocial(e.target.value) }
                    value={ vinculoOrganizacaoSocial }
                    options={[
                        { value: 0, label: 'Não'},
                        { value: 1, label: 'Sim' }
                    ]} />
                </Grid>
                { vinculoOrganizacaoSocial == 1 ? selectSingleOrganizacaoSocial() : '' }
            </>
            :
            ''
        }
        
        { step === 1 &&
            <>
            <Grid item md={12}>
            <Typography className="text-primary" variant="h6">Perfis disponíveis na unidade</Typography>
            </Grid>
            <Grid item md={12}>
            <Grid container alignItems="flex-start">
            { props.profiles.map(function(item){
                return (
                    <Grid item md={6}>
                    <FormControl component="fieldset" multiClass={ `form-control-text ${ error.profiles ? 'form-control-error' : '' }` } autoComplete="off">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={ profiles.indexOf(item.id.toString()) > -1 } onChange={ handleProfiles } value={ item.id } name="profiles[]" />}
                                label={ <Typography className={ classes.formLabel }>{ item.description }</Typography> }
                            />
                            { error.profiles && <FormHelperText>{ error.profiles }</FormHelperText> }
                        </FormGroup>
                    </FormControl>
                    </Grid>
                    )
                }) }
                </Grid>
                </Grid>
                { typeMedicalUnit === 'Assistencial' &&
                <>
                <Grid item md={12}>
                <Typography className="text-primary" variant="h6">Formulários de anamnese</Typography>
                </Grid>
                <Grid item md={12}>
                <Grid container alignItems="flex-start">
                { props.anamneseForms.map(function(item){
                    return (
                        <Grid item md={6}>
                        <FormControl component="fieldset" multiClass={ `form-control-text ${ error.anamneses ? 'form-control-error' : '' }` } autoComplete="off">
                        <FormGroup>
                        <FormControlLabel
                        control={<Checkbox checked={ anamneses.indexOf(item.id.toString()) > -1 } onChange={ handleAnamneses } value={ item.id } name="profiles[]" />}
                        label={ <Typography className={ classes.formLabel }>{ item.name }</Typography> }
                        />
                        { error.anamneses && <FormHelperText>{ error.anamneses }</FormHelperText> }
                        </FormGroup>
                        </FormControl>
                        </Grid>
                        )
                    }) }
                    </Grid>
                    </Grid>
                    </>
                }
                
                </>
            }
            </Grid>
            </DialogContent>
            <DialogActions classes={{root: classes.footer}}>
            <Grid container spacing={1} className="tema-light" justifyContent="flex-end">
            { step === 1 &&
                <Grid item>
                <DButton disabled={ props.loading } type="button" variant="secondary" size="sm" onClick={ e => setStep(0) }>
                Voltar
                </DButton>
                </Grid>
            }
            <Grid item>
            <DButton disabled={ props.loading } className={ `${ step === 1 ? 'btn-success' : '' }${classes.actionButton}` } type="submit" variant="primary" size="sm">
            { props.loading ? <CircularProgress size={ 15 } color="inherit" /> : <>{ step === 0 ? 'Avançar' : 'Finalizar cadastro'  }</> }
            </DButton>
            </Grid>
            </Grid>
            </DialogActions>
            
            </form>
            </Dialog>
        }
        </>
        );
    }
    
    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired
    };
    
    export default function UnidadesCreate(props) {
        
        const [states, setStates] = useState([])
        const [citys, setCitys] = useState([])
        const [profiles, setProfiles] = useState([]);
        const [anamneseForms, setAnamneseForms] = useState([]);
        const [medicalUnits, setMedicalUnits] = useState([]);
        const [executingMedicalUnits, setExecutingMedicalUnits] = useState([]);
        const [administrativeMedicalUnits, setAdministrativeMedicalUnits] = useState([]);
        
        useEffect(() => {
            setMedicalUnits(props.unidades);
        }, [props.unidades])
        
        useEffect(updateUnitys, [, props.unidades]);
        
        function updateUnitys(){
            getDominios(localStorage.getItem('token')).then(res => {
                setStates(res.states)
                setCitys(res.citys)
                setProfiles(res.profiles)
                setAnamneseForms(res.anamnese)
            })
            .catch(console.error)
            
            getAllUnidades(localStorage.getItem('token')).then(res => {
                if(!res.error && res.length > 0) {
                    console.log(res);
                    setMedicalUnits(res)
                    setExecutingMedicalUnits(res.filter(item => item.funcao === 'Executante' || item.funcao === 'Ambos'))
                    setAdministrativeMedicalUnits(res.filter((item) => item.medical_unit_type === "Administrativa"));
                }
            })
            .catch(console.error);
        }
        
        return (
            <div>
            { props.open
                ?
                <SimpleDialog loading={ props.loading }
                created={ props.created }
                setErrors={ setErrors => props.setErrors(setErrors) }
                error={ props.error }
                open={ props.open }
                onClose={ e => props.onClose(true) }
                onNewMedicalUnit={ e => props.onNewMedicalUnit(true) }
                novaUnidade={ dados => props.novaUnidade(dados) }
                states={ states }
                citys={ citys }
                profiles={ profiles }
                anamneseForms={ anamneseForms }
                medicalUnits={ props.unidades }
                executingMedicalUnits={ executingMedicalUnits } 
                administrativeMedicalUnits={ administrativeMedicalUnits } />
                :
                ''
            }
            </div>
            );
        }
        