export function getBrowserData() {

    let navigatorObject = window.navigator
    var strBrowserData = "";

    if (navigatorObject.appCodeName)
        strBrowserData += navigatorObject.appCodeName

    if (navigatorObject.appName)
        strBrowserData += navigatorObject.appName

    if (navigatorObject.appVersion)
        strBrowserData += navigatorObject.appVersion

    if (navigatorObject.platform)
        strBrowserData += navigatorObject.platform

    if (navigatorObject.productSub)
        strBrowserData += navigatorObject.productSub

    return strBrowserData;

}