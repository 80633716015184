import env from 'react-dotenv';

function createRoom(token, dados) {
    return fetch(`${env.API_URL}/conference/createRoom?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function createConference(token, dados) {
    return fetch(`${env.API_URL}/conference?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function getConferenceByAttendenceId(token, id) {
    return fetch(`${env.API_URL}/conference/health-attendance/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getConferenceCallByAttendenceId(token, id) {
    return fetch(`${env.API_URL}/call/recordings/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getConferenceByRoom(token, roomId) {
    return fetch(`${env.API_URL}/conference/recordings/${ roomId }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function joinRoom(token, dados) {
    return fetch(`${env.API_URL}/conferenceParticipant/joinRoom?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function joinRoomPatient(token, dados) {
    return fetch(`${env.API_URL}/conferenceParticipant/joinRoomPatient?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}


function joinRoomUser(token, dados) {
    return fetch(`${env.API_URL}/conferenceParticipant/joinRoomUser?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function conferenceAddParticipant(token, dados) {
    return fetch(`${env.API_URL}/conferenceParticipant?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export {
    createRoom,
    createConference,
    getConferenceByAttendenceId,
    joinRoom,
    conferenceAddParticipant,
    joinRoomPatient,
    getConferenceByRoom,
    getConferenceCallByAttendenceId,
    joinRoomUser
}