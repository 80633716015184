import {
  CallReceived,
  Mic,
  PeopleAlt,
  PhoneEnabled,
  PhoneDisabled,
  PhoneInTalk,
  MicOff,
  Phone,
  Chat,
  Videocam,
  VideoCall,
  Person,
} from "@material-ui/icons";
import DButton from "../../../../../shared/DButton";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useState } from "react";

const useStyles = makeStyles({
  iconInverted: {
    transform: 'scaleX(-1)'
  }
});

const FinalizarIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.6933 3.48667L12.7667 2.36667C12.5867 2.14 12.3133 2 12 2H4C3.68667 2 3.41333 2.14 3.22667 2.36667L2.30667 3.48667C2.11333 3.71333 2 4.01333 2 4.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V4.33333C14 4.01333 13.8867 3.71333 13.6933 3.48667ZM7.76667 11.4333L4.33333 8H6.66667V6.66667H9.33333V8H11.6667L8.23333 11.4333C8.10667 11.56 7.89333 11.56 7.76667 11.4333ZM3.41333 3.33333L3.95333 2.66667H11.9533L12.58 3.33333H3.41333Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export function MuteCall(props) {
  return (
    <DButton
      {...props}
      className="mute-call-button"
      variant="secondary"
      icon={<Mic fontSize="small" />}
      size="md"
    />
  );
}

export function UnmuteCall(props) {
  return (
    <DButton
      {...props}
      className="mute-call-button"
      variant="secondary"
      icon={<MicOff fontSize="small" />}
      size="md"
    />
  );
}

export function AssumirELigar(props) {
  return (
    <DButton
      {...props}
      className="call-button"
      variant="primary"
      icon={<PhoneEnabled fontSize="small" />}
      size="md"
    >
      Assumir e ligar para paciente
    </DButton>
  );
}

export function AbdicarAtendimento(props) {
  return (
    <DButton
      {...props}
      className="call-red-button"
      variant="secondary"
      icon={<CallReceived fontSize="small" />}
      size="md"
    >
      Abdicar atendimento
    </DButton>
  );
}

export function LigarParaEspecialista(props) {
  return (
    <DButton
      {...props}
      className="call-doctor-button"
      variant="secondary"
      icon={<PeopleAlt fontSize="small" />}
      size="md"
    >
      Fale com o especialista
    </DButton>
  );
}

export function LigarParaPaciente(props) {

  const classes = useStyles();

  return (
    <DButton
      {...props}
      className="call-button"
      variant="primary"
      icon={<PhoneEnabled classes={{ root: classes.iconInverted }} fontSize="small" />}
      size="md"
    >
      Ligar para paciente
    </DButton>
  );
}

export function Desligar(props) {
  return (
    <DButton
      {...props}
      className="call-red-button"
      variant="secondary"
      icon={<PhoneDisabled fontSize="small" />}
      size="md"
    >
      Desligar
    </DButton>
  );
}

export function DesligarEFinalizar(props) {
  return (
    <DButton
      {...props}
      className="call-primary-red-button"
      variant="primary"
      icon={<PhoneInTalk fontSize="small" />}
      size="md"
    >
      Desligar e finalizar
    </DButton>
  );
}

export function Finalizar(props) {
  return (
    <DButton
      {...props}
      className="call-primary-red-button"
      variant="primary"
      icon={
        <FinalizarIcon
          style={{ width: 26, height: 26, paddingTop: 5 }}
          fontSize="small"
        />
      }
      size="md"
    >
      Finalizar atendimento
    </DButton>
  );
}

// Novos Botões

export function AbdicarAtendimentoNew(props) {
  return (
    <DButton
      {...props}
      className="attendance-orange-button"
      variant="secondary"
      size="sm"
    >
      Abdicar atendimento
    </DButton>
  );
}

export function ChatButton(props) {
  return (
    <DButton
      {...props}
      className="chat-green-button"
      variant="secondary"
      icon={<Chat fontSize="small" />}
      size="sm"
    >
      Chat
    </DButton>
  );
}

export function ChamadadeVoz(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <DButton
        {...props}
        className="voice-video-green-button"
        variant="secondary"
        icon={<Phone fontSize="small" />}
        size="sm"
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Chamada de voz
      </DButton>
      <Menu className="mt-20-menu"
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <Phone className="icon-drop-color" fontSize="small" />
          Ligar para paciente e solicitante
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Phone className="icon-drop-color" fontSize="small" />
          Ligar para solicitante
        </MenuItem>
      </Menu>
    </div>
  );
}

export function ChamadadeVideo(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <DButton
        {...props}
        className="voice-video-green-button"
        variant="secondary"
        icon={<Videocam fontSize="small" />}
        size="sm"
        id="fade-button2"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Chamada de vídeo
      </DButton>
      <Menu className="mt-20-menu"
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <VideoCall className="icon-drop-color" fontSize="small" />
          Ligar para paciente
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <VideoCall className="icon-drop-color" fontSize="small" />
          Ligar para paciente e solicitante
        </MenuItem>
      </Menu>
    </div>
  );
}

export function AssumirAtendimento(props) {
  return (
    <DButton
      {...props}
      className="voice-video-green-button"
      variant="secondary"
      icon={<Person fontSize="small" />}
      size="sm"
    >
      Assumir atendimento
    </DButton>
  );
}

export function FinalizarAtendimento(props) {
  return (
    <div>
      <DButton
        {...props}
        className="end-service-orange-button"
        variant="secondary"
        size="sm"
      >
        Finalizar atendimento
      </DButton>
    </div>
  );
}