import React from 'react';
import '../../../index.scss';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button, Divider } from '@material-ui/core';




function AnamneseHistorico(props) {

  let dataAnamnese = props.anamnese.map((anamnese_data) => {
    return { type: anamnese_data.field.type, label: anamnese_data.field.label, value: anamnese_data.value};
  });

  const fields = {
    LineBreak: (label, value) => (
      <>
        <section >
          <div>
            <Divider
              variant="fullWidth"
              style={{ marginTop: 30, marginBottom: 20 }}
            />
          </div>
        </section>
      </>
    ),
    Subtitulo: (label, value) => (
      <>
        <div className="fonte_subtitulo_new_tabs text-primary mt_35_new_tab">
          {label}
        </div>
      </>
    ),
    Default: (label, value) => (
      <>
        <div className="fonte_subtitulo_new_tabs text-primary mt_35_new_tab">
          {label}
        </div>
        <div className="mt_50_new_tab">
          <div className="fonte_preta_subtitulo_new_tabs">
            <div
              dangerouslySetInnerHTML={{ __html: `${value}` }}
            />
          </div>
          <div className="linha_texto_new_tabs mt10_new_tabs"></div>
        </div>
      </>
    ),
  };
  
  const getFields = (type, label, value) => {
    switch (type) {
      case "LineBreak": //Textarea - HPMA
        return fields[type] ? fields[type](label, value) : "Leitura de campo indisponível no momento";
      case "Subtitulo": //Textarea - HPMA
        return fields[type] ? fields[type](label, value) : "Leitura de campo indisponível no momento";
      default:
        return fields['Default'] ? fields['Default'](label, value) : "Leitura de campo indisponível no momento";
    }
  }

  const resultField = dataAnamnese.map(data => getFields(data.type, data.label, data.value))
  
  return (
    <>
      {resultField}
      
    </>
  )
}

AnamneseHistorico.defaultProps = {
  anamnese: []
}

export default AnamneseHistorico;