import { Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { uploadHealthAttendanceFile } from "../../../../../../../api/healthAttendance";
import { FormBuilderContext } from "../../../../../../../context/FormBuilderContext";
import DDropzone from "../../../../../../shared/DDropzone";
import DInput from "../../../../../../shared/DInput";

const EBUpload = ({
  id,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  additional_data,
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [file, setFile] = React.useState(null);
  const [idFile, setIdFile] = React.useState(null);


  useEffect(() => {
    if (file) {
      uploadHealthAttendanceFile(localStorage.getItem("token"), file, 1)
        .then((data) => {
          if(data.status) {
            setIdFile(data.healthAttendanceFile.id)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file]);

  useEffect(() => {
    handleInputSubmit({[id]: idFile})
  },[idFile])

  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: "1em", display: "flex"}}
        >
          <div dangerouslySetInnerHTML={{ __html: `${additional_data.construct.showTitle ? label : ""}` }} />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>{is_required === 1 ? '*' : ''}</span>
        </Typography>

        <DDropzone
          emit={(files) => setFile(files[0])}
          accept=".pdf, .jpg, .jpeg, .png"
          description="Formatos pdf, jpg ou png com até 10 Mb"
        />
        <span style={{ fontSize: 12 }} className="text-light text-error">
          { erro[0] ? erro[0].value : '' }
        </span>
        
        <span style={{ fontSize: 12 }} className="text-light">
          {helper_text ? helper_text : ""}
        </span>
      </div>
    </section>
  );
};

export default EBUpload;
