import {Component} from "react";
import { Redirect } from "react-router-dom";
import {Container, Grid, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Medicine from "../../assets/img/undraw_medicine_b1ol 1.svg";
import LoginWatermark from "../../assets/img/LoginWatermark.png"
import LogoDuosystemDevelopedBy from "../../assets/img/LogoDuosystem.svg"
import { toast, ToastContainer } from 'react-toastify';

import FooterLogin from "../shared/FooterLogin";
import FormLogin from "./components/FormLogin";
import FormTwoFactor from "./components/FormTwoFactor";
import MdTermUse from "./components/MdTermUse";
import LogoTheme from './components/LogoTheme';
import { authId as auth, resendCode, verificarCodigo, recoverPassword } from "../../api/auth";
import {getMe, getMeLogin, getDefaultPermission} from "../../api/user";
import styled from "styled-components";

import './style.scss';
// import FormRecover from "./components/FormRecover";
import FormRecoverContent from "./components/FormRecoverContent";

import React from "react";
import env from "react-dotenv";
import ReCAPTCHA from "react-google-recaptcha";

import {setCookie, getCookie} from "./../../helpers/formatacao";

const Content = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ContainerLogin = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
`;

const Wrapper = styled.div`
    height: 100%;
    border-bottom: 8px solid #006FB5;
`;
class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            acoes: 0,
            logando: false,
            reenviando: false,
            errorLogin: false,
            enviandoCodigo: false,
            errorCodigo: false,
            solicitandoNovaSenha: false,
            solicitacaoNovaSenhaEnviada: false,
            tentativasLogin: 0,
            termoDeUso: false,
            openMdTermUse: false,
            dados: '',
            signTerm: false,
            checked: false,
            route_default: "/meu-perfil"
        }
    }

    clearStorageExcept(array){
        let list = array.map((item) => {
            return {key: item, value: localStorage.getItem(item)};
        });

        localStorage.clear();

        list.forEach((item) => {
            localStorage.setItem(item.key, item.value);
        });
    }

    componentDidMount() {
        this.clearStorageExcept(['theme']);
    }

    _setToken(res) {
        localStorage.setItem('old_token', localStorage.getItem('token'));
        localStorage.setItem('token', res.token);
        localStorage.setItem('uid', res.details.uid);
        localStorage.setItem('scope', res.details.scope);
        localStorage.setItem('exp', res.details.exp);
        localStorage.setItem('email', res.details.email);
    }

    efetuarLogin(dados) {
        this.setState({ ...this.state, logando: true })

        auth(dados).then(res => {
            console.log("Res authid", res)
            if(res.status === false && res.error) {
                toast.error(res.error.message ? res.error.message : 'Erro ao tentar efetuar login.')

                this.setState({ ...this.state,
                    logando: false,
                    tentativasLogin: res.details !== undefined ?  res.details.attempts : 0,
                    errorLogin: res.error
                })
                
                this.setState({ ...this.state, logando: false})

                return;
            }
            
            if(res.token) {
                this._setToken(res);
                if(res.details.scope === 'authenticated' || res.details.scope === 'acl') {
                    getMeLogin(localStorage.getItem('token')).then(res => {
                        if(!res.term_use_verified)
                            this.setState({ ...this.state, openMdTermUse: true, dados: res, signTerm: false })
                        else {
                            getDefaultPermission(localStorage.getItem('token')).then(res => {
                                if(res.status) {
                                    localStorage.setItem('route_default', res.route_default);
                                    this.setState({ ...this.state, logando: false, acoes: 4, route_default: res.route_default })
                                } else {
                                    this.setState({ ...this.state, logando: false, acoes: 4 })
                                }
                            });
                        }
                    });
                    
                } else
                    this.setState({ ...this.state, logando: false, acoes: 1 })
            }
            
        })
        .catch(err => {
            toast.error('Erro ao tentar efetuar login.');
            this.setState({ ...this.state, logando: false})

            console.log(err);
        });

        console.log(this.state.openMdTermUse)
    }

    reenviarCodigo(type) {
        this.setState({ ...this.state, reenviando: true })
        resendCode(type).then(res => {
            this.setState({ ...this.state, reenviando: false, errorCodigo: false, errorLogin: null })
        });
    }

    verifyCode(dados) {
        this.setState({ ...this.state, enviandoCodigo: true })
        verificarCodigo(dados).then(res => {
            if( res.error !== undefined ) {
                this.setState({ ...this.state, enviandoCodigo: false, errorCodigo: true })
            } else {

                this._setToken(res);

                getMe(localStorage.getItem('token')).then(res => {
                    console.log(res)
                    if(res.term_use_verified)
                        this.setState({ ...this.state, enviandoCodigo: false, errorCodigo: true, acoes: 3 })
                    else 
                        this.setState({ ...this.state, openMdTermUse: true, dados: res, signTerm: false })
                });
            }
        });
    }

    
    
    recoverPassword(dados) {

        recoverPassword(dados).then(res => {

            if(!res.error) {    

                this.setState({ ...this.state, solicitandoNovaSenha: true })
                this.setState({ ...this.state, solicitandoNovaSenha: false, solicitacaoNovaSenhaEnviada: true })
        
            } else {

                toast.error(res.error.message ? res.error.message : 'Usuário não encontrado.')

            }

        })
    }

    voltar() {
        this.setState({ ...this.state, acoes: 0, tentativasLogin: 0, errorLogin: false, solicitacaoNovaSenhaEnviada: false })
    }    

    getAction(props) {

        var acoes = [
            <FormLogin redefinir={ e => this.setState({...this.state, acoes: 2}) }
                       loading={ this.state.logando }
                       tentativas={ this.state.tentativasLogin }
                       error={ this.state.errorLogin }
                       login={ dados => {
                           props.recaptchaRef.current.execute();
                           props.recaptchaRef.current.reset();
                           this.efetuarLogin(dados);
                       } }
            />,
            <>
                { !this.state.openMdTermUse ?
                    <>
                        <FormTwoFactor loading={ this.state.enviandoCodigo }
                        error={ this.state.errorCodigo }
                        reenviando={ this.state.reenviando }
                        reenviar={ dados => {
                            props.recaptchaRef.current.execute();
                            props.recaptchaRef.current.reset();
                            this.reenviarCodigo(dados);
                        } }
                        verificar={ dados => {
                            props.recaptchaRef.current.execute();
                            props.recaptchaRef.current.reset();
                            this.verifyCode(dados);
                        } }
                        voltar={ this.voltar.bind(this) } />
                    </>
                    : 
                    <Grid container alignItems="center" justifyContent="center" style={{height: 300}}>
                        <Grid item>
                            <CircularProgress color="inherit" />
                        </Grid>
                    </Grid>
                }
                
            </>,            
            <FormRecoverContent onCode={ e => {
                console.log('FormRecoverContent');
                this.setState({ ...this.state, acoes: 0 });
            } } />,
            <Redirect to="/profissional" />,
            <Redirect to={ this.state.route_default } />
        ]

        // <FormRecover voltar={ this.voltar.bind(this) }
        //                  loading={ this.state.solicitandoNovaSenha }
        //                  modal={ this.state.solicitacaoNovaSenhaEnviada }
        //                  onCloseModal={ this.voltar.bind(this) }
        //                  recover={ dados => {
        //                     props.recaptchaRef.current.execute();
        //                     props.recaptchaRef.current.reset();
        //                     this.recoverPassword(dados);
        //                  }} />,

        return acoes[this.state.acoes];
    }

    

    render() {

        this.recaptchaRef = React.createRef();

        return(
            <Wrapper className="bg-white container-login">
                <ToastContainer />
                <ContainerLogin className="h-100" >
                    <Content item md={6} xs={12}>
                        <div style={{marginBottom: "73px"}}>
                            <LogoTheme />
                        </div>

                        <img alt="médicos" src={ Medicine } onClick={ e => this.setState({...this.state, acoes: 0}) } />
                        
                        {/* <div style={{display: 'flex', flexDirection: 'row', marginTop: 107, width: '70%', justifyContent: 'space-around', alignItems: 'center'}}>
                            <img alt="watermark" height="24px" src={ LoginWatermark } />
                            <img alt="Desenvolvido por DuoSystem" src={ LogoDuosystemDevelopedBy } />
                        </div> */}
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 107, width: '70%', justifyContent: 'center', alignItems: 'center'}}>
                            <img alt="Desenvolvido por DuoSystem" src={ LogoDuosystemDevelopedBy } />
                        </div>
                    </Content>
                    <Grid item xs={12} md={6} style={{display: 'flex'}} justifyContent="center">
                        { this.getAction({ recaptchaRef: this.recaptchaRef }) }
                        <ReCAPTCHA
                            ref={ this.recaptchaRef }
                            size="invisible"
                            sitekey={ env.SITE_KEY }
                        />
                    </Grid>
                </ContainerLogin>
                <MdTermUse 
                    open={this.state.openMdTermUse} 
                    onClose={ e => {
                        if(localStorage.getItem('old_token')) {
                            localStorage.setItem('token', localStorage.getItem('old_token'));
                        }
                        this.setState({ ...this.state, openMdTermUse: false, enviandoCodigo: false, acoes: 0, logando: false })
                    }} 
                    value={this.state.dados} 
                    signTerm={ e => {
                        // this.setState({ ...this.state, signTerm: true, openMdTermUse: false, enviandoCodigo: false, acoes: 3})
                        getDefaultPermission(localStorage.getItem('token')).then(res => {
                            if(res.status) {
                                localStorage.setItem('route_default', res.route_default);
                                this.setState({ ...this.state, logando: false, acoes: 4, route_default: res.route_default, signTerm: true, openMdTermUse: false, enviandoCodigo: false })
                            } else {
                                this.setState({ ...this.state, logando: false, acoes: 4, signTerm: true, openMdTermUse: false, enviandoCodigo: false })
                            }
                        });
                    } }
                >
                </MdTermUse>
                <FooterLogin modal={this.state.termoDeUso} onOpenModal={ e => this.setState({ ...this.state, termoDeUso: true }) } onCloseModal={ e => this.setState({ ...this.state, termoDeUso: false }) } />
            </Wrapper>
        )
    }
}

export default Login;
