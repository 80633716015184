import { useState, useEffect, useContext } from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import {  makeStyles } from "@material-ui/core/styles";
import { getUserInfoById, getMe } from "./../../../api/user";
import { getProfileById } from  "./../../../api/profiles";
import { AppContext } from "./../../../context/AppContext";
import Documents from "./components/Documents";

const useStyles =  makeStyles((theme) => ({
    textTitle: {
      color: theme.palette.primary.main,
      marginBottom: 15
    },
    text: {
        color: theme.palette.primary.light,
      }
  }));

function DadosProfissional(props)
{
    const classes = useStyles();

    const { userInfo } = useContext(AppContext);
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        getMe(localStorage.getItem('token')).then(res => {
            if(res) {
                var token = res.token_details;
                getUserInfoById(localStorage.getItem('token'), token.uid).then(res => {
                    if(res.status) {
                        setUser(res);
                    }
                });

                getProfileById(localStorage.getItem('token'), token.acl.profile_id).then(res => {
                    if(res.status) {
                        setProfile(res);
                    }
                });
            }
        });
    }, [])

    useEffect(() => {

        if(profile && profile.profileDocument) {
            var documents_array = profile.profileDocument.map(profile_document => {
                return profile_document.document_type ? profile_document.document_type.description : "Não informado";
            });

            documents_array.push("RG", "CPF", "CNS");

            setDocuments(documents_array);
        }
    }, [profile]);

    useEffect(() => {
        getProfileById(localStorage.getItem('token'), userInfo.perfil).then(res => {
            if(res.status) {
                setProfile(res);
            }
        });
    }, [userInfo]);

    return (<>
        { (user && user.user) && <Grid container direction="column">
            <Grid item>
                <Card>
                    <CardContent>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography className={ `text-regular ${classes.textTitle}` } variant="h2">Dados do profissional</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className="text-regular"><strong className={classes.text}>Profissional:</strong> { user.user.social_name ? user.user.social_name : user.user.full_name  }</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className="text-regular"><strong className={classes.text}>Data de nascimento:</strong> { user.user.birth_date }</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" className="text-regular"><strong className={classes.text}>E-mail:</strong> { user.user.email }</Typography>
                                    </Grid> 
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="h3" className={ `text-regular ${classes.textTitle}` }>Documentação</Typography>
                                    </Grid>
                                    <>
                                        { (profile && documents && user.document) && <>
                                            { (user.document && Array.isArray(user.document)) && user.document.filter(document => documents.includes(document.type)).map((user_document, index) => <Grid item>
                                                    <Documents key={ `documents-${index}` } type={ user_document.type } fields={ user_document.fields } />                                                    
                                                </Grid> ) }                                            
                                        </> }
                                    </>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid> }
    </>)
}

export default DadosProfissional;