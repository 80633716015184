import { MenuItem, Select, Typography, CircularProgress } from "@material-ui/core";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { getMenusByProfileAndUnity } from "../../../../../api/user";
import { useState } from "react";
import { getUnidade } from "../../../../../api/unidades";
import styled from "styled-components";
import { useTheme } from "@material-ui/styles";


function UserInfo(props) {

    const [loading, setLoading] = useState(false);

    const {
      setUpdateAcl,
      userInfo, 
      setUserInfo, 
      selectedProfile, 
      setSelectedProfile, 
      profileInfo, 
      handleGetProfiles,
      setLockAdministrativeUnitMenu,
      unitChangedInConfiguration
    } = useContext(AppContext);

    const theme = useTheme();

    const HeaderUserInfo = styled.div`
        display: flex;
        flex-direction: row-reverse;

        .MuiSelect-select.MuiSelect-select {
          padding: 5px 24px 2px 4px;
        }

        .MuiSelect-icon {
          color: ${theme.palette.primary.light};
        }


        .MuiInput-underline {
          &:after,
          &:before,
          &:hover:not(.Mui-disabled):before {
            border: 0;
          }
        }

        .content:first-child {
          padding-right: 20px;
        }

        .content:last-child {
          padding-right: 10px;
        }

    `;

    useEffect(() => {
      // handleGetProfiles();
    }, [])

    // useEffect(() => {

    //   if(!userInfo || !userInfo.perfil) return;

    //     setLoading(true)

    //     getUnidade(localStorage.getItem('token'), userInfo.unidade).then(res => {
    //       let lockMenu = false
    //       if(res.details.medicalUnit.medical_unit_type === 'Administrativa') {
    //         lockMenu = true
    //         setLockAdministrativeUnitMenu(true)
    //         // toast.error("Erro: Rota não permitida para esta unidade/perfil.")
    //       }
    //       else {
    //         setLockAdministrativeUnitMenu(false)
    //       }

    //     setUpdateAcl(false);

    //     getMenusByProfileAndUnity(localStorage.getItem('token'), userInfo.unidade, userInfo.perfil ).then(data => {
    //         if(!data || (data && !data.details)) {
    //           setLoading(false)
    //           return;
    //         }
  
    //         let menuData = data.details.profileMenu;
  
    //         if(lockMenu) {
    //           props.menus(menuData.filter(menu => menu.slug !== '/atendimento-imediato' && menu.slug !== '/solicitacao'));
    //         } else {
    //           props.menus(menuData);
    //         }
  
    //         if(data.status) {
    //             localStorage.setItem('token', data.token);
    //             localStorage.setItem('scope', 'acl');
    //         }
  
    //         setLoading(false);
    //         setUpdateAcl(true);
  
    //       }).catch(err => {
    //         console.error(err)
    //         setLoading(false)
    //       })

    //     })
    //     .catch(err => {
    //       console.error(err)
    //       setLoading(false)
    //     })

    // }, [userInfo, profileInfo, unitChangedInConfiguration]);

    return (
        <HeaderUserInfo>
            { loading ?
              <div className="content">
                <Typography variant="body1">Carregando permissão aguarde <CircularProgress size={15} color="inherit" /></Typography>
              </div>
              :
              <>
                <div className="content">
                    <strong>Unidade: </strong>
                    <Select
                      onChange={e => {setUserInfo({...userInfo, unidade: e.target.value})}}
                      value={userInfo && userInfo.unidade}
                    >
                      {
                        selectedProfile && selectedProfile.units.map((item, index) => {
                              return <MenuItem value={item.medical_unit_id} key={index}>{item.description}</MenuItem>
                          })
                      }
                    </Select>
                </div>
                <div className="content">
                    <strong>Perfil: </strong>
                    <Select
                      onChange={e => {setSelectedProfile(profileInfo.find(profile => profile.profile_id === e.target.value)); setUserInfo({...userInfo, perfil: e.target.value})}}
                      value={selectedProfile && selectedProfile.profile_id}
                    >
                      {
                          profileInfo && profileInfo.map((item, index) => {
                              return <MenuItem value={item.profile_id} key={index}>{item.description}</MenuItem>
                          })
                      }
                    </Select>
                </div>
              </>
          }            
        </HeaderUserInfo>
    )
}
 
export default UserInfo;