import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Typography, Container, Card, CardContent, Grid, AppBar, Toolbar, IconButton, CircularProgress, Backdrop } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Save from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import DContainer from '../../../../shared/DContainer';
import DButton from '../../../../shared/DButton'; 
import DInput from '../../../../shared/DInput'; 
import DDropdown from "../../../../shared/DDropdown";

import AddFormCondition from "./AddFormCondition";
import AddTrueFormCondition from "./AddTrueFormCondition";
import AddFalseFormCondition from "./AddFalseFormCondition";
import FormsConditionGrid from './FormsConditionGrid';
import FormConditionalsCondition from './FormConditionalsCondition';
import FormsConditionContent from './FormsConditionContent';

import { ConditionalsProvider } from "./../../../../../context/ConditionalsContext";

import Construtor from './../../../../../api/construtor';
import { toast } from 'react-toastify';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.transparency.black16,
        boxShadow: '0px 8px 26px -5px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 1px 1px 3px 2px rgb(0 0 0 / 12%)',
        fontSize: 20,
        marginTop: 3
    },
    arrow: {
        color: theme.palette.neutral.white
    }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    backDrop: {
        zIndex: 3000,
        color: theme.palette.neutral.white
    },
    card: {
        marginBottom: 8,
    },
    cardContent: {
        paddingBottom: '16px !important'
    },
    cardCreate: {
        marginBottom: 40
    },
    icon: {
        color: theme.palette.primary.light
    }
}));

function FormsCondition(props)
{
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [form, setForm] = useState(undefined);
    const [create, setCreate] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [messageBackdrop, setMessageBackdrop] = useState('Carregando...')

    const [conditionals, setConditionals] = useState(undefined);

    
    useEffect(function(){

        loadingGrid();
    }, [updated])

    function loadingGrid()
    {
        setCreate(false);
        setLoading(true);

        Construtor.show(localStorage.getItem('token'), props.formId).then(res => {
            if(res.status)
                setForm(res);

            setLoading(false);            
        })
    }

    function Navbar()
    {
        return (
            <div className={classes.root}>
                <AppBar position="static" color="transparent">
                    <Toolbar variant="dense">
                    <IconButton onClick={ e => props.onAction({formId: null, action: 'grid'}) } edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <ArrowBackIosIcon className={classes.icon} />
                    </IconButton>
                    <Typography variant="h4" color="inherit">
                        Construtor de formulários
                    </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        )
    }


    return (
        <>
            { loading && 
                <Backdrop className={ classes.backDrop } open={ true }>
                    <CircularProgress color="inherit" />&nbsp;{ messageBackdrop }
                </Backdrop>
            }

            <Navbar />           
            
            <ConditionalsProvider>
                <DContainer maxWidth="100%">
                    <Grid container spacing={3}>
                        <Grid item md={7}>
                            <Typography style={{ marginTop: 15, marginBottom: 24 }} variant="h3" className="text-primary">{ form ? form.anamnese_form.name : '' }</Typography>
                            <Card classes={{root: classes.cardCreate}}>
                                <CardContent classes={{root: classes.cardContent}}>
                                    <Grid container justifyContent="space-between" alignItems="center" >
                                        <Grid item>
                                            <Typography variant="body1" className="text-medium text-lt">Condicionais</Typography>
                                        </Grid>
                                        <Grid item>
                                            <DButton onClick={ e => setCreate(true) } variant="secondary" size="md" type="button" icon={ <AddCircleIcon size="small" /> }>Adicionar condicional</DButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>                                
                            </Card>    
                            { form &&<FormsConditionGrid 
                                formId={ props.formId }
                                conditionals={ (form.form && form.form.form_conditionals) ? form.form.form_conditionals.sort(function(a, b){return b.id-a.id}) : [] } 
                                create={ create }
                                onCloseCreate={ e => setCreate(false) }
                                onCreated={ e => {                                        
                                    setMessageBackdrop('Recarregando...')                                        
                                    loadingGrid()
                                }}
                                onDeleted={ e => {
                                    setMessageBackdrop('Recarregando...')
                                    loadingGrid()
                                } } />
                            }
                        </Grid>
                        <Grid item md={5}>
                            <Typography style={{ marginTop: 15, marginBottom: 24 }} variant="h3" className="text-primary">Configurar nova condicional</Typography>
                            <Card classes={{root: classes.card}} style={{ marginBottom: 35 }}>
                                <CardContent classes={{root: classes.cardContent}}>      
                                    <FormsConditionContent />
                                </CardContent>                                
                            </Card> 
                        </Grid>
                    </Grid>                             
                </DContainer>       
            </ConditionalsProvider>                     
        </>
    )
}

export default FormsCondition;