import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Chip, Grid, Link, Typography } from "@material-ui/core";
import { Cancel, Clear, Edit, Reply, Send } from "@material-ui/icons";
import DButton from "../../../shared/DButton";
import DInput from "../../../shared/DInput";
import { toast } from "react-toastify";
import { useEffect } from "react";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: "1px solid #949494",
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    border: 0,
    marginBottom: 0,
  },
  headerConsultaCancelada: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: "0",
    marginBottom: "0",
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: "solid 1px #C7C7C7",
    marginTop: 20,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  gridButton: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
  },
  gridLink: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  headerModalSuccess: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    margin: 20,
  },
  headerText: {
      marginLeft: 10,
  }
}));

export default function RemoverTelefone(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Clear
            style={{ color: "#949494", cursor: "pointer" }}
            onClick={(e) => {
              props.onClose(true);
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light">
          <>

            <Grid item md={12} sm={6}>
                <Typography className={`text-mt text-regular mb-1 ${classes.headerText}`} variant="body1">
                    Confirmar a exclusão do telefone?
                </Typography>
            </Grid>

            <Grid item md={8} sm={3} className={classes.gridButton}>
              <DButton
                variant="outlined"
                color="secondary"
                size="sm"
                onClick={e => props.onClose()}
              >
                Cancelar
              </DButton>
              <DButton
                variant="primary"
                className={"btn-error"}
                size="sm"
                onClick={e => props.onRemove(props.index)}
              >
                Confirmar
              </DButton>
            </Grid>
          </>
        </Grid>
      </>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
