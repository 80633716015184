import React from "react";
import {Component} from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Card, 
    CardContent, 
    Container, 
    Grid, 
    Typography, 
    Backdrop,
    Box
} from "@material-ui/core";
import { Input } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { IconButton } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import './client.css';

// Import Toast
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Router
import { withRouter } from "react-router-dom";

// API - imports
import { getAllCategories } from "./api/faqCategory";
import { getAllQuestionsByTitle, getSuggestedQuestions, registerViewQuestion } from "./api/faqQuestion";
import { getAllContacts } from "./api/faqContact";

const styles = (theme) => ({
    top: {
        width: "calc(100% - 92px)",
        height: 41,
        display: "flex",
        padding: "10px 46px",
        background: theme.palette.background.snow,
        boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
        alignItems: "center",
        flexDirection: "row",
    },
    icon: {
        marginRight: 12,
        cursor: 'pointer',
        color: theme.palette.primary.light,
    },
    titleTop: {
        fontSize: 18,
        fontWeight: 500,
        color: theme.palette.neutral.black,
    },
    title: {
        marginTop: 32,
        marginBottom: 28,
        fontSize: 24,
        fontWeight: 700,
        lineHeight: "36px",
        color: theme.palette.primary.light,
    },
    subtitle: {
        marginBottom: 8,
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "27px",
        color: theme.palette.primary.light,
    },
    sidebar: {
        borderRadius: 12,
        backgroundColor: theme.palette.background.snow,
        padding: "20px 28px",
        minHeight: "calc(100vh - 380px)"
    },
    box: {
        background: theme.palette.background.snow,
        boxShadow: "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
        borderRadius: 8,
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 61,
        padding: "0 16px",
        color: theme.palette.primary.light,
        minWidth: "max-content",
        width: "fit-content",
        marginRight: 8
    },
    box_empty: {
        borderRadius: 12,
        backgroundColor: theme.palette.background.snow,
        padding: "20px 28px",
        height: "calc(100vh - 380px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },
    box_noresult: {
        borderRadius: 12,
        backgroundColor: theme.palette.background.snow,
        padding: "20px 28px",
        height: 115,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: 8,
        boxShadow: "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)"
    },
    box_full: {
        borderRadius: 12,
        backgroundColor: theme.palette.background.snow,
        padding: "20px 28px"
    },
    category: {
        borderRadius: 8,
        backgroundColor: theme.palette.background.snow,
        padding: 8,
        marginBottom: 16,
        marginTop: 8
    },
    category_active : {
        borderRadius: 8,
        backgroundColor: theme.palette.primary.light,
        padding: 8,
        marginBottom: 16,
        marginTop: 8,
        filter: "drop-shadow(0px 4px 5px rgba(49, 34, 67, 0.12)) drop-shadow(0px 1px 10px rgba(49, 34, 67, 0.1)) drop-shadow(0px 2px 4px rgba(49, 34, 67, 0.18))"
    },
    category_item : {
        fontSize: 14,
        lineHeight: "21px",
        fontWeight: 400,
        cursor: "pointer"
    },
    category_item_active : {
        fontSize: 14,
        lineHeight: "21px",
        fontWeight: 400,
        cursor: "pointer",
        color: theme.palette.neutral.white,
    },
    question_card: {
        background: theme.palette.background.snow,
        boxShadow: "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
        borderRadius: 8,
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 61,
        padding: "0 32px 0 16px",
        color: theme.palette.neutral.black,
    },
    question_card_active: {
        background: theme.palette.primary.light,
        boxShadow: "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
        borderRadius: 8,
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 61,
        padding: "0 32px 0 16px",
        color: theme.palette.neutral.white,
    },
    answer_card: {
        background: theme.palette.background.snow,
        boxShadow: "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
        borderRadius: 8,
        marginBottom: 8,
        display: "flex",
        alignItems: "center",
        padding: "16px 32px 16px 16px",
    },
    open_answer: {
        textTransform: "inherit",
        color: theme.palette.primary.light,
        padding: 0
    },
    close_answer: {
        textTransform: "inherit",
        color: theme.palette.background.snow,
        padding: 0
    }
});

class FaqUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingQuestions: false,
            loadingSuggestedQuestions: false,
            loadingContacts: false,
            categories: [],
            questions: [],
            suggestedQuestions: [],
            contacts: [],
            searchResult: [],
            searchText: '',
            isEmpty: true,
            isSearch: false,
            modalVideo: false
        }
    }

    onBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        this.listCategories();
        this.listContacts();
        this.listSuggestedQuestions();
    }

    listCategories() {
        this.setState({...this.state, categories: [], loadingQuestions: true});

        getAllCategories(localStorage.getItem('token')).then(res => {
            if (res.status) {
                res.categories.map((row) => {
                    var new_state       = this.state.categories;
                    var questionsArray  = [];

                    row.questions.map((question) => {
                        if(question !== null) {
                            var new_question = question;
                            new_question.isAccordion = false;

                            questionsArray.push(new_question);
                        }
                    });

                    new_state.push({
                        id: row.id, 
                        name: row.name, 
                        questions: questionsArray, 
                        isAccordion: false, 
                        isLoading: false,
                    });

                    this.setState({ ...this.state, categories: new_state, loadingQuestions: false })
                })
            } else {
                this.setState({ ...this.state, categories: [], loadingQuestions: false })
                toast.error("Ocorreu um erro consultar as categorias.")
            }
        });
    }

    showCategory(category, index){
        this.state.isEmpty = false;
        this.state.categories.map((row, indexArray) => (
            indexArray != index ? row.isAccordion = false : null
        ));

        var new_category = this.state.categories;

        if (new_category[index].isAccordion == true) { 
            this.state.isEmpty = true 
        }
        
        new_category[index].isAccordion = !new_category[index].isAccordion;
        this.setState({ ...this.state, categories: new_category, questions: new_category[index].questions, isSearch: false });
    }

    listSuggestedQuestions() {
        this.setState({...this.state, suggestedQuestions: [], loadingSuggestedQuestions: true});

        getSuggestedQuestions(localStorage.getItem('token')).then(res => {
            if (res.status) {
                if (res.count.length) {
                    res.count.map((row) => {
                        var new_state = this.state.suggestedQuestions;

                        if (row.question) {
                            new_state.push({
                                id: row.question_id,
                                title: row.question.title,
                                description: row.question.description,
                                type: row.question.type,
                                isAccordion: false,
                            });
                        }

                        this.setState({ ...this.state, suggestedQuestions: new_state, loadingSuggestedQuestions: false });
                    });
                } else {
                    this.setState({ ...this.state, loadingSuggestedQuestions: false });
                }
            } else {
                this.setState({ ...this.state, suggestedQuestions: [], loadingSuggestedQuestions: false });
                toast.error("Ocorreu um erro consultar as perguntas sugeridas.");
            }
        });
    }

    listContacts() {
        this.setState({...this.state, contacts: [], loadingContacts: true});

        getAllContacts(localStorage.getItem('token')).then(res => {
            if (res.status) {
                if (res.contacts.length) {
                    res.contacts.map((row) => {
                        var new_state = this.state.contacts;

                        new_state.push({
                            id: row.id,
                            name: row.value,
                            type: row.type,
                        });

                        this.setState({ ...this.state, contacts: new_state, loadingContacts: false });
                    });
                } else {
                    this.setState({ ...this.state, loadingContacts: false });
                }
            } else {
                this.setState({ ...this.state, contacts: [], loadingContacts: false });
                toast.error("Ocorreu um erro consultar os contatos.");
            }
        });
    }

    search() {
        this.setState({...this.state, loadingQuestions: true});

        if (this.state.searchText.length > 0) {
            getAllQuestionsByTitle(localStorage.getItem('token'), this.state.searchText).then(res => {
                if (res.status) {
                    var new_results  = [];
                    var new_category = this.state.categories;

                    res.questions.map((row) => (
                        new_results.push({
                            id: row.id,
                            title: row.title,
                            description: row.description,
                            type: row.type,
                            isAccordion: false,
                        })
                    ));

                    new_category.map((row) => (
                        row.isAccordion = false
                    ));

                    this.setState({ ...this.state, categories: new_category, searchResult: new_results, isSearch: true, loadingQuestions: false });
                } else {
                    this.setState({ ...this.state, categories: new_category, searchResult: [], isSearch: true, loadingQuestions: false });
                }
            });
        } else {
            toast.error("Preencha o campo de busca.");
        }
    }

    insertViewQuestion(question) {
        const user = localStorage.getItem('uid');

        var payload = {
            user_id: user,
            question_id: question
        }

        registerViewQuestion(localStorage.getItem('token'), payload).then(res => {
            if (!res.status) {
                console.log('Ocorreu um erro ao buscar as perguntas indicadas: ', res);
            }
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <Box className={ classes.top }>
                    <ArrowBackIosIcon className={ classes.icon } onClick={ e => this.onBack() } />
                    <Typography className={ classes.titleTop }>FAQ - Perguntas Frequentes</Typography>
                </Box>
                
                <Container>
                    <Backdrop style={{ zIndex: 3000, color: 'white' }} open={ this.state.loadingQuestions || this.state.loadingSuggestedQuestions || this.state.loadingContacts }>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Grid container spacing={5}>
                        <Grid item md={3}>
                            <Typography className={ classes.title }>Categorias</Typography>

                            <Grid style={ styles.sidebar }>
                            {
                                this.state.categories.map((row, index) => (
                                    <Grid
                                        item
                                        className={ row.isAccordion ? classes.category_active : classes.category }
                                        onClick={ e => {
                                            this.showCategory(row, index)
                                        }}
                                    >
                                        <Typography className={ row.isAccordion ? classes.category_item_active : classes.category_item }>{ row.name }</Typography>
                                    </Grid>
                                ))
                            }
                            </Grid>
                        </Grid>
                        <Grid item md={9}>
                            <Input
                                id="searchAuto"
                                placeholder="Como podemos ajudar?"
                                variant="outlined"
                                type="search"
                                value={ this.state.searchText }
                                disableUnderline
                                style={{
                                    marginTop: 32,
                                    marginBottom: 26,
                                    paddingLeft: 10,
                                    paddingRight: 5,
                                    background: "#fff",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: 5,
                                    width: 472,
                                    padding: "0 !important"
                                }}
                                endAdornment={
                                    <SearchIcon 
                                        style={{ color: '#006FB5', cursor: 'pointer' }} 
                                        onClick={ e => {
                                            this.search();
                                        }}
                                    />
                                }
                                onChange={ e => {
                                    this.setState({ ...this.state, searchText: e.target.value });
                                }}
                            />
                            { this.state.isSearch
                                ?
                                    <Grid className={ classes.box_full }>
                                        <Typography className={ classes.subtitle }>Resultados da busca</Typography>
                                        { this.state.searchResult.length > 0
                                            ?
                                                this.state.searchResult.map((question, index) => (
                                                    <>
                                                        <Box className={ question.isAccordion ? classes.question_card_active : classes.question_card }>
                                                            <Typography style={{ fontWeight: 400, fontSize: 16 }}>{ question.title }</Typography>
                                                            <Button
                                                                size="small"
                                                                startIcon={ question.isAccordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                                                className={ question.isAccordion ? classes.close_answer : classes.open_answer }
                                                                onClick={ e => {
                                                                    var new_result = this.state.searchResult;
                                                                    new_result[index].isAccordion = !new_result[index].isAccordion;

                                                                    this.setState({ ...this.state, searchResult: new_result });
                                                                }}
                                                            >
                                                                { question.isAccordion ? 'Ocultar Resposta' : 'Ver Resposta' }
                                                            </Button>
                                                        </Box>
                                                        { question.isAccordion
                                                            ?
                                                                <Box className={ classes.answer_card }>
                                                                    <Grid item md={12}>
                                                                        <Box dangerouslySetInnerHTML={{__html: question.description}} />
                                                                        <Box style={{ textAlign: "right" }}>
                                                                            <Button
                                                                                size="small"
                                                                                startIcon={<KeyboardArrowUpIcon />}
                                                                                className={ classes.open_answer }
                                                                                onClick={ e => {
                                                                                    var new_result = this.state.questions;
                                                                                    new_result[index].isAccordion = false;

                                                                                    this.setState({ ...this.state, searchResult: new_result })
                                                                                }}
                                                                            >
                                                                                Ocultar Resposta
                                                                            </Button>
                                                                        </Box>
                                                                    </Grid>
                                                                </Box>
                                                            :
                                                                ''
                                                        }
                                                    </>
                                                ))
                                            :
                                                <>
                                                    <Box className={ classes.box_noresult }>
                                                        <InfoOutlinedIcon style={{ marginBottom: 12, color: "#c7c7c7" }} />
                                                        <Typography className={{ fontSize: 16, lineHeight: "24px", color: "#c7c7c7" }}>Não foi encontrado nenhum resultado</Typography>
                                                    </Box>

                                                    { this.state.suggestedQuestions.length
                                                        ?
                                                            <>
                                                                <Typography className={ classes.subtitle }>Talvez isso possa te ajudar</Typography>
                                                            </>
                                                        :
                                                            ''
                                                    }

                                                    {
                                                        this.state.suggestedQuestions.length && this.state.suggestedQuestions.map((question, index) => (
                                                            <>
                                                                <Box className={ question.isAccordion ? classes.question_card_active : classes.question_card }>
                                                                    <Typography style={{ fontWeight: 400, fontSize: 16 }}>{ question.title }</Typography>
                                                                    <Button
                                                                        size="small"
                                                                        startIcon={ question.isAccordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                                                        className={ question.isAccordion ? classes.close_answer : classes.open_answer }
                                                                        onClick={ e => {
                                                                            var new_result = this.state.suggestedQuestions;
                                                                            new_result[index].isAccordion = !new_result[index].isAccordion;

                                                                            this.setState({ ...this.state, suggestedQuestions: new_result });
                                                                        }}
                                                                    >
                                                                        { question.isAccordion ? 'Ocultar Resposta' : 'Ver Resposta' }
                                                                    </Button>
                                                                </Box>
                                                                { question.isAccordion
                                                                    ?
                                                                        <Box className={ classes.answer_card }>
                                                                            <Grid item md={12}>
                                                                                <Box dangerouslySetInnerHTML={{__html: question.description}} />
                                                                                <Box style={{ textAlign: "right" }}>
                                                                                    <Button
                                                                                        size="small"
                                                                                        startIcon={<KeyboardArrowUpIcon />}
                                                                                        className={ classes.open_answer }
                                                                                        onClick={ e => {
                                                                                            var new_result = this.state.suggestedQuestions;
                                                                                            new_result[index].isAccordion = false;

                                                                                            this.setState({ ...this.state, suggestedQuestions: new_result })
                                                                                        }}
                                                                                    >
                                                                                        Ocultar Resposta
                                                                                    </Button>
                                                                                </Box>
                                                                            </Grid>
                                                                        </Box>
                                                                    :
                                                                        ''
                                                                }
                                                            </>
                                                        ))
                                                    }
            
                                                    <Typography className={ classes.subtitle }>Ou entre em contato conosco</Typography>
                                                    <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                                        { this.state.contacts && this.state.contacts.map((row, index) => (
                                                            <Box className={ classes.box }>
                                                                { row.type == "phone" ? <PhoneIcon style={{ width: 16, marginRight: 8 }} /> : <MailIcon style={{ width: 16, marginRight: 8 }} /> }
                                                                <Typography style={{ fontWeight: 500, fontSize: 14 }}>{ row.name }</Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </>

                                        }
                                    </Grid>
                                :
                                    <Grid className={ classes.box_full }>
                                        { this.state.isEmpty
                                            ?
                                                <Grid className={ classes.box_empty }>
                                                    <InfoOutlinedIcon style={{ marginBottom: 12, color: "#c7c7c7" }} />
                                                    <Typography style={{ fontSize: 16, lineHeight: "24px", color: "#c7c7c7" }}>Selecione ou busque por palavras chaves<br />para achar o que procura</Typography>
                                                </Grid>
                                            :
                                                <>
                                                    { this.state.questions
                                                        ?
                                                            <>
                                                                { this.state.questions.map((question, indexQuestion) => {
                                                                    return (
                                                                        <>
                                                                            { question
                                                                                ?
                                                                                    <>
                                                                                        <Box className={ question.isAccordion ? classes.question_card_active : classes.question_card }>
                                                                                            <Typography style={{ fontWeight: 400, fontSize: 16 }}>{ question.title }</Typography>
                                                                                            <Button
                                                                                                size="small"
                                                                                                startIcon={ question.isAccordion ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                                                                                                className={ question.isAccordion ? classes.close_answer : classes.open_answer }
                                                                                                onClick={ e => {
                                                                                                    if (!question.isAccordion) {
                                                                                                        this.insertViewQuestion(question.id);
                                                                                                    }

                                                                                                    var new_question = this.state.questions;
                                                                                                    new_question[indexQuestion].isAccordion = !new_question[indexQuestion].isAccordion;

                                                                                                    this.setState({ ...this.state, questions: new_question })
                                                                                                }}
                                                                                            >
                                                                                                { question.isAccordion ? 'Ocultar Resposta' : 'Ver Resposta' }
                                                                                            </Button>
                                                                                        </Box>
                                                                                        { question.isAccordion
                                                                                            ?
                                                                                                <Box className={ classes.answer_card }>
                                                                                                    <Grid item md={12}>
                                                                                                        <Box dangerouslySetInnerHTML={{__html: question.description}} />

                                                                                                        {/* Simulação de vídeo
                                                                                                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                                                                            <video width="320" height="180" controls>
                                                                                                                <source src={colorbars} type="video/mp4" />
                                                                                                            </video>
                                                                                                            <Button
                                                                                                                size="small"
                                                                                                                style={{ marginTop: 16, marginBottom: 16, color: theme.palette.primary.light, textTransform: "inherit" }}
                                                                                                                onClick={ e => { this.setState({...this.state, modalVideo: true}); }}
                                                                                                            >
                                                                                                                Ampliar vídeo
                                                                                                            </Button>

                                                                                                            <Dialog
                                                                                                                onClose={ e => { this.setState({...this.state, modalVideo: false}); }}
                                                                                                                open={ this.state.modalVideo }
                                                                                                                className={ "modalContainer" }
                                                                                                                style={{ height: 450, width: 800 }}
                                                                                                            >
                                                                                                                <video width="800" height="450" controls>
                                                                                                                    <source src={colorbars} type="video/mp4" />
                                                                                                                </video>

                                                                                                                <IconButton
                                                                                                                    style={{ position: "absolute", top: 0, right: 0, color: "#ffffff" }}
                                                                                                                    onClick={e => { this.setState({...this.state, modalVideo: false}); }}
                                                                                                                >
                                                                                                                    <CloseIcon />
                                                                                                                </IconButton>
                                                                                                            </Dialog>
                                                                                                        </Box>
                                                                                                        */}

                                                                                                        <Box style={{ textAlign: "right" }}>
                                                                                                            <Button
                                                                                                                size="small"
                                                                                                                startIcon={<KeyboardArrowUpIcon />}
                                                                                                                className={ classes.open_answer }
                                                                                                                onClick={ e => {
                                                                                                                    var new_question = this.state.questions;
                                                                                                                    new_question[indexQuestion].isAccordion = false;

                                                                                                                    this.setState({ ...this.state, questions: new_question })
                                                                                                                }}
                                                                                                            >
                                                                                                                Ocultar Resposta
                                                                                                            </Button>
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Box>
                                                                                            :
                                                                                                ''
                                                                                        }
                                                                                    </>
                                                                                :
                                                                                    ''
                                                                            }
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        :
                                                            <>
                                                                vazio
                                                            </>
                                                    }
                                                </>
                                        }
                                    </Grid> 
                            }
                        </Grid>
                    </Grid>
                </Container>
            </>
        )
    }
}

export default withStyles(styles)(withRouter(FaqUser));