import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    box: {
      background: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      alignSelf: "flex-start",
      height: 400,
      overflow: "scroll",
      padding: 15
    },
    backDrop: {
      zIndex: 3000,
      color: "#FFF",
    },
});