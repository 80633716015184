import React from 'react';

export const ThemeContext = React.createContext({});

export const ThemeProvider = (props) => {
    
    const [theme, setTheme] = React.useState({
        sidebar: true,
        additionalData: {}
    })

    return (
        <ThemeContext.Provider value={{theme, setTheme}}>
            { props.children }
        </ThemeContext.Provider>
    )
}

export const useTheme = () => React.useContext(ThemeContext);