import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { PatientContext, usePatient } from "../../../../../../context/usePatient";
import { getPatientBySimpleFilter } from "../../../../../../api/patient";

import {
  validaCPF,
  validaCNS,
} from "../../../../../../helpers/validacoes";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 20px",
    paddingRight: 24,
    backgroundColor: "#ffffff"
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  colorIcon: {
    color: `${theme.palette.primary.light} !important`
  }
}));

export function SolicitacaoFiltro() {
  const classes = useStyles();

  const { healthPatients, setHealthPatients, setIsList, setLoadingHealthPatients, setPaginate, searchText, setSearchText, setFirstFilter} = usePatient();
  const [search, setSearch] = useState("");
  const [cpf, setCPF] = useState("");
  const [dataForResearch, setDataForResearch] = useState("");

  const handleKeypress = (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    setLoadingHealthPatients(false);
  }, [healthPatients]);

  const handleSubmit = () => {
    
    setLoadingHealthPatients(true);
    setFirstFilter(true);

    getPatientBySimpleFilter(localStorage.getItem('token'), searchText).then(res => {

          let paginate = {
            current_page: 1,
            last_page: 1,
            per_page: 0,
            total: 0
          }

          if(res.status) {
            if(res.patient && Array.isArray(res.patient)) {
              if(res.patient.length){
                paginate = res.paginate;
                setIsList(true);
                setHealthPatients(res.patient);
              } else {                
                setIsList(false);
                setHealthPatients(res.patient);
              }
            } else {
              setIsList(false);
              setHealthPatients([]);
            }
          } else {
            setIsList(false);
            setHealthPatients([]);
          }

          setPaginate(paginate)
      }).catch(err => {
        console.error(err);
        
        setLoadingHealthPatients(false);
      })
    
  };

  useEffect(() => {
    setSearchText(dataForResearch)
  }, [dataForResearch])
  
  
  const validationSearch = (search) => {

    if(search.length === 0) {
      setDataForResearch("")
    } else {

      const regCPF = /[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/;
      const resultCPF = search.match(regCPF);
      
      if(validaCNS(search)){
        setDataForResearch(search)
      } else if (resultCPF) {
        setDataForResearch(search)
      } else {
        setDataForResearch(search)
      }
    }

   
  };

  useEffect(() => {
    validationSearch(search);
  }, [search]);

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
    >
      <OutlinedInput
        id="outlined-adornment-password"
        type="text"
        placeholder="Buscar por nome, ID, CNS ou CPF"
        onChange={(e) => setSearch(e.target.value)}
        onKeyPress={handleKeypress}
        classes = {{
          root: classes.root
        }}
        inputProps={{
          maxlength: 100
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"              
              onClick={ handleSubmit }
              // onMouseDown={(e) => console.log("")}
              edge="end"
              style={{ padding: 0 }}
            >
              <SearchIcon className={classes.colorIcon}/>
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
