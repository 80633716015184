import { React, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography, FormControl, FormHelperText } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
import DButton from '../../../../shared/DButton';
import DInput from '../../../../shared/DInput';
import DSwitch from "../../../../shared/DSwitch";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: 16,
        marginBottom: 24,
        position: "relative"
    },

    card_title: {
        color: theme.palette.neutral.black,
        fontSize: 16,
        margin: "0 0 16px"
    },

    card_subtitle: {
        color: theme.palette.neutral.black,
        fontSize: 14,
        margin: "0 0 16px"
    },

    card_delete: {
        position: "absolute",
        top: 2,
        right: 8,
        color: theme.palette.support.error,
        cursor: "pointer"
    },

    card_history: {
        position: "absolute",
        top: 2,
        right: 8,
        color: theme.palette.primary.light
    },

    card_switch_container: {
        display: "flex",
        alignItems: "center",
        margin: "16px 0 0 -12px",
        height: 30
    },

    card_outline: {
        padding: 16,
        marginBottom: 24,
        border: `1px solid ${theme.palette.neutral.midgrey}`,
        borderRadius: 4,
        fontSize: 18
    },
    edit_input: {
        backgroundColor: theme.palette.background.snow,
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    }
}));

function NovoCampo(props)
{
    const classes = useStyles();

    const [campos, setCampos] = useState(props.campos);

    const removerCampo = (index) => {
        let new_campos = [...campos];
        new_campos.splice(index, 1);
        setCampos(new_campos);
        props.onChange(new_campos);
    }

    useEffect(() => {
        setCampos(props.campos);
    }, [props.campos])

    return(
        <Grid container>
            { campos.map((campo, index) => {

                return <Grid item key={ `campo-${index}` } md={12}>
                            <Card className={ classes.card }>
                                { index > 0 && <IconButton onClick={ e => removerCampo(index) } className= { classes.card_delete }><DeleteIcon style={{ fontSize: 22 }} /></IconButton> }                    
                                <h5 className={ classes.card_title }>Campo { index + 1 }</h5>
                                <Card className={ classes.card }>
                                    <DInput
                                        id="nome"
                                        label="Nome do campo"
                                        placeholder="Nome do campo"
                                        type="text"
                                        error={ props.error[index] && !campo.name }
                                        helperText={ props.error[index] && !campo.name ? props.error[index] : "" }
                                        fullWidth
                                        value={ campo.name }
                                        onChange={ e => {
                                            
                                            let new_campos = [...campos];
                                            new_campos[index].name = e.target.value;
                                            setCampos(new_campos);
                                            props.onChange(new_campos);
                                        } }
                                    />
                                    <div className={ classes.card_switch_container }>
                                        <DSwitch checked={ campo.showName } onChange={ e => {
                                            let new_campos = [...campos];
                                            new_campos[index].showName = !campo.showName;
                                            setCampos(new_campos);
                                            props.onChange(new_campos);
                                        } } name="show_name" />
                                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>Mostrar nome do campo</Typography>
                                    </div>
                                </Card>
                                <Card className={ classes.card }>
                                    <FormControl fullWidth error={ props.error[index] && !campo.type } className={ props.error[index] && !campo.type ? 'form-control-error' : '' }>
                                        <InputLabel id="select-type-label">Selecione o tipo de campo</InputLabel>
                                        <Select
                                            labelId="select-type-label"
                                            id="select-type"
                                            value={campo.type}
                                            label=""
                                            onChange={ e => {
                                                let new_campos = [...campos];
                                                new_campos[index].type = e.target.value;

                                                //Zera as opções em caso de campos que não precisam de opções
                                                if(e.target.value !== 'lista' && e.target.value !== 'opcoes') {
                                                    new_campos[index].multiple = false;
                                                    new_campos[index].options = [];
                                                }

                                                setCampos(new_campos);
                                                props.onChange(new_campos);
                                            } }
                                            style={{ width: "100%" }}>
                                            <MenuItem value="numero">Número</MenuItem>
                                            <MenuItem value="lista">Lista</MenuItem>
                                            <MenuItem value="opcoes">Opções</MenuItem>
                                            {/* <MenuItem value="texto">Texto</MenuItem> */}
                                            <MenuItem value="verdadeiro_falso">Verdadeiro/Falso</MenuItem>
                                        </Select>
                                        { (props.error[index] && !campo.type) && <FormHelperText className="text-error">{ props.error[index] }</FormHelperText> }
                                    </FormControl>
                                    
                                    <Grid container style={{ marginTop: 8 }}>
                                        { campo.type === "numero" && <>
                                            <Grid item sm={8}>
                                                <div className={ classes.card_switch_container }>
                                                    <DSwitch checked={ campo.showUnit } onChange={ e => {
                                                        let new_campos = [...campos];
                                                        new_campos[index].showUnit = !campo.showUnit;
                                                        setCampos(new_campos);
                                                        props.onChange(new_campos);
                                                    } } name="show_unit" />
                                                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>Incluir unidade de medida</Typography>
                                                </div>
                                            </Grid>                            
                                            { campo.showUnit &&
                                                <Grid item sm={4}>
                                                    <DInput
                                                        id="nome"
                                                        label="Unidade de medida"
                                                        placeholder=""
                                                        type="text"
                                                        helperText=""
                                                        fullWidth
                                                        value={ campo.unit }
                                                        onChange={ e => {
                                                            let new_campos = [...campos];
                                                            new_campos[index].unit = e.target.value;
                                                            setCampos(new_campos);
                                                            props.onChange(new_campos);
                                                        } }
                                                    />
                                                </Grid>                            
                                            }
                                        </> }
                                        { (campo.type === "lista" || campo.type === "opcoes") && <>
                                            <Grid item md={12}>
                                                <div className={ classes.card_switch_container }>
                                                    <DSwitch checked={ campo.multiple } onChange={ e => {
                                                        let new_campos = [...campos];
                                                        new_campos[index].multiple = !campo.multiple;
                                                        setCampos(new_campos);
                                                        props.onChange(new_campos);
                                                    } } name="multipla_escolha" />
                                                    <Typography style={{ fontSize: 14, fontWeight: 500 }}>Múltipla escolha</Typography>
                                                </div>                                
                                                <Grid container style={{ marginTop: 24 }} spacing={1}>
                                                    <Grid item md={12}>
                                                        <Grid container>
                                                            { campo.options.map((option, indexOption) => {
                                                                return <Grid key={ `option-${index}-${indexOption}` } item md={12}>
                                                                    <DInput
                                                                        label={ `Opção ${indexOption + 1}` }
                                                                        placeholder=""
                                                                        type="text"
                                                                        helperText=""
                                                                        fullWidth
                                                                        onChange={e => {
                                                                            let new_campos = [...campos];
                                                                            let new_options = [...new_campos[index].options]
                                                                            new_options[indexOption] = {
                                                                                id: e.target.value,
                                                                                value: e.target.value
                                                                            }
                                                                            new_campos[index].options = new_options;
                                                                            setCampos(new_campos);
                                                                            props.onChange(new_campos);
                                                                        }}
                                                                        value={ option.value }/>
                                                                </Grid>
                                                            }) }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item md={12}>
                                                        <DButton size="sm" onClick={ e => {
                                                            let new_campos = [...campos];
                                                            new_campos[index].options = [...new_campos[index].options, { id: null, value: "" }];
                                                            setCampos(new_campos);
                                                            props.onChange(new_campos);
                                                        } } variant="ghost" icon={ <AddCircle /> }>
                                                            Adicionar nova opção
                                                        </DButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </> }                                        
                                    </Grid>
                                </Card>
                            </Card>
                        </Grid>
            }) }
            <Grid item md={12}>
                <DButton size="sm" onClick={ e => {
                    let new_campos = [...campos, {
                        id: null,
                        type: null,
                        name: null,
                        showName: false,
                        unit: null,
                        showUnit: false,
                        multiple: false,
                        options: []
                    }];
                    setCampos(new_campos);
                    props.onChange(new_campos);
                } } variant="secondary" icon={ <AddCircle /> }>
                    Adicionar campo 3
                </DButton>
            </Grid>
        </Grid>
    )
}

NovoCampo.defaultProps = {
    error: [],
    campos: [
        {
            id: null,
            type: null,
            name: null,
            showName: false,
            unit: null,
            showUnit: false,
            multiple: false,
            options: [],
            values: []
        }
    ],
    onChange: function (campos) {
        console.log(campos)
    }
}

export default NovoCampo;