import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Grid,
  Typography,
  useTheme
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import DButton from "../../../shared/DButton";
import PrintIcon from '@material-ui/icons/Print';
import Smoking from "./components/Smoking";
import Alcoholic from "./components/Alcoholic";
import Drugs from "./components/Drugs";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 627,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: 0,
    borderRadius: 8,
    padding: "4px 16px 0 16px"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  headermodal: {
    height: 44,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  gridButton: {
    height: 65,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 16,
  },
  button: {
    margin: theme.spacing(1),
  },
  gridQuestion: {
    flexWrap: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerModalSuccess: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    margin: 20,
  },
  toggleButtonGroup: {
    display: "block !important",
  },
  toggleButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: `1px solid ${theme.palette.neutral.midgrey} !important`,
    background: theme.palette.background.snow,
    color: theme.palette.neutral.black,
    borderRadius: "18px !important",
    height: 25,
    width: "auto",
    "&$selected": {
      color: `${theme.palette.primary.light} !important`,
      background: `${theme.palette.primary.light} !important`,
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
    "&:hover": {
      color: `${theme.palette.primary.light} !important`,
      background: `${theme.palette.primary.light} !important`,
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    width: "5ch",
    color: theme.palette.neutral.black,
  },
  textHighlight: {
    background: theme.palette.background.cloud,
    borderRadius: "4px",
    padding: "6px 12px"
  },
  socialHabitsText: {
    color: theme.palette.neutral.black,
    marginBottom: 9
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  }  
}));

export default function MdPreConsult(props) {
  const classes = useStyles();
  const theme = useTheme()
  const [modalStyle] = useState(getModalStyle);
  const [preForm, setPreForm] = React.useState(null);
  const [symptoms, setSymptoms] = useState([]);

  useEffect(() => {
    if(!props.anamnesePreForm) return;

    var _byDays = {};


    setSymptoms(props.anamnesePreForm.anamnese_pre_symptom.map(x => {
      return {
        id: x.id,
        name: x.symptom ? x.symptom.description : "-",
        days: parseInt(x.days)
      }
    }))

    props.anamnesePreForm.anamnese_pre_symptom.map(item => {      

      if(!_byDays[item.days]) _byDays[item.days] = [];

      _byDays[item.days].push(item);

      return item.symptom;
    });
    
    props.anamnesePreForm.symptomsByDays = _byDays;

    setPreForm(props.anamnesePreForm);

    return () => {
      setPreForm(null);
    }
  }, [props.anamnesePreForm])

  const bodySpecialty = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography style={{ color: theme.palette.primary.light, fontWeight: 700, fontSize: 16, lineHeight: "24px" }}>Pré-consulta</Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              props.onClose(true);
            }}
          />
        </div>
        <Grid container>
          <>
            <Grid item xs={12}>
				<Grid container style={{marginBottom: 24}}>
					<Grid item xs={12}>
						<Typography className="text-primary" style={{marginBottom: 16}} variant="h6" compoenent="h2">Sintomas</Typography>
					</Grid>
          
          { symptoms && Array.isArray(symptoms) && symptoms.length > 0 ? 
            <>
              { symptoms.map((x, i) => {
                return (
                  <Grid item xs={12} key={i}>
                    <Grid container spacing={2} alignItems="center" style={{marginBottom: ((x.length - 1) == i) ? 0 : 16 }} >
                      <Grid item>
                        <Typography variant="body1" className="text-bold text-st">{ x.days } Dias: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" className={`text-regular text-st ${classes.textHighlight}`}>{ x.name }</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }) }            
            </> 
          : <Typography className="text-regular text-st" variant="body1">Não informado</Typography> }

				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container style={{marginBottom: 24}}>
					<Grid item xs={12}>
              			<Typography className="text-primary" style={{marginBottom: 8}} variant="h6" compoenent="h2">Comorbidades</Typography>
					</Grid>

          { preForm && preForm.anamnese_pre_comorbidity && Array.isArray(preForm.anamnese_pre_comorbidity) && preForm.anamnese_pre_comorbidity.length > 0 ? 
            <>
                { preForm && preForm.anamnese_pre_comorbidity ?
                  <>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                    {
                      preForm.anamnese_pre_comorbidity.map((item, index) => {
                      return  <Grid item key={index}>
                            <Typography variant="body1" className={`text-regular text-st ${classes.textHighlight}`}>{item.comorbidity ? item.comorbidity.description : ""}</Typography>
                          </Grid>
                      })
                    }
                    </Grid>
                  </Grid>
                  </>    
                  :
                  ""                
                }
                        
            </> 
          : <Typography className="text-regular text-st" variant="body1">Não informado</Typography> }

				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Typography className="text-primary" style={{marginBottom: 8}} variant="h6" compoenent="h2">Hábitos sociais</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container style={{marginBottom: 8}}>
							<Grid item>
								<Typography variant="body1" className="text-bold text-st">Atividade física:&nbsp;</Typography>
							</Grid>
							<Grid item>
								<Typography variant="body1" className="text-regular text-st">{preForm && preForm.physical_activity || "Não informado"}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container style={{marginBottom: 8}}>
							<Grid item>
								<Typography variant="body1" className="text-bold text-st">Tabagismo:&nbsp;</Typography>
							</Grid>
							<Grid item>
								<Smoking preForm={preForm} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container style={{marginBottom: 8}}>
							<Grid item>
								<Typography variant="body1" className="text-bold text-st">Uso de bebida alcoólica:&nbsp;</Typography>
							</Grid>
							<Grid item>
								<Alcoholic preForm={preForm} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item>
								<Typography variant="body1" className="text-bold text-st">Uso de drogas ilícitas:&nbsp;</Typography>
							</Grid>
							<Grid item>
								<Drugs preForm={preForm} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
            </Grid>
            <Grid item xs={12} className={classes.gridButton}>
              <DButton
                variant="secondary"
                color="primary"
                size="sm"
                className={classes.button}
                onClick={props.onClose}>
                Voltar
              </DButton>
              <DButton onClick={e => props.openPdf()} variant="primary" size="sm" className={classes.button} icon={<PrintIcon fontSize="small" />}>
                Criar PDF
              </DButton>
            </Grid>
          </>
        </Grid>
      </>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {bodySpecialty}
      </Modal>
    </div>
  );
}
