import React, { createContext, useEffect, useState, useCallback } from "react";
import { Device } from "@twilio/voice-sdk";
import { getPatientById } from "../api/patient";
import { createType } from "../api/healthAttendanceType";
import { createHealthAttendance } from "../api/healthAttendance";
import {
  anamneseFormAnswer,
  anamneseFormHistory,
  createAnamnese,
} from "../api/anamnese";
import {
  assumirAtendimento as atender,
  abdicarAtendimento as abdicar,
} from "../api/health_attendances";
import { toast } from "react-toastify";

import {
  initializePrescription,
  finishPrescription as finishPrescriptionById,
  getPrescriptions,
  getVoipToken,
  finishPrescriptionDocument,
} from "../api/user";

export const AtendimentoContext = createContext({});

export default function AtendimentoContextProvider({ children }) {
  const [atendimentoStatus, setAtendimentoStatus] = useState(0);
  const [attendancesList, setAttendancesList] = useState(null);
  const [readOnlyAnamneseForm, setReadOnlyAnamneseForm] = useState(false);

  // 0 - Início - Não iniciado/assumido
  // 1 - Atendimento assumido mas não em ligação
  // 2 - Atendimento assumido e em ligação com paciente

  const [device, setDevice] = useState(null);
  const [callData, setCallData] = useState(null);
  const [atendimentoInfo, setAtendimentoInfo] = useState(null);
  const [isMuted, setIsMuted] = useState(false);

  const [patientInfo, setPatientInfo] = useState(null);
  const [anamneseInfo, setAnamneseInfo] = useState(null);
  const [startAttendanceInfo, setStartAttendanceInfo] = useState(null);

  const [attendance, setAttendance] = useState(null);

  const [dadosAnamnese, setDadosAnamnese] = useState(null);

  const [callInfo, setCallInfo] = useState({
    patientData: null, // {all data from patient}
    patientSocialName: null, // Fulano
    patientPhone: null, // 21999999999
    patientPhoneMask: null, // (99) 99999-9999
  });

  const [emAtendimento, setEmAtendimento] = useState(false);
  const [newAttendances, setNewAttendances] = useState(null);

  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [newPrescription, setNewPrescription] = useState(null);

  const [patientPrescriptions, setPatientPrescriptions] = useState(null);

  const [atenderScreen, setAtenderScreen] = useState(false);

  const [prescriptionsPageUpdate, setPrescriptionsPageUpdate] = useState(false);

  const [AttendanceFilter, setAttendanceFilter] = useState({
    status: {
      todos: false,
      assumido: false,
      aberto: false,
      finalizado: false,
    },
    quadro: {
      todos: false,
      leve: false,
      moderado: false,
      grave: false,
      nao_indicativo: false,
    },
    linhaDeCuidado: {
      todos: false,
      hiv: false,
      has: false,
      avc: false,
      tea: false,
    },
    period: {
      from: null,
      to: null,
    },
  });

  useEffect(() => {
    // Get patient

    

    if (attendance) {
      getPrescriptions(localStorage.getItem("token"), attendance.id)
        .then((data) => {
          if (data.status == true) {
            //setPatientPrescriptions(data.details.prescriptionItens);

            let prescriptionsArr = data.details.prescriptions.flatMap(
              (item) => {
                return item.prescriptions_type.map((itemType) => {
                  console.log("itemType", itemType);
                  return itemType;
                });
              }
            );

            if (prescriptionsArr.length > 0) {
              setPatientPrescriptions(prescriptionsArr);
            } else {
              setPatientPrescriptions(null);
            }
          } else {
            setPatientPrescriptions(null);
          }
        })
        .catch((err) => {
          setPatientPrescriptions(null);
        });

      setAnamneseInfo(attendance.anamnese);

      getPatientById(localStorage.getItem("token"), attendance.patient.id)
        .then((data) => {
          if (data.status) {
            setPatientInfo(data.details.patient);

            const patientInfoData = data.details.patient;

            if (patientInfoData) {
              const contato = patientInfoData.patient_contact[0];

              const phoneNumber = String(contato.phone).replace(/[^0-9]/g, "");

              setCallInfo({
                ...callInfo,
                patientPhone: phoneNumber,
                patientPhoneMask: contato.phone,
                patientSocialName: contato.name,
                patientData: data.details.patient,
              });
            }
          }
        })
        .catch((err) => console.error(err));

      if (
        attendance.user_id === localStorage.getItem("uid") &&
        attendance.health_attendance_status_id === 1
      ) {
        setEmAtendimento(true);
        setAtendimentoStatus(1);
      }

    }
  }, [attendance]);

  useEffect(() => {
    if (atendimentoStatus === 0) {
      setEmAtendimento(false);
    } else if (atendimentoStatus === 1 || atendimentoStatus === 2) {
      setEmAtendimento(true);
    }
  }, [atendimentoStatus]);

  function getPatientPrescriptions() {
    if (!attendance || (attendance && !attendance.id)) return;

    setPrescriptionsPageUpdate(!prescriptionsPageUpdate);

    getPrescriptions(localStorage.getItem("token"), attendance.id)
      .then((data) => {
        if (data.status == true) {
          //setPatientPrescriptions(data.details.prescriptionItens);

          let prescriptionsArr = data.details.prescriptions.flatMap((item) => {
            return item.prescriptions_type.map((itemType) => {
              console.log("itemType", itemType);
              return itemType;
            });
          });

          if (prescriptionsArr.length > 0) {
            setPatientPrescriptions(prescriptionsArr);
          } else {
            setPatientPrescriptions(null);
          }
        } else {
          setPatientPrescriptions(null);
        }
      })
      .catch((err) => {
        setPatientPrescriptions(null);
      });
  }

  async function startVoipCall(phoneNumber) {
    try {
      const data = await getVoipToken(localStorage.getItem("token"));

      if (data && data.status) {
        if (!data.token) throw new Error("Token empty");

        const device = new Device(data.token);

        const params = { To: "+55" + phoneNumber };

        let call = await device.connect({ params });

        setCallData(call);
        setDevice(device);
        setEmAtendimento(true);

        call.on("ringing", () => console.log("Ringing..."));

        call.on("accepted", () => console.log("Call accepted"));
        call.on("incoming", (conn) => console.log("incoming", conn));

        call.on("disconnected", () => console.log("Call disconnected"));

        call.on("connect", () => console.log("Call connected"));

        device.on("incoming", (conn) => console.log("incoming", conn));

        device.on("ringing", (conn) => console.log("ringing", conn));

        device.on("connection", () => {
          console.log("connection");
        });

        device.on("error", (error) => {
          console.error("Twilio device error:", error);
        });

        device.on("connect", (conn) => {
          console.log("Call success");
        });

        device.on("disconnect", (conn) => {
          console.log("Call ended");
        });

        return { device, call };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function mute() {
    if (callData) {
      isMuted ? callData.mute(false) : callData.mute(true);

      setIsMuted(!isMuted);
    }
  }

  async function hangUpCall(device) {
    if (device) {
      device.disconnectAll();
      device.destroy();

      if (callData) {
        callData.disconnect();
      }
    }
  }

  function assumirAtendimento() {
    atender(localStorage.getItem("token"), attendance.id).then((res) => {
      if (res.status) {
        setEmAtendimento(true);
        setAtendimentoStatus(1);
        toast.success(
          res.message ? res.message : "Operação realizado com sucesso."
        );
      } else {
        toast.error(res.message ? res.message : "Erro inesperado.");
      }
    });
  }

  async function abdicarAtendimento() {
    try {
      let new_attendance = { ...attendance };
      new_attendance.user_id = null;
      new_attendance.health_attendance_status_id = 1;
      setAttendance(new_attendance);

      abdicar(localStorage.getItem("token"), attendance.id).then((res) => {
        if (res.status) {
          setAtendimentoStatus(0);
          toast.success(
            res.message ? res.message : "Operação realizado com sucesso."
          );
        } else {
          toast.error(res.message ? res.message : "Erro inesperado.");
        }
      });

      if (anamneseInfo) {
        await anamneseFormAnswer(
          localStorage.getItem("token"),
          anamneseInfo.id,
          {
            health_attendance_id: atendimentoInfo.id,
            discharge_id: startAttendanceInfo.discharge_id,
            data: dadosAnamnese,
          }
        );

        // const _dataHistory = await anamneseFormHistory(localStorage.getItem("token"), anamneseInfo.id, dadosAnamnese)
      }
    } catch (error) {
      console.error(error);
    }
  }

  function startPrescription() {
    if (!attendance || !attendance.id)
      return "Informações de atendimento necessárias.";

    initializePrescription(localStorage.getItem("token"), attendance.id)
      .then((data) => {

        if(!data) {
          return toast.error("Não foi possível iniciar a prescrição.");
        }

        if (data.error) {
          throw new Error(data.error);
        }

        if(!data.details)
          return toast.error("Não foi possível iniciar a prescrição.");

        const { id, statusPrescription, url } = data.details;

        console.log("*** Nova prescrição");

        setNewPrescription({
          id,
          statusPrescription,
          url,
        });

        const _getPrescriptionEnd = (event) => {

          console.log("_getPrescriptionEnd");
          console.log(event);

          if (event.data.type === "prescricao") {
        
            console.log("*** Listener prescription received", id);

            finishPrescription(event.data, id);

            setPrescriptionModal(false);
            setNewPrescription(null);

            window.removeEventListener("message", _getPrescriptionEnd);
        
          } else if (event.data.type === "cancel") {

              setPrescriptionModal(false);
              setNewPrescription(null);

              window.removeEventListener("message", _getPrescriptionEnd);

          } else if (event.data.type === "excluded") {
            
              setPrescriptionModal(false);
              setNewPrescription(null); 

          }
        
        };

        window.addEventListener("message", _getPrescriptionEnd, false);

        if (data.error) {
          throw new Error(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Não foi possível abrir a prescrição. Verifique se o seu documento profissional foi salvo na opção Meu Perfil."
        );
        console.error(err);
      });
  }

  const finishPrescription = async (data, prescriptionId) => {
    setPrescriptionModal(false);
    setNewPrescription(null);

    let prescriptionMedicamentos = data.Medicamentos;
    let prescriptionExames = data.Exames;
    let prescriptionAtestados = data.Atestado;
    let prescriptionDocuments = data.Documentos;

    try {
      if (prescriptionMedicamentos) {
        let data = prescriptionMedicamentos.map((item) => {
          return {
            name: item.Nome || null,
            label: item.Label || null,
            dosage: item.Posologia || null,
            via: item.ViaAdministracao || null,
          };
        });

        if (data && data.length > 0) {
          var medicamento = data[0];

          if (medicamento.name) {
            let _medicamentosSend = {
              type: "MEDICAMENTOS",
              prescriptionsItens: data,
            }
      
            let response_data = await finishPrescriptionById(
              localStorage.getItem("token"),
              prescriptionId,
              _medicamentosSend
            );

            console.log("*** Envio: Medicamentos", response_data);
          }
        }
      }

      if (prescriptionExames) {
        let data = prescriptionExames.map((item) => {
          return {
            name: item.Nome || "",
            label: item.Label || "",
            dosage: null,
            via: null,
          };
        });

          let _examesSend = {
            type: "EXAME",
            prescriptionsItens: data,
          }
    
          let response_data = await finishPrescriptionById(
            localStorage.getItem("token"),
            prescriptionId,
            _examesSend
          )

          console.log("*** Envio: Exames", response_data);
      }

      if (prescriptionAtestados) {
        let _atestadosSend = {
          type: "ATESTADO",
          prescriptionsItens: [
            {
              name: prescriptionAtestados.Observacao || null,
              label: prescriptionAtestados.Texto || null,
              dosage: null,
              via: null,
            }],
          }
          
    
          let response_data = await finishPrescriptionById(
            localStorage.getItem("token"),
            prescriptionId,
            _atestadosSend
          )
  
          console.log("*** Envio: Atestados", response_data);
      }
  
      if(prescriptionDocuments) {
        for await(const document of prescriptionDocuments) {

          if (document && document.TipoDocumento && document.URL) {
            var _document = {
              type: document.TipoDocumento,
              documnetsPrescription: [
                {
                  url: document.URL
                }
              ]
            }
            
            let data = await finishPrescriptionDocument(localStorage.getItem("token"), prescriptionId, _document);
            
            console.log(`*** [Envio - ${prescriptionId}]: Documentos`, data);
          }
      }
    }
      console.log("***", "Get Patient Prescription");

      getPatientPrescriptions();

      setPrescriptionsPageUpdate(!prescriptionsPageUpdate);
    } catch (err) {
      console.log(err);
    }
  };


    return (
        <AtendimentoContext.Provider value={{
            emAtendimento,
            setEmAtendimento,
            startVoipCall,
            hangUpCall,
            atendimentoStatus,
            setAtendimentoStatus,
            device,
            setDevice,
            callInfo,
            setCallInfo,
            mute,
            setIsMuted,
            isMuted,
            patientInfo,
            setPatientInfo,
            atendimentoInfo,
            setAtendimentoInfo,
            prescriptionModal,
            setPrescriptionModal,
            patientPrescriptions,
            setPatientPrescriptions,
            abdicarAtendimento,
            startPrescription,
            setDadosAnamnese,
            dadosAnamnese,
            setAttendance,
            assumirAtendimento,
            attendance,
            atenderScreen,
            setAtenderScreen,
            setAttendanceFilter,
            AttendanceFilter,
            setNewAttendances,
            newAttendances,
            setAttendancesList,
            attendancesList,
            prescriptionsPageUpdate,
            newPrescription,
            setNewPrescription,
            getPatientPrescriptions,
            setReadOnlyAnamneseForm,
            readOnlyAnamneseForm,
        }}>
            {children}
        </AtendimentoContext.Provider>
    )

}
