import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, CircularProgress, Backdrop } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DetailsMain from "../../../Paciente/components/PacienteDetails/components/DetailsMain/DetailsMain";
import { PatientContext, usePatient } from "../../../../context/usePatient";
import { PatientProvider } from "./../../../../context/PatientContext";
import { useAnamneseFormFilter } from "../../../../context/AnamneseFormFilterContext";
import PreAtendimento from "./components/PreAtendimento/PreAtendimento";
import { useEffect, useState } from "react";
import { getPatientById } from "../../../../api/patient";
import PacientePregnant from "./../../../Paciente/components/PacientePregnant/PacientePregnant";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.background.snow,
  },
  gHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "16px !important",
    fontSize: 16,
    color: theme.palette.primary.light,
  },
  gHeaderPatient: {
    alignItems: "center",
    paddingBottom: "16px !important",
    fontSize: 16,
    color: theme.palette.primary.light,
  },
  gHeader2: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingBottom: "16px !important",
    fontSize: 16,
    color: theme.palette.primary.light
  },
  pTag: {
    fontSize: 14,
    color: theme.palette.neutral.black,
    backgroundColor: theme.palette.background.cloud,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    marginLeft: 8,
    whiteSpace: "normal",
    height: '39px',
    "&>span ": {
      whiteSpace: "normal",
  }
  },
  addButton: {
    color: theme.palette.neutral.white,
    backgroundColor: theme.palette.primary.light,
  },
  card: {
    width: "100%",
    backgroundColor: theme.palette.background.cloud
  },
  blackColor: {
    color: theme.palette.neutral.black
  }
}));

function SolicitacoesPatient(props) {
  const classes = useStyles();

  const {
    patientData,
    userDominio,
    unidadesRef,
    unidadesVinculadas,
  } = usePatient(PatientContext);

  const { resetFilter } = useAnamneseFormFilter();

  const [timer, setTimer] = useState(false);
  const [pregnant, setPregnant] = useState(false);
  const [groups, setGroups] = useState([])
  const [lineCares, setLineCares] = useState([])

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };
  
  useEffect(function(){
    if(patientData.patient) {
      getPatientById(localStorage.getItem("token"), patientData.patient.id)
      .then((data) => {
        console.log(data)
        if (data.hasOwnProperty("status")) {
          if (data.status) {            
            if(data.details.patient && data.details.patient.patient_has_group && data.details.patient.patient_has_group.length > 0) {
              
              setGroups(data.details.patient.patient_has_group.filter(res => res.group !== null).map(function(item){
                return {
                  id: item.group_id,
                  value: item.group ? item.group.name : "-"
                }
              }))
            }
            if (data.details.patient && data.details.patient.patient_line_care && data.details.patient.patient_line_care.length > 0) {
              
                setLineCares(data.details.patient.patient_line_care.filter(function (item) {
                  return item.line_care !== null && item.line_care.active === 1;
                }).map(function (item) {
                    return {
                      id: item.line_care_id,
                      value: item.line_care ? item.line_care.name : "-"
                    } 
                }))
  
            }

          }
        }
      })
      .catch((err) => console.error(err))
    }
  }, [patientData])

  useEffect(() => {

    resetFilter();

    const timer = setTimeout(() => setTimer(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const getPatientChipName = (pdata) => {
    if (pdata && pdata.patient) 
    {
      let name = `${pdata.patient.social_name || pdata.patient.full_name} ${pdata.patient.pronoun ? pdata.patient.pronoun.name : ''}`;
      return name;
    }
    else
    {
      return ''
    }
  }

  return (
    <>
      { pregnant 
        ?
          <PacientePregnant listar={ e => setPregnant(false) } paciente={patientData} />
        :
        <Grid className={classes.bg}>
          <Container style={{ marginTop: 76 }}>
            <Grid container spacing={2}>
              <Grid
                item
                className={classes.gHeader}
                style={{ paddingTop: 18 }}
                md={7}
              >
                Paciente:

                <Chip
                  label={getPatientChipName(patientData)}
                  size="small"
                  deleteIcon={<CancelIcon className={classes.blackColor} />}
                  onDelete={handleDelete}
                  classes={{ root: classes.pTag }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item className={classes.gHeaderPatient} md={7}>
                {userDominio && userDominio.status && timer ? (
                  <Card className={classes.card}>
                    <CardContent>
                      {Object.keys(patientData).length > 0 ? (
                        <PatientProvider>
                          <DetailsMain
                            editPatientDetail
                            userDominios={userDominio}
                            unidadesRef={unidadesRef}
                            unidadesVinculadas={unidadesVinculadas}
                            paciente={patientData}
                            onPregnant={ e => setPregnant(true) }
                            onUpdated={ dados => {
                              if(dados.type === 'line-care') {
                                setLineCares(dados.dados)
                              }else{
                                setGroups(dados.dados)
                              }
                            } }
                          />
                        </PatientProvider>
                      ) : (
                        "Não foi encontrado os dados do Paciente"
                      )}
                    </CardContent>
                  </Card>
                ) : (
                  <Backdrop
                    style={{ zIndex: 3000, color: "white" }}
                    open={timer === false}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </Grid>
              <Grid item className={""} md={5}>
                <PreAtendimento groups={ groups } lineCares={ lineCares } onHealthAttendance={ e => props.onHealthAttendance(true) } sideBar={ false } />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      }
    </>
  );
}

export default SolicitacoesPatient;
