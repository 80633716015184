import { useContext, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import TabPanel from "./components/TabPanel";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import DadosPaciente from "./components/DadosPaciente";
import "./styles.scss";

import { Prescricao as PrescricaoModal } from "../modais/";
import SimpleModal from "../../shared/Modal";
import Anamnese from "./components/Anamnese";
import IniciarAtendimento from "./components/Anamnese/iniciarAtendimento";
import Atendimentos from "../components/Atendimentos";
import Prescricoes from "../components/Prescricoes";
import FloatingChat from "../components/floatingChat";
import { AtendimentoContext } from "../../../context/AtendimentoContext";
import { HealthAttendanceProvider } from "../../../context/HealthAttendanceContext";
import Videocall from "./components/Videocall";

import { AtendimentoCMD, Fidi, Prescricao } from "../modais/";
import { Chat } from "@material-ui/icons";
import { AnamnesePreFormByHealthAttendanceId } from "../../../api/anamnesePreForm";
import { toast } from "react-toastify";

import DetailsMain from "../../Paciente/components/PacienteDetails/components/DetailsMain/DetailsMain.jsx";
import { usePatient } from "../../../context/usePatient";
import VisualizarExame from "../../AtendimentoImediato/components/VisualizadorExames";
import { getPatientById } from "../../../api/patient";
import { getMe } from "../../../api/user";

import NovaBiomedida from "../../shared/NovaBiomedida/NovaBiomedida";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#fff",
    width: "calc(100% + 120px)",
  },
  paciente__topo: {
    position: "absolute",
    left: 0,
    width: "100%",
    height: 61,
    display: "flex",
    padding: "10px 40px",
    background: "#fff",
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 18,
  },
  paciente__tabs: {
    marginTop: 93,
    backgroundColor: "#FFF!important",
  },
  paciente__nome: {
    height: 20,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    fontWeight: 600,
  },
  paciente__button: {
    backgroundColor: "green !important",
  },
  MuiTabsflexContainer: {
    color: theme.palette.primary.main,
    boxShadow: "none",
    // background: "white"
  },
  MuiTabroot: {
    textTransform: "none",
    backgroundColor: "#FFFFFF",
    marginLeft: 4,
    marginRight: 4,
    fontWeight: 700,
    fontSize: "16px",
    minWidth: 150,
  },
  MuiTabtextColorInherit: {
    opacity: 1,
  },
  Muiselected: {
    backgroundColor: "#F9F9F9",
    borderRadius: "8px 8px 0 0",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    border: 0,
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.main
  },
  box: {
    background: "#F9F9F9",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "8px",
    marginTop: 141,
    height: "calc(100vh - 378px)",
    overflow: "auto",
  },
  boxMain: {
    background: "#F9F9F9",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "0 0 8px 8px",
    marginLeft: 4,
    height: "calc(100vh - 250px)",
    overflow: "auto",
  },

  gridSection: {
    alignSelf: "flex-start",
  },

  modalItem: {
    margin: "10px",
  },

  container: {
    height: "calc(100vh - 150px)",
    // backgroundColor: "white",
    // maxWidth: "calc(100% - 20%)!important"
  },
}));

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-${index}`,
  };
}

function PacienteDetails(props) {
  const {
    emAtendimento,
    setEmAtendimento,
    setAtendimentoStatus,
    assumirAtendimento,
    setAttendance,
  } = useContext(AtendimentoContext);
  const { setIdPatient } = usePatient();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [modalFidi, setModalFidi] = useState(false);
  const [modalAtendimentoCmd, setModalAtendimentoCmd] = useState(false);
  const [patientAttendanceData, setPatientAttendanceData] = useState(null);
  const [urlPdf, setUrlPdf] = useState(null);
  const [videoCall, setVideoCall] = useState(false);
  const [paciente, setPaciente] = useState(props.paciente);

  const [openBiomedida, setOpenBiomedida] = useState(false);
  const [updateListBiomedida, setUpdateListBiomedida] = useState(true);
  const [updateBiomedida, setUpdateBiomedida] = useState(null);

  const [profile, setProfile] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (props.atendimento) {
      AnamnesePreFormByHealthAttendanceId(
        localStorage.getItem("token"),
        props.atendimento.id
      )
        .then((data) => {
          setPatientAttendanceData(data[0]);
        })
        .catch((err) => console.error(err));

      setAttendance(props.atendimento);
    }

    if (props.paciente && props.paciente.patient) {
      setIdPatient(props.paciente.patient.id);
    }

    getMe(localStorage.getItem('token')).then(response => {
      if(response.token_details)
          setProfile(response.token_details.acl.profile_id);
    })
  }, []);

  useEffect(() => {
    if(!updateListBiomedida)
      setUpdateListBiomedida(true)
  }, [updateListBiomedida])

  const getLatestPatientInfo = patientId => {
    getPatientById(localStorage.getItem("token"), patientId)
      .then(data => {
        if(data.status){
           setPaciente(data.details);
        }
      })
      .catch(err => console.error(err));
  }

  function getPDF(url) {
    setUrlPdf(url);
  }

  return (
    <>
      { openBiomedida && <NovaBiomedida 
          profile={ profile }
          biomedida={ updateBiomedida }
          healthAttendanceId={ props.atendimento.id } 
          open={ openBiomedida } 
          onSaved={ e => {
            setUpdateListBiomedida(!updateListBiomedida);
            setOpenBiomedida(false);
          } }
          onClose={ e => {
            setUpdateBiomedida(null);
            setOpenBiomedida(false);
          } } /> }
      
      {!videoCall ? (
        <>
          {props.atendimento.interconsultation === 1 && (
            <FloatingChat
              idAtendimento={props.atendimento.id}
              idUser={props.user}
            />
          )}

          <PrescricaoModal />

          <Sidebar
            idAtendimento={props.atendimento.id}
            idPatient={
              props.paciente && props.paciente.patient
                ? props.paciente.patient.id
                : ""
            }
          />

          <Container className={classes.container}>
            <AtendimentoCMD
              open={modalAtendimentoCmd}
              setModal={(_) => setModalAtendimentoCmd(!modalAtendimentoCmd)}
            />

            <Fidi open={modalFidi} setModal={(_) => setModalFidi(!modalFidi)} />

            <SimpleModal
              fullPage
              open={urlPdf}
              onClose={(e) => setUrlPdf(false)}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "88%" }}
              >
                <Grid
                  item
                  className="text-center"
                  style={{ height: "100%", width: "100%" }}
                >
                  {<VisualizarExame getIframe urlPdf={urlPdf} />}
                </Grid>
              </Grid>
            </SimpleModal>
            <HealthAttendanceProvider>
              <Grid className={classes.bg} style={{ paddingTop: 2 }}>
                <Header
                  back={(e) => props.listar()}
                  videoCall={(e) => setVideoCall(true)}
                />

                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  id="atendimento-container"
                >
                  <Grid item md={6} className={classes.gridSection}>
                    <AppBar
                      className={classes.paciente__tabs}
                      color="default"
                      position="static"
                      elevation={0}
                    >
                      <Tabs
                        classes={{
                          flexContainer: classes.MuiTabsflexContainer,
                        }}
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      >
                        <Tab
                          classes={{
                            root: classes.MuiTabroot,
                            textColorInherit: classes.MuiTabtextColorInherit,
                            selected: classes.Muiselected,
                          }}
                          label="Dados Clínicos"
                          {...a11yProps(0)}
                          onClick={x => setPaciente(null)}
                        />
                        <Tab
                          classes={{
                            root: classes.MuiTabroot,
                            textColorInherit: classes.MuiTabtextColorInherit,
                            selected: classes.Muiselected,
                          }}
                          label="Dados do paciente"
                          onClick={x => props.paciente && props.paciente.patient && getLatestPatientInfo(props.paciente.patient.id)}
                          {...a11yProps(1)}
                        />
                        <Tab
                          classes={{
                            root: classes.MuiTabroot,
                            textColorInherit: classes.MuiTabtextColorInherit,
                            selected: classes.Muiselected,
                          }}
                          label="Atendimento"
                          {...a11yProps(2)}
                          onClick={x => setPaciente(null)}
                        />
                        <Tab
                          classes={{
                            root: classes.MuiTabroot,
                            textColorInherit: classes.MuiTabtextColorInherit,
                            selected: classes.Muiselected,
                          }}
                          label="Prescrições"
                          
                          {...a11yProps(3)}
                        />
                      </Tabs>
                    </AppBar>
                    <TabPanel
                      value={value}
                      className={classes.boxMain}
                      index={0}
                    >  

                      { updateListBiomedida &&
                          <DadosPaciente
                            back={(e) => props.listar()}
                            toast={toast}
                            patientData={patientAttendanceData}
                            anamnesePreForm={patientAttendanceData}
                            onNewBioMedida={ e => setOpenBiomedida(true) }
                            onEditBiomedida={ biomedida => {
                              setUpdateBiomedida(biomedida);
                              setOpenBiomedida(true);
                            } }
                            toggleModal={(e) => setModalFidi(!modalFidi)}
                            attendance={props.atendimento}
                          />                      
                      }

                    </TabPanel>
                    <TabPanel
                      value={value}
                      className={classes.boxMain}
                      index={1}
                    >
                      {
                        paciente &&
                        props.userDominios &&
                        props.unidadesRef &&
                        props.unidadesVinculadas &&
                          <DetailsMain
                            userDominios={props.userDominios}
                            unidadesRef={props.unidadesRef}
                            unidadesVinculadas={props.unidadesVinculadas}
                            paciente={paciente}                            
                            patientUpdated={x => getLatestPatientInfo(paciente.patient.id)}
                          />
                      }
                      
                    </TabPanel>
                    <TabPanel
                      value={value}
                      className={classes.boxMain}
                      index={2}
                    >
                      <Atendimentos
                        idPaciente={
                          props.paciente.patient
                            ? props.paciente.patient.id
                            : null
                        }
                        detalhes={(e) => setModalAtendimentoCmd(true)}
                      />
                    </TabPanel>
                    <TabPanel
                      value={value}
                      className={classes.boxMain}
                      index={3}
                    >
                      <Prescricoes
                        idPaciente={
                          props.paciente.patient
                            ? props.paciente.patient.id
                            : null
                        }
                        detalhes={(e) => getPDF(e)}
                      />
                    </TabPanel>
                  </Grid>

                  <Grid item md={6} className={classes.gridSection}>
                    {emAtendimento ? (
                      <Anamnese
                        toast={toast}
                        returnListHealthAttendance={(e) => props.listar(true)}
                        idAtendimento={props.atendimento.id}
                      />
                    ) : (
                      <IniciarAtendimento
                        iniciarAtendimento={(e) => {
                          assumirAtendimento();
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </HealthAttendanceProvider>
          </Container>
        </>
      ) : (
        <Videocall
          idAtendimento={props.atendimento.id}
          paciente={props.paciente}
          attendance={props.atendimento}
          idUser={props.user}
          userDominios={props.userDominios}
          unidadesRef={props.unidadesRef}
          unidadesVinculadas={props.unidadesVinculadas}
          patientData={patientAttendanceData}
          toggleModal={(e) => setModalFidi(!modalFidi)}
          getPDF={(e) => getPDF(e)}
          setModalAtendimentoCmd={(e) => props.setModalAtendimentoCmd(true)}
          onClose={(e) => setVideoCall(false)}
        />
      )}
    </>
  );
}

export default PacienteDetails;
