import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Container, Card, CardContent as CardContentCore, Grid, AppBar, Toolbar, IconButton, CircularProgress, Backdrop, DialogActions, DialogContent, Dialog, DialogTitle, InputBase } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {CheckCircleOutline, Close, ContactsOutlined, Create, Delete, FormatListBulletedSharp, RemoveOutlined, Save} from "@material-ui/icons";
import DButton from "./../../../../shared/DButton";
import DInput from "./../../../../shared/DInput";
import { useContionals } from '../../../../../context/ConditionalsContext';
import Construtor from '../../../../../api/construtor';
import CardView from './componentgrid/CardView';
import CardEdit from './componentgrid/CardEdit';
import CardCreate from './componentgrid/CardCreate';

import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    card: {
      marginBottom: 8,
    },
    cardContent: {
        paddingBottom: '5px !important'
    },    
    contentRemove: {
        width: 267,
        height: 65,
        padding: '0px 24px'
    },
    titleRemove: {
        borderBottom: 'none'
    },
    buttonBack: {
        minWidth: 74,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px'
    },
    buttonConfirm: {
        minWidth: 145,
        height: 33,
        borderRadius: 100,
        fontSize: 14,
        padding: '6px 18px'
    }
}));

function FormsConditionGrid(props)
{
    const classes = useStyles()

    const {setConditionals, setFields, setForms, setLoading} = useContionals();
    const [conditionalsId, setConditionalsId] = useState(undefined);
    

    useEffect(function(){

        setConditionals(undefined);
        setFields([]);
        setForms([]);
        setLoading(false)

        if(conditionalsId !== undefined) {

            setLoading(true);

            toast.warn("Carregando condições do formulário, aguarde...");

            Construtor.showConditional(localStorage.getItem('token'), conditionalsId).then(res => {
                if(res.status) {

                    if(res.conditionals && res.conditionals.form_conditionals_condition && Array.isArray(res.conditionals.form_conditionals_condition)){
                        res.conditionals.form_conditionals_condition = res.conditionals.form_conditionals_condition.map(condition => {

                            condition.contain = condition.contain.split(";");
    
                            return condition;
                        })
                    }

                    setConditionals(res.conditionals)
                    
                    Construtor.showByForm(localStorage.getItem('token'), res.conditionals.form_id).then(res => {
                        if(res.status) {

                            if(res.form && res.form.fields.length == 0)
                                toast.warn("É necessário que o formulário tenha questões para continuar.")

                            setFields(res.form.fields.map(function(item, index){
                                return {id: item.reference_id, value: item.label, additional_data: JSON.parse(item.additional_data)}
                            }))                        
                            Construtor.getFullList(localStorage.getItem('token'), 1).then(res => {
                                if(res.status)
                                    setForms(res.anamnese_forms.map(function(item, index){
                                        return {id: item.form_id, value: item.name}
                                    }))          
                                    
                                setLoading(false);
                            })
                        }
                    })
                }
            });
        }

    }, [conditionalsId])    

    

    function CardContent(props)
    {
        const [edit, setEdit] = useState(false);
        const [conditional, setConditional] = useState(props.conditional)

        props.conditionals.map(function(item, index) {
            if (item.id == conditional.id) {
                props.conditionals[index].name = conditional.name;
            }
        });
        
        return (
            <>
                { !edit 
                    ? 
                        <CardView onCondition={ e => {
                            if(conditionalsId === props.conditional.id){
                                setConditionalsId(undefined)
                            } else {
                                setConditionalsId(props.conditional.id)
                            }
                        } } conditionalId={ conditionalsId } onDeleted={ e => props.onDeleted(true) } onEdit={ setEdit } conditional={ conditional } /> 
                    : 
                        <CardEdit conditional={ { conditional, setConditional, setEdit } } />}
            </>
        )
    }

    return (
        <>
            { props.create && <CardCreate onClose={ e => props.onCloseCreate(true) } onCreated={ e => props.onCreated(true) } formId={ props.formId } /> }
            { props.conditionals.map((item, index) => <CardContent key={`condition-grid-key-${item.id}`} onDeleted={ e => props.onDeleted(true) } conditional={ item } conditionals={ props.conditionals } /> ) }
            { props.conditionals && props.conditionals.length === 0 && <Card>
                <CardContentCore>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <ErrorOutlineIcon size={15} className="text-disabled" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className="text-regular text-st text-disabled" align="center">Ainda não há condicionais cadastradas </Typography>
                        </Grid>
                    </Grid>
                </CardContentCore>
            </Card> }
        </>
    )
}

export default FormsConditionGrid;