import React from 'react';
import "../index.scss";
import { TextField, Backdrop,
  CircularProgress,
  Typography, } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create, Save } from '@material-ui/icons';
import UnidadesView from "./UnidadesView";
import { useEffect } from 'react';
import { useState } from 'react';
import { getAllUnidades } from '../../../../api/unidades';
import UnidadesEdit from './UnidadesEdit';
import UnidadesVinculadasView from './UnidadesVinculadasView';
import UnidadesVinculadasEdit from './UnidadesVinculadasEdit';
import useError from '../../../../hooks/useError';
import { toast } from 'react-toastify';
import { useNewHealthAttendance } from '../../../../context/NewHealthAttendance';
import { updateHasMedicalUnit, updatePatient, updatePatientFull } from '../../../../api/patient';


export default function UnidadeNewTab(props) {
  const { patient } = useNewHealthAttendance();
  const [edit, setEdit] = useState(false);
  const [unidadesVinculadas, setUnidadesVinculadas] = useState(null);
  const [unidadesRef, setUnidadesRef] = useState(null);

  const [unidadeRef, setUnidadeRef] = useState();
  const [unidadesVinculadasSelected, setUnidadesVinculadasSelected] = useState();

  useEffect(() => {
    getAllUnidades(localStorage.getItem("token")).then((res) => {
      var unidadesRef = [];
      var unidadesVinculadas = [];

      res.length &&
        res.map((item) => {
          if (item.below_mother_medical_unit == "no") {
            unidadesRef.push(item);
          } else {
            unidadesVinculadas.push(item);
          }
        });

      setUnidadesVinculadas(unidadesVinculadas);
      setUnidadesRef(unidadesRef);
    }).catch((err) => console.log(err))
  }, []);

  function getDados() {
    var dadosPost = { patient: {}, patientHasMedicalUnit: []}

    dadosPost.patient.biological_sex = patient.biological_sex
    dadosPost.patient.birth_date = patient.birth_date
    dadosPost.patient.birth_year = patient.birth_year
    dadosPost.patient.breed_id = patient.breed_id
    dadosPost.patient.email = patient.email
    dadosPost.patient.full_name =patient.full_name
    dadosPost.patient.sexual_orientation_id = patient.sexual_orientation_id
    dadosPost.patient.nationality = patient.nationality
    dadosPost.patient.incomplete_data = patient.incomplete_data
    dadosPost.patient.incomplete_data_reason = patient.incomplete_data_reason
    dadosPost.patient.medical_unit_id = patient.medical_unit_id || null
    dadosPost.patientHasMedicalUnit = null

    return dadosPost;
  }

  const handleUpdate = async () => {

    var patient_id = patient && patient.id ? patient.id : null;
    var payloadEnvio = getDados();
    
    if(unidadeRef){

      payloadEnvio.patient.medical_unit_id = unidadeRef || null;
   
      var response = await updatePatient(localStorage.getItem('token'), payloadEnvio.patient, patient_id);    
      
      if(response.status) {
        toast.success("Unidade de referência atualizada com sucesso.");
      } else {
          toast.error("Erro ao atualizar Unidade de referência.");
      }
    } else {
        toast.warn("1Preencha todos os campos obrigatórios.");
    } 

    if(unidadesVinculadasSelected && unidadesVinculadasSelected.length > 0){
      var patientHasMedicalUnit = []; //unidade_vinculada
      if (
        unidadesVinculadasSelected &&
        Array.isArray(unidadesVinculadasSelected)
      ) {
        unidadesVinculadasSelected.forEach((unidade) => {
          patientHasMedicalUnit.push({ medical_unit_id: unidade.id });
        });
      }
      
      payloadEnvio.patientHasMedicalUnit = patientHasMedicalUnit

      var response = await updatePatientFull(localStorage.getItem('token'), payloadEnvio, patient_id );  
      if(response.status) {
        toast.success("Unidades vinculadas atualizada com sucesso.");
      } else {
          toast.error("Erro ao atualizar Unidades Vinculadas.");
      }
    } else {
        toast.warn("2Preencha todos os campos obrigatórios.");
    } 
}

  return (
    <div className='fundo_new_tab'>
      <div className='main_flex_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Unidades
        </div>
        <div className='flex_end_new_tab'>
        { !edit ? 
            <Button className="botao_editar_new_tab text-primary" onClick={ e => setEdit(true) } startIcon={<Create />}>
              Editar
            </Button> :
            <Button className="botao_editar_new_tab text-primary" onClick={ handleUpdate } startIcon={<Save />}>
              Salvar
            </Button> }  
        </div>
      </div>
      
      <div className='mt_35_new_tab separa_inputs_new_tabs'>
        <Grid item md={4}>
        {unidadesRef ? ( 
          <>
            { !edit ? <UnidadesView unidadesRef={unidadesRef} unidadesVinculadas={unidadesVinculadas}></UnidadesView> 
            : <UnidadesEdit  unidadesRef={unidadesRef} unidadesVinculadas={unidadesVinculadas} onChange={ (unidadeRef) => {
              setUnidadeRef(unidadeRef);
          } }/> }
            
            
          </>
          ) : (
            <Backdrop
              style={{ zIndex: 3000, color: "white", textAlign: "center" }}
              open={!(unidadesRef)}
            >
              <Grid container>
                <Grid item md={12}>
                  <CircularProgress color="inherit" />
                </Grid>
                <Grid item md={12}>
                  <Typography variant="body1" className="text-regular text-st">
                    Carregando unidade de referência...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
          )}
          
        </Grid>
      </div>
      <div className='main_flex_new_tab mt_35_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Unidades vinculadas
        </div>
      </div>
      <div className='mt_35_new_tab'>
        <div className='main_div_tag_paciente_tab'>
          { !edit ? <UnidadesVinculadasView unidadesRef={unidadesRef} unidadesVinculadas={unidadesVinculadas} /> 
                  : <UnidadesVinculadasEdit  unidadesRef={unidadesRef} unidadesVinculadas={unidadesVinculadas} onChange={ (unidadesVinculadasSelected) => {
                    setUnidadesVinculadasSelected(unidadesVinculadasSelected);
                } }/> }
        </div>
      </div>
    </div>
  )
}