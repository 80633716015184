/* eslint-disable no-use-before-define */
import { Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@material-ui/core";
import DInput from "../../../shared/DInput";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import 'date-fns';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DButton from "../../../shared/DButton";
import useError from "../../../../hooks/useError";
import { required, requiredEmail, requiredTwoName, requiredOptionalTwoName, requiredConselho, CNSValido, validaRG, validaCEP, validaCPF, validaEmail, removesSpecialCharactersInUppercaseLowercase, requiredPassaporte, haveBadWords} from "../../../../helpers/validacoes";
import conselhosCode from "./../../../../assets/dados/conselhos.json";
import racasCode from "./../../../../assets/dados/racas.json";
import generoCode from "./../../../../assets/dados/genero.json";
import orgaoEmissorCode from "./../../../../assets/dados/orgaoEmissor.json";
import sexoCode from "./../../../../assets/dados/sexo.json";
import conselhosDeMedico from "../../../../assets/dados/conselhosDeMedico.json"
import tipoTelefoneCode from "./../../../../assets/dados/tipoTelefone.json";
import logradouroCode from "./../../../../assets/dados/logradouro.json";
import PasswordInstrucions from "../../../Recover/components/FormRecover/components/PasswordInstrucions";
import IDInstructions from "../../../Recover/components/FormRecover/components/IDInstructions";
import {DeleteForever, Visibility, VisibilityOff} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import DAutoComplete from "../../../shared/DAutoComplete";
import DDropDownSimple from "../../../shared/DDropDownSimple/DDropDownSimple";
import {getCPF, setCPF as setCustomCPF, mascaraRG, mascaraNumero, mascaraCEP, nameRegex, mascaraCPF, strCapitalize} from "../../../../helpers/formatacao";
import {getCep2} from "../../../../helpers/apis";
import {isBissexto} from "../../../../helpers/validacoes";
import {retira_acentos} from "../../../../helpers/formatacao";
import { listCityByStateId } from "../../../../api/dominios";
import { checkPatientDocument, checkUserDocument } from "./../../../../api/document";
import "./styles.scss";
import DMultAutoComplete from "../../../shared/DMultAutoComplete";
import DDateInput from "../../../shared/DDateInput";
import { ptBR } from "date-fns/locale";
import FormCadastroConselhos from "./components/FormCadastroConselhos";
import orientacoesSexuaisCode from "./../../../../assets/dados/orientacoesSexuais.json";
import { toast } from "react-toastify";
import { check } from "prettier";
import RemoverTelefone from "../../../MeuPerfil/components/modalRemoverTelefone";
import DAutoCompleteControlled from "../../../shared/DAutoCompleteControlled/DAutoCompleteControlled";
import {useAppTheme} from '../../../../context/useThemeContext';
import { checkIsValidUserId } from "../../../../api/auth";
import useDebounce from "../../../../hooks/useDebounce"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '90%',
        margin: '0 auto'
    },
    marginDefault: {
        marginTop: 15,
        marginBottom: 15
    },
    linha: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 0,
        marginBottom: 32
    },
    maxWidth: {
        width: '100%',
        maxWidth: '100%'
    },
    hide: {
        display: 'none'
    },
    actionButton: {
        padding: '6px 18px 6px 18px',
        height: '50px',
        width: '225px',
        gap: '6px',
        borderRadius: '100px'
    }
}));

function FormCadastro(props) {

    const classes = useStyles();

    const [cpf, setCPF] = useState("");
    const [cpfValido, setCpfValido] = useState(true);
    const [email, setEmail] = useState("");
    const [nome, setNome] = useState("");
    const [nacionalidade, setNacionalidade] = useState(localStorage.getItem('nacionalidade'));
    const [nomeSocial, setNomeSocial] = useState(localStorage.getItem('nomeSocial'));
    const [identidadeNumero, setIdentidadeNumero] = useState(localStorage.getItem('identidadeNumero'));
    const [identidadeEmissao, setIdentidadeEmissao] = useState(localStorage.getItem('identidadeEmissao') ? localStorage.getItem('identidadeEmissao') : null);
    const [identidadeEmissaoValida, setIdentidadeEmissaoValida] = useState(true);
    const [nomeMae, setNomeMae] = useState(localStorage.getItem('nomeMae'));
    const [maeDesconhecida, setMaeDesconhecida] = useState(localStorage.getItem('maeDesconhecida') && localStorage.getItem('maeDesconhecida') == "true" ? true : false);
    const [identidadeOrgaoEmissor, setIdentidadeOrgaoEmissor] = useState(localStorage.getItem('identidadeOrgaoEmissor'));
    const [identidadeUF, setIdentidadeUF] = useState(localStorage.getItem('identidadeUF'));
    const [dataNascimento, setDataNascimento] = useState(localStorage.getItem('dataNascimento') ? localStorage.getItem('dataNascimento') : null);
    const [dataNascimentoValida, setDataNascimentoValida]         = useState(true);
    const [dataNaturalizacao, setDataNaturalizacao] = useState(localStorage.getItem('dataNaturalizacao') ? localStorage.getItem('dataNaturalizacao') : null);
    const [dataNaturalizacaoValida, setDataNaturalizacaoValida] = useState(true);
    const [paisNascimento, setPaisNascimento] = useState(localStorage.getItem('paisNascimento'));
    const [cns, setCNS] = useState(localStorage.getItem('cns'));
    const [passaporteNumero, setPassaporteNumero] = useState(localStorage.getItem('passaporteNumero'));
    const [passaportePaisEmissor, setPassaportePaisEmissor] = useState(localStorage.getItem('passaportePaisEmissor'));
    const [passaporteDataEmissao, setPassaporteDataEmissao] = useState( localStorage.getItem('passaporteDataEmissao') ? localStorage.getItem('passaporteDataEmissao') : null);
    const [passaporteDataEmissaoValida, setPassaporteDataEmissaoValida]   = useState(true);
    const [passaporteDataValidade, setPassaporteDataValidade] = useState(localStorage.getItem('passaporteDataValidade') ? localStorage.getItem('passaporteDataValidade') : null);
    const [passaporteDataValidadeValida, setPassaporteDataValidadeValida] = useState(true);
    const [passaportePaisNascimento, setPassaportePaisNascimento] = useState(localStorage.getItem('passaportePaisNascimento'));
    const [cep, setCep] = useState(localStorage.getItem('cep'));
    const [logradouro, setLogradouro] = useState(localStorage.getItem('logradouro'));
    const [logradouroName, setLogradouroName] = useState(localStorage.getItem('logradouroName'));
    const [endereco, setEndereco] = useState(localStorage.getItem('endereco'));
    const [numero, setNumero] = useState(localStorage.getItem('numero'));
    const [complemento, setComplemento] = useState(localStorage.getItem('complemento'));
    const [bairro, setBairro] = useState(localStorage.getItem('bairro'));
    const [municipio, setMunicipio] = useState(localStorage.getItem('municipio'));
    const [municipioId, setMunicipioId] = useState(localStorage.getItem('municipioid'));
    const [estado, setEstado] = useState(localStorage.getItem('estado'));
    const [pais, setPais] = useState(localStorage.getItem('pais'));
    const [estadoNascimento, setEstadoNascimento] = useState(localStorage.getItem('estadoNascimento'));
    const [municipioNascimento, setMunicipioNascimento] = useState(localStorage.getItem('municipioNascimento') ? JSON.parse(localStorage.getItem('municipioNascimento')) : null);
    const [sexo, setSexo] = useState(localStorage.getItem('sexo'));
    const [genero, setGenero] = useState(localStorage.getItem('genero'));
    const [orientacaoSexual, setOrientacaoSexual]  = useState(localStorage.getItem('orientacaoSexual') ? localStorage.getItem('orientacaoSexual') : null);
    const [raca, setRaca] = useState(localStorage.getItem('raca'));
    const [etnia, setEtnia] = useState(localStorage.getItem('etnia'));
    const [telefones, setTelefones] = useState(localStorage.getItem('telefones') ? JSON.parse(localStorage.getItem('telefones')) : [{ tipoTelefone: '', telefone: '' }]);
    const [removerTelefone, setRemoverTelefone] = useState(null);
    const [qtdTelefones, setQtdTelefones] = useState(localStorage.getItem('qtdTelefones') ? localStorage.getItem('qtdTelefones').split(',') : [1]);
    const [conselhos, setConselhos] = useState(localStorage.getItem('conselhos') ? JSON.parse(localStorage.getItem('conselhos')) : []) // localStorage.getItem('conselhos') && JSON.parse(localStorage.getItem('conselhos'))
    const [especialidade, setEspecialidade] = useState( localStorage.getItem('especialidade') ? localStorage.getItem('especialidade') :[]);
    const [apresentacao, setApresentacao] = useState(localStorage.getItem('apresentacao'));
    const [senha, setSenha] = useState('');
    const [confirmSenha, setConfirmSenha] = useState('');
    const [id, setId] = useState('');
    const [confirmId, setConfirmId] = useState('');
    const [isValidUserId, setIsValidUserId] = useState(null);
    const [senhasIguais, setSenhasIguais] = useState(true);
    const [visibility2, setVisibility2] = useState(false);
    const [visibility1, setVisibility1] = useState(false);
    const [visibilityId1, setVisibilityId1] = useState(false);
    const [visibilityId2, setVisibilityId2] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [citys, setCitys] = useState([]);
    const [birthCitys, setBirthCitys] = useState([]);
    const [conselhosObrigatorios, setConselhosObrigatorios] = useState([]);
    const [isMedico, setIsMedico] = useState(localStorage.getItem('isMedico') ? localStorage.getItem('isMedico') : false);
    
    const [isReadOnly, setIsReadOnly] = useState(false);

    const [peloMenosUmConselho, setPeloMenosUmConselho] = useState(true);
    
    // Dominios data
    const [_cbos, _setCbos] = useState(null);

    const [checkMinCaractere, setChekMinCaractere] = useState(false);
    const [checkCaixa, setCheckCaixa] = useState(false);
    const [checkNumero, setCheckNumero] = useState(false);
    const [checkCaractere, setCheckCaractere] = useState(false);

    // ID Validação
    const [checkIdDontHaveUppercase, setCheckIdDontHaveUppercase] = useState(false);
    const [checkIdDontExceedsCharacters, setCheckIdDontExceedsCharacters] = useState(false);
    const [checkIdDontHaveSpaces, setCheckIdDontHaveSpaces] = useState(false);
    const [checkIdDontHaveOffensiveWords, setCheckIdDontHaveOffensiveWords] = useState(false);
    const [checkIdDontHaveSpecialCharacter, setCheckIdDontHaveSpecialCharacter] = useState(false);

    const [propsErrorInput, setpropsErrorInput] = useState(false);
    const [errorCel, setErrorCel] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);

    const debouncedId = useDebounce(id, 500);

    const [step0, setStep0] = useState(true);
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);
    const [step5, setStep5] = useState(false);
    const [step6, setStep6] = useState(false);
    const [step7, setStep7] = useState(false);
    const [step8, setStep8] = useState(false);
    const [step9, setStep9] = useState(false);

    useEffect(() => { // Valida inserção de CBO médico para campo especialidades.
        if(conselhos && Array.isArray(conselhos)){
            var _medicoConselho = [];
            conselhos.map(conselho => {
                if(conselho.descricao){ // desc id
                    const _conselhoId = conselho.descricao;

                    if(_cbos && Array.isArray(_cbos)){
                        // const _selectedConselho = _cbos.find(x => x.code === _conselhoId);

                        if(conselhosDeMedico.includes(_conselhoId)){
                            _medicoConselho.push(true);
                        } else {
                            _medicoConselho.push(false);

                        }                        
                    }
                }
            })

            if(_medicoConselho.find(x => x)){ // quando existe CBO médico
                setStep8(false);
                setIsMedico(true);

                localStorage.setItem('isMedico', true);
            } else {
                setStep8(true);
                setIsMedico(false);

                localStorage.setItem('isMedico', false);
            }
        }
    }, [conselhos]);

    useEffect(() => {
        if(props.invite){
            const invite = Array.isArray(props.invite) ? props.invite[0] : props.invite;

            setCPF(getCPF(invite.cpf));

            setNome(invite.description);

            setEmail(invite.email);

            const userInviteProfiles = invite.user_invite_profile;

            if(userInviteProfiles && Array.isArray(userInviteProfiles)){

                let obrigatorios = [];

                userInviteProfiles.forEach(profile => {
                    if(profile.profile){
                        if(profile.profile.profile_documents){
                            profile.profile.profile_documents.forEach(doc => {
                                obrigatorios.push(doc);
                            });
                        }
                    }
                });
                
                setConselhosObrigatorios(obrigatorios);
            }
        }

    }, []);

    function checkSenhasIguais(_senha, _confirmSenha) {

        if (_senha != "" && _confirmSenha != "") {

            if (_senha !== _confirmSenha) {
                setSenhasIguais(false);
                setErrors({...error, confirm_senha: "Senhas diferentes."});
            } else {
                setSenhasIguais(true);
                delete error.confirm_senha;
            }

        } else {
            setSenhasIguais(true);
        }

    }

    function validaCpf() {

        if (!cpfValido) {
            return "CPF inválido.";
        } else {
            return undefined;
        }
    
    }

    function handleValidaCpf(value) {

        let checkValidaCpf = validaCPF(value);

        if (checkValidaCpf !== undefined) {
            setErrors({...error, cpf: "CPF inválido."});
            setCpfValido(false);
        } else {
            delete error.cpf;
            handleDocumentUser(value);
        }

    }

    function handleDocumentUser(value) {

        const params = new URLSearchParams(window.location.search);

        checkUserDocument(value, params.get('token'))
            .then((res) => {
                if (res.status && res.document > 0) {
                    setErrors({...error, cpf: "CPF já cadastrado no sistema."})
                    setCpfValido(false);
                } else {
                    delete error.cpf;
                    setCpfValido(true);
                }
            })
        .catch((err) => console.error(err));

    }

    function changeInput(value){
        setPassaporteNumero(value)
        
        if(value === '000000' || value === '0000000' || value === ''){
            setpropsErrorInput(true)
        } else {
            setpropsErrorInput(false)
            delete error.passaporteNumero
            localStorage.setItem('passaporteNumero', value);
        }
    }

    function validaSenhaForte(value) {

        if(value.length >= 8)
            setChekMinCaractere(true)
        else
            setChekMinCaractere(false)

        if(value.toString().replace(/[^0-9]/g, '') !== "")
            setCheckNumero(true);
        else
            setCheckNumero(false);

        if(value.toString().replace(/[0-9a-zA-Z]/g, '') !== "")
            setCheckCaractere(true);
        else
            setCheckCaractere(false);

        if(value.toString().replace(/[^a-z]/g, '') !== "" && value.toString().replace(/[^A-Z]/g, '') !== "")
            setCheckCaixa(true)
        else
            setCheckCaixa(false)
    }

    function validaId(value) {

        if(typeof value !== 'string') return;

        if(value === "") {
            setCheckIdDontHaveSpecialCharacter(false);
            setCheckIdDontExceedsCharacters(false);
            setCheckIdDontHaveUppercase(false);
            setCheckIdDontHaveSpaces(false);
            setCheckIdDontHaveOffensiveWords(false);
            return;
        }

        if(value.length > 15) {
            setCheckIdDontExceedsCharacters(false)
        }
        else {
            setCheckIdDontExceedsCharacters(true)
        }
        
        if(/[A-Z]/.test(value)) {
            setCheckIdDontHaveUppercase(false)
        } else {
            setCheckIdDontHaveUppercase(true)
        }

        if(/[ ]/.test(value)) {
            setCheckIdDontHaveSpaces(false);
        } else {
            setCheckIdDontHaveSpaces(true);
        }

        
        if(/^[\w. ]+$/.test(value)) {
            setCheckIdDontHaveSpecialCharacter(true)
        } else {
            setCheckIdDontHaveSpecialCharacter(false)
        }

        if(haveBadWords(value)) {
            setCheckIdDontHaveOffensiveWords(false)
        }
        else {
            setCheckIdDontHaveOffensiveWords(true)
        }
    }

    
    
    const getBirthCitysUpdate = (stateId) => {
        listCityByStateId(stateId || estadoNascimento)
            .then(data => {
                if(data.status){
                    const citysMap = data.cities.map(item => {
                        return {id: item.id, value: item.description}
                    });

                    setBirthCitys(citysMap);
                } else {
                    // if -> some issue, list all
                    const domainCitys = props.dominios.city.map((item) => {
                        return { id: item.id, value: item.description };
                    });

                    setBirthCitys(domainCitys);
                }
            })
            .catch(err => console.error(err));
    }
    
    useEffect(() => {
        if(!estadoNascimento) return;

        getBirthCitysUpdate(estadoNascimento);
    }, [estadoNascimento]);

    useEffect(() => {
        if(debouncedId !== "") {
            if(typeof id === 'string') {

                if(id.length === 0 || id.length > 15) {
                    return;
                }
    
                if(
                    checkIdDontHaveSpaces 
                    && checkIdDontHaveSpecialCharacter 
                    && checkIdDontHaveUppercase
                    && checkIdDontHaveOffensiveWords
                    && checkIdDontExceedsCharacters
                    ) { // check bad words & check
                    checkIsValidUserId(id)
                        .then(data => {
                            console.log("checkIsValidUserId for id " + id, data);
                            if(data.message === "Usuário não encontrado!") {
                                setIsValidUserId(true);
                                setErrors({...error, id: null})
                            } else {
                                setIsValidUserId(false);
                                setErrors({...error, id: "Este ID já está em uso."})
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            }
        }
    }, [debouncedId]);

    const states = props.dominios.state.map((item) => {
        return { id: item.id, value: item.code}
    })

    const countrys = props.dominios.country.map((item) => {
        return { id: item.id, value: item.description }
    })

    const etnias = props.dominios.etnia.map((item) => {
        return { id: item.id, value: item.description }
    })

    const cbos = props.dominios.cbo.map((item) => {
        return { id: item.id, code: item.code, value: `(${item.code}) ${item.description}` }
    })


    const especialidades = props.dominios.medical_speciality.map((item) => {
        return { id: item.id, value: item.description }
    })

    function especialidadesFiltered(especialidades) {
        const orderEspecialidades = especialidades.sort(function(a, b){
            if(a.value < b.value) { return -1; }
            if(a.value > b.value) { return 1; }
            return 0;
        });

        return orderEspecialidades;
    }

    const racasMap = racasCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const document_types = props.dominios.document_type.map((item) => {
        return { id: item.id, value: item.description }
    })

    const conselhosMap = conselhosCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const generosMap = generoCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const orientacoesSexuaisMap = orientacoesSexuaisCode.map(function(item){
        return { id: item.id, value: item.name }
    })

    const orgaoEmissorMap = orgaoEmissorCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const sexoMap = sexoCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const tipoTelefoneMap = tipoTelefoneCode.map(function(item){
        return { id: item.name, value: item.name }
    })

    const conselhosFiltered = conselhos.filter(function (a) {
        return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null))
    

    const nacionalidades = [
        { id: 1, value: 'Brasileiro' },
        { id: 3, value: 'Estrangeiro' },
        { id: 2, value: 'Naturalizado' }
    ]

    const checarData = (day, month, year) => {
        if(day == "00" || month == "00" || year == "0000") return false;
        
        if(parseInt(month) > 12)
            return false;

        switch(month){
            case "01":
            case "03":
            case "05":
            case "07":
            case "08":
            case "10":
            case "12":
                return day <= 31;
            case "04":
            case "06":
            case "09":
            case "11":
                return day <= 30;
            default:
                if(isBissexto(year))
                    return day <= 29
                else
                    return day <= 28
        }
    }

    useEffect(() => {
        if(localStorage.getItem("cep")) {
                getCep2(localStorage.getItem("cep")).then(res => {
        
                    delete error.cep;
        
                    setStep5(true);
        
                    const brasilCountry = countrys.find(res => res.id == '45');
        
                    var estadoByCep = props.dominios.state.filter( result => result.code === res.estado_sigla)[0]
                    
                    if(brasilCountry){
                        setPais(brasilCountry.id); localStorage.setItem("pais", brasilCountry.id)
                    }
                    setBairro(res.bairro); localStorage.setItem('bairro', res.bairro);    
                    setEndereco(res.logradouro); localStorage.setItem('endereco', res.logradouro);
                    setEstado(estadoByCep.id); localStorage.setItem('estado', estadoByCep.id);
        
                    setMunicipio(res.localidade); localStorage.setItem('municipio', res.localidade);
        
                    listCityByStateId(estadoByCep.id).then(data => {
        
                        if(data.status){
                            const citysMap = data.cities.map(item => {
            
                                let description = item.description.toString().toLowerCase();
            
                                return {id: item.id, value: description }
                            });
            
                            setCitys(citysMap);
        
                            let muni_id = citysMap.filter(item => item.value == retira_acentos(res.localidade, false))[0] ? citysMap.filter(item => item.value == retira_acentos(res.localidade, false))[0].id : null;
                            
                            setMunicipioId(muni_id)
                            localStorage.setItem('municipioid', muni_id);                                                            
                            
                        }
                    }).catch(err => console.error(err));
        
                    var logradouro_id = logradouroCode.filter( rescode => rescode.value == res.logradouro_tipo )[0] ? logradouroCode.filter( rescode => rescode.value == res.logradouro_tipo )[0].id : "";
                    setLogradouro(logradouro_id)
                    localStorage.setItem('logradouro', logradouro_id);
                    setLogradouroName(res.logradouro_tipo)
                    localStorage.setItem('logradouroName', res.logradouro_tipo);
        
                    delete error.bairro;
                    delete error.endereco;
                    delete error.estado;
                    delete error.municipio;
                    delete error.logradouro;
                    delete error.pais;
        
                    if(res.bairro && res.localidade) {
                        setIsReadOnly(true)
                    }
                })
                .catch(console.error)
        }
    }, [])

    const validateDate = {
        dataNascimento: () => {

            let msg = "Data de nascimento inválida";

            try {

                if (dataNascimentoValida && dataNascimento != null) {

                    if(dataNascimento == null || dataNascimento == "")
                        throw "campo vazio";  

                    if(dataNascimento.length < 10)
                        throw "Data inválida";

                    if(dataNascimento === "00/00/0000" || dataNascimento === "99/99/9999")
                        throw "não é uma data";
    
                    var [day, month, year] = dataNascimento.split("/");

                    if(!checarData(day, month, year))
                        throw "Data inválida";
                    
                    if(day == "29" && month == "02" && !isBissexto(year))
                        throw "Data inválida o ano não é bissexto";
    
                    var [bDay, bMonth, bYear] = dataNascimento.split("/");
    
                    var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);
    
                    if(_dataNascimento > new Date()) {
                        // return "Data não pode ser maior que a data atual."
                        return msg;
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        // return "Data inválida.";
                        return msg;
                    }
                } else {
                    // return "Data inválida2.";
                    return msg;
                }
            } catch(err) {
                if(err == "campo vazio")
                    return "Campo obrigatório";
                // return `Data inválida (${err}).`;
                return msg;
            }

        },
        dataEmissaoIdentidade: () => {

            let msg = "Data de emissão inválida";

            if (identidadeEmissao != null) {

                try{

                    if(identidadeEmissao == null || identidadeEmissao == "")
                        throw "campo vazio";  

                    if(identidadeEmissao.length < 10)
                        throw "Data inválida";
                    
                    if(identidadeEmissao === "00/00/0000" || identidadeEmissao === "99/99/9999")
                        throw "não é uma data";

                    var [day, month, year] = identidadeEmissao.split("/");

                    if(!checarData(day, month, year))
                        throw "Data inválida";
    
                    var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

                    if(day == "29" && month == "02" && !isBissexto(year))
                        throw "Data inválida o ano não é bissexto";
    
                    if(dataNascimento){

                        var [bDay, bMonth, bYear] = dataNascimento.split("/");
        
                        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);
        
                        if(_identidadeEmissao < _dataNascimento) {
                            // return "Data não pode ser menor que a data de nascimento."
                            return msg;
                        }
        
                        if(_identidadeEmissao > new Date()) {
                            // return "Data não pode ser maior que a data atual."
                            return msg;
                        }
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        // return "Data inválida.";
                        return msg;
                    }
                } catch(err) {
                    console.log(err)
                    if(err == "campo vazio")
                        return "Campo obrigatório";
                    // return `Data inválida (${err}).`;
                    return msg;
                }

            } else {
                // return "Data inválida.";
                return msg;
            }
        },
        dataNaturalizacao: () => {

            let msg = "Data de naturalização inválida";

            try {

                if (dataNaturalizacaoValida) {
    
                    if(dataNaturalizacao == null || dataNaturalizacao == "")
                        throw "campo vazio";  

                    if(dataNaturalizacao.length < 10)
                        throw "Data inválida";

                    if(dataNaturalizacao == "00/00/0000" || dataNaturalizacao == "99/99/9999")
                        throw "não é uma data";
    
                    var [day, month, year] = dataNaturalizacao.split("/");

                    if(!checarData(day, month, year))
                        throw "Data inválida";

                    if(day == "29" && month == "02" && !isBissexto(year))
                        throw "o ano não é bissexto";
    
                    var _identidadeEmissao = new Date(`${year}-${month}-${day}`);
    
                    var [bDay, bMonth, bYear] = dataNascimento.split("/");
    
                    var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);
    
                    if(_identidadeEmissao < _dataNascimento) {
                        // return "Data não pode ser menor que a data de nascimento."
                        return msg;
                    }
    
                    if(_identidadeEmissao > new Date()) {
                        // return "Data não pode ser maior que a data atual."
                        return msg;
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        // return "Data inválida.";
                        return msg;
                    }
                } else {
                    // return "Data inválida.";
                    return msg;
                }
            } catch(err) {
                if(err == "campo vazio")
                    return "Campo obrigatório";
                // return `Data inválida (${err}).`;
                return msg;
            }
        },
        dataEmissaoPassaporte: () => {

            let msg = "Data de emissão do passaporte inválida";

            try {

                if (passaporteDataEmissaoValida) {
    
                    if(passaporteDataEmissao == null || passaporteDataEmissao == "")
                        throw "campo vazio";    

                    if(passaporteDataEmissao.length < 10)
                        throw "Data inválida";

                    if(passaporteDataEmissao == "00/00/0000" || passaporteDataEmissao == "99/99/9999")
                        throw "não é uma data";    
                            
                    var [day, month, year] = passaporteDataEmissao.split("/");

                    if(!checarData(day, month, year))
                        throw "Data inválida";
    
                    if(day == "29" && month == "02" && !isBissexto(year))
                        throw "o ano não é bissexto";
    
                    var _passaporteEmissao = new Date(`${year}-${month}-${day}`);
    
                    if (passaporteDataValidade != null) {
    
                        var [dayVal, monthVal, yearVal] = passaporteDataValidade.split("/");
                        var _passaporteValidade = new Date(`${yearVal}-${monthVal}-${dayVal}`);
    
                        if(_passaporteEmissao > _passaporteValidade) {
                            // return "Data não pode ser maior que a validade."
                            return msg;
                        }
    
                    }
    
                    if (dataNascimento != null) {
    
                        var [bDay, bMonth, bYear] = dataNascimento.split("/");
                        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);
    
                        if(_passaporteEmissao < _dataNascimento) {
                            // return "Data não pode ser menor que a data de nascimento."
                            return msg;
                        }
    
                    }
    
                    if (_passaporteEmissao > new Date()) {
                        // return "Data não pode ser maior que a data atual."
                        return msg;
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        // return "Data inválida.";
                        return msg;
                    }
                } else {
                    // return "Data inválida.";
                    return msg;
                }
            } catch(err) {
                if(err == "campo vazio")
                    return "Campo obrigatório";
                // return `Data inválida (${err}).`;    
                return msg;
            }

        },
        dataValidadePassaporte: () => {

            let msg = "Data de validade do passaporte inválida";
            let today = new Date();
            today.setHours(0,0,0,0);
            try {

                if (passaporteDataValidadeValida) {
    
                    if(passaporteDataValidade == null || passaporteDataValidade == "")
                        throw "campo vazio";  
                        
                    if(passaporteDataValidade.length < 10)
                        throw "Data inválida";  

                    if(passaporteDataValidade == "00/00/0000" || passaporteDataValidade == "99/99/9999")
                        throw "não é uma data";    
                            
                    var [day, month, year] = passaporteDataValidade.split("/");

                    if(!checarData(day, month, year))
                        throw "Data inválida";

                    if(day == "29" && month == "02" && !isBissexto(year))
                        throw "o ano não é bissexto";

                    var _passaporteValidade = new Date(`${year}-${month}-${day}`);

                    if(_passaporteValidade.getTime() <= (new Date()).getTime())
                        throw "Data inválida";
    
                    if (passaporteDataEmissao != null) {
    
                        var [dayEm, monthEm, yearEm] = passaporteDataEmissao.split("/");
                        var _passaporteEmissao = new Date(`${yearEm}-${monthEm}-${dayEm}`);
    
                        if (_passaporteEmissao > _passaporteValidade) {
                            // return "Data não pode ser menor que a emissão."
                            return msg;
                        }
    
                    }
    
                    if (dataNascimento != null) {
    
                        var [bDay, bMonth, bYear] = dataNascimento.split("/");
                        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);
    
                        if(_passaporteValidade < _dataNascimento) {
                            // return "Data não pode ser menor que a data de nascimento."
                            return msg;
                        }
                    }

                    if (_passaporteValidade < today) {
                        return msg;
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        // return "Data inválida.";
                        return msg;
                    }
                } else {
                    // return "Data inválida.";
                    return msg;
                }
            } catch(err) {
                if(err == "campo vazio")
                    return "Campo obrigatório";
                // return `Data inválida (${err}).`;
                return msg;
            }

        }
    }

    const [error, validarFormulario, setErrors] = useError(getValidators());

    function getValidators() {
        var validators = {};

        validators = {
            email: requiredEmail,
            nome: requiredTwoName,
            nomeSocial: requiredOptionalTwoName,
            nacionalidade: required,
            cpf: validaCpf,
        }

        if(props.step == 2) {
            validators.id = required;
            validators.confirm_id = required;

            if(isValidUserId === null) {
                validators.id = () => "Revise o ID.";
            } else if(isValidUserId === false) {
                validators.id = () => "ID inválido.";
            }
        }

        if (step2 == true) {
            validators.dataNascimento = validateDate.dataNascimento;
            validators.cns            = CNSValido;
        }

        if (step3 == true) {
            validators.telefones = () => {
            
                let valido = true;
                if(Array.isArray(telefones) && telefones.length <= 0 ) {
                    valido = false;
                }
                if(Array.isArray(telefones)){
                    telefones.map(res => {
                        if(!res.tipoTelefone)
                            valido = false;
                        
                        if(!res.telefone)
                            valido = false;
                    });
                }
    
                return valido ? undefined : "Campo obrigatório";
            }
        }

        

        if (step4 == true) {
            // Endereço
            validators.cep    = validaCEP;
            validators.numero = required;
            validators.logradouro = required;
            validators.endereco = required;
            validators.bairro = required;
            validators.estado = required;
            validators.pais = required;
        }

        if (step5 == true) {
            // Dados demográficos
            validators.sexo                   = required;
            validators.genero                 = required;
            validators.raca                   = required;
            validators.orientacaoSexual       = required;
        }

        if (nacionalidade == 1) {
            // Identidade - Brasileiro
            validators.identidadeNumero       = validaRG;
            validators.identidadeEmissao      = validateDate.dataEmissaoIdentidade;
            validators.identidadeOrgaoEmissor = required;
            validators.identidadeUF           = required;
            
            validators.estadoNascimento       = required;
            validators.municipioNascimento    = required;

            if(!maeDesconhecida){
                validators.nomeMae            = requiredTwoName;
            }

            
            validators.logradouro = required;
            validators.endereco   = required;
            validators.bairro     = required;
            validators.estado     = required;
        } else if (nacionalidade == 2) {
            // Identidade - Naturalizado
            validators.dataNaturalizacao = validateDate.dataNaturalizacao;
            validators.paisNascimento    = required;
            
            validators.logradouro = required;
            validators.endereco   = required;
            validators.bairro     = required;
            validators.estado     = required;
        } else if (nacionalidade == 3) {
            // Identidade - Estrangeiro
            validators.passaporteNumero         = requiredPassaporte;
            validators.passaportePaisEmissor    = required;
            validators.passaporteDataEmissao    = validateDate.dataEmissaoPassaporte;
            validators.passaporteDataValidade   = validateDate.dataValidadePassaporte;
            validators.passaportePaisNascimento = required;
        }

        validators.pais = required;

        if(Array.isArray(conselhos) && conselhos.length > 0){
            validators.conselhos = requiredConselho;
        }

        if (raca == 'Indígena') {
            validators.etnia = required;
        }

        if(step9 && isMedico) {
            validators.especialidade = required;
        }
        
        return validators;
    }

    useEffect(() => {

        var local_storage_key = [
            'identidadeOrgaoEmissor',
            'isMedico',
            'endereco',
            'conselhos',
            'sexo',
            'estado',
            'pais',
            'telefones',
            'logradouro',
            'cns',
            'especialidade',
            'identidadeEmissao',
            'identidadeUF',
            'dataNascimento',
            'estadoNascimento',
            'cep',
            'bairro',
            'theme',
            'raca',
            'maeDesconhecida',
            'logradouroName',
            'genero',
            'municipio',
            'apresentacao',
            'orientacaoSexual',
            'municipioNascimento',
            'numero',
            'contador',
            'identidadeNumero',
            'nacionalidade',
            'municipioid'
        ]

        Object.entries(localStorage).map(item => {
            if(local_storage_key.indexOf(item[0]) < 0)
                localStorage.removeItem(item[0]);
        });
    },[])
    
    useEffect(() => {

        try {

            if(error.conselhos && error.conselhos.length > 0) {
                let peloMenos1 = false;
                error.conselhos.map(res => res === undefined ? peloMenos1 = true : "");
                if(peloMenos1)
                    delete error.conselhos;
                
                setPeloMenosUmConselho(peloMenos1)
            }

            // console.log(error)

            if (Object.keys(error).length) {
                document.getElementById(Object.keys(error)[0]).scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest"
                });
            }
            
        } catch(err) {
            console.log(err, 'erro linha 406')
            console.log(error)
        }
    }, [error]);

    useEffect(function(){
        if(!peloMenosUmConselho)
            toast.warn("Preencha pelo menos 1 conselho.")

    }, [peloMenosUmConselho])

    function novoTelefone() {

        // var new_qtd_telefones = [...qtdTelefones, 1];

        // setQtdTelefones(new_qtd_telefones);
        setTelefones([...telefones, { tipoTelefone: '', telefone: '' }]);

        localStorage.setItem('qtdTelefones', [...telefones, { tipoTelefone: '', telefone: '' }]);
    }

    function getDados() {

        var medical_speciality = [];
        try {
            medical_speciality = JSON.parse(especialidade);
        } catch(err) {
            medical_speciality = especialidade;
        }

        var dados = {
            cpf: setCustomCPF(cpf),
            id,
            confirm_id: confirmId,
            email,
            nome,
            nacionalidade,
            nomeSocial,
            identidadeNumero,
            identidadeEmissao,
            nomeMae,
            identidadeOrgaoEmissor,
            identidadeUF,
            dataNascimento,
            dataNaturalizacao,
            paisNascimento,
            cns,
            passaporteNumero,
            passaportePaisEmissor,
            passaporteDataEmissao,
            passaporteDataValidade,
            passaportePaisNascimento,
            cep,
            logradouro,
            endereco,
            numero,
            complemento,
            bairro,
            municipio: municipioId,
            estado,
            pais,
            estadoNascimento,
            municipioNascimento: (municipioNascimento) ? municipioNascimento.id : 'null',
            sexo,
            genero,
            orientacaoSexual,
            raca,
            etnia: etnia || 'null',
            telefones,
            conselhos,
            especialidade: medical_speciality,
            apresentacao,
            senha
        }

        if(nacionalidade == 1){
            dados.passaporteNumero = 'null'
            dados.passaportePaisEmissor = 'null'
            dados.passaporteDataEmissao = 'null'
            dados.passaporteDataValidade = 'null'
            dados.passaportePaisNascimento = 'null'
            dados.dataNaturalizacao = 'null'
            dados.paisNascimento = 'null'
        }

        if(nacionalidade == 2) {
            dados.identidadeNumero = 'null'
            dados.identidadeEmissao = 'null'
            dados.nomeMae = 'null'
            dados.identidadeOrgaoEmissor = 'null'
            dados.identidadeUF = 'null'
            dados.passaporteNumero = 'null'
            dados.passaportePaisEmissor = 'null'
            dados.passaporteDataEmissao = 'null'
            dados.passaporteDataValidade = 'null'
            dados.passaportePaisNascimento = 'null'
        }

        if(nacionalidade == 3) {
            dados.identidadeNumero = 'null'
            dados.identidadeEmissao = 'null'
            dados.nomeMae = 'null'
            dados.identidadeOrgaoEmissor = 'null'
            dados.identidadeUF = 'null'
            dados.dataNaturalizacao = 'null'
            dados.paisNascimento = 'null'
        }

        if(maeDesconhecida)
            dados.nomeMae = 'null'

        return dados;
    }

    function formatDate(date) {
        if(date && date != '') {
            var stringDate = String(date).split('/').reverse().join('-');
            var timestamp  = new Date(stringDate + 'T00:00:00-03:00');
            
            return timestamp;
        } else {
            return null
        }
    }


    useEffect(function(){

        if(localStorage.getItem('nacionalidade')){
            setStep0(false);
            setStep2(true);
        }

        if(localStorage.getItem('cns')){
            setStep3(true);
        }

        // if(localStorage.getItem('estado')){
        //     getCitysUpdate(localStorage.getItem('estado'));
        // }

        if(localStorage.getItem('telefones') && JSON.parse(localStorage.getItem('telefones'))[0].tipoTelefone){
            setStep4(true);
        }

        if(localStorage.getItem('numero') || localStorage.getItem("pais")){
            setStep5(true)
        }

        if(localStorage.getItem('etnia')){
            setStep6(true);
        }

        if(String(localStorage.getItem('raca')).toLowerCase() != 'indígena'){
            setStep6(true);
        }

        _setCbos(cbos); // setando os cbos dos dominios

    }, []);

    useEffect(() => {
        setStep7(true);

        if(conselhosObrigatorios && conselhosObrigatorios.length){
            
            let new_conselhos = [];

            conselhosObrigatorios.forEach((conselho, index) => {
                new_conselhos.push({ conselho: conselho.document_type.description, numero: "", uf: "", descricao: "" });

                // [{type: conselho.document_type.description, fields: [{id: "", value: "uf" }, {id: "", value: "numero"}, {id: "", value: "descricao"}]}];
            });

            try{
                var _conselhos_storage = localStorage.getItem("conselhos");

                if(_conselhos_storage){
                    var _conselhos = JSON.parse(_conselhos_storage);
                    if(_conselhos.length){
                        setConselhos(_conselhos);
                    } else {
                        setConselhos(new_conselhos);
                    }
                } else {
                    setConselhos(new_conselhos);
                }
            } catch(e){
                console.error(e);
            }

        }
    }, [conselhosObrigatorios]);

    useEffect(() => {
        if(checkCaractere && checkCaixa && checkNumero && checkMinCaractere && confirmSenha !== "" && senha !== "" && confirmSenha === senha && id !== "" && confirmId !== "")
                                    {
            if(isValidUserId === true && checkUserIdEqual()) {
                setFormValid(true);
            } else {
                setFormValid(false);
            }
        } else {
            setFormValid(false);
        }
    },[confirmSenha, senha, id, confirmId, isValidUserId])

    const checkUserIdEqual = () => id === confirmId
    
    return (
        <form id="formulario-autocadastro" autoComplete="off" className={classes.form} onSubmit={ form => {
            form.preventDefault();

            var dados = getDados();

            validarFormulario({ ...dados });

        } }>
            {removerTelefone != null && 
                        <RemoverTelefone 
                            open={removerTelefone != null ? true : false} 
                            onRemove={index => {
                                setTelefones(prevState => {
                                    let temp = [...prevState];

                                    temp.splice(index, 1);

                                    return temp;
                                })

                                setRemoverTelefone(null)
                                
                            }} 
                            index={removerTelefone} 
                            onClose={e => setRemoverTelefone(null)} 
                        /> 
                    }
            <Grid container className={ `tema-light ${classes.root} ${ props.step !== 0 ? classes.hide : '' }` } spacing={3}>
                <Grid item md={6}>
                    <DInput
                        id="cpf"
                        label="CPF"
                        placeholder="CPF"
                        type="text"
                        error={ error.cpf }
                        helperText={ error.cpf }
                        inputProps={{maxLength: 14}}
                        positionIcon="end"
                        fullWidth
                        onChange={ e => {
                            delete error.cpf

                            if(e.target.value || e.target.value == ""){
                                setCPF(e.target.value.replace(/[^\d]+/g, ''))
                                handleValidaCpf(e.target.value.replace(/[^\d]+/g, ''))
                            }

                        } }
                        onBlur={ e => {
                            handleValidaCpf(e.target.value)
                        }}
                        value={ cpf && mascaraCPF(cpf) } />
                </Grid>
                <Grid item md={6}>
                    <DInput
                        id="email"
                        label="E-mail"
                        placeholder="E-mail"
                        type="text"
                        inputProps={{maxLength: 254}}
                        error={ error.email }
                        helperText={ error.email }
                        positionIcon="end"
                        fullWidth
                        onChange={ e => {
                            
                            delete error.email

                            if(e.target.value !== undefined)
                                setStep1(true);

                            setEmail(e.target.value)
                        } }
                        value={ email } />
                </Grid>
                <Grid item md={6} className={ !step1 ? classes.hide : '' }>
                    <DInput
                        id="nome"
                        label="Nome completo"
                        placeholder="Nome completo"
                        type="text"
                        positionIcon="end"
                        inputProps={{maxLength: 100}}
                        error={ error.nome }
                        helperText={ error.nome }
                        fullWidth
                        onChange={ e => {
                            delete error.nome

                            var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")

                            setNome(_value)
                        } }
                        value={ nome } />
                </Grid>
                <Grid item md={6} className={ !step1 ? classes.hide : '' }>
                    <DDropDownSimple
                        id="nacionalidade"
                        label="Nacionalidade"
                        placeholder="Nacionalidade"
                        fullWidth
                        error={ error.nacionalidade }
                        helperText={ error.nacionalidade }
                        value={ nacionalidade }
                        options={ nacionalidades }
                        onChange={(event) => {

                            delete error.nacionalidade
                            setStep0(false);
                            setStep2(true);
                            setNacionalidade(event)
                            localStorage.setItem('nacionalidade', event);
                        }}                            
                    />
                    {/* <DDropdown 
                        id="nacionalidade"
                        label="Nacionalidade"
                        placeholder="Nacionalidade"
                        fullWidth
                        error={ error.nacionalidade }
                        helperText={ error.nacionalidade }
                        value={ nacionalidade }
                        options={ nacionalidades }
                        onChange={(event) => {

                            delete error.nacionalidade
                            setStep0(false);
                            setStep2(true);
                            setNacionalidade(event)
                            localStorage.setItem('nacionalidade', event);
                        }} 
                    /> */}
                </Grid>
                
                {
                    genero !== 'Cisgênero' && (
                        <Grid item md={12} className={ !step1 ? classes.hide : '' }>
                            <DInput
                                id="nomeSocial"
                                label="Nome social (opcional)"
                                placeholder="Nome social"
                                type="text"
                                inputProps={{maxLength: 100}}
                                positionIcon="end"
                                error={ error.nomeSocial }
                                helperText={ error.nomeSocial }
                                fullWidth
                                onChange={ e => {
                                    delete error.nomeSocial

                                    var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")
                                                    
                                    setNomeSocial(_value);
                                    localStorage.setItem('nomeSocial', _value);

                                    // setNomeSocial(e.target.value)
                                } }
                                value={ nomeSocial } />
                        </Grid>
                    )
                }
                <Grid item md={12} className={ !step2 ? classes.hide : '' }>
                    <Typography className="text-primary text-st text-bold" variant="body1" gutterBottom>Identidade</Typography>
                </Grid>
                <Grid item md={12} className={ nacionalidade != 1 ? classes.hide : '' }>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <Grid container>
                                <Grid item md={7} style={{paddingRight: 25}}>
                                    <DInput
                                        id="identidadeNumero"
                                        label="Número de identidade"
                                        placeholder="Número de identidade"
                                        type="text"
                                        error={ error.identidadeNumero }
                                        positionIcon="end"
                                        helperText={ error.identidadeNumero }
                                        fullWidth
                                        value={ identidadeNumero }
                                        onChange={ e => {
                                            delete error.identidadeNumero
                                            setIdentidadeNumero(mascaraRG(e.target.value));
                                            localStorage.setItem('identidadeNumero', mascaraRG(e.target.value));
                                        } } />
                                </Grid>
                                <Grid item md={5}>
                                    <DDateInput 
                                        id="identidadeEmissao"
                                        error={ error.identidadeEmissao }
                                        helperText={ error.identidadeEmissao }
                                        fullWidth
                                        label="Data de emissão"
                                        minDate={ dataNascimento ? String(dataNascimento).split('/').reverse().join('-') : null }
                                        minDateMessage="Data de emissão inválido"
                                        maxDate={ new Date() }
                                        maxDateMessage="Data de emissão inválido"
                                        value={ identidadeEmissao }
                                        onError={(errorMessage) => {
                                            setErrors({ ...error, identidadeEmissao: errorMessage })
                                        }}
                                        onChange={(arg1, arg2, e) => {
                                            delete error.identidadeEmissao

                                            localStorage.setItem('identidadeEmissao', arg1);
                                        
                                            setIdentidadeEmissao(arg1)
                                            setIdentidadeEmissaoValida(arg2.isValid);
                                        }}
                                        // onBlur={ e => {
                                        //     setErrors({...error, identidadeEmissao: validateDate.dataEmissaoIdentidade(e.target.value) })  
                                        // } }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <DInput
                                id="nomeMae"
                                label="Nome da mãe"
                                placeholder="Nome da mãe"
                                inputProps={{maxLength: 100}}
                                type="text"
                                disabled={ maeDesconhecida }
                                error={ error.nomeMae }
                                helperText={ error.nomeMae }
                                positionIcon="end"
                                fullWidth
                                onChange={ e => {
                                    delete error.nomeMae
                                    
                                    var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")

                                    setNomeMae(_value)
                                    localStorage.setItem('nomeMae', _value);
                                } }
                                value={ nomeMae } />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={ maeDesconhecida }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onClick={ e => {
                                            delete error.nomeMae;
                                            localStorage.setItem('maeDesconhecida', !maeDesconhecida);
                                            setMaeDesconhecida(!maeDesconhecida);
                                            if(!maeDesconhecida) setNomeMae("");
                                        }}
                                    />
                                }
                                label="Mãe desconhecida"
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="identidadeOrgaoEmissor"
                                label="Órgão emissor"
                                placeholder="Órgão emissor"
                                fullWidth
                                error={ error.identidadeOrgaoEmissor }
                                helperText={ error.identidadeOrgaoEmissor }
                                value={ identidadeOrgaoEmissor }
                                options={ orgaoEmissorMap }
                                onChange={(event) => {

                                    delete error.identidadeOrgaoEmissor
                                    setIdentidadeOrgaoEmissor(event)
                                    localStorage.setItem('identidadeOrgaoEmissor', event);
                                }}                            
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="identidadeUF"
                                label="UF"
                                native
                                placeholder="Estado"
                                fullWidth
                                error={ error.identidadeUF }
                                helperText={ error.identidadeUF }
                                value={ identidadeUF }
                                options={ states }
                                onChange={(event) => {

                                    delete error.identidadeUF
                                    setIdentidadeUF(event)
                                    localStorage.setItem('identidadeUF', event);
                                }}                            
                            />
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item md={12} className={ nacionalidade != 3 ? classes.hide : '' }>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <DInput
                                id="passaporteNumero"
                                label="Número do passaporte"
                                placeholder="Número do passaporte"
                                type="text"
                                error={ error.passaporteNumero || propsErrorInput }
                                helperText={ error.passaporteNumero }
                                positionIcon="end"
                                inputProps={{maxLength: 9}}
                                fullWidth
                                onChange={ e => changeInput(e.target.value)}
                                value={ passaporteNumero } />
                        </Grid>
                        <Grid item md={6}>
                            <DAutoComplete
                                id="passaportePaisEmissor"
                                label="País emissor do passaporte"
                                placeholder="País emissor do passaporte"
                                fullWidth
                                error={ error.passaportePaisEmissor }
                                helperText={ error.passaportePaisEmissor }
                                defaultValue={ countrys.filter( res => res.id == passaportePaisEmissor )[0] }
                                options={countrys}
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.passaportePaisEmissor
                                    setPassaportePaisEmissor(newValue ? newValue.id : '')
                                    localStorage.setItem('passaportePaisEmissor', newValue ? newValue.id : '');
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>                            
                            <DDateInput 
                                id="passaporteDataEmissao"
                                error={ error.passaporteDataEmissao }
                                helperText={ error.passaporteDataEmissao }
                                fullWidth
                                label="Data de emissão de passaporte"
                                // minDate={ dataNascimento ? String(dataNascimento).split('/').reverse().join('-') : null }
                                // minDateMessage="Data de emissão inválido"
                                // maxDate={ new Date() }
                                // maxDateMessage="Data de emissão inválido"
                                value={ passaporteDataEmissao }
                                // onError={(errorMessage) => {
                                //     setErrors({ ...error, passaporteDataEmissao: errorMessage })
                                // }}
                                onChange={(arg1, arg2, e) => {
                                    delete error.passaporteDataEmissao;

                                    localStorage.setItem('passaporteDataEmissao', arg1);
                                
                                    setPassaporteDataEmissao(arg1);
                                    setPassaporteDataEmissaoValida(arg2.isValid);
                                }}
                                // onBlur={ e => {
                                //     setErrors({...error, passaporteDataEmissao: validateDate.dataEmissaoPassaporte(e.target.value) })  
                                // } }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDateInput 
                                id="passaporteDataValidade"
                                error={ error.passaporteDataValidade }
                                helperText={ error.passaporteDataValidade }
                                fullWidth
                                label="Data de validade do passaporte"
                                // minDate={ dataNascimento ? String(dataNascimento).split('/').reverse().join('-') : null }
                                // minDateMessage="Data de validade inválido"
                                // maxDate={ new Date() }
                                // maxDateMessage="A data de validade não pode ser maior que a data atual"
                                value={ passaporteDataValidade }
                                // onError={(errorMessage) => {
                                //     setErrors({ ...error, passaporteDataValidade: errorMessage })
                                // }}
                                onChange={(arg1, arg2, e) => {
                                    delete error.passaporteDataValidade;

                                    localStorage.setItem('passaporteDataValidade', arg1);
                                
                                    setPassaporteDataValidade(arg1);
                                    setPassaporteDataValidadeValida(arg2.isValid);
                                }}
                                // onBlur={ e => {
                                //     setErrors({...error, passaporteDataValidade: validateDate.dataValidadePassaporte(e.target.value) })  
                                // } }
                            />
                        </Grid>
                        <Grid item md={12}>
                            <DAutoComplete
                                id="passaportePaisNascimento"
                                label="País de nascimento"
                                fullWidth
                                placeholder="País de nascimento"
                                error={ error.passaportePaisNascimento }
                                helperText={ error.passaportePaisNascimento }
                                defaultValue={ countrys.filter( res => res.id == passaportePaisNascimento )[0] }
                                options={countrys}
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.passaportePaisNascimento
                                    setPassaportePaisNascimento(newValue ? newValue.id : '')
                                    localStorage.setItem('passaportePaisNascimento', newValue ? newValue.id : '');
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} className={ !step2 ? classes.hide : '' }>
                    <DDateInput 
                        id="dataNascimento"
                        error={ error.dataNascimento }
                        helperText={ error.dataNascimento }
                        fullWidth
                        label="Data de nascimento"
                        // maxDate={ new Date() }
                        // maxDateMessage="Data de nascimento inválido"
                        value={ dataNascimento }
                        // onError={(errorMessage) => {
                        //     setErrors({ ...error, dataNascimento: errorMessage })
                        // }}
                        onChange={(arg1, arg2, e) => {
                            delete error.dataNascimento;

                            localStorage.setItem('dataNascimento', arg1);

                            setDataNascimento(arg1);
                            setDataNascimentoValida(arg2.isValid);
                        }}
                        // onBlur={ e => {
                        //     setErrors({...error, dataNascimento: validateDate.dataNascimento(e.target.value) })  
                        // } }
                    />
                </Grid>
                <Grid item md={6} className={ !step2 ? classes.hide : '' }>
                    <DInput
                        id="cns"
                        label="Cartão nacional de saúde (CNS)"
                        placeholder="Cartão nacional de saúde"
                        type="text"
                        error={ error.cns }
                        helperText={ error.cns }
                        fullWidth
                        inputProps={{maxLength: 15}}
                        onChange={ e => {

                            delete error.cns

                            if(e.target.value !== undefined)
                                setStep3(true);

                            if(e.target.value || e.target.value === ''){
                                var cns_regex = e.target.value.replace(/[^0-9]/g, '')

                                setCNS(cns_regex)
                                localStorage.setItem('cns', cns_regex);

                                if(cns_regex.length === 15){
                                    var validateCNS = CNSValido(cns_regex)
                                    
                                    if(validateCNS){
                                        setErrors({...error, cns: validateCNS})
                                    } else {
                                        setCNS(cns_regex)
                                        localStorage.setItem('cns', cns_regex);
                                    }

                                }
                            }
                        } }
                        positionIcon="end"
                        value={ cns } />
                </Grid>
                <Grid item md={12} className={ nacionalidade != 2 ? classes.hide : '' }>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <DDateInput
                                id="dataNaturalizacao"
                                error={ error.dataNaturalizacao }
                                helperText={ error.dataNaturalizacao }
                                fullWidth
                                label="Data de naturalização"
                                // minDate={ dataNascimento ? String(dataNascimento).split('/').reverse().join('-') : null }
                                // minDateMessage="A data de naturalização não pode ser menor que a data de nascimento"
                                // maxDate={ new Date() }
                                // maxDateMessage="A data de naturalização não pode ser maior que a data atual"
                                value={ dataNaturalizacao }
                                // onError={(errorMessage) => {
                                //     setErrors({ ...error, dataNaturalizacao: errorMessage })
                                // }}
                                onChange={(arg1, arg2, e) => {
                                    delete error.dataNaturalizacao;

                                    localStorage.setItem('dataNaturalizacao', arg1);
                                
                                    setDataNaturalizacao(arg1)
                                    setDataNaturalizacaoValida(arg2.isValid);
                                }}
                                // onBlur={ e => {
                                //     setErrors({...error, dataNaturalizacao: validateDate.dataNaturalizacao(e.target.value) })  
                                // } }
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DAutoComplete
                                id="paisNascimento"
                                label="País de nascimento"
                                fullWidth
                                placeholder="País de nascimento"
                                error={ error.paisNascimento }
                                helperText={ error.paisNascimento }
                                defaultValue={ countrys.filter( res => res.id == paisNascimento )[0] }
                                options={countrys}
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.paisNascimento
                                    setPaisNascimento(newValue ? newValue.id : '')
                                    localStorage.setItem('paisNascimento', newValue ? newValue.id : '');
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={ !step3 ? classes.hide : '' }>
                    <hr className={classes.linha} />
                    <div style={{ display: "flex", alignItems: "baseline"}}>
                        <Typography className="text-primary text-st text-bold" variant="body1" gutterBottom>Telefones</Typography>
                        <span style={{ fontSize: 12, color: '#D13D2E', paddingLeft: 10 }} className="text-regular">{ error.telefones }</span>
                    </div>
                    { telefones.map(function(item, i){

                        return (
                            <Grid key={ `telefones-component-${i}` } container className="tema-light" spacing={3}>
                                <Grid item md={6}>
                                    <DDropDownSimple
                                        id={ `tipo-telefone-${i}` }
                                        label="Tipo de telefone"
                                        placeholder="Tipo de telefone"
                                        fullWidth
                                        error={ error.telefones && !telefones[i].tipoTelefone }
                                        helperText={ error.telefones && !telefones[i].tipoTelefone ? error.telefones : "" }
                                        value={ telefones[i].tipoTelefone }
                                        options={ tipoTelefoneMap }
                                        onChange={(event) => {

                                            delete error.telefones
                                            setStep4(true);
                                            var new_telefone = [...telefones];
                                            new_telefone[i].tipoTelefone = event;
                                            setTelefones(new_telefone);
                                            localStorage.setItem('telefones', JSON.stringify(new_telefone));
                                        }}                            
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <DInput
                                        id='telefones'
                                        label="Telefone"
                                        placeholder="Telefone"
                                        type="text"
                                        error={ (error.telefones || errorCel) && !telefones[i].telefone }
                                        helperText={ (error.telefones || errorCel) && !telefones[i].telefone ? error.telefones : "" }
                                        fullWidth
                                        icon={
                                               i > 0 ? ( <DeleteForever 
                                                onClick={e => {
                                                        e.preventDefault();
                                                        setRemoverTelefone(i);
                                                        
                                                    }
                                                } 
                                                style={{color: "red", cursor: "pointer"}} 
                                                fontSize="small" 
                                            /> ) : null
                                        }
                                        positionIcon="end"
                                        onChange={ e => {
                                            delete error.telefones
                                            var new_telefone = [...telefones];
                                            const regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;

                                            //Vai me retornar um booleano, caso esteja errado motra erro
                                            if(!regexTelefone.test(e.target.value)){
                                                setErrorCel(true);
                                            }

                                            if(new_telefone[i].tipoTelefone == 'Pessoal'){
                                                new_telefone[i].telefone = mascaraNumero(e.target.value);
                                                setTelefones(new_telefone);
                                            }else {
                                                if(e.target.value.length > 20) return;

                                                new_telefone[i].telefone = mascaraNumero(e.target.value);
                                                
                                                setTelefones(new_telefone);
                                            }
                                            setTelefones(new_telefone);
                                            localStorage.setItem('telefones', JSON.stringify(new_telefone));
                                            setErrorCel(false);

                                        } }
                                        value={ telefones[i] && telefones[i].telefone } />
                                </Grid>
                            </Grid>
                        )
                    }) }
                    
                    <DButton className={classes.marginDefault}
                             type="button" variant="ghost" size="sm"
                             icon={ <AddCircleIcon /> } onClick={novoTelefone}>Adicionar novo telefone</DButton>
                </Grid>
                <Grid item md={12} className={ !step4 ? classes.hide : '' }>
                    <hr className={classes.linha} />
                    <Typography className="text-primary text-st text-bold" variant="body1" gutterBottom>Endereço</Typography>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item md={7}>
                                    <DInput
                                        id="cep"
                                        label="CEP"
                                        placeholder="CEP"
                                        type="text"
                                        error={ error.cep }
                                        fixedLabel
                                        helperText={ error.cep }
                                        positionIcon="end"
                                        fullWidth
                                        onBlur={ e => {
                                            try {
                                                getCep2(e.target.value).then(res => {

                                                    delete error.cep;

                                                    setStep5(true);

                                                    const brasilCountry = countrys.find(res => res.id == '45');
    
                                                    var estadoByCep = props.dominios.state.filter( result => result.code === res.estado_sigla)[0]
                                                    
                                                    if(brasilCountry){
                                                        setPais(brasilCountry.id); localStorage.setItem("pais", brasilCountry.id)
                                                    }
                                                    setBairro(res.bairro); localStorage.setItem('bairro', res.bairro);    
                                                    setEndereco(res.logradouro); localStorage.setItem('endereco', res.logradouro);
                                                    setEstado(estadoByCep.id); localStorage.setItem('estado', estadoByCep.id);

                                                    setMunicipio(res.localidade); localStorage.setItem('municipio', res.localidade);

                                                    listCityByStateId(estadoByCep.id).then(data => {

                                                        if(data.status){
                                                            const citysMap = data.cities.map(item => {
                                            
                                                                let description = item.description.toString().toLowerCase();
                                            
                                                                return {id: item.id, value: description }
                                                            });
                                            
                                                            setCitys(citysMap);

                                                            let muni_id = citysMap.filter(item => item.value == retira_acentos(res.localidade, false))[0] ? citysMap.filter(item => item.value == retira_acentos(res.localidade, false))[0].id : null;
                                                            
                                                            setMunicipioId(muni_id)
                                                            localStorage.setItem('municipioid', muni_id);                                                            
                                                            
                                                        }
                                                    }).catch(err => console.error(err));

                                                    var logradouro_id = logradouroCode.filter( rescode => rescode.value == res.logradouro_tipo )[0] ? logradouroCode.filter( rescode => rescode.value == res.logradouro_tipo )[0].id : "";
                                                    setLogradouro(logradouro_id)
                                                    localStorage.setItem('logradouro', logradouro_id);
                                                    setLogradouroName(res.logradouro_tipo)
                                                    localStorage.setItem('logradouroName', res.logradouro_tipo);

                                                    delete error.bairro;
                                                    delete error.endereco;
                                                    delete error.estado;
                                                    delete error.municipio;
                                                    delete error.logradouro;
                                                    delete error.pais;

                                                    if(res.bairro && res.localidade) {
                                                        setIsReadOnly(true)
                                                    }
                                                })
                                                .catch(console.error)
                                            } catch (err) {
                                                setErrors({...error, cep: "CEP inválido ou incorreto."})
                                                setIsReadOnly(false)
                                                // console.log("Erro na busca do cep", e.target.value, err)   
                                            }
                                        }}
                                        onChange={ e => {
                                            delete error.cep
                                            setCep(e.target.value)
                                            localStorage.setItem('cep', e.target.value);
                                        } }
                                        value={ mascaraCEP(cep) } />
                                </Grid>
                                <Grid item md={5}>
                                    <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" className="text-mt text-regular text-link">Não sei o CEP</a>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item md={6}>
                            
                            <DAutoCompleteControlled
                                id="logradouro"
                                label="Tipo de Logradouro"
                                placeholder="Tipo de Logradouro"
                                fullWidth
                                disabled={ !logradouroCode || !Array.isArray(logradouroCode) || logradouroCode.length < 1 || !logradouroName }
                                error={ error.logradouro }
                                helperText={ error.logradouro }
                                options={logradouroCode}
                                value={ logradouroCode.filter( res => res.id == logradouro )[0] }
                                inputValue={ logradouroName }
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.logradouro
                                    setLogradouro(newValue ? newValue.id : '')
                                    localStorage.setItem('logradouro', newValue ? newValue.id : '');
                                    setLogradouroName(newValue ? newValue.value : '')
                                    localStorage.setItem('logradouroName', newValue ? newValue.value : '');
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DInput
                                id="endereco"
                                label="Endereço"
                                placeholder="Endereço"
                                type="text"
                                inputProps={{maxLength: 60}}
                                error={ error.endereco }
                                helperText={ error.endereco }
                                fixedLabel
                                positionIcon="end"
                                fullWidth
                                onChange={ e => {
                                    delete error.endereco

                                    var _value = e.target.value.replace(/[^a-zA-Zà-úÀ-Ú"' ]/g, "")

                                    setEndereco(_value)
                                    localStorage.setItem('endereco', _value);
                                } }
                                value={ endereco } />
                        </Grid>
                        <Grid item md={2}>
                            <DInput
                                id="numero"
                                label="Número"
                                placeholder="Número"
                                inputProps={{maxLength: 8}}
                                type="text"
                                error={ error.numero }
                                positionIcon="end"
                                helperText={ error.numero }
                                fullWidth
                                onChange={ e => {
                                    delete error.numero
                                    setStep5(true);
                                    setNumero(e.target.value.replace(/\D/g, ''))
                                    
                                    localStorage.setItem('numero', e.target.value);
                                } }
                                value={ numero } />
                        </Grid>
                        <Grid item md={4}>
                            <DInput
                                id="Complemento"
                                label="Complemento (opcional)"
                                placeholder="Complemento"
                                type="text"
                                positionIcon="end"
                                inputProps={{maxLength: 10}}
                                fullWidth
                                onChange={ e => {
                                    var _value = e.target.value.replace(/[^a-zA-Z0-9à-úÀ-Ú"' ]/g, "")
 
                                    setComplemento(_value)

                                    localStorage.setItem('complemento', _value);
                                }}
                                value={ complemento } />
                        </Grid>
                        <Grid item md={6}>
                            <DInput
                                id="bairro"
                                label="Bairro"
                                placeholder="Bairro"
                                type="text"
                                positionIcon="end"
                                error={ error.bairro }
                                helperText={ error.bairro }
                                disabled={isReadOnly}
                                inputProps={{maxLength: 30}}
                                fixedLabel
                                fullWidth
                                onChange={ e => {
                                    delete error.bairro
                                    var _value = e.target.value.replace(/[^a-zA-Z0-9à-úÀ-Ú"' ]/g, "")

                                    setBairro(_value)

                                    localStorage.setItem('bairro', _value);
                                } }
                                value={ bairro } />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="estado"
                                label="Estado"
                                placeholder="Estado"
                                fullWidth
                                error={ error.estado }
                                helperText={ error.estado }
                                disabled={true}
                                value={ estado }
                                options={ states }
                                onChange={(event) => {

                                    delete error.estado
                                    setEstado(event)
                                    localStorage.setItem('estado', event);
                                }}                            
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DAutoCompleteControlled
                                id="municipio"
                                label={!estado ? "Selecione um Município" : "Município"}
                                placeholder={!estado ? "Selecione um Município" : "Município"}
                                disabled={!estado || isReadOnly}
                                fullWidth
                                capitalize
                                error={ error.municipio }
                                helperText={ error.municipio }
                                onClick={(event) => {
                                    if(!estado){
                                        setErrors({...error, municipio: "Selecione um Município."})
                                    }
                                }}
                                value={ citys.filter(item => item.value === retira_acentos(municipio, false))[0] }
                                inputValue={retira_acentos(municipio, false)}
                                options={citys}
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.municipio
                                    setMunicipio(newValue ? newValue.value : '')
                                    setMunicipioId(newValue.id)
                                    localStorage.setItem('municipio', newValue.value);
                                    localStorage.setItem('municipioid', newValue.id);
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            
                        <DDropDownSimple
                                id="pais"
                                label="País"
                                placeholder="País"
                                fullWidth
                                error={ error.pais }
                                disabled={true}
                                helperText={ error.pais }
                                value={ pais }
                                options={ countrys }
                                onChange={(event) => {
                                    delete error.pais;
                                    setPais(event);
                                    localStorage.setItem('pais', event);
                                    setStep5(true)
                                }}                            
                            />
                            
                            {/* <DAutoComplete
                                id="Pais"
                                label="Pais"
                                placeholder="Pais"
                                fullWidth
                                error={ error.pais }
                                helperText={ error.pais }
                                defaultValue={ pais && countrys.filter( res => res.id == pais )[0] }
                                options={ countrys }
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.pais
                                    setPais(newValue ? newValue.id : '')
                                    localStorage.setItem('pais', newValue ? newValue.id : '');
                                    setStep5(true)
                                }}
                            /> */}
                            
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={ !step5 ? classes.hide : '' }>
                    <hr className={classes.linha} />
                    <Typography className="text-primary text-st text-bold" variant="body1" gutterBottom>Dados demográficos</Typography>
                    
                    <Grid container spacing={3}>
                        <Grid item md={6} className={nacionalidade != '1' ? classes.hide : ''}>                           
                            <DDropDownSimple
                                id="estadoNascimento"
                                label="Estado de nascimento"
                                placeholder="Estado de nascimento"
                                fullWidth
                                error={ error.estadoNascimento }
                                helperText={ error.estadoNascimento }
                                value={ estadoNascimento }
                                options={ states }
                                onChange={(event) => {

                                    delete error.estadoNascimento
                                    setEstadoNascimento(event)
                                    localStorage.setItem('estadoNascimento', event);
                                }}                            
                            />
                        </Grid>
                        <Grid item md={6} className={nacionalidade != '1' ? classes.hide : ''}>
                            <DAutoComplete
                                id="municipioNascimento"
                                label="Município de nascimento"
                                placeholder="Município de nascimento"
                                fullWidth
                                error={ error.municipioNascimento }
                                helperText={ error.municipioNascimento }
                                defaultValue={ municipioNascimento }
                                options={ strCapitalize(birthCitys) }
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.municipioNascimento
                                    setMunicipioNascimento(newValue)
                                    localStorage.setItem('municipioNascimento', JSON.stringify(newValue));
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="sexo"
                                label="Sexo biológico"
                                placeholder="Sexo biológico"
                                fullWidth
                                error={ error.sexo }
                                helperText={ error.sexo }
                                value={ sexo }
                                options={ sexoMap }
                                onChange={(event) => {

                                    delete error.sexo
                                    setSexo(event)
                                    localStorage.setItem('sexo', event);
                                }}                            
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="genero"
                                label="Gênero"
                                placeholder="Gênero"
                                fullWidth
                                error={ error.genero }
                                helperText={ error.genero }
                                value={ genero }
                                options={ generosMap }
                                onChange={(event) => {

                                    delete error.genero
                                    setGenero(event)
                                    localStorage.setItem('genero', event);
                                }}                            
                            />
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="orientacaoSexual"
                                label="Orientação sexual"
                                placeholder="Orientação sexual"
                                fullWidth
                                error={ error.orientacaoSexual }
                                helperText={ error.orientacaoSexual }
                                value={ orientacaoSexual }
                                options={ orientacoesSexuaisMap }
                                onChange={(event) => {

                                    delete error.orientacaoSexual
                                    setOrientacaoSexual(event)
                                    localStorage.setItem('orientacaoSexual', event);
                                    console.log(event);
                                }}                            
                            />
                            {/* <DAutoComplete
                            id="orientacaoSexual"
                            label="Orientação sexual"
                            placeholder="Orientação sexual"
                            
                            fullWidth={true}
                            required={true}
                            // required={ !dadosIncompletos }
                            error={ error.orientacaoSexual }
                            helperText={ error.orientacaoSexual }
                            defaultValue={ orientacoesSexuaisMap.filter( res => res.id == orientacaoSexual )[0] }
                            options={ orientacoesSexuaisMap }
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) => value && option.id === value.id}
                            onChange={(event, newValue) => {
                                delete error.orientacaoSexual
                                setOrientacaoSexual(newValue ? newValue.id : '')
                            }}
                            /> */}
                        </Grid>
                        <Grid item md={6}>
                            <DDropDownSimple
                                id="raca"
                                label="Raça"
                                placeholder="Raça"
                                fullWidth
                                error={ error.raca }
                                helperText={ error.raca }
                                value={ raca }
                                options={ racasMap }
                                onChange={(event) => {
                                    delete error.raca;
                                    setRaca(event);
                                    localStorage.setItem('raca', event);

                                    setEtnia("")

                                }}                            
                            />
                        </Grid>
                        <Grid item md={6}>
                            {
                                raca && String(raca).toLowerCase() === 'indígena' ? (
                                    <DAutoComplete
                                        id="etnia"
                                        label="Etnia"
                                        placeholder="Etnia"
                                        fullWidth
                                        error={ error.etnia }
                                        helperText={ error.etnia }
                                        defaultValue={ etnias.filter( res => res.id == etnia )[0] }
                                        options={ etnias }
                                        getOptionLabel={(option) => option.value}
                                        getOptionSelected={(option, value) => value && option.id === value.id}
                                        onChange={(event, newValue) => {
                                            delete error.etnia
                                            setStep6(true);
                                            setEtnia(newValue ? newValue.id : '')
                                            localStorage.setItem('etnia', newValue ? newValue.id : '');
                                        }}
                                    />
                                ) : (
                                    <DInput
                                        id="etnia"
                                        label="Etnia"
                                        placeholder="Etnia"
                                        fullWidth
                                        disabled={true}
                                        error={ error.etnia }
                                        helperText={ error.etnia }
                                        onChange={(event, newValue) => {
                                            // delete error.etnia
                                            // setStep6(true);
                                            // setEtnia(newValue ? newValue.id : '')
                                            // localStorage.setItem('etnia', newValue ? newValue.id : '');
                                        }}
                                    />
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={ (!step6 || (conselhos && conselhos.length == 0)) ? classes.hide : '' }>
                    <hr className={classes.linha} />
                    <Typography className="text-primary text-st text-bold" variant="body1" gutterBottom>
                        * Conselhos de classe
                    </Typography>
                    { conselhos && conselhosFiltered.map((item, i) => {
                        

                        return <FormCadastroConselhos 
                            error={  error.conselhos && error.conselhos[i] !== undefined ? [i] in error.conselhos : undefined }
                            onChange={ value => {
                                delete error.conselhos;
                                let new_conselhos = [...conselhosFiltered];
                                new_conselhos[i] = value;
                                localStorage.setItem('conselhos', JSON.stringify(new_conselhos))
                                setConselhos(new_conselhos);
                                
                            } }
                            states={ states } 
                            cbos={ cbos } 
                            conselho={ item } 
                            key={ `conselhos-de-classe-${i}` } />
                    }) }
                </Grid>
                <Grid item md={12} className={ !step7 ? classes.hide : '' }>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <DButton type="button" onClick={ e => {

                                
                                var dados = getDados()
                                
                                // dados.especialidade = 'null'
                                // dados.apresentacao = 'null'
                                dados.senha = 'null'

                                if (validarFormulario({ ...dados })) {
                                    props.onStep(1);
                                    setStep9(true)
                                } else {
                                    console.log(validarFormulario({ ...dados }))
                                    console.log(error)
                                }

                            } } className="btn-success" variant="primary">Avançar</DButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} className={ (!step0 || !step7) ? classes.hide : '' }>
                    <Grid container justifyContent="center">
                        <Grid item>
                            
                            <DButton type="button" onClick={ e => {
                                props.voltar(true);
                            } } variant="secondary">Voltar para o início</DButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={ `tema-light ${classes.root} ${ props.step !== 1 ? classes.hide : '' }` } spacing={3}>
                <Grid item md={12}>
                    <DInput label="Apresentação"
                        textarea={3}
                        error={ error.apresentacao }
                        helperText={ error.apresentacao }
                        contador={apresentacao && apresentacao.length}
                        maxSize={ 255 }
                        inputProps={ {
                            maxLength:255
                        } }
                        fullWidth
                        placeholder="Apresentação"
                        value={ apresentacao }
                        onChange={ e => {
                            delete error.apresentacao
                            setApresentacao(e.target.value)
                            setStep8(true);
                            localStorage.setItem('apresentacao', e.target.value);
                            localStorage.setItem('contador', apresentacao);
                        } } />
                </Grid>
                {
                    isMedico ? (
                        <Grid item md={12}>
                            <DMultAutoComplete
                                inputLabel="Especialidades"
                                placeholder="Especialidades"
                                labelColor='black'
                                error={ error.especialidade }
                                helperText={ error.especialidade }
                                defaultValue={ especialidades.filter(res => res.id == especialidade)[0] }
                                options={ especialidadesFiltered(especialidades) }
                                getOptionLabel={(option) => option.value}
                                getOptionSelected={(option, value) => value && option.id === value.id}
                                onChange={(event, newValue) => {
                                    delete error.especialidade
                                    setStep8(true)
                                    setEspecialidade(newValue)
                                    localStorage.setItem('especialidade', JSON.stringify(newValue));
                                }}
                            />
                        </Grid>
                    ) : ""
                }
                <Grid item md={12} className={ !step8 ? classes.hide : '' }>
                    <Grid container justifyContent="center">
                        <Grid item style={{marginRight: 20}}>
                            
                            <DButton type="button" onClick={ e => {
                                props.onStep(0);
                                setStep9(false)
                            
                            } } variant="secondary">Voltar</DButton>
                            
                        </Grid>
                        <Grid item>
                            <DButton type="button" onClick={ e => {

                                var dados = getDados()
                                if(validarFormulario({ ...dados })) {
                                    props.onStep(2)
                                }
                            } } className="btn-success" variant="primary">Avançar</DButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={ `tema-light ${classes.root} ${ props.step !== 2 ? classes.hide : '' }` } spacing={3}>
                {/* ID Pessoal */}
                <Grid container style={{marginBottom: '24px'}}>
                </Grid>
                <Grid container spacing={3} style={{marginBottom: '60px', backgroundColor: "#FCFCFC"}}>
                    
                    <Grid item md={12}>
                        <Typography className="text-primary text-bold" variant="h3" gutterBottom>
                                ID pessoal
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography className="color-neutral text-regular" variant="body1" gutterBottom>
                                O ID pessoal é totalmente confidencial e intransferível. É através dele que sua entrada na plataforma será permitida.
                        </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <Grid item md={12} xs={12} style={{marginBottom: '32px'}}>
                            <DInput 
                                fullWidth 
                                label="ID" 
                                name="id"
                                id="id"
                                placeholder="Ex: nome.sobrenome"
                                labelColor='black'
                                type={ 'text' }
                                positionIcon="end"
                                error={ !!error.id }
                                helperText={ error.id ? error.id : '' }
                                onChange={ e => {
                                        validaId(e.target.value);
                                        setId(e.target.value);
                                
                                        if(confirmId) {
                                            if(e.target.value === confirmId) {
                                                setErrors({ ...error, confirm_id: null })
                                            } else {
                                                setErrors({ ...error, confirm_id: "Os IDs não conferem" })
                                            }
                                        }
                                    } 
                                } 
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <DInput fullWidth label="Confirmação do ID" name="confirm_id"
                                type={ 'text' }
                                id="confirm_id"
                                labelColor='black'
                                positionIcon="end"
                                error={ !!error.confirm_id }
                                helperText={ error.confirm_id ? error.confirm_id : '' }
                                onChange={ e => {
                                    // if(checkCaractere && checkCaixa && checkNumero && checkMinCaractere && (e.target.value) === senha)
                                    //     setFormValid(true);
                                    // else
                                    //     setFormValid(false);
                                    
                                    setConfirmId(e.target.value)

                                    if(id === e.target.value) {
                                        setErrors({ ...error, confirm_id: null })
                                    } else {
                                        setErrors({ ...error, confirm_id: "Os IDs não conferem" })
                                    }
                                } } 
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <Divider
                            style={{ margin: "0px 50%" }}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid item md={5} style={{ marginTop: 15, marginBottom: 15 }}>
                        <IDInstructions
                            dontHaveUppercase={ checkIdDontHaveUppercase }
                            dontExceedsCharacters={ checkIdDontExceedsCharacters }
                            dontHaveSpaces={ checkIdDontHaveSpaces }
                            dontHaveOffensiveWords={ checkIdDontHaveOffensiveWords }
                            dontHaveSpecialCharacter={ checkIdDontHaveSpecialCharacter }
                        />
                    </Grid>
                </Grid>
                {/* Senha */}
                <Grid container spacing={3} style={{marginBottom: '20px', backgroundColor: "#FCFCFC"}}>
                    <Grid item md={12}>
                        <Typography className="text-primary text-bold" variant="h3" gutterBottom>
                                Senha
                        </Typography>
                    </Grid>
                    <Grid item md={5}>
                        <Grid item xs={12}>
                            <DInput fullWidth label="Senha" name="senha"
                                type={ visibility1 ? 'text' : 'password' }
                                positionIcon="end"
                                labelColor='black'
                                style={{marginBottom: '32px'}}
                                icon={ visibility1 ? <Visibility onClick={ e => setVisibility1(false) } /> : <VisibilityOff onClick={ e => setVisibility1(true) } /> }
                                error={ !!error.senha }
                                helperText={ error.senha ? error.senha : '' }
                                onChange={ e => {
                                    validaSenhaForte(e.target.value);
                                    setSenha(e.target.value);

                                    if (confirmSenha != "")
                                        checkSenhasIguais(e.target.value, confirmSenha)
                                
                                } } 
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DInput fullWidth label="Confirmar nova senha" name="confirm_senha"
                                type={ visibility2 ? 'text' : 'password' }
                                positionIcon="end"
                                labelColor='black'
                                icon={ visibility2 ? <Visibility onClick={ e => setVisibility2(false) } /> : <VisibilityOff onClick={ e => setVisibility2(true) } /> }
                                error={ !!error.confirm_senha }
                                helperText={ error.confirm_senha ? error.confirm_senha : '' }
                                onChange={ e => {
                                    if(checkCaractere && checkCaixa && checkNumero && checkMinCaractere && (e.target.value) === senha) {
                                        if(isValidUserId === true && checkUserIdEqual()) {
                                            
                                        } else {
                                            if(isValidUserId === null)
                                                toast.warning("Por favor, verifique o ID pessoal");

                                            if(isValidUserId === false)
                                                toast.warning("ID pessoal inválido");
                                        }
                                    }
                                    
                                    setConfirmSenha(e.target.value)
                                    checkSenhasIguais(senha, e.target.value)

                                } } 
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={2}>
                        <Divider
                            style={{ margin: "0px 50%" }}
                            orientation="vertical"
                        />
                    </Grid>
                    <Grid item md={5} style={{ marginTop: 15, marginBottom: 15 }}>
                        <PasswordInstrucions
                            checkMinCaractere={ checkMinCaractere }
                            checkCaixa={ checkCaixa }
                            checkNumero={ checkNumero }
                            checkCaractere={ checkCaractere } 
                            senhasIguais = { senhasIguais }
                            />
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <DButton 
                                type="button" 
                                style={{marginRight: 20}} 
                                className={classes.actionButton}
                                onClick={ e => 
                                    {
                                        props.onStep(1)
                                    } 
                                } 
                                variant="secondary"
                                >
                                    Voltar
                            </DButton>
                        </Grid>
                        <Grid item>
                            <DButton 
                            type="button" 
                            multiClass={`btn-success ${classes.actionButton}`}
                            disabled={ !formValid || props.loading } 
                            onClick={ e => {

                                var dados = getDados();

                                var conselhos = localStorage.getItem('conselhos') ? JSON.parse(localStorage.getItem('conselhos')) : null;

                                var conselhosFormat;

                                if(conselhos && Array.isArray(conselhos)){
                                    conselhosFormat = conselhos.map(res => {

                                        // console.log(res)

                                        const uf = states && states.find(x => x.id === res.uf);

                                        const cbo_description = cbos && cbos.find(x => x.code === res.descricao);

                                        // console.log(cbo_description)

                                        return {
                                            type: res.conselho,
                                            fields: [
                                                {id: 17, value: res.conselho}, 
                                                {id: 18, value: res.numero ? res.numero : "-"}, 
                                                {id: 19, value: ""}, 
                                                {id: 20, value: uf ? uf.value : "-" }, 
                                                {id: 21, value: cbo_description ? cbo_description.value : "-"}]
                                        }
                                    });

                                }
                                
                                dados.conselhos = conselhosFormat;

                                if(validarFormulario({ ...dados })) {
                                    dados.identidadeEmissao      = formatDate(identidadeEmissao);
                                    dados.dataNascimento         = formatDate(dataNascimento);
                                    dados.dataNaturalizacao      = formatDate(dataNaturalizacao);
                                    dados.passaporteDataEmissao  = formatDate(passaporteDataEmissao);
                                    dados.passaporteDataValidade = formatDate(passaporteDataValidade);

                                    props.enviarCadastro(dados, setErrors);
                                }

                            } } variant="primary" style={{color: 'white'}}>
                                { props.loading ?  <CircularProgress color="inherit" /> : 'Finalizar' }
                            </DButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}

export default FormCadastro;