import React, {useEffect} from 'react';
import "../index.scss";
import { TextField } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import TabelaUltimosAtendimentos from '../../NovasTabelas/TabelaUltimosAtendimentos'
import ModalCMD from './Components/ModalCMD'
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { getHealthAttendanceByPage, getAllHealthAttendance, getHealthAttendanceByPageFilter } from './../../../../api/health_attendances';
import { getPatientById } from "./../../../../api/patient";

function UltimosAtendimentosNewTab(props) {

  const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();

  const handleSelect = (health_attendance_id) => {
    getHealthAttendanceByPageFilter(localStorage.getItem("token"), 1, {
      search: health_attendance_id
    }).then(res => {
        if(res.status){
            setHealthAttendance(res.healthAttendances.patient[0]);
            props.onReset(true);
        }
    }).catch((err) => {
        console.log(err);
    });
  }

  return (
    <div className='fundo_new_tab'>
      <TabelaUltimosAtendimentos onSelect={ handleSelect } />
      {/* <div>
        <ModalCMD />
      </div> */}
    </div>

  )
}

UltimosAtendimentosNewTab.defaultProps = {
    onReset: function() {
      console.log('onReset');
    }
}

export default UltimosAtendimentosNewTab;