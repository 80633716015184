import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    card_subtitle: {
        color: theme.palette.neutral.black,
        fontSize: 14,
        margin: "0 0 16px"
    },
    edit_input: {
        backgroundColor: "#fff",
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    }
}));

function NumberComponent(props)
{

    const classes = useStyles();

    const [value, setValue] = useState("");

    return (<Grid item>                
        { props.showName && <h6 className={ classes.card_subtitle }>{ props.name }</h6> }
        <Grid container>
            <Grid item style={{ display: "flex", alignItems: "center" }}>
                <TextField
                    value={ value }
                    onChange={ e => {
                        let new_value = e.target.value;
                        setValue(new_value);
                        props.onChange(new_value);
                    } }
                    variant="outlined" classes={{ root: classes.edit_input }} /> { props.showUnit ? props.unit : "" }
            </Grid>
        </Grid>            
    </Grid>)
}

NumberComponent.defaultProps = {
    showName: false,
    name: null,
    showUnit: false,
    unit: null,
    onChange: function(value){
        console.log(value)
    }
}

export default NumberComponent;