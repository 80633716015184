import axios from "axios";
import env from "react-dotenv";

class PowerBI {

    getAccesToken(token){
        try {

            return axios.get(`${env.API_URL}/power-bi/auth?token=${token}`);

        } catch (error) {
            console.error(error)
            throw error;
        }
    }
}

export default new PowerBI();