import { React, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Backdrop, Button, Chip, CircularProgress} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DialogConsulta from "../../../../../../../AtendimentoImediato/AtendimentoList/components/AtendimentoGrid/modais/FichaConsulta";
import { getFormatDate } from "./../../../../../../../../helpers/formatacao";


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    iconKey: {
        color: theme.palette.primary.light,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "18px",
        color: theme.palette.neutral.black,
        padding: "6px 12px"
    },
    body: {
        fontSize: 14,
        padding: "6px 12px",
        whiteSpace: 'nowrap'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function PacienteGrid(props) {
    const classes = useStyles();
    const history = useHistory();
    const [backdrop, setBackdrop] = useState(true);
    const [openDialog, setOpen] = useState(false);
    const [attendance, setAttendance] = useState(null);

    useEffect(() => {
        props.healthAttendances != null && setBackdrop(false);
    }, [props]);

    const handleDialogOpen = () => {
        setOpen(true)
    };

    const handleDialogClose = (value) => {
        setOpen(false);
        
    };

    const openPdf = (healthId) => history.push(`/visualizar-exame/${healthId}`);

    return (
        <>
            <Backdrop style={{zIndex: 3000, color: 'white'}} open={ backdrop }>
                <CircularProgress color="inherit" />
            </Backdrop>
            {
              props.healthAttendances && openDialog && <DialogConsulta close={e => handleDialogClose()} open={openDialog} attendance={attendance} openPdf={e => openPdf(e)} />
            }
            <TableContainer style={{ marginTop:16 }}>
                <Table className={classes.table} aria-label="pacientes table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Protocolo</StyledTableCell>
                            <StyledTableCell><div style={{ display:"flex", alignItems: "center", justifyContent: "space-between" }}>Atendimento&nbsp;<KeyboardArrowDownIcon className={classes.iconKey} /></div></StyledTableCell>
                            <StyledTableCell><div style={{ display:"flex", alignItems: "center", justifyContent: "space-between" }}>Data&nbsp;e&nbsp;hora&nbsp;<KeyboardArrowDownIcon className={classes.iconKey} /></div></StyledTableCell>
                            <StyledTableCell>Finalização</StyledTableCell>
                            <StyledTableCell>Linha&nbsp;de&nbsp;cuidado</StyledTableCell>
                            <StyledTableCell>&nbsp;</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { props.healthAttendances && props.healthAttendances.length
                            ? 
                                props.healthAttendances.map((row, index) => (
                                   
                                    <StyledTableRow  key={ `pacientes-${ index }` }>
                                        <StyledTableCell>{ row.id }</StyledTableCell>
                                        <StyledTableCell>{ row.health_attendance_type && row.health_attendance_anamnese_form[row.health_attendance_anamnese_form.length - 1].anamnese_form.name }</StyledTableCell>
                                        <StyledTableCell>{ `${row.created_at !== null ? getFormatDate(row.created_at, "dd/MM/yyyy HH:mm") : "-"}`}</StyledTableCell>
                                        <StyledTableCell>{ `${row.closed_at !== null ? getFormatDate(row.closed_at, "dd/MM/yyyy HH:mm") : "-"}`}</StyledTableCell>
                                        <StyledTableCell>{ row.line_care && row.line_care.name ? row.line_care.name : "-" }</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <a className="text-link" onClick={ e => { handleDialogOpen(true); setAttendance(row) } } style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                            :
                                <StyledTableRow key={ '0' }>
                                    <StyledTableCell>{ '-' }</StyledTableCell>
                                    <StyledTableCell>{ '-' }</StyledTableCell>
                                    <StyledTableCell>{ '-' }</StyledTableCell>
                                    <StyledTableCell>{/* 'row.health_attendance_anamnese_form.length ? row.health_attendance_anamnese_form[0].medial_unit.company_name : '-' */} - </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <a className="text-link" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                    </StyledTableCell>
                                </StyledTableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
