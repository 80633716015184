import env from 'react-dotenv';

function linkQuestion(token, dados) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category/addFaq?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function unlinkQuestion(token, dados) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category/removeFaq?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export { 
    linkQuestion,
    unlinkQuestion
}