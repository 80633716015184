import React, {useState, useEffect, useContext} from "react";
import {Component} from "react";
import {Typography, Container, Card, CardContent, Grid,DialogActions,DialogContent} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ProfileCreate from "./component/ProfileCreate";
import Button from '@material-ui/core/Button';
import {Close} from "@material-ui/icons";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";

import { getAllProfiles, getAllPermissions, createProfile, updateProfile, deleteProfile, getAllPermissionsByProfile } from "../../api/profiles";
import { userDominios } from "../../api/dominios";
import DButton from "./../shared/DButton";
import { toast } from "react-toastify";

const styles = theme => ({
    addTagButton: {
        borderRadius: 25,
        borderWidth: 2,
        background: theme.palette.primary.light,
        color: theme.palette.background.snow, 
        borderColor: theme.palette.primary.light,
        textTransform: "none"
    },
    deleteButton: {
        color: theme.palette.support.error,
        textTransform: "none",
        marginRight: 16,
        fontWeight: 400
    },
    editButton: {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: 400
    },
    listItem: {
        padding: "12px 0",
        margin: 0,
        borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    },                        
    firstListItem: {
        padding: "12px 0",
        margin: 0,
    },   
});

class Perfis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingProfiles: true,
            loadingScreen: true,
            profiles: [],
            permissions: [],
            counterPermission: [],
            documents: [],
            novoPerfil: false,
            limpandoPerfil: false,
            isEdit: { status: false, id: null, duplicate: false },
            creatingNewProfile: false,
            createdProfile: false,
            createProfileError: false,
            reloadProfileUnit: false,
            setErrors: null,
            confirmClose: false,
            createRegistry: false,
            registryDuplicaded: false,
            delete: false,
            rowSelected: '',
            indexSelected: '',
        }
    }

    componentDidMount() {
        this.listProfiles();
        this.getPermissions();
        this.getDocuments();
    }

    async getDocuments() {
        await userDominios().then(res => {
            if(res.status) {
                this.setState({ ...this.state, documents: res.document_type })
            }
        });
    }

    async getPermissions() {

        await getAllPermissions(localStorage.getItem('token')).then(res => {
            if (res.status) {
                res.rolesGroup.map( function(permissionGroup) {
                    permissionGroup.roles.map( function(groupRole) {
                        groupRole.role_permission.map( function(rolePermission) {
                            rolePermission.permission.checked = false;
                        })
                    })
                })

                this.setState({ ...this.state, permissions: res.rolesGroup, counterPermission: res.rolesCounter})
            }
        });
    }

    async getPermissionsByProfile(profile_id) {
        return await getAllPermissionsByProfile(localStorage.getItem('token'), profile_id).then(res => {
            if (res.status) {
                // res.rolesGroup.map( function(permissionGroup) {
                //     permissionGroup.roles.map( function(groupRole) {
                //         groupRole.role_permission.map( function(rolePermission) {
                //             rolePermission.permission.checked = false;
                //         })
                //     })
                // })

                return res.rolesGroup;

                // this.setState({ ...this.state, permissions: res.rolesGroup })
                // this.setState({ ...this.state, counterPermission: res.rolesCounter })
            } else {
                return [];
            }
        });
    }

    async listProfiles() {
        this.setState({ ...this.state, profiles: [] })

        await getAllProfiles(localStorage.getItem('token')).then(res => {
            if (res.status) {
                res.profiles.data.map((row) => {
                    var new_state = this.state.profiles;
                    new_state.push({id: row.id, description: row.description, profile_permissions: row.profile_permissions, isLoading: false});
                    this.setState({ ...this.state, profiles: new_state })
                })
            } else {
                this.setState({ ...this.state, profiles: [] })
            }
        });
    }

    createProfile(dados) {

        this.setState({...this.state, createRegistry: true})

        var payload = {
            description: dados[0],
            permissions: dados[1],
            documents: dados[2]
        }
        
        createProfile(localStorage.getItem('token'), payload).then(res => {
            if (res.status) {
                toast.success('Processo realizado com sucesso.');
                this.setState({ ...this.state, isEdit: { status: false, id: null, duplicate: false }, novoPerfil: false, createdProfile: true, createRegistry: false, registryDuplicaded: false });
                this.listProfiles(); 
            } else {
                toast.error(res.message ? res.message : 'Erro ao realizar ação.');
                this.setState({ ...this.state, registryDuplicaded: res.details !== undefined && res.details.description !== undefined, createRegistry: false });
            }
        });
    }

    editProfile(dados) {

        if(this.state.isEdit.duplicate)
            this.createProfile(dados)
        else {
            let payload = {
                id: this.state.isEdit.id,
                description: dados[0],
                permissions: dados[1],
                documents: dados[2]
            }
    
            this.setState({...this.state, createRegistry: true})
            
            updateProfile(localStorage.getItem('token'), payload).then(res => {
                if (res.status) {
                    toast.success('Processo realizado com sucesso.');
                    this.setState({ ...this.state, isEdit: { status: false, id: null, duplicate: false }, novoPerfil: false, createdProfile: true, createRegistry: false, registryDuplicaded: false });
                    this.listProfiles();
                } else {
                    toast.error(res.message ? res.message : 'Erro ao realizar ação.');
                    this.setState({ ...this.state, registryDuplicaded: res.details !== undefined && res.details.description !== undefined, createRegistry: false });
                }
    
                this.setState({...this.state, createRegistry: false})
            });
        }

    }

    confirmDelete(profile, index) {
        var new_state = this.state.profiles;
        new_state[index].isLoading = !profile.isLoading;
        this.setState({ ...this.state, profiles: new_state })

        deleteProfile(localStorage.getItem('token'), profile.id).then(res => {
            if(res.status) {
                toast.success('Processo realizado com sucesso.');
                new_state.splice(index, 1);
                this.setState({ ...this.state, profiles: new_state })
            } else {
                toast.error(res.message ? res.message : 'Erro ao realizar ação.');
                new_state[index].isLoading = !profile.isLoading;
                this.setState({ ...this.state, profiles: new_state })
            }
        });
    }

    modalPerfil = () => {

        var permissions = this.state.permissions;

        // if(this.state.isEdit.status && this.state.isEdit.id) {
        //     var response = await this.getPermissionsByProfile(localStorage.getItem('token'), this.state.isEdit.id);
        //     console.log(response);
        // }

        return (
            <ProfileCreate  
                created={ this.state.createdProfile }
                setErrors={ setErrors => this.setState({ ...this.state, setErrors }) }
                error={ this.state.createProfileError }
                open={ this.state.novoPerfil }
                counterPermission={ this.state.counterPermission }
                permission= { permissions }
                documents={ this.state.documents }
                editData={ this.state.isEdit }
                loading={this.state.createRegistry}
                registryDuplicaded={this.state.registryDuplicaded}
                createProfile={ dados => {
                    this.createProfile(dados);
                } }
                editProfile={ dados => {
                    this.editProfile(dados);
                } }
                onConfirmClose={ e => this.setState({...this.state, confirmClose: true, registryDuplicaded: false}) }
                onClose={ e => {
                    this.setState({ ...this.state, isEdit: { status: false, id: null, duplicate: false }, novoPerfil: false, registryDuplicaded: false });
                } }
            />
        )
    }

    modalConfirmClose(props)
    {
        const styles = {            
            contentRemove: {
                width: 267,
                height: 65,
                padding: '0px 24px'
            },
            titleRemove: {
                borderBottom: 'none'
            },
            buttonBack: {
                minWidth: 74,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            },
            buttonConfirm: {
                minWidth: 145,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            }
        }
        
        return (
            <Dialog aria-labelledby="conditional-remove-title" scroll="body" onClose={ e => {
                this.setState({...this.state, confirmClose: false})
            } } open={ true }>
                <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item md={10}><Typography variant="body1" className="text-regular text-st">Se seguir com essa ação { this.state.isEdit.status ? 'todas as alterações serão perdidas' : 'todos os dados serão perdidos' }, deseja confirmar essa ação?</Typography></Grid>
                        <Grid item>
                            <Close onClick={ e => {
                                this.setState({...this.state, confirmClose: false})
                            } } />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent classes={styles.contentRemove}>
                    <Grid style={{ height: 50 }} container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <DButton type="button" style={styles.buttonBack} variant="secondary" size="none" className="btn-error" onClick={ e => this.setState({...this.state, confirmClose: false, duplicate: false}) }>
                                Voltar
                            </DButton>
                        </Grid>
                        <Grid item>
                            <DButton type="button" style={styles.buttonConfirm} variant="primary" size="none" className="btn-error" onClick={ e => this.setState({...this.state, confirmClose: false, novoPerfil: false, duplicate: false }) }>
                                Confirmar e fechar
                            </DButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

    modalConfirmDelete()
    {
        const styles = {            
            contentRemove: {
                width: 267,
                height: 65,
                padding: '0px 24px'
            },
            titleRemove: {
                borderBottom: 'none'
            },
            buttonBack: {
                minWidth: 74,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            },
            buttonConfirm: {
                minWidth: 145,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            }
        }
        return (
            <Dialog aria-labelledby="conditional-remove-title" scroll="body" onClose={ e => {
                this.setState({...this.state, delete: false})
            } } open={ true }>
                <DialogTitle id="conditional-remove-title" style={styles.titleRemove}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item md={10}><Typography variant="body1" className="text-regular text-st">Se seguir com essa ação { this.state.isEdit.status ? 'todas as alterações serão perdidas' : 'todos os dados serão perdidos' }, deseja confirmar essa ação?</Typography></Grid>
                        <Grid item>
                            <Close onClick={ e => {
                                this.setState({...this.state, delete: false})
                            } } />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent classes={styles.contentRemove}>
                    <Grid style={{ height: 50 }} container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <DButton type="button" style={styles.buttonBack} variant="secondary" size="none" className="btn-error" onClick={ e => this.setState({...this.state, delete: false}) }>
                                Voltar
                            </DButton>
                        </Grid>
                        <Grid item>
                            <DButton type="button" style={styles.buttonConfirm} variant="primary" size="none" className="btn-error" onClick={ e => { 
                                this.confirmDelete(this.state.rowSelected, this.state.indexSelected);
                                this.setState({...this.state, delete: false})
                                }}>
                                Confirmar Exclusão
                            </DButton>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
    

    render() {
        const styles = {
            titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14  },
            buttonToolbar: { marginTop: 24, marginBottom: 24 },
            flex: {display: 'flex'},


            contentRemove: {
                width: 267,
                height: 65,
                padding: '0px 24px'
            },
            titleRemove: {
                borderBottom: 'none'
            },
            buttonBack: {
                minWidth: 74,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            },
            buttonConfirm: {
                minWidth: 145,
                height: 33,
                borderRadius: 100,
                fontSize: 14,
                padding: '6px 18px'
            }
        }
        const { classes } = this.props;

        return (
            <Container className="tema-light">
                <Typography style={styles.titleTop} variant="h3" className="text-primary">Perfis e permissões</Typography>
                <Card style={{ background: "#f9f9f9" }}>
                    <CardContent>
                        <Grid container>
                            <Grid item md={9}>
                                <Typography variant="h4">Lista de perfis</Typography>
                            </Grid>
                            <Grid item md={3} style={ styles.flex } justifyContent="flex-end">
                                <Button
                                    variant="outlined"
                                    size="sm"
                                    startIcon={<AddCircleIcon style={{ fontSize: 20 }} />}
                                    className={ classes.addTagButton }
                                    onClick={ e => this.setState({ ...this.state, novoPerfil: true, limpandoPerfil: true, createdProfile: false, isEdit: false, registryDuplicaded: false }) }
                                >
                                    Criar perfil
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container style={{ paddingTop: 24 }}>
                            <Grid item md={12}>
                                <List>
                                { this.state.profiles.length
                                        ? 
                                            this.state.profiles.map((row, index) => (
                                                    <ListItem key={index} className={ index == 0 ? classes.firstListItem : classes.listItem }>
                                                        <ListItemText
                                                            primary={ row.description }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            { row.id > 1 
                                                                ? 
                                                                    <Button
                                                                        size="sm"
                                                                        startIcon={<DeleteIcon style={{ fontSize: 20 }} />}
                                                                        className={ classes.deleteButton }
                                                                        onClick={e => {
                                                                            if(!row.isLoading) {
                                                                                // this.confirmDelete(row, index)
                                                                                this.setState({ ...this.state, delete: true, rowSelected: row, indexSelected: index})
                                                                            }
                                                                        }}
                                                                    >
                                                                        { row.isLoading ? <CircularProgress size={ 15 } color="inherit" /> : 'Excluir' }
                                                                    </Button>
                                                                : 
                                                                    ''
                                                            }
                                                            <Button
                                                                size="sm"
                                                                startIcon={<FileCopyOutlinedIcon style={{ fontSize: 20 }} />}
                                                                className={ classes.editButton }
                                                                onClick={e => {
                                                                    this.setState({ ...this.state, isEdit: { status: true, id: row.id, duplicate: true }, novoPerfil: true });
                                                                }}
                                                            >
                                                                Duplicar
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                startIcon={<EditIcon style={{ fontSize: 20 }} />}
                                                                className={ classes.editButton }
                                                                onClick={e => {
                                                                    this.setState({ ...this.state, isEdit: { status: true, id: row.id, duplicate: false }, novoPerfil: true });
                                                                }}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                            ))
                                        :
                                            'Não existem perfis cadastrados'
                                    }
                                </List>
                            </Grid>
                        </Grid>
                       
                    </CardContent>
                </Card>
                { this.state.confirmClose ? this.modalConfirmClose() : '' }
                { this.state.delete ? this.modalConfirmDelete() : '' }
                {this.state.novoPerfil ? this.modalPerfil() : ''}
            </Container>
        )
    }
}

export default withStyles(styles)(Perfis);