import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import DRadio from "../../../../../../../shared/DRadio";
import "./styles.scss";

const EBPhysicalActivity = ({
  id,
  label,
  name,
  title,
  placeholder,
  field_category_id,
  value,
  additional_data,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer
}) => {
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [options, setOptions] = useState({});

  // useEffect(() => {
  //   handleInputSubmit({[id]: options})
  // },[options])

  const handleChange = (e) => {
    setOptions(e.target.value);
    handleInputSubmit({ [id]: e.target.value });
  };

  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      {field_category_id === 3 && (
        <div>
          <div className="mb-3">
            <div className="box-medicoes-clinicas">
              <Typography
                variant="body1"
                className="text-bold mb-2"
                style={{ fontSize: "0.8em" }}
              >
                <div dangerouslySetInnerHTML={{ __html: `${label ? label : "Atividade Físicas"}` }} />
              </Typography>
              {additional_data.options.length > 0 ? (
                <DRadio
                  id={id}
                  name={name}
                  disabled={readOnly}
                  // onChange={(e) => setOptions(e.target.value)}
                  onChange={handleChange}
                  value={options.length > 0 ?  options : answer[0]}
                  checked={additional_data.options.value}
                  options={additional_data.options}
                />
              ) : (
                "Não existe dados cadastrados"
              )}
              <br />
              <span style={{ fontSize: 12 }} className="text-light text-error">
                { erro[0] ? erro[0].value : '' }
              </span>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EBPhysicalActivity;
