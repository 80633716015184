import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@global': {
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      ".text-primary": {
        color: theme.palette.primary.light
      },
      ".bg-primary": {
        backgroundColor: theme.palette.primary.main
      },
      ".text-error": {
        color: theme.palette.support.error
      },
      ".MuiSvgIcon-popupIcon": {
         color: `${theme.palette.primary.main} !important`
      },
      ".MuiCheckbox-colorSecondary.Mui-checked .MuiIconButton-label .MuiSvgIcon-root": {
          color: `${theme.palette.primary.main} !important`
      },
      ".Radio-root.MuiFormControl-root .MuiSvgIcon-root": {
         color: `${theme.palette.primary.main}`
      },
      ".Radio-root.MuiFormControl-root .MuiFormControlLabel-label": {
        color: `${theme.palette.neutral.darkgray}`
     },
     ".MuiRadio-colorSecondary .Mui-checked": {
        color: `${theme.palette.primary.main} !important`
     },
     ".Mui-DRadio.Mui-checked .MuiSvgIcon-root:nth-child(2)": {
        color: `${theme.palette.primary.main} !important`
     },
     ".MuiFormLabel-root": {
        color: `${theme.palette.primary.main} !important`
     },
     ".MuiFormControlLabel-label": {
         fontSize: 14,
     },
     ".MuiFormControl-root .MuiInput-underline:after": {
        borderBottomColor: `${theme.palette.primary.main} !important`
     },
     ".text-link": {
        fontWeight: "unset !important",
        textDecoration: "unset !important",
        color: `${theme.palette.primary.main} !important`,
        cursor: "pointer"
     },
     ".text-link-bold": {
        fontWeight: "500 !important",
        textDecoration: "unset !important",
        color: `${theme.palette.primary.main} !important`,
        cursor: "pointer"
     },
     ".MuiFormControl-root label": {
        fontSize: 14,
        color: `${theme.palette.primary.main} !important`
     },
     ".MuiFormControl-root label.Mui-focused": {
        fontSize: '12px !important',
        color: `${theme.palette.primary.main} !important`
     },
     ".hr-d-color": {
      borderColor: `${theme.palette.primary.main} !important`
    },
     ".d-text-color": {
      color: `${theme.palette.primary.main} !important`
    },
    ".menu-button-sidebar": {
      background: `${theme.palette.primary.main} !important`
    },
    ".MuiAccordionSummary-root.Mui-expanded": {
      background: `${theme.palette.background.grass} !important`
    },
    ".fundo_new_tab .linha_divide_tab": {
      border: `0.5px solid ${theme.palette.primary.light} !important`,
      width: "100%"
    },
    ".fundo_new_tab .divide_new_tab": {
      color: theme.palette.primary.light
    },
    ".background-color": {
      background: theme.palette.primary.light
    },
    ".border-color": {
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;