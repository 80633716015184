import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import DButton from "./../shared/DButton";

const window_height = window.innerHeight;

const useStyles = makeStyles((theme) => ({
    container: {
        height: window_height
    }    
}));

function NaoAutorizado()
{
    const classes = useStyles();

    const [redirect, setRedirect] = useState(false);

    return (
        <>
            { redirect ? <Redirect to="/meu-perfil" /> : "" }
            <Grid container justifyContent='center' direction="column" spacing={3} alignItems='center' classes={{root: classes.container}}>
                <Grid item>
                    <Typography variant="h1" align='center'>Não autorizado</Typography>
                    <Typography variant="body1" align='center'>Você não tem o acesso necessário, fale com o <strong>administrador</strong>.</Typography>
                </Grid>
                <Grid item>
                    <DButton type="button" variant="primary" onClick={ e => setRedirect(true) }>
                        Ir para meu perfil
                    </DButton>
                </Grid>
            </Grid>
        </>
    )
}

export default NaoAutorizado;