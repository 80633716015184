import { makeStyles } from "@material-ui/core/styles";
import { format } from 'date-fns'
import { Grid, Container, Paper, Box, Typography, CardContent, Chip, Avatar, Collapse } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {usePatient} from "../../../../context/usePatient"
import SplitButton from "../../components/DSplitButton";
import CardPaciente from "../../components/CardPaciente";
import { ptBR } from "date-fns/locale";
import { Redirect, useHistory } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import SolicitacaoTipo from "./components/SolicitacaoTipo/SolicitacaoTipo";
import SolicitacaoSpeciality from "./components/SolicitacaoSpeciality";
import CancelCard from "./components/CancelCard";
import Calendar from "./components/Calendar";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.background.carpet,
  },
  paper: {
    width: "100%",
    background: theme.palette.background.snow,
    border: "0",
    borderRadius: 8,
    outline: 'none !important',
    height: "100%",
    overflow: 'hidden',
    boxShadow: "none"
  },
  gridPaper: {
    height: "100%",
    overflow: 'auto'
  },
  title: {
    fontSize: 14,
    marginBottom: 8,
    fontWeight: 500,
    margin: 16
  },
  dCard: {
    maxWidth: 230,
    background: theme.palette.background.snow,
    margin: "0 0 20px 0",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: 4,
  },
  hidden: {
    display: "none",
  },
  show: {
    display: "block",
  },
  chipFont: {
    fontSize: "12px !important",
    background: `${theme.palette.primary.light} !important`,
  },
  bgAvatarDoctor: {
    background: "transparent !important",
  }
}));


function SolicitacoesList(props) {
  const classes = useStyles();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [cancel, setCancel] = useState(null);

  const {filteredAttendances, healthAttendances, getAttendancesByFilter, getHealthAttendances, totaisByDateHealthAttendances} = usePatient();
  const {lockAdministrativeUnitMenu} = useContext(AppContext);

  useEffect(() => {
    getHealthAttendances(true, props.historyAttendance);
    
  }, []);
  
  function formatDate(date) {
    if(date && date != 'null') {
        date = typeof date != 'object' ? new Date(date) : date;

        return format(date, 'dd/MM/yyyy', { locale: ptBR });
    } else {
        return ''
    }
  }

  useEffect(() => {

    if(selectedDate && selectedDate !== undefined && props.historyAttendance) {
      let _date = formatDate(selectedDate);

      if(_date){
        getAttendancesByFilter({requested_at: _date}, true, props.historyAttendance);
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    if(!props.historyAttendance) {
      getHealthAttendances(true, props.historyAttendance)
    } else {
      setSelectedDate(new Date())
    }
  }, [props.historyAttendance]);
  
  const openPdf = (healthId) => history.push(`/visualizar-exame/${healthId}`);

  return (
    <>
    {lockAdministrativeUnitMenu ? <Redirect to="/meu-perfil" /> : ''}
    <Container maxWidth="lg" className={ classes.bg } style={{ marginTop: 80, height: "calc(100vh - 230px)", overflow: "auto" }}>
      <Grid container spacing={4} style={{ height: "100%" }}>
        <Grid item md={6} className={props.historyAttendance ? classes.show : classes.hidden} style={{ height: "100%", overflow: "auto", paddingBottom: 0 }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Calendar totais={ totaisByDateHealthAttendances } onChange={ date => setSelectedDate(date) } />
            </Grid>
          </Grid>
          {/* <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <p>Selecione uma data</p>
            <Collapse in={props.historyAttendance}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <DatePicker
                  className="date-picker-custom"
                  autoOk
                  disableToolbar
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  views={['date', 'year', 'month']}
                  value={selectedDate}
                  onChange={setSelectedDate}
                />
              </MuiPickersUtilsProvider>
            </Collapse>
          </div> */}
        </Grid>
        <Grid item md={3} sm={6} className={props.historyAttendance ? classes.hidden : classes.show} style={{ height: "100%", paddingBottom: 0 }}>
          <Paper className={classes.paper}>
            <Typography className={ classes.title }>
              {/* Aberto */}
              Aguardando atendimento {filteredAttendances && filteredAttendances.open && filteredAttendances.open.length ? `(${filteredAttendances.open.length})` : ""}
            </Typography>
            <Grid container justifyContent="center" spacing={1} classes={{root: classes.gridPaper}}>
              <Grid item classes={{root: classes.gridPaper}}>
                {
                  filteredAttendances && filteredAttendances.open.map((item, index) => {
                    return (
                      <CardContent className={classes.dCard}>
                        { cancel && cancel === item.id ? 
                          <CancelCard id={item.id} 
                            paciente={item.patient.full_name}
                            cpf={item.patient.cpf}
                            tipoConsulta={ item.interconsultation == "1" ? 'Interconsulta' : (item.service_character == "2" ? 'Monitoramento' : 'Imediata') } 
                            onCanceled={ e => {
                              getHealthAttendances(true)
                              setCancel(null);
                            }}
                            onClose={ e => setCancel(null) } />
                          :
                          <>
                            <Box mb="1rem" flexWrap="wrap" display="flex">
                              <SolicitacaoSpeciality attendance={ item } />
                              <SolicitacaoTipo healthAttendance={item} />
                              <SplitButton onCancel={e => setCancel(item.id)}
                                updateAttendances={e => getHealthAttendances(true)} 
                                openPdf={healthId => openPdf(healthId)} 
                                attendance={item} 
                                unidadesVinculadas={props.unidadesVinculadas} 
                                unidadesRef={props.unidadesRef} 
                                userDominios={props.userDominios} />
                            </Box>
                            <CardPaciente value={item} key={ `card-patient-open-key-${item.id}` } />
                          </>
                        }
                      </CardContent>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} className={props.historyAttendance ? classes.hidden : classes.show} style={{ height: "100%", paddingBottom: 0 }}>
          <Paper className={classes.paper}>
            <Typography className={ classes.title }>
              {/* Assumido */}
              Em atendimento {filteredAttendances && filteredAttendances.assumed && filteredAttendances.assumed.length ? `(${filteredAttendances.assumed.length})` : ""}
            </Typography>
            <Grid container justifyContent="center" spacing={1} classes={{root: classes.gridPaper}}>
              <Grid item classes={{root: classes.gridPaper}}>
                {
                  filteredAttendances && filteredAttendances.assumed.map((item, index) => {
                    return (
                      <CardContent className={classes.dCard}>
                        <Box mb="1rem" flexWrap="wrap" display="flex">
                          <SolicitacaoSpeciality attendance={ item } />                      
                          <SolicitacaoTipo healthAttendance={item} />
                          <SplitButton updateAttendances={e => getHealthAttendances(true)} openPdf={healthId => openPdf(healthId)} attendance={item} unidadesVinculadas={props.unidadesVinculadas} unidadesRef={props.unidadesRef} userDominios={props.userDominios} />
                        </Box>                            
                        <CardPaciente value={item} key={ `card-patient-assumed-key-${item.id}` } />
                      </CardContent>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} style={{ height: "100%", paddingBottom: 0 }}>
          <Paper className={classes.paper}>
            <Typography className={ classes.title }>
              Atendidas { !props.historyAttendance ? 'hoje' : '' } {filteredAttendances && filteredAttendances.finished && filteredAttendances.finished.length ? `(${filteredAttendances.finished.length})` : ""}
            </Typography>
            <Grid container justifyContent="center" spacing={1} classes={{root: classes.gridPaper}}>
              <Grid item classes={{root: classes.gridPaper}}>
                {
                  filteredAttendances && filteredAttendances.finished.map((item, index) => {
                    return (
                      <CardContent className={classes.dCard}>
                        <Box mb="1rem" flexWrap="wrap" display="flex">
                          <SolicitacaoSpeciality attendance={ item } />
                          <SolicitacaoTipo healthAttendance={item} />                      
                          <SplitButton options="finished" updateAttendances={e => getHealthAttendances(true)} openPdf={healthId => openPdf(healthId)} attendance={item} unidadesVinculadas={props.unidadesVinculadas} unidadesRef={props.unidadesRef} userDominios={props.userDominios} />
                        </Box>                            
                        <CardPaciente value={item} key={ `card-patient-finished-key-${item.id}` } />
                      </CardContent>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={3} sm={6} style={{ height: "100%", paddingBottom: 0 }}>
          <Paper className={classes.paper}>
            <Typography className={ classes.title }>
              { `Canceladas ${!props.historyAttendance ? 'hoje' : ''} ${filteredAttendances && filteredAttendances.canceled_today && filteredAttendances.canceled_today.length ? `(${filteredAttendances.canceled_today.length})` : ""}` }
            </Typography>
            <Grid container justifyContent="center" spacing={1} classes={{root: classes.gridPaper}}>
              <Grid item classes={{root: classes.gridPaper}}>
                {
                  filteredAttendances && filteredAttendances.canceled_today.map((item, index) => {
                    return (
                      <CardContent className={classes.dCard}>
                        <Box mb="1rem" flexWrap="wrap" display="flex">
                          <SolicitacaoSpeciality attendance={ item } />
                          <SolicitacaoTipo healthAttendance={item} />
                          <SplitButton options="finished" isCancel={true} updateAttendances={e => getHealthAttendances(true)} openPdf={healthId => openPdf(healthId)} attendance={item} unidadesVinculadas={props.unidadesVinculadas} unidadesRef={props.unidadesRef} userDominios={props.userDominios} />
                        </Box>                            
                        <CardPaciente value={item} key={ `card-patient-finished-key-${item.id}` } />
                      </CardContent>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </>
  );
}

export default SolicitacoesList;