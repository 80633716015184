import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  AddCircle,
  Block,
  Create,
  Delete,
  Save,
  Send,
} from "@material-ui/icons";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Clear } from "@material-ui/icons";
import DButton from "../../../shared/DButton";
import { Link } from "react-router-dom";
import DAutoComplete from "../../../shared/DAutoComplete";
import DMultAutoComplete from "../../../shared/DMultAutoComplete";
import DInput from "../../../shared/DInput";
import useError from "../../../../hooks/useError";
import PropTypes from "prop-types";
import {
  required,
  requiredTwoName,
  requiredOptionalTwoName,
  validaEmail,
  requiredUnit,
  requiredUnitCustom,
} from "../../../../helpers/validacoes";
import { mascaraCPF } from "../../../../helpers/formatacao";

// API Requests
import {
  getUserInfoById,
  updateUser,
  updateCustomUser,
  createUserMedicalUnit,
  deleteUserMedicalUnit,
  blockUser,
  unblockUser,
  deleteUser,
  resendInvite,
  updateUserProfile,
} from "../../../../api/user";
import { getAllUnidades } from "../../../../api/unidades";
import { getAllProfiles } from "../../../../api/profiles";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ProfissionalViewModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "90%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: "1px solid #949494",
    borderRadius: 15,
    overflowX: "hidden",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: "solid 1px #C7C7C7",
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: "solid 1px #C7C7C7",
    marginTop: 20,
  },
  grid: {
    display: "flex",
  },
  gridLinkWrap: {
    flexWrap: "nowrap!important",
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 5,
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  cardChip: {
    background: "#F4F4F4",
  },
  gridLink: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: "-4px",
  },
  icon: {
    color: "#D13D2E",
  },
  iconSuccess: {
    color: "#2A814E",
  },
  linkDanger: {
    color: "#D13D2E",
    textDecoration: "none",
    fontSize: 14,
    minWidth: "max-content",
  },
  professinalField: {
    fontSize: 14,
  },
  linkSuccess: {
    color: "#2A814E",
    textDecoration: "none",
  },
  contentLoad: {
    textAlign: "center",
    minHeight: 150,
  },
  containerEditUnit: {
    display: "flex",
    alignItems: "end",
    marginBottom: "16px",
  },
  textWidth: {
    minWidth: "max-content!important",
  },
}));

export default function ProfissionalViewModal(props) {
  // Style
  const classes = useStyles();
  const theme = useTheme();
  const [modalStyle] = useState(getModalStyle);

  // Edit switchs
  const [editDadosProfissional, setEditDadosProfissional] = useState(false);
  const [editUnidades, setEditUnidades] = useState(false);

  // Loading switchs
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editDadosProfissionalLoading, setEditDadosProfissionalLoading] =
    useState(false);
  const [editUnidadesLoading, setEditUnidadesLoading] = useState(false);
  const [resendInviteLoading, setResendInviteLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [unblockLoading, setUnblockLoading] = useState(false);

  // Select options
  const [units, setUnits] = useState([]);
  const [profiles, setProfiles] = useState([]);

  // Professional data
  const [professionalNome, setProfessionalNome] = useState("");
  const [professionalNomeSocial, setProfessionalNomeSocial] = useState("");
  const [professionalEmail, setProfessionalEmail] = useState("");
  const [professionalTel, setProfessionalTel] = useState("");
  const [professionalCPF, setProfessionalCPF] = useState("");
  const [professionalInvite, setProfessionalInvite] = useState("");
  const [professionalBloqueio, setProfessionalBloqueio] = useState("");
  const [professionalUnits, setProfessionalUnits] = useState([]);
  const [profissinalProfileUnit, setProfissinalProfileUnit] = useState([]);
  const [professionalUnitsPrev, setProfessionalUnitsPrev] = useState([]);
  const [profissionalInviteStatus, setProfissionalInviteStatus] =
    useState(false);

  // Loading
  const [loading, setLoading] = useState(false);
  const [loadingDependences1, setLoadingDependences1] = useState(false);
  const [loadingDependences2, setLoadingDependences2] = useState(false);

  // Validators controller
  const [postSection, setPostSection] = useState(0);
  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    var validators = {};

    if (postSection == 1) {
      validators = {
        // Dados pessoais
        professionalNome: requiredTwoName,
        professionalEmail: validaEmail,
        professionalNomeSocial: requiredOptionalTwoName,
      };
    }

    if (postSection == 2) {
      validators = {
        // Unidades
        profissinalProfileUnit: requiredUnitCustom,
      };
    }

    return validators;
  }

  useEffect(() => {
    if (postSection == 1) {
      !editDadosProfissionalLoading && saveDadosProfissional();
    }

    if (postSection == 2) {
      !editUnidadesLoading && saveDadosUnidades();
    }
  }, [postSection]);

  function mapUnidades(res) {
    let new_units = [];

    if (res.user.user_invite.active !== 1 && res.user.profiles.length) {
      res.user.profiles.map((profile) => {
        if (!profile) return;
        if (new_units.length) {
          new_units.map((item, index) => {
            if (profile.medical_unit_id == item.medical_unit_id) {
              new_units[index].profiles.push(profile.id);
            } else {
              new_units.push({
                profiles: [profile.id],
                medical_unit_id: profile.medical_unit_id,
              });
            }
          });
        } else {
          new_units.push({
            profiles: [profile.id],
            medical_unit_id: profile.medical_unit_id,
          });
        }
      });
    } else if (
      res.user.user_invite.active === 1 &&
      res.user.user_invite.units.length
    ) {
      res.user.user_invite.units.map((profile) => {
        if (!profile) return;
        if (new_units.length) {
          new_units.map((item, index) => {
            if (profile.medical_unit_id == item.medical_unit_id) {
              new_units[index].profiles.push(profile.id);
            } else {
              new_units.push({
                profiles: [profile.id],
                medical_unit_id: profile.medical_unit_id,
              });
            }
          });
        } else {
          new_units.push({
            profiles: [profile.id],
            medical_unit_id: profile.medical_unit_id,
          });
        }
      });
    } else {
      new_units.push({ profiles: [], medical_unit_id: null });
    }

    return new_units;
  }

  useEffect(() => {
    if (props.idProfissional) {
      setProfessionalNome("");
      setProfessionalNomeSocial("");
      setProfessionalEmail("");
      setProfessionalTel("");
      setProfessionalCPF("");
      setProfessionalBloqueio("");
      setProfessionalUnits([]);
      setLoading(true);

      getUserInfoById(localStorage.getItem("token"), props.idProfissional).then(
        (res) => {
          setProfessionalInvite(res.user.user_invite.id);
          setProfissionalInviteStatus(res.user.user_invite.active === 1);

          // var new_units = mapUnidades(res);

          if (props.statusProfissional != "Aguardando") {
            setProfessionalTel(res.contact[0].value);
            setProfessionalCPF(res.document[0].fields[0].value);
          }

          if (props.statusProfissional == "Bloqueado") {
            setProfessionalBloqueio(res.user.blocked_by);
          }

          // setProfessionalUnits(new_units);
          if (res.user.active === "Aguardando")
            setProfissinalProfileUnit(
              res.user.user_invite.user_invite_profiles
            );
          else setProfissinalProfileUnit(res.user.user_profile);

          // setProfessionalUnitsPrev(new_units);
          setProfessionalNome(res.user.full_name);
          setProfessionalNomeSocial(res.user.social_name);
          setProfessionalEmail(res.user.email);

          if (res.status) setLoading(false);
          else toast.error("Erro inesperado, tente novamente.");
        }
      );
    }
  }, [props.idProfissional]);

  useEffect(() => {
    setLoadingDependences1(true);
    getAllUnidades(localStorage.getItem("token")).then((res) => {
      if (!res.error && res.length > 0) {
        setUnits(res);
      }
      setLoadingDependences1(false);
    });

    setLoadingDependences2(true);
    getAllProfiles(localStorage.getItem("token")).then((res) => {
      if (res.status) {
        setProfiles(
          res.profiles.data.map((profile) => {
            return {
              id: profile.id,
              value: profile.description,
            };
          })
        );
      }
      setLoadingDependences2(false);
    });
  }, []);

  function addUnitsAndProfilesSelect() {
    delete error.profissinalProfileUnit;
    setProfissinalProfileUnit({
      unidades: [
        ...profissinalProfileUnit.unidades,
        { id: null, name: "", profiles: [] },
      ],
    });
  }

  function saveDadosProfissional() {
    if (editDadosProfissional) {
      var dadosValidation = {
        professionalNome,
        professionalEmail,
        professionalNomeSocial,
      };

      if (validarFormulario({ ...dadosValidation })) {
        setEditDadosProfissionalLoading(true);

        var dados = {
          user: {
            full_name: professionalNome,
            social_name:
              professionalNomeSocial == "" ? null : professionalNomeSocial,
            email: professionalEmail,
          },
        };

        updateCustomUser(
          localStorage.getItem("token"),
          props.idProfissional,
          dados
        )
          .then((res) => {
            setEditDadosProfissionalLoading(false);
            setEditDadosProfissional(false);
            toast.success("Dados salvos com sucesso!");
          })
          .catch((erro) => {
            setEditDadosProfissionalLoading(false);
            toast.error("Ocorreu um erro ao salvar os dados!");
          });

        setPostSection(0);
      } else {
        setPostSection(0);
      }
    } else {
      setEditDadosProfissional(true);
    }
  }

  function saveDadosUnidades() {
    if (editUnidades) {
      var dadosValidation = {
        profissinalProfileUnit,
      };

      if (validarFormulario({ ...dadosValidation })) {
        setEditUnidadesLoading(true);

        updateUserProfile(
          localStorage.getItem("token"),
          { unidades: profissinalProfileUnit.unidades },
          props.idProfissional
        ).then((res) => {
          setEditUnidadesLoading(false);
          if (res.status) {
            toast.success("Unidade e perfis atualizados com sucesso.");
            setEditUnidades(false);
            setPostSection(0);
          } else {
            toast.warn("Erro inesperado.");
            setPostSection(0);
          }
        });
      } else {
        setPostSection(0);
      }
    } else {
      setEditUnidades(true);
    }
  }

  async function dadosUnidadesCreate() {
    for (var i = 0; i < professionalUnits.length; i++) {
      await createUserMedicalUnit(
        localStorage.getItem("token"),
        professionalUnits[i],
        props.idProfissional
      )
        .then((res) => {
          if (professionalUnits.length == i + 1) {
            setEditUnidadesLoading(false);

            if (res.status) {
              setEditUnidades(false);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setPostSection(0);
  }

  function reenviarConvite() {
    setResendInviteLoading(true);

    resendInvite(localStorage.getItem("token"), professionalInvite)
      .then((res) => {
        setResendInviteLoading(false);

        if (res.status) {
          props.onClose(true);
          toast.success("Convite enviado.");
        } else {
          toast.error("Erro: não foi possível reenviar o convite.");
          setResendInviteLoading(false);
        }
      })
      .catch((erro) => {
        console.log(erro);
        toast.error("Erro: não foi possível reenviar o convite.");
        setResendInviteLoading(false);
      });
  }

  function bloquearProfissional() {
    setBlockLoading(true);

    blockUser(localStorage.getItem("token"), props.idProfissional).then(
      (res) => {
        setBlockLoading(false);

        if (res.status) {
          props.onClose(true);
          toast.success("Profissional bloqueado com sucesso.");
        }
      }
    );
  }

  function desbloquearProfissional() {
    setUnblockLoading(true);

    unblockUser(localStorage.getItem("token"), props.idProfissional).then(
      (res) => {
        setUnblockLoading(false);

        if (res.status) {
          props.onClose(true);
          toast.success("Profissional desbloqueado com sucesso.");
        }
      }
    );
  }

  function excluirProfissional() {
    setDeleteLoading(true);

    deleteUser(localStorage.getItem("token"), props.idProfissional).then(
      (res) => {
        setDeleteLoading(false);

        if (res.status) {
          toast.success("Usuário excluído com sucesso!");
          props.onClose(true);
        } else {
          toast.error("Não foi possível excluir o usuário.");
        }
      }
    );
  }

  function getProfilesByUnit(index) {
    if (
      !profissinalProfileUnit.unidades[index] ||
      !profissinalProfileUnit.unidades[index].id
    )
      return [];

    let new_profiles = profiles.filter((profile) =>
      units
        .filter((unit) => unit.id === profissinalProfileUnit.unidades[index].id)
        .find(
          (unidades) =>
            unidades.medical_unit_profile &&
            unidades.medical_unit_profile.length > 0 &&
            unidades.medical_unit_profile.find(
              (unidade_profile) => unidade_profile.profile_id === profile.id
            )
        )
    );

    if (new_profiles.length <= 0)
      toast.warn("Nenhum perfil relacionado a essa unidade.");

    return new_profiles;
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={`${classes.paper}`}>
          <>
            <div className={classes.headermodal}>
              <h2
                className="simple-modal-title text-mt text-regular"
                style={{
                  alignItems: "center",
                  display: "flex",
                  color: theme.palette.primary.main,
                }}
              >
                <strong
                  style={
                    (props.aceiteTermoProfissional == "no" &&
                      props.statusProfissional != "Aguardando") ||
                    props.statusProfissional == "Bloqueado"
                      ? { color: "red" }
                      : {}
                  }
                >
                  {props.nomeProfissional ? props.nomeProfissional : ""}
                </strong>
              </h2>
              <Clear
                style={{ color: "#c5b5b5", cursor: "pointer" }}
                onClick={(e) => {
                  setEditDadosProfissional(false);
                  setEditUnidades(false);

                  delete error.professionalNome;
                  delete error.professionalEmail;
                  delete error.professionalUnits;

                  props.onClose(true);
                }}
              />
            </div>

            {loading || loadingDependences1 || loadingDependences2 ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                classes={{ root: classes.contentLoad }}
              >
                <Grid item md={12}>
                  <CircularProgress
                    size={25}
                    color="primary"
                    classes={{ colorPrimary: theme.palette.primary.main }}
                  />
                  <Typography
                    variant="body1"
                    className="text-regular text-st"
                    style={{ color: theme.palette.primary.main }}
                  >
                    Carregando
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <>
                {editDadosProfissional ? (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      className="tema-light"
                    >
                      <>
                        <Grid item md={11} sm={6} className="md-mb-3">
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                style={{ color: theme.palette.primary.light }}
                                variant="h6"
                              >
                                Dados do profissional
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              {editDadosProfissionalLoading ? (
                                <div style={{ color: "green" }}>
                                  <strong>
                                    <CircularProgress
                                      size={16}
                                      color="inherit"
                                    />{" "}
                                    Salvando...
                                  </strong>
                                </div>
                              ) : (
                                <DButton
                                  type="submit"
                                  variant="primary"
                                  icon={<Save fontSize="small" />}
                                  size="sm"
                                  className="btn-success"
                                  onClick={(e) => setPostSection(1)}
                                >
                                  Salvar
                                </DButton>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={11} sm={6} className="md-mb-3">
                          <DInput
                            id="name"
                            label="Nome Completo"
                            placeholder="Nome"
                            type="text"
                            fullWidth={true}
                            inputProps={{ maxLength: 100 }}
                            error={error.professionalNome}
                            helperText={error.professionalNome}
                            positionIcon="end"
                            onChange={(e) => {
                              delete error.professionalNome;
                              setProfessionalNome(e.target.value);
                            }}
                            value={professionalNome}
                          />
                        </Grid>
                        <Grid item md={11} sm={6} className="md-mb-3">
                          <DInput
                            id="socialName"
                            label="Nome social"
                            placeholder="Nome social"
                            type="text"
                            inputProps={{ maxLength: 100 }}
                            fullWidth={true}
                            error={error.professionalNomeSocial}
                            helperText={error.professionalNomeSocial}
                            positionIcon="end"
                            onChange={(e) => {
                              var _value = e.target.value.replace(
                                /[^a-zA-Zà-úÀ-Ú"' ]/g,
                                ""
                              );

                              setProfessionalNomeSocial(_value);
                            }}
                            value={professionalNomeSocial}
                          />
                        </Grid>
                        <Grid item md={11}>
                          <DInput
                            id="email"
                            label="E-mail"
                            placeholder="E-mail"
                            type="email"
                            error={error.professionalEmail}
                            helperText={error.professionalEmail}
                            positionIcon="end"
                            fullWidth
                            inputProps={{ maxLength: 254 }}
                            onChange={(e) => {
                              delete error.professionalEmail;
                              setProfessionalEmail(e.target.value);
                            }}
                            value={professionalEmail}
                          />
                        </Grid>
                        <Grid item md={11} sm={6}>
                          <hr className="md-mb-3 md-mt-3" />
                        </Grid>
                      </>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      className="tema-light"
                    >
                      <>
                        <Grid item md={11} sm={6}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                style={{ color: theme.palette.primary.light }}
                                variant="h6"
                              >
                                Dados do profissional
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              {(props.aceiteTermoProfissional == "yes" ||
                                props.statusProfissional == "Aguardando") &&
                              props.statusProfissional != "Bloqueado" ? (
                                <DButton
                                  onClick={(e) => saveDadosProfissional()}
                                  variant="primary"
                                  icon={<Create fontSize="small" />}
                                  size="sm"
                                >
                                  Editar
                                </DButton>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={11} sm={6}>
                          <Typography
                            className={`text-regular mb-1 ${classes.professinalField}`}
                            variant="body1"
                            style={{
                              paddingBottom: "10px",
                              paddingTop: "10px",
                            }}
                          >
                            <strong>Nome completo: </strong>
                            {professionalNome}
                          </Typography>
                          <Typography
                            className={`text-regular mb-1 ${classes.professinalField}`}
                            variant="body1"
                            style={{ paddingBottom: "10px" }}
                          >
                            <strong>Nome social:</strong>{" "}
                            {professionalNomeSocial == "" ||
                            !professionalNomeSocial
                              ? "-"
                              : professionalNomeSocial}
                          </Typography>
                          <Typography
                            className={`text-regular mb-1 ${classes.professinalField}`}
                            variant="body1"
                            style={{ paddingBottom: "10px" }}
                          >
                            <strong>E-mail:</strong> {professionalEmail}
                          </Typography>
                          {props.statusProfissional &&
                          props.statusProfissional != "Aguardando" ? (
                            <>
                              <Typography
                                className={`text-regular mb-1 ${classes.professinalField}`}
                                variant="body1"
                                style={{ paddingBottom: "10px" }}
                              >
                                <strong>Telefone: </strong> {professionalTel}
                              </Typography>
                              <Typography
                                className={`text-regular mb-1 ${classes.professinalField}`}
                                variant="body1"
                                style={{ paddingBottom: "10px" }}
                              >
                                <strong>CPF: </strong> {professionalCPF}
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                          {(props.aceiteTermoProfissional == "no" &&
                            props.statusProfissional != "Aguardando") ||
                          props.statusProfissional == "Bloqueado" ? (
                            <>
                              <Typography
                                className={`text-regular mb-1 ${classes.professinalField}`}
                                variant="body1"
                                style={{ paddingBottom: "10px" }}
                              >
                                <strong style={{ color: "red" }}>
                                  Motivo do bloqueio:
                                </strong>{" "}
                                {professionalBloqueio &&
                                props.statusProfissional == "Bloqueado"
                                  ? "Bloqueado por " + professionalBloqueio
                                  : "Não aceitou os termos de uso"}
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item md={11} sm={6}>
                          <hr className="md-mb-3 md-mt-3" />
                        </Grid>
                      </>
                    </Grid>
                  </>
                )}

                {editUnidades ? (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      className="tema-light"
                    >
                      <>
                        <Grid item md={11} sm={6}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                style={{ color: theme.palette.primary.light }}
                                variant="h6"
                              >
                                Unidades e Perfis
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              {editUnidadesLoading ? (
                                <div style={{ color: "green" }}>
                                  <strong>
                                    <CircularProgress
                                      size={16}
                                      color="inherit"
                                    />{" "}
                                    Salvando...
                                  </strong>
                                </div>
                              ) : (
                                <DButton
                                  type="submit"
                                  variant="primary"
                                  icon={<Save fontSize="small" />}
                                  size="sm"
                                  className="btn-success"
                                  onClick={(e) => setPostSection(2)}
                                >
                                  Salvar
                                </DButton>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={11} sm={6}>
                          <br />
                        </Grid>
                      </>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      className="tema-light"
                    >
                      {/* {professionalUnits.length && professionalUnits.map((unit, index) => { */}
                      {profissinalProfileUnit &&
                        profissinalProfileUnit.unidades &&
                        profissinalProfileUnit.unidades.map((item, index) => {
                          return (
                            <>
                              <Grid
                                container
                                md={11}
                                spacing={2}
                                justifyContent="space-between"
                                className={classes.containerEditUnit}
                              >
                                <Grid item md={6}>
                                  <DAutoComplete
                                    id="unit"
                                    label="Unidade"
                                    placeholder="Unidade"
                                    fullWidth={true}
                                    defaultValue={units.find(
                                      (it) => it.id == item.id
                                    )}
                                    error={error.profissinalProfileUnit}
                                    // helperText={error.professionalUnits}
                                    options={units}
                                    getOptionLabel={(option) =>
                                      option.company_name
                                    }
                                    onChange={(event, newValue) => {
                                      delete error.profissinalProfileUnit;

                                      var new_value = [
                                        ...profissinalProfileUnit.unidades,
                                      ];

                                      new_value[index].id = newValue
                                        ? newValue.id
                                        : null;
                                      new_value[index].name = newValue
                                        ? newValue.company_name
                                        : null;
                                      new_value[index].profiles = [];

                                      setProfissinalProfileUnit({
                                        unidades: new_value,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6}>
                                  <DMultAutoComplete
                                    id="unit"
                                    label="Perfil"
                                    placeholder="Perfil"
                                    fullWidth={true}
                                    value={profiles.filter((res) =>
                                      item.profiles.find(
                                        (it) => it.id == res.id
                                      )
                                    )}
                                    error={error.profissinalProfileUnit}
                                    // helperText={error.professionalUnits}
                                    options={getProfilesByUnit(index)}
                                    // options={[]}
                                    getOptionLabel={(option) => option.value}
                                    onChange={(event, newValue) => {
                                      delete error.profissinalProfileUnit;

                                      var profile_units =
                                        profissinalProfileUnit.unidades;
                                      var new_value = [...profile_units];
                                      var arrayValues = [];

                                      newValue.map((item) => {
                                        arrayValues.push({
                                          id: item.id,
                                          name: item.value,
                                        });
                                      });

                                      new_value[index].profiles = arrayValues;

                                      setProfissinalProfileUnit({
                                        unidades: new_value,
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                      {error.profissinalProfileUnit ? (
                        <Grid item md={12}>
                          <Typography
                            variant="body1"
                            className="text-center text-regular text-st text-error"
                          >
                            {error.profissinalProfileUnit}
                          </Typography>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      className="tema-light"
                    >
                      <Grid item md={11} className="mt-3">
                        <DButton
                          onClick={(e) => {
                            addUnitsAndProfilesSelect();
                          }}
                          variant="primary"
                          icon={<AddCircle fontSize="small" />}
                          size="sm"
                        >
                          Adicionar outra unidade
                        </DButton>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      justifyContent="center"
                      className="tema-light"
                    >
                      <>
                        <Grid item md={11} sm={6} style={{marginBottom: '1em'}}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography
                                style={{ color: theme.palette.primary.light }}
                                variant="h6"
                              >
                                Unidades e Perfis
                              </Typography>
                            </Grid>
                            <Grid item md={3}>
                              {(props.aceiteTermoProfissional == "yes" ||
                                props.statusProfissional == "Aguardando") &&
                              props.statusProfissional != "Bloqueado" ? (
                                <DButton
                                  onClick={(e) => saveDadosUnidades()}
                                  variant="primary"
                                  icon={<Create fontSize="small" />}
                                  size="sm"
                                >
                                  Editar
                                </DButton>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        {profissinalProfileUnit &&
                          profissinalProfileUnit.unidades &&
                          profissinalProfileUnit.unidades.map((item, index) => {
                            return (
                              <>
                                <Grid item md={11} sm={6}>
                                  <Card
                                    className={classes.cardChip}
                                    style={{ paddingBottom: "0px" }}
                                  >
                                    <CardContent>
                                      <Typography
                                        className="text-mt text-regular mb-1"
                                        variant="body1"
                                      >
                                        <strong>{item.name}</strong>
                                      </Typography>
                                      <div className={classes.chips}>
                                        {item.profiles &&
                                        item.profiles.length > 0
                                          ? item.profiles.map((profile) => {
                                              return (
                                                <Chip label={profile.name} />
                                              );
                                            })
                                          : "Sem unidades registradas"}
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                <Grid item md={11} sm={6}>
                                  <br />
                                </Grid>
                              </>
                            );
                          })}

                        {/* {professionalUnits.length && professionalUnits.map((item, index) => {
                            return (
                              <>
                                <Grid item md={11} sm={6}>
                                  <Card className={classes.cardChip} style={{ paddingBottom: '0px' }}>
                                    <CardContent>
                                      <Typography
                                        className="text-mt text-regular mb-1"
                                        variant="body1"
                                      >
                                        <strong>
                                          {
                                            item.medical_unit_id
                                              ?
                                              units.find(res => item.medical_unit_id == res.id).company_name
                                              :
                                              ''
                                          }
                                        </strong>
                                      </Typography>
                                      <div className={classes.chips}>
                                        {
                                          item.profiles.length
                                            ?
                                            item.profiles.map(profile => {
                                              return profiles.find(res => profile == res.id) ? <Chip label={profiles.find(res => profile == res.id).description} /> : ''
                                            })
                                            :
                                            'Sem unidades registradas'
                                        }
                                      </div>
                                    </CardContent>
                                  </Card>
                                </Grid>
    
                                <Grid item md={11} sm={6}>
                                  <br />
                                </Grid>
                              </>
                            )
                          })} */}
                      </>
                    </Grid>
                  </>
                )}
                {(props.aceiteTermoProfissional == "yes" ||
                  props.statusProfissional == "Aguardando") &&
                props.statusProfissional != "Bloqueado" ? (
                  <>
                    <div className={classes.footermodal}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        className={classes.gridLinkWrap}
                      >
                        <Grid
                          item
                          md={
                            props.statusProfissional &&
                            props.statusProfissional == "Aguardando"
                              ? 5
                              : 4
                          }
                          className={classes.gridLink}
                        >
                          <Delete className={classes.icon} />
                          <Link
                            className={classes.linkDanger}
                            onClick={(e) => {
                              e.preventDefault();

                              if (!deleteLoading) {
                                excluirProfissional();
                              }
                            }}
                          >
                            {deleteLoading ? (
                              <CircularProgress size={25} color="inherit" />
                            ) : (
                              " Deletar usuário"
                            )}
                          </Link>
                        </Grid>
                        <Grid
                          item
                          md={
                            props.statusProfissional &&
                            props.statusProfissional == "Aguardando"
                              ? 5
                              : 4
                          }
                          className={classes.gridLink}
                        >
                          <Block className={classes.icon} />
                          <Link
                            className={classes.linkDanger}
                            onClick={(e) => {
                              e.preventDefault();

                              !blockLoading && bloquearProfissional();
                            }}
                          >
                            {blockLoading ? (
                              <CircularProgress size={25} color="inherit" />
                            ) : (
                              " Bloquear usuário"
                            )}
                          </Link>
                        </Grid>
                      </Grid>
                      {props.statusProfissional &&
                      props.statusProfissional == "Aguardando" ? (
                        <Grid container justifyContent="flex-end">
                          <Grid item className={classes.gridLink}>
                            <DButton
                              disabled={
                                !professionalInvite && professionalInvite != ""
                              }
                              variant="primary"
                              icon={<Send fontSize="small" />}
                              className={classes.textWidth}
                              size="sm"
                              onClick={(e) =>
                                !resendInviteLoading && reenviarConvite()
                              }
                            >
                              {resendInviteLoading ? (
                                <CircularProgress size={25} color="inherit" />
                              ) : (
                                "Reenviar convite"
                              )}
                            </DButton>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {props.statusProfissional == "Bloqueado" ? (
                      <div className={classes.footermodal}>
                        <Grid container justifyContent="flex-start">
                          <Grid item md={5} className={classes.gridLink}>
                            <VpnKeyIcon className={classes.iconSuccess} />{" "}
                            &nbsp;&nbsp;
                            <Link
                              className={classes.linkSuccess}
                              onClick={(e) => {
                                e.preventDefault();

                                if (!unblockLoading) {
                                  desbloquearProfissional();
                                }
                              }}
                            >
                              {unblockLoading ? (
                                <CircularProgress size={25} color="inherit" />
                              ) : (
                                " Desbloquear usuário"
                              )}
                            </Link>
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
      </Modal>
    </div>
  );
}
