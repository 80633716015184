import env from "react-dotenv";

function newInvite(token, dados) {

    return fetch(`${env.API_URL}/user/invite?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function testUploadInvite(token, file) {

    var body = new FormData();
    body.append('file', file);

    return fetch(`${env.API_URL}/user/test-upload-invite?token=${ token }`, {
        method: 'post',
        body
    }).then(res => res.json())
}

function uploadInvite(token, file) {

    var body = new FormData();
    body.append('file', file);

    return fetch(`${env.API_URL}/user/upload-invite?token=${ token }`, {
        method: 'post',
        body
    }).then(res => res.json());
}

export { newInvite, testUploadInvite, uploadInvite }