// Componente utilizado nas seguintes telas:
// - Tela de edição de paciente
// - Tela de atendimento

import React, { useEffect, useMemo, useState } from "react";

import {
  Grid,
  Button,
  Checkbox,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { getPatientDocuments } from "../../../../../../helpers/utils";

import pronomesCode from "../../../../../../assets/dados/pronomes.json";
import logradourosCode from "../../../../../../assets/dados/logradouro.json";
import tiposTelefoneCode from "../../../../../../assets/dados/tiposTelefone.json";
import DInput from "../../../../../shared/DInputWithPlaceholder";
import DDateInput from "../../../../../shared/DDateInput";
import DAutoComplete from "../../../../../shared/DAutoComplete";
import DMultAutoComplete from "../../../../../shared/DMultAutoComplete";
import DDropDownSimple from "../../../../../shared/DDropDownSimple";
import { ptBR } from "date-fns/locale";
import { toast } from "react-toastify";

import {
  mascaraCPF,
  mascaraCEP,
  mascaraPIS,
  mascaraNumero,
  mascaraRG,
  mascaraRNE,
  getOnlyNumbers,
} from "../../../../../../helpers/formatacao";
import {
  required,
  requiredTwoName,
  requiredOptionalTwoName,
  validaCPF,
  validaEmail,
  validaRG,
  validaLoteCNS,
  validaLoteCNSCustom,
  validaPIS,
  validaTel,
  validaCEP,
  parseNumber,
} from "../../../../../../helpers/validacoes";
import { getCep, getCep2 } from "../../../../../../helpers/apis";
import useError from "../../../../../../hooks/useError";
import racasCode from "./../../../../../../assets/dados/racas.json";
import orientacoesSexuaisCode from "./../../../../../../assets/dados/orientacoesSexuais.json";
import generoCode from "./../../../../../../assets/dados/genero.json";
import deficienciasCode from "./../../../../../../assets/dados/deficiencia.json";
import populacaoEspecCode from "./../../../../../../assets/dados/populacaoEspec.json";
import orgaoEmissorCode from "./../../../../../../assets/dados/orgaoEmissor.json";
import sexoCode from "./../../../../../../assets/dados/sexo.json";
import justificativaDadosCode from "./../../../../../../assets/dados/justificativasDadosIncompletos.json";

import {
  getPatientById,
  updatePatientFull,
} from "../../../../../../api/patient";

import "./styles.scss";
import PacienteGroup from "../../../PacienteGroup/PacienteGroup";
import PacienteLineCare from "../../../PacienteLineCare";
import Identidade from "./components/Identidade";
import CartaoNacionalSaude from "./components/CartaoNacionalSaude";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { usePatient } from "./../../../../../../context/PatientContext";
import { format } from "date-fns";
import styled from "styled-components";
import { InfoOutlined } from "@mui/icons-material";
import { DeleteForever } from "@material-ui/icons";
import { FormControlLabel } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dados_container: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingBottom: 24,
    marginBottom: 24,
    fontSize: 14,
  },
  dados_titulo: {
    fontStyle: "normal",
    fontWeight: "bold !important",
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.primary.light,
    margin: 0,
  },
  dados_editButton: {
    color: theme.palette.primary.light,
    textTransform: "none",
    fontWeight: 400,
  },
  dados_saveButton: {
    color: theme.palette.primary.light,
    textTransform: "none",
    fontWeight: 400,
  },
  dados_chip: {
    background: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    height: 26,
    marginBottom: 16,
    marginRight: 8,
  },
  dados_card: {
    background: theme.palette.background.snow,
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: 4,
    marginBottom: 16,
    padding: 20,
  },
  field_padding: {
    paddingTop: "10px!important",
    display: "flex",
  },
  alignItemsEnd: {
    alignItems: "end",
  },
}));

const nacionalidades = [
  { id: 1, value: "Brasileiro" },
  { id: 2, value: "Naturalizado" },
  { id: 3, value: "Estrangeiro" },
];

function DetailsMain(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { children, value, index, ...other } = props;

  const [paciente, setPaciente] = useState(null);

  const {
    patient,
    setPatient,
    setDadosPrincipais,
    dadosPrincipais,
    dadosIdentidadeGeral,
    setDadosIdentidadeGeral,
    dadosIdentidadeBrasileiro,
    setDadosIdentidadeBrasileiro,
    dadosIdentidadeNaturalizado,
    setDadosIdentidadeNaturalizado,
    dadosIdentidadeEstrangeiro,
    setDadosIdentidadeEstrangeiro,
    dadosDemograficos,
    setDadosDemograficos,
    dadosCns,
    dadosGravidez,
    setDadosGravidez,
    setDadosCns,
    dadosComplementares,
    setDadosComplementares,
    dadosTelefones,
    setDadosTelefones,
    dadosUnidades,
    setDadosUnidades,
    dadosEndereco,
    setDadosEndereco,
  } = usePatient();

  useEffect(() => {
    delete error.cns;
  }, [dadosCns])

  useEffect(() => {
    if (props.paciente) {
      // console.log("Paciente:", props.paciente);

      setPaciente(props.paciente.patient);
      // setPatient(props.paciente.patient);
    }
  }, [props.paciente]);

  const [loadingPatient, setLoadingPatient] = useState(true);
 
  const [editarGrupo, setEditarGrupo] = useState(false);
  const [editarLineCare, setEditarLineCare] = useState(false);

  // Grupo de paciente
  const [groups, setGroups] = useState(
    props.paciente.patient.patient_has_group
      ? props.paciente.patient.patient_has_group
          .filter((res) => res.group !== null)
          .map((item) => item.group.name)
      : []
  );

  // Linhas de cuidado
  const [lineCares, setLineCares] = useState(
    props.paciente.patient.patient_line_care
      ? props.paciente.patient.patient_line_care
          .filter((res) => res.line_care !== null)
          .map((item) => item.line_care.name)
      : []
  );

  // Telefones
  const [telefones, setTelefones] = useState(
    props.paciente.patient.patient_contact.length
      ? props.paciente.patient.patient_contact
      : []
  );
  const [qtdTelefones, setQtdTelefones] = useState([1]);

  // Unidades
  const [unidadeRef, setUnidadeRef] = useState(
    props.paciente.patient.medical_unit.length
      ? props.paciente.patient.medical_unit[0].id
      : ""
  );
  const [unidadeVinculada, setUnidadeVinculada] = useState(
    props.paciente.patient.patient_has_medical_unit.length > 0
      ? props.paciente.patient.patient_has_medical_unit.map((res) => {
          return {
            id: res.medical_unit_id,
            value: res.medical_unit.bussines_name
              ? res.medical_unit.bussines_name
              : res.medical_unit.company_name,
          };
        })
      : []
  );
  // Switches
  const [editarFull, setEditarFull] = useState(false);

  useEffect(() => {
    if (props.paciente && props.paciente.patient) {
      getPatientById(localStorage.getItem("token"), props.paciente.patient.id)
        .then((data) => {
          if (data.status) {
            setPatient(data.details.patient);

            var _nationalityParse;

            // get nationality;
            if (isNaN(data.details.patient.nationality)) {
              // Parse nationality "Brasileiro" || "1"
              if (
                nacionalidades.find(
                  (x) => x.value === data.details.patient.nationality
                )
              ) {
                _nationalityParse = nacionalidades.find(
                  (x) => x.value === data.details.patient.nationality
                ).id;
              }
            } else {
              _nationalityParse = data.details.patient.nationality;
            }

            // get cpf;
            var _cpfDocument = getPatientDocuments(
              data.details.patient.patient_document,
              {
                description: "CPF",
              }
            ).value;

            // get birth date;
            var _birthDate = data.details.patient.birth_date;

            var regDate = new RegExp(/(\d{4})-(\d{2})-(\d{2})/);

            if (_birthDate && typeof _birthDate === "string") {
              _birthDate = _birthDate.split(" ")[0]; // splitting yyyy-mm-dd 00:00:00;

              _birthDate = _birthDate.replace(regDate, "$3/$2/$1"); // replacing yyyy-mm-dd to dd/mm/yyyy;
            }

            // get biological_sex;
            var _biological_sex = data.details.patient.biological_sex;

            if (_biological_sex) {
              if (sexoMap.find((x) => x.value === _biological_sex)) {
                _biological_sex = sexoMap.find(
                  (x) => x.value === _biological_sex
                ).id;
              }
            }

            // get nr;
            var _NR;

            if (_cpfDocument) {
              _NR = _cpfDocument;
            } else if (
              data.details.patient.cns_principal ||
              (Array.isArray(data.details.patient.cns_secundario) &&
                data.details.patient.cns_secundario.length > 0)
            ) {
              _NR =
                data.details.patient.cns_principal ||
                data.details.patient.cns_secundario[0].value;
            }

            let _dadosPrincipais = {
              nacionalidade: _nationalityParse,
              cpf: _cpfDocument,
              dataNascimento: _birthDate,
              inputDataNascimento: _birthDate,
              nomeCompleto: data.details.patient.full_name,
              nomeMae: data.details.patient.mother_name,
              nomePai: data.details.patient.father_name,
              paiDesconhecido: data.details.patient.father_know,
              maeDesconhecida: data.details.patient.mother_know,
              sexo: _biological_sex,
              orientacaoSexual: data.details.patient.sexual_orientation_id,
              NR: _NR,
              genero: data.details.patient.genre_id,
              nomeSocial: data.details.patient.social_name,
              email: data.details.patient.email,
              seIdentificaSexo: data.details.patient.biological_sex_identifies,
              dadosIncompletos: !!data.details.patient.incomplete_data,
              pronomes: data.details.patient.pronoun_id,
              anoEstimadoDeNascimento: data.details.patient.birth_year,
              justificativaDadoIncompleto:
                data.details.patient.incomplete_data_reason,
              paisNascimento: data.details.patient.country_birth,
            };

            setDadosPrincipais(_dadosPrincipais);

            // ----------------------------------------------------------------

            let _dadosIdentidadeGeral = {
              nomeMae: data.details.patient.mother_name,
              maeDesconhecida:
                data.details.patient.mother_know == false ||
                data.details.patient.mother_know == null ||
                data.details.patient.mother_know == 1
                  ? false
                  : true,
              nomePai: data.details.patient.father_name,
              paiDesconhecido:
                data.details.patient.father_know == false ||
                data.details.patient.father_know == null ||
                data.details.patient.father_know == 1
                  ? false
                  : true,
            };

            setDadosIdentidadeGeral(_dadosIdentidadeGeral);

            // ----------------------------------------------------------------

            var _dadosIdentidadeBrasileiro = {};

            if (data.details.patient.rg) {
              let _rgValue = data.details.patient.rg.value;

              _dadosIdentidadeBrasileiro = {
                identidadeNumero: _rgValue.numero.value,
                identidadeDataEmissao: _rgValue.data_emissao.value.view,
                identidadeOrgaoEmissor: _rgValue.orgao_emissor.value,
                identidadeUF: _rgValue.uf.value.id,
              };
            }

            setDadosIdentidadeBrasileiro(_dadosIdentidadeBrasileiro);

            // ----------------------------------------------------------------

            var _dadosIdentidadeNaturalizado = {};

            if (data.details.patient.rne) {
              let _rneValue = data.details.patient.rne.value;

              _dadosIdentidadeNaturalizado = {
                identidadeNumero: _rneValue.numero.value,
                identidadeDataEmissao: _rneValue.data_emissao.value.view,
                identidadeOrgaoEmissor: _rneValue.orgao_emissor.value,
                identidadeUF: _rneValue.uf.value.id,
              };
            }

            if (_dadosIdentidadeNaturalizado) {
              setDadosIdentidadeNaturalizado(_dadosIdentidadeNaturalizado);
            }

            // ----------------------------------------------------------------

            var _dadosIdentidadeEstrangeiro = {};

            if (data.details.patient.passport) {
              let _passportValue = data.details.patient.passport.value;

              _dadosIdentidadeEstrangeiro = {
                numeroPassaporte: _passportValue.numero.value,
                dataValidade: _passportValue.data_validade.value.view,
                dataEmissaoPassaporte: _passportValue.data_emissao.value.view,
                paisPassaporte: _passportValue.pais_emissor.value.id,
              };
            }

            if (_dadosIdentidadeEstrangeiro) {
              setDadosIdentidadeEstrangeiro(_dadosIdentidadeEstrangeiro);
            }

            // ----------------------------------------------------------------

            var _cnsDocument = [];

            if (data.details.patient.cns_principal)
              _cnsDocument.push({
                value: data.details.patient.cns_principal,
                default: true,
              });

            if (
              data.details.patient.cns_secundario &&
              Array.isArray(data.details.patient.cns_secundario)
            ) {
              data.details.patient.cns_secundario.map((res) =>
                _cnsDocument.push({ value: res.value, default: false })
              );
            }

            setDadosCns(_cnsDocument);

            // ----------------------------------------------------------------

            var _dadosDemograficos = {
              raca: data.details.patient.breed_id,
              etnia: data.details.patient.etnia_id,
              municipioNascimento: data.details.patient.city_birth,
              estadoNascimento: data.details.patient.state_birth,
            };

            setDadosDemograficos(_dadosDemograficos);

            // ----------------------------------------------------------------

            if (Array.isArray(data.details.patient.patient_pregnancy) && data.details.patient.patient_pregnancy.length > 0) {
              
              let _pregnancy = data.details.patient.patient_pregnancy[0];

              try {
                _pregnancy.last_mestruation_date = _pregnancy.last_mestruation_date ? format(new Date(_pregnancy.last_mestruation_date + "T00:00:00"), "dd/MM/yyyy") : null;
              } catch(err) {
                console.log(err);
                _pregnancy.last_mestruation_date = null;
              }

              try {
                _pregnancy.last_abortion_date = _pregnancy.last_abortion_date ? format(new Date(_pregnancy.last_abortion_date + "T00:00:00"), "dd/MM/yyyy") : null;
              } catch (err) {
                console.log(err);
                _pregnancy.last_abortion_date = null;
              }

              setDadosGravidez({
                qtdVezesGravidez: _pregnancy.quantity,
                filhosVivos: _pregnancy.born_alive,
                dtUltimaMenstruacao: _pregnancy.last_mestruation_date,
                teveAborto: _pregnancy.abortion === 0 ? false : true,
                qtdVezesAborto: _pregnancy.quantity_abortion,
                dtUltimoAborto: _pregnancy.last_abortion_date,
                dtUltimaMenstruacaoValida: true,
              });
            }

            // ----------------------------------------------------------------

            var _pisDoc = getPatientDocuments(data.details.patient.documents, {
              description: "PIS",
            });

            var _isDeficient = false;

            if (
              Array.isArray(data.details.patient.patient_deficiencies) &&
              data.details.patient.patient_deficiencies.length > 0
            ) {
              _isDeficient = true;
            }

            setDadosComplementares({
              numeroPis: _pisDoc.value,
              seriePis: _pisDoc.serie,
              ufPis: _pisDoc.uf,
              temDeficiencia: _isDeficient,
              deficiencia: data.details.patient.patient_deficiencies,
              populacaoEspec: data.details.patient.specific_population_id,
              grauDeRelacionamento: data.details.patient.relationship_degree_id,
              responsavelLegalDocumento:
                data.details.patient.legal_responsible_document,
              responsavelLegalDocumentoEmissao:
                data.details.patient.legal_responsible_document_date,
              responsavelLegal: data.details.patient.legal_responsible,
            });

            // ----------------------------------------------------------------

            var _contacts = data.details.patient.patient_contact || [];

            setDadosTelefones(_contacts);

            // -------------------------------------------------

            var _address = data.details.patient.patient_address[0];

            var _endereco = {
              cep: _address.zip_code,
              endereco: _address.address_name,
              numero: _address.number,
              complemento: _address.complement,
              bairro: _address.district,
              logradouro: _address.address_type_id,
              estado: _address.city && _address.city[0] ? _address.city[0].state_id : null,
              municipio: _address.city_id,
              idMunicipio: _address.city_id,
            };

            console.log(_endereco)

            setDadosEndereco(_endereco);

            // -------------------------------------------------

            setDadosUnidades(data.details.patient.patient_has_medical_unit);

            if (props.paciente) {
              props.paciente.patient = data.details.patient;

              setLoadingPatient(false);
            }
          }

          setLoadingPatient(false);
        })
        .catch((err) => {
          console.log(err);

          setLoadingPatient(false);
        });
    }
  }, [, props]);

  const countrys = useMemo(
    () =>
      props.userDominios.country.map((item) => {
        return { id: item.id, value: item.description };
      }),
    []
  );

  const states = useMemo(
    () =>
      props.userDominios.state.map((item) => {
        return { id: item.id, value: item.code, description: item.description };
      }),
    []
  );

  const citys = useMemo(
    () =>
      props.userDominios.city.map((item) => {
        return {
          id: item.id,
          value: item.description,
          state_id: item.state_id,
        };
      }),
    []
  );

  const etnias = useMemo(
    () =>
      props.userDominios.etnia.map((item) => {
        return { id: item.id, value: item.description };
      }),
    []
  );

  const unidadeRefs = useMemo(
    () =>
      props.unidadesRef.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    []
  );

  const unidadesVinculadas = useMemo(
    () =>
      props.unidadesVinculadas.map(function (item) {
        return {
          id: item.id,
          value: item.business_name ? item.business_name : item.company_name,
        };
      }),
    []
  );

  const logradourosMap = useMemo(
    () =>
      logradourosCode.map(function (item) {
        return { id: item.id, value: item.value };
      }),
    []
  );

  const racasMap = useMemo(
    () =>
      racasCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const deficienciasMap = useMemo(
    () =>
      deficienciasCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const populacaoEspecMap = useMemo(
    () =>
      populacaoEspecCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const orientacoesSexuaisMap = useMemo(
    () =>
      orientacoesSexuaisCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const generosMap = useMemo(
    () =>
      generoCode.map(function (item) {
        return { id: item.id, value: item.name };
      }),
    []
  );

  const orgaoEmissorMap = useMemo(
    () =>
      orgaoEmissorCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const sexoMap = useMemo(
    () =>
      sexoCode.map(function (item) {
        return { id: item.name, value: item.name };
      }),
    []
  );

  const pronomeMap = useMemo(
    () =>
      pronomesCode.map((x) => {
        return { id: x.id, value: x.name };
      }),
    []
  );

  const tiposTelefoneMap = useMemo(
    () =>
      tiposTelefoneCode.map((item) => {
        return { id: item, value: item };
      }),
    []
  );

  const justificativaDadosMap = useMemo(
    () =>
      justificativaDadosCode.map((item) => {
        return { id: item.name, value: item.name };
      }),
    []
  );

  function formatDate(date) {
    if (date && date != "" && date != "null") {
      var day = date.substring(0, 2);
      var month = date.substring(3, 5);
      var year = date.substring(6);

      return [year, month, day].join("-");
    } else {
      return "";
    }
  }

  const validateDate = {
    dataEmissaoIdentidade: () => {
      if (dadosIdentidadeBrasileiro.identidadeDataEmissao) {
        var [day, month, year] =
          dadosIdentidadeBrasileiro.identidadeDataEmissao.split("/");

        var _identidadeEmissao = new Date(`${year}-${month}-${day}`);

        var [bDay, bMonth, bYear] = dadosPrincipais.dataNascimento.split("/");

        var _dataNascimento = new Date(`${bYear}-${bMonth}-${bDay}`);

        if (_identidadeEmissao < _dataNascimento) {
          return "Data não pode ser menor que a data de nascimento.";
        }

        if (_identidadeEmissao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          return undefined;
        } else {
          return "Data inválida.";
        }
      } else {
        return "Campo obrigatório.";
      }
    },
    dataUltimaMenstruacao: () => {
      if (dadosGravidez.dtUltimaMenstruacaoValida) {
        var [day, month, year] = dadosGravidez.dtUltimaMenstruacao.split("/");

        var _dtUltimaMenstruacao = new Date(`${year}-${month}-${day}`);

        if (_dtUltimaMenstruacao > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          return undefined;
        } else {
          return "Data inválida.";
        }
      } else {
        return "Data inválida.";
      }
    },
    dataUltimoAborto: () => {
      if (dadosGravidez.dtUltimoAborto || dadosGravidez.dtUltimoAborto != "") {
        var [day, month, year] = dadosGravidez.dtUltimoAborto.split("/");

        var _dtUltimoAborto = new Date(`${year}-${month}-${day}`);

        if (_dtUltimoAborto > new Date()) {
          return "Data não pode ser maior que a data atual.";
        }

        if (day && month && year) {
          return undefined;
        } else {
          return "Data inválida.";
        }
      } else {
        return undefined;
      }
    },
  };

  const [error, validarFormulario, setErrors] = useError(getValidators());

  function getValidators() {
    var validators = {};

    if (
      !dadosPrincipais ||
      !dadosIdentidadeGeral ||
      !dadosIdentidadeBrasileiro ||
      !dadosIdentidadeNaturalizado ||
      !dadosIdentidadeEstrangeiro ||
      !dadosEndereco ||
      !dadosComplementares
    )
      return;

    if (!dadosPrincipais.dadosIncompletos) {
      // Dados pessoais
      validators.email = validaEmail;
      validators.nome = requiredTwoName;
      validators.nacionalidade = required;
      validators.seIdentificaSexo = (value) =>
        value !== null ? undefined : "Campo obrigatório";

      if (dadosPrincipais.nacionalidade == 1) {
        validators.cpf = validaCPF;
      }

      if (dadosPrincipais.seIdentificaSexo === false) {
        validators.genero = required;
        validators.pronomes = required;
        validators.nomeSocial = requiredOptionalTwoName;
      }

      if (dadosPrincipais.nacionalidade == 1) {
        validators.identidadeNumero = validaRG;
        validators.identidadeEmissao = validateDate.dataEmissaoIdentidade;
        validators.identidadeOrgaoEmissor = required;
        validators.identidadeUF = required;
      }

      if (dadosPrincipais.nacionalidade == 2) {
        validators.identidadeNaturalNumero = required;
        validators.identidadeNaturalEmissao = required;
        validators.identidadeNaturalOrgaoEmissor = required;
        validators.identidadeNaturalUF = required;
      }

      if (dadosPrincipais.nacionalidade == 3) {
        validators.paisNascimento = required;
        validators.numeroPassaporte = required;
        validators.paisEmissorPassaporte = required;
        validators.dataEmissaoPassaporte = required;
        validators.dataValidadePassaporte = required;
      }

      if (dadosIdentidadeGeral.maeDesconhecida != true) {
        validators.nomeMae = requiredTwoName;
      }

      if (dadosIdentidadeGeral.paiDesconhecido != true) {
        validators.nomePai = requiredTwoName;
      }

      // Carteira Nacional de saúde (CNS)
      //validators.cns = validaLoteCNS; // condicionado a outro componente
      validators.cns = validaLoteCNSCustom;

      // Dados demográficos
      validators.raca = required;
      validators.sexo = required;
      validators.orientacaoSexual = required;

      if (dadosDemograficos.raca == 5) {
        validators.etnia = required;
      }

      if (dadosPrincipais.sexo == "Feminino" && dadosPrincipais.idade >= 15) {
        validators.qtdVezesGravidez = required;
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;
      }

      if (dadosGravidez.qtdVezesGravidez > 0)
        validators.dtUltimaMenstruacao = validateDate.dataUltimaMenstruacao;

      if (dadosGravidez.teveAborto) {
        validators.qtdVezesAborto = required;
        validators.dtUltimoAborto = validateDate.dataUltimoAborto;
      }

      if (dadosGravidez.filhosVivos || dadosGravidez.qtdVezesAborto) {
        var msgErro;

        if (dadosGravidez.filhosVivos > dadosGravidez.qtdVezesGravidez) {
          // msgErro = "Quantidade de filhos vivos não pode ser superior que a de gravidez";
          // mensagem de erro desativada temporariamente para atender questões de filhos gemeos
        } else if (
          dadosGravidez.qtdVezesAborto > dadosGravidez.qtdVezesGravidez
        ) {
          msgErro =
            "Quantidade de abortos não pode ser superior que a de gravidez";
        } else if (
          dadosGravidez.teveAborto &&
          parseNumber(dadosGravidez.filhosVivos) +
            parseNumber(dadosGravidez.qtdVezesAborto) >
            dadosGravidez.qtdVezesGravidez
        ) {
          msgErro =
            "Quantidade de filhos vivos e de abortos não condizem com a quantidade de gravidez";
        }

        function validaQtdFilhos() {
          return msgErro;
        }

        validators.qtdVezesGravidez = validaQtdFilhos;
      }

      validators.numeroPis = validaPIS;

      // Telefones
      validators.telefones = validaTel;

      if (dadosPrincipais.nacionalidade != 3) {
        // Endereço
        validators.cep = validaCEP;
        validators.numero = required;
      }
    } else {
      // Dados incompletos

      // Dados pessoais
      validators.sexo = required;
      validators.justificativaDadoIncompleto = required;
      validators.anoEstimadoDeNascimento = (valor) => {
        if (valor == "" || valor === undefined || !valor) {
          return "Campo obrigatório";
        }

        if (Number(valor) < 1900 || Number(valor) > new Date().getFullYear()) {
          return Number(valor) < 1900
            ? "Data não pode ser menor que 1900."
            : "Data não pode ser maior que a data atual.";
        }

        return valor.length < 1 ? "Campo obrigatório" : undefined;
      };

      // Endereço

      if (
        dadosPrincipais.nacionalidade == 2 ||
        dadosPrincipais.nacionalidade == 3
      ) {
        validators.paisNascimento = required;
        
        console.log("Pais Nascimento", dadosPrincipais);
      } else {
        validators.cep = validaCEP;
        validators.numero = required;
      }
    }

    return validators;
  }

  function getDados() {
    // Identidade - Estrangeiro

    if (loadingPatient) return;

    var dados = {};

    // Dados principais;
    dados.cpf = dadosPrincipais.cpf;
    dados.nome = dadosPrincipais.nomeCompleto;
    dados.nomeSocial = dadosPrincipais.nomeSocial;
    dados.email = dadosPrincipais.email;
    dados.dataNascimento = dadosPrincipais.dataNascimento;
    dados.nacionalidade = dadosPrincipais.nacionalidade;
    dados.sexo = dadosPrincipais.sexo;
    dados.orientacaoSexual = dadosPrincipais.orientacaoSexual;
    dados.seIdentificaSexo = dadosPrincipais.seIdentificaSexo;
    dados.genero = dadosPrincipais.genero;
    dados.pronomes = dadosPrincipais.pronomes;
    dados.justificativaDadoIncompleto =
      dadosPrincipais.justificativaDadoIncompleto;
    dados.anoEstimadoDeNascimento = dadosPrincipais.anoEstimadoDeNascimento;
    dados.dadosIncompletos = dadosPrincipais.dadosIncompletos;

    // Identidade Geral;
    dados.nomeMae = dadosIdentidadeGeral.nomeMae;
    dados.maeDesconhecida = dadosIdentidadeGeral.maeDesconhecida;
    dados.nomePai = dadosIdentidadeGeral.nomePai;
    dados.paiDesconhecido = dadosIdentidadeGeral.paiDesconhecido;
    dados.paisNascimento = dadosIdentidadeGeral.paisNascimento;

    // Identidade Brasileiro
    dados.identidadeNumero = dadosIdentidadeBrasileiro.identidadeNumero;
    dados.identidadeEmissao = dadosIdentidadeBrasileiro.identidadeDataEmissao;
    dados.identidadeOrgaoEmissor =
      dadosIdentidadeBrasileiro.identidadeOrgaoEmissor;
    dados.identidadeUF = dadosIdentidadeBrasileiro.identidadeUF;

    // Identidade Naturalizado;
    dados.identidadeNaturalNumero =
      dadosIdentidadeNaturalizado.identidadeNumero;
    dados.identidadeNaturalEmissao =
      dadosIdentidadeNaturalizado.identidadeDataEmissao;
    dados.identidadeNaturalOrgaoEmissor =
      dadosIdentidadeNaturalizado.identidadeOrgaoEmissor;
    dados.identidadeNaturalUF = dadosIdentidadeNaturalizado.identidadeUF;
    dados.naturalizadoPaisNascimento =
    dadosIdentidadeNaturalizado.paisNascimentoNaturalizado;
    dados.paisNascimento = dadosPrincipais.paisNascimento;

    // Identidade Estrangeiro;
    dados.numeroPassaporte = dadosIdentidadeEstrangeiro.numeroPassaporte;
    dados.dataEmissaoPassaporte =
      dadosIdentidadeEstrangeiro.dataEmissaoPassaporte;
    dados.paisEmissorPassaporte = dadosIdentidadeEstrangeiro.paisPassaporte;
    dados.passaporteDataValidade =
      dadosIdentidadeEstrangeiro.dataValidadePassaporte;
    dados.paisNascimento = dadosPrincipais.paisNascimento;
    dados.dataValidadePassaporte = dadosIdentidadeEstrangeiro.dataValidade;

    // Carteira Nacional de saúde (CNS)
    dados.cns = dadosCns;

    // Dados demográficos
    dados.estadoNascimento = dadosDemograficos.estadoNascimento;
    dados.municipioNascimento = dadosDemograficos.municipioNascimento;
    dados.raca = dadosDemograficos.raca;
    dados.etnia = dadosDemograficos.etnia;

    // Dados de gravidez
    dados.qtdVezesGravidez = dadosGravidez.qtdVezesGravidez;
    dados.filhosVivos = dadosGravidez.filhosVivos;
    dados.dtUltimaMenstruacao = dadosGravidez.dtUltimaMenstruacao;
    dados.teveAborto = dadosGravidez.teveAborto;
    dados.qtdVezesAborto = dadosGravidez.qtdVezesAborto;
    dados.dtUltimoAborto = dadosGravidez.dtUltimoAborto;

    // Dados complementares
    dados.numeroPis = dadosComplementares.numeroPis;
    dados.seriePis = dadosComplementares.seriePis;
    dados.ufPis = dadosComplementares.ufPis;
    dados.temDeficiencia = dadosComplementares.temDeficiencia;
    dados.deficiencia = dadosComplementares.deficiencia;
    dados.populacaoEspec = dadosComplementares.populacaoEspec;
    dados.grauDeRelacionamento = dadosComplementares.grauDeRelacionamento;
    dados.responsavelLegalDocumento =
      dadosComplementares.responsavelLegalDocumento;
    dados.responsavelLegalDocumentoEmissao =
      dadosComplementares.responsavelLegalDocumentoEmissao;
    dados.responsavelLegal = dadosComplementares.responsavelLegal;

    // Telefones
    dados.telefones = telefones;

    // Endereço
    dados.cep = dadosEndereco.cep;
    dados.endereco = dadosEndereco.endereco;
    dados.numero = dadosEndereco.numero;
    dados.complemento = dadosEndereco.complemento;
    dados.bairro = dadosEndereco.bairro;
    dados.logradouro = dadosEndereco.logradouro;
    dados.estado = dadosEndereco.estado;
    dados.municipio = dadosEndereco.municipio;
    dados.idMunicipio = dadosEndereco.idMunicipio;

    if (dadosPrincipais.nacionalidade == 1) {
      dados.passaporteNumero = "null";
      dados.passaportePaisEmissor = "null";
      dados.passaporteDataEmissao = "null";
      dados.passaporteDataValidade = "null";
      dados.passaportePaisNascimento = "null";
      dados.naturalizadoRne = "null";
      dados.naturalizadoDataEmissao = "null";
      dados.naturalizadoOrgaoEmissor = "null";
      dados.naturalizadoUF = "null";
      dados.naturalizadoPaisNascimento = "null";
    }

    if (dadosPrincipais.nacionalidade == 2) {
      dados.identidadeNumero = "null";
      dados.identidadeEmissao = "null";
      dados.identidadeOrgaoEmissor = "null";
      dados.identidadeUF = "null";
      dados.passaporteNumero = "null";
      dados.passaportePaisEmissor = "null";
      dados.passaporteDataEmissao = "null";
      dados.passaporteDataValidade = "null";
      dados.passaportePaisNascimento = "null";
    }

    if (dadosPrincipais.nacionalidade == 3) {
      dados.identidadeNumero = "null";
      dados.identidadeEmissao = "null";
      dados.identidadeOrgaoEmissor = "null";
      dados.identidadeUF = "null";
      dados.numeroPis = "null";
      dados.seriePis = "null";
      dados.ufPis = "null";
      dados.naturalizadoRne = "null";
      dados.naturalizadoDataEmissao = "null";
      dados.naturalizadoOrgaoEmissor = "null";
      dados.naturalizadoUF = "null";
      dados.naturalizadoPaisNascimento = "null";
    }

    if (dadosIdentidadeGeral.maeDesconhecida) {
      dados.nomeMae = "null";
    }

    if (dadosIdentidadeGeral.paiDesconhecido) {
      dados.nomePai = "null";
    }

    return dados;
  }

  function getDadosEnvio() {
    var patient = {};
    var patientAddress = {};
    var patientPregnancy = {};

    var patientDeficiencies = [];
    var patientContact = [];
    var patientDocument = [];
    var patientHasGroup = [];
    var patientHasMedicalUnit = [];
    var patientLineCare = [];

    // patient

    patient.email = dadosPrincipais.email;
    patient.full_name = dadosPrincipais.nomeCompleto;
    patient.nationality = dadosPrincipais.nacionalidade;
    patient.birth_date = formatDate(dadosPrincipais.dataNascimento);
    patient.birth_year = dadosPrincipais.anoEstimadoDeNascimento;
    patient.biological_sex = dadosPrincipais.sexo;
    patient.pronoun_id = dadosPrincipais.pronomes || null;
    patient.genre_id = dadosPrincipais.genero;
    patient.social_name = dadosPrincipais.nomeSocial;
    patient.biological_sex_identifies = dadosPrincipais.seIdentificaSexo;
    patient.etnia_id =
      dadosDemograficos.raca === 5 ? dadosDemograficos.etnia : null; // Raça -> 5 === Indígena;;
    patient.sexual_orientation_id = dadosPrincipais.orientacaoSexual;
    patient.breed_id = dadosDemograficos.raca;
    patient.specific_population_id = dadosComplementares.populacaoEspec
      ? dadosComplementares.populacaoEspec.id
      : null;
    patient.relationship_degree_id = dadosComplementares.grauDeRelacionamento;
    patient.legal_responsible = dadosComplementares.responsavelLegal;
    patient.legal_responsible_document =
      dadosComplementares.responsavelLegalDocumento;
    patient.legal_responsible_document_date =
      dadosComplementares.responsavelLegalDocumentoEmissao;
    patient.medical_unit_id = Array.isArray(dadosUnidades.unidadeRef) && dadosUnidades.unidadeRef.length ? dadosUnidades.unidadeRef : null;
    patient.profession = null;
    patient.marital_status = null;

    if (dadosPrincipais.nacionalidade == 1) {
      patient.country_birth = 45;
      patient.state_birth = dadosDemograficos.estadoNascimento;
      patient.city_birth = dadosDemograficos.municipioNascimento;
    }

    if (dadosPrincipais.nacionalidade == 2) {
      patient.country_birth =
        dadosIdentidadeNaturalizado.paisNascimentoNaturalizado;
      patient.state_birth = null;
      patient.city_birth = null;
    }

    if (dadosPrincipais.nacionalidade == 3) {
      patient.country_birth =
        dadosIdentidadeEstrangeiro.passaportePaisNascimento;
      patient.state_birth = null;
      patient.city_birth = null;
    }

    patient.father_name = dadosIdentidadeGeral.nomePai;
    patient.mother_name = dadosIdentidadeGeral.nomeMae;
    patient.mother_know = dadosIdentidadeGeral.maeDesconhecida;
    patient.father_know = dadosIdentidadeGeral.paiDesconhecido;

    patient.incomplete_data = dadosPrincipais.dadosIncompletos;
    patient.incomplete_data_reason =
      dadosPrincipais.justificativaDadoIncompleto;

    if (dadosPrincipais.sexo === "Feminino") {
      patientPregnancy.quantity = dadosGravidez.qtdVezesGravidez || 0;
      patientPregnancy.born_alive = dadosGravidez.filhosVivos || 0;
      patientPregnancy.last_mestruation_date =
        dadosGravidez.dtUltimaMenstruacao;
      patientPregnancy.abortion = dadosGravidez.teveAborto;
      patientPregnancy.quantity_abortion = dadosGravidez.qtdVezesAborto;
      patientPregnancy.last_abortion_date = dadosGravidez.dtUltimoAborto;
    } else {
      patientPregnancy = null;
    }

    // -- patient

    // patientAddress
    patientAddress.zip_code = getOnlyNumbers(dadosEndereco.cep);
    patientAddress.address_name = dadosEndereco.endereco;
    patientAddress.street = dadosEndereco.endereco;
    patientAddress.number = dadosEndereco.numero;
    patientAddress.complement = dadosEndereco.complemento;
    patientAddress.district = dadosEndereco.bairro;
    patientAddress.country_id = 45; // ID 45 === Brasil. // Se tem endereço é do brasil.
    patientAddress.address_type_id = dadosEndereco.logradouro;
    patientAddress.city_id = dadosEndereco.idMunicipio;

    // -- patientAddress

    // patientDeficiencies
    if (
      dadosComplementares.deficiencia &&
      Array.isArray(dadosComplementares.deficiencia)
    ) {
      dadosComplementares.deficiencia.forEach((def) => {
        patientDeficiencies.push({
          value: def.value,
          patient_deficiencies_type_id: 1,
        });
      });
    }

    // -- patientDeficiencies

    // patientContact

    if (telefones && Array.isArray(telefones)) {
      telefones.forEach((tel) => {
        patientContact.push({ ...tel });
      });
    }

    // -- patientContact

    // patientDocument

    if (dadosCns && Array.isArray(dadosCns)) {

      dadosCns.forEach((c) => {
        if (c.value != "") {
          patientDocument.push({
            type: "CNS",
            fields: [
              {
                // Valor do cns.
                id: 9,
                value: c.value,
              },
              {
                // Indica se o CNS é principal;
                id: 61,
                value: c.default,
              },
            ],
          });
        }
      });
    }

    if (dadosPrincipais.cpf) {
      var _cpfDocument = {
        type: "CPF",
        fields: [
          {
            id: 1,
            value: dadosPrincipais.cpf,
          },
        ],
      };

      patientDocument.push(_cpfDocument);
    }

    if (dadosComplementares.numeroPis) {
      var _pisDocument = {
        type: "PIS",
        fields: [
          {
            id: 57,
            value: dadosComplementares.numeroPis,
          },
          {
            id: 58,
            value: "",
          },
          {
            id: 59,
            value: "",
          },
        ],
      };

      patientDocument.push(_pisDocument);
    }

    if (dadosPrincipais.nacionalidade == 2) {
      var _rneDocument = {
        type: "RNE",
        fields: [
          {
            id: 62,
            value: dadosIdentidadeNaturalizado.identidadeNumero,
          },
          {
            id: 63,
            value: formatDate(
              dadosIdentidadeNaturalizado.identidadeDataEmissao
            ),
          },
          {
            id: 64,
            value: dadosIdentidadeNaturalizado.identidadeOrgaoEmissor,
          },
          {
            id: 65,
            value: dadosIdentidadeNaturalizado.identidadeUF,
          },
        ],
      };

      patientDocument.push(_rneDocument);
    }

    if (dadosPrincipais.nacionalidade == 1) {
      var _rgDocument = {
        type: "RG",
        fields: [
          {
            id: 2,
            value: dadosIdentidadeBrasileiro.identidadeNumero,
          },
          {
            id: 3,
            value: formatDate(dadosIdentidadeBrasileiro.identidadeDataEmissao),
          },
          {
            id: 4,
            value: dadosIdentidadeBrasileiro.identidadeOrgaoEmissor,
          },
          {
            id: 60,
            value: dadosIdentidadeBrasileiro.identidadeUF,
          },
        ],
      };

      patientDocument.push(_rgDocument);
    }

    if (dadosPrincipais.nacionalidade == 3) {
      // Paciente estrangeiro;
      patientDocument.push({
        type: "PASSPORT",
        fields: [
          {
            //Numero
            id: 5,
            value: dadosIdentidadeEstrangeiro.numeroPassaporte,
          },
          {
            //Data Emissao
            id: 6,
            value: formatDate(dadosIdentidadeEstrangeiro.dataEmissaoPassaporte),
          },
          {
            //Pais Emissor
            id: 7,
            value: dadosIdentidadeEstrangeiro.paisEmissorPassaporte,
          },
          {
            //Data Validade
            id: 8,
            value: formatDate(dadosIdentidadeEstrangeiro.dataValidade),
          },
        ],
      });
    }

    // -- patientDocument

    // PatientHasGroup

    // if (groups && Array.isArray(groups)) {
    //   groups.forEach((group) => {
    //     patientHasGroup.push({ group_id: group.id || null });
    //   });
    // }

    // -- PatientHasGroup

    // PatientHasMedicalUnit


    if (
      Array.isArray(dadosUnidades.unidadeVinculada) && dadosUnidades.unidadeVinculada.length > 0
    ) {
      dadosUnidades.unidadeVinculada.forEach((unidade) => {
        patientHasMedicalUnit.push({ medical_unit_id: unidade.id });
      });
    }

    // -- PatientHasMedicalUnit

    // PatientLineCare

    // patientLineCare = paciente.patient_line_care;

    // -- PatientLineCare

    return {
      patient,
      patientAddress,
      patientPregnancy,
      patientDeficiencies,
      patientContact,
      patientDocument,
      // patientHasGroup,
      patientHasMedicalUnit,
      // patientLineCare,
    };
  }

  async function updatePatientData() {
    var dados = getDados();


    try {
      if (validarFormulario({ ...dados })) {
        var payloadEnvio = getDadosEnvio();

        try {
          var data = await updatePatientFull(
            localStorage.getItem("token"),
            payloadEnvio,
            paciente.id
          );

          setEditarFull(false);

          if (data.status) {
            setEditarFull(false);
            toast.success("Paciente atualizado com sucesso.");
          } else {
            if (typeof data.details.patientDocument !== "undefined") {
              toast.error(
                "Erro ao atualizar paciente." + data.details.patientDocument[0]
              );
            } else {
              throw new Error(data);
            }

            setEditarFull(true);
          }
        } catch (err) {
          console.log("Erro ao atualizar paciente", err);
          toast.error("Erro ao atualizar paciente");
        }
      } else {
        toast.warn("Verifique os campos obrigatórios.");
        console.log("error");
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function novoTelefone() {
    delete error.telefones;

    var new_qtd_telefones = [...qtdTelefones, 1];

    setQtdTelefones(new_qtd_telefones);

    setTelefones((prevState) => {
      return [
        ...prevState,
        {
          id: new_qtd_telefones.length,
          phone: "",
          name: "",
          type: null,
          observation: null,
        },
      ];
    });
  }

  function removeFormat(value) {
    return value.replace(/[\s.-]*/gim, "");
  }

  function removeAccents(text) {
    const accentsMap = new Map([
      ["A", "Á|À|Ã|Â|Ä"],
      ["a", "á|à|ã|â|ä"],
      ["E", "É|È|Ê|Ë"],
      ["e", "é|è|ê|ë"],
      ["I", "Í|Ì|Î|Ï"],
      ["i", "í|ì|î|ï"],
      ["O", "Ó|Ò|Ô|Õ|Ö"],
      ["o", "ó|ò|ô|õ|ö"],
      ["U", "Ú|Ù|Û|Ü"],
      ["u", "ú|ù|û|ü"],
      ["C", "Ç"],
      ["c", "ç"],
      ["N", "Ñ"],
      ["n", "ñ"],
    ]);

    const reducer = (acc, [key]) =>
      acc.replace(new RegExp(accentsMap.get(key), "g"), key);

    return [...accentsMap].reduce(reducer, text);
  }

  function getAgeByDate(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      //
      age--;
    }
    return age;
  }

  function findState() {
    let stateSelecionado = states.find(
      (state) => state.id == dadosIdentidadeNaturalizado.identidadeUF
    )
      ? states.find(
          (state) => state.id == dadosIdentidadeNaturalizado.identidadeUF
        ).id
      : "Não encontrado";

    console.log("stateSelecionado", stateSelecionado);

    return stateSelecionado;
  }

  useEffect(() => {
    try {
      if (!dadosPrincipais.dataNascimento) return;

      var _dateValue;

      if (
        dadosPrincipais.dataNascimento &&
        typeof dadosPrincipais.dataNascimento === "string"
      ) {
        _dateValue = dadosPrincipais.dataNascimento
          .split("/")
          .reverse()
          .join("-");

        var _dateReg = new RegExp(/(\d{4})-(\d{2})-(\d{2})/g);

        if (!_dateReg.test(_dateValue)) {
          // não da match em xxxx-xx-xx
          return;
        }
      }

      setDadosPrincipais((prevState) => {
        return {
          ...prevState,
          idade: getAgeByDate(_dateValue),
        };
      });
    } catch (e) {
      console.log(e);
    }
  }, [dadosPrincipais.dataNascimento]);

  const DadosIncompletosButton = styled.button`
    background-color: ${(props) => props.bgColor};
    color: #fff;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    width: 139px;
    height: 31px;
    border: none;

    font-size: 10px;
    font-weight: 500;
    text-align: center;

    min-width: max-content;

    display: flex;
    align-items: center;
  `;

  const renderIdentidadeBrasileiro = () => {
    return (
      <>
        {!editarFull ? (
          // Visualização
          <Grid container>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Número de identidade:</strong>{" "}
              {dadosIdentidadeBrasileiro.identidadeNumero
                ? dadosIdentidadeBrasileiro.identidadeNumero
                : "Não informado"}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Data de emissão:</strong>{" "}
              {dadosIdentidadeBrasileiro.identidadeDataEmissao
                ? dadosIdentidadeBrasileiro.identidadeDataEmissao
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Órgão emissor:</strong>{" "}
              {orgaoEmissorMap.find(
                (oe) =>
                  oe.value == dadosIdentidadeBrasileiro.identidadeOrgaoEmissor
              )
                ? orgaoEmissorMap.find(
                    (oe) =>
                      oe.value ==
                      dadosIdentidadeBrasileiro.identidadeOrgaoEmissor
                  ).value
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>UF:</strong>{" "}
              {states.find(
                (state) => state.id == dadosIdentidadeBrasileiro.identidadeUF
              )
                ? states.find(
                    (state) =>
                      state.id == dadosIdentidadeBrasileiro.identidadeUF
                  ).description
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Nome da mãe:</strong>{" "}
              {dadosIdentidadeGeral.maeDesconhecida
                ? "Mãe desconhecida"
                : dadosIdentidadeGeral.nomeMae || "Não informado"}
            </Grid>

            <Grid item xs={4} className={classes.field_padding}>
              <strong>Nome do pai:</strong>{" "}
              {dadosIdentidadeGeral.paiDesconhecido
                ? "Pai desconhecido"
                : dadosIdentidadeGeral.nomePai || "Não informado"}
            </Grid>
          </Grid>
        ) : (
          // Edição
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="identidadeNumero"
                    // placeholder="Número de identidade"
                    label="Número de identidade"
                    style={{ marginTop: 0 }}
                    type="text"
                    error={error.identidadeNumero}
                    helperText={error.identidadeNumero}
                    fullWidth={true}
                    value={dadosIdentidadeBrasileiro.identidadeNumero}
                    onChange={(e) => {
                      delete error.identidadeNumero;
                      setDadosIdentidadeBrasileiro((prevState) => {
                        return {
                          ...prevState,
                          identidadeNumero: mascaraRG(e.target.value),
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDateInput
                    id="identidadeEmissao"
                    placeholder="Data de emissão"
                    error={error.identidadeEmissao}
                    helperText={error.identidadeEmissao}
                    fullWidth
                    className="datePicker"
                    label="Data de emissão"
                    maxDate={new Date()}
                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                    value={dadosIdentidadeBrasileiro.identidadeDataEmissao}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        identidadeEmissao: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.identidadeEmissao;

                      setDadosIdentidadeBrasileiro((prevState) => {
                        return {
                          ...prevState,
                          identidadeDataEmissao: arg1,
                        };
                      });

                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DAutoComplete
                    id="identidadeOrgaoEmissor"
                    placeholder="Órgão emissor"
                    label="Órgão emissor"
                    fullWidth
                    fixedLabel
                    error={error.identidadeOrgaoEmissor}
                    helperText={error.identidadeOrgaoEmissor}
                    defaultValue={orgaoEmissorMap.find(
                      (oe) =>
                        oe.value ==
                        dadosIdentidadeBrasileiro.identidadeOrgaoEmissor
                    )}
                    options={orgaoEmissorMap}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      value && option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      delete error.identidadeOrgaoEmissor;

                      setDadosIdentidadeBrasileiro((prevState) => {
                        return {
                          ...prevState,
                          identidadeOrgaoEmissor: newValue ? newValue.id : null,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DAutoComplete
                    id="identidadeUF"
                    placeholder="UF"
                    label="UF"
                    fullWidth
                    error={error.identidadeUF}
                    helperText={error.identidadeUF}
                    defaultValue={states.find(
                      (oe) => oe.id == dadosIdentidadeBrasileiro.identidadeUF
                    )}
                    options={states}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                      value && option.id === value.id
                    }
                    onChange={(event, newValue) => {
                      delete error.identidadeUF;

                      setDadosIdentidadeBrasileiro((prevState) => {
                        return {
                          ...prevState,
                          identidadeUF: newValue ? newValue.id : null,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="nomeMae"
                    label="Nome da mãe"
                    placeholder="Nome da mãe"
                    disabled={dadosIdentidadeGeral.maeDesconhecida}
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.nomeMae}
                    helperText={error.nomeMae}
                    fullWidth
                    onChange={(e) => {
                      delete error.nomeMae;
                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomeMae: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomeMae}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                        color="primary"
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}
                        onClick={(e) => {
                          delete error.nomeMae;

                          setDadosIdentidadeGeral((prevState) => {
                            return {
                              ...prevState,
                              nomeMae: "",
                              maeDesconhecida: e.target.checked,
                            };
                          });
                        }}
                      />
                    }
                    label="Mãe desconhecida"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container className={classes.alignItemsEnd}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="nomePai"
                    placeholder="Nome do pai"
                    label="Nome do pai"
                    disabled={dadosIdentidadeGeral.paiDesconhecido}
                    type="text"
                    error={error.nomePai}
                    helperText={error.nomePai}
                    fullWidth={true}
                    onChange={(e) => {
                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomePai: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomePai}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      onClick={(e) => {
                        setDadosIdentidadeGeral((prevState) => {
                          return {
                            ...prevState,
                            nomePai: "",
                            paiDesconhecido: e.target.checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Pai desconhecido"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderIdentidadeNaturalizado = () => {
    return (
      <>
        {!editarFull ? (
          // Visualização
          <Grid container>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Número do RNE:</strong>{" "}
              {dadosIdentidadeNaturalizado.identidadeNumero
                ? dadosIdentidadeNaturalizado.identidadeNumero
                : "Não informado"}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Data de emissão:</strong>{" "}
              {/* {identidadeEmissao
                ? format(new Date(identidadeEmissao), "dd/MM/yyyy")
                : "Não informado"} */}
              {dadosIdentidadeNaturalizado.identidadeDataEmissao ||
                "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Órgão emissor:</strong>{" "}
              {orgaoEmissorMap.find(
                (oe) =>
                  oe.value == dadosIdentidadeNaturalizado.identidadeOrgaoEmissor
              )
                ? orgaoEmissorMap.find(
                    (oe) =>
                      oe.value ==
                      dadosIdentidadeNaturalizado.identidadeOrgaoEmissor
                  ).value
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>UF:</strong>{" "}
              {states.find(
                (state) => state.id == dadosIdentidadeNaturalizado.identidadeUF
              )
                ? states.find(
                    (state) =>
                      state.id == dadosIdentidadeNaturalizado.identidadeUF
                  ).description
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Nome da mãe:</strong>{" "}
              {dadosIdentidadeGeral.maeDesconhecida
                ? "Mãe desconhecida"
                : dadosIdentidadeGeral.nomeMae || "Não informado"}
            </Grid>

            <Grid item xs={4} className={classes.field_padding}>
              <strong>Nome do pai:</strong>{" "}
              {dadosIdentidadeGeral.paiDesconhecido
                ? "Pai desconhecido"
                : dadosIdentidadeGeral.nomePai || "Não informado"}
            </Grid>
          </Grid>
        ) : (
          // Edição
          <Grid container spacing={3} className={classes.alignItemsEnd}>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="NumeroIdentidadeNatural"
                    placeholder="Número do RNE"
                    label="Número do RNE"
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.identidadeNaturalNumero}
                    helperText={error.identidadeNaturalNumero}
                    fullWidth={true}
                    value={dadosIdentidadeNaturalizado.identidadeNumero}
                    onChange={(e) => {
                      delete error.identidadeNaturalNumero;

                      setDadosIdentidadeNaturalizado((prevState) => {
                        return {
                          ...prevState,
                          identidadeNumero: mascaraRNE(e.target.value),
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDateInput
                    id="identidade-natural-emissao"
                    label="Data de emissão"
                    error={error.identidadeNaturalEmissao}
                    helperText={error.identidadeNaturalEmissao}
                    fullWidth
                    className="datePicker"
                    maxDate={new Date()}
                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                    value={dadosIdentidadeNaturalizado.identidadeDataEmissao}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        identidadeNaturalEmissao: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.identidadeNaturalEmissao;

                      setDadosIdentidadeNaturalizado((prevState) => {
                        return {
                          ...prevState,
                          identidadeDataEmissao: arg1,
                        };
                      });

                      // setIdentidadeNaturalEmissao(arg1);
                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <div className="input-info">
                    <DAutoComplete
                      id="orgao-emissor-natural"
                      placeholder="Órgão emissor"
                      label="Órgão emissor"
                      fullWidth
                      error={error.identidadeNaturalOrgaoEmissor}
                      helperText={error.identidadeNaturalOrgaoEmissor}
                      defaultValue={orgaoEmissorMap.find(
                        (oe) =>
                          oe.value ==
                          dadosIdentidadeNaturalizado.identidadeOrgaoEmissor
                      )}
                      options={orgaoEmissorMap}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        value && option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        delete error.identidadeNaturalOrgaoEmissor;

                        setDadosIdentidadeNaturalizado((prevState) => {
                          return {
                            ...prevState,
                            identidadeOrgaoEmissor: newValue
                              ? newValue.id
                              : null,
                          };
                        });
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDropDownSimple
                    id="identidade-natural-uf"
                    placeholder="UF"
                    label="UF"
                    fullWidth
                    error={error.identidadeNaturalUF}
                    helperText={error.identidadeNaturalUF}
                    value={dadosIdentidadeNaturalizado.identidadeUF}
                    options={states}
                    onChange={(newValue) => {
                      delete error.identidadeNaturalUF;
                      setDadosIdentidadeNaturalizado((prevState) => {
                        return {
                          ...prevState,
                          identidadeUF: newValue ? newValue : null,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="NomeMae"
                    placeholder="Nome da mãe"
                    label="Nome da mãe"
                    disabled={dadosIdentidadeGeral.maeDesconhecida}
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.nomeMae}
                    helperText={error.nomeMae}
                    fullWidth
                    onChange={(e) => {
                      delete error.nomeMae;

                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomeMae: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomeMae}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      onClick={(e) => {
                        setDadosIdentidadeGeral((prevState) => {
                          return {
                            ...prevState,
                            nomeMae: "",
                            maeDesconhecida: e.target.checked,
                          };
                        });

                        if (!dadosIdentidadeGeral.maeDesconhecida) {
                          setDadosIdentidadeGeral((prevState) => {
                            return {
                              ...prevState,
                              nomeMae: "",
                            };
                          });

                          delete error.nomeMae;
                        }
                      }}
                    />
                  }
                  label="Mãe desconhecida"
                />
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="nomePai"
                    placeholder="Nome do pai"
                    label="Nome do pai"
                    disabled={dadosIdentidadeGeral.paiDesconhecido}
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.nomePai}
                    helperText={error.nomePai}
                    fullWidth={true}
                    onChange={(e) => {
                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomePai: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomePai}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container spacing={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      onClick={(e) => {
                        if (!dadosIdentidadeGeral.paiDesconhecido) {
                          return setDadosIdentidadeGeral((prevState) => {
                            return {
                              ...prevState,
                              nomePai: "",
                              paiDesconhecido: e.target.checked,
                            };
                          });
                        }

                        setDadosIdentidadeGeral((prevState) => {
                          return {
                            ...prevState,
                            paiDesconhecido: e.target.checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Pai desconhecido"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const renderIdentidadeEstrangeiro = () => {
    return (
      <>
        {!editarFull ? (
          // Visualização
          <Grid container>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Número do passaporte:</strong>{" "}
              {dadosIdentidadeEstrangeiro.numeroPassaporte || "Não informado"}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Data de emissão do passaporte:</strong>{" "}
              {/* {identidadeEmissao
                ? format(new Date(identidadeEmissao), "dd/MM/yyyy")
                : "Não informado"} */}
              {dadosIdentidadeEstrangeiro.dataEmissaoPassaporte ||
                "Não informado"}
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <strong>Data de validade do passaporte:</strong>{" "}
              {dadosIdentidadeEstrangeiro.dataValidade || "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>País emissor do passaporte:</strong>{" "}
              {countrys.find(
                (x) => x.id == dadosIdentidadeEstrangeiro.paisPassaporte
              )
                ? countrys.find(
                    (x) => x.id == dadosIdentidadeEstrangeiro.paisPassaporte
                  ).value
                : "Não informado"}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>UF:</strong> {paciente.rg?.value.uf.value.code}
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Nome da mãe:</strong>{" "}
              {dadosIdentidadeGeral.maeDesconhecida
                ? "Mãe desconhecida"
                : dadosIdentidadeGeral.nomeMae || "Não informado"}
            </Grid>

            <Grid item xs={4} md={4} className={classes.field_padding}>
              <strong>Nome do pai:</strong>{" "}
              {dadosIdentidadeGeral.paiDesconhecido
                ? "Pai desconhecido"
                : dadosIdentidadeGeral.nomePai || "Não informado"}
            </Grid>
          </Grid>
        ) : (
          // Edição
          <Grid container spacing={3} className={classes.alignItemsEnd}>
            <Grid item md={12} xs={12}>
              <h5 className={classes.dados_titulo}>Identidade</h5>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="numeroPassaporte"
                    placeholder="Número do passaporte"
                    label="Número do passaporte"
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.numeroPassaporte}
                    helperText={error.numeroPassaporte}
                    fullWidth={true}
                    value={dadosIdentidadeEstrangeiro.numeroPassaporte}
                    onChange={(e) => {
                      delete error.numeroPassaporte;

                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          numeroPassaporte: e.target.value,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDateInput
                    id="data-emissao-passaporte"
                    error={error.dataEmissaoPassaporte}
                    helperText={error.dataEmissaoPassaporte}
                    fullWidth
                    className="datePicker"
                    label="Data de emissão do passaporte"
                    maxDate={new Date()}
                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                    value={dadosIdentidadeEstrangeiro.dataEmissaoPassaporte}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        dataEmissaoPassaporte: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.dataEmissaoPassaporte;

                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          dataEmissaoPassaporte: arg1,
                        };
                      });
                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDateInput
                    id="data-validade-passaporte"
                    error={error.dataValidadePassaporte}
                    helperText={error.dataValidadePassaporte}
                    fullWidth
                    className="datePicker"
                    label="Data de validade do passaporte"
                    minDate={new Date()}
                    minDateMessage="A data de validade não pode ser menor que a data atual"
                    value={dadosIdentidadeEstrangeiro.dataValidade}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        dataValidadePassaporte: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.dataValidadePassaporte;

                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          dataValidade: arg1,
                        };
                      });
                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DDropDownSimple
                    primaryColorSelectIcon
                    id="pais-emissor-passaporte"
                    label="País emissor do passaporte"
                    placeholder="País emissor do passaporte"
                    fullWidth
                    // required={!dadosPrincipais.dadosIncompletos}
                    error={error.paisEmissorPassaporte}
                    helperText={error.paisEmissorPassaporte}
                    value={dadosIdentidadeEstrangeiro.paisPassaporte}
                    options={countrys}
                    onChange={(event) => {
                      delete error.paisEmissorPassaporte;
                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          paisEmissorPassaporte: event,
                        };
                      });
                    }}
                  />
                  {/* <DDateInput
                    id="pais-emissor-passaporte"
                    error={error.paisEmissorPassaporte}
                    helperText={error.paisEmissorPassaporte}
                    fullWidth
                    className="datePicker"
                    // minDate={dadosPrincipais.dataNascimento}
                    label=""
                    value={dadosIdentidadeEstrangeiro.paisEmissorPassaporte}
                    onError={(errorMessage) => {
                      setErrors({
                        ...error,
                        paisEmissorPassaporte: errorMessage,
                      });
                    }}
                    onChange={(arg1, arg2, e) => {
                      delete error.paisEmissorPassaporte;

                      setDadosIdentidadeEstrangeiro((prevState) => {
                        return {
                          ...prevState,
                          paisEmissorPassaporte: arg1,
                        };
                      });
                      // setIdentidadeEmissaoValida(arg2.isValid);
                    }}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="NomeMae"
                    placeholder="Nome da mãe"
                    label="Nome da mãe"
                    disabled={dadosIdentidadeGeral.maeDesconhecida}
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.nomeMae}
                    helperText={error.nomeMae}
                    fullWidth
                    onChange={(e) => {
                      delete error.nomeMae;
                      // setNomeMae(e.target.value);

                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomeMae: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomeMae}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={dadosIdentidadeGeral.maeDesconhecida}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      onClick={(e) => {
                        setDadosIdentidadeGeral((prevState) => {
                          return {
                            ...prevState,
                            maeDesconhecida: e.target.checked,
                          };
                        });

                        if (!dadosIdentidadeGeral.maeDesconhecida) {
                          delete error.nomeMae;
                          setDadosIdentidadeGeral((prevState) => {
                            return {
                              ...prevState,
                              nomeMae: "",
                            };
                          });
                        }
                      }}
                    />
                  }
                  label="Mãe desconhecida"
                />
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container>
                <Grid item md={12} style={{ paddingTop: 0 }}>
                  <DInput
                    id="nomePai"
                    placeholder="Nome do pai"
                    label="Nome do pai"
                    disabled={dadosIdentidadeGeral.paiDesconhecido}
                    style={{ marginTop: 5 }}
                    type="text"
                    error={error.nomePai}
                    helperText={error.nomePai}
                    fullWidth={true}
                    onChange={(e) => {
                      setDadosIdentidadeGeral((prevState) => {
                        return {
                          ...prevState,
                          nomePai: e.target.value,
                        };
                      });
                    }}
                    value={dadosIdentidadeGeral.nomePai}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} className={classes.field_padding}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={dadosIdentidadeGeral.paiDesconhecido}
                      color="primary"
                      inputProps={{
                        "aria-label": "secondary checkbox",
                      }}
                      onClick={(e) => {
                        if (!dadosIdentidadeGeral.paiDesconhecido) {
                          return setDadosIdentidadeGeral((prevState) => {
                            return {
                              ...prevState,
                              nomePai: "",
                              paiDesconhecido: e.target.checked,
                            };
                          });
                        }

                        setDadosIdentidadeGeral((prevState) => {
                          return {
                            ...prevState,
                            paiDesconhecido: e.target.checked,
                          };
                        });
                      }}
                    />
                  }
                  label="Pai desconhecido"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  if (
    loadingPatient ||
    paciente === null ||
    !dadosPrincipais ||
    !dadosIdentidadeBrasileiro
  )
    return "Carregando paciente...";

  return (
    <div id="details-man">
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            {dadosPrincipais.dadosIncompletos && editarFull === false && (
              <DadosIncompletosButton bgColor={theme.palette.primary.light}>
                <InfoOutlined
                  style={{ width: 13, height: 13, marginRight: 10 }}
                />
                <span>Dados incompletos</span>
              </DadosIncompletosButton>
            )}

            {editarFull && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={dadosPrincipais.dadosIncompletos}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onClick={(e) => {
                      setDadosPrincipais((prevState) => {
                        return {
                          ...prevState,
                          dadosIncompletos: e.target.checked,
                        };
                      });
                    }}
                    icon={<CircleUnchecked />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                  />
                }
                label="Dados incompletos"
              />
            )}
          </Grid>
          <Grid
            item
            md={6}
            style={{ display: "flex" }}
            justifyContent="flex-end"
          >
            <Button
              variant="text"
              className={
                editarFull ? classes.dados_saveButton : classes.dados_editButton
              }
              onClick={(e) => {
                // Save
                if (editarFull) {
                  updatePatientData();
                } else {
                  setEditarFull(true);
                }
              }}
            >
              {editarFull ? (
                <>
                  <SaveIcon fontSize="small" />
                  <Box ml={1}>Salvar</Box>
                </>
              ) : (
                <>
                  <EditIcon fontSize="small" />
                  <Box ml={1}>Editar</Box>
                </>
              )}
            </Button>
          </Grid>
          <Grid item md={12} className={classes.field_padding}>
            <h5 className={classes.dados_titulo}>Dados Principais</h5>
          </Grid>

          {editarFull ? (
            <>
              <Grid item xs={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="nacionalidade"
                        label="Nacionalidade"
                        placeholder="Nacionalidade"
                        fullWidth
                        error={error.nacionalidade}
                        helperText={error.nacionalidade}
                        value={dadosPrincipais.nacionalidade}
                        options={nacionalidades}
                        onChange={(id) => {
                          delete error.nacionalidade;
                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              nacionalidade: id,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <DInput
                      id="cpf"
                      label="CPF"
                      placeholder="CPF"
                      style={{ marginTop: 5 }}
                      type="text"
                      error={error.cpf}
                      helperText={error.cpf}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.cpf;
                        setDadosPrincipais((prevState) => {
                          return {
                            ...prevState,
                            cpf: mascaraCPF(e.target.value),
                          };
                        });
                      }}
                      value={dadosPrincipais.cpf}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <DInput
                      id="email"
                      placeholder="E-mail"
                      label="E-mail"
                      style={{ marginTop: 5 }}
                      type="text"
                      error={error.email}
                      helperText={error.email}
                      fullWidth={true}
                      inputProps={{ maxLength: 254 }}
                      onChange={(e) => {
                        delete error.email;

                        setDadosPrincipais((prevState) => {
                          return {
                            ...prevState,
                            email: e.target.value,
                          };
                        });
                      }}
                      value={dadosPrincipais.email}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <DInput
                      id="nome"
                      label="Nome"
                      placeholder="Nome completo"
                      style={{ marginTop: 5, fontSize: "14px" }}
                      type="text"
                      error={error.nome}
                      helperText={error.nome}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.nome;

                        var _value = e.target.value.replace(
                          /[^a-zA-Zà-úÀ-Ú"' ]/g,
                          ""
                        );

                        setDadosPrincipais((prevState) => {
                          return {
                            ...prevState,
                            nomeCompleto: _value,
                          };
                        });
                      }}
                      value={dadosPrincipais.nomeCompleto}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                {dadosPrincipais.dadosIncompletos ? (
                  <Grid container spacing={3} className={classes.alignItemsEnd}>
                    <Grid item md={12}>
                      <DInput
                        id="Ano estimado de nascimento"
                        error={error.anoEstimadoDeNascimento}
                        helperText={error.anoEstimadoDeNascimento}
                        label="Ano estimado de nascimento"
                        fullWidth
                        value={dadosPrincipais.anoEstimadoDeNascimento}
                        onChange={(e) => {
                          delete error.anoEstimadoDeNascimento;

                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              anoEstimadoDeNascimento: e.target.value
                                .replace(/[^0-9]/g, "")
                                .slice(0, 4),
                            };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={3} className={classes.alignItemsEnd}>
                    <Grid item md={12} style={{ paddingTop: 0 }}>
                      <DDateInput
                        id="dataNascimento"
                        label="Data de Nascimento"
                        error={error.dataNascimento}
                        helperText={error.dataNascimento}
                        fullWidth
                        maxDate={new Date()}
                        maxDateMessage="A data de nascimento não pode ser maior que a data atual"
                        value={dadosPrincipais.inputDataNascimento}
                        onError={(errorMessage) => {
                          setErrors({ ...error, dataNascimento: errorMessage });
                        }}
                        onChange={(arg1, arg2, e) => {
                          delete error.dataNascimento;

                          if (
                            arg2.isValid &&
                            arg2.day &&
                            arg2.month &&
                            arg2.year &&
                            String(arg2.year).length === 4
                          ) {
                            var month = Number(arg2.month) - 1;

                            setDadosPrincipais((prevState) => {
                              return {
                                ...prevState,
                                dataNascimento: arg1,
                                inputDataNascimento: arg1,
                              };
                            });

                            return;
                          }

                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              inputDataNascimento: arg1,
                            };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="sexo"
                        placeholder="Sexo biológico"
                        label="Sexo biológico"
                        fullWidth
                        error={error.sexo}
                        helperText={error.sexo}
                        value={dadosPrincipais.sexo}
                        options={sexoMap}
                        onChange={(id) => {
                          delete error.sexo;

                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              sexo: id,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="seIdentificaSexo"
                        placeholder="Se identifica com sexo biológico"
                        label="Se identifica com sexo biológico"
                        fullWidth
                        error={error.seIdentificaSexo}
                        helperText={error.seIdentificaSexo}
                        value={dadosPrincipais.seIdentificaSexo}
                        options={[
                          {
                            id: 1,
                            value: "Sim",
                          },
                          {
                            id: 0,
                            value: "Não",
                          }
                        ]}
                        onChange={(value) => {
                          delete error.seIdentificaSexo;

                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              seIdentificaSexo: value ? 1 : 0,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {dadosPrincipais.dadosIncompletos && (
                <Grid item md={4} className={classes.field_padding}>
                  <Grid container spacing={3} className={classes.alignItemsEnd}>
                    <Grid item md={12} style={{ paddingTop: 0 }}>
                      <div className="input-info">
                        <DDropDownSimple
                          primaryColorSelectIcon
                          id="justificativaDadoIncompleto"
                          placeholder="Justificativa dados incompletos"
                          label="Justificativa dados incompletos"
                          fullWidth
                          error={error.justificativaDadoIncompleto}
                          helperText={error.justificativaDadoIncompleto}
                          value={dadosPrincipais.justificativaDadoIncompleto}
                          options={justificativaDadosMap}
                          onChange={(value) => {
                            delete error.justificativaDadoIncompleto;

                            setDadosPrincipais((prevState) => {
                              return {
                                ...prevState,
                                justificativaDadoIncompleto: value,
                              };
                            });
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item md={4} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        primaryColorSelectIcon
                        id="orientacaoSexual"
                        label="Orientação sexual"
                        placeholder="Orientação sexual"
                        fullWidth
                        required={!!dadosPrincipais.dadosIncompletos}
                        error={error.orientacaoSexual}
                        helperText={error.orientacaoSexual}
                        value={dadosPrincipais.orientacaoSexual}
                        options={orientacoesSexuaisMap}
                        onChange={(value) => {
                          delete error.orientacaoSexual;

                          setDadosPrincipais((prevState) => {
                            return {
                              ...prevState,
                              orientacaoSexual: value,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {dadosPrincipais.seIdentificaSexo === 0 && (
                <>
                  <Grid item md={4} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <div className="input-info">
                          <DDropDownSimple
                            id="genero"
                            primaryColorSelectIcon
                            placeholder="Gênero"
                            label="Gênero"
                            fullWidth
                            error={error.genero}
                            helperText={error.genero}
                            value={dadosPrincipais.genero}
                            options={generosMap}
                            onChange={(id) => {
                              delete error.genero;

                              setDadosPrincipais((prevState) => {
                                return {
                                  ...prevState,
                                  genero: id,
                                };
                              });
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <DInput
                          id="nomeSocial"
                          placeholder="Nome social"
                          label="Nome social"
                          style={{ marginTop: 5, fontSize: "14px" }}
                          inputProps={{ maxLength: 100 }}
                          type="text"
                          error={error.nomeSocial}
                          helperText={error.nomeSocial}
                          fullWidth={true}
                          onChange={(e) => {
                            var _value = e.target.value.replace(
                              /[^a-zA-Zà-úÀ-Ú"' ]/g,
                              ""
                            );

                            setDadosPrincipais((prevState) => {
                              return {
                                ...prevState,
                                nomeSocial: _value,
                              };
                            });
                          }}
                          value={dadosPrincipais.nomeSocial}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={4} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <div className="input-info">
                          <DDropDownSimple
                            id="pronomes"
                            primaryColorSelectIcon
                            placeholder="Pronomes"
                            label="Pronomes"
                            fullWidth
                            error={error.pronomes}
                            helperText={error.pronomes}
                            value={dadosPrincipais.pronomes}
                            options={pronomeMap}
                            onChange={(id) => {
                              delete error.pronomes;

                              setDadosPrincipais((prevState) => {
                                return {
                                  ...prevState,
                                  pronomes: id,
                                };
                              });
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              <Grid item md={4} className={classes.field_padding}>
                <span style={{ fontSize: "14px" }}>
                  <strong>Nacionalidade:</strong>{" "}
                  {dadosPrincipais.nacionalidade
                    ? nacionalidades.find(
                        (x) => x.id == dadosPrincipais.nacionalidade
                      ).value || null
                    : "Não informado"}
                </span>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <span style={{ fontSize: "14px" }}>
                  <strong>CPF:</strong> {dadosPrincipais.cpf || "Não informado"}
                </span>
              </Grid>
              <Grid item md={4} className={classes.field_padding}>
                <span style={{ fontSize: "14px" }}>
                  <strong>E-mail:</strong>{" "}
                  {dadosPrincipais.email || "Não informado"}
                </span>
              </Grid>
              <Grid item xs={4} className={classes.field_padding}>
                <span>
                  <strong>NR:</strong> {dadosPrincipais.NR || "Não informado"}
                </span>
              </Grid>
              {!!dadosPrincipais.dadosIncompletos ? (
                <Grid item xs={4} className={classes.field_padding}>
                  <span style={{ fontSize: "14px" }}>
                    <strong>Ano estimado de nascimento:</strong>{" "}
                    {dadosPrincipais.anoEstimadoDeNascimento || "Não informado"}
                  </span>
                </Grid>
              ) : (
                <Grid item xs={4} className={classes.field_padding}>
                  <span style={{ fontSize: "14px" }}>
                    <strong>Data de nascimento:</strong>{" "}
                    {dadosPrincipais.dataNascimento || "Não informado"}
                  </span>
                </Grid>
              )}

              <Grid item xs={4} className={classes.field_padding}>
                <span style={{ fontSize: "14px" }}>
                  <strong>Nome completo:</strong>{" "}
                  {dadosPrincipais.nomeCompleto || "Não informado"}
                </span>
              </Grid>

              <Grid item xs={4} className={classes.field_padding}>
                <strong>Sexo Biológico:</strong>{" "}
                {dadosPrincipais.sexo
                  ? sexoMap.filter((res) => res.id == dadosPrincipais.sexo)[0]
                    ? sexoMap.filter((res) => res.id == dadosPrincipais.sexo)[0]
                        .value
                    : "Não informado"
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} className={classes.field_padding}>
                <strong>Se identifica com sexo biológico?:</strong>{" "}                
                { dadosPrincipais.seIdentificaSexo === 1 ? "Sim" : (dadosPrincipais.seIdentificaSexo === 0 ? "Não" : "Não informado") }
              </Grid>

              <Grid item xs={4} className={classes.field_padding}>
                <strong>Orientação sexual:</strong>{" "}
                {dadosPrincipais.orientacaoSexual
                  ? orientacoesSexuaisMap.filter(
                      (res) => res.id == dadosPrincipais.orientacaoSexual
                    )[0].value
                  : "Não informado"}
              </Grid>

              <Grid item xs={4} className={classes.field_padding}>
                <strong>Gênero:</strong>{" "}
                {dadosPrincipais.genero
                  ? generosMap.filter(
                      (res) => res.id == dadosPrincipais.genero
                    )[0].value
                  : "Não informado"}
              </Grid>

              <Grid item xs={4} className={classes.field_padding}>
                <span style={{ fontSize: "14px" }}>
                  <strong>Nome social:</strong>{" "}
                  {dadosPrincipais.nomeSocial || "Não informado"}
                </span>
              </Grid>
            </>
          )}
        </Grid>
      </div>

      {/* <Identidade> */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            {dadosPrincipais.nacionalidade == 1 && renderIdentidadeBrasileiro()}

            {dadosPrincipais.nacionalidade == 2 &&
              renderIdentidadeNaturalizado()}

            {dadosPrincipais.nacionalidade == 3 &&
              renderIdentidadeEstrangeiro()}
          </Grid>
        </Grid>
      </div>
      {/* </Identidade> */}
      {/* <Cartão nacional de saúde> */}
      <div className={classes.dados_container}>
        <CartaoNacionalSaude error={ error } editar={editarFull} />
      </div>
      {/* </Cartão nacional de saúde> */}
      {/* <Dados demográficos> */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12}>
            <h5 className={classes.dados_titulo}>Dados demográficos</h5>
          </Grid>
          {editarFull ? (
            <>
              {dadosPrincipais.nacionalidade == 1 && (
                <>
                  <Grid item xs={6} md={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <DDropDownSimple
                          id="estadoNascimento"
                          placeholder="Estado de nascimento"
                          label="Estado de nascimento"
                          fullWidth
                          error={error.estadoNascimento}
                          helperText={error.estadoNascimento}
                          value={dadosDemograficos.estadoNascimento}
                          options={states}
                          onChange={(id) => {
                            delete error.estadoNascimento;
                            setDadosDemograficos((prevState) => {
                              return {
                                ...prevState,
                                estadoNascimento: id,
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <DDropDownSimple
                          id="municipioNascimento"
                          placeholder="Município de nascimento"
                          label="Município de nascimento"
                          fullWidth
                          error={error.municipioNascimento}
                          helperText={error.municipioNascimento}
                          value={dadosDemograficos.municipioNascimento}
                          options={citys.filter(
                            (x) =>
                              x.state_id == dadosDemograficos.estadoNascimento
                          )}
                          onChange={(id) => {
                            delete error.municipioNascimento;
                            setDadosDemograficos((prevState) => {
                              return {
                                ...prevState,
                                municipioNascimento: id,
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {dadosPrincipais.nacionalidade == 2 && (
                <>
                  <Grid item xs={6} md={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <DAutoComplete
                          id="paisNascimento"
                          label="País de nascimento"
                          placeholder="País de nascimento"
                          // required={true}
                          // required={!dadosIncompletos}
                          fullWidth
                          error={error.paisNascimento}
                          helperText={error.paisNascimento}
                          defaultValue={
                            countrys &&
                            countrys.length &&
                            countrys.filter(
                              (res) => res.id == dadosPrincipais.paisNascimento
                            )[0]
                          }
                          options={countrys}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.paisNascimento;

                            const pais = newValue ? newValue.id : null;

                            setDadosIdentidadeNaturalizado((prevState) => {
                              return {
                                ...prevState,
                                paisNascimentoNaturalizado: pais,
                              };
                            });

                            setDadosPrincipais((prevState) => {
                              return {
                                ...prevState,
                                paisNascimento: pais
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {dadosPrincipais.nacionalidade == 3 && (
                <>
                  <Grid item xs={6} md={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item md={12} style={{ paddingTop: 0 }}>
                        <DAutoComplete
                          id="paisNascimento"
                          label="País de nascimento"
                          placeholder="País de nascimento"
                          // required={true}
                          // required={!dadosIncompletos}
                          fullWidth
                          error={error.paisNascimento}
                          helperText={error.paisNascimento}
                          defaultValue={
                            countrys &&
                            countrys.length &&
                            countrys.filter(
                              (res) => res.id == dadosPrincipais.paisNascimento
                            )[0]
                          }
                          options={countrys}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) =>
                            value && option.id === value.id
                          }
                          onChange={(event, newValue) => {
                            delete error.paisNascimento;

                            const pais = newValue ? newValue.id : null;

                            setDadosIdentidadeEstrangeiro((prevState) => {
                              return {
                                ...prevState,
                                passaportePaisNascimento: pais
                              };
                            });

                            console.log("Set pais", pais, newValue)
                            
                            setDadosPrincipais((prevState) => {
                              return {
                                ...prevState,
                                paisNascimento: pais
                              };
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item md={6} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        id="raca"
                        placeholder="Raça"
                        label="Raça"
                        primaryColorSelectIcon
                        fullWidth
                        error={error.raca}
                        helperText={error.raca}
                        value={dadosDemograficos.raca}
                        options={racasMap}
                        onChange={(id) => {
                          delete error.raca;
                          delete error.etnia;

                          setDadosDemograficos((prevState) => {
                            return {
                              ...prevState,
                              raca: id,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} className={classes.field_padding}>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <div className="input-info">
                      <DDropDownSimple
                        id="etnia"
                        placeholder="Etnia"
                        primaryColorSelectIcon
                        label="Etnia"
                        fullWidth
                        error={error.etnia}
                        disabled={
                          dadosDemograficos.raca && dadosDemograficos.raca != 5
                        }
                        helperText={error.etnia}
                        value={dadosDemograficos.etnia}
                        options={etnias}
                        onChange={(id) => {
                          delete error.etnia;
                          // setEtnia(id);

                          setDadosDemograficos((prevState) => {
                            return {
                              ...prevState,
                              etnia: id,
                            };
                          });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {dadosPrincipais.nacionalidade == 1 && (
                <>
                  <Grid item md={4} xs={4} className={classes.field_padding}>
                    <strong>Estado de nascimento:</strong>{" "}
                    {states.find(
                      (x) => x.id == dadosPrincipais.estadoNascimento
                    )
                      ? states.find(
                          (x) => x.id == dadosPrincipais.estadoNascimento
                        ).value
                      : "Não informado"}
                  </Grid>
                  <Grid item md={4} xs={4} className={classes.field_padding}>
                    <strong>Município de nascimento:</strong>{" "}
                    {citys.find(
                      (res) => res.id == dadosDemograficos.municipioNascimento
                    )
                      ? citys.find(
                          (res) =>
                            res.id == dadosDemograficos.municipioNascimento
                        ).value
                      : "Não informado"}
                  </Grid>
                </>
              )}
              <Grid item md={4} xs={4} className={classes.field_padding}>
                <strong>Raça:</strong>{" "}
                {racasMap.find((res) => res.id == dadosDemograficos.raca)
                  ? racasMap.find((res) => res.id == dadosDemograficos.raca)
                      .value
                  : "Não informado"}
              </Grid>
              <Grid item md={4} xs={4} className={classes.field_padding}>
                <strong>Etnia:</strong>{" "}
                {etnias.find((res) => res.id == dadosDemograficos.etnia)
                  ? etnias.find((res) => res.id == dadosDemograficos.etnia)
                      .value
                  : dadosDemograficos.raca == 5
                  ? "Não informado"
                  : null}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <>
        {sexoMap.find((x) => x.id == dadosPrincipais.sexo) &&
        sexoMap.find((x) => x.id == dadosPrincipais.sexo).value ==
          "Feminino" ? (
          <div className={classes.dados_container}>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <h5 className={classes.dados_titulo}>Gravidez</h5>
              </Grid>

              {editarFull ? (
                <>
                  <Grid item md={6} xs={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item>
                        <strong>Quantas vezes já engravidou:</strong>
                      </Grid>
                      <Grid item md={2} style={{ paddingTop: 0 }}>
                        <DInput
                          id="qtdVezesGravidez"
                          placeholder="Quantas vezes já engravidou"
                          style={{ marginTop: 5 }}
                          type="text"
                          error={error.qtdVezesGravidez}
                          helperText={error.qtdVezesGravidez}
                          fullWidth={true}
                          onChange={(e) => {
                            delete error.qtdVezesGravidez;

                            setDadosGravidez((prevState) => {
                              return {
                                ...prevState,
                                qtdVezesGravidez: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              };
                            });
                            // }
                          }}
                          value={dadosGravidez.qtdVezesGravidez}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item>
                        <strong>Data da última menstruação:</strong>
                      </Grid>
                      <Grid item md={4} style={{ paddingTop: 0 }}>
                        <DDateInput
                          id="dtUltimaMenstruacao"
                          error={error.dtUltimaMenstruacao}
                          helperText={error.dtUltimaMenstruacao}
                          fullWidth
                          label=""
                          className="datePicker"
                          style={{ paddingBottom: 0 }}
                          maxDate={new Date()}
                          maxDateMessage="A data da ultima menstruação não pode ser maior que a data atual"
                          value={dadosGravidez.dtUltimaMenstruacao}
                          onError={(errorMessage) => {
                            setErrors({
                              ...error,
                              dtUltimaMenstruacao: errorMessage,
                            });
                          }}
                          onChange={(arg1, arg2, e) => {
                            delete error.dtUltimaMenstruacao;

                            setDadosGravidez((prevState) => {
                              return {
                                ...prevState,
                                dtUltimaMenstruacao: arg1,
                                dtUltimaMenstruacaoValida: arg2.isValid,
                              };
                            });

                            // setDtUltimaMenstruacao(arg1);
                            // setDtUltimaMenstruacaoValida(arg2.isValid);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item>
                        <strong>Filhos nascidos vivos:</strong>
                      </Grid>
                      <Grid item md={2} style={{ paddingTop: 0 }}>
                        <DInput
                          id="filhosVivos"
                          placeholder="Filhos nascidos vivos"
                          style={{ marginTop: 5 }}
                          type="text"
                          error={error.filhosVivos}
                          helperText={error.filhosVivos}
                          fullWidth={true}
                          onChange={(e) => {
                            delete error.filhosVivos;

                            setDadosGravidez((prevState) => {
                              return {
                                ...prevState,
                                filhosVivos: e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ),
                              };
                            });
                          }}
                          value={dadosGravidez.filhosVivos}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6} className={classes.field_padding}>
                    <Grid
                      container
                      spacing={3}
                      className={classes.alignItemsEnd}
                    >
                      <Grid item>
                        <strong>Já teve algum aborto:</strong>
                      </Grid>
                      <Grid item md={2} style={{ paddingTop: 0 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={dadosGravidez.teveAborto}
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              onClick={(e) => {
                                if (dadosGravidez.teveAborto) {
                                  setDadosGravidez((prevState) => {
                                    return {
                                      ...prevState,
                                      qtdVezesAborto: 0,
                                    };
                                  });
                                }
                                setDadosGravidez((prevState) => {
                                  return {
                                    ...prevState,
                                    teveAborto: !dadosGravidez.teveAborto,
                                  };
                                });
                              }}
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <>
                    {dadosGravidez.teveAborto ? (
                      <>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          className={classes.field_padding}
                        >
                          <Grid
                            container
                            spacing={3}
                            className={classes.alignItemsEnd}
                          >
                            <Grid item>
                              <strong>Quantas vezes já abortou:</strong>
                            </Grid>
                            <Grid item md={2} style={{ paddingTop: 0 }}>
                              <DInput
                                id="qtdVezesAborto"
                                placeholder="Quantas vezes já abortou"
                                style={{ marginTop: 5 }}
                                type="text"
                                error={error.qtdVezesAborto}
                                helperText={error.qtdVezesAborto}
                                fullWidth={true}
                                onChange={(e) => {
                                  delete error.qtdVezesAborto;

                                  if (e.target.value.length <= 2) {
                                    setDadosGravidez((prevState) => {
                                      return {
                                        ...prevState,
                                        qtdVezesAborto: e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ),
                                      };
                                    });
                                  }
                                }}
                                value={dadosGravidez.qtdVezesAborto}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          className={classes.field_padding}
                        >
                          <Grid
                            container
                            spacing={3}
                            className={classes.alignItemsEnd}
                          >
                            <Grid item>
                              <strong>Data do último aborto:</strong>
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                              <DDateInput
                                id="dtUltimoAborto"
                                error={error.dtUltimoAborto}
                                helperText={error.dtUltimoAborto}
                                fullWidth
                                label=""
                                className="datePicker"
                                style={{ paddingBottom: 0 }}
                                maxDate={new Date()}
                                maxDateMessage="A data do ultimo abordo não pode ser maior que a data atual"
                                value={dadosGravidez.dtUltimoAborto}
                                onError={(errorMessage) => {
                                  setErrors({
                                    ...error,
                                    dtUltimoAborto: errorMessage,
                                  });
                                }}
                                onChange={(arg1, arg2, e) => {
                                  delete error.dtUltimoAborto;

                                  setDadosGravidez((prevState) => {
                                    return {
                                      ...prevState,
                                      dtUltimoAborto: arg1,
                                    };
                                  });

                                  // setDtUltimoAborto(arg1);
                                  // setDtUltimoAbortoValida(arg2.isValid);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                </>
              ) : (
                <>
                  <Grid item md={4} xs={4} className={classes.field_padding}>
                    <strong>Quantas vezes já engravidou:</strong>{" "}
                    {dadosGravidez.qtdVezesGravidez
                      ? dadosGravidez.qtdVezesGravidez
                      : "Não informado"}
                  </Grid>
                  <Grid item md={4} xs={4} className={classes.field_padding}>
                    <strong>Filhos nascidos vivos:</strong>{" "}
                    {dadosGravidez.filhosVivos
                      ? dadosGravidez.filhosVivos
                      : "Não informado"}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.field_padding}>
                    <strong>Data da última menstruação:</strong> &nbsp;
                    {dadosGravidez.dtUltimaMenstruacao
                      ? dadosGravidez.dtUltimaMenstruacao
                      : "Não informado"}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.field_padding}>
                    <strong>Já teve algum aborto:</strong>{" "}
                    {dadosGravidez.teveAborto
                      ? "Sim"
                      : dadosGravidez.teveAborto == null
                      ? "Não informado"
                      : "Não"}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.field_padding}>
                    <strong>Quantas vezes já abortou:</strong>{" "}
                    {dadosGravidez.qtdVezesAborto && dadosGravidez.teveAborto
                      ? dadosGravidez.qtdVezesAborto
                      : "Não informado"}
                  </Grid>
                  <Grid item xs={4} md={4} className={classes.field_padding}>
                    <strong>Data do último aborto:</strong> &nbsp;
                    {dadosGravidez.dtUltimoAborto || "Não informado"}
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        ) : (
          ""
        )}
      </>
      {/* </Dados demográficos> */}
      {/* <Telefones> */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <h5 className={classes.dados_titulo}>Telefones</h5>
          </Grid>
          <Grid item md={12}>
            {editarFull ? (
              <>
                {telefones.length ? (
                  <>
                    {telefones.map(function (item, i) {
                      return (
                        <div key={i} className={classes.dados_card}>
                          <Grid container spacing={1}>
                            <Grid item md={3}>
                              Telefone
                              <br />
                              <DInput
                                id={`telefone-${i}`}
                                placeholder="Telefone"
                                style={{ marginTop: 5 }}
                                type="text"
                                error={
                                  error.telefones &&
                                  error.telefones.find((res) => res.index == i)
                                    ? error.telefones.find(
                                        (res) => res.index == i
                                      ).phone
                                    : undefined
                                }
                                helperText={
                                  error.telefones &&
                                  error.telefones.find((res) => res.index == i)
                                    ? error.telefones.find(
                                        (res) => res.index == i
                                      ).phone
                                    : undefined
                                }
                                fullWidth={true}
                                onChange={(e) => {
                                  delete error.telefones;

                                  var new_telefone = [...telefones];
                                  console.log(new_telefone);
                                  new_telefone[i].phone = mascaraNumero(
                                    e.target.value
                                  );
                                  setTelefones(new_telefone);
                                  console.log(new_telefone);
                                }}
                                value={telefones[i].phone}
                              />
                            </Grid>
                            <Grid item md={3}>
                              Tipo de telefone
                              <br />
                              <DDropDownSimple
                                primaryColorSelectIcon
                                removeDropDownMargin
                                id={`tipo-telefone-${i}`}
                                fullWidth
                                labelColor="black"
                                // required={!dadosIncompletos}
                                error={
                                  error.telefones &&
                                  error.telefones.find((res) => res.index == i)
                                    ? error.telefones.find(
                                        (res) => res.index == i
                                      ).type
                                    : undefined
                                }
                                helperText={
                                  error.telefones &&
                                  error.telefones.find((res) => res.index == i)
                                    ? error.telefones.find(
                                        (res) => res.index == i
                                      ).type
                                    : undefined
                                }
                                labelClass={classes.labelBlack}
                                value={telefones[i] && telefones[i].type}
                                options={tiposTelefoneMap}
                                onChange={(event) => {
                                  delete error.telefones;
                                  var new_telefone = [...telefones];
                                  new_telefone[i].type = event;
                                  setTelefones(new_telefone);
                                }}
                              />
                            </Grid>
                            <Grid item md={4}>
                              Apelido
                              <br />
                              <Grid item md={12}>
                                <DInput
                                  id={`apelido-telefone-${i}`}
                                  placeholder="Apelido"
                                  style={{ marginTop: 5 }}
                                  inputProps={{ maxLength: 10 }}
                                  type="text"
                                  error={
                                    error.telefones &&
                                    error.telefones.find(
                                      (res) => res.index == i
                                    )
                                      ? error.telefones.find(
                                          (res) => res.index == i
                                        ).name
                                      : undefined
                                  }
                                  helperText={
                                    error.telefones &&
                                    error.telefones.find(
                                      (res) => res.index == i
                                    )
                                      ? error.telefones.find(
                                          (res) => res.index == i
                                        ).name
                                      : undefined
                                  }
                                  fullWidth={true}
                                  onChange={(e) => {
                                    delete error.telefones;
                                    var new_telefone = [...telefones];
                                    new_telefone[i].name = e.target.value;
                                    setTelefones(new_telefone);
                                  }}
                                  value={telefones[i].name}
                                />
                              </Grid>
                            </Grid>
                            <Grid item md={2}>
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  delete error.telefones;
                                  var new_telefone = [...telefones];
                                  new_telefone.splice(i, 1);
                                  setTelefones(new_telefone);
                                }}
                              >
                                <DeleteForever style={{ color: "red" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Nenhum telefone adicionado
                  </Typography>
                )}
              </>
            ) : (
              <>
                {telefones.length ? (
                  <>
                    {telefones.map(function (item, i) {
                      return (
                        <div className={classes.dados_card}>
                          <Grid container spacing={1}>
                            <Grid item md={4}>
                              Telefone
                              <br />
                              {telefones[i].phone}
                            </Grid>
                            <Grid item md={4}>
                              Tipo do telefone
                              <br />
                              {telefones[i].type}
                            </Grid>
                            <Grid item md={4}>
                              Apelido do telefone
                              <br />
                              {telefones[i].name}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Nenhum telefone adicionado
                  </Typography>
                )}
              </>
            )}
          </Grid>
          {editarFull ? (
            <>
              <Grid item md={12}>
                <Button
                  variant="text"
                  className={classes.dados_editButton}
                  onClick={novoTelefone}
                >
                  <AddCircleIcon fontSize="small" />
                  <Box ml={1}>Adicionar telefone</Box>
                </Button>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </div>
      {/* </Telefones> */}
      {/* <DadosComplementares */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <h5 className={classes.dados_titulo}>Dados complementares</h5>
          </Grid>

          {editarFull ? (
            <>
              <Grid container spacing={3} className={classes.alignItemsEnd}>
                <Grid item md={6}>
                  <DInput
                    id="numeroPis"
                    placeholder="Número do NIT-PIS-PASEP"
                    label="Número do NIT-PIS-PASEP"
                    type="text"
                    error={error.numeroPis}
                    helperText={error.numeroPis}
                    fullWidth={true}
                    onChange={(e) => {
                      delete error.numeroPis;
                      // setNumeroPis(mascaraPIS(e.target.value));

                      setDadosComplementares((prevState) => {
                        return {
                          ...prevState,
                          numeroPis: mascaraPIS(e.target.value),
                        };
                      });
                    }}
                    value={dadosComplementares.numeroPis}
                  />
                </Grid>

                <Grid
                  item
                  md={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <strong style={{ alignSelf: "center" }}>
                    Possui Deficiência:
                  </strong>
                </Grid>

                <Grid item md={3} style={{ alignSelf: "center" }}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={dadosComplementares.temDeficiencia}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={(e) => {
                            setDadosComplementares((prevState) => {
                              return {
                                ...prevState,
                                temDeficiencia: true,
                              };
                            });
                          }}
                          icon={<CircleUnchecked />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                        />
                      }
                      label="Sim"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={!dadosComplementares.temDeficiencia}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          onClick={(e) => {
                            setDadosComplementares((prevState) => {
                              return {
                                ...prevState,
                                temDeficiencia: false,
                                deficiencia: [],
                              };
                            });
                          }}
                          icon={<CircleUnchecked />}
                          checkedIcon={<RadioButtonCheckedIcon />}
                        />
                      }
                      label="Não"
                    />
                  </div>
                </Grid>

                  {dadosComplementares.temDeficiencia && (
                <Grid item md={6} style={{ alignSelf: "center" }}>
                    <DMultAutoComplete
                      id="deficiencias"
                      placeholder="Deficiências"
                      label="Deficiências"
                      error={error.deficiencia}
                      inputValue=""
                      helperText={error.deficiencia}
                      defaultValue={
                        dadosComplementares.deficiencia.length
                          ? dadosComplementares.deficiencia.find((res) =>
                              deficienciasMap.find(
                                (item) => res.value == item.value
                              )
                            )
                            ? dadosComplementares.deficiencia.filter((res) =>
                                deficienciasMap.find(
                                  (item) => res.value == item.value
                                )
                              )
                            : dadosComplementares.deficiencia
                          : dadosComplementares.deficiencia
                      }
                      options={deficienciasMap}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        value && option.id === value.id
                      }
                      onChange={(event, newValue) => {
                        delete error.deficiencia;
                        // setDeficiencia(newValue);

                        setDadosComplementares((prevState) => {
                          return {
                            ...prevState,
                            deficiencia: newValue,
                          };
                        });
                      }}
                    />
                </Grid>
                  )}
                <Grid
                  item
                  md={6}
                  className={classes.field_padding}
                  style={{ overflow: "hidden" }}
                >
                  <Grid item md={12} style={{ paddingTop: 0 }}>
                    <DDropDownSimple
                      id="populacaoEspec"
                      placeholder="População específica"
                      label="População específica"
                      fullWidth
                      SelectProps={{ multiple: true }}
                      error={error.populacaoEspec}
                      helperText={error.populacaoEspec}
                      value={
                        dadosComplementares.populacaoEspec
                          ? dadosComplementares.populacaoEspec.id
                          : ""
                      }
                      options={populacaoEspecMap}
                      onChange={(event) => {
                        delete error.populacaoEspec;
                        // setPopulacaoEspec(populacaoEspecMap[event]);

                        setDadosComplementares((prevState) => {
                          return {
                            ...prevState,
                            populacaoEspec: populacaoEspecMap[event],
                          };
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={4} xs={4} className={classes.field_padding}>
                <strong>Número do NIT-PIS-PASEP:</strong>{" "}
                {dadosComplementares.numeroPis
                  ? dadosComplementares.numeroPis
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Deficiências:</strong>&nbsp;
                { (Array.isArray(dadosComplementares.deficiencia) && dadosComplementares.deficiencia.length == 0) ? 'Não informado' : dadosComplementares.temDeficiencia &&
                Array.isArray(dadosComplementares.deficiencia)
                  ? dadosComplementares.deficiencia.map((res, index) => {
                      return res.value.length && index == 0
                        ? res.value
                        : ", " + res.value;
                    })
                  : "Não"}
                  
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>População específica:</strong>{" "}
                {dadosComplementares.populacaoEspec
                  ? dadosComplementares.populacaoEspec.description ||
                    dadosComplementares.populacaoEspec.value
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Responsável legal:</strong>{" "}
                {dadosComplementares.responsavelLegal
                  ? dadosComplementares.responsavelLegal
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Grau de relacionamento:</strong>{" "}
                {dadosComplementares.grauDeRelacionamento
                  ? dadosComplementares.grauDeRelacionamento
                  : "Não informado"}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      {/* </Dados complementares */}
      {/* <Unidades> */}
      <div className={classes.dados_container}>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <h5 className={classes.dados_titulo}>Unidades</h5>
          </Grid>
          {editarFull ? (
            <>
                <Grid container spacing={3} className={classes.alignItemsEnd}>
                  <Grid item md={6} style={{ paddingTop: 0 }}>
                      <DAutoComplete
                        id="unidadeRef"
                        placeholder="Unidade de referência"
                        label="Unidade de referência"
                        fullWidth={true}
                        error={error.unidadeRef}
                        helperText={error.unidadeRef}
                        defaultValue={
                          unidadeRefs.filter((res) => res.id == unidadeRef)[0]
                        }
                        options={unidadeRefs}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.unidadeRef;
                          setUnidadeRef(newValue ? newValue.id : null);
                        }}
                      />
                  </Grid>
                  
                  <Grid item md={6} style={{ paddingTop: 0 }}>
                      <DMultAutoComplete
                        id="unidadeVinculada"
                        placeholder="Unidades vinculadas ao paciente"
                        label="Unidades vinculadas ao paciente"
                        error={error.unidadeVinculada}
                        helperText={error.unidadeVinculada}
                        defaultValue={unidadeVinculada}
                        options={unidadesVinculadas}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          value && option.id === value.id
                        }
                        onChange={(event, newValue) => {
                          delete error.unidadeVinculada;
                          setUnidadeVinculada(newValue);
                        }}
                      />
                  </Grid>
                </Grid>
            </>
          ) : (
            <>
              <Grid item md={12}>
                <strong>Unidade de referência:</strong>{" "}
                {unidadeRefs
                  ? unidadeRefs.filter((res) => res.id == unidadeRef)[0]
                    ? unidadeRefs.filter((res) => res.id == unidadeRef)[0].value
                    : "Não informado"
                  : "Não informado"}
              </Grid>
              <Grid item md={12}>
                <strong>Unidades vinculadas:</strong>{" "}
                {!unidadeVinculada.length && "Não informado"}
              </Grid>
              <Grid item md={12}>
                {unidadeVinculada.length ? (
                  <>
                    {unidadeVinculada.map(function (item, i) {
                      return (
                        <Chip
                          label={item.value ? item.value : "-"}
                          className={classes.dados_chip}
                        />
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      {/* </Unidades> */}
      {/* <Endereço> */}
      <div
        className={classes.dados_container}
        style={{ marginBottom: 0, border: 0 }}
      >
        <Grid container spacing={1}>
          <Grid item md={12}>
            <h5 className={classes.dados_titulo}>Endereço</h5>
          </Grid>

          {editarFull ? (
            <Grid container spacing={3} className={classes.alignItemsEnd} style={{marginBottom: "40px"}}>
              <Grid item md={4} className={classes.field_padding}>
                <DInput
                  id="cep"
                  placeholder="CEP"
                  label="CEP"
                  type="text"
                  error={error.cep}
                  helperText={error.cep}
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.cep;
                    setDadosEndereco((prevState) => {
                      return {
                        ...prevState,
                        cep: e.target.value,
                      };
                    });

                    if (e.target.value.length >= 10) {
                      getCep2(e.target.value)
                        .then((res) => {
                          if (res.erro) {
                            var new_erro = {};
                            new_erro.cep = "CEP inexistente";
                            setErrors(new_erro);

                            setDadosEndereco((prevState) => {
                              return {
                                ...prevState,
                                endereco: null,
                                bairro: null,
                                estado: null,
                                municipio: null,
                                idMunicipio: null,
                                logradouro: null,
                              };
                            });
                          } else {
                            var stateSelect = states.find(
                              (x) => x.value === res.estado_sigla
                            );
                            var municipioSelec = citys.filter(
                              (city) =>
                                city.value ==
                                  removeAccents(
                                    res.localidade.toUpperCase()
                                  ) && city.state_id == stateSelect.id
                            )[0];

                            if (municipioSelec) {
                              let logradouroFounded = logradourosCode.find(
                                (x) => x.value === res.logradouro_tipo
                              )
                                ? logradourosCode.find(
                                    (x) => x.value === res.logradouro_tipo
                                  ).id
                                : null;

                              setDadosEndereco((prevState) => {
                                return {
                                  ...prevState,
                                  endereco: res.logradouro,
                                  bairro: res.bairro,
                                  estado: stateSelect.id,
                                  idMunicipio: municipioSelec.id,
                                  municipio: municipioSelec.id,
                                  logradouro: logradouroFounded,
                                };
                              });
                            }
                          }
                        })
                        .catch(console.error);
                    }
                  }}
                  value={mascaraCEP(dadosEndereco.cep)}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                <DDropDownSimple
                  id="Logradouro"
                  placeholder="Logradouro"
                  label="Logradouro"
                  fullWidth
                  error={error.logradouro}
                  helperText={error.logradouro}
                  value={dadosEndereco.logradouro}
                  options={logradourosMap}
                  onChange={(id) => {
                    delete error.logradouro;
                    setDadosEndereco((prevState) => {
                      return {
                        ...prevState,
                        logradouro: id,
                      };
                    });
                  }}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                <DInput
                  id="endereco"
                  placeholder="Endereço"
                  label="Endereço"
                  type="text"
                  error={error.endereco}
                  helperText={error.endereco}
                  fullWidth={true}
                  onChange={(e) => {
                    delete error.endereco;
                    setDadosEndereco((prevState) => {
                      return {
                        ...prevState,
                        endereco: e.target.value,
                      };
                    });
                  }}
                  value={dadosEndereco.endereco}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                <DInput
                  id="numero"
                  placeholder="Número"
                  label="Número"
                  type="text"
                  error={error.numero}
                  helperText={error.numero}
                  fullWidth={true}
                  inputProps={{ maxLength: 8 }}
                  onChange={(e) => {
                    delete error.numero;

                    // var _value = e.target.value.replace(/[^0-9]/g, "");

                    // setNumero(e.target.value);

                    setDadosEndereco((prevState) => {
                      return {
                        ...prevState,
                        numero: e.target.value,
                      };
                    });
                  }}
                  value={dadosEndereco.numero}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                <DInput
                  id="Complemento"
                  placeholder="Complemento"
                  label="Complemento"
                  type="text"
                  fullWidth={true}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setDadosEndereco((prevState) => {
                        return {
                          ...prevState,
                          complemento: e.target.value,
                        };
                      });
                    }
                  }}
                  value={dadosEndereco.complemento}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                    <DInput
                      id="Bairro"
                      placeholder="Bairro"
                      label="Bairro"
                      type="text"
                      error={error.bairro}
                      helperText={error.bairro}
                      fullWidth={true}
                      onChange={(e) => {
                        delete error.bairro;

                        setDadosEndereco((prevState) => {
                          return {
                            ...prevState,
                            bairro: e.target.value,
                          };
                        });
                      }}
                      value={dadosEndereco.bairro}
                    />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                <DDropDownSimple
                  id="estado"
                  placeholder="Estado"
                  label="Estado"
                  fullWidth
                  error={error.estado}
                  helperText={error.estado}
                  value={dadosEndereco.estado}
                  options={states}
                  onChange={(id) => {
                    delete error.estado;

                    setDadosEndereco((prevState) => {
                      return {
                        ...prevState,
                        estado: id,
                      };
                    });
                  }}
                />
              </Grid>

              <Grid item md={4} className={classes.field_padding}>
                {dadosEndereco.estado ? (
                  <DDropDownSimple
                    id="municipio"
                    placeholder="Município"
                    label="Município"
                    fullWidth
                    error={error.municipio}
                    helperText={error.municipio}
                    value={dadosEndereco.municipio}
                    options={citys.filter(
                      (x) => x.state_id == dadosEndereco.estado
                    )}
                    onChange={(id) => {
                      delete error.municipio;

                      setDadosEndereco((prevState) => {
                        return {
                          ...prevState,
                          municipio: id,
                        };
                      });
                    }}
                  />
                ) : (
                  <DDropDownSimple
                    id="municipio"
                    placeholder="Município"
                    label="Município"
                    fullWidth
                    error={error.municipio}
                    helperText={error.municipio}
                    value={null}
                    options={[]}
                    onChange={(id) => {
                      delete error.municipio;
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item md={4} xs={4} className={classes.field_padding}>
                <strong>CEP:</strong>{" "}
                {dadosEndereco.cep
                  ? mascaraCEP(dadosEndereco.cep)
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Logradouro:</strong>{" "}
                {logradourosMap.find((x) => x.id == dadosEndereco.logradouro)
                  ? logradourosMap.find((x) => x.id == dadosEndereco.logradouro)
                      .value
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Endereço:</strong>{" "}
                {dadosEndereco.endereco || "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Número:</strong>{" "}
                {dadosEndereco.numero ? dadosEndereco.numero : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Complemento:</strong>{" "}
                {dadosEndereco.complemento || "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Bairro:</strong>{" "}
                {dadosEndereco.bairro || "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>UF:</strong>{" "}
                {states[dadosEndereco.estado - 1]
                  ? states[dadosEndereco.estado - 1].value
                  : "Não informado"}
              </Grid>
              <Grid item xs={4} md={4} className={classes.field_padding}>
                <strong>Município:</strong>{" "}
                {citys.find((x) => x.id == dadosEndereco.idMunicipio)
                  ? citys.find((x) => x.id == dadosEndereco.idMunicipio).value
                  : "Não informado"}
              </Grid>
            </>
          )}
        </Grid>
      </div>
      {/* </Endereço> */}
    </div>
  );
}

export default DetailsMain;
