import { useEffect, useContext } from "react";
import { Box } from "@material-ui/core";
import { Healing, LocalHospital } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import DButton from "../../../../shared/DButton";
import { AtendimentoContext } from "../../../../../context/AtendimentoContext";

const useStyles = makeStyles({
    box: {
        background: "#F9F9F9",
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        borderRadius: "8px",
        marginTop: 141,
        alignSelf: "flex-start",
        maxHeight: '40vh'
    },

    section: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },

    text: {
        margin: "2em 0",
        fontWeight: "500",
        whiteSpace: "nowrap",
        fontSize: "1.2rem"
    },
    icon: {
        fontSize: "5em"
    }
});

export default function IniciarAtendimento (props) {

    const classes = useStyles();

    const {attendance} = useContext(AtendimentoContext);

    useEffect(() => {
        if(attendance && attendance.user_id !== null && attendance.user_id != localStorage.getItem('uid'))
            toast.warning('Não é possível assumir, pois a mesma já foi assumida.')
    }, [attendance])

    return (
        
        <Box
        className={ classes.box }
        p={6}
        py={12}
    >
        <aside>
        <div className="container-box">
            <section className={classes.section}>
                <div className="icon">
                    <LocalHospital color="primary" className={classes.icon} />
                </div>

                <span className={classes.text}>Para iniciar o atendimento, você deve assumi-lo</span>

                
                <DButton disabled={ attendance && attendance.user_id !== null && attendance.user_id != localStorage.getItem('uid')} variant="secondary" icon={ <Healing fontSize="small" /> } size="md" onClick={e => props.iniciarAtendimento()}>
                    Assumir atendimento
                </DButton>
            </section>
        </div>
        </aside>
    </Box>
    )
}