import { useState, useEffect, useCallback } from "react";
import useError from "../../../../hooks/useError";
import { required } from "./../../../../helpers/validacoes";

import DInput from "../../../shared/DInput";
import DButton from "../../../shared/DButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withRouter } from "react-router";

import {Visibility, VisibilityOff,InfoRounded as Warning} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import './style.scss';
import {Typography} from "@material-ui/core";
import { userid } from "../../../../api/recoverid";
import { useEventListener } from "../../../../hooks/useEventListener";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: '#67738B',
    },
    tooltip: {
        maxWidth: 150,
        fontSize: 14,
        lineHeight: '21px',
        backgroundColor: '#67738B',
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

function FormLogin(props) {

    const [error, validarFormulario, setErrors] = useError({
        id: required,
        senha: required
    });


    const [id, setID] = useState('');
    const [senha, setSenha] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [paramUrl, setParamUrl] = useState(false);
    
    useEventListener("keydown", (event) => {
        if (event.key === 'Enter' && validarFormulario({ id, senha })) 
            props.login({ id, senha });
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userid = urlParams.get('userid');
        if(userid) {
            setID(userid);
            setParamUrl(true);
        }
    }, [])

    function AlertTentativas(props) {
        if(props.error && props.error.code === 2)
            return <Alert severity="error" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                        <AlertTitle>
                            <Typography className="text-st text-medium" variant="body1" gutterBottom>Tentativa { props.tentativas } de 5</Typography>
                        </AlertTitle>
                        <Typography className="text-est text-regular" variant="body1" gutterBottom>Após 5 tentativas falhas, seu acesso será bloqueado e será necessário resetar sua senha</Typography>
                    </Alert>
        else
            return <></>
    }

    function AlertError(props) {

        if(props.error && (props.error.code === 1))
            return <Alert severity="error" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
                <AlertTitle>
                    <Typography className="text-st text-medium" variant="body1" gutterBottom>{ props.error.code === 1 ? 'Acesso bloqueado' : 'Erro' }</Typography>
                </AlertTitle>
                <Typography className="text-est text-regular" variant="body1" gutterBottom>{ props.error.message }</Typography>
            </Alert>
        else if(props.error && (props.error.code === 3))
         return <Alert severity="error" style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
             <AlertTitle>
                 <Typography className="text-st text-medium" variant="body1" gutterBottom>{ props.error.code === 1 ? 'Acesso bloqueado' : 'Erro' }</Typography>
             </AlertTitle>
             <Typography className="text-est text-regular" variant="body1" gutterBottom>{ props.error.message }</Typography>
         </Alert>
        else
            return <></>
    }

    function IconToolTip()
    {
        return paramUrl ?   <BootstrapTooltip open={true} title="Este é seu ID" placement="right-start">
                                <Warning />
                            </BootstrapTooltip> : <></>
    }

    return(
        <form className="form-login" onSubmit={ form => {
            form.preventDefault();
        } }>
            <div style={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <DInput fullWidth={true} label="ID" name="id"
                    labelColor="#4F4F4F"
                    positionIcon="end"
                    icon={ <IconToolTip /> }
                    error={ !!error.id }
                    helperText={ error.id ? error.id : '' }
                    value={id}                    
                    onChange={ e => setID(e.target.value) } />
                <div style={{ marginTop: 8 }}>
                    <DInput fullWidth={true} label="Senha" name="senha"
                        labelColor="#4F4F4F"
                        type={ visibility ? 'text' : 'password' }
                        positionIcon="end"
                        icon={ visibility ? <Visibility onClick={ e => setVisibility(false) } /> : <VisibilityOff onClick={ e => setVisibility(true) } /> }
                        error={ !!error.senha }
                        helperText={ error.senha ? error.senha : '' }
                        onChange={ e => setSenha(e.target.value) } />
                </div>
            </div>
            <div style={{ height: 200, display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
                <AlertTentativas tentativas={ props.tentativas } error={ props.error } />
                <AlertError error={ props.error } />
                <div>
                    { props.error && (props.error.code === 1)
                        ?
                        <>
                            <a className="text-link" href="/" >
                                <Typography className="text-xt text-bold" style={{marginBottom: 15}}>Utilizar outra conta</Typography>                        
                            </a>
                            <DButton labelColor="#4F4F4F" onClick={ e => props.redefinir(true) } variant="primary">Redefinir senha</DButton>
                        </>
                        :
                        <>
                            <a className="text-link" onClick={ e => props.redefinir(true) } >
                                <Typography className="text-mt" style={{marginBottom: 15, textDecoration: 'underline'}}>Problemas para entrar?</Typography>                        
                            </a>
                            <DButton 
                            style={{width: "210px"}}
                            onClick={e => {
                                
                                if(validarFormulario({ id, senha })){
                                    props.login({ id, senha });
                                }
                            }} variant="primary">{ props.loading ?  <CircularProgress color="inherit" /> : 'Entrar' }</DButton>
                        </>
                    }
                </div>
            </div>
        </form>
    )
}

export default withRouter(FormLogin);