import { Device } from "@twilio/voice-sdk";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  getPrescriptions,
  initializePrescription,
  finishPrescription as finishPrescriptionById,
  finishPrescriptionDocument,
  getVoipToken,
  createVoipConference,
  getVoipCallToken,
  addParticipantVoipConference,
} from "../api/user";

export const NewHealthAttendanceContext = React.createContext({});

export const NewHealthAttendanceProvider = (props) => {
  const [healthAttendance, setHealthAttendance] = useState(null);
  const [patient, setPatient] = useState(null);
  const [newPrescription, setNewPrescription] = useState(null);
  const [prescriptionModal, setPrescriptionModal] = useState(false);
  const [patientPrescriptions, setPatientPrescriptions] = useState(null);
  const [activeCall, setActiveCall] = useState(null);
  const [activeDevice, setActiveDevice] = useState(null);
  const [finish, setFinish] = useState(false);
  const [finishLock, setFinishLock] = useState(true);
  const [finished, setFinished] = useState(false);
  const [callData, setCallData] = useState({});

  function getPatientPrescriptions() {
    if (!healthAttendance || (healthAttendance && !healthAttendance.id)) return;

    getPrescriptions(localStorage.getItem("token"), healthAttendance.id)
      .then((data) => {
        if (data.status == true) {
          //setPatientPrescriptions(data.details.prescriptionItens);

          let prescriptionsArr = data.details.prescriptions.flatMap((item) => {
            return item.prescriptions_type.map((itemType) => {
              console.log("itemType", itemType);
              return itemType;
            });
          });

          if (prescriptionsArr.length > 0) {
            setPatientPrescriptions(prescriptionsArr);
          } else {
            setPatientPrescriptions(null);
          }
        } else {
          setPatientPrescriptions(null);
        }
      })
      .catch((err) => {
        setPatientPrescriptions(null);
      });
  }

  function startPrescription(cid10 = []){
    
    if (!healthAttendance || !healthAttendance.id)
      return "Informações de atendimento necessárias.";

    initializePrescription(localStorage.getItem("token"), healthAttendance.id, cid10)
      .then((data) => {
        if(!data) {
          return toast.error("Não foi possível iniciar a prescrição.");
        }

        if (data.error) {
          return toast.error(data.error);
        }

        if(!data.details)
          return toast.error("Não foi possível iniciar a prescrição.");

        const { id, statusPrescription, url } = data.details;

        console.log("*** Nova prescrição");

        setNewPrescription({
          id,
          statusPrescription,
          url,
        });

        setPrescriptionModal(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          typeof err === 'string' ? err : "Não foi possível abrir a prescrição. Verifique se o seu documento profissional foi salvo na opção Meu Perfil."
        );
      });
  }

  const finishPrescription = async (data, prescriptionId) => {
    setPrescriptionModal(false);
    setNewPrescription(null);

    let prescriptionMedicamentos = data.Medicamentos;
    let prescriptionExames = data.Exames;
    let prescriptionAtestados = data.Atestado;
    let prescriptionDocuments = data.Documentos;

    try {
      if (prescriptionMedicamentos) {
        let data = prescriptionMedicamentos.map((item) => {
          return {
            name: item.Nome || null,
            label: item.Label || null,
            dosage: item.Posologia || null,
            via: item.ViaAdministracao || null,
          };
        });

        if (data && data.length > 0) {
          var medicamento = data[0];

          if (medicamento.name) {
            let _medicamentosSend = {
              type: "MEDICAMENTOS",
              prescriptionsItens: data,
            };

            let response_data = await finishPrescriptionById(
              localStorage.getItem("token"),
              prescriptionId,
              _medicamentosSend
            );

            console.log("*** Envio: Medicamentos", response_data);
          }
        }
      }

      if (prescriptionExames) {
        let data = prescriptionExames.map((item) => {
          return {
            name: item.Nome || "",
            label: item.Label || "",
            dosage: null,
            via: null,
          };
        });

        let _examesSend = {
          type: "EXAME",
          prescriptionsItens: data,
        };

        let response_data = await finishPrescriptionById(
          localStorage.getItem("token"),
          prescriptionId,
          _examesSend
        );

        console.log("*** Envio: Exames", response_data);
      }

      if (prescriptionAtestados) {
        let _atestadosSend = {
          type: "ATESTADO",
          prescriptionsItens: [
            {
              name: prescriptionAtestados.Observacao || null,
              label: prescriptionAtestados.Texto || null,
              dosage: null,
              via: null,
            },
          ],
        };

        let response_data = await finishPrescriptionById(
          localStorage.getItem("token"),
          prescriptionId,
          _atestadosSend
        );

        console.log("*** Envio: Atestados", response_data);
      }

      if (prescriptionDocuments) {
        for await (const document of prescriptionDocuments) {
          if (document && document.TipoDocumento && document.URL) {
            var _document = {
              type: document.TipoDocumento,
              documnetsPrescription: [
                {
                  url: document.URL,
                },
              ],
            };

            let data = await finishPrescriptionDocument(
              localStorage.getItem("token"),
              prescriptionId,
              _document
            );

            console.log(`*** [Envio - ${prescriptionId}]: Documentos`, data);
          }
        }
      }
      console.log("***", "Get Patient Prescription");

      getPatientPrescriptions();

      //   setPrescriptionsPageUpdate(!prescriptionsPageUpdate);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @description Adiciona o paciente na conferência.
   * @param {String} phoneNumber
   * @param {String} nameParticipant
   * @param {String} nameRoom
   * @param {String} health_attendance_id
   * @param {String?} to_type
   * @returns {void}
   */
  async function addCallParticipant({
    phoneNumber,
    nameParticipant,
    nameRoom,
    health_attendance_id,
    to_type = "patient",
  }) {
    if (!phoneNumber || !nameParticipant || !nameRoom || !health_attendance_id)
      return "Informações de atendimento necessárias.";

    try {
      const newParticipantData = await addParticipantVoipConference(localStorage.getItem("token"), {
        phoneNumber,
        nameParticipant,
        nameRoom,
        health_attendance_id,
        to_type,
      });

      if(newParticipantData.status) {
        setCallData({...newParticipantData.call})
      }

    } catch (err) {
      console.log(err);
    }
  }

  function muteCall(stats) {
    try {
      if (activeCall) {
        activeCall.mute(stats);
        // activeCall.isMuted() ? activeCall.mute(false) : activeCall.mute(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function hangUpCall() {
    if (activeDevice) {
      activeDevice.disconnectAll();
      activeDevice.destroy();

      if (activeCall) {
        activeCall.disconnect();
      }
    }
  }

  /**
   * @param {"paciente_solicitante" | "solicitante"} action
   * @param {object} dataPaciente
   * @param {string} dataPaciente.phoneNumber
   * @param {string} dataPaciente.nameParticipant
   * @param {string} dataPaciente.nameRoom
   * @param {string} dataPaciente.health_attendance_id
   * @param {string | "patient"} dataPaciente.to_type
   * @param {object} dataSolicitante
   * @param {string} dataSolicitante.phoneNumber
   * @param {string} dataSolicitante.nameParticipant
   * @param {string} dataSolicitante.nameRoom
   * @param {string} dataSolicitante.health_attendance_id
   * @param {string | "requesting"} dataSolicitante.to_type
   */
  async function startCall(action, dataPaciente, dataSolicitante) {
    try {

      if (dataPaciente) {
        if (!dataPaciente.health_attendance_id || !dataPaciente.nameParticipant || !dataPaciente.nameRoom || !dataPaciente.phoneNumber) {
          return false;
        }

        if (!dataSolicitante.to_type) {
          dataSolicitante.to_type = "patient";
        }
      }       

      if (dataSolicitante) {
        if (!dataSolicitante.health_attendance_id || !dataSolicitante.nameParticipant || !dataSolicitante.nameRoom || !dataSolicitante.phoneNumber) {
          return false;
        }

        if (!dataSolicitante.to_type) {
          dataSolicitante.to_type = "requesting";
        }
      } else {
        return false;
      }

      const data = await getVoipCallToken(localStorage.getItem("token"));

      const callCreate = await createVoipConference(localStorage.getItem("token"), dataPaciente.phoneNumber, "Room " + dataSolicitante.health_attendance_id)

      if (data && data.status) {
        if (!data.token) throw new Error("Token empty");

        const device = new Device(data.token);

        // Ligação para solicitante
        const params = { To: "+55" + dataSolicitante.phoneNumber, nameRoom: "Room " + dataSolicitante.health_attendance_id };

        let call = await device.connect({ params });

        setActiveCall(call);
        setActiveDevice(device);

        if (action === "paciente_solicitante") {

          // Adicionar solicitante.
          addCallParticipant({
            phoneNumber: "+55" + dataSolicitante.phoneNumber,
            nameParticipant: dataSolicitante.nameParticipant,
            nameRoom: "Room " + dataSolicitante.health_attendance_id,
            health_attendance_id: dataSolicitante.health_attendance_id,
            to_type: "requesting",
          });

          // Adicionar paciente.
          addCallParticipant({
            phoneNumber: "+55" + dataPaciente.phoneNumber,
            nameParticipant: dataPaciente.nameParticipant,
            nameRoom: "Room " + dataSolicitante.health_attendance_id,
            health_attendance_id: dataSolicitante.health_attendance_id,
            to_type: "patient",
          });
        }

        if (action === "paciente") {
          // Adicionar paciente.
          addCallParticipant({
            phoneNumber: "+55" + dataPaciente.phoneNumber,
            nameParticipant: dataPaciente.nameParticipant,
            nameRoom: "Room " + dataSolicitante.health_attendance_id,
            health_attendance_id: dataSolicitante.health_attendance_id,
            to_type: "patient",
          });
        }

        if (action === "solicitante") {
          // Adicionar solicitante.
          addCallParticipant({
            phoneNumber: "+55" + dataSolicitante.phoneNumber,
            nameParticipant: dataSolicitante.nameParticipant,
            nameRoom: "Room " + dataSolicitante.health_attendance_id,
            health_attendance_id: dataSolicitante.health_attendance_id,
            to_type: "requesting",
          });
        }
        

        device.on("registered", () => {
          console.log(device.edge);
        });

        call.on("destroyed", () => console.log("Call ended"));

        call.on("ringing", () => console.log("Ringing..."));

        call.on("accepted", () => console.log("Call accepted"));
        call.on("incoming", (conn) => console.log("incoming", conn));

        call.on("disconnected", () => console.log("Call disconnected"));

        call.on("connect", () => console.log("Call connected"));

        device.on("incoming", (conn) => console.log("incoming", conn));

        device.on("ringing", (conn) => console.log("ringing", conn));

        device.on("connection", () => {
          console.log("connection");
        });

        device.on("error", (error) => {
          console.error("Twilio device error:", error);
        });

        device.on("connect", (conn) => {
          console.log("Call success");
        });

        device.on("disconnect", (conn) => {
          console.log("Call ended");
        });

        return { device, call };
      } else {
        throw new Error(data);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return (
    <NewHealthAttendanceContext.Provider
      value={{
        healthAttendance,
        setHealthAttendance,
        patient,
        setPatient,
        startPrescription,
        setNewPrescription,
        finish,
        setFinish,
        finishLock,
        setFinishLock,
        finished,
        setFinished,
        newPrescription,
        finishPrescription,
        muteCall,
        hangUpCall,
        startCall,
        callData,
        setCallData,
        prescriptionModal,
        setPrescriptionModal
      }}
    >
      {props.children}
    </NewHealthAttendanceContext.Provider>
  );
};

export const useNewHealthAttendance = () =>
  React.useContext(NewHealthAttendanceContext);
