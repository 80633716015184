import { useState, useContext, useEffect } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Backdrop,
  makeStyles,
  Button,
} from "@material-ui/core";
import DCard from "./../../../../shared/DCard";
import DContainer from "./../../../../shared/DContainer";
import AddCircle from "@material-ui/icons/AddCircle";
import DButton from "../../../../shared/DButton";
import FormsCreateModal from "./FormsCreateModal";
import FormsEditModal from "./FormsEditModal";
import FormsDeleteModal from "./FormsDeleteModal";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#ffffff",
  },
  primaryColor: {
    color: theme.palette.primary.light,
  },
  addButton: {
    color: `${theme.palette.primary.light} !important`,
    borderColor: `${theme.palette.primary.light} !important`,
    borderRadius: 20,
    textTransform: "none",
    borderWidth: 2,
    padding: "0 18px 0"
  },
  addTagButton: {
    borderRadius: 20,
    borderWidth: 2,
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    textTransform: "none",
    padding: "0 18px 0"
},
}));

function FormsHeader(props) {
  const classes = useStyles();
  const [openModalCreateForm, setOpenModalCreateForm] = useState(false);

  function handleOpenModalCreateForm() {
    setOpenModalCreateForm(true);
  }

  function handleCloseModalCreateForm() {
    setOpenModalCreateForm(false);
  }

  return (
    <div>
      <Grid className={classes.bg}>
        <Container className="tema-light" style={{ marginTop: 0 }} maxWidthSm>
          <Grid style={{ paddingTop: 2 }}>
            <Typography
              style={{ marginTop: 32, marginBottom: 24 }}
              variant="h3"
              className="text-primary"
            >
              Atendimento
            </Typography>
            <Card style={{ background: "#f9f9f9" }}>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Formulários</Typography>
                <Button
                    variant="outlined"
                    size="sm"
                    startIcon={<AddCircle style={{ fontSize: 16 }} />}
                    className={ classes.addTagButton }
                    onClick={handleOpenModalCreateForm}
                >
                    Criar formulários
                </Button>
              </CardContent>
            </Card>
            <FormsCreateModal
              open={openModalCreateForm}
              onClose={handleCloseModalCreateForm}
              forms={props.forms}
            />
            { props.formId && props.edit ?
                <FormsEditModal
                  listForms={props.listForms}
                  open={props.edit}
                  formId={props.formId}
                  onUpdated={(e) => props.onUpdated(true)}
                  onClose={(e) => props.onClose(true)}
                  forms={props.forms}
                />
              :  
              ""
            }
            {/* <FormsDeleteModal
              open={props.onRemove}
              formId={props.formId}
              forms={props.forms}
              onCloseMdDelete={(e) => props.onCloseMdDelete(true)}
            ></FormsDeleteModal> */}
          </Grid>
        </Container>
      </Grid>
    </div>
  );
}

export default FormsHeader;
