import { useState } from "react";
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({    
    avatar: {
      backgroundColor: 'red',
      float: "left"
    }, 
    card: {
        maxWidth: 400,
        cursor: 'pointer'
    },
    closeIconSize:{
        width: 15, 
        height: 15
    },
    cardContent: {
        padding: 12,
        paddingBottom: 20
    },
    paragraph: {
        margin: 0,
        fontSize: 14
    },
    cardHeader: {
        padding: 12,
        paddingBottom: 4
    }
  }));

export default function CardNotify({title, message, onClose, onClick}) {

    const classes = useStyles();

    return(
        <>
            <Card 
                className={classes.card}
                onClick={(e) => {                    
                    onClick();
                }}
            >
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            R
                        </Avatar>
                    }
                    action={
                        <IconButton 
                            size="small"
                            aria-label="settings"
                            onClick={(e) => {
                                e.stopPropagation();                                
                                onClose();
                            }}
                        >
                            <CloseIcon 
                                className={classes.closeIconSize}
                            />
                        </IconButton>
                    }
                    title={title}                    
                />
                <CardContent className={classes.cardContent}>
                    <p className={classes.paragraph}>{message}</p>
                </CardContent>
            </Card>

        </>
    )

}