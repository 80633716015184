// Import core
import React, { useEffect, useState, useRef } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

// Import toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import icons
import CloseIcon from "@material-ui/icons/Close";

// Import components
import Header from "./Header";
import Sidebar from "./Sidebar";
import AtendimentoSidebar from "./AtendimentoSidebar";
import FloatingChat from "../../../components/floatingChat";
import Modal from "./Modal";
import "./styles.scss";

// Import API requests
import {
  createRoom,
  createConference,
  getConferenceByAttendenceId,
  joinRoom,
  conferenceAddParticipant,
  joinRoomPatient,
} from "../../../../../api/videocall";

// Import Twilio Video
import Video from "twilio-video";

const useStyles = makeStyles({
  box: {
    background: "#FFFFFF",
    boxShadow:
      "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
    borderRadius: "8px",
    marginTop: 141,
    alignSelf: "flex-start",
  },
  container: {
    height: "calc(100vh - 150px)",
    overflow: "visible",
    backgroundColor: "white",
    maxWidth: "calc(100% - 20%)!important",
  },
  bg: {
    backgroundColor: "#000",
    width: "100%",
    position: "absolute",
    height: "calc(100vh - 155px)",
    top: 155,
    left: 0,
    color: "#fff",
  },
  waiting: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  doctor: {
    width: 200,
    height: 200,
    position: "absolute",
    left: 0,
    bottom: 40,
    display: "flex",
  },
  patient: {
    width: "50%",
    height: "95%",
    left: "25%",
    top: "1%",
    position: "absolute",
    display: "flex",
  },
});

function Videocall(props) {

  console.log("PROPSVIDEOCALL", props)
  // Classes
  const classes = useStyles();

  // Refs
  const localRef = useRef();
  const remoteRef = useRef();

  // States
  const [isWaiting, setIsWaiting] = useState(true);
  const [open, setOpen] = useState(false);
  const [showAtendimentoSidebar, setShowAtendimentoSidebar] = useState(false);
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);
  const [idAtendimento, setIdAtendimento] = useState(null);
  const [previewTracks, setPreviewTracks] = useState(null);
  const [localMediaAvailable, setLocalMediaAvailable] = useState(false);
  const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
  const [activeRoom, setActiveRoom] = useState(null);
  const [toggleChat, setToggleChat] = useState(null);
  const [conferenceId, setConferenceId] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.idAtendimento && props.idAtendimento !== idAtendimento) {
      setIdAtendimento(props.idAtendimento);

      createRoom(localStorage.getItem("token"), {
        roomName: props.idAtendimento,
      })
        .then((resCreateRoom) => {

          if (resCreateRoom.status) {
            
            var dataPostRoom = {
              health_attendance_id: resCreateRoom.message,
              room: resCreateRoom.message,
            };

            createConference(localStorage.getItem("token"), dataPostRoom)
              .then((resCreateConference) => {
                if (resCreateConference.status) {
                  setRoomName(resCreateConference.conference.room);

                  //join do profissional da saude
                  joinRoom(localStorage.getItem("token"), {
                    roomName: resCreateConference.conference.room,
                  }).then((resJoinRoom) => {

                    if (resJoinRoom.status) {

                      setConferenceId(resCreateConference.conference.id);

                      var dataPostParticipant = {
                        conference_id: resCreateConference.conference.id,
                        // patient_id: props.paciente.patient.id,
                        conference_token: resJoinRoom.message.accessToken,
                      };

                      conferenceAddParticipant(
                        localStorage.getItem("token"),
                        dataPostParticipant
                      ).then((resAddParticipant) => {

                        if (resAddParticipant.conference_token) {
                        
                            setToken(resAddParticipant.conference_token);

                            console.log("alo123");

                            //Start Join Patient
                            joinRoomPatient(localStorage.getItem("token"), {
                                roomName: props.idAtendimento,
                                patient_id: props.paciente.patient.id,
                                health_attendance_id: props.idAtendimento,
                                conference_id: resAddParticipant.conference_id
                            }).then((resJoinRoomPatient) => {


                                console.log("result alo123");

                                if (resJoinRoomPatient.status) {
                                
                                    var dataPostParticipantPatient = {
                                        conference_id: resCreateConference.conference.id,
                                        patient_id: props.paciente.patient.id,
                                        conference_token:
                                        resJoinRoomPatient.message.accessToken,
                                    };

                                    conferenceAddParticipant(
                                        localStorage.getItem("token"),
                                        dataPostParticipantPatient
                                    ).then((resAddParticipantPatient) => {
                                        if (resAddParticipantPatient.conference_token) {
                                        setToken(
                                            resAddParticipantPatient.conference_token
                                        );
                                        } else {
                                        console.log("Payload 1:", resAddParticipantPatient);
                                        toast.error(
                                            "Ocorreu um erro ao criar a sala de videoconferência"
                                        );
                                        }
                                    });

                                }
                            });
                            //End Join Patient

                        } else {
                          console.log("Payload 2:", resAddParticipant);
                          toast.error(
                            "Ocorreu um erro ao criar a sala de videoconferência"
                          );
                        }
                      });

                    } else {
                      console.log("Payload 3:", resJoinRoom);
                      toast.error(
                        "Ocorreu um erro ao criar a sala de videoconferência"
                      );
                    }
                  });
                } else {
                  if (
                    resCreateConference.message ==
                    "Room eistente esse nome: " + resCreateRoom.message
                  ) {
                    getConferenceByAttendenceId(
                      localStorage.getItem("token"),
                      props.idAtendimento
                    ).then((resGetParticipant) => {
                      if (resGetParticipant.status) {
                        setRoomName(resGetParticipant.conferences[0].room);

                        joinRoom(localStorage.getItem("token"), {
                          roomName: resGetParticipant.conferences[0].room,
                        }).then((resJoinRoom) => {
                            
                          if (resJoinRoom.status) {

                            var dataPostParticipant = {
                                conference_id: resGetParticipant.conferences[0].id,
                                patient_id: props.paciente.patient.id,
                                conference_token: resJoinRoom.message.accessToken,
                            };

                            conferenceAddParticipant(
                              localStorage.getItem("token"),
                              dataPostParticipant
                            ).then((resAddParticipant) => {
                              
                                if (resAddParticipant.conference_token) {
                                //join do patient
                                joinRoomPatient(localStorage.getItem("token"), {
                                    roomName: props.idAtendimento,
                                    patient_id: props.paciente.patient.id,
                                    health_attendance_id: props.idAtendimento,
                                    conference_id: resAddParticipant.conference_id
                                }).then((resJoinRoomPatient) => {
                                  console.log("Teste marlon", resAddParticipant.conference_id);
                                    if (resJoinRoomPatient.status) {
                                        var dataPostParticipantPatient = {
                                            conference_id: resGetParticipant.conferences[0].id,
                                            patient_id: props.paciente.patient.id,
                                            conference_token: resJoinRoomPatient.message.accessToken
                                        };

                                        conferenceAddParticipant(
                                            localStorage.getItem("token"),
                                            dataPostParticipantPatient
                                        ).then((resAddParticipantPatient) => {

                                            if (resAddParticipantPatient.conference_token) {
                                                setToken(resAddParticipant.conference_token);
                                            } else {
                                                console.log("Payload 4:", resAddParticipantPatient);
                                                toast.error(
                                                "Ocorreu um erro ao criar a sala de videoconferência"
                                                );
                                            }

                                        });
                                    }

                                });

                              } else {
                                console.log("Payload 5:", resAddParticipant);
                                toast.error(
                                  "Ocorreu um erro ao criar a sala de videoconferência"
                                );
                              }
                            });
                          } else {
                            
                            console.log("Payload 6:", resJoinRoom);
                            toast.error(
                              "Ocorreu um erro ao criar a sala de videoconferência"
                            );
                          }
                        });

                      } else {

                        console.log("Payload 7:", resGetParticipant);
                        toast.error(
                          "Ocorreu um erro ao criar a sala de videoconferência"
                        );

                      }
                    });
                  } else {
                    console.log("Payload 8:", resCreateConference);
                    toast.error(
                      "Ocorreu um erro ao criar a sala de videoconferência"
                    );
                  }
                }
              })
              .catch((err) => {
                console.log("Payload 9:", err);
                toast.error(
                  "Ocorreu um erro ao criar a sala de videoconferência"
                );
              });
          } else {
            console.log("Payload 10:", resCreateRoom);
            toast.error("Ocorreu um erro ao criar a sala de videoconferência");
          }
        })
        .catch((err) => {
          console.log("Payload 11:", err);
          toast.error("Ocorreu um erro ao criar a sala de videoconferência");
        });
    }
  }, [props]);

  useEffect(() => {
    if (token) {
      joinTwilioRoom();
    }
  }, [token]);

  function joinTwilioRoom() {
    console.log("Joining room '" + roomName + "'...");

    let connectOptions = {
      name: roomName,
    };

    if (previewTracks) {
      connectOptions.tracks = previewTracks;
    }

    console.log(
      "Joining room with token'" + token + "' and options",
      connectOptions
    );

    Video.connect(token, connectOptions).then(roomJoined, (error) => {
      if (error.message === "Requested device not found") {
        toast.error(
          "Não foi possível habilitar a webcam e/ou o microfone, verifique se os mesmos encontram-se conectados ao computador 2"
        );
      } else {
        alert("Could not connect to Twilio: " + error.message);
      }
    });
  }

  function roomJoined(room) {
    console.log("Joined as localMedia");

    setActiveRoom(room);
    setLocalMediaAvailable(true);
    setHasJoinedRoom(true);

    var localContainer = localRef.current;

    if (localContainer && !localContainer.querySelector("video")) {
      console.log(room.localParticipant)
      attachParticipantTracks(room.localParticipant, localContainer, 'local');
    }

    //Attach the Tracks of the room's participants.
    room.participants.forEach((participant) => {
      console.log("Already in Room: '" + participant.identity + "'");
      setIsWaiting(false);

      var remoteContainer = remoteRef.current;

      attachParticipantTracks(participant, remoteContainer, 'remote');
    });

    // Participant joining room
    room.on("participantConnected", (participant) => {
      console.log("Joining: '" + participant.identity + "'");
      setIsWaiting(false);

      var remoteContainer = remoteRef.current;

      attachParticipantTracks(participant, remoteContainer, 'remote');
    });

    // Attach participant's tracks to DOM when they add a track
    room.on("trackSubscribed", (track, publication, participant) => {
      setIsWaiting(false);

      var remoteContainer = remoteRef;

      if(remoteRef && remoteRef.current) {
        
        console.log(`trackSubscribed::${track.kind}`, track)

        remoteContainer.current.appendChild(track.attach());
      } else {
        console.log(`trackSubscribed::${track.kind}::ERROR`, "remoteRef is null")
      }

      // tracks.forEach((value) => {
      //     if(value.track) {
      //       container.appendChild(value.track.attach());
      //     }
      // });

      // detachNewTracks([track], remoteContainer);
    });

    room.on("trackUnsubscribed", (track, publication, participant) => {
      setIsWaiting(false);

      var remoteContainer = remoteRef;

      console.log(`trackUnsubscribed::${track.kind}`, track)

      if(remoteRef && remoteRef.current) {
        Array.from(remoteContainer.current.children).forEach((child) => {
          remoteContainer.current.removeChild(child);
        })
      } else {
        console.log(`trackUnsubscribed::${track.kind}::ERROR`, "remoteRef is null")
      }
      
      // remoteContainer.current.removeChild(track.attach());

      // if(remoteRef.current) {
      //   attachNewTracks([track], remoteContainer);
      // }
    });

    // Detach all participant's track when they leave a room.
    room.on("participantDisconnected", (participant) => {
      console.log("Participant '" + participant.identity + "' left the room");
      detachParticipantTracks(participant);
      setIsWaiting(true);
    });

    // Once the local participant leaves the room, detach the Tracks
    // of all other participants, including that of the LocalParticipant.
    room.on("disconnected", () => {
      if (previewTracks) {
        previewTracks.forEach((value) => {
          value.track.stop();
        });
      }

      detachParticipantTracks(room.localParticipant);
      room.participants.forEach(detachParticipantTracks);

      setActiveRoom(null);
      setHasJoinedRoom(false);
      setLocalMediaAvailable(false);
    });
  }

  function attachTracks(tracks, container, type) {
    console.log("Attaching tracks to container", tracks);
    tracks.forEach((value) => {
        if(value.track) {
          container.appendChild(value.track.attach());
        }
    });
  }

  function attachNewTracks(tracks, container, type) {
    tracks.forEach((value) => {
      if(value && value.track) {
        container.appendChild(value.track.attach());
      } else if(value) {
          container.appendChild(value.attach());
        }
    });
  }

  function attachParticipantTracks(participant, container, type) {
    var tracks = Array.from(participant.tracks.values());

    console.log("Teste call marlon", tracks);

    attachTracks(tracks, container, type);
  }

  function detachTracks(tracks) {
    console.log("Teste detach", tracks);

    tracks.forEach((value) => {
      if (value.isSubscribed) {
        value.track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      }
    });
  }

  function detachNewTracks(tracks) {
    console.log("Teste detach new", tracks);

    tracks.forEach((value) => {
      var detach = value.detach()
      
      console.log("Teste detach new element", detach);

      detach.forEach((detachedElement) => {
        console.log("Teste detach new element", detachedElement);
        detachedElement.remove();
      });
    });
  }

  function detachParticipantTracks(participant) {
    var tracks = Array.from(participant.tracks.values());

    console.log("Teste detach participant", tracks);

    detachTracks(tracks);
  }

  function leaveRoom() {
    if (hasJoinedRoom) {
      activeRoom.disconnect();

      setHasJoinedRoom(false);
      setLocalMediaAvailable(false);
      setIsWaiting(true);
    }
  }

  function leaveCall() {
    if (hasJoinedRoom) {
      activeRoom.disconnect();

      setHasJoinedRoom(false);
      setLocalMediaAvailable(false);
      setIsWaiting(true);
    }

    props.onClose(true);
  }

  function muteAudio() {
    if (localMediaAvailable) {
      activeRoom.localParticipant.audioTracks.forEach((value) => {
        value.track.disable();
      });
    }
  }

  function unmuteAudio() {
    if (localMediaAvailable) {
      activeRoom.localParticipant.audioTracks.forEach((value) => {
        value.track.enable();
      });
    }
  }

  function disableVideo() {
    if (localMediaAvailable) {
      activeRoom.localParticipant.videoTracks.forEach((value) => {
        value.track.disable();
      });
    }
  }

  function enableVideo() {
    if (localMediaAvailable) {
      activeRoom.localParticipant.videoTracks.forEach((value) => {
        value.track.enable();
      });
    }
  }

  return (
    <>
      <Header
        paciente={props.paciente.patient}
        onClose={(e) => leaveCall()}
        onHangUp={(e) => leaveRoom()}
        onMute={(e) => muteAudio()}
        onUnmute={(e) => unmuteAudio()}
        onDisableVideo={(e) => disableVideo()}
        onEnableVideo={(e) => enableVideo()}
        toggleChat={(e) => setToggleChat(!toggleChat)}
        openSideBar={(e) => setShowAtendimentoSidebar(!showAtendimentoSidebar)}
      />
      <Sidebar idAtendimento={props.idAtendimento} />
      <AtendimentoSidebar
        show={showAtendimentoSidebar}
        paciente={props.paciente}
        patientData={props.patientData}
        userDominios={props.userDominios}
        unidadesRef={props.unidadesRef}
        unidadesVinculadas={props.unidadesVinculadas}
        toggleModal={(e) => props.toggleModal(true)}
        getPDF={(e) => props.getPDF(e)}
        setModalAtendimentoCmd={(e) => props.setModalAtendimentoCmd(true)}
        attendance={props.attendance}
      />

      <FloatingChat
        idAtendimento={props.idAtendimento}
        idUser={props.idUser}
        videocall
        openChat={toggleChat}
      />

      <Grid container className={classes.bg} spacing={3}>
        {isWaiting ? (
          <div className={classes.waiting}>
            <div class="lds-ellipsis">
              <div class="blue"></div>
              <div></div>
              <div class="blue"></div>
              <div></div>
            </div>
            <Typography>Aguardando paciente</Typography>
          </div>
        ) : (
          <div>
            <div className="flex-item">
              <div
                className={classes.patient}
                ref={remoteRef}
                id="remote-media"
              />
            </div>
          </div>
        )}

        <div>
          {localMediaAvailable ? (
            <div className="flex-item">
              <div ref={localRef} id="local-media" className={classes.doctor} />
            </div>
          ) : (
            ""
          )}
        </div>
      </Grid>

      <Dialog open={open} onClose={handleClose} disableBackdropClick="true">
        <IconButton
          onClick={handleClose}
          style={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Modal title="Reconectando">Aguarde... Verifique sua conexão</Modal>
      </Dialog>
    </>
  );
}

export default Videocall;
