import axios from 'axios';
import env from "react-dotenv";

export async function AnamnesePreFormByHealthAttendanceId(token, healthAttendanceId) {

    const res = await axios.get(`${env.API_URL}/anamnese-pre-form/health-attendance/${healthAttendanceId}?token=${token}`);
    return res.data;
}

export async function updateComorbidity(token, anamneseFormId, data){
    
    const body = {
        comorbidity: data
    }

    const res = await axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/comorbidity?token=${token}`, body);
    return res.data;
}

export async function updateComorbidityFamilyHistory(token, anamneseFormId, data){

    const body = {
        comorbidity_family_history: data
    }

    const res = await axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/comorbidity-family-history?token=${token}`, body);
    return res.data;
}

export async function updateSymptom(token, anamneseFormId, data){
    
    const body = {
        symptom: data
    }

    const res = axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/symptom?token=${token}`, body);
    return res.data;
}

export async function updateMedicineAllergy(token, anamneseFormId, data){

    const body = {
        medicine_allergy: data
    }

    const res = await axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/medicine-allergy?token=${token}`, body);    
    return res.data;
}

export async function update(token, anamneseFormId, body){

    const res = await axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}?token=${token}`, body);    
    return res.data;
}

export async function updateFoodAllergy(token, anamneseFormId, data){
    
        const body = {
            food_allergy: data
        }
    
        const res = await axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/food-allergy?token=${token}`, body);
        return res.data;
}

export async function updateMedicine(token, anamneseFormId, data){

    const body = {
        medicine: data
    }

    const res = axios.put(`${env.API_URL}/anamnese-pre-form/${anamneseFormId}/medicine?token=${token}`, body);
    return res.data;
}

// Para importação dinâmica.
export default {
    AnamnesePreFormByHealthAttendanceId,
    updateComorbidity,
    updateComorbidityFamilyHistory,
    updateSymptom,
    updateMedicineAllergy,
    updateFoodAllergy,
    updateMedicine
}