import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Chip, CircularProgress, Grid, Link, Typography } from "@material-ui/core";
import { Cancel, Clear, Edit, Reply, Send } from "@material-ui/icons";
import DButton from "../../../../shared/DButton";
import construtor from "../../../../../api/construtor";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  headerConsultaCancelada: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: "0",
    marginBottom: "0",
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  gridButton: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
  },
  gridLink: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  headerModalSuccess: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    margin: 20,
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  }  
}));

export default function FormsDeleteModal(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);

  const notify = {
    form: {
      delete: () => toast.success("Formulário removido com sucesso!"),
      error: () => toast.error("Erro ao remover o formulário!"),
    }
  };

  function cancelConsult() {
    setLoading(true)
    construtor
      .delete(localStorage.getItem("token"), props.formId)
      .then((res) => {
        if (res.status) {
          notify.form.delete();
          getAllForms();
          closeModal();
          setLoading(false)
        } else {
          notify.form.error();
        }
      });
  }

  function getAllForms(){
    construtor.getList(localStorage.getItem('token')).then(res => {
        if(res.status)
            props.forms(res.anamnese_forms.data)
    });
  }

  function closeModal() {
    //try catch if send true open modal success
    props.onCloseMdDelete(true);
  }

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography
            className="simple-modal-title-alert-danger text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Cancel style={{fontSize: "20px"}} /> &nbsp;Excluir Formulário
          </Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              props.onCloseMdDelete(true);
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light" style={{padding: "0px 10px"}}>
          <>

            <Grid item md={12} sm={6}>
              <Typography className="text-mt text-regular text-center mb-1" variant="body1">
                Confirmar a exclusão desse formulário? 
              </Typography>
                    
            </Grid>

            <Grid item md={8} sm={3} className={classes.gridButton}>
              <DButton
                variant="outlined"
                color="secondary"
                size="sm"
                onClick={closeModal}
              >
                Voltar
              </DButton>
              <DButton
                size="sm"
                variant="primary"
                className={"btn-error"}
                onClick={ cancelConsult }
            >
                { loading ? <CircularProgress size={ 15 } color="inherit" /> : 'Excluir' }
            </DButton>
            </Grid>
            
          </>
        </Grid>
      </>
    </div>
  );


  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onCloseMdDelete}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
