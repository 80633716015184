import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { OpenInNew } from "@material-ui/icons";
import { useEffect } from "react";
import { addWeeks, format, subDays, subWeeks } from "date-fns";
import { ptBR } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 8,
    padding: 16,
    margin: 8,
  },
  card__title: {
    margin: "0 0 8px",
    fontSize: 16,
    color: theme.palette.neutral.black,
  },
  card__note: {
    fontSize: 12,
    color: theme.palette.advanced.light,
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  card__button: {
    textTransform: "none",
    boxShadow: "none",
    borderRadius: 20,
    color: theme.palette.neutral.white,
    background: theme.palette.primary.light,
    "&:hover": {
      boxShadow: "none",
      background: theme.palette.primary.main,
    },
  },
  flex_align: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    color: theme.palette.neutral.black,
    height: 12,
    marginLeft: -6,
  },
}));


function Card(props) {
  const classes = useStyles();

function calcDpp(diasGestacao, semanasGestacao) {
  if (diasGestacao === "" || semanasGestacao === "") return;

  console.log(typeof diasGestacao, semanasGestacao);

  var dias = diasGestacao;
  var semanas = semanasGestacao;

  var _base = addWeeks(new Date(), 42); // Adiciona 42 semanas a partir de hoje
  var _dppDays = subDays(_base, dias); // Subtrai os dias que já se passaram
  var _dpp = subWeeks(_dppDays, semanas); // Subtrai as semanas que já se passaram

  console.log(
    "Data do parto prevista",
    format(_dpp, "dd/MM/yyyy", { locale: ptBR })
  );

  return format(_dpp, "dd/MM/yyyy", { locale: ptBR });
};

  function getDppByGroupForm(groupForm) {
    if(groupForm.group_form_anwser){
      const idadeGest = groupForm.group_form_anwser.find(x => x.group_form_input.name === "idade_gestacional_dias")
      const semanaGest = groupForm.group_form_anwser.find(x => x.group_form_input.name === "idade_gestacional_semanas")

      if(idadeGest && semanaGest){
        return calcDpp(idadeGest.value, semanaGest.value)
      }
    }
  }

  return (
    <div className={classes.card}>
      <div className={classes.flex_align}>
        <div>
          <h4 className={classes.card__title}>{props.title}</h4>
          {/* <Typography className={classes.card__note}>
            <AssignmentOutlinedIcon className={classes.icon} /> {props.exams}{" "}
            Exames
          </Typography> */}
          <Typography className={classes.card__note}>
            <ChatBubbleOutlineOutlinedIcon className={classes.icon} />{" "}
            {props.attendance} Atendimentos
          </Typography>
          <Typography className={classes.card__note}>
            <AccessTimeIcon className={classes.icon} /> Data prevista do parto:{" "}
            {getDppByGroupForm(props.groupForm)}
          </Typography>
        </div>

        <div style={{display: "flex", flexDirection: "column"}}>
          <OpenInNew 
            onClick={e => props.openForm()}
            style={{alignSelf: "center", width: 20, fill: "#4a4ea9", marginBottom: 15, cursor: "pointer"}} />
          <Button
            onClick={(x) => props.click()}
            classes={{ contained: classes.card__button }}
            variant="contained"
            size="sm"
          >
            Entrar
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Card;
