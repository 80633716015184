// Import React
import { React, useState, useEffect, useContext } from 'react';

// Import Material-ui
import { Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from '@material-ui/core/styles';
import { AtendimentoContext } from "../../../../context/AtendimentoContext";
// Import components
import PacienteGrid from '../../../Paciente/components/PacienteDetails/components/DetailsPrescriptions/components/PacienteGrid/PacienteGrid';

// Import API requests
import { getPrescriptionsByPatientId } from  '../../../../api/user';

const useStyles = makeStyles((theme) => ({
  tbl_header: {
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: 21,
    color: "#4F4F4F"
  },
  pagination: {
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    '& .MuiPagination-ul': {
      color: "#272727",
      background: "transparent",
      fontWeight: 400,

      "& > li": {
        "& button": {
          fontSize: 16
        }
      },
      "& > li:first-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        }
      },
      "& > li:last-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
        }
      },
      '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        background: "#fff",
        fontWeight: 500
      },
    }
  }

}));



function DetailsPrescriptions(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;
  const {prescriptionsPageUpdate} = useContext(AtendimentoContext);

  const [totalPages, setTotalPages] = useState(1);
  const [prescriptions, setPrescriptions] = useState(null);
  const [prescriptionsMap, setPrescriptionsMap] = useState(null);
  

  function getPrescriptionFormat(prescription) {  
    const {certificate, exam, forwarding, lme, revenue, ...rest} = prescription;

    var _prescription = {};

    if (certificate) {

      var _certificateItens = Array.isArray(certificate.certificateItens) ? certificate.certificateItens : Object.values(certificate.certificateItens);
      var _certificateDocument = certificate.certificateDocument;

      _prescription = {
        ...rest,
        ..._prescription,
        certificate: {
          type: "Atestado",
          teste: "teste",
          document: _certificateDocument,
          certificateDocument: _certificateDocument ? _certificateDocument : null,
          data: _certificateItens.length > 0 ? _certificateItens.map(x => {
            return {
              ...x,
              certificateDocument: _certificateDocument ? _certificateDocument : null,
              certificateItens: _certificateItens
            }
          }) : null
        }
      }

    }

    if (exam) {
      
      var _exames = Array.isArray(exam.examItens) ? exam.examItens : Object.values(exam.examItens);
      var _examesDocumentos = exam.examDocument

      _prescription = {
        ...rest,
        ..._prescription,
        exam: {
          type: "Exame",
          document: exam.examDocument,
          examDocument: _examesDocumentos ? _examesDocumentos : null,
          data: _exames.length > 0 ? _exames.map(x => {
            return {
              ...x,
              examDocument: _examesDocumentos ? _examesDocumentos : null,
              examItens: _exames,
            }
          }) : null
        }
      }

    }

    if (revenue) {

      var _receitas = revenue.revenueItens;
      var _receitasDocumentos = revenue.revenueDocument;

      _prescription = {
        ...rest,
        ..._prescription,
        revenue: {
          type: "Receita",
          document: revenue.revenueDocument,
          renevueDocument: _receitasDocumentos ? _receitasDocumentos : null,
          data: _receitas.length > 0 ? _receitas.map(x => {
            return {
              ...x,
              renevueDocument: _receitasDocumentos ? _receitasDocumentos : null,
              revenueItens: _receitas,
            }
          }) : null
        }
      }

    }

    if (forwarding) {

      var _forwarding = forwarding.forwardingItens;
      var _forwardingDocumentos = forwarding.forwardingDocument

      _prescription = {
        ...rest,
        ..._prescription,
        forwarding: {
          type: "Encaminhamento",
          document: forwarding.forwardingDocument,
          forwardingDocumentos: _forwardingDocumentos ? _forwardingDocumentos : null,
          data: _forwarding.length > 0 ? _forwarding.map(x => {
            return {
              ...x,
              forwardingDocumentos: _forwardingDocumentos ? _forwardingDocumentos : null,
              forwardingItens: _receitas,
            }
          }) : null
        }
      }
    }

    console.log("_prescription", _prescription);

    return _prescription

  }

  useEffect(() => {
    if (props.idPaciente) {
      getPrescriptionsByPatientId(localStorage.getItem("token"), props.idPaciente, 1).then(res => {
        if (res.status) {
          
          console.log("res", res);

          var _prescriptionsFormat = res.prescription.map(prescription => getPrescriptionFormat(prescription));
          //var _prescriptionsFormat = json.prescription.map(prescription => getPrescriptionFormat(prescription));
          
          setPrescriptionsMap(_prescriptionsFormat);

          // console.log("_prescriptionsFormat", _prescriptionsFormat);
          
          setTotalPages(res.paginate.last_page);
          setPrescriptions(res.prescription);
        }
      }).catch(error => {
        setPrescriptions([]);
        console.log('error', error);
      });
    }
  }, [props.idPaciente, prescriptionsPageUpdate]);

  function getPrescriptionPage(page){
    getPrescriptionsByPatientId(localStorage.getItem("token"), props.idPaciente, page).then(res => {
      if(res.status){
        setPrescriptions(res.prescription.data);
      }
    }).catch(error => { 
      console.error(error)  
    });
  }
  
  return (
        <div style={{ height: '100%' }}>
          <PacienteGrid
            prescriptions={ prescriptions }
            prescriptionsMap={ prescriptionsMap }
            detalhes={ e => props.detalhes(e) }
          ></PacienteGrid>
          <div className={ classes.pagination }>
            <Pagination 
              count={ totalPages } 
              onChange={ (event, page) => {
                getPrescriptionPage(page);
              }}
            />
          </div>
        </div>
    );
}

export default DetailsPrescriptions;