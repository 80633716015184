import {Grid, Card, CardContent, Typography, CircularProgress, Backdrop} from "@material-ui/core";
import UnidadesAdministrativas from "./UnidadesAdministrativas";
import UnidadesAssistenciais from "./UnidadesAssistenciais";
import {makeStyles} from "@material-ui/core/styles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    card: {
        background: '#F4F4F4'
    },
    cardBody: {
        padding: 15,
        paddingBottom: '15px !important'
    },
    backDrop: {
        zIndex: 3000,
        color: '#FFF'
    }
}))

function UnidadesGrid(props) {
    const classes = useStyles();

    const SemUnidades = (props) => {
        return <Card classes={{root: classes.card}}>
            <CardContent classes={{root: classes.cardBody}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item md={12}>
                        <Typography variant="h4">{ props.title }</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1" className="text-medium text-st">Não existem unidades cadastradas no momento</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    }

    function handleUnidade(id) {
        props.unidade(id);
    }

    return (
        <>
            <Backdrop className={ classes.backDrop } open={ props.loading }>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    { 
                    props.unidades && props.unidades.filter(item => item.medical_unit_type === 'Administrativa' || item.medical_unit_type === 'TEST').length > 0 
                        ?   <UnidadesAdministrativas 
                                unidade={ unidade => handleUnidade(unidade) } 
                                unidades={ props.unidades && props.unidades.filter(item => item.medical_unit_type === 'Administrativa' || item.medical_unit_type === 'TEST') } 
                            /> 
                        : <SemUnidades title="Unidades administrativas" /> 
                    }
                </Grid>
                <Grid item md={12}>
                    { 
                        props.unidades && props.unidades.filter(item => item.medical_unit_type === 'Assistencial').length > 0 
                            ?   <UnidadesAssistenciais 
                                    unidade={ unidade => handleUnidade(unidade) } 
                                    unidades={ props.unidades && props.unidades.filter(item => item.medical_unit_type === 'Assistencial') }
                                /> 
                            : <SemUnidades title="Unidades assistenciais" /> }
                </Grid>
            </Grid>
        </>
    )
}

export default UnidadesGrid;