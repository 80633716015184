import env from 'react-dotenv';
import axios from 'axios';

// function acceptTermUse(dados) {
//     var body = new FormData();
//     body.append('terms_user_id', dados);
//     body.append('accepted', true);

//     return fetch(`${env.API_URL}/user/term?token=${ localStorage.getItem('token') }`, {
//         method: 'post',
//         body
//     }).then(res => res.json());
// }

export function acceptTermUse(termId, userId){
    return axios.post(`${env.API_URL_SERVICE}/terms/accept-term?token=${ localStorage.getItem('token') }`, {
        term_id: termId,
        user_id: userId
    }).then(res => res.data);
}

export function denyTermUse(termId, userId){
    return axios.post(`${env.API_URL_SERVICE}/terms/deny-term?token=${ localStorage.getItem('token') }`, {
        term_id: termId,
        user_id: userId
    }).then(res => res.data);
}

export function getTermActive(token){
    return axios.get(`${env.API_URL_SERVICE}/terms/get-term-active?token=${ token }`)
            .then(res => res.data)
}