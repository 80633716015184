import env from 'react-dotenv';

function getAllSymptoms(token) {
    return fetch(`${env.API_URL}/symptom/list?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createSymptom(token, dados) {
    return fetch(`${env.API_URL}/symptom?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateSymptom(token, dados) {
    return fetch(`${env.API_URL}/symptom/${ dados.id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteSymptom(token, id) {
    return fetch(`${env.API_URL}/symptom/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

export { getAllSymptoms, createSymptom, updateSymptom, deleteSymptom }