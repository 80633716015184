
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from "react-router-dom";
import Box from '@material-ui/core/Box';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';

import DButton from "../../../shared/DButton";
import TabPanel from "./components/TabPanel";
import SimpleModal from "../../../shared/Modal";

import DetailsMain from './components/DetailsMain';
import Atendimentos from '../../../AtendimentoImediato/components/Atendimentos';
import Prescricoes from '../../../AtendimentoImediato/components/Prescricoes';
import DetailsAppointments from './components/DetailsAppointments';
import { AtendimentoCMD } from "../../../AtendimentoImediato/modais"
import VisualizarExame from "../../../AtendimentoImediato/components/VisualizadorExames"
import { userDominios } from "../../../../api/dominios";
import { PatientContext, PatientProvider, usePatient } from "./../../../../context/PatientContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: theme.palette.background.transparent
    },
    paciente__topo: {
        position: "absolute",
        left: 0,
        top: 88,
        width: "100%",
        height: 61,
        display: "flex",
        padding: "10px 40px",
        background: theme.palette.background.grass,
        boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 18
    },
    paciente__tabs: {
        marginTop: 93,
        backgroundColor: "transparent!important"
    },
    paciente__nome: {
        height: 20,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: theme.palette.neutral.black,
        fontWeight: 600
    },
    paciente__button: {
        backgroundColor: `${theme.palette.primary.light} !important`
    },
    MuiTabsflexContainer: {
        color: theme.palette.primary.light,
        boxShadow: "none"
    },
    MuiTabroot: {
        textTransform: "none",
        backgroundColor: theme.palette.background.cloud,
        marginLeft: 4,
        marginRight: 4,
        fontWeight:  700,
        fontSize: "16px",
        minWidth: 180,
        borderRadius: "8px 8px 0 0",
    },
    MuiTabtextColorInherit: {
        opacity: 1
    },
    Muiselected: {
        backgroundColor: theme.palette.background.snow,
        borderRadius: "8px 8px 0 0",
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        border: 0,
        borderBottom: "1px solid",
        borderColor: theme.palette.primary.light,
    },
    box: {
        background: theme.palette.background.snow,
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        borderRadius: "8px",
        marginTop: 141,
        height: "calc(100vh - 378px)",
        overflow: "auto"
    },
    boxMain: {
        background: theme.palette.background.snow,
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        borderRadius: "0 0 8px 8px",
        marginLeft: 4,
        height: "calc(100vh - 378px)",
        overflow: "auto"
    },
    dados_titulo: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",  
        color: theme.palette.primary.light,
        margin: 0,
        padding: "6px 0"
    },
    backgroundCoud: {
        color: theme.palette.background.cloud,
    }
}));

function a11yProps(index) {
    return {
      id: 'tab-${index}',
      'aria-controls': 'tab-${index}',
    };
}

function PacienteDetails(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [modalAtendimentoCmd, setModalAtendimentoCmd] = useState(false);
    const [urlPdf, setUrlPdf] = useState(null);
    const [solicitacao, setSolicitacao] = useState(false);
    const { sideBarInternal, setSideBarInternal } = usePatient(PatientContext);

    const [dominios, setDominios] = useState(null)

    document.body.style.backgroundColor = classes.backgroundCoud.color;

    function getPDF(url) {
        setUrlPdf(url);
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(function(){

        userDominios().then(res => {            
            if(res.status)
                setDominios(res)
        }).catch(err => console.log(err));
        
        props.triggerSidebar && props.triggerSidebar(true);
        setSideBarInternal(false)

        
        if(!props.paciente || !props.paciente.patient) 
        {
            console.log("Buscando paciente")
            const pid = window.location.href.split('/').pop();

            if(isNaN(pid))
                return
            
            props.getPaciente(pid);
        }

        return () => {
            props.triggerSidebar && props.triggerSidebar(false);
        }


    }, []);

    if(!props.paciente || !props.paciente.patient) 
    {
        // console.log(props.paciente.patient);
        
        return "";
    }

    return (
        <>
            { solicitacao && props.paciente && props.paciente.patient ? <Redirect to={ `/solicitacao?pid=${props.paciente.patient.id}` } /> : '' }

            <AtendimentoCMD open={modalAtendimentoCmd} setModal={_ => setModalAtendimentoCmd(!modalAtendimentoCmd)} />

            <SimpleModal fullPage open={ urlPdf } onClose={ e => setUrlPdf(false) }>
                <Grid container justifyContent="center" alignItems="center" style={{ height: "88%" }}>
                    <Grid item className="text-center" style={{ height: "100%", width: "100%"}}>
                        {
                            <VisualizarExame getIframe urlPdf={ urlPdf } />
                        }
                    </Grid>
                </Grid>
            </SimpleModal>

            <Grid className={ classes.bg } style={{ paddingTop: 2 }}>
                <Grid item className={ classes.paciente__topo }>
                    <NavLink 
                        style={{textDecoration: 'none'}}
                        to='/pacientes'
                        >
                        <button className={ classes.paciente__nome }
                        //  onClick={ e => props.listar(true) }
                        style={{ fontSize: "18px", lineHeight: "27px", border: 0, backgroundColor: "transparent", cursor: "pointer" }}>
                            <ArrowBackIosIcon color="primary" fontSize="large" /> 
                            {props.paciente.patient.social_name || props.paciente.patient.full_name} 
                            {props.paciente.patient.pronoun ? ` (${props.paciente.patient.pronoun.name})` : ""}
                        </button>
                    </NavLink>

                    <DButton onClick={ e => setSolicitacao(true) } variant="primary" className={ classes.paciente__button } icon={ <AddBoxIcon fontSize="small" /> } size="md">
                        Solicitar consulta
                    </DButton>
                </Grid>

                <Grid container spacing={3} alignItems="center">
                    <Grid item md={8}>
                        <AppBar className={ classes.paciente__tabs } color="default" position="static" elevation={0}>
                            <Tabs
                                classes={{
                                    flexContainer: classes.MuiTabsflexContainer
                                }}
                                value={ value }
                                onChange={ handleChange }
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab
                                    classes={{
                                        root: classes.MuiTabroot, 
                                        textColorInherit: classes.MuiTabtextColorInherit,
                                        selected: classes.Muiselected
                                    }}
                                    label="Dados do paciente"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    classes={{
                                        root: classes.MuiTabroot, 
                                        textColorInherit: classes.MuiTabtextColorInherit,
                                        selected: classes.Muiselected
                                    }}
                                    label="Atendimentos"  
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    classes={{
                                        root: classes.MuiTabroot, 
                                        textColorInherit: classes.MuiTabtextColorInherit,
                                        selected: classes.Muiselected
                                    }}
                                    label="Prescrições"
                                    {...a11yProps(2)}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} className={ classes.boxMain } index={0}>
                            { dominios ? 
                                <PatientProvider>
                                    <DetailsMain
                                        userDominios={ dominios }
                                        unidadesRef={ props.unidadesRef }
                                        unidadesVinculadas={ props.unidadesVinculadas }
                                        paciente={ props.paciente }
                                        updatePatientInfoData={ props.updatePatientInfoData }
                                        onUpdated={ dados => console.log(dados) }
                                    />
                                </PatientProvider>
                                :
                                <>
                                    <CircularProgress size={ 15 } color="inherit" /> carregando...
                                </>
                            }
                        </TabPanel>
                        <TabPanel value={value} className={ classes.boxMain } index={1}>
                            <Atendimentos 
                                idPaciente={ props.paciente.patient ? props.paciente.patient.id : null }
                                detalhes={ e => setModalAtendimentoCmd(true) }
                            />
                        </TabPanel>
                        <TabPanel value={value} className={ classes.boxMain } index={2}>
                            <Prescricoes 
                                idPaciente={ props.paciente.patient ? props.paciente.patient.id : null }
                                detalhes={ e => getPDF(e) }
                            />
                        </TabPanel>
                    </Grid>
                    <Grid item md={4}>
                        <Box className={ classes.box } p={3} >
                            { dominios ? 
                                <DetailsAppointments
                                    userDominios={ dominios }
                                    unidadesRef={ props.unidadesRef }
                                    unidadesVinculadas={ props.unidadesVinculadas }
                                    paciente={ props.paciente }
                                    pregnancy={e => props.pregnancy()}
                                    onGroup={ e => null }
                                />
                                :
                                <>
                                    <CircularProgress size={ 15 } color="inherit" /> carregando...
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
      </>
    );
}

export default PacienteDetails;