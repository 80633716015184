//Import core
import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Select, Typography, Grid } from "@material-ui/core";

//Import styles
import "./styles.scss";

//Import icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';

//Import custom components
import DButton from "../../../shared/DButton";

//Import API services
import { getAllUnidades } from "../../../../api/unidades";
import { getAllGroups } from "../../../../api/groups";
import { getPatientByFilter } from "../../../../api/patient"
import { usePatientFilter } from "../../../../context/PatientFilterContext";

import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        [theme.breakpoints.up("lg")]: {
          marginTop: "4vh",
        },
        [theme.breakpoints.down("md")]: {
          marginTop: "8vh",
        },
    },
    footer: {
        borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
        width: '20.2%',
        paddingTop: 8,
        bottom: 60,
        position: 'fixed',
    }
}));

export default function SideBarPacientes(props) {
    const classes = useStyles();

    const [idadeInicial, setIdadeInicial] = useState('')
    const [idadeFinal, setIdadeFinal] = useState('')
    const [pcd, setPcd] = useState('null')
    const [groupId, setGroupId] = useState('')
    const [unidadeId, setUnidadeId] = useState('')
    const [listUnidades, setListUnidades] = useState([]);
    const [listGrupos, setListGrupos] = useState([]);
    const [openGrupos, setOpenGrupos] = useState(false);
    const [openUnidadesVinculadas, setOpenUnidadesVinculadas] = useState(false);
    const [error, setError] = useState("");

    const { filter, setFilter } = usePatientFilter();

    useEffect(() => {
        if(!props.sideBarOpen){
            setOpenGrupos(false)
            setOpenUnidadesVinculadas(false);
        }
    }, [props.sideBarOpen])

    useEffect(() => {
        if(error)
            toast.error(error);
    }, [error])

    useEffect(function () {    
        getAllUnidades(localStorage.getItem("token")).then((res) => {
            if (res.length && res[0].id) {
                setListUnidades(res);
            }
        });

        getAllGroups(localStorage.getItem("token")).then((res) => {
            if (res.length && res[0].id ) {
                setListGrupos(res);
            }
        });
    }, []);

    function submitFilters() {
        // var jsonData = {
        //     min_age: idadeInicial,
        //     max_age: idadeFinal,
        //     pcd: pcd,
        //     group_id: groupId,
        //     medical_unit_id: unidadeId
        // }

        setFilter({ ...filter,
            grupo: groupId, 
            unidade: unidadeId, 
            idade: { de: idadeInicial, ate: idadeFinal },
            pcd,
            time: (new Date).getTime()
        })

        // await getPatientByFilter(localStorage.getItem('token'), jsonData, 'sidebar').then(res => {
        //     var pacientes = [];
    
        //     if((res.patient && res.patient.length === 0) || !res.status) {
        //         pacientes.push({
        //             id: '-', 
        //             full_name: '-', 
        //             cpf: '-', 
        //             cns: '-', 
        //             birth_date: '-', 
        //             pcd: '-', 
        //             group_description: '-'
        //         });
    
        //         props.filteredData(pacientes);
        //     } else {
        //         res.patient.length && res.patient.map((item) => {
        //             var pacienteObj = {};
    
        //             pacienteObj.id                  = item.id;
        //             pacienteObj.full_name           = item.full_name;
        //             pacienteObj.birth_date          = item.birth_date;
        //             pacienteObj.cns                 = item.cns;
        //             pacienteObj.cpf                 = item.cpf;
        //             pacienteObj.pcd                 = item.deficiencies;
        //             pacienteObj.group_description   = item.hasGroup;
    
        //             pacientes.push(pacienteObj);
        //         });

        //         props.filteredData(pacientes);
        //     }
        // });
    }

    const validarIdade = (inicial, final) => {
        if(final < inicial && final !== ""){
            setError("Idade final não pode ser maior que a idade inicial.");
        } else {
            setError("");
        }
    }

    return (
        <>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <Typography
                        className="text-mt text-regular text-primary mt-3"
                        variant="body1"
                    >
                        Grupos
                    </Typography>
                    <FormGroup>
                        <Select
                            open={openGrupos}
                            onOpen={e => setOpenGrupos(true)}
                            onClose={e => setOpenGrupos(false)}
                            onChange={ e => {
                                setGroupId(e.target.value)
                            }}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            {listGrupos.sort((a, b) => a.name > b.name ? 1 : -1).map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormGroup>


                    <Typography
                        className="text-mt text-regular text-primary mt-3"
                        variant="body1"
                    >
                        Unidades vinculadas ao paciente
                    </Typography>
                    <FormGroup>
                        <Select
                            open={openUnidadesVinculadas}
                            onOpen={e => setOpenUnidadesVinculadas(true)}
                            onClose={e => setOpenUnidadesVinculadas(false)}
                            onChange={ e => {
                                setUnidadeId(e.target.value)
                            }}
                        >
                            <MenuItem value="">Todos</MenuItem>
                            {listUnidades.map((item, index) => (
                                <MenuItem value={item.id} key={index}>{item.company_name}</MenuItem>
                            ))}
                        </Select>
                    </FormGroup>


                    <Typography
                        className="text-mt text-regular text-primary mt-3"
                        variant="body1"
                    >
                        Idade
                    </Typography>
                    <FormGroup>
                        <Grid container spacing={0} alignItems="center" style={{height: '100%'}}>
                            <Grid item xs={1} style={{marginRight: 10}}>
                                De
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="outlined-number"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    style={{backgroundColor: 'white'}}
                                    value={idadeInicial}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {

                                        if (e.target.value.length <= 3) {
                                            let valor = e.target.value.toString().replace(/[^0-9]/g, '');
                                            setIdadeInicial(valor);
                                            validarIdade(valor, idadeFinal)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} style={{marginLeft: 10}}>
                                a
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="outlined-number"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    style={{backgroundColor: 'white'}}
                                    value={idadeFinal}
                                    error={ error !== "" }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {
                                        if (e.target.value.length <= 3) {
                                            let valor = e.target.value.toString().replace(/[^0-9]/g, '');
                                            setIdadeFinal(valor);
                                            validarIdade(idadeInicial, valor)                                            
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} style={{marginLeft: 10}}>
                                anos
                            </Grid>
                        </Grid>
                    </FormGroup>


                    <Typography
                        className="text-mt text-regular text-primary mt-3"
                        variant="body1"
                    >
                        PCD
                    </Typography>
                    <FormGroup>
                        <RadioGroup
                            aria-label="pcd"
                            defaultValue="null"
                            name="radio-pcd"
                            onChange={ e => {
                                setPcd(e.target.defaultValue)
                            }}
                        >
                            <FormControlLabel
                                control={<Radio/>}
                                value="null"
                                label="Ambos"
                            />
                            <FormControlLabel
                                control={<Radio/>}
                                value="1"
                                label="Sim"
                            />
                            <FormControlLabel
                                control={<Radio/>}
                                value="0"
                                label="Não"
                            />
                        </RadioGroup>
                    </FormGroup>
                </FormControl>
            </div>

        
            <Grid container spacing={1} className="tema-light" justifyContent="center" alignItems="center" classes={{root: classes.footer}}>
                <Grid item>
                    <DButton
                        variant="primary"
                        type="submit"
                        id='btnFechar'
                        style={{paddingLeft: "40px !important"}}
                        icon={ <FilterAltIcon fontSize="small" /> }
                        onClick={ e => {
                            submitFilters();
                        }}
                    >
                        Aplicar Filtros
                    </DButton>
                </Grid>
            </Grid>
        </>
    );
}