import React, { Component } from "react";
import ProfissionalGrid from "./components/ProfissionalGrid";
import {filterProfessional, getAllUser} from "../../api/user";
import {
    Container,
    Grid,
} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import ProfissionalFilter from "./components/ProfissionalFilter/ProfissionalFilter";
import {Redirect} from "react-router-dom";
import {getMe} from "../../api/user";
import ProfissionalConvidar from "./components/ProfissionalConvidar";
import DRadio from "../shared/DRadio";
import {newInvite, testUploadInvite, uploadInvite} from "../../api/invite";
import { SideBarFilterProfessionalContext } from "../../context/useFilterProfessional";
import { toast } from "react-toastify";
import { getAllUserStatus } from "./../../api/user";
import searchPersonIcon from '../../assets/img-icon/userSearchIcon.svg';
import styled from 'styled-components';
import { useTheme, withStyles } from "@material-ui/core";

const SearchEmpty = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 60px;
`;



const styles = theme => ({
    primaryTextColor: {
        color: theme.palette.primary.main
    },
    primaryLightTextColor: {
        color: theme.palette.primary.light
    },
    primaryDarkTextColor: {
        color: theme.palette.primary.dark
    },
    addTagButton: {
        color: theme.palette.primary.light,
        border: `1px ${theme.palette.primary.light} solid`
    },
    historico__topo: {
        position: "absolute",
        left: 0,
        top: 88,
        width: "100%",
        height: 61,
        display: "flex",
        padding: "10px 40px",
        background: theme.palette.background.grass,
        boxShadow: "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 18,
        zIndex: 1,
    },
    historico__tabs: {
        marginTop: 93
    },
    historico__nome: {
        height: 20,
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        color: "#000",
        fontWeight: 600
    },
    icon: {
        fontSize: 15
    },
});


class Profissional extends Component {

    static contextTypes = SideBarFilterProfessionalContext;

    constructor(props) {
        super(props);

        this.state = {
            convidar: false,
            enviandoConvite: false,
            conviteEnviado: false,
            enviandoConviteEmMassa: false,
            uploadEmMassaEnviado: false,
            arquivoComErro: false,
            login: true,
            arquivoInvalido: false,
            filtro: null,
            page: 1, 
            filterProfessional: [],
            pagination: null,
            activeFilter: null,
            professionalFilterResult: [{status: false, name: false, conselho: [], cpf: false, perfis: [], pagination: null}],
        }
    }

    handleGetProfessinal(page = 1, filter = undefined){
        const {profissionais, setProfissionais, activeFilter, profissionaisPaginate, setProfissionaisPaginate, all, waiting, released, blocked} = this.context;
        let query = filter ? { ...filter, page } : {...activeFilter, active: [all || waiting, released, blocked], page}
        filterProfessional(localStorage.getItem("token"), {...query}).then((res) => {
            if(res.status || !res.error) {
                setProfissionais(res.users);
                setProfissionaisPaginate(res.paginate);
                this.setState({ ...this.state, professionalFilterResult: res.users });
            } else {
                setProfissionais([{status: false, name: false, conselho: [], cpf: false, perfis: [], pagination: null}]);
            }
        }).catch(err => {
            console.error(err);
        })
    }

    componentDidMount() {
        getMe(localStorage.getItem('token')).then(res => {
            if(res.error)
                this.setState({ login: false });            
        });
        
        this.handleGetProfessinal()
    }


    enviarConvite(dados) {
        
        dados.workload = (new Date(dados.workload)).toLocaleTimeString();

        const {profissionais, setProfissionais, setTotaisStatus} = this.context;

        this.setState({ ...this.state, enviandoConvite: true });

        newInvite(localStorage.getItem('token'), dados).then(res => {

            if(res.status === true) {
                getAllUser(localStorage.getItem('token'), 1).then(res => {
                    this.setState({ ...this.state, conviteEnviado: true, enviandoConvite: false })
                    setProfissionais(res.users);
                })
                .catch(console.error)
                getAllUserStatus(localStorage.getItem("token")).then((res) => {
                    setTotaisStatus(res.total);
                });
            } else {
                let data = res.hasOwnProperty('details') && res.details;
                dados.setErrors({...data});

                this.setState({ ...this.state, enviandoConvite: false, conviteEnviado: false });
            }
        });
    }

    testUploadEmMassa(dados) {
        const {profissionais, setProfissionais} = this.context;

        this.setState({ ...this.state, enviandoConviteEmMassa: true })

        testUploadInvite(localStorage.getItem('token'), dados.file)
            .then(res => {
                if(!res.status) {
                    this.setState({ ...this.state, arquivoComErro: res, enviandoConviteEmMassa: false })
                } else {
                    uploadInvite(localStorage.getItem('token'), dados.file).then(res => {

                        if(!res.status) {
                            this.setState({ ...this.state, enviandoConviteEmMassa: false })
                            toast.error("Seu arquivo não foi enviado, verifique o modelo e layout utilizado.");
                        } else {
                            getAllUser(localStorage.getItem('token'), 1).then(res => {
                                this.setState({ ...this.state, enviandoConviteEmMassa: false, uploadEmMassaEnviado: true, arquivoComErro: false })
                                setProfissionais(res.users);
                            });
                        }
                    });
                }
            })
            .catch(error => {this.setState({ ...this.state, arquivoInvalido: true, arquivoComErro: { errors: [ {line: 0, column: 0, message: 'Arquivo inválido'} ] }, enviandoConviteEmMassa: false } ) });
    }


    uploadEmMassa(dados) {
        const {profissionais, setProfissionais} = this.context;

        this.setState({ ...this.state, enviandoConviteEmMassa: true })

        uploadInvite(localStorage.getItem('token'), dados.file).then(res => {

            if(!res.status) {
                this.setState({ ...this.state, enviandoConviteEmMassa: false })
                toast.error("Seu arquivo não foi enviado, verifique o modelo e layout utilizado.");
            } else {
                getAllUser(localStorage.getItem('token'), 1).then(res => {
                    this.setState({ ...this.state, enviandoConviteEmMassa: false, uploadEmMassaEnviado: true, arquivoComErro: false })
                    setProfissionais(res.users);
                });
            }
        });
    }

    render() {
        const {profissionais, activeFilter, setActiveFilter, profissionaisPaginate} = this.context;
        const { classes } = this.props;
        return (
            <Container className="tema-light">
                { !this.state.login ? <Redirect to="/" /> : '' }                

                <Grid style={{ paddingTop: 90 }}>
                    <Grid item className={classes.historico__topo}>
                        <Grid container>
                            <Grid item md={12} style={{ display: "flex", alignItems: "center" }}>                                
                                <Grid item md={2}><h3>Profissionais</h3></Grid>
                                <Grid item md={10}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item md={8}>
                                            <ProfissionalFilter 
                                                setPagination={e => console.log('setPagination')}
                                                setActiveFilter={e => {
                                                    let filter = { ...activeFilter, ...e };
                                                    setActiveFilter(filter)
                                                    this.handleGetProfessinal(1, filter)
                                                }}/>
                                        </Grid>
                                        <Grid item md={4}>
                                            <Grid container justifyContent="flex-end">
                                                <Grid item>
                                                    <Button
                                                        variant="outlined"
                                                        size="md" 
                                                        startIcon={ <MailIcon fontSize="small" /> } 
                                                        className={classes.addTagButton}
                                                        onClick={ e => this.setState({ ...this.state, convidar: true, arquivoComErro: false,  uploadEmMassaEnviado: false }) } 
                                                    >
                                                        Convidar Profissionais
                                                    </Button>                                                    
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                
                { 
                    this.state.professionalFilterResult.length === 0 ?
                    <SearchEmpty>
                        <img src={searchPersonIcon} alt="Ícone nenhum resultado encontrado" />
                        <p>Nenhum resultado encontrado.</p>
                    </SearchEmpty> : 
                    <ProfissionalGrid 
                        pagination={ profissionaisPaginate }
                        onPage={page => this.handleGetProfessinal(page)} 
                    />


                }

                {
                    this.state.convidar ? <ProfissionalConvidar
                    arquivoInvalido={ this.state.arquivoInvalido }
                    uploadEmMassaEnviado={ this.state.uploadEmMassaEnviado }
                    onCancelarEnvio={ e => this.setState({ ...this.state, arquivoComErro: false }) }
                    arquivoComErro={ this.state.arquivoComErro }
                    testUploadEmMassa={ this.testUploadEmMassa.bind(this) }
                    uploadEmMassa={ this.uploadEmMassa.bind(this) }
                    enviarConvite={ this.enviarConvite.bind(this) }
                    open={ this.state.convidar }
                    enviado={ this.state.conviteEnviado }
                    loading={ this.state.enviandoConvite || this.state.enviandoConviteEmMassa }
                    onNew={ e => this.setState({ ...this.state, conviteEnviado: false }) }
                    onClose={ e => this.setState({...this.state, convidar: false, conviteEnviado: false, uploadEmMassaEnviado: false}) } /> : ""
                }
            </Container>
        )
    }
}

Profissional.contextType = SideBarFilterProfessionalContext

export default withStyles(styles, {withTheme: true})(Profissional)
// export default Profissional;