import React from "react";
import {Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { getClasses, getSize, getvariant } from "../shared.helper";


const useStyles = makeStyles((theme) => ({
    primaryLight: {
        backgroundColor: theme.palette.primary.light,
        color: "white",

        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "white"
        }
    },
    primaryDark: {
        backgroundColor: theme.palette.primary.dark,

        color: "white",

        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: "white"
        }
    },
}));

function DButton(props) {

    const classes = useStyles()

    const spanStyle = props.spanStyle ? props.spanStyle : { margin: "0 8px" };

    return (
        <Button
            style={props.style}
            classes={props.classes}
            id={props.id}
            type={props.type ? props.type : 'button'}
            onClick={props.onClick}
            className={`${getvariant(props.variant) === 'primary' || "secondary" ? (props.variant === 'primary' ? classes.primaryLight : classes.primaryDark) : getvariant(props.variant)} ${getClasses(props.className, props.disabled)} ${props.multiClass} ${getSize('button', props.size)} ${ props.active ? 'btn-active' : '' } `}
            disabled={props.disabled ?? false}>
                
            { props.icon ? props.icon : "" }
            <span className="text-icon" style={spanStyle}>{props.children}</span>
            { props.endIcon ? props.endIcon : "" }
        </Button>
    )
}

export default DButton;