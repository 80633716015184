import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    box: {
      position: 'absolute',
      marginTop: "-58px",
      marginLeft: "-47px",
      background: '#F4F6F8',
      width: 100,
      padding: 8,
      borderTopLeftRadius: 5,
      borderBottomRightRadius: 5,
      boxShadow: "0px 1px 5px rgb(0 0 0 / 10%)"
    }
});

function Version(props)
{

    const classes = useStyles();

    return <Grid container classes={{root: classes.box}}>
        <Grid item>
            <Typography className="text-regular text-st">Versão: <strong>{ props.version }</strong></Typography>
        </Grid>
    </Grid>
}

Version.defaultProps = {
    version: 1
}

export default Version;