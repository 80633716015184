import { useState } from "react";
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { toast } from "react-toastify";
import { change } from "./../../../../api/recoverpassword";
import useError from "./../../../../hooks/useError";
import { required } from "../../../../helpers/validacoes";
import { DefaultButton } from "./../../../shared/ButtonDefault/index.jsx";
import PasswordInstrucions from "./components/PasswordInstrucions";
import DInput from "./../../../shared/DInput";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmCustom from "./../../../shared/ConfirmCustom";
import { makeStyles } from "@material-ui/core/styles";
import {
    Visibility,
    VisibilityOff
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: "#4F4F4F"
    },
    iconColorConfirm: {
        color: "#1DE8B5"
    },
    iconConfirm: {
        fontSize: 33
    }
}));

function FormRecoverPasswordChange(props)
{

    const classes = useStyles();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");    
    const [visibility1, setVisibility1] = useState(false);
    const [visibility2, setVisibility2] = useState(false);
    const [checkMinCaractere, setChekMinCaractere] = useState(false);;
    const [checkCaixa, setCheckCaixa] = useState(false);
    const [checkNumero, setCheckNumero] = useState(false);
    const [checkCaractere, setCheckCaractere] = useState(false)
    const [formValid, setFormValid] = useState(false);
    const [senhasIguais, setSenhasIguais] = useState(true);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);

    const [error, validarFormulario, setErrors] = useError({
        password: required,
        confirmPassword: required
    })

    const checkSenhasIguais = (_senha, _confirmSenha) => {

        if (_senha != "" && _confirmSenha != "") {

            if (_senha !== _confirmSenha) {
                setSenhasIguais(false);
                setErrors({...error, confirm_senha: "Senhas diferentes."});
            } else {
                setSenhasIguais(true);
                delete error.confirm_senha;
            }

        } else {
            setSenhasIguais(true);
        }

    }

    const validaSenhaForte = (value) => {

        if(value.length >= 8)
            setChekMinCaractere(true)
        else
            setChekMinCaractere(false)

        if(value.toString().replace(/[^0-9]/g, '') !== "")
            setCheckNumero(true);
        else
            setCheckNumero(false);

        if(value.toString().replace(/[0-9a-zA-Z]/g, '') !== "")
            setCheckCaractere(true);
        else
            setCheckCaractere(false);

        if(value.toString().replace(/[^a-z]/g, '') !== "" && value.toString().replace(/[^A-Z]/g, '') !== "")
            setCheckCaixa(true)
        else
            setCheckCaixa(false)
    }

    const handlePasswordChange = () => {

        setLoading(true);

        if(formValid) {
            change(localStorage.getItem('token_recoverpassword'), password).then(res => {
                if(res.status) {
                    toast.success("Senha atualizada com sucesso.");        
                    setConfirm(true);
                } else {
                    toast.error(res.message);
                }

                setLoading(false);
            })
        } else {
            toast.warn("Preencha todas as regras de senha forte.");
        }
    }

    return (
        <>
            <form onSubmit={form => {

                form.preventDefault();

                if(validarFormulario({password, confirmPassword}))
                    handlePasswordChange();
                else
                    toast.warn("Preencha todos os campos obrigatórios.");
            }}>
                <Grid container justifyContent="center" style={{maxWidth: 492}}>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className="text-regular text-mt">Por favor, insira sua nova senha:</Typography>
                            </Grid>
                            <Grid item>
                                <DInput
                                    disabled={ loading }
                                    labelColor="#4F4F4F"
                                    label="Nova a senha"
                                    placeholder="Senha"
                                    type={ visibility1 ? 'text' : 'password' }
                                    positionIcon="end"
                                    icon={ visibility1 ? <Visibility onClick={ e => setVisibility1(false) } /> : <VisibilityOff onClick={ e => setVisibility1(true) } /> }
                                    inputProps={{maxLength: 254}}
                                    error={ error.password ? true : false }
                                    helperText={ error.password ? error.password : "" }
                                    fullWidth
                                    onChange={ e => {
                                        
                                        delete error.password;
                                        validaSenhaForte(e.target.value);
                                        setPassword(e.target.value);
                                        if (confirmPassword != "")
                                            checkSenhasIguais(e.target.value, confirmPassword)
                                    } }
                                    value={ password } />
                            </Grid>
                            <Grid item>
                                <PasswordInstrucions
                                    checkCaractere={ checkCaractere }
                                    checkMinCaractere={ checkMinCaractere }
                                    checkCaixa={ checkCaixa }
                                    checkNumero={ checkNumero }
                                    senhasIguais={ senhasIguais }
                                />
                            </Grid>
                            <Grid item>
                                <DInput
                                    disabled={ loading }
                                    labelColor="#4F4F4F"
                                    label="Confirmação de senha"
                                    placeholder="senha"
                                    type={ visibility2 ? 'text' : 'password' }
                                    positionIcon="end"
                                    icon={ visibility2 ? <Visibility onClick={ e => setVisibility2(false) } /> : <VisibilityOff onClick={ e => setVisibility2(true) } /> }                                
                                    inputProps={{maxLength: 254}}
                                    error={ error.confirmPassword ? true : false }
                                    helperText={ error.confirmPassword ? error.confirmPassword : "" }
                                    fullWidth
                                    onChange={ e => {
                                        
                                        delete error.confirmPassword;

                                        if(checkCaractere && checkCaixa && checkNumero && checkMinCaractere && (e.target.value) === password)
                                            setFormValid(true);
                                        else
                                            setFormValid(false);

                                        setConfirmPassword(e.target.value);
                                        checkSenhasIguais(password, e.target.value)
                                    } }
                                    value={ confirmPassword } />
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <DefaultButton disabled={ loading } onClick={ e => props.onBack() } type="button" variant="outlined" color="primary">Voltar</DefaultButton>
                                    </Grid>
                                    <Grid item>
                                        <DefaultButton disabled={ loading || !formValid } type="submit" variant="text" color="primary">
                                            { loading ? <CircularProgress size={15} /> : 'Avançar' }                                        
                                        </DefaultButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            { confirm && <ConfirmCustom open={ true } onClose={ e => {
                props.onCode();
                setConfirm(false);
            } }>
                <Grid container direction="column" alignItems="center" style={{ marginTop: 0, marginBottom: 48 }}>
                    <Grid item>
                        <CheckCircleIcon fontSize="small" color="primary"
                            classes={{
                                colorPrimary: classes.iconColorConfirm,
                                fontSizeSmall: classes.iconConfirm
                            }} 
                        />
                    </Grid>
                    <Grid item style={{marginTop: 18}}>
                        <Typography classes={{root: classes.textColor}} variant="body1" className="text-bold text-el">Senha</Typography>
                    </Grid>
                    <Grid item style={{marginTop: 24}}>
                        <Typography classes={{root: classes.textColor}} variant="body1" className="text-regular text-mt">Sua senha foi redefinida com sucesso</Typography>
                    </Grid>
                </Grid>
            </ConfirmCustom> }            
        </>
    )
}

FormRecoverPasswordChange.defaultProps = {
    onCode: function() {
        console.log('onCode');
    },
    onBack: function() {
        console.log('onBack');
    }
}

export default FormRecoverPasswordChange;