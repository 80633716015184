import { TextField } from "@material-ui/core";

function Drugs(props)
{
    function getType()
    {

        if (typeof props.preForm !== 'undefined') {
            if (props.preForm.drugs && props.preForm.drugs == 'Sim') {

                const drugs = props.preForm && props.preForm.anamnese_pre_form_drugs && props.preForm.anamnese_pre_form_drugs.length > 0 ? props.preForm.anamnese_pre_form_drugs.filter(drugs => drugs.drug_type).map(res => `${res.drug_type} - ${res.drug_use}`) : [];

                return `Sim (${drugs.join(', ')})`;
            } else {
                return 'Não';
            }
        }
    }

    // return <Typography variant="body1" className="text-regular text-st">{ getType() }</Typography>
    return <TextField className='input_new_solicitante_new_tab' id="standard-basic" disabled value={ getType() } label="Uso de drogas ilícitas" variant="standard" />
}

export default Drugs;