import { useState, useEffect } from "react";

import { Box, Grid, Button,
    Checkbox,
    FormControlLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { toast } from "react-toastify";
import { format } from 'date-fns'

import DInput from "../../../../../../shared/DInput";
import DDateInput from '../../../../../../shared/DDateInput';
import DAutoComplete from "../../../../../../shared/DAutoComplete";

import useError from "./../../../../../../../hooks/useError";
import { required, requiredOptionalTwoName, validaRG } from "./../../../../../../../helpers/validacoes";
import { mascaraRG } from "./../../../../../../../helpers/formatacao";
import { updateDocument, getPatientById, updatePatient } from "./../../../../../../../api/patient";

import orgaoEmissorCode from "./../../../../../../../assets/dados/orgaoEmissor.json";

const useStyles = makeStyles((theme) => ({
    dados_titulo: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
        color: theme.palette.primary.light,
        margin: 0
    },
    dados_editButton: {
        color: theme.palette.primary.light,
        textTransform:'none',
        fontWeight: 400
    },
    dados_saveButton: {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: 400
    }
}));

function IdentidadeBrasileiro(props)
{
    const classes = useStyles();

    const [editar, setEditar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState(props.patient);

    useEffect(() => {
        getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
            if(res.status) {
                setPatient(res.details.patient);
            }
        }).catch(console.error)
    }, []);

    const states = props.dominios.state.map((item) => {
        return { id: item.id, value: item.code };
    });

    const orgaoEmissorMap = orgaoEmissorCode.map(function(item){
        return { id: item.name, value: item.name }
    });

    function formatDate(date) {
        if(date && date != '') {
            var day   = date.substring(0,2);
            var month = date.substring(3,5);
            var year  = date.substring(6);
            
            return [year, month, day].join('-');
        } else {
            return ''
        }
    }

    const renderUfPatient = rg => {
        if(rg && rg.value && rg.value.uf) {
            if(rg.value.uf.value === null) {
                return '-'
            } else {
                return rg.value.uf.value.code
            }
        }
    }

    const View = (props) => {
        return (
            <>
                { props.patient.rg &&                
                    <Grid container>
                        <Grid item md={6}>
                            <h5 className={ classes.dados_titulo }>Identidade</h5>
                        </Grid>
                        <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                            <Button variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton } onClick={e => setEditar(true) }>
                                <EditIcon fontSize="small" />
                                <Box ml={1}>Editar</Box>
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <strong>Número de identidade:</strong> { props.patient.rg.value.numero.value }
                        </Grid>
                        <Grid item md={6}>
                            <strong>Data de emissão:</strong> { props.patient.rg.value.data_emissao.value.view }
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Órgão emissor:</strong> { props.patient.rg.value.orgao_emissor.value }
                        </Grid>
                        <Grid item xs={6}>
                            <strong>UF:</strong> { renderUfPatient(props.patient.rg) }
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Nome da mãe:</strong> { props.patient.mother_know ? (props.patient.mother_name || null) : "Desconhecida" }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const Edit = (props) => {

        const [identidadeNumero, setIdentidadeNumero]                 = useState("");
        const [identidadeEmissao, setIdentidadeEmissao]               = useState("");
        const [identidadeOrgaoEmissor, setIdentidadeOrgaoEmissor]     = useState(null);
        const [identidadeUF, setIdentidadeUF]                         = useState(props.patient.rg.value.uf.value ? props.patient.rg.value.uf.value.id : null);
        const [nomeMae, setNomeMae]                                   = useState("");
        const [identidadeEmissaoValida, setIdentidadeEmissaoValida]   = useState(true);
        const [maeDesconhecida, setMaeDesconhecida]                   = useState(props.patient.mother_know === 0 ? true : false);

        const validateDate = {
            dataEmissaoIdentidade: () => {
                if (identidadeEmissaoValida && identidadeEmissao != null) {
                    try {
                        var [day, month, year] = identidadeEmissao.split("/");
                    
                        var _identidadeEmissao = new Date(`${year}-${month}-${day} 00:00:00`);

                        if(_identidadeEmissao > new Date()) {
                            return "Data não pode ser maior que a data atual."
                        }

                        if(props.birth_date) {
                            if(_identidadeEmissao < new Date(props.birth_date)){
                                return "Data não pode ser menor que a data de nascimento."

                            }
                        }
        
                        if (day && month && year) {
                            return undefined;
                        } else {
                            return "Data inválida.";
                        }
                    } catch(e){
                        console.log(e);

                        return undefined;
                    }
                } else {
                    return "Data inválida.";
                }
            }
        }

        const [error, validarFormulario, setErrors] = useError({
            identidadeNumero: validaRG,
            identidadeEmissao: validateDate.dataEmissaoIdentidade,
            identidadeOrgaoEmissor: required,
            identidadeUF: required,
            nomeMae: requiredOptionalTwoName
        })

        useEffect(() => {
            if(props.patient.rg) {
                setIdentidadeNumero(props.patient.rg.value.numero.value)
                setIdentidadeOrgaoEmissor(props.patient.rg.value.orgao_emissor.value)
                setIdentidadeUF(props.patient.rg.value.uf.value ? props.patient.rg.value.uf.value.id : null)
                setIdentidadeEmissao(props.patient.rg.value.data_emissao.value.view)
                setNomeMae(props.patient.mother_name)        
                setMaeDesconhecida(!props.patient.mother_know)        
            }
        }, [])

        return (
            <form onSubmit={ form => {
                form.preventDefault();
                if(validarFormulario({
                    identidadeNumero,
                    identidadeEmissao,
                    identidadeOrgaoEmissor,
                    identidadeUF,
                    nomeMae
                })){

                    const fields = [
                        {
                            id: props.patient.rg.value.numero.id,
                            value: identidadeNumero
                        },
                        {
                            id: props.patient.rg.value.orgao_emissor.id,
                            value: identidadeOrgaoEmissor
                        },
                        {
                            id: props.patient.rg.value.uf.id,
                            value: identidadeUF
                        },
                        {
                            id: props.patient.rg.value.data_emissao.id,
                            value: formatDate(identidadeEmissao)
                        }
                    ];

                    getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                        if(res.status) {
                            var _patient = res.details.patient;

                            if(_patient && typeof _patient === 'object') {

                                _patient.mother_know = maeDesconhecida ? false : true;

                                updatePatient(localStorage.getItem("token"), {..._patient}, props.patient.id)
                                    .then(() => {
                                        updateDocument(localStorage.getItem('token'), { mother_name: nomeMae ? nomeMae : null, fields }, props.patient.rg.id).then(res => {

                                            setLoading(true);
                                            if(res.status) {
                                                toast.success('Identidade atualizada com sucesso.')
                                                getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                                                    if(res.status) {
                                                        setPatient(res.details.patient);
                                                    }
                                                }).catch(console.error)
                                                setEditar(false);
                                            }
                                            setLoading(false);
                                        }).catch(err => {
                                            console.log(err);
                                            setLoading(false);
                                        })
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        setLoading(false);
                                    })
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                        setLoading(false);
                    })
                    
                } else {
                    toast.warn("Preencha todos os campos corretamente.")
                }
            } }>
                <Grid container>
                    <Grid item md={6}>
                        <h5 className={ classes.dados_titulo }>Identidade</h5>
                    </Grid>
                    <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                        <Button type="submit" variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton }>
                            <SaveIcon fontSize="small" />
                            <Box ml={1}>{ loading ? <CircularProgress size={ 15 } color="inherit" /> : 'Salvar' }</Box>
                        </Button>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Número de identidade:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <DInput
                                    id="NumeroIdentidade"
                                    placeholder="Número de identidade"
                                    style={{ marginTop: 5 }}
                                    type="text"
                                    error={ error.identidadeNumero }
                                    helperText={ error.identidadeNumero }
                                    fullWidth={true}
                                    value={ identidadeNumero }
                                    onChange={ e => {
                                        delete error.identidadeNumero;
                                        setIdentidadeNumero(mascaraRG(e.target.value))
                                    } }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Data de emissão:</strong> 
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <DDateInput 
                                    id="identidade-emissao"
                                    error={ error.identidadeEmissao }
                                    helperText={ error.identidadeEmissao }
                                    fullWidth
                                    className='datePicker'
                                    label=""
                                    maxDate={ new Date() }
                                    maxDateMessage="A data de emissão não pode ser maior que a data atual"
                                    value={ identidadeEmissao }
                                    onError={(errorMessage) => {
                                        setErrors({ ...error, identidadeEmissao: errorMessage })
                                    }}
                                    onChange={(arg1, arg2, e) => {
                                        delete error.identidadeEmissao
                                    
                                        setIdentidadeEmissao(arg1)
                                        setIdentidadeEmissaoValida(arg2.isValid);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Órgão emissor:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <div className='input-info'>
                                    { identidadeOrgaoEmissor !== null &&
                                        <DAutoComplete
                                            id="orgao-emissor"
                                            placeholder="Órgão emissor"
                                            labelColor='black'
                                            fullWidth
                                            error={ error.identidadeOrgaoEmissor }
                                            helperText={ error.identidadeOrgaoEmissor }
                                            defaultValue={ orgaoEmissorMap.filter( oe => oe.value == identidadeOrgaoEmissor )[0] }
                                            options={orgaoEmissorMap}
                                            getOptionLabel={(option) => option.value}
                                            getOptionSelected={(option, value) => value && option.id === value.id}
                                            onChange={(event, newValue) => {
                                                delete error.identidadeOrgaoEmissor;
                                                setIdentidadeOrgaoEmissor(newValue ? newValue.id : "");
                                            }}
                                        />
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>UF:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <div className='input-info'>
                                        <DAutoComplete
                                            id="identidade-uf"
                                            placeholder="UF"
                                            labelColor='black'
                                            fullWidth
                                            error={ error.identidadeUF }
                                            helperText={ error.identidadeUF }
                                            defaultValue={ states.filter( oe => oe.id == identidadeUF )[0] }
                                            options={states}
                                            getOptionLabel={(option) => option.value}
                                            getOptionSelected={(option, value) => value && option.id === value.id}
                                            onChange={(event, newValue) => {
                                                delete error.identidadeUF;
                                                setIdentidadeUF(newValue ? newValue.id : "");
                                            }}
                                        />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Nome da mãe:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <DInput
                                    id="NomeMae"
                                    placeholder="Nome da mãe"
                                    disabled={maeDesconhecida}
                                    style={{ marginTop: 5 }}
                                    type="text"
                                    error={ error.nomeMae }
                                    helperText={ error.nomeMae }
                                    fullWidth
                                    onChange={ e => {
                                        delete error.nomeMae;
                                        setNomeMae(e.target.value);
                                    } }
                                    value={ nomeMae }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Mãe desconhecida</strong>
                            </Grid>
                            <FormControlLabel
                        control={
                            <Checkbox
                            defaultChecked={ maeDesconhecida }
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={ e => {
                                setMaeDesconhecida(e.target.checked);

                                if(!maeDesconhecida) {
                                    setNomeMae('');
                                    delete error.nomeMae
                                }
                            }}
                            />
                        }
                        label=""
                        />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <>
            { patient ?
                <>
                    { editar ? <Edit patient={ patient } birth_date={props.birth_date} /> : <View patient={ patient } /> }
                </>
                :
                ''
            }
        </>
    )
}

export default IdentidadeBrasileiro;