import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Menu,
    MenuItem,
    OutlinedInput,
    InputAdornment, FormControl, Checkbox, ListItemText
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({
    card: {
        background: '#F4F4F4'
    },
    cardTitle: {
        marginBottom: 12
    },
    cardBody: {
        padding: 15,
        paddingBottom: '15px !important'
    },
    tableContainer: {
        boxShadow: 'none'
    },
    container: {
        display: 'none'
    },
    containerOpen: {
        display: 'block'
    },
    table: {
        minWidth: 650
    },
    icon: {
        color: theme.palette.primary.light,
    },
    headerText: {
        fontSize: '16px !important',
        color: '#272727 !important',
        fontWeight: "500 !important",
        lineHeight: "24px !important"
    },
    actionButton: {
        fontWeight: "400 !important"
    }
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
        borderBottom: "1px solid rgb(114 114 114)"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,
        borderBottom: "1px solid rgb(114 114 114)"
    }
}))(TableRow);

const StyledButton = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: 14,
        padding: 0,
        minWidth: 'unset',
        fontWeight: "400 !important"
    }
}))(Button);

const StyledButtonAction = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: 14,
        color: theme.palette.primary.light,
        fontWeight: "400 !important",
    }
}))(Button);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledTitle = withStyles((theme) => ({
    colorPrimary: {
        color: theme.palette.primary.light,
    }
}))(props => (
    <>
        { props.order === 'asc' ? <KeyboardArrowUpIcon color="primary" /> : '' }
        { props.order === 'desc' ? <KeyboardArrowDownIcon color="primary" /> : '' }
    </>
))

const StyledOutlinedInput = withStyles((theme) => ({
    root: {
        background: '#FFF',
        minWidth: 400,
        marginBottom: 30
    }
}))(OutlinedInput)

function sortByCompanyName(unidades, order) {

    var array = [...unidades];

    if(order.name === 'asc') {
        array = array.sort(function(a , b) {
            return a.company_name - b.company_name;
        })
    }else if(order.name === 'desc') {
        array = array.reverse(function(a , b) {
            return a.company_name - b.company_name;
        })
    }

    if(!order.executante && !order.solicitante){

        return  array;
    }

    if(order.executante || order.solicitante) {

        array = array.filter(item => {

            var valido = false;

            if(order.executante && !valido)
                valido = item.funcao === 'Executante' || item.funcao === 'Ambos';

            if(order.solicitante && !valido)
                valido = item.funcao === 'Solicitante' || item.funcao === 'Ambos';

            return valido;
        });
    }



    return array;
}

function filterGrid(unidades, search) {

    if(search.length < 1)
        return unidades;

    var array = [...unidades];

    const regex = new RegExp(search, 'i');

    return array.filter(item => item.company_name.match(regex) || item.cnes.match(regex));
}


function UnidadesAssitenciais(props) {

    const classes = useStyles();
    const [unidades, setUnidades] = useState(props.unidades);
    const [orderName, setOrderName] = useState(null)
    const [orderFunction, setOrderFunction] = useState(null)
    const [order, setOrder] = useState({ name: null, executante: false, solicitante: false })
    const [search, setSearch] = useState(false);
    const [open, setOpen] = useState(false);
    
    useEffect(() => setUnidades(props.unidades), [props.unidades]);

    useEffect(function(){

        setUnidades(sortByCompanyName(props.unidades, order))

        if(search)
            setUnidades(filterGrid(props.unidades, search))

    }, [order, search])

    function handleUnidade(id){
        props.unidade(id)
    }

    return (
        <Card classes={{root: classes.card}}>
            <CardContent classes={{root: classes.cardBody}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography className={`${classes.headerText} text-primary`}>Unidades assistenciais</Typography>
                    </Grid>
                    <Grid item>
                        { open
                            ?
                            <StyledButtonAction onClick={ e => setOpen(false) } className={classes.handleActionButton}>
                                Fechar <KeyboardArrowDownIcon style={{paddingLeft: 16}} color="primary" />
                            </StyledButtonAction>
                            :
                            <StyledButtonAction onClick={ e => setOpen(true) } className={classes.handleActionButton}>
                                Abrir <KeyboardArrowUpIcon style={{paddingLeft: 16}} color="primary" />
                            </StyledButtonAction>
                        }
                    </Grid>
                </Grid>
                <Grid container classes={{ root: open ? classes.containerOpen : classes.container }}>
                    <Grid item md={12}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <FormControl className={clsx(classes.textField)} variant="outlined" size="small" autoComplete="off">
                                    <StyledOutlinedInput
                                        id="filter-unidades-administrativas"
                                        type="text"
                                        placeholder="Buscar por nome ou CNES"
                                        onChange={e => setSearch(e.target.value) }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconButton edge="start">
                                                    <SearchIcon classes={{ root: classes.icon }} color="primary" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={0}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="Unidades administrativas table">
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell align="left">
                                            <StyledButton onClick={ event => setOrderName(event.currentTarget) }>
                                                Nome
                                                <StyledTitle order={ order.name || "desc" } />
                                            </StyledButton>
                                            <StyledMenu
                                                id="order-nome-fantasia"
                                                anchorEl={orderName}
                                                keepMounted
                                                open={Boolean(orderName)}
                                                onClose={ event => setOrderName(null) }>
                                                <MenuItem onClick={event => {
                                                    setOrderName(null)
                                                    setOrder({ ...order, name: 'asc' })
                                                }}>A &lt; Z</MenuItem>
                                                <MenuItem onClick={event => {
                                                    setOrderName(null)
                                                    setOrder({ ...order, name: 'desc' })
                                                }}>Z &gt; A</MenuItem>
                                            </StyledMenu>
                                        </TableCell>
                                        <TableCell align="left">
                                            <StyledButton onClick={ event => setOrderFunction(event.currentTarget) }>
                                                Função
                                                <StyledTitle order={ orderFunction ? 'desc' : order.executante || order.solicitante ? 'asc' : 'desc' } />
                                            </StyledButton>
                                            <StyledMenu
                                                id="order-nome-fantasia"
                                                anchorEl={orderFunction}
                                                keepMounted
                                                open={Boolean(orderFunction)}
                                                onClose={ event => setOrderFunction(null) }>
                                                <MenuItem onClick={ e => {
                                                    setOrder( { ...order, executante: !order.executante } )
                                                }}>
                                                    <Checkbox checked={ order.executante } />
                                                    <ListItemText primary="Executante" />
                                                </MenuItem>
                                                <MenuItem onClick={ e => {
                                                    setOrder( { ...order, solicitante: !order.solicitante } )
                                                }}>
                                                    <Checkbox checked={ order.solicitante } />  
                                                    <ListItemText primary="Solicitante" />
                                                </MenuItem>
                                            </StyledMenu>
                                        </TableCell>
                                        <TableCell className={classes.actionButton} align="left">CNES</TableCell>
                                        <TableCell className={classes.actionButton} align="left">Telefone</TableCell>
                                        <TableCell className={classes.actionButton} align="left">Vinculada à</TableCell>
                                        <TableCell align="left">&nbsp;</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { unidades.map(function(item, index){
                                        return <StyledTableRow  key={ `unidades-administrativas-${ index }` }>
                                            <StyledTableCell  component="th" scope="row">{ item.company_name }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.funcao }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.cnes }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.phone_number }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.below_mother_medical_unit === 'yes' ? item.mother_medical_unit?.company_name : '-' }</StyledTableCell>
                                            <StyledTableCell  align="left">
                                                <StyledButtonAction onClick={ e => handleUnidade(item.id) }>
                                                    <OpenInNewIcon fontSize="small" />&nbsp;Mais dados
                                                </StyledButtonAction>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UnidadesAssitenciais;