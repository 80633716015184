import React, {useEffect, useState} from 'react';
import { Container, Typography, Grid, Card, CardContent } from  "@material-ui/core";
import DAutoCompleteCreate from "../shared/DAutoCompleteCreate/DAutoCompleteCreate";

function Autocomplete(props) {

    const style = {
        light: {
            background: '#FFF',
            padding: '12px'
        },
        dark: {
            background: '#272727',
            padding: '12px'
        },
        card: {
            background: 'transparent',
            padding: 15
        },
        titleDark: {
            color: '#FFF'
        }
    }

    const [especialidades, setEspecialidades] = useState([
        {id: 1, value: "Pediatra"},
        {id: 2, value: "Clinico geral"},
        {id: 3, value: "Cirurgiao"}
    ]);

    const [especialidade, setEspecialidade] = useState([]);

    return (
        <>

            <Container style={ style.light }>
                <Typography variant="h3">Inputs (Light)</Typography>
                <Grid container spacing={ 3 } className="tema-light">
                    <Grid item xs={4}>
                        <Card style={ style.card }>
                            <DAutoCompleteCreate
                                id="especialidades"
                                label="Especialidades"
                                placeholder="Especialidades"
                                // error={ true }
                                // helperText={ "Erro teste" }
                                value={ especialidade }
                                options={ especialidades }
                                onNewChange={ values => {
                                    setEspecialidade(values)
                                } }
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Autocomplete;