import { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Error from "@material-ui/icons/Error";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => createStyles({
    formControl: {
      minWidth: 120,
      width: '100%'
    },
    menuPaper: {
        maxHeight: 200
    },
    select: {
        color: `${theme.palette.primary.main} !important`
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DSelectMutiple(props)
{
    const classes = useStyles();

    const [value, setValue] = useState([]);

    useEffect(() => {
        setValue(props.options.filter(res => res !== undefined && props.value.filter(res_value => res_value !== undefined && res_value === res.id).length > 0));
    }, [props.value])

    const handleChange = (event, child) => {
        switch(child.props.value){
            case 'todos':
                if(value.length === props.options.length){
                    props.onChange([]);
                }else {
                    props.onChange(props.options.filter(res => res !== undefined).map(res => res.id));
                }
                break;
            case 'resetar':
                props.onChange([]);
                break;
            default:
                props.onChange(event.target.value.filter(res => res !== undefined).map(res => res.id));
                break;
        }
    };

    return (
        <FormControl className={`${classes.formControl} ${props.error ? "form-control-error" : ""}`}>
            <InputLabel id={props.labelId}>{ props.label }</InputLabel>
            <Select
                style={ props.style }
                labelId={props.labelId}
                id={props.id}
                multiple 
                error={props.error}
                value={ value }
                onChange={handleChange}
                input={<Input />}
                IconComponent={() => props.error ? <Error /> : <ExpandMore className={classes.select} /> }
                renderValue={(selected) => {
                    return selected.length > 1 ? `${selected.length} selecionados` : selected[0].value;
                } }
                MenuProps={{
                    classes: {
                        paper: classes.menuPaper
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                >                
                <MenuItem value='todos'>
                    <Checkbox checked={ value.length === props.options.length } />
                    <ListItemText primary="Todos" />
                </MenuItem>
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        <Checkbox checked={ value.filter(res => res !== undefined && res.id === option.id).length > 0 } />
                        <ListItemText primary={option.value} />
                    </MenuItem>
                ))}
                <MenuItem value='resetar'>
                    <ListItemText className="text-right text-est text-regular text-link" primary="Resetar seleção" />
                </MenuItem>
            </Select>
        </FormControl>
    )
}

DSelectMutiple.defaultProps = {
    id: (new Date()).getTime(),
    labelId: `mutiple-checkbox-label-${(new Date()).getTime()}`,
    label: 'Label',
    error: false,
    style: {},
    value: [],
    options: []
}

export default DSelectMutiple;