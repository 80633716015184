/* eslint-disable no-use-before-define */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
  Redirect,
} from "react-router-dom";

import "./assets/sass/app.scss";
import { useContext } from "react";
import Home from "./component/Home";
import Login from "./component/Login";
import Recover from "./component/Recover";
import Buttons from "./component/Demo/buttons";
import Inputs from "./component/Demo/inputs";
import Textareas from "./component/Demo/textareas";
import Switches from "./component/Demo/switches";
import Radios from "./component/Demo/radio";
import Titulos from "./component/Demo/titulos";
import LayoutAdmin from "./component/shared/LayoutAdmin";
import LayoutAdminContext from "./component/shared/LayoutAdminContext";
import Profissional from "./component/Profissional";
import Paciente from "./component/Paciente";
import Agenda from "./component/Agenda";
import HealthAttendanceType from "./component/HealthAttendanceType";
import AutoCadastro from "./component/AutoCadastro";
import Autocomplete from "./component/Demo/autocomplete";
import Configuracao from "./component/Configuracao";
import NaoAutorizado from "./component/NaoAutorizado";
import MeuPerfil from "./component/MeuPerfil";
import { useState } from "react";
import AtendimentoImediato from "./component/AtendimentoImediato";
import AtendimentoSideBar from "./component/AtendimentoImediato/AtendimentoSideBar";
import VisualizarExame from "./component/AtendimentoImediato/components/VisualizadorExames";
import CriarPdf from "./component/VisualizadorExames";
import ErrorPage from "./component/ErrorPage/ErrorPage";

import SideBar from "./component/Profissional/components/SideBar";
import SideBarPacientes from "./component/Paciente/components/SideBar";
import { Sidebar as SidebarConfiguracao } from "./component/Configuracao/component/Sidebar";

import Solicitacoes from "./component/Solicitacoes/Solicitacoes";
import MdPatients from "./component/Solicitacoes/components/Modais/MdPatients";
import { SideBarFilterProfessionalProvider } from "./context/useFilterProfessional";
import AtendimentoContextProvider from "./context/AtendimentoContext";
import { PatientFilterProvider } from "./context/PatientFilterContext";
import { PatientProvider } from "./context/usePatient";
import { PatientProvider as PatientCtxProvider } from "./context/PatientContext";
import { SolicitacaoProvider } from "./context/SolicitacaoContext";
import { ThemeProvider } from "./context/ThemeContext";
import { AuthRouteProvider } from "./context/AuthRouteContext";
import NotificationCentral from "./component/shared/Notifications/Panel";
import { AppContext } from "./context/AppContext";
import { ToastContainer } from "react-toastify";
import Anamnese from "./component/AtendimentoImediato/PacienteDetails/components/Anamnese";
import { useAppTheme } from "./context/useThemeContext";
import { getList as getListTheme, palette } from './api/temas';
import InnerApp from "./InnerApp";
import { MenuProvider } from "./context/MenuContext";
import SelectPermission from "./component/shared/LayoutAdmin/components/Navbar/components/SelectPermission";

function App() {
  
  const [configuracao, setConfiguracao] = useState("forms");
  const [pacientesSidebar, setPacientesSidebar] = useState(false);
  const [patientFilter, setPatientFilter] = useState([]);
  const { openNotifications, setOpenNotifications } = useContext(AppContext);
  const { handleChangeTheme } = useAppTheme();

  useState(() => {

    const jsonStorage = localStorage.getItem("theme");
    const plt = jsonStorage ? JSON.parse(jsonStorage) : palette;

    handleChangeTheme(plt);
    
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

    if (path) {
      window.history.replaceState(path, "", path);
    }

    getListTheme().then((response) => {
      const localPalette = {
        ...palette,
        primary: {
          light: response.configuration.primary_color_light || palette?.primary.light,
          main: response.configuration.primary_color || palette?.primary.main,
          dark: response.configuration.primary_color_dark || palette?.primary.dark,
        },
        secondary: {
          light: response.configuration.second_color_light || palette?.secondary.light,
          main: response.configuration.second_color || palette?.secondary.main,
          dark: response.configuration.second_color_dark || palette?.secondary.dark,
        },
        logoPlataforma: response.configuration.file || palette?.logoPlataforma
      };

      handleChangeTheme(localPalette);
    });

  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/autocomplete">
          <Autocomplete />
        </Route>
        <Route path="/botoes">
          <Buttons />
        </Route>
        <Route path="/inputs">
          <Inputs />
        </Route>
        <Route path="/textareas">
          <Textareas />
        </Route>
        <Route path="/switches">
          <Switches />
        </Route>
        <Route path="/radios">
          <Radios />
        </Route>
        <Route path="/titulos">
          <Titulos />
        </Route>
        <Route path="/notificacoes">
          <LayoutAdmin>{/* <Notifications /> */}</LayoutAdmin>
        </Route>
        <Route path="/error">
          <ErrorPage />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/recover">
          <Recover />
        </Route>

        <Route path="/autocadastro">
          <AutoCadastro />
        </Route>        
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/nao-autorizado" exact>
          <NaoAutorizado />
        </Route>
        <Route path="/visualizar-exame/:id" exact component={CriarPdf} />        
        <Route path="*">
          <AuthRouteProvider>
            <MenuProvider>
                <SelectPermission />
                <InnerApp />
            </MenuProvider>
          </AuthRouteProvider>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
