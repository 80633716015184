import {useState, useEffect} from 'react';
import {Grid, IconButton} from "@material-ui/core"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DAutoCompleteControlled from '../../../shared/DAutoCompleteControlled';
import DInput from '../../../shared/DInput';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    buttonSymptom: {
      minWidth: "15px !important",
      height: "34px !important",
      borderRadius: "100px !important",
      fontSize: "14px !important",
    },
    iconRemove: {
        color: "#D13D2E"
    }
  }));
  

function DrogasIlicitas(props)
{
    const [type, setType] = useState(null)
    const [use, setUse] = useState(null)
    const [inputValueType, setInputValueType] = useState("")
    const [inputValueUse, setInputValueUse] = useState("")

    const classes = useStyles();

    useEffect(() => {

        setType(props.value.type)
        setUse(props.value.use)
        if(props.value.inputValueType)
            setInputValueType(props.value.inputValueType)

        if(props.value.inputValueUse)
            setInputValueUse(props.value.inputValueUse)
    }, [props.value])

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item md={5}>
                <DAutoCompleteControlled
                    label={"Tipo de droga ilícita"}
                    fullWidth
                    disableClearable
                    placeholder="Tipo de droga ilícita"
                    labelColor="black"
                    error={ props.error && !type }
                    helperText={ props.error && !type ? props.error : "" }
                    options={ props.drugsList }
                    value={ props.drugsList.filter(res => res.value === type)[0] }
                    inputValue={inputValueType}
                    openText="Visualizar listagem"
                    onInputChange={(event, newInputValue) => {
                        setInputValueType(newInputValue);
                    }}
                    getOptionLabel={(option) => option && option.value}
                    getOptionSelected={(option, value) =>
                        value && option.id === value.id
                    }
                    onChange={(e, x) => {
                        setType(x.value)
                        setInputValueType(x.value)
                        props.onChange(x.value, use, x.value, inputValueUse);
                    }}
                />
            </Grid>
            <Grid item md={7}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item md={ props.index > 0 && ((props.totalDrugs - 1) === props.index) ? 7 : 9}>
                        <DAutoCompleteControlled
                            label={"Forma de uso"}
                            fullWidth
                            disableClearable
                            placeholder="Forma de uso"
                            labelColor="black"
                            error={ props.error && !use }
                            helperText={ props.error && !use ? props.error : "" }
                            onChange={(e, x) => {
                                setUse(x.value);
                                setInputValueUse(x.value);
                                props.onChange(type, x.value, inputValueType, x.value);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValueUse(newInputValue);
                            }}
                            options={ props.useDrugsList }
                            value={ props.useDrugsList.filter(res => res.value === use)[0] }
                            inputValue={inputValueUse}
                            openText="Visualizar listagem"
                            getOptionLabel={(option) => option && option.value}
                            getOptionSelected={(option, value) =>
                                value && option.id === value.id
                            }
                        />

                    </Grid>
                    <Grid item md={ props.index > 0 && ((props.totalDrugs - 1) === props.index) ? 5 : 3}>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            { props.index > 0 &&
                                <Grid item>
                                    <IconButton onClick={ e => props.onRemoveDrug(true) }>
                                        <DeleteForeverIcon color="primary"
                                            classes={{
                                            colorPrimary: classes.iconRemove,
                                            }} />
                                    </IconButton>
                                </Grid>                    
                            }
                            { (props.totalDrugs - 1) === props.index &&
                                <Grid item>
                                    <IconButton onClick={ e => props.onAddDrug(true) }>
                                        <AddCircleIcon color="primary"
                                            classes={{
                                            colorPrimary: "MuiSvgIcon-popupIcon",
                                            }} />
                                    </IconButton>
                                </Grid>                    
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

DrogasIlicitas.defaultProps = {
    drugsList: [
        { id: 1, value: "Chá de Ayahuasca" },
        { id: 2, value: "Cocaína" },
        { id: 3, value: "Crack" },
        { id: 4, value: "Ecstasy" },
        { id: 5, value: "Haxixe" },
        { id: 6, value: "Heroína" },
        { id: 7, value: "Lança-perfume" },
        { id: 8, value: "LSD" },
        { id: 9, value: "Maconha" },
        { id: 10, value: "MDMA" },
        { id: 11, value: "Merla" },
        { id: 12, value: "Oxi" },
        { id: 13, value: "Quetamina" },
        { id: 14, value: "Skank" },
        { id: 15, value: "Outra" },
    ],
    useDrugsList: [
        { id: 1, value: "Aspirada" },
        { id: 2, value: "Injetada" },
        { id: 3, value: "Inalada" },
        { id: 4, value: "Oral" },
        { id: 5, value: "Sublingual" },
        { id: 6, value: "Fumada" },
    ],
    totalDrugs: 0,
    index: 0,
    error: false,
    value: {type: null, use: null, inputValueType: "", inputValueUse: ""},
    onChange: function(type, use, inputValueType, inputValueUse) {
        console.log(type)
        console.log(use)
        console.log(inputValueType)
        console.log(inputValueUse)
    },
    onAddDrug: function () {
        console.log('onAddDrug')
    },
    onRemoveDrug: function () {
        console.log('onRemoveDrug')
    },
}

export default DrogasIlicitas;