import { useState } from "react";
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    show: {
        display: "block"
    },
    radioLabel: {
        color: "rgba(39, 39, 39, 1)",
        fontSize: 14,
        fontFamily: "Roboto"
    },
    radioLabelRed: {
        color: "#D13D2E",
        fontSize: 14,
        fontFamily: "Roboto"
    },
    rootRadio: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: "solid rgba(39, 39, 39, 1) 1px",
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    iconRadioRed: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: "solid #D13D2E 1px",
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        borderRadius: '50%',
        border: `1px solid rgba(39, 39, 39, 1) !important`,
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 28%,transparent 32%)`,
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#fff',
        },
    }
    
}));

function StyledRadio(props) {
    
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.rootRadio}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIconRadio)} />}
        icon={<span className={ props.error ? classes.iconRadioRed : classes.iconRadio} />}
        {...props}
      />
    );
}

export const RadioDefault = (props) => {
    const classes = useStyles();
    const { error } = {...props};
    return  <RadioGroup {...props}>
                { (props.options && Array.isArray(props.options)) && props.options.map((option, index) => 
                    <FormControlLabel key={ `radio-default-${index}` } classes={{root: classes.show, label: !error ? classes.radioLabel : classes.radioLabelRed }} value={ option.value } control={<StyledRadio error={ error } />} label={ option.label } /> ) }
            </RadioGroup>
}