// React import
import React, { useState, useEffect } from "react";

// Material styles and icons import
import { Typography, Container, Card, CardContent, Grid, Box } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import "./index.scss";


export default function CustomSwitch(props) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(props.active);
  }, [props.active]);

  const toggleChecked = (e) => {
    props.onChange(e.target.checked);
  };
  
  return (
    <Grid container>
      <Grid item md={12}>
        <List>
          <ListItem>
            <ListItemText
              primary={props.titulo}
              disableTypography={true}
            />
            <ListItemSecondaryAction>
              <Box className="box_custom_switch">
                <Switch name="recording" checked={checked} onChange={toggleChecked} />
                <Typography>{`${checked ? 'Habilitado' : 'Desabilitado'}`}</Typography>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Grid>

  )
}