import { Grid } from "@material-ui/core";
import SimpleModal from "../../../shared/Modal";
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import {useEventListener} from '../../../../hooks/useEventListener';

export function Prescricao(props) {
  const {
    newPrescription,
    setNewPrescription,
    finishPrescription,
    setPrescriptionModal,
    prescriptionModal
  } = useNewHealthAttendance();

  useEventListener('message', (event) => {
    console.log('[Prescricao] Message event:', event);

    if (event.data.type === "prescricao") {
      console.log("[Prescricao::prescricao] Event received by id:", newPrescription.id);
      console.log('[Prescricao::prescricao] Event received:', event);

      finishPrescription(event.data, newPrescription.id);

      setPrescriptionModal(false);
      setNewPrescription(null);
    } else if (event.data.type === "cancel") {
      console.log('[Prescricao::cancel] Event received:', event);
      setPrescriptionModal(false);
      setNewPrescription(null);
    } else if (event.data.type === "excluded") {
      console.log('[Prescricao::excluded] Event received:', event);
      setPrescriptionModal(false);
      setNewPrescription(null);
    }
  });

  return (
    <SimpleModal
      fullPage
      open={prescriptionModal}
      onClose={(e) => {
        setPrescriptionModal(false);
        setNewPrescription(null);
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid
          item
          className="text-center"
          style={{ height: "100%", width: "100%" }}
        >
          {newPrescription && newPrescription.url && (
            <iframe
              width="100%"
              height="94%"
              noborder
              src={newPrescription.url}
            ></iframe>
          )}
        </Grid>
      </Grid>
    </SimpleModal>
  );
}
