import badwords from "../assets/dados/badwords.json";
import Filter from "bad-words";
import { isValid, parse } from "date-fns";
import { ptBR } from "date-fns/locale";

function required(valor) {
  if (valor == "" || valor === undefined || !valor) {
    return "Campo obrigatório";
  }

  return valor.length < 1 ? "Campo obrigatório" : undefined;
}

function requiredPassaporte(valor) {
  if (valor == "" || valor === undefined || !valor) {
    return "Campo obrigatório";
  }

  if (valor.length < 9) return "Número do passaporte inválido";

  if (valor === "000000000") return "Número do passaporte inválido";

  return valor.length < 1 ? "Campo obrigatório" : undefined;
}

function requiredUnidades(valor) {
  //desconciderar
  return undefined;
}

function requiredEmail(valor) {
  if (valor == "" || valor === undefined || !valor) {
    return "Campo obrigatório";
  }

  //Validação antiga
  // const regexEmailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  //Validação nova
  const regexEmailValidation = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(.*)+?$/i;
  //Vai me retornar um booleano, caso esteja errado motra erro
  if (regexEmailValidation.test(valor)) {
    return undefined;
  } else {
    return "E-mail inválido";
  }
}

function requiredTwoName(string_name) {
  var valor = string_name != undefined ? string_name.toString().trim() : "";

  if (!valor || valor === undefined || valor == "" || valor.length < 1) {
    return "Campo obrigatório";
  }

  return valor.split(" ").length < 2
    ? "O campo deve ter pelo menos 2 nomes"
    : undefined;
}

function requiredOptionalTwoName(string_name) {
  var valor = string_name != undefined ? string_name.toString().trim() : "";

  if (!valor || valor === undefined || valor == "" || valor.length < 1) {
    return undefined;
  }

  return valor.split(" ").length < 2
    ? "O campo deve ter pelo menos 2 nomes"
    : undefined;
}

/**
 * Analisa documentos profissionais para aplicar pelo menos 1
 * @param {array} documents
 */
function checkProfissionalDocuments(documents) {
  var checkValidate = documents.map((document, index) => {
    switch (document.type) {
      case "CREFITO":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "CRO":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "CRM":
        console.log("CRM");
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "RMS":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "COREN":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "CRN":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "CRP":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
      case "CREFONO":
        return mappingFieldsProfessionalDocuments(document.fields);
        break;
    }
  });

  checkValidate = checkValidate.filter((x) => x);

  const oneLeastDocument = checkValidate.map((item, index) => {
    if (item !== undefined) {
      if (item.length === 5) {
        item.splice(2, 1); // Removendo item da posição 2 (Número) o qual não está sendo utilizado no banco.

        console.log(item);

        let counter = item.filter((check) => check === true).length;

        if (counter >= 4) {
          return true;
        }
      } else if (item.length === 4) {
        let counter = item.filter((check) => check === true).length;

        if (counter >= 4) {
          return true;
        }
      }
    }
  });

  if (oneLeastDocument.includes(true)) {
    return undefined;
  } else {
    return "Campo obrigatório";
  }
}

/**
 * Verifica estado dos fields dos documents, especialmente os profissionais
 * @param {*} fields
 * @returns boolean
 */
function mappingFieldsProfessionalDocuments(fields) {
  return fields.map((field) => {
    if (field.value == "" || field.value === null) return false;
    else return true;
  });
}

function requiredConselho(valor) {
  let error = [];
  let peloMenosUm = false;
  let camposOK = true;
  let preenchido = [];

  for (let cont = 0; cont < valor.length; cont++) {
    if (
      valor[cont].descricao != "" ||
      valor[cont].numero != "" ||
      valor[cont].uf != ""
    )
      preenchido.push(cont);

    if (
      valor[cont].conselho == "" ||
      valor[cont].descricao == "" ||
      ((valor[cont].numero == "" || valor[cont].uf == "") &&
        valor[cont].descricao.substr(0, 2) == "22")
    ) {
      error[cont] = "Campo obrigatório";
    } else {
      error[cont] = undefined;
      peloMenosUm = true;
    }

    if (
      valor[cont].numero === "0000000000" ||
      valor[cont].numero === "00000000" ||
      valor[cont].numero === ""
    ) {
      error[cont] = "Campo inválido";
    }
  }

  if (preenchido.length > 0) {
    for (let cont = 0; cont < valor.length; cont++) {
      if (preenchido.indexOf(cont) < 0) error[cont] = undefined;
    }
  }

  for (let cont = 0; cont < error.length; cont++) {
    if (error[cont] !== undefined) camposOK = false;
  }

  return camposOK ? undefined : error;
}

function requiredUnit(valor) {
  return valor.length &&
    valor[0].medical_unit_id &&
    valor[0].profile &&
    valor[0].profile.length
    ? undefined
    : "Campo obrigatório";
}

function requiredUnitCustom(valor) {
  let contOk = 0;

  for (let cont = 0; cont < valor.unidades.length; cont++) {
    if (
      valor.unidades.length &&
      valor.unidades[cont].id &&
      valor.unidades[cont].profiles &&
      valor.unidades[cont].profiles.length
    )
      contOk++;
  }

  return contOk === valor.unidades.length
    ? undefined
    : contOk === 0
    ? "Campo obrigatório"
    : "Certifique-se que todas unidades tenha pelo menos um perfil relacionado";
}

function CNSValido(valor) {
  if (valor == "" || valor === undefined || !valor) {
    return "Campo obrigatório";
  }

  return !validaCNS(valor) ? "CNS inválido" : undefined;
}

function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

function validaLoteCNS(cns) {
  var errors = [];

  cns.map((item, index) => {
    if (!item || item.length <= 0) {
      errors[index] = "Campo obrigatório";
      //errors.push("Campo obrigatório");
    } else if (!/\d+$/g.test(item)) {
      //errors.push("CNS só pode conter números em sua composição");
      errors[index] = "CNS só pode conter números em sua composição";
    } else {
      var validaTamanho = item.length == 15;
      var validaInicio = ["1", "2", "7", "8", "9"].includes(item[0]);

      if (validaTamanho && validaInicio) {
        //CNS Iniciados em 7, 8 ou 9
        if (["7", "8", "9"].includes(item[0])) {
          var soma = item
            .split("")
            .reduce((total, value, index) => total + value * (15 - index), 0);
          var result = soma % 11;

          //result && errors.push("CNS inválido");
          result && (errors[index] = "CNS inválido");
        } else {
          //CNS Iniciados em 1, 2
          var pis = item.substring(0, 11);
          var soma = pis
            .split("")
            .reduce((total, value, index) => total + value * (15 - index), 0);

          var resto = soma % 11;
          var dv = resto == 0 ? 0 : 11 - resto;

          var value =
            dv == 10 ? `${pis}001${11 - ((soma + 2) % 11)}` : `${pis}000${dv}`;
          var result = value == item ? false : true;

          //result && errors.push("CNS inválido");
          result && (errors[index] = "CNS inválido");
        }
      } else {
        //errors.push("CNS inválido");
        errors[index] = "CNS inválido";
      }
    }
  });

  if (errors.length) {
    return errors;
  } else {
    return undefined;
  }
}

export function validaLoteCNSCustom(cns_value) {

  var errors = [];

  if(!cns_value || !Array.isArray(cns_value) || cns_value.length <= 0)
    errors[0] = "Campo obrigatório";
  else {

    var cns = cns_value.map(item => item.value);
  
    console.log(cns);  
  
    cns.map((item, index) => {
  
      if (!item || item.length <= 0) {
        errors[index] = "Campo obrigatório";
        //errors.push("Campo obrigatório");
      } else if (!/\d+$/g.test(item)) {
        //errors.push("CNS só pode conter números em sua composição");
        errors[index] = "CNS só pode conter números em sua composição";
      } else {
        var validaTamanho = item.length == 15;
        var validaInicio = ["1", "2", "7", "8", "9"].includes(item[0]);
  
        if (validaTamanho && validaInicio) {
          //CNS Iniciados em 7, 8 ou 9
          if (["7", "8", "9"].includes(item[0])) {
            var soma = item
              .split("")
              .reduce((total, value, index) => total + value * (15 - index), 0);
            var result = soma % 11;
  
            //result && errors.push("CNS inválido");
            result && (errors[index] = "CNS inválido");
          } else {
            //CNS Iniciados em 1, 2
            var pis = item.substring(0, 11);
            var soma = pis
              .split("")
              .reduce((total, value, index) => total + value * (15 - index), 0);
  
            var resto = soma % 11;
            var dv = resto == 0 ? 0 : 11 - resto;
  
            var value =
              dv == 10 ? `${pis}001${11 - ((soma + 2) % 11)}` : `${pis}000${dv}`;
            var result = value == item ? false : true;
  
            //result && errors.push("CNS inválido");
            result && (errors[index] = "CNS inválido");
          }
        } else {
          //errors.push("CNS inválido");
          errors[index] = "CNS inválido";
        }
      }
    });
  }


  if (errors.length) {
    return errors;
  } else {
    return undefined;
  }
}

function validaCPF(cpf) {
  if (!cpf) return "Campo obrigatório";

  cpf = cpf.replace(/[\s.-]*/gim, "");

  if (!cpf || cpf.length <= 0) {
    return "Campo obrigatório";
  } else if (
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return "CPF inválido";
  }

  var soma = 0;
  var resto;

  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);

  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;

  if (resto != parseInt(cpf.substring(9, 10))) return "CPF inválido";

  soma = 0;

  for (var i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);

  resto = (soma * 10) % 11;

  if (resto == 10 || resto == 11) resto = 0;

  if (resto != parseInt(cpf.substring(10, 11))) return "CPF inválido";

  return undefined;
}

function validaEmail(field) {
  if (!field || field.length <= 0) {
    return "Campo obrigatório";
  } else if (typeof field !== "string") {
    return "E-mail inválido";
  } else {
    var usuario = field.substring(0, field.indexOf("@"));
    var dominio = field.substring(field.indexOf("@") + 1, field.length);
  }

  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search("@") == -1 &&
    dominio.search("@") == -1 &&
    usuario.search(" ") == -1 &&
    dominio.search(" ") == -1 &&
    dominio.search(".") != -1 &&
    dominio.indexOf(".") >= 1 &&
    dominio.lastIndexOf(".") < dominio.length - 1
  ) {
    return undefined;
  } else {
    return "E-mail inválido";
  }
}

function validaRG(rg) {
  if (!rg || rg === "") return "Campo obrigatório";

  rg = rg.replace(/[\s.-]*/gim, "");

  if (!rg || rg.length <= 0) {
    return "Campo obrigatório";
  } else if (
    rg.length != 9 ||
    rg == "000000000" ||
    rg == "111111111" ||
    rg == "222222222" ||
    rg == "333333333" ||
    rg == "444444444" ||
    rg == "555555555" ||
    rg == "666666666" ||
    rg == "777777777" ||
    rg == "888888888" ||
    rg == "999999999"
  ) {
    return "RG inválido";
  }

  return undefined;
}

function validaCNS(value) {
  if (value !== "") {
    //return true;

    var cns = value.replace(/\D/g, "");

    var validaTamanho = cns.length == 15;
    var validaInicio = ["1", "2", "7", "8", "9"].includes(cns[0]);

    if (validaTamanho && validaInicio) {
      //CNS Iniciados em 7, 8 ou 9
      if (["7", "8", "9"].includes(cns[0])) {
        var soma = cns
          .split("")
          .reduce((total, value, index) => total + value * (15 - index), 0);

        return soma % 11 == 0;
      } else {
        //CNS Iniciados em 1, 2
        var pis = cns.substring(0, 11);
        var soma = pis
          .split("")
          .reduce((total, value, index) => total + value * (15 - index), 0);

        var resto = soma % 11;
        var dv = resto == 0 ? 0 : 11 - resto;

        var resultado =
          dv == 10 ? `${pis}001${11 - ((soma + 2) % 11)}` : `${pis}000${dv}`;

        return resultado == cns;
      }
    }
  } else {
    return true;
  }
}

function validaPIS(pis) {
  let multiplicadorBase = "3298765432";
  let total = 0;
  let resto = 0;
  let multiplicando = 0;
  let multiplicador = 0;
  let digito = 99;

  // Retira a mascara

  if (typeof pis !== "string") return undefined; // evita erro em tela caso pis != string.

  let numeroPIS = pis.replace(/[^\d]+/g, "");

  if (numeroPIS.length) {
    if (
      numeroPIS.length < 11 ||
      numeroPIS === "00000000000" ||
      numeroPIS === "11111111111" ||
      numeroPIS === "22222222222" ||
      numeroPIS === "33333333333" ||
      numeroPIS === "44444444444" ||
      numeroPIS === "55555555555" ||
      numeroPIS === "66666666666" ||
      numeroPIS === "77777777777" ||
      numeroPIS === "88888888888" ||
      numeroPIS === "99999999999"
    ) {
      return "PIS/PASEP inválido";
    } else {
      for (var i = 0; i < 10; i++) {
        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
        total += multiplicando * multiplicador;
      }

      resto = 11 - (total % 11);
      resto = resto === 10 || resto === 11 ? 0 : resto;

      digito = parseInt("" + numeroPIS.charAt(10));

      if (resto === digito) {
        return undefined;
      } else {
        return "PIS/PASEP inválido";
      }
    }
  } else {
    return undefined;
  }
}

function validaTel(tel) {
  var errors = [];

  console.log("validaTel first");

  if (Array.isArray(tel)) {
    tel.forEach((item, index) => {
      var telObj = { index: null, phone: null, name: null };

      console.log("validaTel");
      console.log(tel);
      console.log(item.phone);
      console.log(item);

      if (!item.phone || item.phone.length <= 0) {
        telObj.phone = "Campo obrigatório";
      } else if (
        item.phone.length < 14 ||
        item.phone == "(00) 00000-0000" ||
        item.phone == "(11) 11111-1111" ||
        item.phone == "(22) 22222-2222" ||
        item.phone == "(33) 33333-3333" ||
        item.phone == "(44) 44444-4444" ||
        item.phone == "(55) 55555-5555" ||
        item.phone == "(66) 66666-6666" ||
        item.phone == "(77) 77777-7777" ||
        item.phone == "(88) 88888-8888" ||
        item.phone == "(99) 99999-9999"
      ) {
        telObj.phone = "Telefone inválido";
      }

      if (!item.name || item.name.length <= 0) {
        telObj.name = "Campo obrigatório";
      }

      if (telObj.name || telObj.phone) {
        telObj.index = index;
        errors.push(telObj);
      }
    });

    if (errors.length) {
      return errors;
    } else {
      return undefined;
    }
  } else {
    return null;
  }
}

/**
 * @description Filtro de palavrões / palavras ofensivas.
 * @param {string} value - Valor a ser filtrado.
 * @returns {boolean} Verdadeiro ou falso para palavra ofensiva.
 */
export function haveBadWords(value) {
  var filter = new Filter();

  filter.addWords(...badwords);

  if (typeof value === "string" && value.length > 0) {
    return filter.isProfane(value);
  } else {
    return false;
  }
}

function validaRNE(value){
  if (!value) return "Campo obrigatório.";

  const regex = new RegExp(/[a-zA-Z]{1}[0-9]{6}-[0-9]{1}/);
  if(!regex.test(value)){
    return "Campo Inválido"
  }

  return false;
}

function validaCEP(cep) {
  if (!cep) return "Campo obrigatório.";

  cep = cep.replace(/[\s.-]*/gim, "");

  if (
    cep.length != 8 ||
    cep == "00000000" ||
    cep == "11111111" ||
    cep == "22222222" ||
    cep == "33333333" ||
    cep == "44444444" ||
    cep == "55555555" ||
    cep == "66666666" ||
    cep == "77777777" ||
    cep == "88888888" ||
    cep == "99999999"
  ) {
    return "CEP inválido";
  }

  return undefined;
}

function parseNumber(value) {
  var number = 0;
  try {
    number = value != "" ? parseInt(value) : 0;
  } catch (err) {}

  return number;
}

const isBissexto = (ano) => {
  return ano % 4 == 0 && (ano % 100 != 0 || ano % 400 == 0);
};

const checarData = (day, month, year) => {
  if (parseInt(month) > 12) return false;

  switch (month) {
    case "01":
    case "03":
    case "05":
    case "07":
    case "08":
    case "10":
    case "12":
      return day <= 31;
    case "04":
    case "06":
    case "09":
    case "11":
      return day <= 30;
    default:
      if (isBissexto(year)) return day <= 29;
      else return day <= 28;
  }
};

/**
 * @description Verifica se uma data é válida usando o padrão brasileiro
 * @param {string} day Dia
 * @param {string} month Mês
 * @param {string} year Ano
 * @returns {boolean} Verdadeiro ou falso para data válida.
 */
export function isValidDate(day, month, year) {
  if(!day || !month || !year)
    return false;

  const parsed = parse(`${day}/${month}/${year}`, 'P', new Date(), { locale: ptBR })
  return isValid(parsed)
}

export {
  required,
  requiredEmail,
  requiredTwoName,
  requiredUnidades,
  requiredOptionalTwoName,
  checkProfissionalDocuments,
  validaCPF,
  validaEmail,
  requiredUnit,
  requiredConselho,
  requiredUnitCustom,
  validaRG,
  validaLoteCNS,
  validaCNS,
  validaPIS,
  validaTel,
  validaCEP,
  CNSValido,
  parseNumber,
  validarCNPJ,
  isBissexto,
  checarData,
  requiredPassaporte,
  validaRNE
};
