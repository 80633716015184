import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { Clear } from "@material-ui/icons";
import DButton from "../../../shared/DButton";

import { acceptTermUse, denyTermUse, getTermActive } from "../../../../api/termuse";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Redirect } from "react-router";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: "1px solid #949494",
    borderRadius: 15,
    outline: "none !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: "solid 1px #C7C7C7",
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: "solid 1px #C7C7C7",
    marginTop: 20,
  },
  check: {
    display: "flex",
  },
  gridButton: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

export default function MdTermUse(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [term, setTerm] = useState(false);
  const [valueTerm, setValueTerm] = useState();
  const [uid, setUid] = useState(localStorage.getItem("uid"));

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [redirect, setRedirect] = useState(false);

  const [currentTermo, setCurrentTermo] = useState()

  useEffect(
    function () {
      if (props.value.message) {
        setTerm(true);
        setValueTerm(props.value.term);
      }
    },
    [props.value]
  );

  useEffect(() => {
    getTermActive(localStorage.getItem('token')).then(res => {
      if(res.status) {
      setCurrentTermo(res.term);
      }
  });
}, []);

  function closeModal() {
    notAcceptTerm()
    return props.onClose(true);
  }
  
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  async function acceptTerm() {
    if (checked) {
      setLoading(true);
      const signTermUse = await acceptTermUse(currentTermo.id, localStorage.getItem("uid")).then((res) => {
        return res;
      });
      if (signTermUse.status) {
        props.signTerm(signTermUse.status);
      }
      setLoading(false);
    }
  }

  async function notAcceptTerm() {
      const signTermUse = await denyTermUse(currentTermo.id, localStorage.getItem("uid")).then((res) => {
        return res;
      });
  }
  

  const TermContent = () => (
    <div dangerouslySetInnerHTML={{ __html: currentTermo.terms }} />
  );
   

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <h2
            className="simple-modal-title text-mt text-regular"
            style={{ alignItems: "center", display: "flex" }}
          >
            Termo de Uso e Política de Privacidade
          </h2>
          <Clear
            style={{ color: "#949494", cursor: "pointer" }}
            onClick={(e) => {
              closeModal()
            }}
          />
        </div>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ padding: 15 }}
        >
          <>
            <Grid item>{term ? <TermContent /> : <CircularProgress />}</Grid>
          </>
        </Grid>
        <div className={classes.footermodal}>
          <div className={classes.check}>
            <Checkbox
              checked={props.checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <h6 className="text-mt text-regular">
              Eu li e concordo com o termo de uso
            </h6>
          </div>
          <div className={classes.gridButton}>
            <DButton
              style={{margin: '0px 10px'}}
              variant="secondary"
              color="primary"
              size="sm"
              onClick={(e) => {
                closeModal()
              }}
            >
              Voltar
            </DButton>
            <DButton variant="primary" size="sm" onClick={acceptTerm}>
              { loading ?  <CircularProgress color="inherit" size={15} /> : 'Aceitar' }
            </DButton>
            
          </div>
          
        </div>
      </>
    </div>
  );


  return (
    <div>
      <Modal
        open={props.open}
        onClose={ e => props.onCloseModal(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
