// React import
import React from "react";
import {Component} from "react";

// Material styles and icons import
import {Typography, Container, Card, CardContent, Grid, Box, Backdrop} from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";

// Component imports
import Button from '@material-ui/core/Button';
import CustomSwitchDropdown from '../shared/CustomSwitchDropdown'
import CustomSwitch from '../shared/CustomSwitch'
import ModalHabiliteVideo from './component/ModalHabiliteVideo'

// API routes import
import configuration from "../../api/configuration";
import { getAllUnidades } from "../../api/unidades";
import { getAllMedicalSpecialties } from "../../api/medicalSpecialty";
import { getAllProfilesNotPaginate } from "../../api/profiles";

// Styled components
import styledComponents from "styled-components";
import { withStyles } from "@material-ui/core/styles";

// const ButtonAction = styledComponents(Button)`
//     background-color: ${props => props.active ? '#EDF6FD' : 'transparent'};
//     color: ${props => props.active ? '#006FB5' : '#D0D3D9'};
//     text-decoration: underline;
//     border-radius: 8px;
//     font-size: 12px;
//     text-transform: none;
//     padding: 8px 20px;
// `;
const styles = theme => ({
    bckSnow: { 
        background: theme.palette.background.cloud
    },
    listItem: {
        padding: "41px 0",
        margin: 0,
        borderTop: `1px solid ${theme.palette.neutral.midlightgray}`
    },
    btnAction: {
        backgroundColor: 'transparent',
        color: theme.palette.neutral.midlightgray,
        textDecoration: 'underline',
        borderRadius: '8px',
        fontSize: '12px',
        textTransform: 'none',
        padding: "8px 20px"
    },

    btnActionActive: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        borderRadius: '8px',
        fontSize: '12px',
        textTransform: 'none',
        padding: "8px 20px"        
    }
});

class Ligacoes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingMedicalUnits: false,
            loadingMedicalSpecialties: false,
            loadingMedicalProfiles: false,
            unidades: [],
            specialties: [],
            profiles: [],
            abaModal: 1,
            videoconference: false
        }
    }

    componentDidMount() {
        this.listUnits();
        this.listSpecialties();
        this.listProfiles();
        this.getConfig();
    }

    async listUnits() {
        this.setState({...this.state, loadingMedicalUnits: true});

        await getAllUnidades(localStorage.getItem("token")).then((res) => {
            var unidades = [];

            res.length && res.map((item, index) => {
                if (item.below_mother_medical_unit == "no") {
                  unidades.push(item);
                  this.setState({ ...this.state, unidades: unidades });
                }

                if (index == res.length - 1) {
                    this.setState({ ...this.state, loadingMedicalUnits: false });
                }
            });
        }).catch((err) => {
            this.setState({...this.state, loadingMedicalUnits: false});
            console.error(err);
            toast.error("Ocorreu um erro ao carregar as unidades. Por favor tente novamente.");
        });
    }

    async listSpecialties() {
        this.setState({...this.state, loadingSpecialties: true});

        await getAllMedicalSpecialties(localStorage.getItem('token')).then(res => {
            this.setState({...this.state, specialties: res});
            this.setState({...this.state, loadingSpecialties: false});
        }).catch((err) => {
            this.setState({...this.state, loadingSpecialties: false});
            console.error(err);
            toast.error("Ocorreu um erro ao carregar as especialidades. Por favor tente novamente.");
        })
    }

    async listProfiles() {
        this.setState({...this.state, loadingMedicalProfiles: true});

        await getAllProfilesNotPaginate(localStorage.getItem('token')).then(res => {
            if (res.status) {
                this.setState({...this.state, profiles: res.profiles});    
            } else {
                toast.error("Ocorreu um erro ao carregar os perfis. Por favor tente novamente.");
            }

            this.setState({...this.state, loadingMedicalProfiles: false});
        }).catch((err) => {
            this.setState({...this.state, loadingMedicalProfiles: false});
            console.error(err);
            toast.error("Ocorreu um erro ao carregar os perfis. Por favor tente novamente.");
        })
    }

    async getConfig() {
        configuration.get(localStorage.getItem('token')).then(res => {
            if (res.status) {
                this.setState({ ...this.state, videoconference: res.configuration.video_conference == "1" ? true : false });
            }
        }).catch((err) => {
            console.error(err);
            toast.error("Ocorreu um erro ao carregar as configurações. Por favor tente novamente.");
        });
    }
    
    async enableOrDisableVideoconference(status) {
        var payload = {
            "video_conference": status
        }

        await configuration.putEnableOrDisableRecording(localStorage.getItem('token'), 1, payload).then(res => {
            if (res.status) {
                toast.success(`videoconferência ${status ? 'h' : 'des'}abilitada com sucesso!`);
                this.setState({ ...this.state, videoconference: status });
            } else {
                toast.error(`Ocorreu um erro ao ${status ? 'h' : 'des'}abilitar a videoconferência. Por favor tente novamente.`);
            }
        }).catch((err) => {
            console.error(err);
            toast.error(`Ocorreu um erro ao ${status ? 'h' : 'des'}abilitar a videoconferência. Por favor tente novamente.`);
        });
    }
    
    render() {

        const { classes } = this.props;
        
        const styles = {
            titleTop: { 
                marginTop: 32,
                marginBottom: 24,
                marginLeft: 14
            },
            flex: {
                display: 'flex'
            },
            firstListItem: {
                padding: "0px 0px 41px 0px",
                margin: 0
            },
            item_text: {
                fontSize: 14,
                margin: 0,
                padding: 0
            }
        }

        return (
            <>
                <Backdrop style={{zIndex: 3000, color: 'white'}} open={ this.state.loadingMedicalUnits || this.state.loadingMedicalSpecialties }>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container className="tema-light">
                    <Typography style={ styles.titleTop } variant="h3" className="text-primary">Ligações</Typography>
                    <Card style={{ background: "#f9f9f9", marginBottom: 32 }}>
                        <CardContent style={{ paddingBottom: 0, paddingTop: 24 }}>
                            <Grid container>
                                <Grid item md={3}>
                                    <Typography variant="h4">Permissões</Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ paddingTop: 32 }}>
                                <Grid item md={12}>
                                    <List style={{ padding: 0 }}>
                                        <ListItem className={ classes.firstListItem  }>
                                            <ListItemText
                                                primary={ 'Videoconferência' }
                                                disableTypography = { true }
                                                style= { styles.item_text }
                                            />
                                            <ListItemSecondaryAction style={{ right: 0, top: "16%" }}>
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <CustomSwitch  active={ this.state.videoconference } onChange={ e => this.enableOrDisableVideoconference(e) } />
                                                </Box>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className={classes.bckSnow} style={{position: "relative" }}>
                        <ModalHabiliteVideo open={ (!this.state.videoconference && (this.state.unidades.length || this.state.specialties.length)) } />
                        <CardContent style={{ paddingBottom: 0, paddingTop: 24 }}>
                            <Grid container style={{ paddingBottom: 24 }}>
                                <Grid item md={12} style={{ display: "flex" }}>
                                    <div style={{ paddingRight: 10 }}>
                                        <Button className={this.state.abaModal === 1 ? classes.btnActionActive : classes.btnAction} active={ this.state.abaModal === 1 } onClick={ e => { this.setState({ ...this.state, abaModal: 1 }) } } id="basic-button">
                                            Unidades executantes
                                        </Button>
                                    </div>
                                    <div>
                                        <Button className={this.state.abaModal === 2 ? classes.btnActionActive : classes.btnAction} active={ this.state.abaModal === 2 } onClick={ e => { this.setState({ ...this.state, abaModal: 2 }) } } id="basic-button">
                                            Especialidades
                                        </Button>
                                    </div>
                                    <div>
                                        <Button className={this.state.abaModal === 3 ? classes.btnActionActive : classes.btnAction} active={ this.state.abaModal === 3 } onClick={ e => { this.setState({ ...this.state, abaModal: 3 }) } } id="basic-button">
                                            Perfis
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            { this.state.abaModal === 1
                                ?
                                    <>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <Typography variant="h4">Unidades executantes</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ paddingTop: 32 }}>
                                            <Grid item md={12}>
                                                <List style={{ padding: 0 }}>
                                                    { this.state.unidades.length 
                                                        ? 
                                                            this.state.unidades.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <ListItem style={ styles.firstListItem }>
                                                                            <ListItemText
                                                                                primary={ item.company_name }
                                                                                disableTypography = { true }
                                                                                style= { styles.item_text }
                                                                            />
                                                                            <ListItemSecondaryAction style={{ right: 0, top: "16%" }}>
                                                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                                                    <CustomSwitchDropdown id={ item.id } active={ item.callEnabled } type="unit" titulodrop='Tipos de Chamada' />
                                                                                </Box>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })
                                                        :
                                                            ''
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    ""
                            }
                            { this.state.abaModal === 2
                                ?
                                    <>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <Typography variant="h4">Especialidades</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ paddingTop: 32 }}>
                                            <Grid item md={12}>
                                                <List style={{ padding: 0 }}>
                                                    { this.state.specialties.length 
                                                        ? 
                                                            this.state.specialties.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <ListItem style={ styles.firstListItem }>
                                                                            <ListItemText
                                                                                primary={ item.description }
                                                                                disableTypography={ true }
                                                                                style= { styles.item_text }
                                                                            />
                                                                            <ListItemSecondaryAction style={{ right: 0, top: "16%" }}>
                                                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                                                    <CustomSwitchDropdown id={ item.id } active={ item.callEnabled } type="specialty" titulodrop='Tipos de Chamada' />
                                                                                </Box>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })
                                                        :
                                                            ''
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    ""
                            }
                            { this.state.abaModal === 3
                                ?
                                    <>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <Typography variant="h4">Perfis</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ paddingTop: 32 }}>
                                            <Grid item md={12}>
                                                <List style={{ padding: 0 }}>
                                                    { this.state.profiles.length 
                                                        ? 
                                                            this.state.profiles.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <ListItem style={ styles.firstListItem }>
                                                                            <ListItemText
                                                                                primary={ item.description }
                                                                                disableTypography={ true }
                                                                                style={ styles.item_text }
                                                                            />
                                                                            <ListItemSecondaryAction style={{ right: 0, top: "16%" }}>
                                                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                                                    <CustomSwitchDropdown id={ item.id } active={ item.callEnabled } type="profile" titulodrop='Tipos de Chamada' />
                                                                                </Box>
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    </>
                                                                )
                                                            })
                                                        :
                                                            ''
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </>
                                :
                                    ""
                            }
                        </CardContent>
                    </Card>
                </Container>
            </>
        )
    }
}

export default withStyles(styles)(Ligacoes);