import styled from "styled-components";

export const Container = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
`;

export const PacienteCreateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: 100px;
  min-width: 90%;
  padding: 20px;
`;
