import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WifiOffIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles({
    box: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 24,
        width: 450,
        height: 240,
        background: "#F9F9F9",
        boxShadow: "0px 2px 2px rgba(49, 34, 67, 0.12), 0px 3px 1px rgba(49, 34, 67, 0.1), 0px 1px 5px rgba(49, 34, 67, 0.18)",
        borderRadius: 4
    },
    title: {
        fontSize: 20,
        color: "#4f4f4f",
        marginTop: 20,
        marginBottom: 28
    },
    message: {
        color: "#4f4f4f",
        fontSize: 16,
        fontWeight: 400
    }
});



function Modal(props) {
    const classes =  useStyles();

    return (
        <div className={ classes.box }>
            <div style={{ textAlign: "center" }}>
                <WifiOffIcon style={{ color: "#D13D2E", fontSize: 48 }} />
                <h1 className={ classes.title }>{ props.title }</h1>
            </div>
            <Typography className={ classes.message }>{ props.children }</Typography>
        </div>
    );
}

export default Modal;