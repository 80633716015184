
import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./../theme";
import { MuteCall, UnmuteCall, AddUserCall, VideoButtom, VideoOffButtom, ChatButtom, OpenInBrowserButtom, DesligarButtom  } from "./Buttons";
import { toast } from "react-toastify";
import {secondsToMinutes} from "../../../../helpers/utils"

function ToolBar(props) {

    const classes = useStyles();
    const {timer} = props;

    const [chat, setChat] = useState(false);
    const [mute, setMute] = useState(false);
    const [video, setVideo] = useState(true);

    return <Box className={classes.boxToolbarContent}
                width={ props.width }
                height={ props.height }
                position="absolute" 
                zIndex="tooltip"
                bgcolor="white">
                <Grid classes={{root: classes.boxInnerContent}} container alignItems="center">
                    <Grid item md={4}>
                        <Grid classes={{root: classes.boxInnerContent}} container spacing={2} justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <OpenInBrowserButtom onClick={ e => props.onBrowser(true) } />
                            </Grid>
                            <Grid item>
                                <AddUserCall onClick={ e => props.onAddUser(true) } />
                            </Grid>
                            <Grid item>
                                <ChatButtom onClick={ e => {
                                    setChat(!chat);
                                    props.onChat(true)
                                } } />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={8}>
                        <Grid classes={{root: classes.boxInnerContent}} container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item>
                                <span className={classes.boxPipe}>&nbsp;</span>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className="text-regular text-lt">{ secondsToMinutes(timer) }</Typography>
                            </Grid>
                            <Grid item>
                                { mute ? 
                                    <UnmuteCall onClick={ e => {
                                        setMute(false);
                                        props.onUnmute(true);
                                    } } /> : <MuteCall onClick={ e => {
                                        setMute(true);
                                        props.onMute(true);
                                    } } /> }                                
                            </Grid>
                            <Grid item>
                                { video ?
                                    <VideoButtom onClick={ e => {
                                        setVideo(false);
                                        props.onNotVideo(true);
                                    } } />
                                    :
                                    <VideoOffButtom onClick={ e => {
                                        setVideo(true);
                                        props.onVideo(true);
                                    } } />
                                }
                            </Grid>
                            <Grid item>
                                <DesligarButtom onClick={ e => props.onFinish(true) } />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
    
}

ToolBar.defaultProps = {
    duration: "0:00",
    width: 453,
    height: 58,
    onBrowser: function() {
        console.log("onBrowser");
        toast.success("onBrowser")
    },
    onChat: function() {
        console.log('onChat');
        toast.success("onChat")
    },
    onMute: function() {
        console.log('onMute');
        toast.success("onMute")
    },
    onUnmute: function() {
        console.log('onUnmute');
        toast.success("onUnmute")
    },
    onVideo: function() {
        console.log('onVideo');
        toast.success("onVideo")
    },
    onNotVideo: function() {
        console.log('onNotVideo');
        toast.success("onNotVideo")
    },
    onAddUser: function() {
        console.log('onAddUser');
        toast.success("onAddUser")
    },
    onFinish: function() {
        console.log('onFinish');
        toast.success("onFinish")
    },
    timer: 0,
    setTimer: function(x) {
        console.log('setTimer');
    }
}

export default ToolBar;