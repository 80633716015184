import React, { Component, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Popover from "@material-ui/core/Popover";
import PregnantWomanIcon from "@material-ui/icons/PregnantWoman";
import DoctorIcon from "../../../../assets/img-icon/DoctorIconBlue.svg";
import MonitorIcon from "../../../../assets/img-icon/monitorIcon.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import BlockIcon from "@material-ui/icons/Block";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@material-ui/icons/Info";
import DescriptionIcon from "@material-ui/icons/Description";

import DButton from "../../../shared/DButton";
import Person from "@material-ui/icons/Person";
import { getPatientById } from "../../../../api/patient";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { getPatientDocuments } from "../../../../helpers/utils";
import { useTheme } from "@material-ui/styles";
import PatientInfo from "../PatientInfo";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  item__card: {
    display: "flex",
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
    boxShadow:
      "0px 1px 1px rgba(49, 34, 67, 0.1), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.1)",
  },
  item__card_onduty: {
    display: "flex",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__card_rest: {
    display: "flex",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__card_blocked: {
    display: "flex",
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    backgroundColor: `${theme.palette.background.cloud}`,
    borderRadius: 8,
    padding: 24,
    height: 70,
    marginBottom: 32,
  },
  item__chip: {
    backgroundColor: theme.palette.primary.light,
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: "0 16px",
    marginRight: 12,
    height: 26,
  },
  item__chip_success: {
    backgroundColor: theme.palette.primary.light,
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: "0 16px",
    marginRight: 12,
    height: 26,
  },
  item__chip_cancelled: {
    backgroundColor: theme.palette.support.error,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: "0 16px",
    marginRight: 12,
    height: 26,
  },
  item__chip_onduty: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: "0 16px",
    marginRight: 12,
    height: 26,
  },
  item__chip_blocked: {
    backgroundColor: theme.palette.neutral.darkgray,
    fontSize: 12,
    color: theme.palette.neutral.white,
    padding: "0 16px",
    marginRight: 12,
    height: 26,
  },
  dialog: {
    padding: 24,
    width: 320,
  },
  dialog__success: {
    backgroundColor: theme.palette.background.cloud,
    padding: 24,
  },
  close_button: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  success: {
    color: theme.palette.primary.main,
  },
  cancel: {
    color: theme.palette.support.error,
  },
  active: {
    color: theme.palette.primary.main,
  },
  consultas_popover: {
    listStyle: "none",
    padding: 0,
    margin: "0 16px",
  },
  consultas_popoverItem: {
    listStyle: "none",
    margin: 0,
    padding: "12px 0",
    fontSize: 12,
    color: theme.palette.neutral.black,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  avisoAtraso: { 
    fontSize: 18, 
    color: theme.palette.primary.light, 
    margin: 0 
  },
  primaryMargin: { 
    fontSize: 18, 
    color: theme.palette.primary.light, 
    margin: 0 
  },
  bckContent:{
    borderRadius: 2,
    width: 4,
    backgroundColor: theme.palette.primary.main,
    height: "100%",
  },
  colorPrimary: {
    color: theme.palette.primary.light
  },
  btnOpenClass: {
    textTransform: "none",
    color: theme.palette.primary.light,
    fontWeight: 500,
  }
}));

function AgendaItem(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [redirect, setRedirect] = useState(false);
  const [openPatientData, setOpenPatientData] = useState(false);
  const [open, setOpen] = useState(false);

  const [patientInfo, setPatientInfo] = useState("");
  const [cpf, setCpf] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [delayopen, setDelayOpen] = React.useState(false);
  const handleDelayOpen = () => {
    setDelayOpen(true);
  };
  const handleDelayClose = () => {
    setDelayOpen(false);
  };
  const [delayopensuccess, setDelayOpenSuccess] = React.useState(false);
  const handleDelayOpenSuccess = () => {
    setDelayOpen(false);
    setDelayOpenSuccess(true);
  };
  const handleDelayCloseSuccess = () => {
    setDelayOpenSuccess(false);
  };

  const [cancelopen, setCancelOpen] = React.useState(false);
  const handleCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const [cancelopensuccess, setCancelOpenSuccess] = React.useState(false);
  const handleCancelOpenSuccess = () => {
    setCancelOpen(false);
    setCancelOpenSuccess(true);
  };
  const handleCancelCloseSuccess = () => {
    setCancelOpenSuccess(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  const getPatientName = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _patientData = data.schedule_participants.find(
          (x) => x.patient_id
        );

        return _patientData.patient.full_name;
      }
    }
  };

  const getHealthAttendanceProtocol = (data) => {
    if (data && data.schedule_health_attendance) {
      if (Array.isArray(data.schedule_health_attendance)) {
        const _patientData = data.schedule_health_attendance.find(
          (x) => x.health_attendance_id
        );

        return _patientData.health_attendance_id;
      }
    }
  };

  const getTypeOfConsultation = (data) => {
    if (data && data.anamnese_form) {
      return data.anamnese_form.type_service;
    }
  };

  const getProfessionalName = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _professionalData = data.schedule_participants.find(
          (x) => x.document_type === "cns" && x.patient === null
        );

        if (_professionalData) {
          return _professionalData.user.full_name
            ? _professionalData.user.full_name
            : _professionalData.user.social_name;
        }
      }
    }
  };

  const getPatientIdAndPatientInfo = (data) => {
    if (data && data.schedule_participants) {
      if (Array.isArray(data.schedule_participants)) {
        const _patientData = data.schedule_participants.find(
          (x) => x.patient_id
        );

        if (_patientData && _patientData.patient_id) {
          getPatientById(localStorage.getItem("token"), _patientData.patient_id)
            .then((data) => {
              if (data.status) {
                setPatientInfo(data.details.patient);

                // console.log(data.details.patient);
                var { patient_document } = data.details.patient;

                setCpf(
                  getPatientDocuments(patient_document, { description: "CPF" })
                    .value
                );
              }
            })
            .catch(console.error);
        }
      }
    }
  };

  const getScheduleHour = (hour) => {
    if (hour) {
      var d = new Date(hour);

      return format(d, "HH:mm", { locale: ptBR });
    } else {
      return null;
    }
  };

  const getPatientUnity = (data) => {
    if (data) {
      if (Array.isArray(data.medical_unit) && data.medical_unit.length > 0) {
        return data.medical_unit[0].company_name;
      }
    }
  };

  const getHealthAttendanceStatus = (data) => {
    if(data && data.schedule_health_attendance && Array.isArray(data.schedule_health_attendance) && data.schedule_health_attendance[0].health_attendance) {
      switch (data.schedule_health_attendance[0].health_attendance.health_attendance_status_id) {
        case 2:
          return 'Assumido';
        case 3:
          return 'Finalizado';
        case 4:
          return 'Cancelado';
        default:
          return "Aguardando atendimento";
      }
    }
  }

  useEffect(() => {
    getPatientIdAndPatientInfo(props.data);
  }, []);

  if (props.data && props.data.status === 1) {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <img src={MonitorIcon} style={{ height: 32, width: 32 }} />
          <h3 style={{ margin: "20px 0 16px" }}>
            {getPatientName(props.data)}
          </h3>
          <ul style={{ margin: 0, padding: "0 20px", fontSize: 14 }}>
            <li>
              <span className={classes.active}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.active}>Protocolo:</span>{" "}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.active}>Unidade:</span>{" "}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.active}>Profissional:</span>{" "}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.active}>Grupo de paciente:</span>{" "}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(", ")
                : null}
            </li>
            <li>
              <span className={classes.active}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
            </li>
            {/* <li>
              <span className={classes.active}>Motivo do cancelamento:</span>{" "}
            </li> */}
          </ul>
          <DButton
            variant="primary"
            icon={<PersonIcon fontSize="small" />}
            size="sm"
            onClick={ e => {
              if(props.data.schedule_health_attendance && Array.isArray(props.data.schedule_health_attendance) && props.data.schedule_health_attendance[0].health_attendance_id){
                localStorage.setItem('health_attendance_interconsultation', props.data.schedule_health_attendance[0].health_attendance_id);
                setRedirect(true);
              } else {
                toast.error("Erro ao selecionar atendimento.");
              }

            }}
            style={{ margin: "48px auto 0", width: 200 }}
          >
            Atender
          </DButton>
        </Dialog>

        { redirect && <Redirect to="/atendimento-imediato?view=1" /> }

        <Dialog
          onClose={handleDelayClose}
          open={delayopen}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleDelayClose} />
          </IconButton>
          <h3 className={classes.avisoAtraso}>
            Aviso de atraso
          </h3>
          <Typography style={{ margin: "16px 0" }}>
            Atraso na consulta:
            <br />
            <strong>17/02/2021 das 09:00 às 09:10</strong>
            <br />
            <br />O atraso vai passar de 30 minutos? Se sim recomendamos pedir o
            cancelamento da consulta.
          </Typography>
          <div style={{ textAlign: "center" }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleDelayClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton
              variant="primary"
              size="sm"
              onClick={handleDelayOpenSuccess}
            >
              Confirmar
            </DButton>
          </div>
        </Dialog>
        <Dialog
          onClose={handleDelayCloseSuccess}
          open={delayopensuccess}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleDelayCloseSuccess} />
          </IconButton>
          <h3 style={{ fontSize: 18, color: "#2a814e", margin: 0 }}>
            Aviso de atraso enviado
          </h3>
          <Typography style={{ margin: "16px 0" }}>
            Informaremos o paciente, mas pedimos que inicie o atendimento o
            quanto antes.
          </Typography>
        </Dialog>

        <Dialog
          onClose={handleCancelClose}
          open={cancelopen}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleCancelClose} />
          </IconButton>
          <h3 className={classes.primaryMargin}>
            Pedido de cancelamento
          </h3>
          <Typography style={{ margin: "16px 0" }}>
            Cancelar a consulta:
            <br />
            <strong>17/02/2021 das 09:00 às 09:10</strong>
            <br />
            <br />
            Houve algum imprevisto? Cancele a consulta apenas em casos urgentes.
          </Typography>
          <div style={{ textAlign: "center" }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleCancelClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton
              variant="primary"
              size="sm"
              onClick={handleCancelOpenSuccess}
            >
              Confirmar
            </DButton>
          </div>
        </Dialog>
        <Dialog
          onClose={handleCancelCloseSuccess}
          open={cancelopensuccess}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleCancelCloseSuccess} />
          </IconButton>
          <h3 style={{ fontSize: 18, color: "#2a814e", margin: 0 }}>
            Pedido enviado à administração
          </h3>
          <Typography style={{ margin: "16px 0" }}>
            Assim que aprovado, enviaremos uma notificação para te avisar.
          </Typography>
        </Dialog>

        <Dialog
          onClose={ e => setOpenPatientData(false)}
          open={openPatientData}
          classes={{ paper: classes.dialog }}>
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={ e => setOpenPatientData(false)} />
          </IconButton>          
          <PatientInfo patientInfo={patientInfo} />
        </Dialog>

        <Popover
          open={openPop}
          anchorEl={anchorEl}
          onClose={handlePopClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <ul className={classes.consultas_popover}>
            <li
              className={classes.consultas_popoverItem}
              onClick={handleClickOpen}
            >
              <DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} />{" "}
              Visualizar
            </li>
            <li className={ classes.consultas_popoverItem } onClick={ e => setOpenPatientData(true) }>
              <DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} />&nbsp;Informações do paciente
            </li>
            {/* <li
              className={classes.consultas_popoverItem}
              onClick={handleDelayOpen}
            >
              <InfoIcon style={{ fontSize: 16, marginRight: 8 }} /> Aviso de
              atraso
            </li>
            <li
              className={classes.consultas_popoverItem}
              onClick={handleCancelOpen}
              style={{ color: "#d13d2e" }}
            >
              <CancelIcon style={{ fontSize: 16, marginRight: 8 }} /> Pedir
              cancelamento
            </li> */}
          </ul>
        </Popover>

        <div className={classes.item__card}>
          <div
            style={{
              paddingRight: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: theme.palette.primary.main }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: "#c7c7c7",
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300 }}
              className={classes.colorPrimary}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            className={classes.bckContent}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 16,
              flexDirection: "column",
            }}
          >
            <div>
              <Chip
                icon={
                  getTypeOfConsultation(props.data) === "imediato" ? (
                    <Person style={{ color: theme.palette.primary.main, height: 16 }} />
                  ) : (
                    <PeopleIcon style={{ color: theme.palette.primary.main, height: 16 }} />
                  )
                }
                label={
                  getTypeOfConsultation(props.data) === "imediato"
                    ? "Imediata"
                    : "Monitoramento"
                }
                className={classes.item__chip}
              />

              <Chip label={getHealthAttendanceStatus(props.data)} className={classes.item__chip_blocked} />

              {patientInfo &&
                Array.isArray(patientInfo.patient_has_group) &&
                patientInfo.patient_has_group.map((item, key) => {
                  return (
                    <Chip
                      key={key}
                      label={item.group.name}
                      className={classes.item__chip}
                    />
                  );
                })}
            </div>
            <Typography
              style={{
                color: "#272727",
                fontWeight: 300,
                marginTop: 12,
                fontSize: 16,
              }}
            >
              {getPatientName(props.data)}&nbsp;&nbsp;
              <strong style={{ fontWeight: 400 }}>Protocolo:</strong>&nbsp;
              {getHealthAttendanceProtocol(props.data)}
            </Typography>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <Button
              startIcon={<img src={DoctorIcon} style={{ height: 16, color: theme.palette.primary.main }} />}
              className={classes.btnOpenClass}
              onClick={handleClickOpen}
            >
              Atender
            </Button>
            <IconButton
              style={{ color: theme.palette.primary.main, marginTop: -6, marginRight: -12 }}
              onClick={handlePopOpen}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      </>
    );
  } else if (props.data && props.data.status === 2) {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <AssignmentIcon style={{ color: "#168183", height: 36, width: 34 }} />
          <h3 style={{ margin: "20px 0 16px" }}>
            {getPatientName(props.data)}
          </h3>
          <ul style={{ margin: 0, padding: "0 20px", fontSize: 14 }}>
            <li>
              <span className={classes.success}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.success}>Protocolo:</span>{" "}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.success}>Unidade:</span>{" "}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.success}>Profissional:</span>{" "}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.success}>Grupo de paciente:</span>{" "}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(", ")
                : null}
            </li>
            <li>
              <span className={classes.success}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
              <br />
              <br />
            </li>
            {/* <li>
              Paciente relatou fortes dores abdominais com sangramento periódico
              <br />
              <br />
            </li>
            <li>
              <span className={classes.success}>Diagnóstico:</span> trabalho de
              parto com risco
            </li>
            <li>
              <span className={classes.success}>Prescrição:</span> 24h de
              observação em completo repouso
            </li> */}
          </ul>
        </Dialog>

        <div
          className={classes.item__card}
          onClick={handleClickOpen}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              paddingRight: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: "#168183" }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: "#c7c7c7",
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 2,
              width: 4,
              backgroundColor: "#168183",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 16,
              flexDirection: "column",
            }}
          >
            <div>
              <Chip
                icon={
                  <AssignmentIcon style={{ color: "#168183", height: 16 }} />
                }
                label="Ver Prontuário"
                className={classes.item__chip_success}
              />
            </div>
            <Typography
              style={{
                color: "#272727",
                fontWeight: 300,
                marginTop: 12,
                fontSize: 16,
              }}
            >
              {getPatientName(props.data)} &nbsp;&nbsp;{" "}
              <strong style={{ fontWeight: 400 }}>Protocolo:</strong>{" "}
              {getHealthAttendanceProtocol(props.data)}
            </Typography>
          </div>
        </div>
      </>
    );
  } else if (props.status == "cancelled") {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog__success }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <CancelIcon style={{ color: "#E23F5D", height: 36, width: 34 }} />
          <h3 style={{ margin: "20px 0 16px" }}>
            {getPatientName(props.data)}
          </h3>
          <ul style={{ margin: 0, padding: "0 20px", fontSize: 14 }}>
            <li>
              <span className={classes.cancel}>CPF:</span> {cpf}
            </li>
            <li>
              <span className={classes.cancel}>Protocolo:</span>{" "}
              {getHealthAttendanceProtocol(props.data)}
            </li>
            <li>
              <span className={classes.cancel}>Unidade:</span>{" "}
              {patientInfo && getPatientUnity(patientInfo)}
            </li>
            <li>
              <span className={classes.cancel}>Profissional:</span>{" "}
              {getProfessionalName(props.data)}
            </li>
            <li>
              <span className={classes.cancel}>Grupo de paciente:</span>{" "}
              {patientInfo && Array.isArray(patientInfo.patient_has_group)
                ? patientInfo.patient_has_group
                    .map((x) => x.group.name)
                    .join(", ")
                : null}
            </li>
            <li>
              <span className={classes.cancel}>Linha de cuidado:</span>
              {patientInfo && Array.isArray(patientInfo.patient_line_care)
                ? patientInfo.patient_line_care.map((x) => x.line_care.name)
                : null}
            </li>
            {/* <li>
              <span className={classes.cancel}>Motivo do cancelamento:</span>{" "}
              paciente não pôde comparecer à consulta
            </li> */}
          </ul>
        </Dialog>

        <div
          className={classes.item__card}
          onClick={handleClickOpen}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              paddingRight: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: "#E23F5D" }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: "#c7c7c7",
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 2,
              width: 4,
              backgroundColor: "#E23F5D",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 16,
              flexDirection: "column",
            }}
          >
            <div>
              <Chip
                icon={
                  <CancelIcon
                    fontSize="small"
                    style={{ color: "#E23F5D", height: 16 }}
                  />
                }
                label="Ver Prontuário"
                className={classes.item__chip_cancelled}
              />
            </div>
            <Typography
              style={{
                color: "#272727",
                fontWeight: 300,
                marginTop: 12,
                fontSize: 16,
              }}
            >
              {getPatientName(props.data)} &nbsp;&nbsp;{" "}
              <strong style={{ fontWeight: 400 }}>Protocolo:</strong>{" "}
              {getHealthAttendanceProtocol(props.data)}              
            </Typography>
          </div>
        </div>
      </>
    );
  } else if (props.data && props.data.status === 3) {
    return (
      <div className={classes.item__card} style={{ opacity: 0.5 }}>
        <div
          style={{
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: "#1616B5" }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: "#c7c7c7",
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div
          className={classes.bckContent}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 16,
            flexDirection: "column",
          }}
        >
          <div>
             {/*
            <Chip
              icon={<img src={MonitorIcon} style={{ height: 12 }} />}
              label="Monitoramento"
              className={classes.item__chip}
            />
            <Chip
              icon={
                <PregnantWomanIcon
                  fontSize="small"
                  style={{ color: "#1616b5" }}
                />
              }
              label="Gravidez"
              className={classes.item__chip}
            /> */}
          </div>
          <Typography
            style={{
              color: "#272727",
              fontWeight: 300,
              marginTop: 12,
              fontSize: 16,
            }}
          >
            {getPatientName(props.data)} &nbsp;&nbsp;{" "}
            <strong style={{ fontWeight: 400 }}>Protocolo:</strong>{" "}
            {getHealthAttendanceProtocol(props.data)}
          </Typography>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <IconButton
            style={{ color: "#1616b5", marginTop: -6, marginRight: -12 }}
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
    );
  } else if (props.status == "onduty") {
    return (
      <div className={classes.item__card_onduty}>
        <div
          style={{
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: "#1616B5" }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: "#c7c7c7",
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div
          className={classes.bckContent}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 16,
            flexDirection: "column",
          }}
        >
          <div>
            <Chip
              icon={<AccessTimeIcon style={{ color: "#fff", height: 16 }} />}
              label="Plantão"
              className={classes.item__chip_onduty}
            />
          </div>
        </div>
      </div>
    );
  } else if (props.status == "rest") {
    return (
      <div className={classes.item__card_rest}>
        <div
          style={{
            paddingRight: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ fontSize: 18, fontWeight: 700, color: "#1616B5" }}
          >
            {getScheduleHour(props.data.start)}
          </Typography>
          <div
            style={{
              height: 12,
              width: 1,
              backgroundColor: "#c7c7c7",
              marginBottom: 4,
            }}
          ></div>
          <Typography
            style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
          >
            {getScheduleHour(props.data.end)}
          </Typography>
        </div>
        <div
          className={classes.bckContent}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: 16,
            flexDirection: "column",
          }}
        >
          <div>
            <Chip
              icon={<FreeBreakfastIcon style={{ color: "#fff", height: 16 }} />}
              label="Período de descanso"
              className={classes.item__chip_onduty}
            />
          </div>
        </div>
      </div>
    );
  } else if (props.status == "blocked") {
    return (
      <>
        <Dialog
          onClose={handleClose}
          open={open}
          classes={{ paper: classes.dialog }}
        >
          <IconButton className={classes.close_button}>
            <CloseIcon onClick={handleClose} />
          </IconButton>
          <h3 className={classes.primaryMargin}>
            Pedido de desbloqueio
          </h3>
          <Typography style={{ margin: "16px 0" }}>
            Os horários ficarão disponíveis para os pacientes novamente
          </Typography>
          <div style={{ textAlign: "center" }}>
            <DButton
              variant="secondary"
              size="sm"
              onClick={handleClose}
              style={{ marginRight: 16 }}
            >
              Voltar
            </DButton>
            <DButton variant="primary" size="sm" onClick={handleClose}>
              Confirmar
            </DButton>
          </div>
        </Dialog>

        <div className={classes.item__card_blocked}>
          <div
            style={{
              paddingRight: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: 18, fontWeight: 700, color: "#4f4f4f" }}
            >
              {getScheduleHour(props.data.start)}
            </Typography>
            <div
              style={{
                height: 12,
                width: 1,
                backgroundColor: "#c7c7c7",
                marginBottom: 4,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, fontWeight: 300, color: "#4f4f4f" }}
            >
              {getScheduleHour(props.data.end)}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 2,
              width: 4,
              backgroundColor: "#4f4f4f",
              height: "100%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 16,
              flexDirection: "column",
            }}
          >
            <div>
              <Chip
                icon={<BlockIcon style={{ color: "#fff", height: 16 }} />}
                label="Horario bloqueado"
                className={classes.item__chip_blocked}
              />
              <Button
                style={{ textTransform: "none", color: "#1616b5" }}
                onClick={handleClickOpen}
              >
                Pedir desbloqueio
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
}

export default AgendaItem;
