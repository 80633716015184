import env from 'react-dotenv';

function getAllMedicalSpecialties(token) {
    return fetch(`${env.API_URL}/medicalSpeciality?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getMedicalSpecialtyById(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createMedicalSpecialty(token, dados) {
    return fetch(`${env.API_URL}/medicalSpeciality?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateMedicalSpecialty(token, dados, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateStatusMedicalSpecialty(token, specialtyId, status) {
    // status: {active: 1} || {active: 0}

    return fetch(`${env.API_URL}/medicalSpeciality/${specialtyId}?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'PATCH',
        body: JSON.stringify(status)
    }).then(res => res.json());
}

function deleteMedicalSpecialty(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'delete'
    }).then(res => res.json());
}

function getMedicalSpecialtyPermission(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/list-permission?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function enableMedicalSpecialtyPermissionCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-enabled?token=${ token }`, {
        method: 'put'
    }).then(res => res.json());
}

function disableMedicalSpecialtyPermissionCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-enabled?token=${ token }`, {
        method: 'delete'
    }).then(res => res.json());
}

function enableMedicalSpecialtyPermissionSequentialCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/sequential-call?token=${ token }`, {
        method: 'put'
    }).then(res => res.json());
}

function disableMedicalSpecialtyPermissionSequentialCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/sequential-call?token=${ token }`, {
        method: 'delete'
    }).then(res => res.json());
}

function enableMedicalSpecialtyPermissionGeneralGroup(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-in-general-group?token=${ token }`, {
        method: 'put'
    }).then(res => res.json());
}

function disableMedicalSpecialtyPermissionGeneralGroup(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-in-general-group?token=${ token }`, {
        method: 'delete'
    }).then(res => res.json());
}

function enableMedicalSpecialtyPermissionTotalGroup(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-in-total-group?token=${ token }`, {
        method: 'put'
    }).then(res => res.json());
}

function disableMedicalSpecialtyPermissionTotalGroup(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/call-in-total-group?token=${ token }`, {
        method: 'delete'
    }).then(res => res.json());
}

function enableMedicalSpecialtyPermissionDirectCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/direct-call?token=${ token }`, {
        method: 'put'
    }).then(res => res.json());
}

function disableMedicalSpecialtyPermissionDirectCall(token, id) {
    return fetch(`${env.API_URL}/medicalSpeciality/${ id }/direct-call?token=${ token }`, {
        method: 'delete'
    }).then(res => res.json());
}

export { 
    getAllMedicalSpecialties,
    getMedicalSpecialtyById,
    createMedicalSpecialty,
    updateMedicalSpecialty,
    deleteMedicalSpecialty,
    updateStatusMedicalSpecialty,
    getMedicalSpecialtyPermission,
    enableMedicalSpecialtyPermissionCall,
    disableMedicalSpecialtyPermissionCall,
    enableMedicalSpecialtyPermissionSequentialCall,
    disableMedicalSpecialtyPermissionSequentialCall,
    enableMedicalSpecialtyPermissionGeneralGroup,
    disableMedicalSpecialtyPermissionGeneralGroup,
    enableMedicalSpecialtyPermissionTotalGroup,
    disableMedicalSpecialtyPermissionTotalGroup,
    enableMedicalSpecialtyPermissionDirectCall,
    disableMedicalSpecialtyPermissionDirectCall
}