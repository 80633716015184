import React from "react";
import clsx from "clsx";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { usePatient } from "../../../../context/usePatient";
import { CircularProgress } from '@material-ui/core';
import useDebounce from "../../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
    margin: "0",
    paddingRight: 0
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: 256,
  },
  colorIcon: {
    color: `${theme.palette.primary.light} !important`
  }
}));

export function SolicitacaoFiltro() {
  const classes = useStyles();
  const {getAttendancesByFilter, filteredAttendances, getHealthAttendances} = usePatient();
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const debouncedValue = useDebounce(search, 500);

  React.useEffect(() => {
    if (debouncedValue) {
      setLoadingSearch(true);
      getAttendancesByFilter({search: debouncedValue}, true);
    } else {
      getHealthAttendances(true);
    }
  }, [debouncedValue]);

  React.useEffect(() => {
    setLoadingSearch(false);
  }, [filteredAttendances]);

  return (
    <FormControl
      className={clsx(classes.textField)}
      variant="outlined"
      size="small"
    >
      <OutlinedInput
        id="outlined-adornment-password"
        type="text"
        placeholder="Buscar por nome, e-mail ou CPF"
        value={search}
        onChange={e => setSearch(e.target.value)}
        classes = {{
          root: classes.root
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              style={{ padding: 0, marginRight: 5 }}
              className={classes.colorIcon}
            >
              {loadingSearch ? <CircularProgress size={15} color="inherit" /> : <SearchIcon className={classes.colorIcon} />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={0}
      />
    </FormControl>
  );
}
