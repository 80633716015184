import {
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography
} from "@material-ui/core";
import MedicalUnitCard from "./MedicalUnitCard";
import {useEffect, useState} from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import DDropDownSimple from "../../../shared/DDropDownSimple/DDropDownSimple";
import DDropdownTwo from "../../../shared/DDropdownTwo";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckboxList from '../../../../component/shared/CheckboxList';
import {getAllUnidades} from "../../../../api/unidades";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: `${theme.palette.primary.light} !important`,
    },
    textField: {
        marginLeft: '-5px',
        marginTop: '-10px'
    },
    labelFilter: {
        paddingTop: 4
    },
    dropdown: {
        minWidth: "60%",
        marginTop: 4
    }
}))

const StyledOutlinedInput = withStyles((theme) => ({
    root: {
        background: '#FFF',
        minWidth: 400,
        marginBottom: 30
    }
}))(OutlinedInput)

function UnidadeArvore(props){

    const classes = useStyles()

    const [search, setSearch] = useState('')
    const [tipo, setTipo] = useState('todas')
    const [filtroMedicalUnits, setFiltroMedicalUnits] = useState([]);
    const [administrativeMedicalUnits, setAdministrativeMedicalUnits] = useState([]);
    const [assistanceMedicalUnits, setAssistanceMedicalUnits] = useState([]);

    useEffect(() => {
        console.log(props.unidades);

        getAllUnidades(localStorage.getItem("token")).then((res) => {

            if (!res.error && res.length > 0) {
              
                setAdministrativeMedicalUnits(
                    res.filter(
                      (item) => item.medical_unit_type === "Administrativa"
                    )
                  );

                setAssistanceMedicalUnits(
                    res.filter(
                      (item) => item.medical_unit_type === "Assistencial"
                    )
                );

            }

          });

    }, [props.unidades])

    function getChild(children) {

        console.log(children);

        return children.map(function(item, index){
            return <MedicalUnitCard viewUnidade={ unidade => props.viewUnidade(unidade) } key={ `medical-unit-child-${item.id}-${index}` } hasChild lastItem={ children[index + 1] === undefined } unidade={item}>
                { item.children.length > 0 && getChild(item.children) }
            </MedicalUnitCard>
        });
    }

    return (
        <Container>
            <Grid container alignItems="baseline" justifyContent="space-between">
                <Grid item>
                    <FormControl className={clsx(classes.textField)} variant="outlined" size="small" autoComplete="off">
                        <StyledOutlinedInput
                            id="filter-unidades-administrativas"
                            type="text"
                            placeholder="Buscar por nome ou CNPJ"
                            onChange={e => setSearch(e.target.value) }
                            onBlur={ e => props.onSearch({search: e.target.value, tipo}) }
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton edge="start">
                                        <SearchIcon classes={{ root: classes.icon }} color="primary" />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={0}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={6} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Typography
                        variant="body1"
                        className={ `text-regular text-st ${ classes.labelFilter }` }
                        style={{ marginRight: 16 }}
                    >
                       Mostrar unidades:
                    </Typography>
                    <div className={ classes.dropdown }>

                        <DDropdownTwo id="filtro"
                            label={ null }
                            placeholder="filtro"
                            onChange={ option => {
                                setTipo(option)
                                props.onSearch({search, medical_unit_id: option})
                            } }
                            value={ filtroMedicalUnits }
                            options={ assistanceMedicalUnits.map(function(item) { return { id: item.id, value: item.company_name } }) }
                            optionsChild={ administrativeMedicalUnits.map(function(item) { return { id: item.id, value: item.company_name } }) }
                            error={ null }
                            helperText={ null } />

                    </div>
                    {/* <div className={ classes.dropdown }>

                         <DDropDownSimple
                            id="filtro"
                            name="filtro"
                            placeholder="filtro"
                            notLabel
                            value={ tipo }
                            onChange={ option => {
                                setTipo(option)
                                props.onSearch({search, tipo: option})
                            } }
                            options={ [
                                { id: 'todas', value: 'Todas' },
                                { id: 'assistencial', value: 'Assistencial' },
                                { id: 'adminstrativa', value: 'Adminstrativa' }
                            ] }
                        /> 

                    </div> */}
                </Grid>
            </Grid>
            { props.loading ?
                <>
                    <CircularProgress size={ 15 } color="inherit" /> carregando...                    
                </>
                :
                <>
                    { props.unidades.length > 0 ?
                        <>
                            { props.unidades.map(function(item, index){
                                return  <MedicalUnitCard viewUnidade={ unidade => props.viewUnidade(unidade) } key={ `medical-unit-${ index }` } unidade={item}>
                                            { getChild(item.children) }
                                        </MedicalUnitCard>
                            }) }
                        </>
                        :
                        ''
                    }
                </>
            }                        
        </Container>
    )
}

export default UnidadeArvore;