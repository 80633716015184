import axios from 'axios';
import env from 'react-dotenv';

function getFormByHealthAttendanceId(token, id) {
    return fetch(`${env.API_URL}/anamnese-form/health-attendance-type/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createAnamnesePreForm(token, dados) {
    return fetch(`${env.API_URL}/anamnese-pre-form?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function anamneseFormByLineCare(token, dados) {
    return fetch(`${env.API_URL}/anamnese-form/by-line-care?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export function anamneseFormByFilter(token, dados) {
    return fetch(`${env.API_URL}/anamnese-form/filter?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export function createAnamnese(token, dados){
    return axios.post(`${env.API_URL}/anamnese?token=${token}`, dados)
        .then(res => res.data)

    // body:
    // {
    //  health_attendance_type_id: number,
    //  active: boolean
    // }

}

export function anamneseFormAnswer(token, anamneseId, dados){
    return axios.patch(`${env.API_URL}/anamnese-form-answer/${anamneseId}?token=${token}`,
    dados)
    .then(res => res.data)

    // {
    //     "health_attendance_id": 1,
    //     "discharge_id": 1,
    //     "data": {
    //         "id": 1,
    //         "value": "Teste"
    //     }
    // }
}

export function getFormAnswer(token, formanswerid){
    return axios.get(`${env.API_URL_SERVICE}/form/form/form-answer/${formanswerid}?token=${token}`).then(res => res.data);
}

export function getFormConditionals(token, formId){
    return axios.get(`${env.API_URL}/anamnese-form-answer/${formId}/show-all-conditionals?token=${token}`)
              .then(res => res.data)
  }

export function anamneseFormHistory(token, anamneseId, dados) {
    return axios.put(`${env.API_URL}/anamnese-form/${anamneseId}?token=${token}`, {
        anamnese_id: anamneseId,
        data: dados
    })
        .then(res => res.data)

}

export function anamneseFormDischarge(token, anamneseFormId, dados){
    return axios.post(`${env.API_URL}/anamnese-form/health-attendance/${anamneseFormId}/discharge?token=${token}`,
    dados)
    .then(res => res.data)
}

export function getAnamneseFormAnswer(token, anamneseFormId){
    return axios.get(`${env.API_URL}/anamnese-form-answer/${anamneseFormId}/show?token=${token}`)
              .then(res => res.data)
}

export function getAnamneseFormInterconsultation(token, medical_speciality_id) 
{
    return axios.post(`${env.API_URL}/anamnese-form/anamnese-for-interconsult?token=${token}`, {
        medical_speciality_id
    }).then(res => res.data)
}

export { getFormByHealthAttendanceId, createAnamnesePreForm, anamneseFormByLineCare }
