import { useState, useEffect } from "react";
import { format } from 'date-fns';
import { ptBR } from "date-fns/locale";
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import DescriptionIcon from '@material-ui/icons/Description';
import UpdateIcon from '@material-ui/icons/Update';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
    consultas_titulo: {
      fontWeight: 'bold !important',
      fontSize: 16,
      lineHeight: "24px",
      color: theme.palette.primary.light,
      padding: "0 0 12px 0",
      margin: 0
    },
    consultas_subtitulo: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      padding: "12px 0",
      margin: 0
    },
    consultas_text: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px"
    },
    consultas_purpleCard: {
      width: "100%",
      height: 62,
      borderRadius: 4,
      background: "#9300D926",
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      position: "relative"
    },
    consultas_purpleBar: {
      width: 8,
      height: "100%",
      borderRadius: 4,
      background: "#9300D9",
      marginRight: 8
    },
    consultas_greenCard: {
      width: "100%",
      height: 62,
      borderRadius: 4,
      background: "#3C757926",
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      position: "relative"
    },
    consultas_greenBar: {
      width: 8,
      height: "100%",
      borderRadius: 4,
      background: "#3C7579",
      marginRight: 8
    },
    consultas_brownCard: {
      width: "100%",
      height: 62,
      borderRadius: 4,
      background: "#C5794226",
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      position: "relative"
    },
    consultas_brownBar: {
      width: 8,
      height: "100%",
      borderRadius: 4,
      background: "#C57942",
      marginRight: 8
    },
    consultas_button: {
      width: 30,
      height: "100%",
      borderRadius: 4,
      color: "#fff",
      background: "#4A4AE9",
      position: 'absolute',
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      right: 0,
      fontSize: 20,
      cursor: "pointer"
    },
    consultas_popover: {
      listStyle: "none",
      padding: 0,
      margin: "0 16px"
    },
    consultas_popoverItem: {
      listStyle: "none",
      margin: 0,
      padding: "12px 0",
      fontSize: 12,
      color: "#272727",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      cursor: "pointer"
    }
  
  }));

function CardNextHealthAttendance(props)
{

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDoctorName = () => {

        if(props.healthAttendance.schedule_health_attendance) {
            if(props.healthAttendance.schedule_health_attendance.schedule.schedule_participants) {
              var user = props.healthAttendance.schedule_health_attendance.schedule.schedule_participants.find(schedule_participants => schedule_participants.user).user;
              return `${ user.social_name ? user.social_name : user.full_name }`;
            } else {
              return "Não identificado";
            }
        } else {
            if(props.healthAttendance.user_requesting && props.healthAttendance.user_requesting.social_name) {
                return props.healthAttendance.user_requesting.social_name;
            } else {
                return props.healthAttendance.user_requesting.full_name;
            }
        }
    }

    const getDoctorSpeciality = () => {

      if(props.healthAttendance.schedule_health_attendance) {
          if(props.healthAttendance.schedule_health_attendance.schedule.schedule_participants) {
            var user = props.healthAttendance.schedule_health_attendance.schedule.schedule_participants.find(schedule_participants => schedule_participants.user).user;
            return user.user_medical_speciality.map(user_medical_speciality => user_medical_speciality.medical_speciality.description);
          } else {
            return "Não identificado";
          }
      } else {
          if(props.healthAttendance.user_requesting) {
              return props.healthAttendance.user_requesting.user_medical_speciality.map(user_medical_speciality => user_medical_speciality.medical_speciality.description);
          } else {
              return "Não identificado";
          }
      }
  }

    useEffect(() => {
        setOpen(Boolean(anchorEl));
    }, [anchorEl])

    return <>
        { props.healthAttendance ? 
            <>
                { props.title && <h6 className={ classes.consultas_subtitulo }>{ format(new Date(props.healthAttendance.requested_at), "dd 'de' MMMM 'de' yyyy", {locale: ptBR}) }</h6> }
                <div className={ classes.consultas_greenCard }>
                    <div className={ classes.consultas_greenBar }></div>
                    <p className={ classes.consultas_text } style={{ marginRight:16 }}>{ format(new Date(props.healthAttendance.requested_at), "h:mm") }</p>
                    <p className={ classes.consultas_text }>
                        { getDoctorSpeciality() }
                        <span style={{ fontWeight:400, display:"block" }}>
                          { getDoctorName() }
                        </span>     
                    </p>
                    <div className={ classes.consultas_button } onClick={ handleClick }><p style={{ marginTop: "10px" }}>...</p></div>
                </div>
                <Popover
                    id={props.healthAttendance.id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <ul className={ classes.consultas_popover }>
                        <li className={ classes.consultas_popoverItem } onClick={ e => props.onView() }><DescriptionIcon style={{ fontSize: 16, marginRight: 8 }} /> Visualizar dados da consulta</li>
                        {/* <li className={ classes.consultas_popoverItem }><UpdateIcon style={{ fontSize: 16, marginRight: 8 }} /> Reagendar consulta</li> */}
                        <li className={ classes.consultas_popoverItem } style={{ color: "#d13d2e" }} onClick={ e => props.onCancel() }><CancelIcon style={{ fontSize: 16, marginRight: 8 }} /> Cancelar consulta</li>
                    </ul>
                </Popover>
            </> : <></> }
    </>
}

CardNextHealthAttendance.defaultProps = {
    title: false,
    healthAttendance: null,
    onCancel: function() {
        console.log('onCancel');
    },
    onView: function() {
        console.log('onView');
    }
}

export default CardNextHealthAttendance;