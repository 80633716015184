import axios from 'axios';
import env from 'react-dotenv'

export const api = axios.create({
  baseURL: 'http://localhost:3004/',
});

const getParams = (params) => {
  if(typeof params === 'object'){
      params = Object.keys(params).map((key, index) => {
          return {key: key, value: Object.values(params)[index]}
      });

      return params;
  }
}

export function healthAttendanceList(token, search){
  return axios.get(`${env.API_URL}/healthAttendance?search=${search}&token=${token}`)
            .then(res => res.data)

  // {{cloud}}/api/healthAttendance?patient_id=1&user_id=&discharge_id=&anamnese_id=&rating_health_id=1,2,3&requested_at=05/11/2021&health_attendance_status_id=1,2,3&search=193&token={{token}}
}

export function getAllHealthAttendance(token){
  return axios.get(`${env.API_URL}/healthAttendance?token=${token}`)
            .then(res => res.data)
}

export function getAllHealthAttendanceRequest(token, requested_at, search){
  return axios.get(`${env.API_URL}/healthAttendance/userrequesting?token=${token}&requested_at=${requested_at}&search=${search}`).then(res => res.data)
}

export function getHealthAttendanceByPage(token, page){
  return axios.get(`${env.API_URL}/healthAttendance?page=${page}&token=${token}`).then(res => res.data)
}

export function getHealthAttendanceByPageFilter(token, page, filter){

  // let body = {
  //   line_care: filter.linhaDeCuidado
  // };


  return axios.post(`${env.API_URL}/healthAttendance/filter?page=${page}&token=${token}`, filter).then(res => res.data)
}

export function getHealthAttendanceById(token, patientId, page){
  var url = '';

  if (page) {
    url = `${env.API_URL}/healthAttendance?patient_id=${patientId}&page=${page}&token=${token}`
  } else {
    url = `${env.API_URL}/healthAttendance?patient_id=${patientId}&token=${token}`
  }

  return axios.get(url)
            .then(res => res.data)
}

export function getByHealthAttendanceId(token, healthAttendanceId) {
  return axios.get(`${env.API_URL}/healthAttendance/${healthAttendanceId}?token=${token}`)
            .then(res => res.data)
}

export function assumirAtendimento(token, healthAttendanceId) {
  return axios.patch(`${env.API_URL}/healthAttendance/${healthAttendanceId}/attendance?token=${token}`).then(res => res.data)
}

export function abdicarAtendimento(token, healthAttendanceId, body = null) {
  if(body)
    return axios.patch(`${env.API_URL}/healthAttendance/${healthAttendanceId}/abdicate-attendance?token=${token}`, body).then(res => res.data)
  else
    return axios.patch(`${env.API_URL}/healthAttendance/${healthAttendanceId}/abdicate-attendance?token=${token}`).then(res => res.data)
}

export function getHealthAttendance(token, paramsFilter){ // ParamsFilter example: {patient_id: 3, user_id: 2, requested_at='05/11/2021', seach="josé de tal"}

  var uri = new URL(`${env.API_URL}/healthAttendance`);

  if(paramsFilter && paramsFilter.id){
    return axios.get(`${env.API_URL}/healthAttendance/${paramsFilter.id}?token=${token}`)
            .then(res => res.data)
  }

  var params = getParams(paramsFilter)

  if(params && Array.isArray(params)){
    params.forEach(param => {
      uri.searchParams.append(param.key, param.value)
    })
  }

  uri.searchParams.append('token', token);

  return axios.get(uri)
            .then(res => res.data);
}

export function storeInterconsultationByHealthAttendance(token, healthAttendanceId, anamneseFormId, userInviteId) {
  return axios.post(`${env.API_URL}/healthAttendance/health-attendance-interconsult?token=${token}`, {
    "health_attendance_id": healthAttendanceId,
    "anamnese_form_id": anamneseFormId,
    "user_invite_id": userInviteId
  }).then(res => res.data)
}

export function getInterconsultationInvite(token) {
  return axios.get(`${env.API_URL}/healthAttendance/health-attendance-interconsult/invite?token=${token}`).then(res => res.data)
}

export function assumirInterconsultation(token, healthAttendanceInviteId) {
  return axios.put(`${env.API_URL}/healthAttendance/health-attendance-interconsult/assumir/${healthAttendanceInviteId}?token=${token}`).then(res => res.data)
}

export function abdicarInterconsultation(token, healthAttendanceInviteId) {
  return axios.put(`${env.API_URL}/healthAttendance/health-attendance-interconsult/abdicar/${healthAttendanceInviteId}?token=${token}`).then(res => res.data)
}