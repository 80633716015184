import { useState, useEffect } from "react";

import { Box, Grid, Button,
    Checkbox,
    FormControlLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { ptBR } from "date-fns/locale";
import { toast } from "react-toastify";

import DInput from "../../../../../../shared/DInput";
import DDateInput from '../../../../../../shared/DDateInput';
import DAutoComplete from "../../../../../../shared/DAutoComplete";

import useError from "./../../../../../../../hooks/useError";
import { required, requiredOptionalTwoName, validaRG } from "./../../../../../../../helpers/validacoes";
import { mascaraRG } from "./../../../../../../../helpers/formatacao";
import { updateDocument, getPatientById, updatePatient } from "./../../../../../../../api/patient";

import orgaoEmissorCode from "./../../../../../../../assets/dados/orgaoEmissor.json";

const useStyles = makeStyles((theme) => ({
    dados_titulo: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "24px",
        color: theme.palette.primary.light,
        margin: 0
    },
    dados_editButton: {
        color: theme.palette.primary.light,
        textTransform:'none',
        fontWeight: 400
    },
    dados_saveButton: {
        color: theme.palette.primary.light,
        textTransform: "none",
        fontWeight: 400
    }
}));

function IdentidadeNaturalizado(props)
{
    const classes = useStyles();

    const [editar, setEditar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState(props.patient);

    useEffect(() => {
        getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
            if(res.status) {
                setPatient(res.details.patient);
            }
        }).catch(console.error)
    }, []);

    const countrys = props.dominios.country.map((item) => {
        return { id: item.id, value: item.description };
    });

    function formatDate(date) {
        if(date && date != '') {
            var day   = date.substring(0,2);
            var month = date.substring(3,5);
            var year  = date.substring(6);
            
            return [year, month, day].join('-');
        } else {
            return ''
        }
    }

    const renderPaisNaturalizado = naturalizado => {
        if(naturalizado && naturalizado.value && naturalizado.value.pais_nascimento && naturalizado.value.pais_nascimento.value) {
            return naturalizado.value.pais_nascimento.value.description;
        } else {
            return '-';
        }
    }

    const View = (props) => {
        return (
            <>
                { props.patient.naturalizado &&                
                    <Grid container>
                        <Grid item md={6}>
                            <h5 className={ classes.dados_titulo }>Identidade</h5>
                        </Grid>
                        <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                            <Button variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton } onClick={e => setEditar(true) }>
                                <EditIcon fontSize="small" />
                                <Box ml={1}>Editar</Box>
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            <strong>Data de naturalização:</strong> { props.patient.naturalizado.value.data_naturalizacao.value.view }
                        </Grid>
                        <Grid item xs={6}>
                            <strong>Pais de nascimento:</strong> { renderPaisNaturalizado(props.patient.naturalizado)}
                        </Grid>
                        <Grid item xs={12}>
                            <strong>Nome da mãe:</strong> { props.patient.mother_know ? (props.patient.mother_name || null) : "Desconhecida" }
                        </Grid>
                    </Grid>
                }
            </>
        )
    }

    const Edit = (props) => {

        const [identidadeDataNaturalizacao, setIdentidadeDataNaturalizacao] = useState("");
        const [dataNaturalizacaoValida, setDataNaturalizacaoValida]         = useState(true);
        const [identidadePaisNascimento, setIdentidadePaisNascimento]       = useState(props.patient.naturalizado.value.pais_nascimento.value ? props.patient.naturalizado.value.pais_nascimento.value.id : null);
        const [nomeMae, setNomeMae]                                         = useState("");
        const [maeDesconhecida, setMaeDesconhecida]                         = useState(!props.patient.mother_know);

        const validateDate = {
            dataValidadePassaporte: () => {
                if (dataNaturalizacaoValida) {
                    var [day, month, year] = identidadeDataNaturalizacao.split("/");
    
                    var _identidadeDataNaturalizacao = new Date(`${year}-${month}-${day}`);
    
                    if(_identidadeDataNaturalizacao > new Date()) {
                        return "Data não pode ser maior que a data atual."
                    }
    
                    if (day && month && year) {
                        return undefined;
                    } else {
                        return "Data inválida.";
                    }
                } else {
                    return "Data inválida.";
                }
            }
        }

        const [error, validarFormulario, setErrors] = useError({
            identidadeDataNaturalizacao: validateDate.dataValidadePassaporte,
            identidadePaisNascimento: required,
            nomeMae: requiredOptionalTwoName
        })

        useEffect(() => {
            if(props.patient.naturalizado) {
                setIdentidadeDataNaturalizacao(props.patient.naturalizado.value.data_naturalizacao.value.view)
                setIdentidadePaisNascimento(props.patient.naturalizado.value.pais_nascimento.value ? props.patient.naturalizado.value.pais_nascimento.value.id : null)
                setNomeMae(props.patient.mother_name)   
                setMaeDesconhecida(props.patient.mother_know)    
            }
        }, [])

        return (
            <form onSubmit={ form => {
                form.preventDefault();
                if(validarFormulario({
                    identidadeDataNaturalizacao,
                    identidadePaisNascimento,
                    nomeMae
                })){
                    setLoading(true);

                    const fields = [
                        {
                            id: props.patient.naturalizado.value.pais_nascimento.id,
                            value: identidadePaisNascimento
                        },
                        {
                            id: props.patient.naturalizado.value.data_naturalizacao.id,
                            value: formatDate(identidadeDataNaturalizacao)
                        }
                    ];

                    getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                        if(res.status) {
                            var _patient = res.details.patient;

                            if(_patient && typeof _patient === 'object') {

                                _patient.mother_know = maeDesconhecida ? false : true;

                                updatePatient(localStorage.getItem("token"), {..._patient}, props.patient.id)
                                    .then(() => {
                                        updateDocument(localStorage.getItem('token'), { mother_name: nomeMae ? nomeMae : null, fields }, props.patient.naturalizado.id).then(res => {

                                            if(res.status) {
                                                toast.success('Identidade atualizada com sucesso.')
                                                getPatientById(localStorage.getItem('token'), props.patient.id).then(res => {
                                                    if(res.status) {
                                                        setPatient(res.details.patient);
                                                    }
                                                }).catch(console.error)
                                            } else {
                                                toast.error("Erro não esperado ao atualizar a identidade.")
                                            }

                                            setEditar(false);
                                            setLoading(false);
                                        }).catch(err => {
                                            console.log(err);
                                            toast.error("Erro não esperado ao atualizar a identidade.")

                                            setEditar(false);
                                            setLoading(false);

                                        })
                                    })
                                    .catch(err => {
                                        toast.error("Erro não esperado ao atualizar a identidade.")
                                        console.log(err);
                                        setEditar(false);
                                        setLoading(false);
                                    })

                                }

                            }

                        }).catch(console.error)

                    
                } else {
                    toast.warn("Preencha todos os campos corretamente.")
                }
            } }>
                <Grid container>
                    <Grid item md={6}>
                        <h5 className={ classes.dados_titulo }>Identidade</h5>
                    </Grid>
                    <Grid item md={6} style={{display: 'flex'}} justifyContent="flex-end">
                        <Button type="submit" variant="text" className={ editar ? classes.dados_saveButton : classes.dados_editButton }>
                            <SaveIcon fontSize="small" />
                            <Box ml={1}>{ loading ? <CircularProgress size={ 15 } color="inherit" /> : 'Salvar' }</Box>
                        </Button>
                    </Grid>
                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Data da naturalização:</strong> 
                            </Grid>
                            <Grid item md={4} style={{ paddingTop: 0 }}>
                                <DDateInput 
                                    id="identidade-data-naturalizacai"
                                    error={ error.identidadeDataNaturalizacao }
                                    helperText={ error.identidadeDataNaturalizacao }
                                    fullWidth
                                    label=""
                                    maxDate={ new Date() }
                                    maxDateMessage="A data de naturalização não pode ser maior que a data atual"
                                    value={ identidadeDataNaturalizacao }
                                    onError={(errorMessage) => {
                                        setErrors({ ...error, identidadeDataNaturalizacao: errorMessage })
                                    }}
                                    onChange={(arg1, arg2, e) => {
                                        delete error.identidadeDataNaturalizacao
                                    
                                        setIdentidadeDataNaturalizacao(arg1)
                                        setDataNaturalizacaoValida(arg2.isValid);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>País de nascimento:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <div className='input-info'>
                                        <DAutoComplete
                                            id="identidade-pais-nascimento"
                                            placeholder="País de nascimento"
                                            labelColor='black'
                                            fullWidth
                                            error={ error.identidadePaisNascimento }
                                            helperText={ error.identidadePaisNascimento }
                                            defaultValue={ countrys.filter( oe => oe.id == identidadePaisNascimento )[0] }
                                            options={ countrys }
                                            getOptionLabel={(option) => option.value}
                                            getOptionSelected={(option, value) => value && option.id === value.id}
                                            onChange={(event, newValue) => {
                                                delete error.identidadePaisNascimento;
                                                setIdentidadePaisNascimento(newValue ? newValue.id : "");
                                            }}
                                        />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Nome da mãe:</strong> 
                            </Grid>
                            <Grid item md={6} style={{ paddingTop: 0 }}>
                                <DInput
                                    id="NomeMae"
                                    placeholder="Nome da mãe"
                                    style={{ marginTop: 5 }}
                                    type="text"
                                    error={ error.nomeMae }
                                    helperText={ error.nomeMae }
                                    fullWidth
                                    onChange={ e => {
                                        delete error.nomeMae;
                                        setNomeMae(e.target.value);
                                    } }
                                    value={ nomeMae }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item>
                                <strong>Mãe desconhecida</strong>
                            </Grid>
                            <FormControlLabel
                        control={
                            <Checkbox
                            defaultChecked={ maeDesconhecida }
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            onClick={ e => {
                                setMaeDesconhecida(e.target.checked);

                                if(!maeDesconhecida) {
                                    setNomeMae('');
                                    delete error.nomeMae;
                                }
                            }}
                            />
                        }
                        label=""
                        />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        )
    }

    return (
        <>
            { patient ?
                <>
                    { editar ? <Edit patient={ patient } /> : <View patient={ patient } /> }
                </>
                :
                ''
            }
        </>
    )
}

export default IdentidadeNaturalizado;