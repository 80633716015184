import styled from "styled-components";

export const TextHightlight1 = styled.span`
    background: #f4f4f4;
    border-radius: 4px;
    padding: 6px 8px;
    margin: 0px 5px;
}
    
`;

export const DTable = styled.table`
    border-spacing: 0;

    tr {
        background-color: ${props => props.gray ? "gray" : "white"};
        td:last-child {
            text-align: center;
        }
    }

`

export const AddButton = styled.button`
    padding: 5px 10px;
    align-self: center;
    border-radius: 50%;
    margin-top: 15px;
    cursor: pointer;
    border: 1pt solid #42a;
`