import env from 'react-dotenv';

const palette = {
    //Product Color
    primary: {
        light: "#449793",
        main: '#016965',
        dark: '#003E3B',
        // contrastText: calculado em contraste com palette.primary.main
    },
    //Auxiliar Color
    secondary: {
        dark: '#5B8E0F',
        main: '#8CBE45',
        light: '#BFF175',
        // contrastText: calculado em contraste com palette.primary.main
    },
    //Complementar Color
    complement: {
        dark: "#C37400",
        main: "#FBA31F",
        light: "#FFD456",
    },
    //Advanced Color
    advanced: {
        dark: "#006DC5",
        main: "#009BF9",
        light: "#67CBFF",
        sidebar: "#006FB5",
    },
    //Background Colors
    background: {
        grass: "#E8F4F4",
        sky: "#EDF6FD",
        carpet: "#F5F6F6",
        cloud: "#F9F9F9",
        snow: "#FFFFFF",
    },
    //Neutral Colors
    neutral: {
        black: "#272727",
        darkgray: "#4F4F4F",
        gray: "#767676",
        midgrey: "#949494",
        midlightgray: "#E6E6E6",
        lightgray: "#F4F4F4",
        cloud: "#FCFCFC",
        white: "#FFFFFF",
    },
    //Support Colors
    support: {
        success: "#1DB8B5",
        attention: "#FFC76D",
        error: "#FF6B57",
        secondary: "#6893F8",
        grey: "#D0D3D9",
        check: "#1DE8B5"
    },
    //Transparency
    transparency: {
        transparent: "rgba(0, 0, 0, 0)",
        white8: "rgba(255, 255, 255, 0.08)",
        white16: "rgba(255, 255, 255, 0.16)",
        white32: "rgba(255, 255, 255, 0.32)",
        black8: "rgba(0, 0, 0, 0.08)",
        black16: "rgba(0, 0, 0, 0.16)",
        black24: "rgba(0, 0, 0, 0.24)",
        black32: "rgba(0, 0, 0, 0.32)",
        green8: "rgba(0, 255, 0, 0.08)",
        green16: "rgba(0, 255, 0, 0.16)",
        green24: "rgba(0, 255, 0, 0.24)",
        green32: "rgba(0, 255, 0, 0.32)",
        yellow8: "rgba(255, 255, 0, 0.08)",
        yellow16: "rgba(255, 255, 0, 0.16)",
        yellow24: "rgba(255, 255, 0, 0.24)",
        yellow32: "rgba(255, 255, 0, 0.32)",
        red8: "rgba(255, 0, 0, 0.08)",
        red16: "rgba(255, 0, 0, 0.16)",
        red24: "rgba(255, 0, 0, 0.24)",
        red32: "rgba(255, 0, 0, 0.32)",
    },
    logoPlataforma: null,
    contrastThreshold: 3,
    tonalOffset: 0.2,
};

function saveColors(token, dados) {
    return fetch(`${env.API_URL}/configuration/1?token=${token}`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },        
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function getList() {
    return fetch(`${env.API_URL}/configuration`, {       
        method: 'get'
    }).then(res => res.json());
}

function saveLogo(token, formData) {
    return fetch(`${env.API_URL}/configuration/file/1?token=${token}`, {
        method: 'post',
        body: formData,
        redirect: 'follow'
    }).then(res => res.json());
}


export { saveColors, saveLogo, getList, palette }