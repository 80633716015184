import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@material-ui/core/Input";
import DDateInput from "../../../../../shared/DDateInput";
import api_groupForm from "../../../../../../api/group-form";
import useDebounce from "../../../../../../hooks/useDebounce";
import useError from "../../../../../../hooks/useError";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import "react-toastify/dist/ReactToastify.css";
import DInput from "../../../../../shared/DInput";

const useStyles = makeStyles((theme) => ({
  tip: {
    backgroundColor: theme.palette.neutral.black,
  },
  tiparrow: {
    color: theme.palette.neutral.black,
  },
  labelBlack: {
    color: "#000 !important",
  },
  disabled: {
    opacity: "0.25 !important",
  },
}));

function Panel(props) {
  const classes = useStyles();

  const [error, validarFormulario, setErrors] = useError({});

  const [forms, setForms] = useState([]);

  const [dumDesconhecida, setDumDesconhecida] = useState(false);
  const [gestanteAltoRisco, setGestanteAltoRisco] = useState(false);

  const [savedForm, setSavedForm] = useState(null);

  const debounceSavedForm = useDebounce(savedForm, 1000);

  function formatDate(date) {
    if (date && date != "") {
      var day = date.substring(0, 2);
      var month = date.substring(3, 5);
      var year = date.substring(6);

      return [year, month, day].join("-");
    } else {
      return "";
    }
  }

  useEffect(() => {
    // console.log(props)

    if (forms && forms.inputs) return; // preven re-render

    if (props.form && props.groupForm) {
      var _answerValues = [];

      if (props.form.answer) {
        var answerForms = [];
        props.form.answer.forEach((answer) => {
          var input = answer.group_form_input;
          var _options = null;

          if (input.options) {
            try {
              _options = JSON.parse(input.options);
            } catch (e) {
              _options = input.options.replace(/[{}" ]/g, "").split(",");
            }
          }

          answerForms.push({
            group_form_input_id: answer.group_form_input_id,
            value: answer.value,
            _options: _options || {},
            group_form_patient_id: answer.group_form_patient_id,
          });
        });

        _answerValues.push(...answerForms);

        var _formValues = props.form.group_form_input.map((x) => {
          var _answer = _answerValues.find(
            (y) => y.group_form_input_id === x.id
          );

          return {
            ...x,
            value: _answer ? _answer.value : "",
            group_form_patient_id: _answer
              ? _answer.group_form_patient_id
              : null,
          };
        });

        props.form.group_form_input = _formValues;

        if (Array.isArray(_formValues)) {
          _formValues
            .filter((x) => x.type === "checkbox")
            .forEach((form) => {
              if (form && form.name) {
                if (form.name === "dum_desconhecida") {
                  setDumDesconhecida(Boolean(Number(form.value)));
                }

                if (form.name === "gestante_alto_Risco") {
                  setGestanteAltoRisco(Boolean(Number(form.value)));
                  props.updateGestanteAltoRisco(Boolean(Number(form.value)));
                }
              }
            });

          var { value: sisprenatalValue } = _formValues
            .filter((x) => x.type === "text")
            .find((y) => y.name === "sisprenatal");

          props.updateSisprenatal(sisprenatalValue);
        }

        var _setForms = {
          inputs: _formValues,
          status: props.openForm,
        };

        console.log("openform", props.openForm);

        if (_setForms.inputs) {
          _setForms.inputs
            .filter(
              (x) =>
                x.name === "idade_gestacional_semanas" ||
                x.name === "idade_gestacional_dias"
            )
            .forEach((age_input) => {
              if (age_input.name === "idade_gestacional_semanas") {
                props.updateSemanasGestacao(age_input.value);
              } else if (age_input.name === "idade_gestacional_dias") {
                props.updateDiasGestacao(age_input.value);
              }
            });
        }

        setForms(_setForms);
      } else {
        setForms({
          inputs: props.form.group_form_input,
          status: props.openForm,
        });
      }
    }
  }, [props.form]);

  useEffect(() => {
    return () => {
      setForms([]);
      setSavedForm(null);
    };
  }, []);

  useEffect(() => {
    if (debounceSavedForm) {
      if (props.form.opennedForm && props.form.opennedForm.status === 2)
        return false; // Prevenir salvamento de formulário já finalizado

      handleSave(debounceSavedForm.save, debounceSavedForm.value);
    }

    return () => setSavedForm(null);
  }, [debounceSavedForm]);

  function handleInstaSave(form_input, value) {
    if (props.form.opennedForm && props.form.opennedForm.status === 2)
      return false; // Prevenir salvamento de formulário já finalizado

    handleSave(form_input, value);
  }

  function findSequenceOfNumber(str) {
    str = str.replace(/[^0-9]/g, "");

    for (var x = 0; x < 10; x++) {
      if (str === String(x).repeat(15)) {
        return x;
      }
    }

    return null;
  }

  function handleSave(form_input, value) {
    // Auto salvamento com debounce.
    // console.log("save", form_input, value)
    const _saveData = {
      group_form_input_id: form_input.id,
      patient_id: props.paciente.patient.id,
      group_form_id: form_input.group_form_id,
      value: value,
      group_form_patient_id:
        (props.answerForm && props.answerForm.id) ||
        form_input.group_form_patient_id,
    };

    const promise = api_groupForm
      .createOrUpdateForm(_saveData)
      .then((res) => {
        if (!res.status) {
          throw new Error(res.error || "Não foi possível salvar");
        }
      })
      .catch(console.error);

    toast.promise(promise, {
      loading: "Salvando...",
      success: "Dados salvos com sucesso!",
      error: "Erro ao salvar dados!",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  const RenderForm = (form_input, index) => {
    if (
      form_input.name === "idade_gestacional_dias" ||
      form_input.name === "idade_gestacional_semanas" ||
      form_input.name === "motivo_encerramento_gestacao"
    )
      return;

    const indexOf = forms.inputs.findIndex((x) => x.id === form_input.id);

    switch (form_input.type) {
      case "text":
      case "number":
        return (
          <Grid item md={6} key={index} className={classes.labelBlack}>
            <DInput
              id={form_input.id}
              label={form_input.label}
              placeholder={form_input.value ? "" : form_input.placeholder}
              type="text"
              required={Boolean(form_input.required)}
              labelColor="black"
              disabled={forms.status == 2}
              positionIcon="end"
              fullWidth
              value={forms && forms.inputs[indexOf].value}
              onChange={(e) => {
                var _value =
                  form_input.type === "number"
                    ? e.target.value.replace(/[^0-9]/g, "").slice(0, 2)
                    : e.target.value;

                if (form_input.name === "sisprenatal") {
                  _value = _value.replace(/[^0-9]/g, "").slice(0, 15);
                }

                if (form_input.name === "sisprenatal") {
                  const sequence = findSequenceOfNumber(_value);
                  console.log(sequence);

                  if (sequence !== null) {
                    toast.warn("Não é permitido sequência do mesmo número.");
                    return;
                  }
                }

                if (form_input.name) {
                  if (form_input.name === "sisprenatal") {
                    props.updateSisprenatal(_value);
                  }
                }

                setForms((prevState) => {
                  var _temp = prevState.inputs;

                  _temp[indexOf].value = _value;

                  return { ...prevState, inputs: _temp };
                });

                setSavedForm({ save: form_input, value: _value });
              }}
            />
          </Grid>
        );
      case "select":
        var _options = [];

        try {
          _options = JSON.parse(form_input.options);
        } catch (x) {
          // {0, 1, 2, 3} => [0, 1, 2, 3]; {"xxx", "yyy", "fff"} => ["xxx", "yyy", "fff"]
          _options = form_input.options.replace(/[{}" ]/g, "").split(",");
        }

        return (
          <Grid item md={6} key={index}>
            <FormControl
              fullWidth
              className={forms.status == 2 ? classes.disabled : null}
            >
              <InputLabel
                ref={(el) =>
                  el && el.style.setProperty("color", "black", "important")
                }
              >
                {form_input.label}
              </InputLabel>
              <Select
                id={form_input.id}
                name={form_input.name}
                placeholder={form_input.placeholder}
                required={form_input.required}
                disabled={forms.status == 2}
                value={forms && forms.inputs[indexOf].value}
                onChange={(e) => {
                  setSavedForm({ save: form_input, value: e.target.value });

                  setForms((prevState) => {
                    var _temp = prevState.inputs;

                    _temp[indexOf].value = e.target.value;

                    return { ...prevState, inputs: _temp };
                  });
                }}
              >
                {_options.map((option, index) => {
                  if (typeof option === "string") {
                    return (
                      <MenuItem
                        key={index}
                        value={option}
                        selected={option == form_input.value}
                      >
                        {option}
                      </MenuItem>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        value={option.id}
                        selected={option.id == form_input.value}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
        );
      case "date":
        var _dumDateValue = forms.inputs[indexOf].value
          ? forms.inputs[indexOf].value.split("-").reverse().join("/")
          : null;

        if (form_input.name === "dum" && dumDesconhecida) {
          _dumDateValue = null;

          return (
            <Grid item md={6} key={index} className={classes.labelBlack}>
              <DDateInput
                fullWidth
                disabled={true}
                fixedLabel
                label={form_input.label}
                value={""}
                onChange={(arg1, arg2, e) => null}
              />
            </Grid>
          );
        }

        if (form_input.name === "dum") console.log("DUM", _dumDateValue);

        const getInputError = (name) => error[name];

        var fromBackendDate = false;

        if (form_input.value && typeof form_input.value === "string") {
          fromBackendDate = form_input.value.charAt(4) === "-" ? true : null;
        }

        return (
          <Grid item md={6} key={index} className={classes.labelBlack}>
            <DDateInput
              fullWidth
              disabled={
                form_input.name === "dum"
                  ? dumDesconhecida || forms.status == 2
                  : forms.status == 2
              }
              error={getInputError(form_input.name)}
              fixedLabel
              label={form_input.label}
              labelColor="black"
              value={
                fromBackendDate
                  ? form_input.value.split("-").reverse().join("/")
                  : form_input.value
              }
              icon={
                form_input.name === "dum" && (
                  <Tooltip
                    title="Data da última menstruação"
                    placement="right"
                    arrow
                    classes={{ tooltip: classes.tip, arrow: classes.tiparrow }}
                  >
                    <InfoIcon fontSize="small" style={{ cursor: "pointer" }} />
                  </Tooltip>
                )
              }
              onChange={(arg1, arg2, e) => {
                delete error[form_input.name];

                if (arg1 || arg1 === "") {
                  setForms((prevState) => {
                    var _temp = prevState.inputs;

                    _temp[indexOf].value = arg1;

                    return { ...prevState, inputs: _temp };
                  });
                }

                if (arg1 && arg1.length == 10) {
                  if (form_input.name === "dum") {
                    console.log("VALIDANDO DUM", arg1);

                    try {
                      if (
                        arg1 === "00/00/0000" ||
                        arg1.split("/")[0] === "00" ||
                        arg1.split("/")[1] === "00"
                      ) {
                        throw new Error("Data inválida");
                      }

                      var _dumDate = new Date(
                        arg1.split("/").reverse().join("-")
                      );
                      console.log("VALIDANDO DUM", _dumDate);

                      if (
                        isNaN(
                          Date.parse(_dumDate) ||
                            new Date(
                              arg1.split("/").reverse().join("-")
                            ).toString() === "Invalid Date"
                        )
                      ) {
                        throw new Error("Data inválida");
                      }

                      if (_dumDate >= new Date()) {
                        toast.error(
                          "Data não pode ser maior que a data atual."
                        );
                        setErrors({
                          ...error,
                          dum: "Data não pode ser maior que a data atual.",
                        });
                        return;
                      }

                      setSavedForm({
                        save: form_input,
                        value: arg1.split("/").reverse().join("-"),
                      });
                    } catch (x) {
                      toast.error("Data inválida.");
                      setErrors({ ...error, dum: "Data inválida." });
                      return;
                    }

                    return;
                  }

                  if (form_input.name === "data_acolhimento") {
                    console.log("VALIDANDO DATA ACOLHIMENTO", arg1);

                    try {
                      if (
                        arg1 === "00/00/0000" ||
                        arg1.split("/")[0] === "00" ||
                        arg1.split("/")[1] === "00"
                      ) {
                        throw new Error("Data inválida");
                      }
                      var _acolhimentoDate = new Date(
                        arg1.split("/").reverse().join("-") + "T00:00:00"
                      );

                      if (
                        isNaN(
                          Date.parse(_acolhimentoDate) ||
                            new Date(
                              arg1.split("/").reverse().join("-")
                            ).toString() === "Invalid Date"
                        )
                      ) {
                        throw new Error("Data inválida");
                      }

                      if (
                        _acolhimentoDate >=
                        new Date(new Date().setHours(0, 0, 0, 0))
                      ) {
                        toast.error(
                          "Data não pode ser maior ou igual a data atual."
                        );
                        setErrors({
                          ...error,
                          data_acolhimento:
                            "Data não pode ser maior ou igual a data atual.",
                        });
                        return;
                      }

                      setSavedForm({
                        save: form_input,
                        value: arg1.split("/").reverse().join("-"),
                      });
                    } catch (x) {
                      toast.error("Data inválida.");
                      setErrors({
                        ...error,
                        data_acolhimento: "Data inválida.",
                      });
                      return;
                    }

                    return;
                  }

                  if (form_input.name === "data_primeira_consulta_pre_natal") {
                    console.log("VALIDANDO DATA PRENATAL", arg1);

                    try {
                      if (
                        arg1 === "00/00/0000" ||
                        arg1.split("/")[0] === "00" ||
                        arg1.split("/")[1] === "00"
                      ) {
                        throw new Error("Data inválida");
                      }
                      var _dataPrenatal = new Date(
                        arg1.split("/").reverse().join("-") + "T00:00:00"
                      );

                      if (
                        isNaN(
                          Date.parse(_dataPrenatal) ||
                            new Date(
                              arg1.split("/").reverse().join("-")
                            ).toString() === "Invalid Date"
                        )
                      ) {
                        throw new Error("Data inválida");
                      }

                      if (
                        _dataPrenatal >=
                        new Date(new Date().setHours(0, 0, 0, 0))
                      ) {
                        toast.error(
                          "Data não pode ser maior ou igual a data atual."
                        );
                        setErrors({
                          ...error,
                          data_primeira_consulta_pre_natal:
                            "Data não pode ser maior ou igual a data atual.",
                        });
                        return;
                      }

                      var _patientBirth = new Date(
                        new Date(props.paciente.patient.birth_date)
                      );

                      console.log(_patientBirth, props.paciente.patient);

                      if (_dataPrenatal < _patientBirth) {
                        toast.error(
                          "Data não pode ser menor que a data de nascimento."
                        );
                        setErrors({
                          ...error,
                          data_primeira_consulta_pre_natal:
                            "Data não pode ser menor que a data de nascimento.",
                        });
                        return;
                      }

                      setSavedForm({
                        save: form_input,
                        value: arg1.split("/").reverse().join("-"),
                      });
                    } catch (x) {
                      toast.error("Data inválida.");
                      setErrors({
                        ...error,
                        data_primeira_consulta_pre_natal: "Data inválida.",
                      });
                      return;
                    }

                    return;
                  }

                  if (form_input.name === "data_x") {
                    console.log("VALIDANDO DATA USG", arg1);

                    try {
                      if (
                        arg1 === "00/00/0000" ||
                        arg1.split("/")[0] === "00" ||
                        arg1.split("/")[1] === "00"
                      ) {
                        throw new Error("Data inválida");
                      }
                      var _realizacaoUsgDate = new Date(
                        arg1.split("/").reverse().join("-") + "T00:00:00"
                      );

                      if (
                        isNaN(
                          Date.parse(_realizacaoUsgDate) ||
                            new Date(
                              arg1.split("/").reverse().join("-")
                            ).toString() === "Invalid Date"
                        )
                      ) {
                        throw new Error("Data inválida");
                      }

                      if (
                        _realizacaoUsgDate >=
                        new Date(new Date().setHours(0, 0, 0, 0))
                      ) {
                        toast.error(
                          "Data não pode ser maior ou igual a data atual."
                        );
                        setErrors({
                          ...error,
                          data_realizacao_usg:
                            "Data não pode ser maior ou igual a data atual.",
                        });
                        return;
                      }

                      setSavedForm({
                        save: form_input,
                        value: arg1.split("/").reverse().join("-"),
                      });
                    } catch (x) {
                      toast.error("Data inválida.");
                      setErrors({
                        ...error,
                        data_realizacao_usg: "Data inválida.",
                      });
                      return;
                    }

                    return;
                  }

                  setSavedForm({
                    save: form_input,
                    value: arg1.split("/").reverse().join("-"),
                  });
                }
              }}
            />
          </Grid>
        );
    }
  };

  const renderFormIdadeGest = (form_input, index) => {
    var _options = form_input.options.replace(/[{}" ]/g, "").split(",");

    const indexOf = forms.inputs.findIndex((x) => x.id === form_input.id);

    return (
      <FormControl
        key={index}
        className={forms.status == 2 ? classes.disabled : null}
      >
        <InputLabel
          ref={(el) =>
            el && el.style.setProperty("color", "black", "important")
          }
        >
          {form_input.name == "idade_gestacional_dias" ? "Dia(s)" : "Semana(s)"}
        </InputLabel>
        <Select
          name={form_input.name}
          style={{ width: 100 }}
          disabled={forms.status == 2}
          onChange={(e, data) => {
            if (form_input.name == "idade_gestacional_dias") {
              props.updateDiasGestacao(e.target.value);
            } else if (form_input.name === "idade_gestacional_semanas") {
              props.updateSemanasGestacao(e.target.value);
            }

            setForms((prevState) => {
              var _temp = prevState.inputs;

              _temp[indexOf].value = e.target.value;

              return { ...prevState, inputs: _temp };
            });

            setSavedForm({ save: form_input, value: data.props.value });
          }}
          value={forms && _options.find((x) => x == form_input.value)}
        >
          {_options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                selected={option == form_input.value}
                value={option}
              >
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {forms &&
          forms.inputs &&
          forms.inputs
            .filter((x) => x.type === "checkbox")
            .map((form_input, index) => {
              // console.log(form_input, index)
              const indexOf = forms.inputs.findIndex(
                (x) => x.id === form_input.id
              );

              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={Boolean(Number(forms.inputs[indexOf].value))}
                      disabled={forms.status == 2}
                      name={form_input.name}
                      onChange={(e, checked, y) => {
                        setSavedForm({ save: form_input, value: checked });

                        handleInstaSave(form_input, checked);

                        if (form_input.name === "dum_desconhecida") {
                          setDumDesconhecida(checked);

                          if (checked) {
                            setForms((prevState) => {
                              let _temp = prevState.inputs;

                              let _dumFieldDateIndex = _temp.findIndex(
                                (x) => x.name === "dum"
                              );

                              _temp[_dumFieldDateIndex].value = "";

                              return { ...prevState, inputs: _temp };
                            });

                            if (forms) {
                              let _dumFieldDateIndex = forms.inputs.findIndex(
                                (x) => x.name === "dum"
                              );

                              handleInstaSave(
                                forms.inputs[_dumFieldDateIndex],
                                ""
                              ); // Apagando valor data DUM
                            }

                            setErrors({ ...error, dum: undefined });
                          }
                        }

                        if (form_input.name === "gestante_alto_Risco") {
                          props.updateGestanteAltoRisco(checked);
                          //   if (props.updateGestanteAltoRisco) {
                          //     props.updateGestanteAltoRisco(checked);
                          //   }
                        }

                        setForms((prevState) => {
                          var _temp = prevState.inputs;

                          _temp[indexOf].value = checked;

                          if (!checked) {
                            if (form_input.name === "dum_desconhecida") {
                              if (
                                _temp.findIndex((x) => x.name === "dum") > -1
                              ) {
                                _temp[
                                  _temp.findIndex((x) => x.name === "dum")
                                ].value = "";
                              }
                            }
                          }

                          return { ...prevState, inputs: _temp };
                        });
                      }}
                    />
                  }
                  label={form_input.label}
                />
              );
            })}
      </div>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <h3 style={{ marginTop: 20, color: "#4a4ae9" }}>
            Gestação&nbsp;
            {props.groupForm && props.groupForm.status === 3
              ? "encerrada"
              : "atual"}
          </h3>
        </Grid>
        <Grid
          item
          md={6}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            style={{
              marginTop: 20,
              color: "#4a4ae9",
              marginRight: 20,
              minWidth: "max-content",
            }}
          >
            Idade gestacional
          </Typography>

          {forms &&
            forms.inputs &&
            forms.inputs
              .filter(
                (x) =>
                  x.name === "idade_gestacional_dias" ||
                  x.name == "idade_gestacional_semanas"
              )
              .map((group, index) => {
                return (
                  <Grid item md={6} key={index}>
                    {renderFormIdadeGest(group, index)}
                  </Grid>
                );
              })}
        </Grid>

        {forms &&
          forms.inputs &&
          forms.inputs.map((form_input, index) => {
            return RenderForm(form_input, index);
          })}
      </Grid>
    </>
  );
}

export default Panel;
