import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, RadioGroup, FormControlLabel, TextField, Radio, Checkbox, FormGroup, FormControl } from "@material-ui/core";

const useStyles = makeStyles({
    card_subtitle: {
        color: "#272727",
        fontSize: 14,
        margin: "0 0 16px"
    },
    edit_input: {
        backgroundColor: "#fff",
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    },
    radio: {
        color: "blue"
    },
    content: {
        height: 76
    },
    formControl: {
        display: "inline-block"
    }
});

function OptionComponent(props)
{

    const classes = useStyles();
    const [value, setValue] = useState(props.value);
    const [values, setValues] = useState(props.options.map(option => {
        return {
            id: option.id,
            value: option.value,
            checked: props.value && props.value.toString() === option.value.toString() ? true : false
        }
    }));

    useEffect(() => {

        let new_values = props.options.map(option => {

            var checked = false;

            if(props.multiple) {
                if(props.value && props.value.split(";").indexOf(option.value) >= 0)
                    checked = true;
            }

            return {
                id: option.id,
                value: option.value,
                checked
            }
        });

        setValues(new_values);

    }, [props.options])

    return (<Grid item>
        { props.multiple ?
                <Grid container classes={{root: classes.content}}>
                    { props.showName && <Grid item md={12}><h6 className={ classes.card_subtitle }>{ props.name }</h6></Grid> }
                    <Grid item style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup row>
                            <FormControl className={ `${ classes.formControl } ${ props.error ? "form-control-error" : "" }` }>
                                { values.map((option, index) => <FormControlLabel key={`option-checkbox-${index}`} value={ option.value } 
                                    control={<Checkbox
                                                checked={ option.checked }
                                                onChange={ e => {
                                                    let new_values = [...values]
                                                    new_values[index].checked = !option.checked;
                                                    setValues(new_values);
                                                    props.onChange(new_values.filter(val => val.checked).map(val => val.value));
                                                } }
                                            />} 
                                    label={ option.value } /> ) }
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            :
            <Grid container classes={{root: classes.content}}>
                { props.showName && <Grid item md={12}><h6 className={ classes.card_subtitle }>{ props.name }</h6></Grid> }
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <FormControl className={ `Radio-root ${ classes.formControl } ${ props.error ? "form-control-error" : "" }` }>
                        <RadioGroup aria-label="gender" name="gender" row={true} value={value} onChange={ e => {
                            setValue(e.target.value);
                            props.onChange([e.target.value]);
                        } }>
                                { props.options.map((option, index) => <FormControlLabel key={`option-radio-${index}`} value={ option.value } control={ <Radio error={ props.error ? true : false } /> } label={ option.value } /> ) }
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>            
        }        
    </Grid>)
}

OptionComponent.defaultProps = {
    value: null,
    error: false,
    showName: false,
    name: null,
    multiple: false,
    options: [],
    onChange: function(value) {
        console.log(value)
    }
}

export default OptionComponent;