import { makeStyles, TextField, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import { mascaraAltura, mascaraPeso } from "../../../../../../../../helpers/formatacao";
import "./styles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "15% !important",
  },
}));

const EBBodyMassIndex = ({
  id,
  title,
  label,
  placeholder,
  value,
  helper_text,
  is_required,
  is_read_only,
  erro,
  condicional,
  readOnly,
  answer
}) => {
  const classes = useStyles();
  const { handleInputSubmit } = useContext(FormBuilderContext);

  const [height, setHeight] = useState();
  const [heightFormatter, setHeightFormatter] = useState();
  const [weight, setWeight] = useState();
  const [weightFormatter, setWeightFormatter] = useState();
  const [statusIMC, setStatusIMC] = useState();
  const [IMC, setIMC] = useState();
  const [data, setData] = useState('');
  const [teste, setTeste] = useState(123);
  
  useEffect(() => {
    const data = {
      value: {
        height: height,
        weight: weightFormatter,
      },
    };
    if(data.value.height !== undefined){
      setData(data.value)
      // handleInputSubmit({[id]: data.value})
    }
    calculateBMI(height, weightFormatter)
    setHeightFormatter(mascaraAltura(height));
    
  }, [height, weight]);

  useEffect(() => {
    if(answer.length > 0){
      setHeight(answer[0].height)
      setHeightFormatter(mascaraAltura(answer[0].height));
      setWeight(answer[0].weight)
      setWeightFormatter(mascaraPeso(answer[0].weight))
    }
  },[])


  function calculateBMI(height, weight) {
    let error = false;
    
    if(height && height.length > 3 ) {
      error=false
    } else {
      error=true
      setIMC('')
      setStatusIMC('')
    }

    if(height && height.substr(0, 1) == '0') {
      error=true
      setIMC('')
      setStatusIMC('')
    }

    if(height != null) {
      height = parseFloat(height.replace(",", ""));
    }

    if(weight != null) {
      weight = parseFloat(weight);
    }
    
    if (height === "" || isNaN(height))
      console.log("Forneça uma altura válida!");
    else if (weight === "" || isNaN(weight))
    console.log("Fornaça um peso válido");
    
    else if (!error) {
        height = height /100
      
        let bmi = (weight / (height * height)).toFixed(1);

        if (bmi < 18.6)  
          setStatusIMC('Abaixo do peso');
        if (bmi >= 18.6 && bmi < 24.9)
          setStatusIMC('Normal');
        if(bmi >=25 && bmi < 29.9)
          setStatusIMC('Sobrepeso');
        if(bmi>=30 && bmi <=34.9)
          setStatusIMC('Obesidade Grau I');
        if(bmi>=35 && bmi <= 39.9)
          setStatusIMC('Obesidade Grau II');
        if(bmi > 40) 
          setStatusIMC('Obesidade Grau III ou Mórbida');
        setIMC(bmi);

    }
  }
 
  return (
    <section style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <div className="box-medicoes-clinicas">
          <Typography
            variant="body1"
            className="text-bold mb-2"
            style={{ fontSize: "0.8em" }}
          >
            <div dangerouslySetInnerHTML={{ __html: `${label ? label : "Índice de Massa Corporal (IMC)"}` }} />
          </Typography>
          <Box className={classes.root}>
            <span className="text-regular text-info-input">altura</span>
            <TextField
              id={id}
              // label={label ? label : ""}
              className={classes.textField}
              placeholder={placeholder ? placeholder : ""}
              readOnly={is_read_only === 1 ? true : false}
              required={is_required === 1 ? true : false}
              error={ erro[0] ? erro[0].value : '' }
              helperText={ erro[0] ? erro[0].value : '' }
              value={heightFormatter ?  heightFormatter : ''}
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ",");
              }}
              onChange={(e) => {
                if(erro !== null && erro.length > 0){
                  delete erro[0].value
                }
                setHeight(e.target.value)
              }}
              // InputProps={{
              //   readOnly: readOnly,
              // }}
            />
            <span className="text-regular text-info-input">m</span>
            <span className="text-regular text-info-input">peso</span>
            <TextField
              id={id}
              // label={label ? label : ""}
              className={classes.textField}
              placeholder={placeholder ? placeholder : ""}
              readOnly={is_read_only === 1 ? true : false}
              required={is_required === 1 ? true : false}
              error={ erro[0] ? erro[0].value : '' }
              helperText={ erro[0] ? erro[0].value : '' }
              value={weightFormatter ? weightFormatter : ""}
              variant="outlined"
              inputProps={{ maxLength: 6 }}
              // onInput={(e) => {
              //   e.target.value = e.target.value.replace(/[^0-9]/g, "");
              // }}
              onChange={(e) => {
                if(erro !== null && erro.length > 0){
                  delete erro[0].value
                }
                setWeight(e.target.value);
                setWeightFormatter(mascaraPeso(e.target.value));
              }}
              onBlur={ e => {
                if(data !== null){
                  !readOnly ? handleInputSubmit({[id]: data}) : console.log('effect onBlur')
                }
              } }
              // InputProps={{
              //   readOnly: readOnly,
              // }}
            />
            <span className="text-regular text-info-input">kg</span>
            <span className="text-regular text-info-input">
              <strong>{IMC ? IMC.toString().replace(".", ",") : ''}</strong> {statusIMC}
            </span>
          </Box>
          <span style={{ fontSize: 12 }} className="text-light">
            {helper_text ? helper_text : ""}
          </span>
        </div>
      </div>
    </section>
  );
};

export default EBBodyMassIndex;
