import {
    CallReceived,
    Mic,
    PeopleAlt,
    PhoneEnabled,
    PhoneDisabled,
    PhoneInTalk,
    MicOff,
    Phone,
    Chat,
    Videocam,
    VideoCall,
    Person,
    PersonAdd
  } from "@material-ui/icons";
import DButton from "./../../DButton";
import { SvgIcon } from "@material-ui/core";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { useState } from "react";  
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

  const useStyles = makeStyles((theme) => ({
    iconInverted: {
      transform: 'scaleX(-1)'
    },
    voiceVideoGreenButton: {
      borderColor:  `${theme.palette.primary.light} !important`,
      color: `${theme.palette.background.snow} !important`,
      backgroundColor: `${theme.palette.primary.light} !important`,
      marginLeft: "10px",
      textTransform: "none"
    },
    callDoctorButton: {
      color: `${theme.palette.primary.light} !important`,
      borderColor: `${theme.palette.primary.light} !important`,
    },
    callButton: {
      marginLeft: "10px",
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
    callRedButton: {
      marginLeft: "10px",
      borderColor: `${theme.palette.support.error} !important`,
      color: `${theme.palette.support.error} !important`,
    },
    callPrimaryRedButton: {
      marginLeft: "10px",
      backgroundColor: `${theme.palette.support.error} !important`,
      color: `${theme.palette.background.snow} !important`,
    },
    iconDropColor: {
      color: `${theme.palette.primary.light} !important`,
      marginRight: "10px"
    },
    endServiceOrangeButton: {
      borderColor: `${theme.palette.support.error} !important`,
      color: `${theme.palette.background.snow} !important`,
      marginLeft: "10px",
      backgroundColor: `${theme.palette.support.error} !important`,
      textTransform: "none",
      "&:hover": {
        borderColor: `${theme.palette.support.error} !important`,
        color: `${theme.palette.background.snow} !important`,
        marginLeft: "10px",
        backgroundColor: `${theme.palette.support.error} !important`,
      },
    }
  }));
  
  const FinalizarIcon = (props) => {
    return (
      <SvgIcon {...props}>
        <path
          d="M13.6933 3.48667L12.7667 2.36667C12.5867 2.14 12.3133 2 12 2H4C3.68667 2 3.41333 2.14 3.22667 2.36667L2.30667 3.48667C2.11333 3.71333 2 4.01333 2 4.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V4.33333C14 4.01333 13.8867 3.71333 13.6933 3.48667ZM7.76667 11.4333L4.33333 8H6.66667V6.66667H9.33333V8H11.6667L8.23333 11.4333C8.10667 11.56 7.89333 11.56 7.76667 11.4333ZM3.41333 3.33333L3.95333 2.66667H11.9533L12.58 3.33333H3.41333Z"
          fill="white"
        />
      </SvgIcon>
    );
  };
  
//   export function MuteCall(props) {
//     return (
//       <DButton
//         {...props}
//         className="mute-call-button"
//         variant="secondary"
//         icon={<Mic fontSize="small" />}
//         size="md"
//       />
//     );
//   }
  
//   export function UnmuteCall(props) {
//     return (
//       <DButton
//         {...props}
//         className="mute-call-button"
//         variant="secondary"
//         icon={<MicOff fontSize="small" />}
//         size="md"
//       />
//     );
//   }
  
  export function AssumirELigar(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.callButton}
        variant="primary"
        icon={<PhoneEnabled fontSize="small" />}
        size="md"
      >
        Assumir e ligar para paciente
      </DButton>
    );
  }
  
  export function AbdicarAtendimento(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.callRedButton}
        variant="secondary"
        icon={<CallReceived fontSize="small" />}
        size="md"
      >
        Abdicar atendimento
      </DButton>
    );
  }
  
  export function LigarParaEspecialista(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.callDoctorButton}
        variant="secondary"
        icon={<PeopleAlt fontSize="small" />}
        size="md"
      >
        Fale com o especialista
      </DButton>
    );
  }
  
  export function LigarParaPaciente(props) {
  
    const classes = useStyles();
  
    return (
      <DButton
        {...props}
        className={classes.callButton}
        variant="primary"
        icon={<PhoneEnabled classes={{ root: classes.iconInverted }} fontSize="small" />}
        size="md"
      >
        Ligar para paciente
      </DButton>
    );
  }
  
  export function Desligar(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.endServiceOrangeButton}
        variant="primary"
        icon={<PhoneDisabled fontSize="small" />}
        size="sm"
      >
        Desligar
      </DButton>
    );
  }
  
  export function DesligarEFinalizar(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.callPrimaryRedButton}
        variant="primary"
        icon={<PhoneInTalk fontSize="small" />}
        size="sm"
      >
        Desligar e finalizar
      </DButton>
    );
  }
  
  export function Finalizar(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.callPrimaryRedButton}
        variant="primary"
        icon={
          <FinalizarIcon
            style={{ width: 26, height: 26, paddingTop: 5 }}
            fontSize="small"
          />
        }
        size="md"
      >
        Finalizar atendimento
      </DButton>
    );
  }
  
  // Novos Botões
  
  // export function AbdicarAtendimentoNew(props) {
  //   return (
  //     <DButton
  //       {...props}
  //       className="attendance-orange-button"
  //       variant="secondary"
  //       size="sm"
  //     >
  //       Abdicar atendimento
  //     </DButton>
  //   );
  // }
  
//   export function ChatButton(props) {
//     return (
//       <DButton
//         {...props}
//         className="chat-green-button"
//         variant="secondary"
//         icon={<Chat fontSize="small" />}
//         size="sm"
//       >
//         Chat
//       </DButton>
//     );
//   }
  
  export function ChamadadeVoz(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (action) => {
      setAnchorEl(null);
      props.onAction(action)
    };
    return (
      <div>
        <DButton
          {...props}
          className={classes.voiceVideoGreenButton}
          variant="secondary"
          icon={<Phone fontSize="small" />}
          size="sm"
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Chamada de voz
        </DButton>
        <Menu className="mt-20-menu"
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={ e => handleClose('paciente_solicitante')}>
            <Phone className={classes.iconDropColor} fontSize="small" />
            Ligar para paciente e solicitante
          </MenuItem>
          <MenuItem onClick={ e => handleClose('solicitante')}>
            <Phone className={classes.iconDropColor} fontSize="small" />
            Ligar para solicitante
          </MenuItem>
        </Menu>
      </div>
    );
  }
  
  export function ChamadadeVideo(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (action) => {
      setAnchorEl(null);
      props.onAction(action)
    };
    return (
      <div>
        <DButton
          {...props}
          className={classes.voiceVideoGreenButton}
          variant="secondary"
          icon={<Videocam fontSize="small" />}
          size="sm"
          id="fade-button2"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Chamada de vídeo
        </DButton>
        <Menu className="mt-20-menu"
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={ e =>  handleClose('paciente')  }>
            <VideoCall className={classes.iconDropColor}fontSize="small" />
            Ligar para paciente
          </MenuItem>
          <MenuItem onClick={e => handleClose('paciente_solicitante') }>
            <VideoCall className={classes.iconDropColor} fontSize="small" />
            Ligar para paciente e solicitante
          </MenuItem>
        </Menu>
      </div>
    );
  }
  
  export function AssumirAtendimento(props) {
    const classes = useStyles();
    return (
      <DButton
        {...props}
        className={classes.voiceVideoGreenButton}
        variant="secondary"
        icon={<Person fontSize="small" />}
        size="sm"
      >
        Assumir atendimento
      </DButton>
    );
  }
  
  export function FinalizarAtendimento(props) {
    const classes = useStyles();
    return (
      <div>
        <DButton
          {...props}
          className={classes.endServiceOrangeButton}
          variant="secondary"
          size="sm"
        >
          Finalizar atendimento
        </DButton>
      </div>
    );
  }
export const CancelarButtom = withStyles((theme) => ({
    root: {
      width: 92,
      height: 33,
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: 100,
      backgroundColor: "transparent",
      '&:hover': {
        backgroundColor: "#00000014",
      },
    },
}))(Button);

export const BackButtom = withStyles((theme) => ({
  root: {
    width: 74,
    height: 33,
    textTransform: "capitalize",
    color: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.light} !important`,
    borderRadius: 100,
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "#00000014",
    },
  },
}))(Button);

const ChatButtonStyled = withStyles((theme) => ({
    root: {
      width: 88,
      height: 33,
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      border: `2px solid ${theme.palette.primary.light} !important`,
      borderRadius: 100,
      backgroundColor: "transparent",
      '&:hover': {
        backgroundColor: "#00000014",
      },
    },
}))(Button);

export const ChatButton = (props) => {
    return <ChatButtonStyled {...props}><Chat fontSize="small" />&nbsp;Chat</ChatButtonStyled>
}

export const ConfirmarButtom = withStyles((theme) => ({
    root: {
      width: 141,
      height: 33,
      textTransform: "capitalize",
      color: "white",
      border: `1px solid ${theme.palette.primary.light} !important`,
      borderRadius: 100,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
}))(Button);

export const ConfirmarAbdicar = withStyles((theme) => ({
  root: {
    width: 167,
    height: 33,
    textTransform: "initial",
    color: "white",
    border: `1px solid ${theme.palette.support.error} !important`,
    borderRadius: 100,
    backgroundColor: theme.palette.support.error,
    '&:hover': {
      backgroundColor: theme.palette.support.error
    },
  },
}))(Button);

export const AbdicarAtendimentoNew = withStyles((theme) => ({
  root: {
    width: 167,
    height: 33,
    textTransform: "initial",
    color: theme.palette.support.error,
    border: `2px solid ${theme.palette.support.error} !important`,
    borderRadius: 100,
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "#00000008",
    },
  },
}))(Button);

const CircleButtom = withStyles((theme) => ({
    root: {
      width: 32,
      minWidth: 32,
      height: 32,
      padding: 0,
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      border: "solid #d8ebea 1px",
      borderRadius: 100,
      backgroundColor: "#d8ebea",
      '&:hover': {
        backgroundColor: "#00000014",
      },
    },
}))(Button);

const CircleBlueButtom = withStyles((theme) => ({
    root: {
      width: 32,
      minWidth: 32,
      height: 32,
      padding: 0,
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      border: "solid #d8ebea 1px",
      borderRadius: 100,
      backgroundColor: "rgba(74, 74, 233, 0.08)",
      '&:hover': {
        backgroundColor: "#00000014",
      },
    },
}))(Button);

export const MuteCall = (props) => {
    return <CircleButtom {...props}>
        <Mic fontSize="small" />
    </CircleButtom>
}

export const UnmuteCall = (props) => {
    return <CircleButtom {...props}>
        <MicOff fontSize="small" />
    </CircleButtom>
}

export const AddUserCall = (props) => {
    return <CircleBlueButtom {...props}>
        <PersonAdd fontSize="small" />
    </CircleBlueButtom> 
}
