import { useEffect, useRef, useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Container,Box,Typography,FormControlLabel,Checkbox,Divider,FormHelperText,TextField,FormGroup, FormControl, OutlinedInput, InputAdornment, IconButton  } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import DAutoComplete from "./../../../../../../shared/DAutoComplete";
import DMultAutoComplete from "./../../../../../../shared/DMultAutoComplete";
import DRadio from "./../../../../../../shared/DRadio";
import DRadioColumn from "./../../../../../../shared/DRadioColumn";
import DDropzone from "./../../../../../../shared/DDropzone";
import DAutoCompleteCreate from "./../../../../../../shared/DAutoCompleteCreate/DAutoCompleteCreate";

import { getAllComorbidities } from "./../../../../../../../api/comorbidity";
import { listMedicines } from "./../../../../../../../api/medicine";
import { getAllSymptoms } from "./../../../../../../../api/symptom";
import { uploadHealthAttendanceFile } from "./../../../../../../../api/healthAttendance";
import { createAnamnesePreForm } from "./../../../../../../../api/anamnese";
import { createHealthAttendance } from "./../../../../../../../api/healthAttendance";
import LineCares from "./../../../../../../../api/lineCare";
import { listHealthAttendanceById } from "./../../../../../../../api/healthAttendance";
import ComorbidityFamily from "./../../../../../../../api/comorbidityFamilyHistory";
import { anamneseFormByFilter } from "./../../../../../../../api/anamnese";
import { getAll } from "./../../../../../../../api/healthAttendanceType";

import { required } from "./../../../../../../../helpers/validacoes";

import useError from "./../../../../../../../hooks/useError";

import { useAnamneseFormFilter } from "./../../../../../../../context/AnamneseFormFilterContext";
import { useNewHealthAttendance } from "./../../../../../../../context/NewHealthAttendance";

import Alergias from "./../../../../../../../assets/dados/alergias.json";


import Sintomas from "./Sintomas";
import AntecedentesFamiliares from "./AntecedentesFamiliares";
import Comorbidades from "./Comorbidades";
import Tabagismo from "./Tabagismo";
import DrogasIlicitas from "./DrogasIlicitas";

const useStyles = makeStyles((theme) => ({
  bg: {},
  card: {
    width: "100%",
    backgroundColor: "#f9f9f9",
  },
  alergias: {
    marginTop: theme.spacing(2),
  },
  medicoescard: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    borderRadius: "4px",
    marginTop: 16,
    fontSize: 14,
    boxShadow:
      "0px 1px 1px rgba(49, 34, 67, 0.12), 0px 2px 1px rgba(49, 34, 67, 0.1), 0px 1px 3px rgba(49, 34, 67, 0.18)",
  },
  medicoescards: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridGap: "10px",
    color: "#000 !important",
  },
  root: {
    display: "inline !important",
  },
  label: {
    color: "#000000 !important",
  },
  buttonSymptom: {
    minWidth: "15px !important",
    height: "34px !important",
    borderRadius: "100px !important",
    fontSize: "14px !important",
  },
  input_root: {
    width: "50px !important",
    marginRight: 16,
    "& input": {
      padding: 8,
      fontSize: 14,
    },
  },
  input_root_tempo: {
    width: "40px !important",
    height: "36px !important",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    "& input": {
      width: "40px !important",
      height: "36px !important",
      padding: "8px 12px 7px 12px",
      boxSizing: "border-box",
      fontSize: 14,
    },
  },
  input_root_tempo_maior: {
    width: "49px !important",
    height: "36px !important",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 4,
    "& input": {
      width: "49px !important",
      height: "36px !important",
      padding: "8px 13px 7px 12px",
      boxSizing: "border-box",
      fontSize: 14,
    },
  },
  autocomplete: {
    fontSize: 14,
  },
  gHeader: {
    paddingLeft: "12px !important",
    width: "18%",
    alignItems: "flex-end",
    display: "flex",
    position: "absolute !important",
    paddingBottom: "16px !important",
    top: "155px !important",
    fontSize: 16,
    color: theme.palette.primary.light,
  },
  icon: {
    cursor: "pointer",
  },
  labelError: {
    color: theme.palette.support.error,
    fontSize: 14,
  },
  labelInitial: {
    color: "initial",
    fontSize: 14,
  },
  checkboxInitial: {
    color: "initial",
  },
  checkboxError: {
    color: theme.palette.support.error,
  },
}));

const smokingTimeOptions = [
  { id: 1, value: "Ano(s)" },
  { id: 2, value: "Mês(es)" },
  { id: 3, value: "Dia(s)" },
];

const drinkOptions = [
  {
    value: "Abstinente",
    label: "Abstinente",
    note: "1 vez ao ano ou nunca",
    width: 170,
  },
  { value: "Raro", label: "Raro", note: "menos de 1 vez por mês", width: 170 },
  {
    value: "Ocasional",
    label: "Ocasional",
    note: "de 1 a 3 vezes por mês",
    width: 170,
  },
  {
    value: "Frequente",
    label: "Frequente",
    note: "de 1 a 4 vezes por semana",
    width: 170,
  },
  {
    value: "Muito Frequente",
    label: "Muito Frequente",
    note: "bebe todos os dias",
    width: 170,
  },
];

function PreAtendimento(props) {
  const classes = useStyles();

  let user_id = localStorage.getItem("uid");

  const { patient } = useNewHealthAttendance();

  const [groups, setGroups] = useState([]);
  const [healthAttendanceTypes, setHealthAttendanceTypes] = useState([]);
  const [patientFile, setPatientFiles] = useState([]);

  const [comorbiditiesData, setComorbiditiesData] = useState([]);
  const [comorbiditiesFamilyData, setComorbiditiesFamilyData] = useState([]);
  const [comorbidities, setComorbidities] = useState([]);
  const [comorbidity, setComorbidity] = useState([]);
  const [comorbidityFamilyHistory, setComorbidityFamilyHistory] = useState([]);
  const [comorbidityFamily, setComorbidityFamily] = useState([]);
  const [comorbidityFamilyAF, setComorbidityFamilyAF] = useState([]);

  const [symptomsData, setSymptomsData] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [symptomsDays, setSymptomsDays] = useState([]);

  const [lineOfCare, setLineOfCare] = useState();
  const [lineOfCareCheck, setLineOfCareCheck] = useState();

  const [healthAttendanceType, setHealthAttendanceType] = useState();
  const [healthAttendanceId, setHealthAttendanceId] = useState();
  const [anamneseId, setAnamneseId] = useState("");

  const [continuousUseMedications, setContinuousUseMedications] = useState([]);
  const [jsonListMedicines, setListMedicine] = useState([]);

  const [foodAllergiesOptions, setFoodAllergiesOptions] = useState(Alergias);
  const [foodAllergies, setFoodAllergies] = useState([]);
  const [drugAllergies, setDrugAllergies] = useState([]);

  const [physycalActivity, setPhysycalActivity] = useState("");
  const [smoking, setSmoking] = useState("");
  const [smoker, setSmoker] = useState({
    field_1: {
      time: null,
      period: null,
    },
    field_2: {
      amount: null,
    },
  });
  const [exSmoker, setExSmoker] = useState({
    field_1: {
      time: null,
      period: null,
    },
    field_2: {
      time: null,
      period: null,
    },
  });
  const [smoking_amount, setSmokingAmount] = useState(null);
  const [smoking_period, setSmokingPeriod] = useState(null);
  const [smoking_period_without, setSmokingPeriodWithout] = useState(null);

  const [alcoholic, setAlcoholic] = useState("");
  const [alcoholic_type, setAlcoholicType] = useState(null);
  // const [alcoholic_period, setAlcoholicPeriod] = useState(null);
  const [alcoholicPeriod, setAlcoholicPeriod] = useState({
    field_1: {
      time: null,
      period: null,
    },
  });

  const [drugs, setDrugs] = useState("");
  const [drugsList, setDrugsList] = useState([
    {
      type: null,
      use: null,
      inputValueType: "",
      inputValueUse: "",
    },
  ]);

  const [symptomItems, setSymptomItems] = useState([{ id: 0, days: null }]);

  const [lineCares, setLineCares] = useState(null);

  const [anamneseForm, setAnamneseForm] = useState([]);
  const [healthAttendanceAnamneseForm, setHealthAttendanceAnamneseForm] =
    useState(null);
  const [group, setGroup] = useState(null);
  const [serviceCharacter, setServiceCharacter] = useState(null);
  const [interconsultation, setInterconsultation] = useState(false);

  const [drinkstate, setdrinkState] = useState({
    checkedA: false,
    checkedB: false,
  });

  const handledrinkChange = (event) => {
    setdrinkState({ ...drinkstate, [event.target.name]: event.target.checked });
  };

  const carater = [
    { id: 1, value: "Imediata" },
    { id: 2, value: "Monitoramento" },
    { id: 3, value: "Orientação" },
    { id: 4, value: "Triagem" },
  ];

  const { filter, setFilter } = useAnamneseFormFilter();

  useEffect(() => {
    if(patient && patient.patient_has_group && patient.patient_has_group.length > 0)
        setGroups(patient.patient_has_group.filter(group => group.group !== null).map(group => {
            return {
                id: group.group.id,
                value: group.group.name
            }
        }))

  }, [patient])

  useEffect(
    function () {
      if (!lineCares || lineCares.length === 0) {
        setLineOfCareCheck(false);
        setLineOfCare(null);
      }
    },
    [lineCares]
  );

  useEffect(() => {
    listMedicines(localStorage.getItem("token"))
      .then((res) => {
        if (res.status) {
          setListMedicine([{ id: 0, name: "Desconhece" }, ...res.medicines]);
        }
      })
      .catch((err) => console.error(err));

    getAllComorbidities(localStorage.getItem("token"))
      .then((res) => {
        if (res.status) {
          setComorbiditiesData(...comorbiditiesData, res.comorbidity);
          setComorbidity(
            res.comorbidity.map((comorbidity) => {
              return {
                id: comorbidity.id,
                checked: false,
                observation: "",
              };
            })
          );
          setComorbidityFamilyAF(
            res.comorbidity.map((comorbidity) => {
              return {
                id: comorbidity.id,
                checked: false,
                observation: "",
              };
            })
          );
        } else {
          setComorbiditiesData(...comorbiditiesData, []);
        }
      })
      .catch((err) => console.error(err));

    ComorbidityFamily.getAll().then((data) => {
    if (data.status) {
        if (data.comorbidity && Array.isArray(data.comorbidity)) {
        var _comorbidity_family = data.comorbidity.map((x) => {
            return {
            id: x.id,
            observation: "",
            ...x,
            };
        });
        setComorbiditiesFamilyData(_comorbidity_family);
        }
    } else {
        setComorbiditiesFamilyData(...comorbiditiesFamilyData, []);
    }
    });

    getAllSymptoms(localStorage.getItem("token")).then((res) => {
      if (res.status) {
        setSymptomsData(res.symptoms.data);
      } else {
        setSymptomsData([]);
      }
    });
    localStorage.setItem("qtdSymptoms", 1);

    LineCares.listLineCare(localStorage.getItem("token")).then((res) => {
      if (res.status) {
        setLineCares(
          res.lineCare.data.map((x) => {
            return { id: x.id, value: x.name, active: x.active };
          })
        );
      }
    });

    getAll(localStorage.getItem("token")).then(res => {
        if(res.status) {
          setHealthAttendanceTypes(res.healthAttendanceTypes)          
        }
    })

    anamneseFormByFilter(localStorage.getItem("token"), { ...filter }).then(
      (res) => {
        setHealthAttendanceAnamneseForm(null);
        if (res.status) {
          setAnamneseForm(res.anamnese_forms);
        } else setAnamneseForm([]);
      }
    );
  }, []);

  useEffect(() => {
    if (filter && filter.time > 0) {
      anamneseFormByFilter(localStorage.getItem("token"), { ...filter }).then(
        (res) => {
          setHealthAttendanceAnamneseForm(null);
          if (res.status) {
            if (res.anamnese_forms.length === 0)
              toast.error(
                "Esse filtro não obteve nenhum formulário, sem formulários o atendimento não pode prosseguir."
              );

            setAnamneseForm(res.anamnese_forms);
          } else setAnamneseForm([]);
        }
      );
    }
  }, [filter]);

  function handleCheckBoxComorbidities(e) {
    if (e.target.checked) {
      setComorbidities([...comorbidities, parseInt(e.target.value)]);

      setComorbidity((old) => [
        ...old,
        { id: parseInt(e.target.value), observation: e.target.name },
      ]);
    } else {
      var old_comorbidities = [...comorbidities];
      old_comorbidities.splice(
        comorbidities.indexOf(parseInt(e.target.value)),
        1
      );
      setComorbidities(old_comorbidities);
      setComorbidity((old) => [
        ...old,
        { id: parseInt(e.target.value), observation: e.target.name },
      ]);
    }
  }

  function handleCheckBoxComorbiditiesFamily(e, familyIndex) {
    if (e.target.checked) {
      setComorbidityFamilyHistory([
        ...comorbidityFamilyHistory,
        parseInt(e.target.value),
      ]);

      setComorbidityFamily((old) => [
        ...old,
        {
          id: parseInt(e.target.value),
          observation: e.target.name,
          description: "",
        },
      ]);
    } else {
      var old_comorbidities_family_history = [...comorbidityFamilyHistory];

      old_comorbidities_family_history.splice(
        comorbidityFamilyHistory.indexOf(parseInt(e.target.value)),
        1
      );

      setComorbidityFamilyHistory(old_comorbidities_family_history);

      setComorbidityFamily((old) => [
        ...old,
        { id: parseInt(e.target.value), observation: e.target.name },
      ]);
    }
  }

  function handleCheckBoxLineOfCare(e) {
    setLineOfCare(null);
    setLineOfCareCheck(e.target.checked);
    if (!e.target.checked) setFilter({ ...filter, line_care: "" });
  }

  function getAnamneseId(healthAttendanceType) {
    setAnamneseId("");
    healthAttendanceTypes.map(function (item, i) {
      let anamnese = item.anamnese.filter(
        (anamnese) =>
          anamnese.health_attendance_type_id === healthAttendanceType
      );
      anamnese.map(function (a) {
        return setAnamneseId(a.id);
      });
    });
  }

  function addNewSymptomItem() {
    setSymptomItems([...symptomItems, { id: 0, days: 0 }]);
  }

  function removeNewSymptomItem(index) {
    let symptons = [...symptomItems];
    symptons.splice(index, 1);
    setSymptomItems(symptons);
    let days = [...symptomsDays];
    days.splice(index, 1);
    setSymptomsDays(days);
  }

  function setSymptomItemValue(position, field, value) {
    const updateSymptomItems = symptomItems.map((symptomItem, index) => {
      if (index === position) {
        return { ...symptomItem, [field]: value };
      }

      return symptomItem;
    });

    setSymptomItems(updateSymptomItems);
  }

  function attachPatientFile(healthAttendanceId) {
    if (patientFile && Array.isArray(patientFile) && healthAttendanceId) {
      patientFile.forEach((file) => {
        uploadHealthAttendanceFile(
          localStorage.getItem("token"),
          file[0],
          healthAttendanceId
        ).catch(console.error);
      });
    }
  }

  const [error, validarFormulario, setErrors] = useError({
    alcoholic: function () {
      if (!alcoholic || alcoholic === "Abstinente") return undefined;
      else {
        return (!drinkstate.checkedA && !drinkstate.checkedB) ||
          alcoholicPeriod.field_1.time <= 0 ||
          !alcoholicPeriod.field_1.period
          ? "Campo inválido"
          : undefined;
      }
    },
    symptoms: function () {
      let error = false;
      symptomItems.map((it) => {
        if (it.days <= 0 && it.id > 0) error = true;
      });

      return error ? "Campo obrigatório" : undefined;
    },
    drugs: function () {
      let error = false;
      if(drugs === "Não" || drugs === "" || !drugs)
        return undefined;
      else {
        console.log(drugsList);
        drugsList.map((dlist) => {
          if (!dlist.type || !dlist.use) error = true;
        });

        return error ? "Campo obrigatório" : undefined;
      }
    },
    healthAttendanceType: required,
    healthAttendanceAnamneseForm: required,
    serviceCharacter: required,
    smoking: function () {
      switch (smoking) {
        case "Ex-fumante":
          return exSmoker.field_1.time <= 0 ||
            !exSmoker.field_1.period ||
            exSmoker.field_2.time <= 0 ||
            !exSmoker.field_2.period
            ? "Campo inválido"
            : undefined;
          break;
        case "Fumante":
          return smoker.field_1.time <= 0 ||
            !smoker.field_1.period ||
            smoker.field_2.amount <= 0
            ? "Campo inválido"
            : undefined;
        default:
          return undefined;
      }
    },
  });

  function handleSuccess() {
    toast.success("Solicitação enviada com sucesso");
  }

  function handleWarn() {
    toast.warn("Dados incorretos ou não preenchidos");
  }

  function handleError() {
    toast.error("Error! Algo deu errado!");
  }

  function getData() {
    let medicine = continuousUseMedications.map(function (med) {
      return {
        id: med.id,
        name: med.value,
      };
    });

    let food_allergy = foodAllergies.map(function (food) {
      return {
        id: food.id,
        name: food.value,
      };
    });

    let medicine_allergy = drugAllergies.map(function (drug) {
      return {
        id: drug.id,
        name: drug.value,
      };
    });

    var data;

    data = {
      comorbidity: comorbidity
        .filter((y) => y.checked)
        .map((x) => {
          return {
            id: x.id,
            observation: x.observation,
          };
        }),
      symptom: symptomItems,
      // symptoms,
      // symptomsDays,
      comorbidity_family_history: comorbidityFamilyAF
        .filter((y) => y.checked)
        .map((x) => {
          return {
            id: x.id,
            observation: x.observation,
          };
        }),
      lineOfCare,
      healthAttendanceType,
      serviceCharacter: "imediato",
      health_attendance_id: healthAttendanceId,
      healthAttendanceAnamneseForm,
      anamneseId,
      medicine,
      food_allergy,
      medicine_allergy,
      physical_activity: physycalActivity,
      smoking,
      smoking_amount, // string "20 por dia"
      smoking_period, // string "6 anos (Periodo fumando)"
      smoking_period_without, // string | null  2 anos (Periodo sem fumar)
      alcoholic,
      alcoholic_type: null, // string "destilada" / "Destilada, Alguma coisa"
      alcoholic_period: null, // string "16 anos"
      drugs,
      drugs_list: drugsList,
      user_id,
    };

    if (smoking == "Ex-fumante") {
      data.smoking_period = `${exSmoker.field_1.time} ${
        exSmoker.field_1.period ? exSmoker.field_1.period.value : ""
      } (Período fumando)`;
      data.smoking_period_without = `${exSmoker.field_2.time} ${
        exSmoker.field_2.period ? exSmoker.field_2.period.value : ""
      } (Período sem fumar)`;
    } else if (smoking == "Fumante") {
      data.smoking_period = `${smoker.field_1.time} ${
        smoker.field_1.period ? smoker.field_1.period.value : ""
      }`;
      data.smoking_period_without = `${smoker.field_2.amount} por dia`;
    }

    if (drugs == "Não") {
      data.drugs_list = [];
    }

    if (alcoholic != "Abstinente") {
      data.alcoholic_type = `${
        drinkstate.checkedA
          ? drinkstate.checkedB
            ? "Fermentada, "
            : "Fermentada"
          : ""
      }${drinkstate.checkedB ? "Destilada" : ""}`;
      data.alcoholic_period = `${alcoholicPeriod.field_1.time} ${
        alcoholicPeriod.field_1.period
          ? alcoholicPeriod.field_1.period.value
          : ""
      }`;
    }

    return data;
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let data = getData();

    try {
      if (validarFormulario({ ...data })) {
        createHealthAttendance(localStorage.getItem("token"), {
          user_requesting_id: user_id,
          patient_id: patient ? patient.id : null,
          discharge_id: 1,
          health_attendance_status_id: 1,
          health_attendance_type_id: healthAttendanceType,
          interconsultation: false,
          anamnese_form_id: healthAttendanceAnamneseForm
            ? healthAttendanceAnamneseForm.id
            : null,
          group_id: group ? group.id : null,
          line_care_id: lineOfCare ? lineOfCare.id : null,
          interconsultation,
          service_character: serviceCharacter,
        })
          .then((res) => {
            if (res.status) {
              setHealthAttendanceId(res.healthAttendance.id);
              attachPatientFile(res.healthAttendance.id);
              data.health_attendance_id = res.healthAttendance.id;

              listHealthAttendanceById(
                localStorage.getItem("token"),
                data.health_attendance_id
              ).then((res) => {
                if (res.status) {
                  const healthAttendance = res.healthAttendance;

                  if (healthAttendance) {
                    sendData(data);
                    
                  } else {
                    handleError();
                  }
                } else {
                  handleError();
                }
              });
            }
          })
          .catch(console.error);
      } else {
        handleWarn();
      }
    } catch (e) {
      handleError();
    }
  }

  function sendData(data) {
    
    createAnamnesePreForm(localStorage.getItem("token"), data).then((res) => {
        if(res.status)
          toast.success("Atendimento criado com sucesso.")
          props.onHealthAttendance(true)
      }).catch((err) => {
            toast.error("Erro ao criar atendimento.")
      });    
  }

  return (
    <form id="anamnese-pre-form"
      autoComplete="off"
      onSubmit={handleSubmit}>      
        <Grid container spacing={2}>
			<div className="fonte_new_tab_full">
				<div className="separa_inputs_solicitacao" style={{marginBottom: 34.5}}>
					<div className="flex_1_inputs">
						<div className="flex_modal_inter">
							<div className="fonte_titulo_modal_inter">Nova Solicitação</div>					
						</div>
					</div>
				</div>
				<div className="separa_inputs_solicitacao">
					<div>
						<div className="checkbox_new_tab">
							{ (lineCares && lineCares.length > 0 && <>
								<FormControlLabel
									control={
										<Checkbox
										defaultChecked={false}
										color="primary"
										inputProps={{
											"aria-label": "Linha de cuidado",
										}}
										checked={lineOfCareCheck}
										onChange={handleCheckBoxLineOfCare}
										/>
									}
									label="Linha de cuidado"
								/>
								{error.lineOfCareCheck && <FormHelperText>{error.lineOfCareCheck}</FormHelperText>}
							</> ) }				
							
						</div>
					</div>
					{lineOfCareCheck && <div className="flex_1_inputs">
						<Box style={{ marginBottom: 26 }}>
							<DAutoComplete
								fullWidth
								placeholder="Linhas de cuidado"
								label="Linhas de cuidado"
								labelColor="black"
								options={lineCares}
								getOptionLabel={(option) => option.value}
								getOptionSelected={(option, value) =>
									value && option.id === value.id
								}
								onChange={(e, newValue) => {
									setLineOfCare(newValue);
									setFilter({
									...filter,
									time: new Date().getTime(),
									line_care: newValue ? newValue.id : "",
									});
								}}
								style={{ maginBottom: 16 }}
							/>
						</Box>
					</div> }					
					<div className="flex_1_inputs">
						{(groups && groups.length > 0) &&
							<Box style={{ marginBottom: 26 }}>
								<DAutoComplete
									id="group"
									label="Selecione o grupo de atendimento"									
									placeholder=""
									labelColor="black"
									fullWidth
									options={groups}
									value={group}
									getOptionLabel={(option) => option.value}
									getOptionSelected={(option, value) =>
										value && option.id === value.id
									}
									onChange={(event, newValue) => {
										setGroup(newValue);
									}}
									style={{ maginBottom: 16 }}
								/>
							</Box>
						}
					</div>
					<div className="flex_1_inputs">
						<Box style={{ marginBottom: 26 }}>
							<DAutoComplete
								id="tipo-atendimento"
								label="Selecione o tipo de atendimento"
								placeholder=""
								labelColor="black"
								fullWidth={true}
								error={error.healthAttendanceType}
								helperText={error.healthAttendanceType}
								options={
								healthAttendanceTypes && healthAttendanceTypes.length
									? healthAttendanceTypes.map(function (item) {
										return { id: item.id, value: item.name };
									})
									: []
								}
								getOptionLabel={(option) => option.value}
								getOptionSelected={(option, value) =>
								value && option.id === value.id
								}
								onChange={(event, newValue) => {
								delete error.healthAttendanceType;
								setHealthAttendanceType(newValue ? newValue.id : "");

								if (newValue) {
									getAnamneseId(newValue.id);
								}
								}}
							/>
						</Box>
					</div>
					<div className="flex_1_inputs">
						<Box style={{ marginBottom: 26 }}>
							<DAutoComplete
								id="anamnese-from"
								label="Selecione o formulário de anamnese"
								placeholder=""
								labelColor="black"
								fullWidth={true}
								error={error.healthAttendanceAnamneseForm}
								helperText={error.healthAttendanceAnamneseForm}
								options={
								anamneseForm.length > 0
									? anamneseForm.map(function (item) {
										return { id: item.id, value: item.name };
									})
									: []
								}
								getOptionLabel={(option) => option.value}
								getOptionSelected={(option, value) =>
								value && option.id === value.id
								}
								value={healthAttendanceAnamneseForm}
								onChange={(event, newValue) => {
								delete error.healthAttendanceAnamneseForm;
								setHealthAttendanceAnamneseForm(newValue);
								}}
							/>
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="main_flex_new_tab mt_35_new_tab">
					<div className="fonte_new_tab  text-primary">Sintomas</div>
				</div>
				<div className="flex_separa_solciitacao_menor mt_35_new_tab">
					<div className="flex_1_inputs">						
						<Box>
							{symptomItems.map(function (item, i) {
								return (
								<Sintomas
									key={`sintomas-key-${i}`}
									index={i}
									id={`sintoma-${i}`}
									error={error.symptoms}
									onRemoveNewSymptomItem={(e) =>
									removeNewSymptomItem(i)
									}
									onAddNewSymptomItem={addNewSymptomItem}
									totalSintomas={symptomItems.length}
									sintomaId={item.id}
									sintomaDay={item.days}
									onChange={(sin) => {
									delete error.symptoms;

									let new_symptom = [...symptomItems];
									new_symptom[i] = sin;
									setSymptomItems(new_symptom);
									}}
									sintomas={symptomsData.map(function (item) {
									return {
										id: item.id,
										value: item.description,
									};
									})}
								/>
								);
							})}
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Medicamentos de uso contínuo
				</div>
				<div className="separa_inputs_solicitacao">
					<div className="flex_1_inputs">
						<Box
							className="mult-complet-med-uso-continuo"
							style={{ marginTop: 10, marginBottom: 10 }}>
							<DMultAutoComplete
								placeholder="Medicamentos de uso contínuo"
								label="Medicamentos de uso contínuo"
								labelColor="black"
								error={error.continuousUseMedications}
								helperText={error.continuousUseMedications}
								options={jsonListMedicines.map(function (item) {
								return {
									id: item.id,
									value: item.name,
								};
								})}
								getOptionLabel={(option) => option.value}
								getOptionSelected={(option, value) =>
								value && option.id === value.id
								}
								value={continuousUseMedications}
								onChange={(event, newValue) => {
								delete error.continuousUseMedications;
								setContinuousUseMedications(newValue);
								}}
							/>
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Alergias
				</div>
				<div className="separa_inputs_solicitacao">
					<div className="flex_1_inputs">
						<Box
							className="alergias"
							style={{ marginTop: 10, marginBottom: 20 }}>
							<strong
								style={{
								marginBottom: 8,
								display: "block",
								fontSize: 16,
								fontWeight: 500,
								}}
							>
								Alimentar{" "}
							</strong>

							<DAutoCompleteCreate
								id="alergia-alimentar"
								placeholder="Alergia Alimentar"
								labelColor="black"
								error={error.foodAllergies}
								helperText={error.foodAllergies}
								value={foodAllergies}
								options={foodAllergiesOptions}
								onNewChange={(values) => {
								delete error.foodAllergies;
								setFoodAllergies(values);
								}}
							/>
						</Box>
						<Box className="alergias">
							<strong
								style={{
								marginBottom: 8,
								display: "block",
								fontSize: 16,
								fontWeight: 500,
								}}
							>
								Medicamentosa{" "}
							</strong>

							<DMultAutoComplete
								placeholder="Medicamentosas"
								label="Medicamentosa"
								labelColor="black"
								error={error.drugAllergies}
								helperText={error.drugAllergies}
								options={jsonListMedicines.map(function (item) {
								return {
									id: item.id,
									value: item.name,
								};
								})}
								getOptionLabel={(option) => option.value}
								getOptionSelected={(option, value) =>
								value && option.id === value.id
								}
								value={drugAllergies}
								onChange={(event, newValue) => {
								delete error.drugAllergies;
								setDrugAllergies(newValue);
								}}
							/>
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Comorbidades
				</div>
				<div className="separa_inputs_solicitacao">
					<div className="flex_1_inputs">
						<Box style={{ paddingTop: 10, display: "grid" }}>
							{comorbiditiesData.length > 0 ? (
								<Grid container>
									{comorbiditiesData.map((comorbiditie, index) => (
										<Grid item>
											<Comorbidades
												key={`comorbidades-${index}`}
												label={comorbiditie.description}
												value={comorbidity[index]}
												onUpdate={(dados) => {
													let new_comorbidity = [...comorbidity];
													new_comorbidity[index].checked = dados.checked;
													new_comorbidity[index].observation =
													dados.observation;
													setComorbidity(new_comorbidity);
												}} />
										</Grid>
									))}
								</Grid>
							) : (
								<Typography
								variant="body"
								className="text-regular text-st"
								>
								Não existem comorbidades cadastradas
								</Typography>
							)}
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Antecedentes Familiares (AF)
				</div>
				<div className="separa_inputs_solicitacao">
					<div className="flex_1_inputs">
						<Box style={{ paddingTop: 10, display: "grid" }}>
							{comorbiditiesData.length > 0 ? (
								<Grid container>
									{comorbiditiesData.map((comorbiditie, index) => (
										<Grid item>
											<AntecedentesFamiliares
												key={`antecedentes-familiares-${index}`}
												label={comorbiditie.description}
												value={comorbidityFamilyAF[index]}
												onUpdate={(dados) => {
													let new_comorbidity_family = [
													...comorbidityFamilyAF,
													];
													new_comorbidity_family[index].checked =
													dados.checked;
													new_comorbidity_family[index].observation =
													dados.observation;
													setComorbidityFamilyAF(new_comorbidity_family);
												}} />
										</Grid>
									))}
								</Grid>
							) : (
								<Typography
								variant="body"
								className="text-regular text-st"
								>
								Não existem comorbidades cadastradas
								</Typography>
							)}
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Upload de arquivo
				</div>
				<div className="separa_inputs_solicitacao">
					<div className="flex_1_inputs">
						<Box style={{ marginTop: 30, marginBottom: 20 }}>
							<DDropzone
								formatMessage="Anexe arquivos de imagem até 10 MB"
								maxFiles={5}
								multiple
								emit={(files) => {
								setPatientFiles((_files) => {
									return [..._files, files];
								});
								}}
								onError={(message) => toast.error(message)}
								accept="image/*, application/pdf"
							/>
						</Box>
					</div>
				</div>
				<div className="mt_35_new_tab">
					<Divider variant="fullWidth" className="divide_new_tab" />
				</div>
				<div className="fonte_new_tab text-primary mt_35_new_tab">
					Hábitos Sociais
				</div>
				<div className="box_info_nova_solicitacao mt_35_new_tab">
					<div className="fonte_preta_subtitulo_new_tabs">
						Atividade Física
					</div>
					<div className="box_info_flex_nova_solicitacao mt_35_new_tab">
						<DRadio
							inline
							name="physycal-activity"
							onChange={(e) => {
								setPhysycalActivity(e.target.value);
							}}
							value={physycalActivity}
							labelClass={classes.label}
							options={[
								{ value: "Não pratica", label: "Não pratica" },
								{ value: "de 1 a 2 vezes por semana", label: "de 1 a 2 vezes por semana"},
								{ value: "de 3 a 4 vezes por semana", label: "de 3 a 4 vezes por semana" },
								{ value: "de 5 ou mais vezes por semana", label: "de 5 ou mais vezes por semana" }
							]} />
					</div>
				</div>
				<div className="box_info_nova_solicitacao mt_35_new_tab">
					<Tabagismo
						error={error.smoking}
						onChange={(tipo, tempo, tempoParado, quantidade) => {
						delete error.smoking;
						setSmoking(tipo);
						switch (tipo) {
							case "Ex-fumante":
							let new_ex_smoker = {
								field_1: {
								period: tempo.tipo,
								time: tempo.valor,
								},
								field_2: {
								period: tempoParado.tipo,
								time: tempoParado.valor,
								},
							};
							setExSmoker(new_ex_smoker);
							break;
							case "Fumante":
							let new_smoker = {
								field_1: {
								period: tempo.tipo,
								time: tempo.valor,
								},
								field_2: {
								amount: quantidade,
								},
							};
							setSmoker(new_smoker);
							break;
						}
						}}
					/>
				</div>
				<div className="box_info_nova_solicitacao mt_35_new_tab">
					<div className="fonte_preta_subtitulo_new_tabs">
						Uso de bebida alcoólica
					</div>
					<div className="box_info_flex_nova_solicitacao mt_35_new_tab">
						<Grid container spacing={1}>
							<Grid item md={12} style={{paddingLeft: 10}}>
								<DRadio
									inline
									name="alcoholic-beverage-use"
									onChange={(e) => setAlcoholic(e.target.value)}
									value={alcoholic}
									labelClass={classes.label}
									options={drinkOptions}/>
							</Grid>
							{alcoholic && alcoholic != "Abstinente" ? (
								<>
									<Grid
											item
											sm={4}
											style={{
											display: "flex",
											alignItems: "center",
											marginBottom: 8,
											}}>
											Tipo de bebida:
										</Grid>
										<Grid item sm={8} style={{ marginBottom: 8 }}>
											<FormGroup row>
												<FormControlLabel
													control={
													<Checkbox
														classes={{
														colorSecondary:
															error.alcoholic &&
															!drinkstate.checkedA &&
															!drinkstate.checkedB
															? classes.checkboxError
															: classes.checkboxInitial,
														}}
														checked={drinkstate.checkedA}
														onChange={handledrinkChange}
														name="checkedA"
													/>
													}
													label="Fermentada"
													classes={{
													label:
														error.alcoholic &&
														!drinkstate.checkedA &&
														!drinkstate.checkedB
														? classes.labelError
														: classes.labelInitial,
													}}
													onChange={(e) => {
													setAlcoholicType(null);
													}}
												/>
												<FormControlLabel
													control={
													<Checkbox
														classes={{
														colorSecondary:
															error.alcoholic &&
															!drinkstate.checkedA &&
															!drinkstate.checkedB
															? classes.checkboxError
															: classes.checkboxInitial,
														}}
														checked={drinkstate.checkedB}
														onChange={handledrinkChange}
														name="checkedB"
													/>
													}
													label="Destilada"
													classes={{
													label:
														error.alcoholic &&
														!drinkstate.checkedA &&
														!drinkstate.checkedB
														? classes.labelError
														: classes.labelInitial,
													}}
													onChange={(e) => {
													setAlcoholicType(null);
													}}
												/>
											</FormGroup>
										</Grid>
										<Grid
											item
											sm={4}
											style={{ display: "flex", alignItems: "center" }}>
											Há quanto tempo?
										</Grid>
										<Grid item sm={8}>
											<TextField
												label=""
												variant="outlined"
												placeholder="00"
												inputProps={{ maxLength: 2 }}
												error={
													error.alcoholic &&
													alcoholicPeriod.field_1.time <= 0
												}
												value={alcoholicPeriod.field_1.time}
												onChange={(e) => {
													var value = e.target.value.replace(/[^0-9]/g, "");
													setAlcoholicPeriod({
													...alcoholicPeriod,
													field_1: {
														...alcoholicPeriod.field_1,
														time: value,
													},
													});
												}}
												classes={{
													root: classes.input_root_tempo,
												}}
												/>
											<DAutoComplete
												label=""
												placeholder=""
												labelColor="black"
												openText="Visualizar listagem"
												style={{ marginTop: -16, width: 120 }}
												classes={{ input: classes.autocomplete }}
												error={
													error.alcoholic && !alcoholicPeriod.field_1.period
												}
												onChange={(e, x) => {
													setAlcoholicPeriod({
													...alcoholicPeriod,
													field_1: {
														...alcoholicPeriod.field_1,
														period: x,
													},
													});
												}}
												options={[
													{ id: 1, value: "Ano(s)" },
													{ id: 2, value: "Mês(es)" },
													{ id: 3, value: "Dia(s)" },
												]}
												getOptionLabel={(option) => option.value}
												getOptionSelected={(option, value) =>
													value && option.id === value.id
												}
												/>
										</Grid>
									</>									
								) : (
									""
								)}
						</Grid>
					</div>
				</div>

				<div className="box_info_nova_solicitacao mt_35_new_tab">
					<div className="fonte_preta_subtitulo_new_tabs">
						Uso de drogas ilícitas
					</div>
					<div className="box_info_flex_nova_solicitacao mt_35_new_tab">
						<Grid container spacing={1}>
							<Grid item md={12} style={{paddingLeft: 10}}>                
								<DRadio
									inline
									name="drugs-use"
									onChange={(e) => setDrugs(e.target.value)}
									value={drugs}
									labelClass={classes.label}
									options={[
										{ value: "Não", label: "Não" },
										{ value: "Sim", label: "Sim" },
									]} />
							</Grid>
							{drugsList && drugs == "Sim" ? (
								<Grid item md={12} st>
									{drugsList.map((item, index) => {
										return (
											<DrogasIlicitas
												key={`DrogasIlicitas-${index}`}
												error={error.drugs}
												index={index}
												value={item}
												totalDrugs={drugsList.length}
												onRemoveDrug={(e) => {
													let new_drugs_list = [...drugsList];
													new_drugs_list.splice(index, 1);
													setDrugsList(new_drugs_list);
												}}
												onAddDrug={(e) => {
													let new_drugs_list = [...drugsList];
													new_drugs_list.push({
													type: null,
													use: null,
													inputValueType: "",
													inputValueUse: "",
													});
													setDrugsList(new_drugs_list);
												}}
												onChange={(
													type,
													use,
													inputValueType,
													inputValueUse
												) => {
													delete error.drugs;

													let new_drugs_list = [...drugsList];
													new_drugs_list[index].type = type;
													new_drugs_list[index].use = use;
													new_drugs_list[index].inputValueType =
													inputValueType;
													new_drugs_list[index].inputValueUse =
													inputValueUse;
													setDrugsList(new_drugs_list);
												}}/>
											);
									})}
								</Grid>								
							) : (
								""
							)}
						</Grid>
					</div>
				</div>
			</div>
		</Grid>
      { props.children }
    </form>
  );
}

PreAtendimento.defaultProps = {
    sideBar: false,
    onHealthAttendance: function(){
        console.log("onHealthAttendance")
    }
}

export default PreAtendimento;
