import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Clear} from "@material-ui/icons";
import DButton from "../DButton";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: 304,
            // top: "10%",
            // left: "50%",
            // marginLeft: "-152px",
            backgroundColor: theme.palette.background.snow,
            border: '1px solid #949494',
            // boxShadow: theme.shadows[5],
            borderRadius: 15
        },
        headermodal: {
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px 15px',
            borderBottom: 'solid 1px #C7C7C7'
        },
        footermodal: {
            height: 50,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '3px 15px',
            borderTop: 'solid 1px #C7C7C7'
        }
    }),
);

const useStylesFullWidth = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: '100%',
            maxWidth: 770,
            // top: "15px",
            // left: "50%",
            // marginLeft: "-385px",
            backgroundColor: theme.palette.background.snow,
            border: '1px solid #949494',
            // boxShadow: theme.shadows[5],
            borderRadius: 15
        },
        paperFull: {
            position: 'absolute',
            width: '100%!important',
            height: '100%!important',
            // top: "15px",
            // left: "50%",
            // marginLeft: "-385px",
            backgroundColor: theme.palette.background.snow,
            border: '1px solid #949494',
            // boxShadow: theme.shadows[5],
            borderRadius: 0
        },
        headermodal: {
            height: '5%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px 15px',
            borderBottom: 'solid 1px #C7C7C7'
        },
        footermodal: {
            height: '5%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '3px 15px',
            borderTop: 'solid 1px #C7C7C7'
        }
    }),
);

export default function SimpleModal(props) {
    const classes =  useStyles();
    const classesFull =  useStylesFullWidth();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={ // use customStyle={classes.yourStyle} to customize;
            props.fullWidth ? classesFull.paper : (props.fullPage ? classesFull.paperFull : classes.paper)
            }>
            <div className={ props.fullWidth || props.fullPage ? classesFull.headermodal : classes.headermodal }>
                { props.title ? <h2 class="simple-modal-title text-mt text-regular">{ props.title }</h2> : <h2>&nbsp;</h2> }
                <Clear style={{color:"#949494"}} onClick={ e => props.onClose(true) } />
            </div>
            {props.children}
            <SimpleModal />
            { !props.notButtonClose ?
                <div className={ `${ props.fullWidth || props.fullPage ? classesFull.footermodal : classes.footermodal } tema-light` }>
                    <DButton onClick={ e => props.onClose(true) } variant="primary">Fechar</DButton>
                </div>
                :
                ''    
            }            
        </div>
    );

    return (
        <div>
            <Modal
                open={props.open}
                onClose={e => props.onClose(true)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}