import { Grid, LinearProgress, Typography } from "@material-ui/core";
import { Info, Visibility, VisibilityOff, WarningRounded, CheckCircleOutlineOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import DInput from "../../../shared/DInput";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme, makeStyles } from "@material-ui/core";

export function Step1(props) {
    const [reenviar, setReenviar] = useState(10);
    const theme = useTheme()
    useEffect(() => {
        if(props.started) {
            let interval = setInterval(() => setReenviar(value => value - 1), 1000)
    
            return () => clearInterval(interval)
        }

    }, [props.started])


    return (
        <form onSubmit={ form => {
                            form.preventDefault();
                            // handleSendForm();
                    }}>
                <Grid item xs={12} className="d-redefinir-box">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className="text-lt text-regular text-center" style={{ marginBottom: 20 }}>Para redefinir sua senha, <br/>Insira o código de 5 dígitos enviado para</Typography>
                            <Typography className="text-lt text-medium text-center">{ localStorage.getItem('email') }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" style={{ height: 200 }}>
                        <Grid item xs={12} style={{ marginBottom: 50 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_1" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        props.setCodigo(digitos => ({...digitos, [0]: e.target.value}))
                                        document.querySelector(`input[name="digito_2"]`).focus();
                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_2" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        props.setCodigo(digitos => ({...digitos, [1]: e.target.value}))
                                        document.querySelector(`input[name="digito_3"]`).focus();
                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_3" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        props.setCodigo(digitos => ({...digitos, [2]: e.target.value}))
                                        document.querySelector(`input[name="digito_4"]`).focus();

                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_4" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        props.setCodigo(digitos => ({...digitos, [3]: e.target.value}))
                                        document.querySelector(`input[name="digito_5"]`).focus();

                                    } } />
                                </Grid>
                                <Grid item style={{ width:50 }}>
                                    <DInput name="digito_5" inputProps={ { maxLength: 1, className: 'text-center' } } error={ props.error } notIcon={ true } onChange={ e => {
                                        props.setCodigo(digitos => ({...digitos, [4]: e.target.value}))
                                    } } />
                                </Grid>
                            </Grid>
                            { props.error ? <Grid container><Grid item md={12} className="text-error"><Typography className="text-st text-medium" style={{marginTop: 10}}>Código inválido</Typography></Grid></Grid> : '' }
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: 25 }}>
                            { reenviar > 0
                                ? <Typography className="text-mt text-regular text-disabled">Enviar código novamente em 00:{ reenviar.toString().padStart(2, "0") }</Typography>
                                : <a href="#" onClick={ e => {
                                    props.reenviar('email');
                                    setReenviar(10);
                                } } className="text-link"><Typography className="text-mt text-bold">{ props.reenviando ? <LinearProgress /> : 'Reenviar código por e-mail' }</Typography></a>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            { reenviar > 0
                                ? <Typography className="text-mt text-regular text-disabled">Enviar código novamente em 00:{reenviar.toString().padStart(2, "0")}</Typography>
                                : <a href="#" onClick={e => {
                                    props.reenviar('sms');
                                    setReenviar(10);
                                }} className="text-link"><Typography className="text-mt text-bold">{props.reenviando ? <LinearProgress/> : 'Enviar código por SMS'}</Typography></a>
                            }
                        </Grid>
                    </Grid>
                </Grid>
        </form>
    )
}

export function Step2(props) {

    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            maxWidth: 150,
            fontSize: 14,
            lineHeight: '21px',
            backgroundColor: theme.palette.common.black,
        }
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    const [type1, setType1] = useState("password")
    const [type2, setType2] = useState("password")
    const theme = useTheme()

    // useEffect(() => {
    //     if(props.error){
    //         setType1('text');
    //         setType2('text');
    //     }
    // }, [props.error])
    
    return (
        <Grid item xs={12} className="d-redefinir-box">
            <Grid container>
                <DInput
                    label="Nova senha"
                    type={type1}
                    error={props.error}
                    // success={true}
                    required={props.required}
                    // disabled={true}
                    fullWidth={true}
                    // onClick={ e => console.log(e.target.value) }
                    onChange={ e => props.setPassword(e.target.value) }
                    positionIcon="end"
                    value={props.password}
                    icon={ type1 === 'text' ? <Visibility onClick={e => setType1('password')} /> : <VisibilityOff onClick={e => setType1('text')} /> }
                />

                <span className="head-text">Sua senha deve conter no mínimo:</span>

                <section className="min-request">
                    <div>
                        <WarningRounded style={props.validationProgress.minimumCharacters ? {fill: `${theme.palette.support.check}`} : {fill: 'yellow'}} />
                        <span>8 caracteres</span>
                    </div>
                    <div>
                        <WarningRounded style={props.validationProgress.oneUppercaseAndDowncase ? {fill: `${theme.palette.support.check}`} : {fill: 'yellow'}} />
                        <span>Uma letra maiúscula e uma minúscula</span>
                    </div>
                    <div>
                        <WarningRounded style={props.validationProgress.oneNumber ? {fill: `${theme.palette.support.check}`} : {fill: 'yellow'}} />
                        <span>Um número</span>
                    </div>
                    <div>
                        <WarningRounded style={props.validationProgress.oneSpecialCharacter ? {fill: `${theme.palette.support.check}`} : {fill: 'yellow'}} />
                        <span>Um caractere especial</span>
                        <BootstrapTooltip title="Caracteres especiais: ! @ # $ % & * ( ) - + | \ / ? { } [ ]" placement="right-start">
                            <Info style={{fill: `${theme.palette.primary.light}`}} />
                        </BootstrapTooltip>
                    </div>
                </section>

                <DInput
                    label="Nova senha"
                    type={type2}
                    error={props.error}
                    // success={true}
                    required={props.required}
                    // disabled={true}
                    
                    fullWidth={true}
                    // onClick={ e => console.log(e.target.value) }
                    onChange={ e => props.setConfirmPassword(e.target.value) }
                    positionIcon="end"
                    value={props.confirmPassword}
                    icon={ type2 === 'text' ? <Visibility onClick={e => setType2('password')} /> : <VisibilityOff onClick={e => setType2('text')} /> }
                />


            </Grid>
        </Grid>
    )
}

export function Step3(props) {

    const [type, setType] = useState("password")
    
    return (
        <Grid item xs={12} className="d-redefinir-box">
            <Grid container justifyContent="center" style={{margin: '2em 0'}}>

                <section className="success-box">
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <CheckCircleOutlineOutlined style={{fontSize: '5em', fill: 'green'}} />
                    </div>

                    <span style={{fontSize: '1.04em'}}>Senha redefinida com sucesso</span>
                </section>
            </Grid>
        </Grid>
    )
}