import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Clear} from "@material-ui/icons";
import DButton from "../../../shared/DButton";
import { CircularProgress, Grid } from '@material-ui/core';


function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            position: 'absolute',
            width: 304,
            // top: "10%",
            // left: "50%",
            // marginLeft: "-152px",
            backgroundColor: theme.palette.background.snow,
            border: `1px solid ${theme.palette.neutral.midgrey}`,
            // boxShadow: theme.shadows[5],
            borderRadius: 15
        },
        headermodal: {
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '3px 15px',
            borderBottom: `1px solid ${theme.palette.neutral.midgrey}`
        },
        footermodal: {
            height: 50,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '3px 15px',
            borderTop: `1px solid ${theme.palette.neutral.midgrey}`
        },

        footerButtons: {
            marginRight: "10px"
        },

        successButton: {
            backgroundColor: `${theme.palette.primary.light} !important`
        },
        clearClass:{
            color: theme.palette.neutral.midgrey, 
            cursor: "pointer"
        }
    }),
);

export default function SimpleModal(props) {
    const classes =  useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    const body = (
        <div style={modalStyle} className={ // use customStyle={classes.yourStyle} to customize;
            props.customStyle || classes.paper
        }>
            <div className={ props.customStyleHeader || classes.headermodal }>
                { props.title ? <h2 class="simple-modal-title text-mt text-bold">{ props.title }</h2> : <h2>&nbsp;</h2> }
                <Clear className={classes.clearClass} onClick={ e => props.onClose(true) } />
            </div>
            {props.children}
            <SimpleModal />
            {
                props.step != 2 && (<div className={ `${classes.customStyleFooter || classes.footermodal} tema-light footer-modal` }>
                            <Grid container justifyContent="flex-end">
                                    {
                                        props.step == 0 ? (
                                            <>
                                                <DButton variant="primary" disabled={!props.formValid} type="submit" size="sm" onClick={e => props.formValid && props.setStep(1)}>{ props.loading ? <CircularProgress color="inherit" /> : 'Avançar' }</DButton>
                                            </>
                                        ) : (
                                            <>
                                                <DButton variant="secondary" multiClass={classes.footerButtons} onClick={ e => props.setStep(0) } size="sm">Voltar</DButton>
                                                <DButton variant="primary"  multiClass={classes.successButton} type="submit" size="sm" onClick={e => props.sendRedefinir()}>{ props.loading ? <CircularProgress color="inherit" /> : 'Redefinir senha' }</DButton>
                                            </>
                                        )
                                    }
                            </Grid>
                    </div>)
            }
        </div>
    );

    return (
        <div>
            <Modal
                open={props.open}
                onClose={e => props.onClose(true)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                {body}
            </Modal>
        </div>
    );
}