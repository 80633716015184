import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DButton from '../shared/DButton';
import brokenImg from '../../assets/img/broken.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    page: {
        background: theme.palette.background.snow,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    title: {
        fontWeight: 400,
        fontSize: 40,
        color: theme.palette.primary.light,
        marginBottom: 28
    },
    text: {
        fontSize: 16,
        lineHeight: "24px",
        color: theme.palette.neutral.darkgray,
        textAlign: "center",
        marginBottom: 28

    },
    broken: {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100vw",
        height: 8,
        backgroundColor: theme.palette.primary.light,
        display: "flex",
        justifyContent: "center"
    },
    img: {
        height: 8
    }
}));
function ErrorPage(props) {
    const classes = useStyles();

    return (
        <>
            <div className={ classes.page }>
                <h1 className={ classes.title }>Ops...</h1>
                <p className={ classes.text }>A página que você procura, não foi encontrada.<br />Ela pode ter sido removida :(</p>
                <DButton onClick={e => props.voltar()} variant="card" size="md" icon={ <ArrowBackIcon fontSize="small" /> }>
                    Voltar para a página inicial
                </DButton>
            </div>
            <div className={ classes.broken }>
                <img src={ brokenImg } className={ classes.img } />
            </div>
        </>
    )
}

export default ErrorPage;