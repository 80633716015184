import env from "react-dotenv";
import axios from "axios";

class LineCare {

    token;

    constructor(){
        localStorage.getItem("token") ? this.token = localStorage.getItem("token") : this.token = null;
    }

    
    // listPatientLineCares (patientId) {
    //     return axios.get(`${env.API_URL}/patient/${patientId}/patient-line-care?token=${this.token}`)
    //         .then(res => res.data);
    // }

    async getLineCaresByPage(page){

        var url = new URL(`${env.API_URL}/line-care`)

        url.searchParams.append("page", page);

        url.searchParams.append("token", this.token ? this.token : localStorage.getItem("token"));

        const res = await axios.get(url);
        return res.data;
    }

    async getPages(data){
        if(!data) throw data;

        var lineCares = [data];

        if(data.lineCare){
            if(data.lineCare.total === 1) return data;

            for (var x = 1; x <= data.lineCare.total; x++){
                let response = await this.getLineCaresByPage(this.token ? this.token : localStorage.getItem("token"), x);
                lineCares.push(response);
            }

            return lineCares;
        }
    }

    async listLineCare(token = undefined){
        const res = await axios.get(`${env.API_URL}/line-care?token=${token ? token : this.token}`);
        return res.data;
    }

    async getAllLineCare(token = undefined){
        const res = await axios.get(`${env.API_URL}/all-line-care?token=${token ? token : this.token}`);
        return res.data;
    }

    async createLineCare(body, token = undefined){
        const res = await axios.post(`${env.API_URL}/line-care?token=${ token ? token : this.token}`, body);
        return res.data;
    }

    async putLineCareById(body, id, token = undefined){

        /* Body
        {
            "name": "Line care",
            "active": true,
        } */

        const res = await axios.put(`${env.API_URL}/line-care/${id}?token=${token ? token : this.token}`, body);
        return res.data;
    }

    async deleteLineCare(id, token = undefined){
        return axios.delete(`${env.API_URL}/line-care/${id}?token=${token ? token : this.token}`)
            .then(res => res.data);
    }

    async listPatientLineCares (patientId, token = undefined) {
        return axios.get(`${env.API_URL}/patient/${patientId}/patient-line-care?token=${token ? token : this.token}`)
            .then(res => res.data);
    }

    async vinculateNewLineCare(patientId, line_care_id, token = undefined){
        return axios.post(`${env.API_URL}/patient/${patientId}/patient-line-care?token=${token ? token : this.token}`,
        {
            line_care_id
        })
            .then(res => res.data);
    }

    async vinculateAllLineCare(token = undefined, patientId, body){
        return axios.post(`${env.API_URL}/patient/${patientId}/all-patient-line-care?token=${ token ? token : this.token}`, body)
            .then(res => res.data);
    }

    async deletePatientLineCare(id, token = undefined){
        return axios.delete(`${env.API_URL}/patient/patient-line-care/${id}?token=${token ? token : this.token}`)
            .then(res => res.data);
    }

    async deleteAllPatientLineCares(patientId, token = undefined){
        return axios.delete(`${env.API_URL}/patient/${patientId}/patient-line-care?token=${ token ? token : this.token}`)
            .then(res => res.data);
    }


}



export default new LineCare();