import React from 'react';
import {Typography, Container, Grid, Card, CardContent} from "@material-ui/core";
import DRadio from "../shared/DRadio";

function Radios() {

    const style = {
        light: {
            background: '#FFF'
        },
        dark: {
            background: '#272727'
        },
        textLight: {
            color: '#FFF'
        }
    }
    const [value1, setValue1] = React.useState('Female');
    const [value2, setValue2] = React.useState('');
    const [value3, setValue3] = React.useState('Option text');
    const [value4, setValue4] = React.useState('Option text');
    const [value5, setValue5] = React.useState('');
    const [value6, setValue6] = React.useState('Outros');

    return (
        <>
            <Container className="tema-light" style={ style.light }>
                <Typography variant="h3">Radio Buttons (Light)</Typography>
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Atom (Átomo)</Typography>
                                <DRadio label="Exemplo"
                                        name="exemplo1"
                                        onChange={ e => setValue1(e.targe.value) }
                                        onChangeOutros={ e => {/*console.log(e.target.value, 'outros')*/} }
                                        value={ value1 }
                                    // others={ true }
                                        options={[
                                            { value: 'Female', label: '' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Default (Padrão)</Typography>
                                <DRadio label="Exemplo 2"
                                        name="exemplo2"
                                        onChange={ e => setValue2(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value2 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Hover/Focus Default - Clicked Selected</Typography>
                                <DRadio label="Exemplo 3"
                                        name="exemplo3"
                                        onChange={ e => setValue3(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value3 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Desabilitado e selecionado</Typography>
                                <DRadio label="Exemplo 4"
                                        name="exemplo4"
                                        onChange={ e => setValue4(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value4 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text', disabled: true }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Disabled (Desabilitado)</Typography>
                                <DRadio label="Exemplo 5"
                                        name="exemplo5"
                                        onChange={ e => setValue5(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value5 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text', disabled: true }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card>
                            <CardContent>
                                <Typography>Selected Editable (Selecionado editável)</Typography>
                                <DRadio label="Exemplo 6"
                                        name="exemplo6"
                                        onChange={ e => setValue6(e.target.value) }
                                        //onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value6 }
                                        others={ true }
                                        options={[]} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container className="tema-dark" style={ style.dark }>
                <Typography variant="h3" style={style.textLight}>Radio Buttons (Dark)</Typography>
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Atom (Átomo)</Typography>
                                <DRadio label="Exemplo"
                                        name="exemplo1"
                                        onChange={ e => setValue1(e.targe.value) }
                                        //onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value1 }
                                    // others={ true }
                                        options={[
                                            { value: 'Female', label: '' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Default (Padrão)</Typography>
                                <DRadio label="Exemplo 2"
                                        name="exemplo2"
                                        onChange={ e => setValue2(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value2 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Hover/Focus Default - Clicked Selected</Typography>
                                <DRadio label="Exemplo 3"
                                        name="exemplo3"
                                        onChange={ e => setValue3(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value3 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text' }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Desabilitado e selecionado</Typography>
                                <DRadio label="Exemplo 4"
                                        name="exemplo4"
                                        onChange={ e => setValue4(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value4 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text', disabled: true }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Disabled (Desabilitado)</Typography>
                                <DRadio label="Exemplo 5"
                                        name="exemplo5"
                                        onChange={ e => setValue5(e.target.value) }
                                    // onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value5 }
                                    // others={ true }
                                        options={[
                                            { value: 'Option text', label: 'Option text', disabled: true }
                                        ]} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 4 }>
                        <Card style={ style.dark }>
                            <CardContent>
                                <Typography style={ style.textLight }>Selected Editable (Selecionado editável)</Typography>
                                <DRadio label="Exemplo 6"
                                        name="exemplo6"
                                        onChange={ e => setValue6(e.target.value) }
                                        //onChangeOutros={ e => console.log(e.target.value, 'outros') }
                                        value={ value6 }
                                        others={ true }
                                        options={[]} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Radios;