import {FormControl, InputLabel, Input, InputAdornment, FormHelperText} from "@material-ui/core";
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { getType, getEvents } from "../shared.helper";

const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
        maxWidth: '100%'
    },
    disabled: {
        opacity: '0.40!important'
    }
}));

function DInput(props) {

    const containerStyle = useStyles();

    function getIcon(props) {
        if (props.error && !props.notIcon)
            return <Error />

        if (props.success)
            return <CheckCircle />

        if (props.icon)
            return props.icon;
    }

    function getHelperText() {

        if (props.textarea && (props.helperText))
            return (<FormHelperText className="d-flex">
                <span>{ props.helperText }</span>
                { props.props.utilizeMaxSize && <span>{props.contador ? props.contador : 0 }/{ props.maxSize ? props.maxSize : 100 }</span> }
            </FormHelperText>)
        else if (props.textarea && !props.helperText)
            return (<FormHelperText className="d-flex">
                <span>&nbsp;</span>
                { props.utilizeMaxSize && <span>{props.contador ? props.contador : 0 }/{ props.maxSize ? props.maxSize : 100 }</span>}
            </FormHelperText>)
        else if (!props.textarea && props.helperText)
            return <FormHelperText>{ props.helperText }</FormHelperText>
        else
            return  ''
    }

    return (
        <FormControl className={ `${props.fullWidth ? containerStyle.fullWidth : ''} ${ props.success ? 'form-control-success' : '' } ${ props.error ? 'form-control-error' : '' } ${ props.disabled ? 'form-control-disabled' : '' } ${props.disabled && containerStyle.disabled} ${ props.textarea ? 'form-control-textarea' : '' }` }>
            { !props.notLabel && 
                <InputLabel 
                    shrink
                >
                    { props.labelColor 
                        ? (<span style={{ color: props.labelColor }}>{props.label}</span>) 
                        : props.label } 
                        { props.required === true && (<span style={{ color: 'red' }}>*</span>) }
                </InputLabel> }
            <Input { ...getEvents(props) }
                id={ props.id }
                error={props.error}
                disabled={ props.disabled }
                name={ props.name }
                readOnly={ props.readOnly }
                type={ getType(props.type) }
                required={ props.required === true }
                placeholder={ props.placeholder }
                value={ props.value }
                multiline={ props.textarea > 0 }
                rows={ props.textarea }
                inputProps={ { ...props.inputProps } }
                className={ props.className }
                style={ props.style }
                endAdornment={ ((props.icon || props.error) && props.positionIcon == 'end') ? <InputAdornment position="end">{ getIcon(props) }</InputAdornment> : false }
                startAdornment={ ((props.icon || props.error) && props.positionIcon == 'start') ? <InputAdornment position="start">{ getIcon(props) }</InputAdornment> : false }
            />
            { getHelperText() }
        </FormControl>
    );
}

DInput.defaultProps = {
    utilizeMaxSize: true
}

export default DInput;