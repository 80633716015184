import { useState } from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { Cancel, Clear, Reply } from "@material-ui/icons";
import { toast } from "react-toastify";

import DInput from "./../../../../../shared/DInput";
import DButtonCustom from "./../../../../../shared/DButtonCustom";

import { updateAttendanceStatus } from "../../../../../../api/healthAttendance";

const useStyles = makeStyles((theme) => ({
    textRed: {
      color: theme.palette.support.error
    },
    line: {
        borderColor: theme.palette.neutral.midgrey,
        margin: 0
    }
}));

const CancelarButton = withStyles((theme) => ({
    root: {
        height: '33px !important',
        width: 149,
        minWidth: '149px !important',
        borderRadius: 100,
        paddingTop: '6px !important',
        paddingRight: '18px !important',
        paddingBottom: '6px !important',
        paddingLeft: '18px !important',
        backgroundColor: `${theme.palette.support.error} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.support.error} !important`,
            border: 'none !important'
        },
        '&:active': {
            backgroundColor: `${theme.palette.support.error} !important`,
            border: 'none !important'
        },
        '&:focus': {
            backgroundColor: `${theme.palette.support.error} !important`,
            border: 'none !important'
        }
    },
    label: {
        width: 113,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '21px',
        letterSpacing: 0,
        textAlign: 'center',
        textTransform: 'initial',
        textDecoration: 'none',
        textIndent: 'initial',
        '&:hover': {
            width: 113,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px',
            letterSpacing: 0,
            textAlign: 'center',
            textTransform: 'initial',
            textDecoration: 'none',
            textIndent: 'initial'
        },
        '&:active': {
            width: 113,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px',
            letterSpacing: 0,
            textAlign: 'center',
            textTransform: 'initial',
            textDecoration: 'none',
            textIndent: 'initial'
        },
        '&:focus': {
            width: 113,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '21px',
            letterSpacing: 0,
            textAlign: 'center',
            textTransform: 'initial',
            textDecoration: 'none',
            textIndent: 'initial'
        }
    }
}))(DButtonCustom);

const BackButton = withStyles((theme) => ({
    root: {
        height: '29px !important',
        width: 70,
        minWidth: '70px !important',
        borderRadius: 100,
        paddingTop: '4px !important',
        paddingRight: '16px !important',
        paddingBottom: '4px !important',
        paddingLeft: '16px !important'
    },
    label: {
        width: 38,
        height: 21,
        color: theme.palette.support.error,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '21px',
        letterSpacing: 0,
        textAlign: 'center',
        textTransform: 'initial',
        textDecoration: 'none',
        textIndent: 'initial',
        '&:hover': {
            textIndent: 'initial'
        },
        '&:active': {
            textIndent: 'initial'
        },
        '&:focus': {
            textIndent: 'initial'
        }
    }
}))(DButtonCustom);

const CloseButton = withStyles({
    root: {
        height: '29px !important',
        width: 30,
        minWidth: '30px !important',
        borderRadius: 100,
        paddingTop: '4px !important',
        paddingRight: '16px !important',
        paddingBottom: '4px !important',
        paddingLeft: '16px !important'
    },
    label: {
        width: 20,
        height: 20,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '21px',
        letterSpacing: 0,
        textAlign: 'center',
        textTransform: 'initial',
        textDecoration: 'none',
        textIndent: 'initial',
        '&:hover': {
            textIndent: 'initial'
        },
        '&:active': {
            textIndent: 'initial'
        },
        '&:focus': {
            textIndent: 'initial'
        }
    }
})(DButtonCustom);


function CancelCard(props)
{
    const classes = useStyles();

    const [motivo, setMotivo] = useState('');
    const [loading, setLoading] = useState(false);

    const Undo = ({ onUndo, closeToast }) => {
        return (
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Cancel className={ classes.textRed } fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="h3" className={ classes.textRed }>Consulta cancelada</Typography>
                                </Grid>                                
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Clear fontSize="small" onClick={ closeToast } />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Typography onClick={ onUndo } variant="body1" className="text-regular text-st text-link"><Reply size={20} />&nbsp; Desfazer cancelamento de consulta</Typography>                    
                </Grid>
            </Grid>
        );
    };

    return (
        <form onSubmit={ form => {

            form.preventDefault();
            setLoading(true);
            updateAttendanceStatus(localStorage.getItem('token'), props.id, {active: false, reason: motivo}).then(data => {
                toast(<Undo onUndo={() => {
                                updateAttendanceStatus(localStorage.getItem('token'), props.id, {active: true, reason: null}).then(data => {
                                    toast.success("Consulta reativada com sucesso.");
                                    props.onClose(true);
                                }).catch(err => {
                                    toast.error("Não foi possível reativar o atendimento.");
                                    props.onCanceled(true);
                                });
                            }} />, {
                    onClose: () => {
                        props.onCanceled(true);
                    },
                    closeButton: false,
                    theme: 'light',
                    autoClose: 15000,
                    hideProgressBar: true
                });
            }).catch(err => {
                toast.error("Não foi possível cancelar o atendimento.");
                setLoading(false);
            });

        }}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Cancel className={ classes.textRed } fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="h3" className={ classes.textRed }>Cancelar consulta</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CloseButton type="button" varitant="primary" disabled={ loading } onClick={e => props.onClose(true)}>
                                <Clear fontSize="small" />
                            </CloseButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Grid container>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-bold">Paciente:&nbsp;</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-regular">{ props.paciente }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-bold">CPF:&nbsp;</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-regular">{ props.cpf }</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-bold">Tipo de consulta:&nbsp;</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" className="text-st text-regular">{ props.tipoConsulta }</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}><hr className={ classes.line } /></Grid>
                <Grid item md={12}>
                    <Typography variant="body1" className="text-est text-regular">Qual o motivo do cancelamento?</Typography>
                    <Typography variant="body1" className="text-est text-regular text-error">(obrigatório)</Typography>
                    <DInput notLabel fullWidth disabled={ loading } contador={ motivo.length } inputProps={{ maxLength: 255 }} utilizeMaxSize={false} label="Qual o motivo do cancelamento?" type="textarea" textarea={3} onChange={e => setMotivo(e.target.value)} />
                </Grid>
                <Grid item md={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <BackButton type="button" varitant="primary" onClick={e => props.onClose(true)}>Voltar</BackButton>
                        </Grid>
                        <Grid item>
                            <CancelarButton type="submit" disabled={ motivo.length === 0 || loading } variant="primary">
                                { loading ?  <><CircularProgress color="inherit" size={15} />&nbsp;Cancelando</> : 'Cancelar consulta' }
                            </CancelarButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

CancelCard.defaultProps = {
    id: null,
    paciente: "-",
    cpf: "-",
    tipoConsulta: "-"
}

export default CancelCard;