import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getPatientDocuments } from '../../../../helpers/utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  text: {
    fontSize: 14
  },
  item: {
    display: "block",
    marginBottom: 8,
    lineHeight: "21px"
  }
});

function formatDate(date, time = false, separador = "às") {
  if(date && date != 'null') {
      date = typeof date != 'object' ? new Date(date) : date;

      var day = date.getDate() >= 10 ? date.getDate() : '0'+ date.getDate();
      var month = (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : '0'+ (date.getMonth() + 1);
      var year = date.getFullYear();
      var hour = date.getHours().toString().padStart(2, '0');
      var minutes = date.getMinutes().toString().padStart(2, '0');
      
      if(time)
        return [day, month, year].join('/') + " " + separador + " " + [hour, minutes].join(':');  

      return [day, month, year].join('/');
  } else {
      return ''
  }
}


export default function CardPaciente(props) {

    const classes = useStyles();

    return (
      <>
        <div className={ classes.text }>
        <span className={ classes.item }><strong>Paciente: </strong> {props.value.patient.social_name || props.value.patient.full_name} {props.value.patient.pronoun ? props.value.patient.pronoun.name : ""}</span>
          { props.value.patient.patient_document.length > 0 ?
            <>
              <span className={ classes.item }><strong>CPF:</strong> { props.value.patient.cpf }</span>
            </>
            :
            ''
          }          
        </div>
        <hr />
        <div className={ classes.text }>
          <span className={ classes.item }><strong>Protocolo: </strong> {props.value.id}</span>
          <span className={ classes.item }><strong>Profissional: </strong> { props.value.user_requesting !== null ? (props.value.user_requesting.social_name || props.value.user_requesting.full_name) : "-" }</span>
          <span className={ classes.item }><strong>Solicitação:</strong> {formatDate(props.value.created_at, true)}</span>
          {
            props.value.health_attendance_status_id == 2 ? (
              <>
                <span className={ classes.item }><strong>Última atualização: </strong> {formatDate(props.value.updated_at, true, '|')}</span>
                <span className={ classes.item }><strong>Inicio da Consulta:</strong> {formatDate(props.value.open_at, true, '|')}</span>
              </>
            ) : ""
          }
          {
            props.value.health_attendance_status_id == 3 ? (
              <>
                <span className={ classes.item }><strong>Última atualização: </strong> {formatDate(props.value.updated_at, true, '|')}</span>
                <span className={ classes.item }><strong>Inicio da Consulta:</strong> {formatDate(props.value.open_at, true, '|')}</span>
                <span className={ classes.item }><strong>Fim da Consulta:</strong> {formatDate(props.value.closed_at, true, '|')}</span>
              </>
            ) : ""
          }
        </div>
      </>
  );
}
