import React from 'react';
import "../index.scss";
import { TextField } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import CustomSwitchDropdown from '../../CustomSwitchDropdown'


export default function LigacoesEspecialidadeds(props) {
  return (
    <div className='fundo_new_tab'>
      <div className='flex_botoes_unidades_executantes'>
        <div>
          <Button className='botao_inativo_unidades_exe' id="basic-button">
            Unidades executantes
          </Button>
        </div>
        <div>
          <Button className='botao_ativo_unidades_exe text-primary' id="basic-button">
            Especialidades
          </Button>
        </div>
      </div>
      <div className='main_flex_new_tab mt_35_new_tab'>
        <div className='fonte_titulo_unidades_executantes'>
          Especialidades
        </div>
      </div>
      <div className='mt_35_new_tab'>
        <CustomSwitchDropdown titulo='Clínico Geral' titulodrop='Tipos de Chamada' />
      </div>
      <div className='mt_35_new_tab'>
        <CustomSwitchDropdown titulo='Oftalmologista' titulodrop='Tipos de Chamada' />
      </div>
      <div className='mt_35_new_tab'>
        <CustomSwitchDropdown titulo='Ginecologista' titulodrop='Tipos de Chamada' />
      </div>
    </div>
  )
}