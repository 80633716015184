import axios from "axios";
import env from "react-dotenv";

class Construtor {

    async getList(token){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async getFullList(token, status){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form-full?active=${status}&token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async show(token, id){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form/${id}?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async getFormById(token, formId){
        return await axios.get(`${env.API_URL_SERVICE}/form/form/${formId}/show?token=${token}`)
                  .then(res => res.data)
    }

    async showByVersion(token, version, id){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form/${id}?token=${token}&version=${version}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async showByForm(token, id){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form/${id}/by-form?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
    
    async createForm(token, dados){
        try {
            const response = await fetch(`${env.API_URL}/anamnese-form?token=${token}`, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify(dados),
              }).then((res) => res.json());

            return response;            
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async showConditional(token, id){
        try {

            const {data} = await axios.get(`${env.API_URL}/anamnese-form/${id}/conditionals?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async updateConditional(token, id, body){
        try {

            const {data} = await axios.put(`${env.API_URL}/anamnese-form/${id}/conditionals?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async addConditional(token, id, body){
        try {

            const {data} = await axios.patch(`${env.API_URL}/anamnese-form/${id}/conditionals?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async updateAction(token, id, body){
        try {
            const {data} = await axios.put(`${env.API_URL}/anamnese-form/${id}/action?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async put(token, id, dados){
        try {
            const response = await  fetch(`${env.API_URL}/anamnese-form/${id}?token=${token}`, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "put",
                body: JSON.stringify(dados),
              }).then((res) => res.json());

            return response;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async addAction(token, id, body){
        try {

            const {data} = await axios.patch(`${env.API_URL}/anamnese-form/${id}/action?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async destroyConditional(token, id){
        try {

            const {data} = await axios.delete(`${env.API_URL}/anamnese-form/${id}/conditionals?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async addCondition(token, id, body){
        
        const {data} = await axios.patch(`${env.API_URL}/anamnese-form/${id}/condition?token=${token}`, body);
        return data;
        
    }

    async updateCondition(token, id, body){
        try {
            const {data} = await axios.put(`${env.API_URL}/anamnese-form/${id}/condition?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
            
    async delete(token, id){
        try {
            const {data} = await axios.delete(`${env.API_URL}/anamnese-form/${id}?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async addConditionFull(token, id, body){
        try {

            const {data} = await axios.patch(`${env.API_URL}/anamnese-form/${id}/conditionals-full?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async open(token, id){
        try {
            const {data} = await axios.put(`${env.API_URL}/anamnese-form/${id}/open?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async statusUpdate(token, id, body){
        try {

            const {data} = await axios.patch(`${env.API_URL}/anamnese-form-active/${id}?token=${token}`, body);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }

    async approvedForm(token, id){
        try {

            const {data} = await axios.post(`${env.API_URL_SERVICE}/formfield/field/approved/${id}?token=${token}`);
            return data;
        } catch (error) {
            console.error(error)
            throw error;
        }
    }
}

export default new Construtor();