import { Typography } from "@material-ui/core";

function Drugs(props)
{
    function getType()
    {
        if(props.preForm.drugs && props.preForm.drugs === 'Sim'){

            const drugs = props.preForm.anamnese_pre_form_drugs.map(res => `${res.drug_type} - ${res.drug_use}`);

            return `Sim (${drugs.join(', ')})`;
        } else if (props.preForm.drugs && props.preForm.drugs === 'Não'){
            return 'Não';
        } else {
            return 'Não informado';
        }
    }

    return <Typography variant="body1" className="text-regular text-st">{ getType() }</Typography>
}

export default Drugs;