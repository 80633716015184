import React, { useState, useEffect } from "react";
import DButton from "../shared/DButton";
import { useTheme } from "@material-ui/core";
import { toast } from 'react-toastify';
import {
  Backdrop,
  Typography,
  Container,
  Card,
  CircularProgress,
  CardContent,
  Grid,
} from "@material-ui/core";
import { useAppTheme } from "../../context/useThemeContext";
import DroagAndDropLogotipo from '../shared/DroagAndDropLogotipo';
import styledComponents from "styled-components";
import { saveColors, saveLogo, getList, palette as paletteInit } from '../../api/temas';

export default function Temas() {
  
  const { handleChangeTheme } = useAppTheme();
  const inputColorRef = React.useRef();
  const inputColor2Ref = React.useRef();
  const inputColor3Ref = React.useRef();
  const inputColor4Ref = React.useRef();
  const inputColor5Ref = React.useRef();
  const inputColor6Ref = React.useRef();
  const token = localStorage.getItem("token");
  const [showLoader, setShowLoader] = useState(false);
  const [palette, setPalette] = useState(null);
  const [logoTipo, setLogoTipo] = useState(null);
  const theme = useTheme();


  useEffect(() => {

    setShowLoader(true);
    getList(token).then((response) => {
      setShowLoader(false);
      setLogoTipo(response.configuration.file);
      setPalette({
        ...paletteInit,
        primary: {
          light: response.configuration.primary_color_light || inputColorRef.current.value,
          main: response.configuration.primary_color ||inputColor2Ref.current.value,
          dark: response.configuration.primary_color_dark ||inputColor3Ref.current.value,
        },       
        secondary: {
          light: response.configuration.second_color_light ||inputColor4Ref.current.value,
          main: response.configuration.second_color ||inputColor5Ref.current.value,
          dark: response.configuration.second_color_dark ||inputColor6Ref.current.value,
        },
        logoPlataforma: response.configuration.file || paletteInit.logoPlataforma
      });
    });

  }, [])

  useEffect(() => {
    if(palette != null)
    handleChangeTheme(palette);
  }, [palette])

  const setAppTheme = () => {
    if(theme?.palette != null)
      setPalette(theme.palette);
  }

  const BoxContainer = styledComponents.div`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: baseline;

        margin-bottom: 10px;
        padding: 1em;

        min-height: 200px;

        div {
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            gap: 15px;
            padding: 5px;

            legend {
                font-weigth: 400;
                padding: 0;
                
            }

            button {
                border-radius: 10px;
                padding: 4px 20px;
                border: 1px solid ${theme.palette.primary.light};
                background-color: transparent;
                cursor: pointer;
            }
        }
    `;

  const BoxBlockField = styledComponents.div`
        justify-content: space-between !important;
        width: 100%;
        padding: 30px 30px 30px 30px !important;
        background: ${theme.palette.background.cloud} !important;
        border-radius: 10px;
        margin-bottom: 20px;

        button {
          transition: all 0.4s ease;
          &:hover {
            background-color: ${theme.palette.primary.light};
            color: ${theme.palette.primary.contrastText};
            border-radius: 0px;
          }
        }
        
    `;

  const InputColor = styledComponents.input`
        border: 0px;
        padding: 0;
        width: 60px;
        background: transparent;
    `;

  const BoxHeaderText = styledComponents.span`
        font-size: 1em;
        font-weight: 500;
        color: ${theme.palette.primary.light};
        
    `;

  const BoxInputColorValue = styledComponents.input`
      width: 64px;
      margin-left: -18px;
      border: 1px solid ${theme.palette.background.cloud};
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent;
        
    `;

  const HeadText = styledComponents(Typography)`
        margin-top: 15,
        margin-bottom: 24,
        color: ${theme.palette.primary.light};
    `;
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  const getSaveLogo = () => {
    const fileInput = document.getElementById("logo_config");
    if(fileInput.files.length <= 0 ) {
      setAppTheme();
      return;
    } 

    const form = new FormData();    
    form.append("file", fileInput.files[0], "Logo.png");
    
    setShowLoader(true);
    saveLogo(token, form).then((response) => {
     
      if(response.status){

        toBase64(fileInput.files[0]).then((res) => {
          theme.palette.logoPlataforma = res;      
          setAppTheme();
        }); 

      } else if(response.file != null){
        toast.error("Imagem incorreta, verifique se está dentro das dimensões (150px por 30px) ou se ela é PNG, JPG ou SVG");
      } else {
        toast.error(response.error);
      }

      setShowLoader(false);
      
    })
    .catch((error) => {
      setShowLoader(false);
    })
  };

  const getSaveColors = () => {

    const body = {
      recording: true,
      video_conference: true,
      primary_color_light: theme?.palette.primary.light,
      primary_color: theme?.palette.primary.main,
      primary_color_dark: theme?.palette.primary.dark,
      second_color_light: theme?.palette.secondary.light,
      second_color: theme?.palette.secondary.main,
      second_color_dark: theme?.palette.secondary.dark
    }

    setShowLoader(true);
    saveColors(token, body).then((response) => {
      
      setShowLoader(false);

      if(response.status){

        getSaveLogo();

      } else {
        toast.error(response.error);
      }
      

    })
    .catch((error) => {
      setShowLoader(false);
    })    

  };

  return (

    <>
      <Backdrop style={{ zIndex: 3000, color: 'white', textAlign: 'center' }} open={showLoader}>
        <Grid container>
          <Grid item md={12}>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item md={12}>
            <Typography variant="body1" className="text-regular text-st">Carregando configurações de tema...</Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <Container style={{ marginTop: 20 }}>
        <HeadText variant="h3" className="text-primary">
          Identidade Visual
        </HeadText>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item md={12} xs={12}>
                <DroagAndDropLogotipo 
                  logoTipo={logoTipo}
                />
              </Grid>
            </Grid>
          </CardContent>

          
        </Card>
      </Container>
      {/* Cores */}
      <Container style={{ marginTop: 20 }}>
        <Card>
        <CardContent>
            <Grid container>
              <Grid item md={12} xs={12}>
                <Typography variant="h4">Paleta de cores</Typography>
                <p>Adicione aqui as cores da sua empresa</p>
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 24 }} maxWidthSm={5}>
              <Grid item xs={6}>
                <BoxContainer>
                  <BoxHeaderText style={{ paddingBottom: 20 }}>
                    Cores Principais
                  </BoxHeaderText>
                  
                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                        Primária
                      </BoxHeaderText>
                      <legend>Principal</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.primary.main} ref={inputColor2Ref} type="color" onChange={(e) => { theme.palette.primary.main = e.target.value; }} />
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.primary.main} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>
                  
                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                      Primária
                      </BoxHeaderText>
                      <legend>Principal - Clara</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.primary.light} ref={inputColorRef} type="color" 
                        onChange={(e) => { 
                          theme.palette.primary.light = e.target.value;                          
                      }}/>
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.primary.light} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>

                  
                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                        Primária
                      </BoxHeaderText>
                      <legend>Principal - Escura</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.primary.dark} ref={inputColor3Ref} type="color" onChange={(e) => { theme.palette.primary.dark = e.target.value; }} />
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.primary.dark} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>

                  <BoxHeaderText style={{ paddingBottom: 20 }}>
                    Cores auxiliares
                  </BoxHeaderText>
                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                        Secundária
                      </BoxHeaderText>
                      <legend>Secundária - Clara</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.secondary.light} ref={inputColor4Ref} type="color" onChange={(e) => { theme.palette.secondary.light = e.target.value; }} />
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.secondary.light} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>
                  
                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                        Secundária
                      </BoxHeaderText>
                      <legend>Secundária</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.secondary.main} ref={inputColor5Ref} type="color" onChange={(e) => { theme.palette.secondary.main = e.target.value; }} />
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.secondary.main} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>

                  <BoxBlockField>
                    <div style={{ flexDirection: 'column' }}>
                      <BoxHeaderText>
                        Secundária
                      </BoxHeaderText>
                      <legend>Secundária - Escura</legend>
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <InputColor defaultValue={theme && theme.palette.secondary.dark} ref={inputColor6Ref} type="color" onChange={(e) => { theme.palette.secondary.dark = e.target.value; }} />
                      <BoxInputColorValue readonly type="text"  value={theme && theme.palette.secondary.dark} >
                      </BoxInputColorValue>
                      {/* <button onClick={(e) => setAppTheme()}>Aplicar</button> */}
                    </div>
                  </BoxBlockField>

                </BoxContainer>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={12} xs={12}>
                <DButton
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    getSaveColors();
                  }}
                >
                  Salvar
                </DButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>



  );
}
