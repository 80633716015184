import { React, useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Backdrop, Button, Chip, CircularProgress} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {getFormatDate} from "../../../../../../../../helpers/formatacao";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    presc_chip: {
        background: theme.palette.background.cloud,
        border: `1px solid ${theme.palette.neutral.midgrey}`,
        height: 26,
        marginBottom: 8,
        marginRight: 8
    },
    iconKey: {
        color: theme.palette.primary.light,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "18px",
        color: theme.palette.neutral.black,
        padding: "6px 12px"
    },
    body: {
        fontSize: 14,
        padding: "6px 12px"
    },
   
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function PacienteGrid(props) {
    const classes = useStyles();
    const [backdrop, setBackdrop] = useState(true);

    useEffect(() => {
        console.log("props.prescriptionsMap", props.prescriptionsMap);
        props.prescriptions != null && setBackdrop(false);
    }, [props]);

    return (
        <>
            <Backdrop style={{zIndex: 3000, color: 'white'}} open={ backdrop }>
                <CircularProgress color="inherit" />
            </Backdrop>
            <TableContainer style={{ marginTop:16 }}>
                <Table className={classes.table} aria-label="pacientes table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell><div style={{ display:"flex", alignItems: "center", justifyContent: "space-between" }}>Tipo <KeyboardArrowDownIcon className={classes.iconKey} /></div></StyledTableCell>
                            <StyledTableCell><div style={{ display:"flex", alignItems: "center", justifyContent: "space-between" }}>Data  <KeyboardArrowDownIcon className={classes.iconKey} /></div></StyledTableCell>
                            <StyledTableCell>Prescrição</StyledTableCell>
                            <StyledTableCell>&nbsp;</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.prescriptionsMap && props.prescriptionsMap.map((row, index) => {

                                if (row.revenue && Array.isArray(row.revenue.data) && row.revenue.data.length > 0) {

                                    return (
                                        <StyledTableRow  key={ `pacientes-${ index }` }>
                                            <StyledTableCell>{ row.revenue.type || "-"}</StyledTableCell>
                                            <StyledTableCell>{ row.prescriptionData ? getFormatDate(new Date(row.prescriptionData), "dd/MM/yyyy") : "-"}</StyledTableCell>
                                            <StyledTableCell>
                                            { row.revenue.data.map((rowItem, index) => (
                                                <Chip label={rowItem.name} className={ classes.presc_chip } />
                                            ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <a className="text-link" onClick={ e => row.revenue.renevueDocument && props.detalhes(row.revenue.renevueDocument.url) } style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )

                                }

                            })
                        }

                        {
                            props.prescriptionsMap && props.prescriptionsMap.map((row, index) => {

                                if (row.exam && Array.isArray(row.exam.data) && row.exam.data.length > 0) {

                                    return (
                                        <StyledTableRow  key={ `pacientes-${ index }` }>
                                            <StyledTableCell>{ row.exam.type || "-"}</StyledTableCell>
                                            <StyledTableCell>{ row.prescriptionData ? getFormatDate(new Date(row.prescriptionData), "dd/MM/yyyy") : "-"}</StyledTableCell>
                                            <StyledTableCell>
                                            { row.exam.data.map((rowItem, index) => (
                                                <Chip label={rowItem.name} className={ classes.presc_chip } />
                                            ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <a className="text-link" onClick={ e => row.exam.document && props.detalhes(row.exam.document.url) } style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )

                                }

                            })
                        }

                        {
                            props.prescriptionsMap && props.prescriptionsMap.map((row, index) => {

                                if (row.certificate && Array.isArray(row.certificate.data) && row.certificate.data.length > 0) {

                                return row.certificate && row.certificate.document && (
                                    <StyledTableRow  key={ `pacientes-${ index }` }>
                                        <StyledTableCell>Atestado</StyledTableCell>
                                        <StyledTableCell>{ row.prescriptionData ? getFormatDate(row.prescriptionData, "dd/MM/yyyy") : "-"}</StyledTableCell>
                                        <StyledTableCell>
                                        {
                                            Array.isArray(row.certificate.data) ? row.certificate.data.map((rowItem, index) => (
                                                <Chip label={rowItem.name} className={ classes.presc_chip } />
                                            )) : ""
                                        }
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <a className="text-link" onClick={ e => row.certificate.document && props.detalhes(row.certificate.document.url) } style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )

                                }
                            })
                        }

                        {/* {
                            props.prescriptionsMap && props.prescriptionsMap.map((row, index) => {
                                return row.certificate && row.certificate.document && (
                                        <StyledTableRow  key={ `pacientes-${ index }` }>
                                            <StyledTableCell>{ row.certificate.type || "-"}</StyledTableCell>
                                            <StyledTableCell>{ row.prescriptionData ? getFormatDate(new Date(row.prescriptionData), "dd/MM/yyyy") : "-"}</StyledTableCell>
                                            <StyledTableCell>
                                            { Object.entries(row.revenue.revenueItens).map((rowItem, index) => (
                                                <Chip label={rowItem[1].name} className={ classes.presc_chip } />
                                            ))}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <a className="text-link" onClick={ e => row.revenue.revenueDocument && props.detalhes(row.revenue.revenueDocument.url) } style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}><OpenInNewIcon style={{ width:18 }} /></a>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                        } */}

                    </TableBody>
                </Table>
            </TableContainer>
        </>

    );
}
