import React, {Component} from "react";
import { withStyles } from "@material-ui/core/styles";
import {Typography, Container, Card, CardContent, Grid, CircularProgress, Backdrop, Button} from "@material-ui/core";
import {TreeIcon, ListIcon} from "../shared/Icon/Icon";
import DButton from "../shared/DButton";
import UnidadesGrid from "./component/UnidadesGrid";
import UnidadesCreate from "./component/UnidadesCreate";
import {deleteMedicalUnit, getAllUnidades, getAllUnidadesExecutantes, storeMedicalUnit, getAllUnidadesArvore, getAllUnidadesArvoreSearch} from "../../api/unidades";
import UnidadeView from "./component/UnidadeView";
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import UnidadeArvore from "./component/UnidadeArvore";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from "../../context/AppContext";

const styles = theme => ({
    addButton: {
        color: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        borderRadius: 20,
        textTransform: "none",
        borderWidth: 2,
        padding: "0 18px 0"
    },
});

class Unidades extends Component {
    static contextTypes = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            loadingMedicalUnits: true,
            unidades: [],
            unidadesArvore: [],
            unidade: null,
            novaUnidade: false,
            creatingNewMedicalUnit: false,
            createdMedicalUnit: false,
            createMedicalUnitError: false,
            reloadCreateMedicalUnit: false,
            removeMedicalUnit: false,
            standBy: false,
            update: false,
            setErrors: null,
            anchorEl: null,
            open: false,
            id: undefined,
            lista: true,
            searchArvore: false
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(event) {
        this.setState({anchorEl: event.currentTarget, open: Boolean(event.currentTarget), id: "simple-popover"});
    }
    
    handleClose(event) {
        this.setState({anchorEl: event.currentTarget, open: false, id: undefined});
    }

    componentDidMount() {

        getAllUnidades(localStorage.getItem('token')).then(res => {

            var unidades = [];
            
            if(!res.error && res.length > 0) {
                unidades = res;
            }

            getAllUnidadesArvore(localStorage.getItem('token')).then(unidadesArvore => {
                if(unidadesArvore.status)                    
                    this.setState({ ...this.state, unidades, unidadesArvore: unidadesArvore.unidades, loadingMedicalUnits: false })
            })
        });
    }

    createMedicalUnit(dados) {

        this.setState({ ...this.state, creatingNewMedicalUnit: true, nameMedicalUnitCreated: dados.company_name })

        storeMedicalUnit(localStorage.getItem('token'), dados).then(res => {

            if(res.status) {

                getAllUnidades(localStorage.getItem('token')).then(res => {

                    var unidades = [];

                    if(!res.error && res.length > 0) {
                        unidades = res;
                    }

                    getAllUnidadesArvore(localStorage.getItem('token')).then(unidadesArvore => {
                        if(unidadesArvore.status)                    
                            this.setState({ ...this.state, unidades, unidadesArvore: unidadesArvore.unidades, loadingMedicalUnits: false, creatingNewMedicalUnit: false, createdMedicalUnit: true })
                    })
                });
            } else {

                if (res.code == '23000') {
                    this.state.setErrors({ cnpj: 'CNPJ existente' })
                } else {
                    this.state.setErrors({ cnes: 'Campo obrigatorio' })
                }

                this.setState({ ...this.state, creatingNewMedicalUnit: false, createMedicalUnitError: true })
            }
        });
    }

    destroyMedicalUnit(id) {

        this.setState({ ...this.state, standBy: true, removeMedicalUnit: true , unidade: null })

        deleteMedicalUnit(localStorage.getItem('token'), id).then(res => {

            if(res.status) {

                getAllUnidades(localStorage.getItem('token')).then(res => {

                    var unidades = [];

                    if(!res.error && res.length > 0) {
                        unidades = res;
                    }

                    this.setState({ ...this.state, standBy: false, removeMedicalUnit: false, unidades, unidade: null })
                });
            } else {
                this.setState({ ...this.state, standBy: false, removeMedicalUnit: false, unidade: id })
            }
        })
    }

    render() {

        const styles = {
            titleTop: { marginTop: 32, marginBottom: 24, marginLeft: 14  },
            buttonToolbar: { marginTop: 24, marginBottom: 24 },
            flex: {display: 'flex'},
            doc_popover: {
                listStyle: "none",
                padding: 0,
                margin: "0 16px 0 0",
                width: "100%"
            },
            doc_popoverItem: {
                listStyle: "none",
                margin: 0,
                color: "#272727",
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                fontSize: 14,
                lineHeight: "21px"
            },
            viewActionButton: {
                padding: "6px 18px 6px 40px",
            }
        }
        const { classes } = this.props;

        return (
            <Container className="tema-light">
                <Typography style={styles.titleTop} variant="h3" className="text-primary">Atendimento</Typography>
                <Card style={{ background: "#f9f9f9" }}>
                    <CardContent>
                        <Grid container>
                            <Grid item md={9}>
                                <Typography variant="h4">Unidades</Typography>
                                <Typography variant="body1" className="text-st text-regular">Cadastro e gestão de unidades</Typography>
                            </Grid>
                            <Grid item md={3} style={styles.flex}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button variant="outlined" className={classes.addButton} size="sm" onClick={ e => this.setState({ ...this.state, novaUnidade: true }) }>
                                            Cadastrar unidade
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" spacing={3} style={styles.buttonToolbar}>
                            <Grid item>
                                <Typography variant="body1" className="text-st text-regular">Visualizar como:</Typography>
                            </Grid>
                            <Grid item>
                                <DButton style={styles.viewActionButton} type="button" onClick={ e => this.setState({...this.state, lista: true}) } variant="ghost" active={ this.state.lista } icon={ <ListIcon width={18} /> } size="sm">
                                    Listas
                                </DButton>
                            </Grid>
                            <Grid item>
                                <DButton style={styles.viewActionButton} type="button" disabled={this.state.unidadesArvore.length < 1} onClick={ e => this.setState({...this.state, lista: false}) } variant="ghost" active={ !this.state.lista } icon={ <TreeIcon width={18}  /> } size="sm">
                                    Árvore de regionalização
                                </DButton>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12}>
                                { this.state.lista
                                    ?
                                    <>
                                        { !this.state.creatingNewMedicalUnit && !this.state.removeMedicalUnit ? <UnidadesGrid loading={ this.state.loadingMedicalUnits } unidade={ unidade => this.setState({ ...this.state, unidade }) } unidades={ this.state.unidades } /> : '' }
                                    </>
                                    :
                                    <>
                                        <UnidadeArvore loading={ this.state.searchArvore } onSearch={ dados => {

                                            this.setState({...this.state, searchArvore: true, unidadesArvore: []})

                                            if (typeof dados.tipo == "undefined")   
                                                dados.tipo = 'todas';

                                            if (typeof dados.medical_unit_id == "undefined")   
                                                dados.medical_unit_id = '';

                                            if ((dados.search !== '' && dados.search.length >= 3) || dados.tipo !== 'todas' || dados.medical_unit_id !== '') {
                                                getAllUnidadesArvoreSearch(localStorage.getItem('token'), dados).then(res => {
                                                    if(res.status)
                                                        this.setState({...this.state, unidadesArvore: res.unidades, searchArvore: false})
                                                    else
                                                        this.setState({...this.state, searchArvore: false})
                                                })
                                            } else {
                                                getAllUnidadesArvore(localStorage.getItem('token')).then(res => {
                                                    if(res.status)
                                                        this.setState({...this.state, unidadesArvore: res.unidades, searchArvore: false})
                                                    else
                                                        this.setState({...this.state, searchArvore: false})
                                                })
                                            }
                                        } } unidades={ this.state.unidadesArvore } viewUnidade={ unidade => this.setState({...this.state, unidade}) } />
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <UnidadesCreate novaUnidade={ this.createMedicalUnit.bind(this) }
                                loading={ this.state.creatingNewMedicalUnit }
                                created={ this.state.createdMedicalUnit }
                                setErrors={ setErrors => this.setState({ ...this.state, setErrors }) }
                                error={ this.state.createMedicalUnitError }
                                open={ this.state.novaUnidade }
                                onNewMedicalUnit={ e => {
                                    this.setState({ ...this.state, createdMedicalUnit: false })
                                } }
                                unidades={this.state.unidades}
                                onClose={ e => {
                                    console.log("Close modal", e)
                                    this.setState({ ...this.state, novaUnidade: false, createdMedicalUnit: false })
                                } } />
                <UnidadeView 
                            onClose={ e => {

                                if(this.state.update) {
                                    const {setUnitChangedInConfiguration} = this.context;
                                    // this.setState({ ...this.state, creatingNewMedicalUnit: true, standBy: true, unidade: null })
                                    this.setState({ ...this.state, standBy: true, unidade: null })

                                    getAllUnidades(localStorage.getItem('token')).then(res => {
                                        var unidades = [];

                                        if(!res.error && res.length > 0) {
                                            unidades = res;
                                        }

                                        // this.setState({ ...this.state, creatingNewMedicalUnit: false, standBy: false, unidades, update: false })
                                        // this.setState({ ...this.state, standBy: false, unidades: unidades, update: false })

                                        getAllUnidadesArvore(localStorage.getItem('token')).then(unidadesArvore => {
                                            if(unidadesArvore.status)                    
                                                this.setState({ ...this.state, unidades, unidadesArvore: unidadesArvore.unidades, update: false, standBy: false })
                                                setUnitChangedInConfiguration(true)
                                        })
                                    });
                                    setUnitChangedInConfiguration(false)
                                }else {
                                    this.setState({ ...this.state, unidade: null })
                                }
                             }}
                             onUpdated={ e => {
                                 this.setState({ ...this.state, update: true })
                             }}
                             open={ this.state.unidade !== null }
                             remove={ this.state.removeMedicalUnit }
                             unidade={ this.state.unidade }
                             onRemoveMedicalUnit={ unidade => this.destroyMedicalUnit(unidade) } />
                <Backdrop style={{zIndex: 3000, color: 'white'}} open={ this.state.standBy }>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        )
    }
}
Unidades.contextType = AppContext

export default withStyles(styles)(Unidades);