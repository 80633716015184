// Import React
import { React, useState, useEffect } from 'react';

// Import Material-ui
import { Grid } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from '@material-ui/core/styles';

// Import components
import PacienteGrid from '../../../Paciente/components/PacienteDetails/components/DetailsAttendance/components/PacienteGrid/PacienteGrid';

// Import API requests
import { getHealthAttendanceById } from  '../../../../api/health_attendances';

const useStyles = makeStyles((theme) => ({
  tbl_header: {
    fontWeight: "normal",
    fontSize: 14,
    lineHeight: 21,
    color: "#4F4F4F"
  },
  pagination: {
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    '& .MuiPagination-ul': {
      color: "#272727",
      background: "transparent",
      fontWeight: 400,

      "& > li": {
        "& button": {
          fontSize: 16
        }
      },
      "& > li:first-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      },
      "& > li:last-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main
        }
      },
      '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        background: "#fff",
        fontWeight: 500
      },
    }
  }

}));

function DetailsAttendance(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  const [totalPages, setTotalPages] = useState(1);
  const [healthAttendances, setHealthAttendances] = useState(null);

  useEffect(() => {
    if (props.idPaciente) {
      getHealthAttendanceById(localStorage.getItem("token"), props.idPaciente, null).then(res => {
        if (res.status) {
          setTotalPages(res.healthAttendances.paginate.last_page);
          setHealthAttendances(res.healthAttendances.patient);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }, [props.idPaciente]);

  function getHealthAttendancePage(page){
    getHealthAttendanceById(localStorage.getItem("token"), props.idPaciente, page).then(res => {
      if(res.status){
        setHealthAttendances(res.healthAttendances.patient);
      }
    }).catch(error => { 
      console.error(error)
    });
  }
  
  return (
        <div style={{ height: '100%' }}>
          <PacienteGrid
            healthAttendances={ healthAttendances }
            detalhes={ e => props.detalhes(true) }
          ></PacienteGrid>
          <div className={ classes.pagination }>
            <Pagination 
              count={ totalPages } 
              onChange={ (event, page) => {
                getHealthAttendancePage(page);
              }}
            />
          </div>
        </div>
    );
}

export default DetailsAttendance;