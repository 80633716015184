import { Grid, Typography } from "@material-ui/core";

export function EmptyGestation() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100%", textAlign: "center" }}
    >
      <Grid item md={12}>
        <Typography variant="h1" style={{ marginBottom: 30, color: "#4a4ae9" }}>
          Paciente grávida?...
        </Typography>

        <Typography>Cadastre uma nova gestação</Typography>
      </Grid>
    </Grid>
  );
}
