// React import
import React, { useEffect, useState } from "react";

// Material styles and icons import
import { Typography, Grid, Box } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./index.scss";

// Toast
import { toast } from "react-toastify";

import { 
  getMedicalUnitPermission, 
  enableMedicalUnitPermissionCall,
  disableMedicalUnitPermissionCall,
  enableMedicalUnitPermissionSequentialCall,
  disableMedicalUnitPermissionSequentialCall,
  enableMedicalUnitPermissionGeneralGroup,
  disableMedicalUnitPermissionGeneralGroup,
  enableMedicalUnitPermissionTotalGroup,
  disableMedicalUnitPermissionTotalGroup,
  enableMedicalUnitPermissionDirectCall,
  disableMedicalUnitPermissionDirectCall
} from "../../../api/unidades";
import { 
  getMedicalSpecialtyPermission,
  enableMedicalSpecialtyPermissionCall,
  disableMedicalSpecialtyPermissionCall,
  enableMedicalSpecialtyPermissionSequentialCall,
  disableMedicalSpecialtyPermissionSequentialCall,
  enableMedicalSpecialtyPermissionGeneralGroup,
  disableMedicalSpecialtyPermissionGeneralGroup,
  enableMedicalSpecialtyPermissionTotalGroup,
  disableMedicalSpecialtyPermissionTotalGroup,
  enableMedicalSpecialtyPermissionDirectCall,
  disableMedicalSpecialtyPermissionDirectCall
} from "../../../api/medicalSpecialty";
import { 
  getProfilePermission,
  enableProfilePermissionCall,
  disableProfilePermissionCall,
  enableProfilePermissionSequentialCall,
  disableProfilePermissionSequentialCall,
  enableProfilePermissionGeneralGroup,
  disableProfilePermissionGeneralGroup,
  enableProfilePermissionTotalGroup,
  disableProfilePermissionTotalGroup,
  enableProfilePermissionDirectCall,
  disableProfilePermissionDirectCall
} from "../../../api/profiles";

export default function CustomSwitch(props) {
  const [checked, setChecked] = useState(false);
  const [permitions, setPermitions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect( async () => {
    setChecked(props.active);
  }, [props.active]);

  const handleClick = (event) => {
    setPermitions(null);
    listPermitions();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleChecked = (e) => {
    if (props.type == 'unit') {
      if (e.target.checked) {
        enableMedicalUnitPermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(true);

            toast.success("Permissão ativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      } else {
        disableMedicalUnitPermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(false);

            toast.success("Permissão desativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      }
    } else if (props.type == 'specialty') {
      if (e.target.checked) {
        enableMedicalSpecialtyPermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(true);

            toast.success("Permissão ativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      } else {
        disableMedicalSpecialtyPermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(false);

            toast.success("Permissão desativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      }
    } else {
      if (e.target.checked) {
        enableProfilePermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(true);

            toast.success("Permissão ativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      } else {
        disableProfilePermissionCall(localStorage.getItem("token"), props.id).then( async (res) => {
          if (res.status) {
            setChecked(false);

            toast.success("Permissão desativada com sucesso!");
          } else {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          }
        }).catch( (err) => {
          toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
        });
      }
    }

    setChecked((prev) => !prev);
  };

  async function listPermitions() {
    return new Promise((resolve, reject) => {
      if (props.type == 'unit') {
        getMedicalUnitPermission(localStorage.getItem("token"), props.id).then( async (res) => {
          resolve(res);
          if (res.status) {
            setPermitions(res.details);
          }      
        })
        .catch(async (error) => {
          reject(error);
        });
      } else if (props.type == 'specialty') {
        getMedicalSpecialtyPermission(localStorage.getItem("token"), props.id).then( async (res) => {
          resolve(res);
          if (res.status) {
            setPermitions(res.details);
          }
        })
        .catch(async (error) => {
          reject(error);
        });
      } else {
        getProfilePermission(localStorage.getItem("token"), props.id).then( async (res) => {
          resolve(res);
          if (res.status) {
            setPermitions(res.details);
          }
        })
        .catch(async (error) => {
          reject(error);
        });
      }
    });
    
  }

  async function atualizaStatus(tipoAtt, event) {
    if (props.type == 'unit') {
      if (tipoAtt == 'sequencial') {
        if (event.target.checked) {
          enableMedicalUnitPermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalUnitPermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'geral') {
        if (event.target.checked) {
          enableMedicalUnitPermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalUnitPermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'total') {
        if (event.target.checked) {
          enableMedicalUnitPermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalUnitPermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'direta') {
        if (event.target.checked) {
          enableMedicalUnitPermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalUnitPermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      }
    } else if (props.type == 'specialty') {
      if (tipoAtt == 'sequencial') {
        if (event.target.checked) {
          enableMedicalSpecialtyPermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalSpecialtyPermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'geral') {
        if (event.target.checked) {
          enableMedicalSpecialtyPermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalSpecialtyPermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'total') {
        if (event.target.checked) {
          enableMedicalSpecialtyPermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalSpecialtyPermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'direta') {
        if (event.target.checked) {
          enableMedicalSpecialtyPermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableMedicalSpecialtyPermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      }
    } else {
      if (tipoAtt == 'sequencial') {
        if (event.target.checked) {
          enableProfilePermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableProfilePermissionSequentialCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'geral') {
        if (event.target.checked) {
          enableProfilePermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableProfilePermissionGeneralGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'total') {
        if (event.target.checked) {
          enableProfilePermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableProfilePermissionTotalGroup(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      } else if (tipoAtt == 'direta') {
        if (event.target.checked) {
          enableProfilePermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) =>{
                  if(res.status){
                    toast.success("Permissão ativada com sucesso!");  
                  } else {
                    toast.error(res.error.message);
                  }                  
                })
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        } else {
          disableProfilePermissionDirectCall(localStorage.getItem("token"), props.id).then( async (res) => {
            if (res.status) {
              listPermitions()
                .then((res) => {
                  if(res.status){
                    toast.success("Permissão desativada com sucesso!");
                  } else {
                    toast.error(res.error.message);
                  }
                });
              
            } else {
              toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
            }
          }).catch( (err) => {
            toast.error("Ocorreu um erro ao atualizar a permissão. Tente novamente.");
          });
        }
      }
    }
  }

  return (
    <Grid container>
      <Grid item md={12}>
        <List>
          <ListItem>
            <ListItemText
              primary={props.titulo}
              disableTypography={true}
            />
            <ListItemSecondaryAction>
              <Box className="box_custom_switch">
                <Button className="dropdown_botao"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  style={{ width: "max-content" }}
                  onClick={handleClick}
                >
                  {props.titulodrop}
                  <KeyboardArrowDownIcon className="icone_arrow" />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={permitions && permitions.SequentialCall} />} onChange={ e => atualizaStatus('sequencial', e) } label="Chamada sequencial" />
                      <FormControlLabel control={<Checkbox checked={permitions && permitions.CallInGeneralGroup} />} onChange={ e => atualizaStatus('geral', e) } label="Chamada em grupo geral" />
                      <FormControlLabel control={<Checkbox checked={permitions && permitions.CallInTotalGroup} />} onChange={ e => atualizaStatus('total', e) } label="Chamada em grupo total" />
                      <FormControlLabel control={<Checkbox checked={permitions && permitions.DirectCall} />} onChange={ e => atualizaStatus('direta', e) } label="Chamada direta" />
                    </FormGroup>
                  </MenuItem>
                </Menu>
                <Switch name="recording" checked={checked} onChange={toggleChecked} />
                <Typography>{`${checked ? 'Habilitado' : 'Desabilitado'}`}</Typography>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}