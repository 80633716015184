import React, { Component } from "react";
import { getAllTypes } from "../../api/healthAttendanceType";
import { deleteType } from "../../api/healthAttendanceType";
import { Container } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { getMe } from "../../api/user";
import ActionModal from "../shared/ActionModal";
import HealthAttendanceTypeList from "./components/HealthAttendanceTypeList/HealthAttendanceTypeList"
import HealthAttendanceTypeCreate from "./components/HealthAttendanceTypeCreate/HealthAttendanceTypeCreate"
import HealthAttendanceTypeUpdate from "./components/HealthAttendanceTypeUpdate/HealthAttendanceTypeUpdate"

class HealthAttendanceType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            acoes: 0,
            loadingDelete: false,
            selectedType: [],
            modalDelete: false,
            login: true,
            types: {
                data: []
            },
            filtro: null,
            page: 1
        }
    }

    componentDidMount() {
        getMe(localStorage.getItem('token')).then(res => {
            if (res.error) {
                this.setState({ login: false });
            } else {
                console.log(res);
            }
        });

        this.listarTiposAtendimento();
    }

    listarTiposAtendimento() {
        getAllTypes(localStorage.getItem('token')).then(res => {
            if (res.healthAttendanceTypes.data.length === 0) {
                res.healthAttendanceTypes.data = [{
                    name: "-",
                    description: "-",
                    active: "-"
                }]
            }

            this.setState({ ...this.state, types: res.healthAttendanceTypes })
        });
    }

    voltar() {
        this.setState({ ...this.state, acoes: 0 })
    }

    deletarTipo(event) {
        this.setState({...this.state, modalDelete: true, selectedType: event[1]});
    }

    deletarTipoAPI() {
        this.setState({ ...this.state, loadingDelete: true });

        deleteType(localStorage.getItem('token'), this.state.selectedType).then(res => {
            this.setState({ ...this.state, loadingDelete: false, modalDelete: false })
            this.listarTiposAtendimento();
        });
    }

    getAction() {
        var acoes = [
            <HealthAttendanceTypeList 
                cadastrar={ e => this.setState({...this.state, acoes: 1}) } 
                alterar={ e => this.setState({...this.state, acoes: 2, selectedType: e[1]}) }
                excluir={ e => this.deletarTipo(e) }
                types={ this.state.types }
            />,
            <HealthAttendanceTypeCreate   
                voltar={ this.voltar.bind(this) }
            />,
            <HealthAttendanceTypeUpdate   
                selectedType= {this.state.selectedType}
                voltar={ this.voltar.bind(this) }
            />
        ]

        return acoes[this.state.acoes];
    }

    render() {

        return (
            <Container className="tema-light">
                { !this.state.login ? <Redirect to="/login" /> : '' }
                { this.getAction() }
                <ActionModal 
                    open={ this.state.modalDelete } 
                    labelButton='Confirmar'
                    loadingButton={ this.state.loadingDelete }
                    children='Confirma a exclusão do tipo de atendimento selecionado?'
                    title='Excluir tipo de atendimento'
                    onClose={ e => e ? this.deletarTipoAPI() : this.setState({...this.state, modalDelete: false}) }
                />
            </Container>
        )
    }
}

export default HealthAttendanceType;