import React from 'react';
import {useState} from "react";
import {Typography, Container, Grid, Card, CardContent} from "@material-ui/core";
import DSwitch from "../shared/DSwitch";

function Switches() {

    const style = {
        light: {
            background: '#FFF'
        },
        dark: {
            background: '#272727'
        },
        textLight: {
            color: '#FFF'
        }
    }

    const [state, setState] = useState({
        checkedA: true,
        checkedB: false,
        checkedC: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <>
            <Container className="tema-light" style={ style.light }>
                <Typography variant="h3">Switch Buttons (Label)</Typography>
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={ 6 }>
                        <Card>
                            <CardContent>
                                <Typography>Ligado (Normal + Hover)</Typography>
                                <DSwitch checked={ state.checkedA } onChange={ handleChange } name="checkedA" label="Label A" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Card>
                            <CardContent>
                                <Typography>Desligado (Normal + Hover)</Typography>
                                <DSwitch checked={ state.checkedB } onChange={ handleChange } name="checkedB" label="Label B" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Card>
                            <CardContent>
                                <Typography>Disabled (Ativo)</Typography>
                                <DSwitch disabled={true} checked={ true } onChange={ handleChange } name="checkedD" label="Label 4" />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={ 6 }>
                        <Card>
                            <CardContent>
                                <Typography>Disabled (Inativo)</Typography>
                                <DSwitch disabled={ true } checked={ false } onChange={ handleChange } name="checkedE" label="Label 5" />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Switches;