import React, { useState, useEffect, useContext } from 'react';
import "./index.scss";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

// Context
import { AppContext } from "../../../context/AppContext";

export default function FloatingButton(props) {
  // Context
  const { userInfo } = useContext(AppContext);

  // States
  const [haveUnit, setHaveUnit] = useState(false);

  useEffect(() => {
    if (userInfo.unidade) {
      setTimeout(() => {
        setHaveUnit(true);
      }, 1000);
    }
  }, [userInfo.unidade]);

  return (
    <Box className='floating_button' style={!haveUnit ? { display: 'none' } : {}} onClick={e => { props.setOpenModal(true) }}>
      <Fab className='fundo_floating_button'>
        <ChatBubbleOutlineIcon />
      </Fab>
    </Box>
  );
}










