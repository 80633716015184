import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { Clear } from "@material-ui/icons";
import DetailsMain from "../../../Paciente/components/PacienteDetails/components/DetailsMain";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    padding: "0 20px",
    width: 768,
    maxHeight: "100%",
    overflowY: "hidden",
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  body: {
    height: `${(window.innerHeight - 80)}px`,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px 0 0",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  }  
}));

export default function MdPatients(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  function closeModal() {
    return props.onClose;
  }

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography className="text-primary" style={{ alignItems: "center", display: "flex" }}variant="h6" ><Person />
            Dados do Paciente</Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              props.onClose(true);
            }}
          />
        </div>
        <Grid container spacing={2} justifyContent="center" className="tema-light" classes={{root: classes.body}}>
          <Grid item>
            <DetailsMain 
              userDominios={ props.userDominios }
              unidadesRef={ props.unidadesRef }
              unidadesVinculadas={ props.unidadesVinculadas }
              paciente={ props.patient }
            />
          </Grid>
        </Grid>
      </>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
