import env from 'react-dotenv';

function auth(dados) {
    return fetch(`${env.API_ECINE_URL}/auth`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-functions-key': 'bgG366vmTJpYN0wpVmSA6rXMRtwYl8S8sMhs5s8nFFtbvQ2wBNdKJw=='
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function getSalaChat(token) {
    return fetch(`${env.API_ECINE_URL}/chat`, {
        headers: {
            'x-functions-key': 'bgG366vmTJpYN0wpVmSA6rXMRtwYl8S8sMhs5s8nFFtbvQ2wBNdKJw==',
            'Authorization': token
        },
        method: 'get'
    }).then(res => res.json());
}

function updateSalaChat(token, dados) {
    return fetch(`${env.API_ECINE_URL}/chat`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-functions-key': 'bgG366vmTJpYN0wpVmSA6rXMRtwYl8S8sMhs5s8nFFtbvQ2wBNdKJw==',
            'Authorization': token
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}


function getChat(token, id) {
    return fetch(`${env.API_URL}/conversation-team/${id}?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getChatListByUserId(token, id) {
    return fetch(`${env.API_URL}/conversation-team/list/user?user_id=${id}&token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createChat(token, dados) {
    return fetch(`${env.API_URL}/conversation-team?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function addParticipantChat(token, dados) {
    return fetch(`${env.API_URL}/conversation-team/add-participant?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

export { auth, getChat, getSalaChat, getChatListByUserId, createChat, updateSalaChat, addParticipantChat }