// Import core
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

// Import icons
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { toast } from 'react-toastify';

import DadosPaciente from '../../../components/DadosPaciente';
import DetailsMain from '../../../../../Paciente/components/PacienteDetails/components/DetailsMain/DetailsMain.jsx';
import Atendimentos from '../../../../components/Atendimentos';
import Prescricoes from '../../../../components/Prescricoes';

const useStyles = makeStyles((theme) => ({
    sidebarClose: {
        transition:"0.5s",
        top:159,
        right: -490,
        width:480,
        position: "absolute",
        zIndex:4,
        height: "calc(100vh - 233px)",
        display:"flex",
        alignItems:"right"
    },
    sidebarOpen: {
        transition: "0.5s",
        right: "0 !important",
        transition:"0.5s",
        top:159,
        width:480,
        position: "absolute",
        zIndex:4,
        height: "calc(100vh - 233px)",
        display:"flex",
        alignItems:"right"
    },
    sidebar: {
        width: 405,
        backgroundColor: "#f9f9f9",
        padding:24,
        height: "calc(100% - 24px)",
        borderLeft: `2px solid ${theme.palette.primary.light}`,
        overflow: 'auto'
    },
    tab: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.background.snow,
        borderRadius: "8px 0 0 8px",
        padding: 6,
        marginBottom: 4,
        "&:hover":{
            color: theme.palette.neutral.white,
            backgroundColor: theme.palette.primary.light
        }
    },
    tab_active: {
        color: theme.palette.neutral.white,
        backgroundColor: theme.palette.primary.light,
        borderRadius: "8px 0 0 8px",
        padding: 6,
        marginBottom: 4,
        "&:hover":{
            color: theme.palette.neutral.white,
            backgroundColor: theme.palette.primary.light
        }
    },
    container: {

    },
    displayShow: {
        display: "block"
    },
    displayHide: {
        display: "none"
    },
}));

function AtendimentoSidebar(props) {
    const classes = useStyles();

    const [show, setShow] = useState(true);
    const [modalFidi, setModalFidi] = useState(false)

    return (
        <>
        <div className={ props.show ? classes.sidebarOpen : classes.sidebarClose } >
            <div style={{ width: 30, height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent:"flex-end" }}>
                <IconButton onClick={() => setShow(1)} className={ (show==1) ? classes.tab_active : classes.tab }><LocalHospitalIcon /></IconButton>
                <IconButton onClick={() => setShow(2)} className={ (show==2) ? classes.tab_active : classes.tab }><PersonIcon /></IconButton>
                <IconButton onClick={() => setShow(3)} className={ (show==3) ? classes.tab_active : classes.tab }><HistoryIcon /></IconButton>
                <IconButton onClick={() => setShow(4)} className={ (show==4) ? classes.tab_active : classes.tab }><NoteAddIcon /></IconButton>
            </div>
            <div className={ classes.sidebar } >
                <div className={ (show==1) ? classes.displayShow : classes.displayHide } style={{ height: '10%' }}>
                    <DadosPaciente 
                        toast={toast} 
                        patientData={ props.patientData } 
                        anamnesePreForm={ null } 
                        toggleModal={ e => props.toggleModal(true) } 
                        attendance={props.attendance}
                    />
                </div>
                <div className={ (show==2) ? classes.displayShow : classes.displayHide }>
                    {
                        (props.userDominios && props.unidadesRef && props.unidadesVinculadas && props.paciente) 
                            ?
                                <DetailsMain
                                    userDominios={ props.userDominios }
                                    unidadesRef={ props.unidadesRef }
                                    unidadesVinculadas={ props.unidadesVinculadas }
                                    paciente={ props.paciente }
                                /> 
                            : ""
                    }
                </div>
                <div className={ (show==3) ? classes.displayShow : classes.displayHide }>
                    <Atendimentos 
                        idPaciente={ props.paciente.patient ? props.paciente.patient.id : null }
                        detalhes={ e => props.setModalAtendimentoCmd(true) }
                    />
                </div>
                <div className={ (show==4) ? classes.displayShow : classes.displayHide }>
                    <Prescricoes 
                        idPaciente={ props.paciente.patient ? props.paciente.patient.id : null }
                        detalhes={ e => props.getPDF(e) }
                    />    
                </div>
            </div>
        </div>
        </>
    );
}

export default AtendimentoSidebar;