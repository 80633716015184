
import React from "react";
import SimpleModal from "../../../shared/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";

const useStyles = makeStyles({
    modalBI: {
        width:"100%",
        height:"100%",
    },
    containerModalBI: {
        height:"100%",
    },
});

export function BI(props) {

    const classes = useStyles();
    const history = useHistory();

    return (
        
        <SimpleModal fullPage open={ props.open } onClose={ e => closeAndRedirect() }  >
            <Grid container justifyContent="center" alignItems="center" className={classes.containerModalBI}>
                <Grid item className="text-center" className={classes.modalBI}>
                    <iframe width="100%" height="100%" noborder src="https://app.powerbi.com/view?r=eyJrIjoiMzBmNWY5OGEtNmM4Yi00MjVmLTljMmItZTQ5NTFhNTdhOTBhIiwidCI6IjYyMmZhZDkxLWQ5ZGEtNGUyNy04MzhhLWE0OTEyMTM0YWFlMiIsImMiOjR9"></iframe>
                </Grid>
            </Grid>
        </SimpleModal>
    )

    function closeAndRedirect()
    {
        props.setModal();
        history.push("/profissional");
    }

}