import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography, CircularProgress } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { IconButton, FormControlLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AgendaFilter from "./components/AgendaFilter";
import AgendaItem from "./components/AgendaItem";
import { getMe } from "../../api/user";
import DButton from "../shared/DButton";
import DadosProfissional from "../shared/DadosProfissional";

//Import styles
import "./styles.scss";
import AgendaSemana from "./components/AgendaSemana";
import { filterProfessionalSchedule } from "../../api/schedule";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const styles = (theme) => ({
  sidebar: {
    padding: 32,
    backgroundColor: theme.palette.background.grass,
  },
  tabs__root: {
    background: theme.palette.background.sky,
    borderRadius: 20,
    width: 260,
    minHeight: 32,
    marginTop: 16,
  },
  tab__root: {
    background: theme.palette.background.sky,
    borderRadius: 20,
    fontSize: 12,
    textTransform: "none",
    padding: 6,
    minWidth: 130,
    minHeight: 32,
  },
  tab__selected: {
    border: 0,
    color: theme.palette.background.snow,
    backgroundColor: theme.palette.primary.light,
  },
  tabs2__root: {
    backgroundColor: theme.palette.background.sky,
    borderRadius: 20,
    width: 300,
    minHeight: 32,
    marginTop: 16,
  },
  tab2__root: {
    backgroundColor: theme.palette.transparency.green8,
    borderRadius: 20,
    fontSize: 12,
    textTransform: "none",
    padding: 6,
    minWidth: 100,
    minHeight: 32,
  },
  tab2__selected: {
    border: 0,
    color: theme.palette.background.snow,
    backgroundColor: theme.palette.primary.main,
  },
  close_button: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  dialog: {
    padding: 16,
    width: 700,
    maxWidth: 700,
    backgroundColor: theme.palette.background.cloud,
  },
  dialog__title: {
    color: theme.palette.primary.light,
    fontSize: 18,
    margin: "0 0 40px",
  },
  dialog__text: {
    color: theme.palette.neutral.black,
    fontSize: 14,
  },
  tooltip: {
    backgroundColor: theme.palette.background.snow,
    color: theme.palette.neutral.black,
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    fontSize: 12,
    width: 210,
  },
  tooltip_arrow: {
    color: theme.palette.background.snow,
  },
  filled_input: {
    backgroundColor: theme.palette.background.snow,
    padding: "4px 8px",
    boxShadow:
      "0px 4px 5px rgba(49, 34, 67, 0.12), 0px 1px 10px rgba(49, 34, 67, 0.1), 0px 2px 4px rgba(49, 34, 67, 0.18)",
  },
  outlined: {
    padding: 8,
  },
  textDate: {
    color: theme.palette.primary.light,
    fontSize: 18,
    position: "absolute",
    margin: 0,
    top: 11,
    width: "100%",
    textAlign: "center",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  snowBackgroundColor: {
    background: theme.palette.background.snow,
  },  
  snowColor: {
    color: theme.palette.background.snow,
  },  
  midlightgrayColor: {
    color: theme.palette.neutral.midlightgray,
  },
  midgrayColor: {
    color: theme.palette.neutral.midgrey,
  },  
});

class Agenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: true,
      status: 0,
      calendar: 0,
      open: false,
      selectedDate: new Date(),
      professional: null,
      scheduleList: [],
      filter: null,
      todayDate: new Date(),
      selectedWeekDays: this.getClosestWeekDays({ locale: ptBR }),
      scheduleListFilterData: '',
      scheduleListFilterKey: '',
      loading: false
    };
  }

  handleClickOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  setSelectedDate = (date) => {
    this.setState({ ...this.state, selectedDate: new Date(date) })
    this.filtrarAgendas(date);
  };

  async componentDidMount() {
    await getMe(localStorage.getItem("token")).then((res) => {
      
      try {
        if (res.error) {
          this.setState({ ...this.state, login: false });
        } else {
  
          this.setState({ ...this.state, professional: res });

          var selected_date_calendar = (new Date()).toLocaleDateString();
          var formated_selected_date_calendar = `${selected_date_calendar.split("/")[2]}-${selected_date_calendar.split("/")[1]}-${selected_date_calendar.split("/")[0]}`;
  
          filterProfessionalSchedule(localStorage.getItem("token"), {
            status: 'scheduled',
            start_date_min: formated_selected_date_calendar,
            start_date_max: formated_selected_date_calendar,
            user_id: localStorage.getItem("uid"),
          }).then((data) => {
            if (data.status) {

              if(this.state.status === 0) {
                var dataSchedule = data.schedule
                .filter((x) => x.status !== 3)
                .map((item, index) => {
                  return item
                })
                this.setState({ ...this.state, scheduleList: data.schedule, scheduleListFilterData: dataSchedule, loading: false});
              } else {
                dataSchedule = data.schedule
                .filter((x) => x.status === 3)
                .map((item, index) => {
                  return item
                })
                this.setState({ ...this.state, scheduleList: data.schedule, scheduleListFilterData: dataSchedule, loading: false});
              }
            }
          });
        }
      } catch(err) {
        console.log(err, 'componentDidMount')
      }

      
    });
  }

  filtrarAgendas(date, status = 0) {
    this.setState({ ...this.state, loading: true , status: status, selectedDate: date});

    try {
      var selected_date_calendar = (new Date(date)).toLocaleDateString();
      var formated_selected_date_calendar = `${selected_date_calendar.split("/")[2]}-${selected_date_calendar.split("/")[1]}-${selected_date_calendar.split("/")[0]}`;
      filterProfessionalSchedule(localStorage.getItem("token"), {
        status: status === 0 ? 'scheduled' : 'finished',
        start_date_min: formated_selected_date_calendar,
        start_date_max: formated_selected_date_calendar,
        user_id: localStorage.getItem("uid"),
      }).then((data) => {
        if (data.status) {
          if(status === 0) {
            var dataSchedule = data.schedule
              .filter((x) => x.status !== 3)
              .map((item, index) => {
                return item
            })
            this.setState({ ...this.state, scheduleList: data.schedule, scheduleListFilterData: dataSchedule, loading: false});
          } else {
            dataSchedule = data.schedule
            .filter((x) => x.status === 3)
            .map((item, index) => {
              return item
            })
            this.setState({ ...this.state, scheduleList: data.schedule, scheduleListFilterData: dataSchedule, loading: false});
          }

        }
      });
    } catch(err) {
      console.log(err, 'err-filter-date');
    }
  }

  componentDidUpdate(prevProps) {    
    //
  }

  addMonth(date, month) {

    date.setMonth(date.getMonth() + month);

    return date;
  }

  handleMonthChange(value) {

    var new_date = this.addMonth(this.state.selectedDate, value);

    this.setState({
      ...this.state,
      selectedDate: new_date,
    });

    this.setSelectedDate(new_date)
  }

  /**
   * @description Função que retorna o dia da semana mais próximo da data atual
   * @param {object} data
   * @param {Date} fromDate
   */
  getClosestWeekDays({ locale }, fromDate = new Date()) {
    const weekDays = [];
    const start = startOfWeek(fromDate, { locale });
    const end = endOfWeek(fromDate, { locale });

    var scheduleDays = [];
    if (this.state) {
      scheduleDays = this.state.scheduleList.map((x) => x.start.split(" ")[0]);
    }

    eachDayOfInterval({ start, end }).forEach((day) => {
      var dayOfWeek = {
        day: format(day, "EEEEEE", { locale }),
        dayNumber: format(day, "d", { locale }),
        date: format(day, "dd/MM/yyyy", { locale }),
        isToday:
          format(day, "dd/MM/yyyy", { locale }) ===
          format(new Date(), "dd/MM/yyyy", { locale }),
        scheduleCommitment: scheduleDays.filter(
          (x) => x === format(day, "yyyy-MM-dd", { locale })
        ),
      };

      weekDays.push(dayOfWeek);
    });

    return weekDays;
  }

  TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Typography>{children}</Typography>}
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        style={{ height: "calc(100vh - 148px)", overflow: "auto" }}
      >
        {!this.state.login ? <Redirect to="/login" /> : ""}
        <Grid item md={4} className={classes.sidebar}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "relative", width: 36 }}>
                <CalendarTodayIcon
                  fontSize="large"
                  className={classes.primaryColor}
                />
                <h5 className={classes.textDate}>
                  {format(this.state.selectedDate, "dd", { locale: ptBR })}
                </h5>
              </div>
              <h1
                style={{
                  whiteSpace: "nowrap",
                  fontSize: 24,
                  margin: "0 0 0 32px",
                }}
                className={classes.primaryColor}
              >
                {format(this.state.selectedDate, "MMMM yyyy", { locale: ptBR })}
              </h1>
            </div>
            <div>
              <IconButton>
                <ChevronLeftIcon
                  fontSize="large"
                  className={classes.primaryColor}
                  onClick={(e) => this.handleMonthChange(-1)}
                />
              </IconButton>
              <IconButton>
                <ChevronRightIcon
                  fontSize="large"
                  className={classes.primaryColor}
                  onClick={(e) => this.handleMonthChange(+1)}
                />
              </IconButton>
            </div>
          </div>
          {/* <div style={{ width: 300, margin: "0 auto 40px" }}>
            <Tabs
              value={this.state.calendar}
              classes={{
                root: classes.tabs2__root,
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                label="Hoje"
                classes={{
                  root: classes.tab2__root,
                  selected: classes.tab2__selected,
                }}
                onClick={() => {
                  this.setState({ calendar: 0 });
                }}
              />
              <Tab
                label="Semana"
                classes={{
                  root: classes.tab2__root,
                  selected: classes.tab2__selected,
                }}
                onClick={() => {
                  this.setState({ calendar: 1 });
                }}
              />
              <Tab
                label="Mês"
                classes={{
                  root: classes.tab2__root,
                  selected: classes.tab2__selected,
                }}
                onClick={() => {
                  this.setState({ calendar: 2 });
                }}
              />
            </Tabs>
          </div> */}

          <AgendaSemana
            // calendar={this.state.calendar}
            calendar={1}
            setSelectedDate={(date) => this.setSelectedDate(date)}
            selectedDate={this.state.selectedDate}
            weekDays={this.getClosestWeekDays({ locale: ptBR })}
            selectedWeekDays={this.state.selectedWeekDays}
            setSelectedWeekDays={(e) => null}
          />

          {/* <hr
            style={{
              border: 0,
              borderBottom: "1px solid",
              color: classes.midlightgrayColor,
              margin: "40px 0",
            }}
          />

          <div style={{ width: 326, margin: "0 auto" }}>
            <h3
              style={{ fontSize: 16, margin: "0 0 32px" }}
              className={classes.primaryColor}
            >
              Definir periodo de bloqueio
            </h3>
          </div>
          <div
            style={{
              width: 294,
              margin: "0 auto",
              borderRadius: 8,
              padding: 16,
              boxShadow:
                "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
            }}
            className={classes.snowBackgroundColor}
          >
            <h5
              style={{
                fontSize: 16,
                fontWeight: 400,
                margin: 0,
              }}
              className={classes.primaryColor}
            >
              Bloqueio{" "}
              <span style={{ color: classes.midGray, fontSize: 14 }}>
                (Entre 09:00 e 15:00)
              </span>
            </h5>
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: 8 }}>
                <Typography
                  className={classes.dialog__text}
                  style={{ marginTop: 16 }}
                >
                  Início:
                </Typography>
                <OutlinedInput
                  InputProps={{ disableUnderline: true }}
                  placeholder="00:00"
                  classes={{ input: classes.outlined }}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 8 }}>
                <Typography
                  className={classes.dialog__text}
                  style={{ marginTop: 16 }}
                >
                  Término:
                </Typography>
                <OutlinedInput
                  InputProps={{ disableUnderline: true }}
                  placeholder="00:00"
                  classes={{ input: classes.outlined }}
                />
              </Grid>
            </Grid>
            <DButton variant="primary" size="sm" style={{ marginTop: 16 }}>
              Enviar pedido
            </DButton>
          </div> */}
        </Grid>
        <Grid item md={8} style={{ padding: 32 }}>
          <Dialog
            onClose={this.handleClose}
            open={this.state.open}
            classes={{ paper: classes.dialog }}
          >
            <IconButton className={classes.close_button}>
              <CloseIcon onClick={this.handleClose} />
            </IconButton>
            <h3 className={classes.dialog__title}>Novo compromisso</h3>
            <Grid container>
              <Grid item xs={6} style={{ paddingRight: 32 }}>
                <TextField
                  label="Nome do compromisso"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Aniversário"
                />
                <Typography
                  className={classes.dialog__text}
                  style={{ margin: "42px 0 16px" }}
                >
                  Enviar notificação de aviso?
                </Typography>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="top"
                    control={<Radio color="primary" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label="Não"
                  />
                </RadioGroup>
                <Typography
                  className={classes.dialog__text}
                  style={{ margin: "42px 0 16px" }}
                >
                  Deseja bloquear este horário?
                </Typography>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                >
                  <FormControlLabel
                    value="top"
                    control={<Radio color="primary" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="start"
                    control={<Radio color="primary" />}
                    label="Não"
                  />
                </RadioGroup>
               
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: 24 }}>
                <div
                  style={{
                    boxShadow:
                      "0px 12px 17px rgba(49, 34, 67, 0.08), 0px 5px 22px rgba(49, 34, 67, 0.06), 0px 7px 8px rgba(49, 34, 67, 0.13)",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <DatePicker
                      autoOk
                      disableToolbar="false"
                      orientation="landscape"
                      variant="static"
                      openTo="date"
                      value={this.state.selectedDate}
                      onChange={(e) =>
                        this.setState({ ...this.state, selectedDate: e })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <Grid container>
                  <Grid item xs={6} style={{ paddingRight: 8 }}>
                    <Typography
                      className={classes.dialog__text}
                      style={{ marginTop: 24 }}
                    >
                      Início:
                    </Typography>
                    <div className={classes.filled_input}>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        placeholder="00:00"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 8 }}>
                    <Typography
                      className={classes.dialog__text}
                      style={{ marginTop: 24 }}
                    >
                      Término:
                    </Typography>
                    <div className={classes.filled_input}>
                      <TextField
                        InputProps={{ disableUnderline: true }}
                        placeholder="00:00"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ textAlign: "right", marginTop: 56 }}>
              <DButton
                variant="secondary"
                size="sm"
                onClick={this.handleClose}
                style={{ marginRight: 16 }}
              >
                Voltar
              </DButton>
              <DButton variant="primary" size="sm" onClick={this.handleClose}>
                Salvar
              </DButton>
            </div>
          </Dialog>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1
              style={{
                whiteSpace: "nowrap",
                fontSize: 24,
                marginRight: 60,
              }}
              className={classes.primaryColor}
            >
              Sua agenda
            </h1>
            <AgendaFilter
              setScheduleList={(schedule, filter) =>
                this.setState({ ...this.state, scheduleList: schedule, filter })
              }
            />
            {/* <IconButton
              style={{ marginLeft: 48, marginRight: -8 }}
              className={classes.primaryColor}
              onClick={this.handleClickOpen}
            >
              <AddCircleIcon />
            </IconButton> */}
          </div>
          <DadosProfissional />
          <div style={{ width: 260, margin: "0 auto" }}>
            <Tabs
              value={this.state.status}
              classes={{
                root: classes.tabs__root,
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                label="Agendadas"
                classes={{
                  root: classes.tab__root,
                  selected: classes.tab__selected,
                }}
                onClick={() => {
                  this.setState({ ...this.state, status: 0 });
                  this.filtrarAgendas(this.state.selectedDate, 0)
                }}
              />
              <Tab
                label="Encerradas"
                classes={{
                  root: classes.tab__root,
                  selected: classes.tab__selected,
                }}
                onClick={() => {
                  this.setState({ ...this.state, status: 1 });
                  this.filtrarAgendas(this.state.selectedDate, 1)
                }}
              />
            </Tabs>
          </div>

          <div style={{ padding: "36px 0" }}>
            {this.state.loading ? (
              <>
                <CircularProgress size={15} color="inherit" /> Carregando...
              </>
            ) : (
              <>
              {Array.isArray(this.state.scheduleListFilterData) && this.state.scheduleListFilterData.map((item, index) => <AgendaItem data={item} key={`agenda-item-${index}`} />) }
                
              </>
            )}

            {/* {this.state.status === 0
              ? Array.isArray(this.state.scheduleList) &&
                this.state.scheduleList
                  .filter((x) => x.status !== 3)
                  .map((item, index) => {
                    return <AgendaItem data={item} key={index} />;
                  })
              : this.state.scheduleList
                  .filter((x) => x.status === 3)
                  .map((item, index) => {
                    return <AgendaItem data={item} key={index} />;
                  })} */}
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Agenda);
