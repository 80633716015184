import { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client'
import powerbi from "./../../api/powerbi";

function PowerBI(props)
{
    const [token, setToken] = useState(null);
    
    const reportId = "128f159d-3447-40c1-99ed-423d1aae600a";
    const groupId = "02f01751-d07b-4441-accc-929b4821dd7c";

    useEffect(() => {
        powerbi.getAccesToken(localStorage.getItem('token')).then(res => {
            if(res.data.status) {
                setToken(res.data.token_content.access_token);
            }
        });
    }, []);


    return(
        <>
            { token && <PowerBIEmbed
                            embedConfig = {{
                                type: 'report',
                                id: reportId,
                                embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
                                accessToken: token,
                                tokenType: models.TokenType.Aad,
                                settings: {
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false
                                        }
                                    }
                                }
                            }}
                        
                            eventHandlers = { 
                                new Map([
                                    ['loaded', function () {console.log('Report loaded');}],
                                    ['rendered', function () {console.log('Report rendered');}],
                                    ['error', function (event) {console.log(event.detail);}]
                                ])
                            }
                                
                            cssClassName = { "Ember-container" }
                        
                            getEmbeddedComponent = { (embeddedReport) => {
                                window.report = embeddedReport;
                            }}
                        />
            }        
        </>
    )
}

export default PowerBI;