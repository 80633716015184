import React from 'react';
import { Container } from  "@material-ui/core";

import HistoricoList from './HistoricoList';

export default function Index() {
  return (
    <Container className="tema-light">
      <HistoricoList/>
    </Container>
  )
}
