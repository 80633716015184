//Import core
import React, {useState} from 'react';
import clsx from "clsx";
import {FormControl, InputAdornment, OutlinedInput} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import { usePatientFilter } from '../../../../../../context/PatientFilterContext';

//Import API services
import { getPatientByFilter } from "../../../../../../api/patient"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '100%'
    },
    lupaIcon: {
        color: `${theme.palette.primary.main} !important` 
    }
}));

function PacienteFilter(props) {
    const classes = useStyles();

    const [filterValue, setFilterValue] = useState('');
    const { filter, setFilter } = usePatientFilter();

    function listarPacientes() {
        setFilter({ ...filter, search: encodeURI(filterValue), time: (new Date()).getTime() })
    }

    return(
        <form autoComplete="off" onSubmit={form => {
            form.preventDefault();
            listarPacientes()
        }}>
            <FormControl className={clsx(classes.textField)} variant="outlined" size="small">
                <OutlinedInput
                    id="outlined"
                    type="text"
                    placeholder="Buscar por nome, ID, CNS ou CPF"
                    labelWidth={0}
                    value={ filterValue }
                    onChange={e => {
                        console.log(e)
                        setFilterValue(e.target.value.toString().replace(/[`´¨~!@#$%^&*()_|+\=?;:'",<>\{\}\[\]\\\/]/gi, ''))
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="search icon"
                                onClick={e => listarPacientes()}
                                edge="end">
                                <SearchIcon className={classes.lupaIcon} />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </form>
    )
}

export default PacienteFilter;