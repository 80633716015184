import { useState, useEffect } from "react";
import VaccinesIcon from '@mui/icons-material/Vaccines';
import EggAltIcon from '@mui/icons-material/EggAlt';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import DraftsIcon from '@mui/icons-material/Drafts';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import "./../index.scss";
import { GetMyHealthList } from "./../../../../../../api/above/MinhaSaude";
import IMCView from "./IMCView";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    titleBoxCardNewVisualizadorClinico: {
        color: theme.palette.primary.light,
        fontWeight: 700,
        fontSize: "18px",
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
    },
}));

function HeaderCards(props)
{

    const [drugList, setDrugList] = useState([]);
    const [allergyList, setAllergyList] = useState([]);
    const [chronicDiseasesLIst, setChronicDiseasesLIst] = useState([]);
    const [pressureMeasurementLIst, setPressureMeasurementLIst] = useState([]);
    const [capillaryBloodGlucoseLIst, setCapillaryBloodGlucoseLIst] = useState([]);
    const [bmimEasurementLIst, setBmimEasurementLIst] = useState([]);
    const classes = useStyles();
    
    useEffect(() => {
        if(props.healthAttendance)
            GetMyHealthList(localStorage.getItem('token'), props.healthAttendance).then(res => {
                if(res.status && res.details && res.details.itens) {
                    setDrugList(res.details.itens.drugList);
                    setAllergyList(res.details.itens.allergyList);
                    setChronicDiseasesLIst(res.details.itens.chronicDiseasesLIst);
                    setPressureMeasurementLIst(res.details.itens.pressureMeasurementLIst);
                    setCapillaryBloodGlucoseLIst(res.details.itens.capillaryBloodGlucoseLIst);
                    setBmimEasurementLIst(res.details.itens.bmimEasurementLIst);
                }
            })
    }, [props.healthAttendance])

    return (
        <div className='main_div_visualizador_clinico'>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <VaccinesIcon />
                    Alergias
                </div>
                { allergyList.map(allergy => <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        { allergy.type }
                    </div>
                    <div className='tag_new_visualizador_clinico'>
                        { allergy.name }
                    </div>
                </div> ) }
                { allergyList.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }
            </div>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <EggAltIcon />
                    Doenças Crônicas
                </div>
                { chronicDiseasesLIst.map(chronicDisease => <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        { chronicDisease.name }
                    </div>
                    <div className='tag_new_visualizador_clinico'>
                        { chronicDisease.obs }
                    </div>
                </div> ) }
                { chronicDiseasesLIst.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }
            </div>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <FingerprintIcon />
                    Glicemia Capilar
                </div>
                { capillaryBloodGlucoseLIst.map(capillaryBloodGlucose => <div className='divide_info_visualizador_clinico'>
                    <div className='tag_new_visualizador_clinico'>
                        { capillaryBloodGlucose.mgdl }
                    </div>
                </div> ) }
                { capillaryBloodGlucoseLIst.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }
            </div>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <TagFacesIcon />
                    IMC
                </div>
                { bmimEasurementLIst.map(bmimEasurement => <div className='divide_info_visualizador_clinico'>
                    <div className='tag_new_visualizador_clinico'>
                        <IMCView peso={ parseFloat(bmimEasurement.weight_kg) } altura={ parseFloat(bmimEasurement.height_cm) } />                                                
                    </div>
                </div> ) }
                { bmimEasurementLIst.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }                
            </div>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <DraftsIcon />
                    Medicamentos
                </div>
                { drugList.map(drug => <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        { drug.name }
                    </div>
                    <div className='tag_new_visualizador_clinico'>
                        { drug.active_principle }
                    </div>
                </div> ) }
                { drugList.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }                
            </div>
            <div className='box_new_cards_visualizador_clinico'>
                <div className={classes.titleBoxCardNewVisualizadorClinico}>
                    <MonitorHeartIcon />
                    Pressão Arterial
                </div>
                { pressureMeasurementLIst.map(pressureMeasurement => <div className='divide_info_visualizador_clinico'>
                    <div className='tag_new_visualizador_clinico'>
                    { pressureMeasurement.pa_systolic_mmhg }/{ pressureMeasurement.pa_diastolica_mmhg } mmHg
                    </div>
                </div> ) }
                { pressureMeasurementLIst.length === 0 && <div className='divide_info_visualizador_clinico'>
                    <div className='info_new_visualizador_clinico'>
                        Nenhum Registro
                    </div>
                </div> }
            </div>
        </div>
    )
}

HeaderCards.defaultProps = {
    healthAttendance: null
}

export default HeaderCards;