import { createTheme, ThemeProvider } from "@material-ui/core";
import {createContext, useContext, useState} from "react";
import GlobalStyles from "../GlobalStyles";
import { palette } from '../api/temas';

export const useThemeContext = createContext({});

/**
 * @description Seguindo o padrão Hipócrates 1.0 - Figma
 * @dev https://www.figma.com/file/D9RZSmA5UN6RbmIQuMwpGS/Hip%C3%B3crates-1.0?node-id=1%3A173
 */
export default function ThemeContext({children}) {
    
    const [theme, setTheme] = useState(createTheme({
        palette,
      }));

    /**
     * @description Altera a palheta de cores do sistema.
     * @param {PaletteColorOptions} palette
     * @memberof useTheme
     */
    const handleChangeTheme = palette => {   
        if(palette){
            localStorage.setItem("theme", JSON.stringify(palette));
            setTheme(createTheme({palette: palette}));        
        }        
    }

    const handleChangeBackgroundAppColor = color =>  window.document.body.style.backgroundColor = color || "white";

    return (
        <useThemeContext.Provider value={{handleChangeTheme, handleChangeBackgroundAppColor}}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                {children}
            </ThemeProvider>
        </useThemeContext.Provider>
    )
}

export const useAppTheme = () => {
    return useContext(useThemeContext);
}