import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import DSwitch from "./../../DSwitch";

const useStyles = makeStyles({
    card_subtitle: {
        color: "#272727",
        fontSize: 14,
        margin: "0 0 16px"
    },
    edit_input: {
        backgroundColor: "#fff",
        width: "56px !important",
        marginRight: 8,
        "& input": {
            padding: 12
        }
    },
    content: {
        height: 76
    }
});


function VerdadeiroFalsoComponent(props)
{

    const classes = useStyles();

    const [value, setValue] = useState(props.value === "verdadeiro");

    return(
        <Grid item>
            <Grid container justifyContent="center" alignItems="flex-end" classes={{root: classes.content}}>
                <Grid item>
                    <DSwitch checked={ value } 
                        onChange={ e => {
                            let new_value = !value;
                            setValue(new_value)
                            props.onChange([new_value ? 'verdadeiro' : 'falso']);
                        } } 
                        name="verdadeiro_falso" />
                </Grid>
                { props.showName && <Grid item><Typography style={{ fontSize: 14, fontWeight: 500 }}>{ props.name }</Typography></Grid> }            
            </Grid>
        </Grid>
    )
}

VerdadeiroFalsoComponent.defaultProps = {
    value: "falso",
    showName: false,
    name: null,
    onChange: function(value){
        console.log(value)
    }
}

export default VerdadeiroFalsoComponent;