import axios from 'axios';
import env from 'react-dotenv';

async function getCep(cep) {
  var new_cep = cep.replace(/[^0-9]/gm, "");

  if (new_cep.length === 8) {
    //var url = `https://viacep.com.br/ws/${new_cep}/json`;
    var url = `https://cep.awesomeapi.com.br/json/${new_cep}`;
    
    return fetch(url, {
      method: "get",
    })
      .then((res) => {      

        return res.json().then((response) => {
          
          if(!response.address_name){
            return { erro: true}
          }
  
          return {
            "cep": response.cep, 
            "logradouro": response.address_name,
            "logradouro_tipo": response.address_type,
            "complemento": "",
            "bairro": response.district,
            "localidade": response.city,
            "uf": response.state,
            "ibge": response.city_ibge,
            "ddd": response.ddd,
            "siafi": "",
            "gia": ""
          };
        });

      })
      .catch((err) => {
        throw err;
      });
  } else {
    throw new Error("CEP inválido"); // Devolve erro para tratamento no front.
  }
}

async function getCep2(cep) {
  var new_cep = cep.replace(/[^0-9]/gm, "");

  if (new_cep.length === 8) {

    var url = `${env.API_URL}/service/cep/${new_cep}`;

    return fetch(url, {
      method: "get",
    })
      .then((res) => res.json())
      .catch((err) => {
        throw err;
      });
  } else {
    throw new Error("CEP inválido"); // Devolve erro para tratamento no front.
  }
}

function cnsUnique(cns, id, token)
{
      return axios.post(`${env.API_URL}/document/unique?token=${token}`, {
        type: 'cns',
        value: cns,
        id
    })
    .then(res => res.data);
}

function cpfUnique(cpf, id, token)
{
      return axios.post(`${env.API_URL}/document/unique?token=${token}`, {
        type: 'cpf',
        value: cpf,
        id
    })
    .then(res => res.data);
}

export { getCep, getCep2, cnsUnique, cpfUnique };
