import NewCards from "../shared/NewCards";
import SubmenuNewCardsAtendimento from "../shared/SubMenuNewCards/SubmenuNewCardsAtendimento";
import { Grid } from '@material-ui/core';
import Container from '@mui/material/Container';
import { useState } from "react";
import SubmenuNewCardsSolicitacao from "../shared/SubMenuNewCards/SubmenuNewCardsSolicitacao";
import AnamneseNewTab from "../shared/NewTabs/AnamneseNewTab";
import HistoricoNewTab from "../shared/NewTabs/HistoricoNewTab";
import NewCardsEMenu from "../NewCardsEMenu"
import VisualizadorClinNewCards from "../shared/VisualizadorClinNewCards"
import VisualizadorClinNewTab from "../shared/NewTabs/VisualizadorClinNewTab";
import VoipCall from "./../shared/VoipCall";
import VideoCall from "./../shared/VideoCall";
import LigacoesUnidades from "../shared/NewTabs/LigacoesUnidades";
import LigacoesEspecialidades from "../shared/NewTabs/LigacoesEspecialidades";
import ModalChat from "../shared/ModalChat"
//import ModalChamada from "../shared/ModalIncomingCall"

// import VideoImage from "./../../assets/img/video-default2.png";
// import VideoImageGuest from "./../../assets/img/video-default1.png";
import { ToastContainer } from "react-toastify";
// import VideoDefault from "https://desembarque-qa.s3.sa-east-1.amazonaws.com/example.mp4";

import Weni from "../shared/Weni";


export default function Tela() {

  const [modalWeni, setModalWeni] = useState(false);

  const [call, setCall] = useState({
    status: false,
    duration: "0:00",
    phone: {
      nickname: "Apelido",
      number: "(11) 00000-0000"
    }
  })

  const [videoCall, setVideoCall] = useState({
    status: true,
    duration: "0:00",
    phone: {
      nickname: "Apelido",
      number: "(11) 00000-0000"
    }
  })

  const [guests, setGuests] = useState([]);
  const [chat, setChat] = useState(null);
  const [featuredGuest, setFeaturedGuest] = useState(null);

  const handleCall = () => {

    var minutos = 0;
    var segundos = 0;

    let idInterval = setInterval(function () {
      if (++segundos > 59) {
        minutos++;
        segundos = 0;
      }

      setCall({ ...call, status: true, duration: `${minutos.toString().padStart(2, 0)}:${segundos.toString().padStart(2, 0)}` });
    }, 1000);

    localStorage.setItem('idInterval', idInterval);
  }

  const handleEndCall = () => {
    clearInterval(localStorage.getItem('idInterval'))
    setCall({
      status: false,
      duration: "0:00",
      phone: {
        nickname: "Apelido",
        number: "(11) 00000-0000"
      }
    })
  }

  const handleVideoCall = () => {

    var minutos = 0;
    var segundos = 0;

    let idInterval = setInterval(function () {
      if (++segundos > 59) {
        minutos++;
        segundos = 0;
      }

      setVideoCall({ ...videoCall, status: true, duration: `${minutos.toString().padStart(2, 0)}:${segundos.toString().padStart(2, 0)}` });
    }, 1000);

    localStorage.setItem('idInterval', idInterval);
  }

  const handleEndVideoCall = () => {
    clearInterval(localStorage.getItem('idInterval'))
    setVideoCall({
      status: false,
      duration: "0:00",
      phone: {
        nickname: "Apelido",
        number: "(11) 00000-0000"
      }
    })
  }

  const handleGuest = () => {
    let new_guest = [...guests, <Video />];
    setGuests(new_guest);
    setFeaturedGuest(new_guest[0]);
  }


  const Video = () => {
    return <video width="100%" height="100%" controls>
      <source src="https://desembarque-qa.s3.sa-east-1.amazonaws.com/example.mp4" type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  }

  return (
    <>
      <ToastContainer />      
      <VoipCall chatButton callButton videoCallButton call={call} videoCall={videoCall} onVideoCall={handleVideoCall} onCall={handleCall} onEndCall={handleEndCall} />
      {videoCall.status && <VideoCall
        duration={videoCall.duration}
        onFinish={handleEndVideoCall}
        onAddUser={handleGuest}
        chat={chat}
        guest={guests}
        featuredGuest={featuredGuest}>

        {/* elemento representando o video */}
        <video width="100%" height="100%" controls>
          <source src="https://desembarque-qa.s3.sa-east-1.amazonaws.com/example.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </VideoCall>}
      <Container maxWidth="xl" style={{ backgroundColor: "#F5F6F6" }}>
        <Grid item md={12}>
          <div>
            <LigacoesEspecialidades />
          </div>
          <div>
            {/* <ModalChat /> */}
          </div>
          <div>
            {/* <ModalChamada /> */}
          </div>

        </Grid>
        <button onClick={e => setModalWeni(true)} type="button">Modal Weni</button>
        { modalWeni && <Weni open={true} onClose={ e => setModalWeni(false) } /> }
      </Container>
    </>
  )
}