// Import core
import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DButton from '../../../../../shared/DButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { CircularProgress } from "@material-ui/core";

// Import icons
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PrintIcon from '@material-ui/icons/Print';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SearchIcon from '@material-ui/icons/Search';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import GetAppIcon from '@material-ui/icons/GetApp';

// Import toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import API services
import { getAllMedicalSpecialties } from '../../../../../../api/medicalSpecialty'
import { listUserByMedicalSpeciality } from "../../../../../../api/user";

// Import context
import { AppContext } from "../../../../../../context/AppContext";
import { PatientContext, usePatient } from "../../../../../../context/usePatient";

// Import helpers
import { mascaraCNES } from "../../../../../../helpers/formatacao";

// Import components
import VisualizarExame from "../../../../../AtendimentoImediato/components/VisualizadorExames"
import PreAtendimento from "../../../../../Solicitacoes/components/SolicitacoesPatient/components/PreAtendimento/PreAtendimento.jsx";

const useStyles = makeStyles({
    sidebar: {
        top:159,
        left: 0,
        width:56,
        position: "absolute",
        zIndex:5,
        paddingTop:24,
        paddingLeft: 32,
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    },
    button: {
        color: "#fff",
        width: 40,
        height:40,
        backgroundColor: "#2727274D",
        marginBottom: 8
    },
    sidebarClose: {
        transition:"0.5s",
        width: 445,
        height: "calc(100% - 195px)",
        background:"#f4f4f4",
        position: "absolute",
        top:159,
        left:-480,
        zIndex: 7,
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between"
    },
    sidebarOpen: {
        transition: "0.5s",
        left: "0 !important",
        width: 445,
        height: "calc(100% - 205px)",
        background:"#f4f4f4",
        position: "absolute",
        top:159,
        zIndex: 7,
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between"
    },
    btClose: {
        position: "absolute",
        right: -32,
        top: 65,
        background: "#f4f4f4",
        borderRadius: "0 5px 5px 0",
        padding: 6,
        "&:hover": {
            background: "#f4f4f4"
        }
    },
    title: {
        display: "flex",
        fontSize: 16,
        fontWeight: 500,
        color: "#4a4ae9",
        alignItems: "center",
        margin: 0
    },
    footer: {
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width:"100%",
        height:80,
        borderTop:"1px solid #c7c7c7",
        
        bottom: 0
    },
    autocomplete_input: {
        padding: "0 9px !important"
    },
    popperTooltip: {
        
        
    },
    tooltip: {
        background: "#272727",
        position: "relative",
        "&::after": {
            content: '',
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "5px solid transparent",
            borderRight: "20px solid #272727",
            borderLeft: 0,
            marginTop: "-5px",
            marginLeft: "-5px",
        }
    },
    displayShow: {
        display: "block"
    },
    displayHide: {
        display: "none"
    },
    title_visual: {
        height: 30,
        width: "100%",
        background: "#4a4ae9",
        display: "flex",
        alignItems: "center",
        color: "#fff",
        paddingLeft: 12,
        fontSize: 16,
        fontWeight: 500
    },
    size_visual: {
        width: "100%",
        color: "#4f4f4f",
        fontSize: 12,
        fontWeight: 300,
        textAlign: "right",
        marginTop: 8
    }
});


function Sidebar(props) {
    const classes = useStyles();

    const { userInfo } = useContext(AppContext);
    const { preForm, loading } = usePatient(PatientContext);

    const [show, setShow] = useState(false);
    const [specialties, setSpecialties] = useState([])
    const [specialty, setSpecialty] = useState({});
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [doctorsList, setDoctorsList] = useState([]);
    const [fileSize, setFileSize] = useState('');
    const [downloadFile, setDownloadFile] = useState(false);

    useEffect(() => {
        getAllMedicalSpecialties(localStorage.getItem('token')).then(res => {
            if (res && res.length) {
                setSpecialties(res);
            }
        });
    }, []);

    useEffect(() => {
        if (downloadFile) {
            setDownloadFile(false);
        }
    }, [downloadFile]);

    async function listaMedicos(specialty) {
        listUserByMedicalSpeciality(localStorage.getItem('token'), userInfo.unidade, specialty.id).then(res => {
            if (res.status) {
                setDoctorsList(res.medicalUnitExecutant)
            }
        });
    }

    function handleSuccess() {
        toast.success("O arquivo foi baixado com sucesso");
    }

    return (
        <>
            <div className={ classes.sidebar } >
                <Tooltip
                    title="Novo Atendimento"
                    placement="right"
                    classes={{
                        popper: classes.popperTooltip,
                        tooltip: classes.tooltip
                    }}
                >
                    <IconButton className={ classes.button } onClick={() => setShow(1)}><AddCircleIcon style={{ width: 20 }} /></IconButton>
                </Tooltip>
                <Tooltip
                    title="Solicitar Interconsulta"
                    placement="right"
                    classes={{
                        popper: classes.popperTooltip,
                        tooltip: classes.tooltip
                    }}
                >
                    <IconButton className={ classes.button } onClick={() => setShow(2)}><PersonAddIcon style={{ width: 20 }} /></IconButton>
                </Tooltip>
                <Tooltip
                    title="Criar PDF"
                    placement="right"
                    classes={{
                        popper: classes.popperTooltip,
                        tooltip: classes.tooltip
                    }}
                >
                    <IconButton className={ classes.button } onClick={() => setShow(3)}><PrintIcon style={{ width: 20 }} /></IconButton>
                </Tooltip>
            </div>

            <div className={ show ? classes.sidebarOpen : classes.sidebarClose } >
                <IconButton className={ classes.btClose } onClick={() => setShow(0)}><MenuOpenIcon style={{ width: 20, color: "#4a4ae9" }} /></IconButton>

                <div className={ (show==1) ? classes.displayShow : classes.displayHide }>
                    <h4 className={ classes.title }>
                        <IconButton onClick={() => setShow(0)}><ChevronLeftIcon fontSize="large" style={{ color: "#4a4ae9" }} /></IconButton>
                        Solicitar novo atendimento
                    </h4>

                    <div style={{ padding: "0 20px 20px", height: "calc(100vh - 350px)", overflow: "auto" }}>
                        <PreAtendimento sideBar={ true } />
                    </div>

                    <div className={ (show==1) ? classes.footer : classes.displayHide }>
                        { loading
                            ?
                                (<>
                                    <CircularProgress size={15} color="inherit" /> Salvando...
                                </>)
                            :
                                (<DButton 
                                    variant="primary" 
                                    icon={ <AddCircleIcon fontSize="small" /> } 
                                    size="sm" 
                                    onClick={ e => { 
                                        preForm.current.dispatchEvent(
                                            new Event("submit", { cancelable: true, bubbles: true })
                                        )
                                    }}
                                >
                                    Solicitar Atendimento
                                </DButton>)
                        }
                    </div>
                </div>

                <div className={ (show==2) ? classes.displayShow : classes.displayHide }>
                    <h4 className={ classes.title }>
                        <IconButton onClick={() => setShow(0)}><ChevronLeftIcon fontSize="large" style={{ color: "#4a4ae9" }} /></IconButton>
                        Solicitar Interconsulta
                    </h4>
                    <div style={{ padding: "0 20px 20px", height: "calc(100vh - 350px)", overflow: "auto" }}>
                        <Autocomplete
                            options={ specialties.filter( item => item.active ) }
                            getOptionLabel={ option => option.description }
                            getOptionSelected={(option, value) => value && option.id === value.id}
                            renderInput={ (params) => (
                                <TextField {...params} margin="normal" variant="outlined" placeholder="Escolha aqui a especialidade" />
                            )}
                            popupIcon={ <SearchIcon style={{ color: "#4a4ae9" }} /> }
                            classes={{ 
                                inputRoot: classes.autocomplete_input
                            }}
                            onChange={ (event, newValue) => {
                                listaMedicos(newValue);
                                setSpecialty(newValue);
                            }}
                        />

                        <h4 className={ classes.title } style={{ marginTop:8 }}>Aqui estão os médicos disponíveis</h4>
                        <p style={{ fontSize:12, color:"#4f4f4f", marginTop:4 }}>Selecione o médico que deseja:</p>

                        <Button style={{ color:"#4a4ae9", float:"right", marginBottom:16 }} size="small" startIcon={<CompareArrowsIcon />} >
                            Qualquer médico
                        </Button>

                        <Card style={{ clear:"both" }}>
                            <CardContent style={{ padding: 0 }}>
                                <ul style={{ margin: 0, padding: 0, listStyle:"none" }}>
                                    { (doctorsList && doctorsList.length)
                                        ? 
                                            doctorsList.map( doctor => {
                                                return(
                                                    <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px 8px 4px" }}>
                                                        <div style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                                                            <Radio
                                                                checked={ selectedDoctor == doctor.id }
                                                                onChange={ e => setSelectedDoctor(doctor.id) }
                                                                value={ doctor.id }
                                                                name="medico"
                                                                style={{ color: "#4a4ae9" }}
                                                            />
                                                            <Typography style={{ fontSize: 12 }}>
                                                                { doctor.full_name }<br/>
                                                                <strong>Unidade:</strong> { doctor.unidade }
                                                            </Typography>
                                                        </div> 
                                                        <div>
                                                            <Typography style={{ fontSize: 12 }}>
                                                                <strong>CNES:</strong> { mascaraCNES(doctor.cnes) }
                                                            </Typography>
                                                        </div> 
                                                    </li>
                                                )
                                            })
                                        :
                                            <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8px 16px 8px 15px" }}>
                                                Não existem médicos disponíveis
                                            </li>
                                    }
                                </ul>
                            </CardContent>
                        </Card>
                    </div>
                </div>
                <div className={ (show==2) ? classes.footer : classes.displayHide }>
                    <DButton variant="primary" icon={ <PersonAddIcon fontSize="small" /> } size="sm">
                        Convidar médico
                    </DButton>
                </div>


                <div className={ (show==3) ? classes.displayShow : classes.displayHide }>
                    <h4 className={ classes.title }>
                        <IconButton onClick={() => setShow(0)}><ChevronLeftIcon fontSize="large" style={{ color: "#4a4ae9" }} /></IconButton>
                        Criar PDF
                    </h4>

                    <div style={{ padding: "0 20px", height: "calc(100vh - 350px)", overflow: "auto" }}>
                        <Card style={{ borderRadius:8 }}>
                            <CardContent style={{ padding: 0 }}>
                                <Typography className={classes.title_visual}>
                                    Pré-visualização
                                </Typography>

                                <div style={{ background: "#949494", padding:"16px 32px", height:"calc(100vh - 445px)", overflow:"auto"}}>
                                    <div style={{ width:"100%", height: '100%', background:"#fff"}}>
                                        { props.idAtendimento
                                            ?
                                                <VisualizarExame getIframe idAtendimento={ props.idAtendimento } downloadFile={ downloadFile } fileSize={ e => setFileSize(e) } />
                                            :
                                                ''
                                        }
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                            
                        <Typography className={classes.size_visual}>
                            Tamanho: { fileSize }
                        </Typography>
                    </div>
                </div>
                <div className={ (show==3) ? classes.footer : classes.displayHide }>
                    <DButton variant="primary" icon={ <GetAppIcon fontSize="small" /> } size="sm" onClick={ e => { setDownloadFile(true) } }>
                        Baixar arquivo
                    </DButton>
                </div>
            </div>
        </>
    );
}

export default Sidebar;