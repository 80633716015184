import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import { Container } from "@material-ui/core";

const useStyles = makeStyles({

    menuFilter: {
        margin: "10px",
    },

    paddingPop: {
        padding: "25px"
    },

    formControl: {
        margin: '5px'
    },
});

export default function HistoricoPopover(props) {

    const classes = useStyles();

    return (
        <>
            <Popover
                id={props.id}
                open={Boolean(props.anchor)}
                anchorEl={props.anchor}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Container className={classes.paddingPop}>
                    { props.children }
                </Container>
            </Popover>
        </>
    )
}
