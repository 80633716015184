import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import "./index.scss";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VideocamIcon from '@mui/icons-material/Videocam';
import ChatIcon from '@mui/icons-material/Chat';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@material-ui/core/IconButton";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PushPinIcon from '@mui/icons-material/PushPin';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import styled from "styled-components";

// Pusher
import Pusher from 'pusher-js';
import env from "react-dotenv";

// Toast
import { toast } from "react-toastify";

// Routes
import { getUnidade, getMedicalUnitPermission } from '../../../api/unidades';
import { getMedicalSpecialtyById, getMedicalSpecialtyPermission  } from '../../../api/medicalSpecialty';
import { getProfilePermission } from '../../../api/profiles';
import { 
  getUserInfoById, 
  listMedicalUnitOnlineUsers, 
  listUserByMedicalUnitNotPaginated, 
  listChatUserByMedicalSpeciality 
} from '../../../api/user';
import { 
  auth,
  getChat,
  getChatListByUserId,
  createChat,
  updateSalaChat,
  addParticipantChat
} from '../../../api/chatService';
import configuration from "../../../api/configuration";

// Context
import { AppContext } from "../../../context/AppContext";

export const UserIcon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  color: ${(props) => props.themeColor};
`;

export default function ModalChat(props) {
  // States
  const [open, setOpen]                                 = useState(false);
  const [openstatus, setOpenstatus]                     = useState(false);
  const [opensearch, setOpensearch]                     = useState(false);
  const [opengroup, setOpengroup]                       = useState(false);
  const [chatStarted, setChatStarted]                   = useState(false);
  const [callStarted, setCallStarted]                   = useState(false);
  const [nome, setNome]                                 = useState('');
  const [cpf, setCpf]                                   = useState('');
  const [telefone, setTelefone]                         = useState('');
  const [tokenJWT, setTokenJWT]                         = useState(null);
  const [srcChat, setSrcChat]                           = useState('');
  const [specialties, setSpecialties]                   = useState([]);
  const [chats, setChats]                               = useState(null);
  const [unitPermissions, setUnitPermissions]           = useState(null);
  const [profilePermissions, setProfilePermissions]     = useState(null);
  const [specialtyPermissions, setSpecialtyPermissions] = useState(null);
  const [configurations, setConfigurations]             = useState(null);
  const [groupParticipants, setGroupParticipants]       = useState([]);

  // Archor
  const [anchorEl, setAnchorEl] = useState(null);
  const openbutton = Boolean(anchorEl);

  // Context
  const { avatar, userInfo } = useContext(AppContext);

  useEffect(() => {
    async function asyncSetUnit() {
      if (userInfo.unidade != '') {
        await listMedicalUnitOnlineUsers(localStorage.getItem('token'), userInfo.unidade).then(res => {
          if (res.status) {
            localStorage.setItem("online_users", JSON.stringify(res.users));
          }
        });

        if (chats && chats.length) {
          if ("UNIT_" + userInfo.unidade == chats[0].id) {
            return;
          }
        }
        
        await getUnidade(localStorage.getItem('token'), userInfo.unidade).then(res => {
          if (res.status) {
            setChats(prevState => {
              let _tempState = prevState ? prevState : [];

              if (_tempState.length) {
                _tempState.splice(0, 1);
                _tempState.unshift({
                  id: "UNIT_" + res.details.medicalUnit.id,
                  nome: res.details.medicalUnit.company_name,
                  avatar: getInitialsName(res.details.medicalUnit.company_name, false),
                  online: null,
                  ultimaMensagem: '',
                  horaUltimaMensagem: '',
                  naoLidas: 0,
                  active: false,
                  type: 'unidade'
                });
              } else {
                _tempState.push({
                  id: "UNIT_" + res.details.medicalUnit.id,
                  nome: res.details.medicalUnit.company_name,
                  avatar: getInitialsName(res.details.medicalUnit.company_name, false),
                  online: null,
                  ultimaMensagem: '',
                  horaUltimaMensagem: '',
                  naoLidas: 0,
                  active: false,
                  type: 'unidade'
                });
              }

              return _tempState;
            });
          }
        });

        await getChatListByUserId(localStorage.getItem('token'), localStorage.getItem('uid')).then(async resChatList => {
          if (resChatList.status) {
            if (resChatList.conversationTeam.length) {
              resChatList.conversationTeam.map(async item => {
                if (item.identify_room.split("_")[0] == "USER") {
                  var userExternal = item.conversation_team_participant.find( res => res.user_id != localStorage.getItem("uid"));

                  await getUserInfoById(localStorage.getItem('token'), userExternal.user_id).then( resUserInfo => {
                    if (resUserInfo.status) {
                      setChats(prevState => {
                        let _tempState  = prevState ? prevState : [];
                        var onlineUsers = JSON.parse(localStorage.getItem("online_users"));

                        _tempState.push({
                          id: item.identify_room,
                          nome: resUserInfo.user.name,
                          avatar: getInitialsName(resUserInfo.user.name, true),
                          online: onlineUsers.find(res => res.id == userExternal.user_id) ? true : false,
                          ultimaMensagem: '',
                          horaUltimaMensagem: '',
                          naoLidas: 0,
                          active: false,
                          type: 'usuario'
                        });

                        return _tempState;
                      });
                    }
                  });
                }
              });
            }
          }
        });

        getConfigurations();
        getUnitPermissions();

        if (chatStarted) {
          openChat(chats[0], 0, tokenJWT);
        }
      }
    }

    asyncSetUnit();
  }, [userInfo.unidade]);

  
  useEffect(() => {
    if (userInfo.perfil != '') {
      getProfilePermissions();
    }
  }, [userInfo.perfil]);
  

  useEffect(async () => {
    if (props.open && !chatStarted) {
      await getUserInfoById(localStorage.getItem("token"), localStorage.getItem("uid")).then(async (res) => {
        if (res.error) {
          toast.error("Ocorreu um erro ao abrir o chat");
          console.log(res.error);
        } else {
          var json = {
            app: "telemed-sp-dev",
            chave: "ec3a2fa3-9c60-4048-bd25-50ad8bd674a0",
            usuario: res.document.find(res => res.type == 'CPF').fields[0].value,
            nome: res.user.name,
            celular: "+55" + res.contact.find(res => res.type == 'telefone').value.replace(/[^0-9]/g, ""),
            perfil: "usuario"
          }

          if (json.usuario && json.celular && json.nome) {
            await auth(json).then((resAuth) => {
              if (resAuth.status_ret != 'NOK') {
                setNome(resAuth.nome);
                setCpf(resAuth.usuario);
                setTelefone(resAuth.celular);
                setTokenJWT(resAuth.tokenJwt);

                handleOpen();
                loadSpecialty(res.speciality);

                setChatStarted(true);
              } else {
                toast.error("Ocorreu um erro ao abrir o chat");
                console.log(resAuth.erro);
              }

              openChat(chats[0], 0, resAuth.tokenJwt);
            }).catch((err) => {
              toast.error("Ocorreu um erro ao abrir o chat");
              console.log(err);
            });
          }
        }
      }).catch((err) => {
        toast.error("Ocorreu um erro ao abrir o chat");
        console.log(err);
      });
    } else if (props.open && chatStarted) {
      getConfigurations();
      getUnitPermissions();
      getProfilePermissions();
      handleOpen();
    }
  }, [props.open]);

  useEffect(() => {
    try {

      console.log('subs....')

      const pusher = new Pusher(env.KEY_PUSHER, {
        cluster: env.PUSHER_APP_CLUSTER
      });
  
      const channelUsersOnline = pusher.subscribe('listUsers');
      const channelChat = pusher.subscribe('ambienteD');
  
      channelUsersOnline.bind('addUser', function (data) {
        if (localStorage.getItem("online_users")) {
          var online_users = JSON.parse(localStorage.getItem("online_users"));
  
          if (!online_users.find(res => res.id == data.id)) {
            online_users.push(data);
  
            localStorage.setItem("online_users", JSON.stringify(online_users));
          }
        } else {
          localStorage.setItem("online_users", JSON.stringify([data]));
        }
  
        setChats(prevState => {
          var conversas = prevState;
  
          if (conversas && conversas.length) {
            conversas.map(async (item, index) => {
              if (item.type == "usuario") {
                var ids = item.id.split("_");
    
                if (ids[1] == data.id || ids[2] == data.id) {
                  conversas[index].online = true;
                }
              }
            });
          }
  
          return conversas;
        });
      });
  
      channelUsersOnline.bind('removeUser', function (data) {
        if (localStorage.getItem("online_users")) {
          var online_users = JSON.parse(localStorage.getItem("online_users"));
          var index = online_users.findIndex(res => res.id == data.id);
          online_users.splice(index, 1);
  
          localStorage.setItem("online_users", JSON.stringify(online_users));
        }
  
        setChats(prevState => {
          var conversas = prevState;
  
          if (conversas && conversas.length) {
            conversas.map(async (item, index) => {
              if (item.type == "usuario") {
                var ids = item.id.split("_");
    
                if (ids[1] == data.id || ids[2] == data.id) {
                  conversas[index].online = false;
                }
              }
            });
          }
  
          return conversas;
        });
      });
  
      channelChat.bind('videochamadaD', function (data) {
        //Evento de entrada e saída de participantes na videochamada
      });
  
      channelChat.bind('chat_updatestatusD', function (data) {
        var stateCallStarted = false;
  
        setCallStarted(prevState => {
          stateCallStarted = prevState;
  
          return prevState;
        });
  
        var obj = JSON.parse(data.message);
  
        if (!stateCallStarted && obj.Status != 0) {
          if (obj.chaveConversa.split('_')[0] == 'UNIT' || obj.chaveConversa.split('_')[0] == 'SPEC') {
            var stateChats = [];
  
            setChats(prevState => {
              stateChats = prevState ? prevState : [];
  
              return prevState;
            });
  
            if (stateChats.find(res => res.id == obj.chaveConversa)) {
              props.incomingCall(true, obj, stateChats.find(res => res.id == obj.chaveConversa));
              handleClose();
            }
          } else if (obj.chaveConversa.split('_')[0] == 'USER') {
            if (obj.chaveConversa.split('_')[1] == localStorage.getItem("uid") || obj.chaveConversa.split('_')[2] == localStorage.getItem("uid")) {
              var dadosChat = {};
  
              getChatListByUserId(localStorage.getItem('token'), localStorage.getItem('uid')).then(async resChatList => {
                console.log(resChatList);

                if (resChatList.status) {
                  if (resChatList.conversationTeam.length) {
                    resChatList.conversationTeam.map(async item => {
                      if (item.identify_room == obj.chaveConversa) {
                        var userExternal = item.conversation_team_participant.find( res => res.user_id != localStorage.getItem("uid"));
      
                        await getUserInfoById(localStorage.getItem('token'), userExternal.user_id).then( resUserInfo => {
                          if (resUserInfo.status) {
                            dadosChat = {
                              id: item.identify_room,
                              nome: resUserInfo.user.name,
                              type: 'usuario'
                            };
  
                            props.incomingCall(true, obj, dadosChat);
                          }
                        });
                      }
                    });
                  }
                }
              });
  
              handleClose();
            }
          }
        }
      });
  
      channelChat.bind('chat_mensagemNovaD', function (data) {
        console.log('chat_mensagemNovaD', data);
      });

      return (() => {
        console.log('unsubscribe');
        pusher.unsubscribe('listUsers');
        pusher.unsubscribe('ambienteD');
        pusher.disconnect();
      })

    } catch(err) {
      console.log("Não foi possível iniciar o chat:", err);
    }
  }, []);

  useEffect(() => {
    setCallStarted(props.callStarted);
  }, [props.callStarted]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickStatus = () => {
    // setOpenstatus((prev) => !prev);
  };

  const handleClickAwayStatus = () => {
    setOpenstatus(false);
  };

  const handleClickSearch = () => {
    setOpensearch((prev) => !prev);
  };

  const handleClickAwaySearch = () => {
    setOpensearch(false);
  };

  const handleClickGroup = async () => {
    setOpengroup((prev) => !prev);
  };

  const handleClickAwayGroup = () => {
    setOpengroup(false);
  };

  const handleClickButton = (event) => {
    if (chats && chats.find( item => item.active == true)) {
      var chat = chats.find(item => item.active == true);

      if (chat.type != 'usuario') {
        setAnchorEl(event.currentTarget);
      } else {
        openCall(1);
      }
    }
  };

  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  const getInitialsName = (value, pessoa) => {
    if (!value) return;
    value = value.split(" ");

    if (value.length > 0) {
      return value.length > 1 ? (value[0].charAt(0).toUpperCase() + (pessoa ? value[value.length - 1].charAt(0).toUpperCase() : value[1].charAt(0).toUpperCase())) : value[0].charAt(0).toUpperCase();
    } else {
      return value;
    }
  };

  const getInitialName = (value) => {
    if (!value) return;
    value = value.split(" ");

    if (value.length > 0) {
      return value[0].charAt(0).toUpperCase();
    } else {
      return value;
    }
  };

  async function openIndividualCall(participantId, participantName) {
    setCallStarted(true);
    
    var payload = {
      operacao: "updatestatus",
      chaveConversa: chats.find( item => item.id == 'USER_' + participantId + "_" + localStorage.getItem("uid"))
                      ?
                        'USER_' + participantId + "_" + localStorage.getItem("uid")
                      :
                        'USER_' + localStorage.getItem("uid") + "_" + participantId,
      Status: 1,
      tipoChamada: 1
    }

    console.log('payload', payload);

    await updateSalaChat(tokenJWT, payload).then(async (resUpdate) => {
      props.openCall(true, payload, {type: 'usuario', nome: participantName});
      handleClose();
    }).catch((err) => {
      setCallStarted(false);
      toast.error("Ocorreu um erro ao abrir a chamada");
      console.log(err);
    });
  }

  async function openChat(chat, index, tokenJwtIframe) {
    if (chat.type == "especialidade") {
      getSpecialtyPermissions(chat.id.split('_')[2])
    }

    await getChat(localStorage.getItem("token"), chat.id).then( async (resGet) => {
      if (resGet.status) {
        if (resGet.conversationTeam.length > 0) {
          if (!resGet.conversationTeam[0].conversation_team_participant.find( res => res.user_id == localStorage.getItem("uid") )) {
            var payloadAdd = {
              "conversation_team_id": resGet.conversationTeam[0].id,
              "user_id": localStorage.getItem("uid"),
            }

            await addParticipantChat(localStorage.getItem("token"), payloadAdd).then(async (resAdd) => {
              if (resAdd.status) {
                var state = chats;

                if (chats.findIndex(res => res.active == true) != -1) {
                  var indexActive = chats.findIndex(res => res.active == true);
                  state[indexActive].active = false;
                }

                state[index].active = true;
                
                setChats(state);
                await carregaParticipantes(state[index]);
                setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJwtIframe}&protocolo=${chat.id}&tipoParticipante=1&isVideo=S&comHeader=N`);
              } else {
                toast.error("Ocorreu um erro ao abrir o chat");
                console.log(resAdd);
              }
            }).catch((err) => {
              toast.error("Ocorreu um erro ao abrir o chat");
              console.log(err);
            });
          } else {
            var state = chats

            if (chats.findIndex(res => res.active == true) != -1) {
              var indexActive = chats.findIndex(res => res.active == true);
              state[indexActive].active = false;
            }

            state[index].active = true;
            
            setChats(state);
            await carregaParticipantes(state[index]);
            setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJwtIframe}&protocolo=${chat.id}&tipoParticipante=1&isVideo=S&comHeader=N`);
          }
        } else {
          var payloadCreate = {
            "user_id": null,
            "medical_speaciality_id": null,
            "medical_unit_id": null,
            "identify_room": chat.id,
            "notification": false
          }

          await createChat(localStorage.getItem("token"), payloadCreate).then(async (resCreate) => {
            if (resCreate.status) {
              var payloadAdd = {
                "conversation_team_id": resCreate.conversationTeam.id,
                "user_id": localStorage.getItem("uid"),
              }

              await addParticipantChat(localStorage.getItem("token"), payloadAdd).then(async (resAdd) => {
                if (resAdd.status) {
                  var state = chats;

                  if (chats.findIndex(res => res.active == true) != -1) {
                    var indexActive = chats.findIndex(res => res.active == true);
                    state[indexActive].active = false;
                  }

                  state[index].active = true;
                  
                  setChats(state);
                  await carregaParticipantes(state[index]);
                  setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJwtIframe}&protocolo=${chat.id}&tipoParticipante=1&isVideo=S&comHeader=N`);
                } else {
                  toast.error("Ocorreu um erro ao abrir o chat");
                  console.log(resAdd);
                }
              }).catch((err) => {
                toast.error("Ocorreu um erro ao abrir o chat");
                console.log(err);
              });
            } else {
              toast.error("Ocorreu um erro ao abrir o chat");
              console.log(resCreate);
            }
          }).catch((err) => {
            toast.error("Ocorreu um erro ao abrir o chat");
            console.log(err);
          });
        }
      } else {
        toast.error("Ocorreu um erro ao abrir o chat");
        console.log(resGet);
      }
    }).catch((err) => {
      toast.error("Ocorreu um erro ao abrir o chat");
      console.log(err);
    });
  }

  async function getConfigurations() {
    await configuration.get(localStorage.getItem('token')).then(res => {
      if (res.status) {
        setConfigurations(res.configuration);
      }
    });
  }

  async function getUnitPermissions() {
    await getMedicalUnitPermission(localStorage.getItem("token"), userInfo.unidade).then( async (res) => {
      if (res.status) {
        setUnitPermissions(res.details);
      }
    });
  }

  async function getProfilePermissions() {
    await getProfilePermission(localStorage.getItem("token"), userInfo.perfil).then( async (res) => {
      if (res.status) {
        setProfilePermissions(res.details);
      }
    });
  }

  async function getSpecialtyPermissions(id) {
    await getMedicalSpecialtyPermission(localStorage.getItem("token"), id).then( async (res) => {
      if (res.status) {
        setSpecialtyPermissions(res.details);
      }
    });
  }

  async function carregaParticipantes(chat) {
    if (chat.type == 'unidade') {
      await listUserByMedicalUnitNotPaginated(localStorage.getItem('token'), userInfo.unidade).then(res => {
        console.log(res);
        if (res.status) {
          setGroupParticipants(res.users);
        }
      });
    } else {
      var arrayChat = chat.id.split('_');

      await listChatUserByMedicalSpeciality(localStorage.getItem('token'), userInfo.unidade, arrayChat[2]).then(res => {
        if (res.status) {
          var arrayGroup = []

          res.medicalSpeciality.map(item => {
            arrayGroup.push({
              id: item.user_id,
              name: item.full_name 
            });
          });

          setGroupParticipants(arrayGroup);
        }
      }).catch(err => console.log(err));
    }
  }

  async function openCall(tipoChamada) {
    setCallStarted(true);
    
    var payload = {
      operacao: "updatestatus",
      chaveConversa: chats.find( item => item.active == true).id,
      Status: 1,
      tipoChamada: tipoChamada
    }

    await updateSalaChat(tokenJWT, payload).then(async (resUpdate) => {
      props.openCall(true, payload, chats.find(res => res.active == true));
      handleClose();
    }).catch((err) => {
      setCallStarted(false);
      toast.error("Ocorreu um erro ao abrir a chamada");
      console.log(err);
    });
  }

  function loadSpecialty(specialtiesArray) {
    if (specialtiesArray && specialtiesArray.length > 0) {
      specialtiesArray.map(async function (item) {
        await getMedicalSpecialtyById(localStorage.getItem('token'), item.medical_speciality_id).then(res => {
          if (res.active && res.active == 1) {
            var state = specialties;

            setSpecialties(state);  
            setChats(prevState => {
              let _tempState = prevState ? [...prevState] : [];

              _tempState.push({
                id: "SPEC_" + userInfo.unidade + '_' + res.id,
                nome: res.description,
                avatar: getInitialsName(res.description, false),
                online: null,
                ultimaMensagem: '',
                horaUltimaMensagem: '',
                naoLidas: 0,
                active: false,
                type: 'especialidade'
              })

              return _tempState;
            })
          }
        }).catch(err => console.log(err));
      });
    }
  }

  async function createPrivateChat(participant, type) {
    await getChatListByUserId(localStorage.getItem("token"), localStorage.getItem("uid")).then(async (resGet) => {
      if (!resGet.conversationTeam.find( res => res.identify_room == "USER_" + participant.id + "_" + localStorage.getItem("uid") || res.identify_room == "USER_" + localStorage.getItem("uid") + "_" + participant.id)) {
        var payload = {
          "user_id": null,
          "medical_speaciality_id": null,
          "medical_unit_id": null,
          "identify_room": "USER_" + localStorage.getItem("uid") + "_" + participant.id,
          "notification": false
        }
    
        await createChat(localStorage.getItem("token"), payload).then(async (resCreate) => {
          if (resCreate.status) {
            var payloadAdd = {
              "conversation_team_id": resCreate.conversationTeam.id,
              "user_id": localStorage.getItem("uid"),
            }
    
            await addParticipantChat(localStorage.getItem("token"), payloadAdd).then(async (resAdd) => {
              if (resAdd.status) {
                var payloadAddExternal = {
                  "conversation_team_id": resCreate.conversationTeam.id,
                  "user_id": participant.id,
                }

                await addParticipantChat(localStorage.getItem("token"), payloadAddExternal).then(async (resAddExternal) => {
                  if (resAddExternal.status) {
                    setChats(prevState => {
                      let _tempState  = prevState ? [...prevState] : [];
                      var onlineUsers = JSON.parse(localStorage.getItem("online_users"));
        
                      if (chats.findIndex(res => res.active == true) != -1) {
                        var indexActive = chats.findIndex(res => res.active == true);
                        _tempState[indexActive].active = false;
                      }
    
                      _tempState.push({
                        id: "USER_" + localStorage.getItem("uid") + "_" + participant.id,
                        nome: participant.name,
                        avatar: getInitialsName(participant.name, true),
                        online: onlineUsers.find(res => res.id == participant.id),
                        ultimaMensagem: '',
                        horaUltimaMensagem: '',
                        naoLidas: 0,
                        active: true,
                        type: 'usuario'
                      })
        
                      return _tempState;
                    });
    
                    if(type == 'chat') {
                      setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJWT}&protocolo=${resCreate.conversationTeam.id}&tipoParticipante=1&isVideo=S&comHeader=N`);
                    } else {
                      openIndividualCall(participant.id, participant.name);
                    }
                  } else {
                    toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
                    console.log(resAdd);
                  }
                }).catch((err) => {
                  toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
                  console.log(err);
                });
              } else {
                toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
                console.log(resAdd);
              }
            }).catch((err) => {
              toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
              console.log(err);
            });
          } else {
            toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
            console.log(resCreate);
          }
        }).catch((err) => {
          toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
          console.log(err);
        });
      } else if (resGet.conversationTeam.find( res => res.identify_room == "USER_" + participant.id + "_" + localStorage.getItem("uid"))) {
        var state = chats;
        var indexState = resGet.conversationTeam.findIndex( res => res.identify_room == "USER_" + participant.id + "_" + localStorage.getItem("uid"))

        if (chats.findIndex(res => res.active == true) != -1) {
          var indexActive = chats.findIndex(res => res.active == true);
          state[indexActive].active = false;
        }

        state[indexState].active = true;
        setChats(state);

        if(type == 'chat') {
          setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJWT}&protocolo=${state[indexState].id}&tipoParticipante=1&isVideo=S&comHeader=N`);
        } else {
          openIndividualCall(participant.id, participant.name);
        }
      } else if (resGet.conversationTeam.find( res => res.identify_room == "USER_" + localStorage.getItem("uid") + "_" + participant.id)) {
        var state = chats;
        var indexState = resGet.conversationTeam.findIndex( res => res.identify_room == "USER_" + localStorage.getItem("uid") + "_" + participant.id)

        if (chats.findIndex(res => res.active == true) != -1) {
          var indexActive = chats.findIndex(res => res.active == true);
          state[indexActive].active = false;
        }

        state[indexState].active = true;
        setChats(state);

        if(type == 'chat') {
          setSrcChat(`https://appwebchatduodoctorspdev.z15.web.core.windows.net/index.html?token=${tokenJWT}&protocolo=${state[indexState].id}&tipoParticipante=1&isVideo=S&comHeader=N`);
        } else {
          openIndividualCall(participant.id, participant.name);
        }
      }
    }).catch((err) => {
      toast.error("Ocorreu um erro ao abrir " + type == 'chat' ? 'o chat' : 'a chamada');
      console.log(err);
    });
  }

  function isAllowedCall() {
    var chat = chats && chats.find(res => res.active == true);

    if (configurations && configurations.video_conference == "1") {
      if (profilePermissions && profilePermissions.callEnabled && (profilePermissions.SequentialCall || profilePermissions.CallInGeneralGroup || profilePermissions.CallInTotalGroup)) {
        if (chat && chat.type == 'unidade') {
          if (unitPermissions && unitPermissions.callEnabled && (unitPermissions.SequentialCall || unitPermissions.CallInGeneralGroup || unitPermissions.CallInTotalGroup)) {
            return true;
          } else {
            return false;
          }
        } else if (chat && chat.type == 'especialidade') {
          if (specialtyPermissions && specialtyPermissions.callEnabled && (specialtyPermissions.SequentialCall || specialtyPermissions.CallInGeneralGroup || specialtyPermissions.CallInTotalGroup)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function verifyPermission(typeCall) {
    var chat = chats && chats.find(res => res.active == true);

    if (typeCall == 'sequencial') {
      if (!profilePermissions || !profilePermissions.SequentialCall) {
        return false;
      }
    } else if (typeCall == 'geral') {
      if (!profilePermissions || !profilePermissions.CallInGeneralGroup) {
        return false;
      }
    } else if (typeCall == 'total') {
      if (!profilePermissions || !profilePermissions.CallInTotalGroup) {
        return false;
      }
    } else {
      return false;
    }

    if (chat && chat.type == 'unidade') {
      if (typeCall == 'sequencial') {
        if (unitPermissions && unitPermissions.SequentialCall) {
          return true;
        } else {
          return false;
        }
      } else if (typeCall == 'geral') {
        if (unitPermissions && unitPermissions.CallInGeneralGroup) {
          return true;
        } else {
          return false;
        }
      } else if (typeCall == 'total') {
        if (unitPermissions && unitPermissions.CallInTotalGroup) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (chat && chat.type == 'especialidade') {
      if (typeCall == 'sequencial') {
        if (specialtyPermissions && specialtyPermissions.SequentialCall) {
          return true;
        } else {
          return false;
        }
      } else if (typeCall == 'geral') {
        if (specialtyPermissions && specialtyPermissions.CallInGeneralGroup) {
          return true;
        } else {
          return false;
        }
      } else if (typeCall == 'total') {
        if (specialtyPermissions && specialtyPermissions.CallInTotalGroup) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function verifyIndividualCall(chatType) {
    var chat = chats && chats.find(res => res.active == true);

    if (configurations && configurations.video_conference == "1") {
      if (chatType == 'group') {
        if (profilePermissions && profilePermissions.callEnabled && profilePermissions.DirectCall) {
          if (chat && chat.type == 'unidade') {
            if (unitPermissions && unitPermissions.callEnabled && unitPermissions.DirectCall) {
              return true;
            } else {
              return false;
            }
          } else if (chat && chat.type == 'especialidade') {
            if (specialtyPermissions && unitPermissions.callEnabled && specialtyPermissions.DirectCall) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (chatType == 'individual') {
        if (chat && chat.type == 'usuario') {
          if (profilePermissions && profilePermissions.callEnabled && profilePermissions.DirectCall) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={e => { handleClose(); props.setCloseModal(true) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx className='estilo_modal_chat'>
          <div className='flex_modal_topo'>
            <div className='tamanho_lado_esquerdo_topo'>
              <div className='div_imagem_chat'>
                { avatar
                  ?
                    (
                      <UserIcon
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                        src={avatar}
                      />
                    )
                  :
                    (
                      <IconButton
                        aria-label="account of current user"
                        aria-haspopup="true"
                        color="inherit"
                      >
                        {getInitialsName(nome, true)}
                      </IconButton>
                    )
                }
                <div className='div_status_ativo_imagem_chat'>
                </div>
              </div>
              <div className='flex_column_pessoa_chat'>
                <div className='fonte_nome_pessoa_titulo_chat'>
                  {nome}
                </div>
                <div>
                  <ClickAwayListener onClickAway={handleClickAwayStatus}>
                    <Box className='main_box_dropdown_status'>
                      <button className='botao_status' onClick={handleClickStatus}>
                        Disponível
                        {/* <KeyboardArrowDownIcon className='icone_seta_status' /> */}
                      </button>
                      {openstatus ? (
                        <Box className='box_dropdown_status'>
                          <div className='main_flex_status_box'>
                            <div className='circulo_status_disponivel'></div>
                            <div className='fonte_status_box'>
                              Disponível
                            </div>
                          </div>
                          <div className='main_flex_status_box mt10'>
                            <div className='circulo_status_opcupado'></div>
                            <div className='fonte_status_box'>
                              Ocupado
                            </div>
                          </div>
                          <div className='main_flex_status_box mt10'>
                            <div className='circulo_status_ausente'></div>
                            <div className='fonte_status_box'>
                              Ausente
                            </div>
                          </div>
                          <div className='main_flex_status_box mt10'>
                            <div className='circulo_status_invisivel'></div>
                            <div className='fonte_status_box'>
                              Invísivel
                            </div>
                          </div>
                        </Box>
                      ) : null}
                    </Box>
                  </ClickAwayListener>
                </div>
              </div>
              <ClickAwayListener onClickAway={handleClickAwaySearch}>
                <Box className='main_div_search'>
                  {/* <button className='botao_status' onClick={handleClickSearch}>
                    <SearchIcon className='icone_seta_status' />
                  </button> */}
                  {opensearch ? (
                    <Box className='box_dropdown_status_search'>
                      <TextField className='estilo_input_search' size='small' id="outlined-basic" label="Pesquisar" variant="outlined" />
                    </Box>
                  ) : null}
                </Box>
              </ClickAwayListener>
            </div>
            <div className='flex_lateral_direita'>
              { chats
                ?
                  <div className='nome_grupo_pessoa_titulo'>
                    { chats.length && chats.find( item => item.active == true) ? (chats.find( item => item.active == true).type != 'usuario' ? 'Grupo: ' : '') : '' }
                    { chats && chats.find( item => item.active == true) ? chats.find( item => item.active == true).nome : '' }
                  </div>
                :
                  ''
              }
              { (chats && chats.find( item => item.active == true)) && isAllowedCall() || (chats && chats.find( item => item.active == true)) && verifyIndividualCall("individual")
                ?
                  <Button className='botao_chamar'
                    id="basic-button"
                    aria-controls={openbutton ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openbutton ? 'true' : undefined}
                    onClick={handleClickButton}
                  >
                    <VideocamIcon className='icone_camera' />
                    Chamar
                  </Button>
                :
                  ''
              }
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openbutton}
                onClose={handleCloseButton}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                { verifyPermission('sequencial')
                  ?
                    <MenuItem
                      onClick={() => {
                        openCall(1);
                        handleCloseButton();
                      }}
                    >
                      Chamada sequencial
                    </MenuItem>
                  :
                    ''
                }
                { verifyPermission('geral')
                  ?
                    <MenuItem
                      onClick={() => {
                        openCall(2);
                        handleCloseButton();
                      }}
                    >
                      Chamada geral
                    </MenuItem>
                  :
                    ''
                }
                { verifyPermission('total')
                  ?
                    <MenuItem
                      onClick={() => {
                        openCall(3);
                        handleCloseButton();
                      }}
                    >
                      Chamada total
                    </MenuItem>
                  :
                    ''
                }
              </Menu>
              <div>
                <ClickAwayListener onClickAway={handleClickAwayGroup}>
                  <Box className='main_div_search_group'>
                    { (chats && chats.find( item => item.active == true)) && (chats.find( item => item.active == true).type != 'usuario')
                      ?
                        <button className='botao_grupo' onClick={handleClickGroup}>
                          { groupParticipants.length } membros
                          <KeyboardArrowDownIcon />
                        </button>
                      :
                        ''
                    }
                    { opengroup && (groupParticipants && groupParticipants.length) 
                      ?
                        <>
                          <Box className='box_dropdown_grupos'>
                            { groupParticipants.map( (item, index) => (
                              <div className='linha_box_dropdown_group'>
                                <div className='div_imagem_dropdown_group'>
                                  { getInitialName(item.name) }
                                </div>
                                <div className='fonte_box_dropdown_group'>
                                  { item.name }
                                </div>
                                <div className='flex_end_dropdown_group'>
                                  { item.id != localStorage.getItem('uid')
                                    ?
                                      <div className='fundo_chat_icon_group'
                                        onClick={() => {
                                          createPrivateChat(item, 'chat');
                                          setOpengroup(false);
                                        }}
                                      >
                                        <ChatIcon className='icone_chat_group' />
                                      </div>
                                    :
                                      ''
                                  }
                                  { verifyIndividualCall('group') && (item.id != localStorage.getItem('uid'))
                                    ?
                                      <div className='fundo_call_icon_group'
                                        onClick={() => {
                                          createPrivateChat(item, 'call');
                                          setOpengroup(false);
                                        }}
                                      >
                                        <PhoneIcon className='icone_chat_group' />
                                      </div>
                                    :
                                      ''
                                  }
                                </div>
                              </div> 
                            ))}
                          </Box>
                        </>
                      :
                        null
                    }
                  </Box>
                </ClickAwayListener>
              </div>
            </div>
            <div className='flex_end_fechar_modal'>
              <Button onClick={e => { handleClose(); props.setCloseModal(true) }}>
                <CloseIcon className='icone_fechar_modal' />
              </Button>
            </div>
          </div>
          <div className='flex_chat_pessoas_lado_esquerdo mt30'>
            <div className='tamanho_lado_esquerdo_chat'>
              {/* <Accordion className='estilo_accordion'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='cor_icon_drop_accordion' />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='titulo_accordion'>
                    <div className='div_icone_acorddion'>
                      <PushPinIcon className='cor_icon_accordion' />
                    </div>
                    Mensagens fixadas
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='flex_accordion_main'>
                    <div className='div_imagem_chat'>
                      Teste
                      <div className='div_status_ativo_imagem_chat'>
                      </div>
                    </div>
                    <div className='flex_column_pessoa_chat'>
                      <div className='fonte_nome_pessoa_titulo_chat'>
                        Igor Alírio Teufel
                      </div>
                      <div className='fonte_lado_esquerdo_accordion'>
                        Igor está digitando
                      </div>
                    </div>
                    <div className='flex_end_accordion'>
                      <div className='alinha_flex_end_accordion '>
                        <div className='fonte_hora_accordion'>
                          08:02
                        </div>
                        <div className='numero_mensagem_chat mt10'>
                          8
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex_accordion_main_colorido'>
                    <div className='div_imagem_chat'>
                      Teste
                      <div className='div_status_ativo_imagem_chat'>
                      </div>
                    </div>
                    <div className='flex_column_pessoa_chat'>
                      <div className='fonte_nome_pessoa_titulo_chat_colorido'>
                        Igor Alírio Teufel
                      </div>
                      <div className='fonte_lado_esquerdo_accordion_colorido'>
                        Igor está digitando
                      </div>
                    </div>
                    <div className='flex_end_accordion'>
                      <div className='alinha_flex_end_accordion '>
                        <div className='fonte_hora_accordion_colorido'>
                          08:02
                        </div>
                        <div className='numero_mensagem_chat mt10'>
                          8
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion> */}
              <Accordion className='estilo_accordion'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='cor_icon_drop_accordion' />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='titulo_accordion'>
                    <div className='div_icone_acorddion'>
                      <GroupIcon className='cor_icon_accordion' />
                    </div>
                    Mensagens de grupos
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {chats && chats.map( (chat, index) => {
                    if (chat.type === 'unidade' || chat.type === 'especialidade') {
                      return (
                        <div className={ chat.active ? 'flex_accordion_main_colorido' : 'flex_accordion_main' } style={{ cursor: 'pointer' }} onClick={ e => openChat(chat, index, tokenJWT) } >
                          <div className='div_imagem_chat'>
                            {chat.avatar}
                          </div>
                          <div className='flex_column_pessoa_chat'>
                            <div className={ chat.active ? 'fonte_nome_pessoa_titulo_chat_colorido' : 'fonte_nome_pessoa_titulo_chat' }>
                              {chat.nome}
                            </div>
                            <div className={chat.active ? 'fonte_lado_esquerdo_accordion_colorido' : 'fonte_lado_esquerdo_accordion' }>
                              {chat.ultimaMensagem}
                            </div>
                          </div>
                          <div className='flex_end_accordion'>
                            <div className='alinha_flex_end_accordion '>
                              <div className='fonte_hora_accordion'>
                                {chat.horaUltimaMensagem}
                              </div>
                              {chat.naoLidas > 0
                                ?
                                <div className='numero_mensagem_chat mt10'>
                                  {chat.naoLidas}
                                </div>
                                :
                                ''
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    })
                  }
                </AccordionDetails>
              </Accordion>
              <Accordion className='estilo_accordion'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='cor_icon_drop_accordion' />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='titulo_accordion'>
                    <div className='div_icone_acorddion'>
                      <PersonIcon className='cor_icon_accordion' />
                    </div>
                    Lista geral
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {chats && chats.map( (chat, index) => {
                    if (chat.type === 'usuario') {
                      return (
                        <div className={ chat.active ? 'flex_accordion_main_colorido' : 'flex_accordion_main' } style={{ cursor: 'pointer' }} onClick={ e => openChat(chat, index, tokenJWT) } >
                          <div className='div_imagem_chat'>
                            {chat.avatar}
                            { chat.online
                              ?
                                <div className='div_status_ativo_imagem_chat' />
                              :
                                ''
                            }
                          </div>
                          <div className='flex_column_pessoa_chat'>
                            <div className={ chat.active ? 'fonte_nome_pessoa_titulo_chat_colorido' : 'fonte_nome_pessoa_titulo_chat' }>
                              {chat.nome}
                            </div>
                            <div className={chat.active ? 'fonte_lado_esquerdo_accordion_colorido' : 'fonte_lado_esquerdo_accordion' }>
                              {chat.ultimaMensagem}
                            </div>
                          </div>
                          <div className='flex_end_accordion'>
                            <div className='alinha_flex_end_accordion '>
                              <div className='fonte_hora_accordion'>
                                {chat.horaUltimaMensagem}
                              </div>
                              {chat.naoLidas > 0
                                ?
                                <div className='numero_mensagem_chat mt10'>
                                  {chat.naoLidas}
                                </div>
                                :
                                ''
                              }
                            </div>
                          </div>
                        </div>
                      )}
                    })
                  }
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='flex_chat_iframe_lado_direito mt30'>
            <div className='tamanho_lado_direito_iframe'>
              <iframe className='tamanho_iframe' src={srcChat} frameborder="0"></iframe>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}