import env from "react-dotenv";
import axios from "axios";

/**
 * @param {Object.<string, string>} params
 * @returns {Array.<{key: string, value: string}>}
 * @description Função que retorna os parâmetros da url em chave e valor
 */
function getParams(params) {
  if (typeof params === "object") {
    params = Object.keys(params).map((key, index) => {
      return { key: key, value: Object.values(params)[index] };
    });

    return params;
  }
}

/**
 * @param {*} token
 * @param {object} params
 * @param {string?} params.pcd Ambos = null | Sim = 1 | Não = 0
 * @param {string?} params.group_id
 * @param {string?} params.medical_unit_id
 * @param {string?} params.search
 * @param {string?} params.min_age
 * @param {string?} params.max_age
 * @description Filtra por pacientes pelos parâmetros passados
 **/
export async function filterPatient(token, params) {
  const uri = new URL(`${env.API_URL}/patient/filter`);

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  uri.searchParams.append("token", token);

  const response = await axios.get(uri);
  return response.data;
}

/**
 * @param {*} token
 * @param {object} params
 * @param {string?} params.pcd Ambos = null | Sim = 1 | Não = 0
 * @param {string?} params.group_id
 * @param {string?} params.medical_unit_id
 * @param {string?} params.search
 * @param {string?} params.min_age
 * @param {string?} params.max_age
 * @description Filtra as agendas de um profissional.
 **/
export async function simpleFilterPatient(token, params) {
  const uri = new URL(`${env.API_URL}/patient/simple-filter`);

  delete params.idade;

  var filters = getParams(params);

  if (filters && Array.isArray(filters)) {
    filters.forEach((filter) => {
      uri.searchParams.append(filter.key, filter.value);
    });
  }

  uri.searchParams.append("token", token);

  const response = await axios.get(uri);
  return response.data;
}

export async function listPronouns() {
  const response = await axios.get(`${env.API_URL}/pronoun?token=${localStorage.getItem('token')}`);
  return response.data;
}

function getAllPatients(token, page = 1) {
  return fetch(`${env.API_URL}/patient?page=${page}&token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function getAllPatientsFilter(token, page = 1, filter) {
  return fetch(
    `${env.API_URL}/patient?page=${page}&search=${filter.search}&grupo=${filter.grupo}&unidade=${filter.unidade}&pcd=${filter.pcd}&idadede=${filter.idade.de}&idadeate=${filter.idade.ate}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function getPatientByPage(token, page) {
  return axios
    .get(`${env.API_URL}/patient/filter?page=${page}&token=${token}`)
    .then((res) => res.data);
}

function getPatientById(token, id) {
  return fetch(`${env.API_URL}/patient/${id}?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function createPatient(token, dados) {
  return fetch(`${env.API_URL}/patient?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function getPatientDeficiencies(token){
  return fetch(`${env.API_URL}/patientDeficienciesType?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

function updatePatient(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function updatePatientFull(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/full?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deletePatient(token, id) {
  return fetch(`${env.API_URL}/patient/${id}?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
  }).then((res) => res.json());
}

function createDocument(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-document?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function updateDocument(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-document?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteDocument(token, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-document?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
  }).then((res) => res.json());
}

function updateCNSDocument(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-document-cns?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function createPregnancy(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-pregnancy?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function updatePregnancy(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-pregnancy?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function deletePregnancy(token, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-pregnancy?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "delete",
    }
  ).then((res) => res.json());
}

function createDeficiencies(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-deficiencies?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function updateDeficiencies(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-deficiencies?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function deleteDeficiencies(token, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-deficiencies?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "delete",
    }
  ).then((res) => res.json());
}

function createContact(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-contact?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function updateContact(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-contact?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteContact(token, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-contact?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
  }).then((res) => res.json());
}

function createHasMedicalUnit(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-has-medical-unit?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function updateHasMedicalUnit(token, dados, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-has-medical-unit?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function deleteHasMedicalUnit(token, id) {
  return fetch(
    `${env.API_URL}/patient/${id}/patient-has-medical-unit?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "delete",
    }
  ).then((res) => res.json());
}

function createAddress(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-address?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function updateAddress(token, dados, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-address?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "put",
    body: JSON.stringify(dados),
  }).then((res) => res.json());
}

function deleteAddress(token, id) {
  return fetch(`${env.API_URL}/patient/${id}/patient-address?token=${token}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "delete",
  }).then((res) => res.json());
}

function getPatientByFilter(token, dados, tipo) {
  if (tipo == "sidebar") {
    var url = "";

    if (dados.min_age.length) {
      url += `&min_age=${dados.min_age}`;
    }

    if (dados.max_age.length) {
      url += `&max_age=${dados.max_age}`;
    }

    if (dados.pcd != "null") {
      url += `&pcd=${dados.pcd}`;
    }

    if (dados.group_id) {
      url += `&group_id=${dados.group_id}`;
    }

    if (dados.medical_unit_id) {
      url += `&medical_unit_id=${dados.medical_unit_id}`;
    }

    return fetch(`${env.API_URL}/patient/filter?token=${token}${url}`, {
      method: "get",
    }).then((res) => res.json());
  } else {
    return fetch(
      `${env.API_URL}/patient/filter?token=${token}&search=${dados}`,
      {
        method: "get",
      }
    ).then((res) => res.json());
  }
}

function getPatientBySimpleFilter(token, dados, page = 1) {
  return fetch(
    `${env.API_URL}/patient/simple-filter?page=${page}&search=${dados}&token=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

function updateGroup(token, id, dados) {
  return fetch(
    `${env.API_URL}/patient/${id}/all-patient-has-group?token=${token}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(dados),
    }
  ).then((res) => res.json());
}

function getHistoryByPatient(token, patientId) {
  return fetch(`${env.API_URL}/health-attendance-history/patient/${patientId}?token=${token}`, {
    method: "get",
  }).then((res) => res.json());
}

export {
  getAllPatients,
  getAllPatientsFilter,
  getPatientById,
  createPatient,
  updatePatient,
  deletePatient,
  updateCNSDocument,
  createDocument,
  updateDocument,
  deleteDocument,
  createPregnancy,
  updatePregnancy,
  deletePregnancy,
  createDeficiencies,
  updateDeficiencies,
  deleteDeficiencies,
  createContact,
  updateContact,
  deleteContact,
  createHasMedicalUnit,
  updateHasMedicalUnit,
  deleteHasMedicalUnit,
  createAddress,
  updateAddress,
  deleteAddress,
  getPatientByFilter,
  getPatientBySimpleFilter,
  updateGroup,
  getPatientByPage,
  updatePatientFull,
  getPatientDeficiencies,
  getHistoryByPatient
};
