import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { getHealthAttendancePDF } from "../../api/healthAttendance";
import { getByHealthAttendanceId } from "../../api/health_attendances";
import { getFormatDate } from "../../helpers/formatacao";
import Header from "./components/Header";


function VisualizarExame (props) {
    const {id} = useParams();

    const [linkData, setLinkData] = useState(null);
    const [blob, setBlob] = useState(null);
    const [attendance, setAttendance] = useState(null);

    useEffect(() => {

        if(id && isNaN(id)) return;

        getHealthAttendancePDF(localStorage.getItem('token'), id || props.idAtendimento).then(data => {
            var base64Str = Buffer.from(data).toString('base64');

            var binaryString = window.atob(base64Str);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);

            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }

            if (props.idAtendimento) {
                if(bytes.length < 1000000) {
                    var string = `${Math.round(+bytes.length/1024).toFixed(2)}`;
                    var numero = parseFloat(string.replace('.', ','));
    
                    props.fileSize(`${numero}kb`)
                } else {
                    var string = `${(Math.round(+bytes.length/1024)/1000).toFixed(2)}`;
                    var numero = parseFloat(string.replace('.', ','));
    
                    props.fileSize(`${numero}mb`)
                }
            }

            var arrBuffer = bytes;
            setBlob(arrBuffer)
            var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            data = window.URL.createObjectURL(newBlob);

            setLinkData(data);

            window.URL.revokeObjectURL(data);
        });

        getByHealthAttendanceId(localStorage.getItem("token"), id || props.idAtendimento)
            .then(data => {
                if(data.status){
                    setAttendance(data.healthAttendance)
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [id, props.idAtendimento]);

    useEffect(() => {
        if (props.downloadFile) {
            downloadPdf();
        }
    }, [props.downloadFile]);

    let info = {
        url: linkData,
        cns: attendance ? (attendance.patient.cns || "") : null,
        data: attendance && attendance.open_at ? getFormatDate(attendance.open_at, "dd/MM/yyyy") : "",
        nome_paciente: attendance ? attendance.patient.full_name : ""
    }

    const downloadPdf = () => {
        let pdfAnchor = document.createElement('a');
        document.body.appendChild(pdfAnchor);

        var newBlob = new Blob([blob], { type: "application/pdf" });
        let _data   = URL.createObjectURL(newBlob)

        pdfAnchor.href     = _data; 
        pdfAnchor.target   = '_blank'; 
        pdfAnchor.download = `ficha_de_consulta_${id || props.idAtendimento}.pdf`;

        pdfAnchor.click();
        pdfAnchor.remove();
        setTimeout(() => URL.revokeObjectURL(_data), 5000);
    }

    if(props.getIframe){
        return <iframe src={info.url} width="100%" height={props.height} frameborder="0" />
    }
    
    if(!linkData) return null;
    
    return (
        <Header download={e => downloadPdf()} info={info}>
            <iframe src={info.url} width="100%" height="100%" frameborder="0" />
        </Header>
    )
}

export default VisualizarExame;