import 'date-fns';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import SyncIcon from '@material-ui/icons/Sync';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Backdrop, 
    CircularProgress,
    Typography,
    Grid
} from "@material-ui/core";
import * as moment from 'moment';
import 'moment/locale/pt-br';

import HistoricoPopover from './HistoricoPopover';
import SearchList from '../../../component/shared/SearchList';
import SelectRangeDate from '../../../component/shared/SelectRangeDate';
import CheckboxList from '../../../component/shared/CheckboxList';
import { 
    getAllTrails, 
    getAllActions, 
    getAllProfiles, 
    getAllUsers, 
    getAllUnidades 
} from '../../../api/trail';

const useStyles = makeStyles((theme) => ({

    btnFilter: {
        margin: "0 auto",
        marginTop: "15px",
        borderRadius: "20px",
        width: "120px",
        display: "block"
    },

    menuFilter: {
        margin: "10px",
        fontSize: "14px",
        color: theme.palette.neutral.midgrey
    },

    menuRedefinir: {
        color: `${theme.palette.primary.light} !important`
    },

    labelFilter: {
        display: "table-cell",
        verticalAlign: "middle"
    }

}));


export default function HistoricoFilter({ callbackAfterFilter, callbackSelectFilter }) {

    const classes = useStyles();
    const token = localStorage.getItem("token");
    const filtrosSelectedinit = { tipoAcao: [], unidade: [], perfil: [], executor: [], dataInicial: null, dataFinal: null, itemPerPage: 3 };

    const [anchorTipoAcao, setAnchorTipoAcao] = useState(null);
    const [anchorUnidade, setAnchorUnidade] = useState(null);
    const [anchorPerfil, setAnchorPerfil] = useState(null);
    const [anchorExecutor, setAnchorExecutor] = useState(null);
    const [anchorPeriodo, setAnchorPeriodo] = useState(null);
    const [showLoader, setShowLoader] = useState(true);
    const [filtrosSelecionados, setFiltrosSelecionados] = useState(filtrosSelectedinit);
    const [tipoAcao, setTipoAcao] = useState([]);
    const [users, setUsers] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [dataInicial, setDataInicial] = useState(null);//moment().subtract(10, "days").toDate());
    const [dataFinal, setDataFinal] = useState(null);//moment().toDate());

    useEffect(() => {
        
        moment.locale('pt-br');

        getAllActions(token)
            .then((response) => {
                setTipoAcao(response);  
            });

        getAllUsers(token)
            .then((response) => {
                setUsers(response);
            });

        getAllUnidades(token)
            .then((response) => {
                setUnidades(response);
            });

        getAllProfiles(token)
            .then((response) => {
                setProfiles(response);
            });

        getAllTrails(token, 1, filtrosSelecionados)
            .then((response) => {
                callbackAfterFilter(response.data, 1, response.last_page);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
            });            
    }, []);

    const handleTipoAcaoClick = (event) => {
        setAnchorTipoAcao(event.currentTarget);
    };

    const handleTipoAcaoClose = (event) => {
        setAnchorTipoAcao(null);
    };

    const handleUnidadeClick = (event) => {
        setAnchorUnidade(event.currentTarget);
    };

    const handleUnidadeClose = (event) => {
        setAnchorUnidade(null);
    };

    const handlePeriodoClick = (event) => {
        setAnchorPeriodo(event.currentTarget);
    };    

    const handlePeriodoClose = (event) => {
        setAnchorPeriodo(null);
    };

    const handlePerfilClick = (event) => {
        setAnchorPerfil(event.currentTarget);
    };

    const handlePerfilClose = (event) => {
        setAnchorPerfil(null);
    };

    const handleExecutorClick = (event) => {
        setAnchorExecutor(event.currentTarget);
    };

    const handleExecutorClose = (event) => {
        setAnchorExecutor(null);
    };

    const getTrails = () => {
        setShowLoader(true);
        callbackSelectFilter(filtrosSelecionados)    
        getAllTrails(token, 1, filtrosSelecionados)
            .then((response) => {
                callbackAfterFilter(response.data, 1, response.last_page);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
            });
    }

    const resetFilters = () => {
        setShowLoader(true);
        setDataInicial(null);
        setDataFinal(null);
        setFiltrosSelecionados(filtrosSelectedinit);                       
        callbackSelectFilter(filtrosSelectedinit)    
        getAllTrails(token, 1, filtrosSelectedinit)
            .then((response) => {
                callbackAfterFilter(response.data, 1, response.last_page);
                setShowLoader(false);
            })
            .catch((error) => {
                setShowLoader(false);
            });        
    }

    const setTipoAcaoFilter = (acoes) => {       
        const act = tipoAcao.filter(u => acoes.map(a => a.description).includes(u.description));
        setFiltrosSelecionados({...filtrosSelecionados, tipoAcao: act});       
    };

    const setUnidadeFilter = (unis) => {  
        const uni = unidades.filter(u => unis.map(a => a.id).includes(u.id));        
        setFiltrosSelecionados({...filtrosSelecionados, unidade: uni});      
    };

    const setDateFilter = (dates) => {  
        setDataInicial(dates.dataInicial);
        setDataFinal(dates.dataFinal);
        setFiltrosSelecionados({...filtrosSelecionados, dataInicial:dates.dataInicial, dataFinal:dates.dataFinal});
    };    

    const setPerfilFilter = (perfis) => {        
        const per = profiles.filter(u => perfis.map(a => a.id).includes(u.id));
        setFiltrosSelecionados({...filtrosSelecionados, perfil: per});       
    };

    const setExecutorFilter = (executores) => {
        const exe = users.filter(u => executores.map(a => a.id).includes(u.id));
        setFiltrosSelecionados({...filtrosSelecionados, executor: exe});    
    }

    return (
        <>
            <Backdrop style={{zIndex: 3000, color: 'white', textAlign: 'center'}} open={showLoader}>
                <Grid container>
                    <Grid item md={12}>
                        <CircularProgress color="inherit" />                
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant="body1" className="text-regular text-st">Carregando listagem de ações...</Typography>
                    </Grid>
                </Grid>
            </Backdrop> 

            <div
                className={[classes.menuFilter, classes.menuRedefinir]}
                style={{display: "table"}}
            >
                <FilterListIcon /> &nbsp; <label className={classes.labelFilter}>Filtros</label>
            </div>

            <Button
                aria-describedby="simple-popover-tipo-acao"
                className={classes.menuFilter}
                onClick={handleTipoAcaoClick}
                type="button"
            >
                Tipo de Ação &nbsp; <ExpandMoreIcon />
            </Button>

            <HistoricoPopover
                id="simple-popover-tipo-acao"
                anchor={anchorTipoAcao}
                handleClose={handleTipoAcaoClose}                
            >
                <CheckboxList 
                    src={tipoAcao} 
                    displayAttr="description" 
                    keyAttr="id" 
                    callbackFilterClick={(e) => {
                        setAnchorTipoAcao(null);
                        getTrails();
                    }}
                    callbackFilterSelect={setTipoAcaoFilter}
                    selecionados={filtrosSelecionados.tipoAcao}                 
                />
                
            </HistoricoPopover>

            <Button
                aria-describedby="simple-popover-unidade"
                className={classes.menuFilter}
                onClick={handleUnidadeClick}
                type="button"
            >
                Unidade &nbsp; <ExpandMoreIcon />
            </Button>

            <HistoricoPopover
                id="simple-popover-unidade"
                anchor={anchorUnidade}
                handleClose={handleUnidadeClose}                
            >
                <SearchList
                    src={unidades}
                    searchAttr="company_name"
                    keyAttr="id"
                    callbackFilterSelect={setUnidadeFilter}
                    callbackFilterClick={(e) => {
                        setAnchorUnidade(null);
                        getTrails();
                    }}
                    selecionados={filtrosSelecionados.unidade}
                />
            </HistoricoPopover>

            <Button
                aria-describedby="simple-popover-perfil"
                className={classes.menuFilter}
                onClick={handlePerfilClick}
                type="button"
            >
                Perfil &nbsp; <ExpandMoreIcon />
            </Button>

            <HistoricoPopover
                id="simple-popover-perfil"
                anchor={anchorPerfil}
                handleClose={handlePerfilClose}                
            >
                <CheckboxList 
                    src={profiles} 
                    displayAttr="description" 
                    keyAttr="id" 
                    callbackFilterSelect={setPerfilFilter}
                    callbackFilterClick={(e) => {
                        setAnchorPerfil(null);
                        getTrails();
                    }}
                    selecionados={filtrosSelecionados.perfil}               
                />                    
            </HistoricoPopover>

            <Button
                aria-describedby="simple-popover-executor"
                className={classes.menuFilter}
                onClick={handleExecutorClick}
                type="button"
            >
                Executor da Ação &nbsp; <ExpandMoreIcon />
            </Button>

            <HistoricoPopover
                id="simple-popover-executor"
                anchor={anchorExecutor}
                handleClose={handleExecutorClose}                
            >
                <SearchList 
                    src={users} 
                    searchAttr="full_name"
                    keyAttr="id"
                    callbackFilterSelect={setExecutorFilter}
                    callbackFilterClick={(e) => {
                        setAnchorExecutor(null);
                        getTrails();
                    }}
                    selecionados={filtrosSelecionados.executor}        
                />                 
            </HistoricoPopover>

            <Button 
                aria-describedby="simple-popover-periodo" 
                className={classes.menuFilter}
                onClick={handlePeriodoClick}
            >
                Período &nbsp; <ExpandMoreIcon />
            </Button>

            <HistoricoPopover
                id="simple-popover-periodo"
                anchor={anchorPeriodo}
                handleClose={handlePeriodoClose}                
            >
                <SelectRangeDate
                    dateStart={dataInicial}
                    dateEnd={dataFinal}                    
                    callbackFilterSelect={setDateFilter}
                    callbackFilterClick={(e) => {
                        setAnchorPeriodo(null);
                        getTrails();
                    }}                    
                />
            </HistoricoPopover>            

            <Button
                className={[classes.menuFilter, classes.menuRedefinir]}
                onClick={resetFilters}
                type="button"
            >
                <SyncIcon />&nbsp;Redefinir
            </Button>

        </>
    )
}
