import { Typography } from "@material-ui/core";

function Smoking(props)
{
    function getType()
    {
        switch(props.preForm.smoking) {
            case 'Fumante':
                return `Fumante por ${props.preForm.smoking_period} cigarros: ${props.preForm.smoking_period_without}`;
            case 'Ex-fumante':
                return `Ex-fumante, ${props.preForm.smoking_period}, ${props.preForm.smoking_period_without}`;
            case 'Não fumante':
                return "Não fumante";
            default:
                return "Não informado";
        }
    }

    return <Typography variant="body1" className="text-regular text-st">{ getType() }</Typography>
}

export default Smoking;