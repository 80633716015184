import axios from 'axios';
import env from "react-dotenv";

export async function getPreviousImagingExams(token, healthAttendanceId){
    const res = await axios.get(`${env.API_URL}/prodam/${healthAttendanceId}/previousImagingExamsList?token=${token}`);
    return res.data;
}

export async function getImagingExamsById(token, examsId){
    const res = await axios.get(`${env.API_URL}/prodam/previousImagingExams/${examsId}?token=${token}`);
    return res.data;
}

export default {
    getPreviousImagingExams,
    getImagingExamsById
 
}