import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormBuilderContext } from "../../../../../../../context/FormBuilderContext";
import DInput from "../../../../../../shared/DInput";

const EBParagraph = ({ id, label, placeholder, value, helper_text, is_required, is_read_only, additional_data }) => {
  return (
    <section>
      <div className="mb-3">
        <p><div dangerouslySetInnerHTML={{ __html: `${additional_data.construct.showTitle ? label : ""}` }} /></p>
        <span  className="text-light">{helper_text ? helper_text : ""}</span>
      </div>
    </section>
  );
};

export default EBParagraph;
