import React, { Component } from "react";
import Pusher from 'pusher-js';
import env from "react-dotenv";
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import CardNotify from "./CardNotify";
import {readNotification} from "../../../../api/notificationUser";

export default class NotifyPopUp extends Component {

    constructor(props) {
        super(props);
        this.pusher = new Pusher(env.KEY_PUSHER);
        this.uid = localStorage.getItem("uid");
        this.token = localStorage.getItem("token");
        this.state = {
            notifications: [],
            open: false,
            positionX: document.body.clientWidth
        }
    }

    componentDidMount() {
        const channel = this.pusher.subscribe('notification');
        channel.bind(`event-user-${this.uid}`, this.onReceiveData.bind(this));
    }

    componentWillUnmount() {
        this.pusher.unsubscribe('notification');
        this.pusher.disconnect();
    }

    onReceiveData(data) {
        this.state.notifications.push(data);
        this.setState({ notifications: this.state.notifications, open: true });
    }

    render() {

        return (
            <>
                <Popover
                    open={this.state.open}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 60, left: this.state.positionX }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transition
                >
                    <Paper>
                        {
                            this.state.notifications.map((not, index) => {
                                return (
                                    <>
                                        <CardNotify
                                            title={not.title}
                                            message={not.message}
                                            onClose={() => {
                                                this.state.notifications.splice(index, 1);
                                                this.setState({ 
                                                    notifications: this.state.notifications,  
                                                    open: this.state.notifications.length > 0
                                                });
                                            }}
                                            onClick={() => {
                                                this.state.notifications.splice(index, 1);
                                                this.setState({ 
                                                    notifications: this.state.notifications,  
                                                    open: this.state.notifications.length > 0
                                                });                                                
                                                window.location = not.link;
                                                readNotification(this.token, not.id)
                                            }}
                                        />
                                    </>
                                );
                            })
                        }
                    </Paper>
                </Popover>

            </>
        )

    }

}