import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Chip, Grid, Link, Typography } from "@material-ui/core";
import { Cancel, Clear, Edit, Reply, Send } from "@material-ui/icons";
import DButton from "../../../shared/DButton";
import DInput from "../../../shared/DInput";
import { toast } from "react-toastify";
import { updateAttendanceStatus } from "../../../../api/healthAttendance";
import { useEffect } from "react";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border:`1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  headerConsultaCancelada: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: "0",
    marginBottom: "0",
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  icon: {
    paddingRight: theme.spacing(1),
  },
  gridButton: {
    margin: theme.spacing(2),
    display: "flex",
    justifyContent: "space-evenly",
  },
  gridLink: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.light,
    cursor: "pointer",
    margin: theme.spacing(2),
  },
  headerModalSuccess: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    margin: 20,
  },
  clearClass:{ 
    color: theme.palette.neutral.midgrey, 
    cursor: "pointer" 
  }  
}));

export default function MdCancelConsult(props) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openAlertDanger, setOpenAlertDanger] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [motivoCancelamento, setMotivoCancelamento] = useState("");

  useEffect(() => {
    if(props.undoCancelConsult) {
      setOpenAlertDanger(true);
    }
  }, [props.undoCancelConsult]);

  function cancelConsult() {
    if(!motivoCancelamento){
      return toast.error("Preencha o motivo do cancelamento.");
    } else {
      const {attendance} = props;

      if(attendance){
        updateAttendanceStatus(localStorage.getItem('token'), attendance.id, {active: false, reason: motivoCancelamento}).then(data => {
          setOpenAlertDanger(true);
          toast.success("Consulta cancelada com sucesso.");
        }).catch(err => {
          toast.error("Não foi possível cancelar o atendimento.");
          console.error(err);
        });
      } else {
        toast.error("Algo deu errado, tente novamente.")
      }
    }
  }


  function handleUndoCancelAttendance(){
    const {attendance} = props;

    if(attendance){
      updateAttendanceStatus(localStorage.getItem('token'), attendance.id, {active: true, reason: null}).then(data => {
        setOpenAlertDanger(false);
        props.onClose(true);
        toast.success("Consulta reativada com sucesso.");
      }).catch(err => {
        toast.error("Não foi possível reativar o atendimento.");
        console.error(err);
      });
    } else {
      toast.error("Algo deu errado, tente novamente.")
    }
  }

  function handleCloseAlertDanger() {
    setOpenAlertDanger(false);
  }

  function closeModal() {
    //try catch if send true open modal success
    props.onClose(true);
  }

  function handleCloseAlertSuccess() {
    setOpenAlertSuccess(false);
  }

  const body = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headermodal}>
          <Typography
            className="simple-modal-title-alert-danger text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Cancel style={{fontSize: "20px"}} /> &nbsp;Cancelar consulta
          </Typography>
          <Clear
            className={classes.clearClass}
            onClick={(e) => {
              props.onClose(true);
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light" style={{padding: "0px 10px"}}>
          <>
            <Grid item md={12} sm={6}>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>Paciente </strong>{" "}
                {props.attendance && props.attendance.patient && props.attendance.patient.full_name}
              </Typography>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>CPF:</strong> {props.attendance && props.attendance.patient && props.attendance.patient.cpf}
              </Typography>
              <Typography className="text-mt text-regular mb-1" variant="body1">
                <strong>Tipo de consulta: </strong> {props.attendance && props.attendance.interconsultation ? "Interconsulta" : "Imediata"}
              </Typography>
            </Grid>

            <Grid item md={12} sm={6}>
            <Typography className="text-mt text-regular mb-1" variant="body1">
                Qual o motivo do cancelamento? <span style={{color: "red"}}>(obrigatório)</span>
            </Typography>
              
                    <DInput 
                        textarea={3}
                        contador={motivoCancelamento && motivoCancelamento.length}
                        maxSize={ 260 }
                        inputProps={ {
                            maxLength:260
                        } }
                        fullWidth={true}
                        placeholder="Digite aqui"
                        value={ motivoCancelamento }
                        onChange={ e => {
                            setMotivoCancelamento(e.target.value)
                        } } 
                    />
            </Grid>

            <Grid item md={12} sm={6}>
              <hr />
            </Grid>


            <Grid item md={8} sm={3} className={classes.gridButton}>
              <DButton
                variant="outlined"
                color="secondary"
                size="sm"
                onClick={closeModal}
              >
                Voltar
              </DButton>
              <DButton
                variant="primary"
                className={"btn-error"}
                size="sm"
                onClick={cancelConsult}
              >
                Cancelar
              </DButton>
            </Grid>
            {/* <Grid item md={11} sm={6} className={classes.gridLink}>
              <Edit className={classes.icon} />
              <Link>Editar dados do Paciente</Link>
            </Grid> */}
          </>
        </Grid>
      </>
    </div>
  );

  const alertConsultCanceled = (
    <div style={modalStyle} className={`${classes.paper}`}>
      <>
        <div className={classes.headerConsultaCancelada}>
          <Typography
            className="simple-modal-title-alert-danger text-mt text-bold"
            style={{ alignItems: "center", display: "flex" }}
          >
            Consulta cancelada
          </Typography>
          <Clear
            style={{ color: "#949494", cursor: "pointer" }}
            onClick={(e) => {
              handleCloseAlertDanger();
              props.onClose(true)
            }}
          />
        </div>
        <Grid container justifyContent="center" className="tema-light">
          <>
            <Grid item md={11} sm={6} className={classes.gridLink}>
              <Reply />&nbsp;<Link onClick={e => handleUndoCancelAttendance()}> Desfazer cancelamento</Link>
            </Grid>
          </>
        </Grid>
      </>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openAlertDanger}
        onClose={handleCloseAlertDanger}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alertConsultCanceled}
      </Modal>
    </div>
  );
}
