import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Menu,
    MenuItem,
    OutlinedInput,
    InputAdornment, FormControl
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {useEffect, useState} from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles((theme) => ({
    card: {
        background: '#F4F4F4'
    },
    cardTitle: {
        marginBottom: 12
    },
    cardBody: {
        padding: 15,
        paddingBottom: '15px !important'
    },
    tableContainer: {
        boxShadow: 'none'
    },
    container: {
        display: 'none'
    },
    containerOpen: {
        display: 'block'
    },
    table: {
        minWidth: 650
    },
    icon: {
        color: theme.palette.primary.light,
    },
    headerText: {
        fontSize: '16px !important',
        color: '#272727 !important',
        fontWeight: "500 !important",
        lineHeight: "24px !important"
    },
    actionButton : {
        fontWeight: "400 !important"
    }
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontWeight: "400 !important"
    },
    body: {
        fontSize: 14,
        borderBottom: "1px solid rgb(114 114 114)"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.action.hover,

        borderBottom: "1px solid rgb(114 114 114)"
    }
}))(TableRow);

const StyledButton = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: 14,
        padding: 0,
        fontWeight: "400 !important"
    }
}))(Button);

const StyledButtonAction = withStyles((theme) => ({
    root: {
        textTransform: 'unset',
        fontSize: 14,
        color: theme.palette.primary.light,
        fontWeight: "400 !important"
    }
}))(Button);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledTitle = withStyles((theme) => ({
    colorPrimary: {
        color: theme.palette.primary.light,
    }
}))(props => (
    <>
        { props.order === 'asc' ? <KeyboardArrowUpIcon color="primary" /> : '' }
        { props.order === 'desc' ? <KeyboardArrowDownIcon color="primary" /> : '' }
    </>
))

const StyledOutlinedInput = withStyles((theme) => ({
    root: {
        background: '#FFF',
        minWidth: 400,
        marginBottom: 30
    }
}))(OutlinedInput)

function sortByCompanyName(unidades, order) {
     var array = [...unidades];

     if(order.companyName === 'asc') {
         array = array.sort(function(a , b) {
             return a.company_name - b.company_name;
         })
     }else if(order.companyName === 'desc') {
         array = array.reverse(function(a , b) {
             return a.company_name - b.company_name;
         })
     }

    if(order.businessName === 'asc') {
        array = array.sort(function(a , b) {
            return a.business_name - b.business_name;
        })
    }else if(order.businessName === 'desc') {
        array = array.reverse(function(a , b) {
            return a.business_name - b.business_name;
        })
    }

    return array;
}

function filterGrid(unidades, search) {

    if(search.length < 1)
        return unidades;

    var array = [...unidades];

    const regex = new RegExp(search, 'i');

    return array.filter(item => item.company_name.match(regex) || item.cnpj.match(regex));
}


function UnidadesAdministrativas(props) {

    const classes = useStyles();
    const [unidades, setUnidades] = useState(props.unidades);
    const [orderCompanyName, setOrderCompanyName] = useState(null)
    const [orderBusinessName, setOrderBusinessName] = useState(null)
    const [order, setOrder] = useState({ companyName: null, businessName: null })
    const [search, setSearch] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => setUnidades(props.unidades), [props.unidades]);

    useEffect(function(){

        if(order.companyName || order.businessName)
            setUnidades(sortByCompanyName(props.unidades, order))

        if(search)
            setUnidades(filterGrid(props.unidades, search))

    }, [order, search])

    function handleUnidade(id){
        props.unidade(id)
    }

    return (
        <Card classes={{root: classes.card}}>
            <CardContent classes={{root: classes.cardBody}}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography className={`${classes.headerText} text-primary`}>Unidades administrativas</Typography>
                    </Grid>
                    <Grid item>
                        { open
                            ?
                            <StyledButtonAction onClick={ e => setOpen(false) }>Fechar <KeyboardArrowDownIcon color="primary" style={{paddingLeft: 16}} /></StyledButtonAction>
                            :
                            <StyledButtonAction onClick={ e => setOpen(true) }>Abrir <KeyboardArrowUpIcon color="primary" style={{paddingLeft: 16}} /></StyledButtonAction>
                        }
                    </Grid>
                </Grid>
                <Grid container classes={{ root: open ? classes.containerOpen : classes.container }}>
                    <Grid item md={12}>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <FormControl className={clsx(classes.textField)} variant="outlined" size="small">
                                    <StyledOutlinedInput
                                        id="filter-unidades-administrativas"
                                        type="text"
                                        placeholder="Buscar por nome ou CNPJ"
                                        onChange={e => setSearch(e.target.value) }
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <IconButton edge="start">
                                                    <SearchIcon classes={{ root: classes.icon }} color="primary" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={0}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <Table className={classes.table} aria-label="Unidades administrativas table">
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell align="left">
                                            <StyledButton onClick={ event => setOrderCompanyName(event.currentTarget) }>
                                                Nome fantasia
                                                <StyledTitle order={ order.companyName || "desc" } />
                                            </StyledButton>
                                            <StyledMenu
                                                id="order-nome-fantasia"
                                                anchorEl={orderCompanyName}
                                                keepMounted
                                                open={Boolean(orderCompanyName)}
                                                onClose={ event => setOrderCompanyName(null) }>
                                                <MenuItem onClick={event => {
                                                    setOrderCompanyName(null)
                                                    setOrder({ ...order, companyName: 'asc' })
                                                }}>A > Z</MenuItem>
                                                <MenuItem onClick={event => {
                                                    setOrderCompanyName(null)
                                                    setOrder({ ...order, companyName: 'desc' })
                                                }}>Z > A</MenuItem>
                                            </StyledMenu>
                                        </TableCell>
                                        <TableCell align="left">
                                            <StyledButton onClick={ event => setOrderBusinessName(event.currentTarget) }>
                                                Nome empresarial
                                                <StyledTitle order={ order.businessName || "desc" } />
                                            </StyledButton>
                                            <StyledMenu
                                                id="order-nome-fantasia"
                                                anchorEl={orderBusinessName}
                                                keepMounted
                                                open={Boolean(orderBusinessName)}
                                                onClose={ event => setOrderBusinessName(null) }>
                                                <MenuItem onClick={event => {
                                                    setOrderBusinessName(null)
                                                    setOrder({ ...order, businessName: 'asc' })
                                                }}>A > Z</MenuItem>
                                                <MenuItem onClick={event => {
                                                    setOrderBusinessName(null)
                                                    setOrder({ ...order, businessName: 'desc' })
                                                }}>Z > A</MenuItem>
                                            </StyledMenu>
                                        </TableCell>
                                        <TableCell className={classes.actionButton} align="left">Telefone</TableCell>
                                        <TableCell className={classes.actionButton} align="left">CNPJ</TableCell>
                                        <TableCell align="left">&nbsp;</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    { unidades.map(function(item, index){
                                        return <StyledTableRow  key={ `unidades-administrativas-${ index }` }>
                                            <StyledTableCell  component="th" scope="row">{ item.company_name }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.business_name }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.phone_number }</StyledTableCell>
                                            <StyledTableCell  align="left">{ item.cnpj }</StyledTableCell>
                                            <StyledTableCell  align="left">
                                                <StyledButtonAction onClick={ e => handleUnidade(item.id) }>
                                                    <OpenInNewIcon fontSize="small" />&nbsp;Mais dados
                                                </StyledButtonAction>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UnidadesAdministrativas;