import { Typography } from "@material-ui/core";

function Alcoholic(props)
{
    function getType()
    {
        switch(props.preForm.alcoholic) {
            case 'Ocasional':
            case 'Muito Frequente':
            case 'Raro':
            case 'Frequente':
                return `${props.preForm.alcoholic}, ${props.preForm.alcoholic_period}, tipo de bebida: ${props.preForm.alcoholic_type}`;
            case 'Abstinente':
                return "Abstinente";
            default:
                return "Não informado";
        }
    }

    return <Typography variant="body1" className="text-regular text-st">{ getType() }</Typography>
}

export default Alcoholic;