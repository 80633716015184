/* eslint-disable no-use-before-define */
import React, { Component } from "react";
import {Redirect, withRouter} from "react-router-dom";
import TopBar from "./components/TopBar";
import StepLine from "./components/Step";
import FormCadastro from "./components/FormCadastro";
import {userDominios} from "../../api/dominios";
import { getMeByInvite, createUser } from "../../api/user";
import {Backdrop, CircularProgress, Grid, Typography} from "@material-ui/core";
import {CheckCircleOutline} from "@material-ui/icons";
import SimpleModal from "../shared/Modal";
import { toast } from "react-toastify";

class AutoCadastro extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            step: 0,
            invite: null,
            dominios: null,
            cadastrando: false,
            cadastrado: false
        }
    }

    componentDidMount() {

        const params = new URLSearchParams(this.props.location.search);

        getMeByInvite(params.get('token')).then(res => {
            if(res.status)
                this.setState({ ...this.state, invite: res.details })
            else
                this.setState({ ...this.state, redirect: true })
        });

        userDominios().then(res => {
            this.setState({ ...this.state, dominios: res })
        });
    }

    cadastrarUsuario(dados, setErrors) {

        const params = new URLSearchParams(this.props.location.search);

        this.setState({ ...this.state, cadastrando: true });

        createUser(params.get('token'), dados).then(res => {
            if(res.status){
                this.setState({ ...this.state, cadastrando: false, cadastrado: true });
            } else {
                var errors = {};

                if(res.error == "E-mail já cadastrado"){
                    errors.email = "E-mail já cadastrado";
                }

                if(res.details) {
                    (Object.entries(res.details)).forEach(function(item, i){

                        Object.assign(errors,{ [item[0]]: item[1].join(',') })
                    });
    
                    if(res.details && res.details.document && Array.isArray(res.details.document)){
                        if(res.details.document[0] === "CPF inválido ou CPF já utilizado."){
                            errors.cpf = "CPF inválido ou CPF já utilizado.";
                        }
    
                        res.details.document.forEach(x => {
                            toast.error(x);
                        })
                    }
                }

                setErrors({ ...dados.error, ...errors });

                this.setState({ ...this.state, step: 0 })

                this.setState({ ...this.state, cadastrando: false });
            }
        })
        .catch(err => {
            console.error(err)
            this.setState({ ...this.state, cadastrando: false });
        })
    }

    render() {

        return (
            <div className="bg-white h-100">
                { this.state.redirect && <Redirect to="/" /> }
                <TopBar />
                <StepLine step={ this.state.step } />
                { this.state.dominios && this.state.invite
                    ?
                    <FormCadastro voltar={ e => this.setState({...this.state, redirect: true}) } loading={ this.state.cadastrando } enviarCadastro={ (dados, setErr) => this.cadastrarUsuario(dados, setErr) } step={ this.state.step } onStep={ step => this.setState({ ...this.state, step }) } dominios={ this.state.dominios } invite={ this.state.invite } />
                    :
                    <Backdrop style={{zIndex: 3000, color: 'white'}} open={ !this.state.dominios }>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <SimpleModal open={ this.state.cadastrado } onClose={ e => this.setState({...this.state, redirect: true}) }>
                    <Grid container justifyContent="center" alignItems="center" style={{ height: 200 }}>
                        <Grid item className="text-center">
                            <CheckCircleOutline className="text-success" style={{fontSize: 64}} />
                            <Typography className="text-mt text-regular">Cadastro efetuado com sucesso</Typography>
                        </Grid>
                    </Grid>
                </SimpleModal>
            </div>
        )
    }
}

export default withRouter(AutoCadastro);