import env from 'react-dotenv';

function getAllCategories(token) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category/all?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function getCategoryById(token, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.
    
    return fetch(`${api_url}/faq/category/${ id }?token=${ token }`, {
        method: 'get'
    }).then(res => res.json());
}

function createCategory(token, dados) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'post',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function updateCategory(token, dados, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category/${ id }?token=${ token }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'put',
        body: JSON.stringify(dados)
    }).then(res => res.json());
}

function deleteCategory(token, id) {
    var api_url = new URL(env.API_URL).origin; // a api de faq não tem /api na rota.

    return fetch(`${api_url}/faq/category/${ id }`, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-auth-token': token
        },
        method: 'delete'
    }).then(res => res.json());
}

export { 
    getAllCategories,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory
}