import React, {useState,useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LaunchIcon from '@mui/icons-material/Launch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Stack from '@mui/material/Stack';
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { getHealthAttendance } from "./../../../../api/healthAttendance";
import { getFormatDate } from "./../../../../helpers/formatacao";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#4F4F4F",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: "#F4F4F4",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  pagination: {
    margin: "20px 0 30px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.light,

    '& .MuiPagination-ul': {
      color: "#272727",
      background: "transparent",
      fontWeight: 400,

      "& > li": {
        "& button": {
          fontSize: 16
        }
      },
      "& > li:first-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.light}`,
          color: theme.palette.primary.light,
        }
      },
      "& > li:last-child": {
        "& button": {
          border: `1px solid ${theme.palette.primary.light}`,
          color: theme.palette.primary.light,
        }
      },
      '& .Mui-selected': {
        border: `1px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.light,
        background: "#fff",
        fontWeight: 500
      },
    }
  }

}));

export default function CustomizedTables(props) {
  const classes = useStyles();
  const {patient} = useNewHealthAttendance();
  const [order, setOrder] = useState({
    atendimento: "desc",
    data: "desc"
  })
  const [atendimentos, setAtendimentos] = useState([]);
  const [pagination, setPagination] = useState({
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 0
  })

  useEffect(() => {
    if(patient) {
      getHealthAttendance(localStorage.getItem('token'), { page: 1, patient_id: patient.id, atendimento_order: order.atendimento, data_order: order.data }).then(res => {
          if(res.status) {
              setAtendimentos(res.healthAttendances.patient);
              setPagination(res.healthAttendances.paginate);
          }
      });
    }
  }, [patient])

  const handlePage = (page) => {
    getHealthAttendance(localStorage.getItem('token'), { page, patient_id: patient.id, atendimento_order: order.atendimento, data_order: order.data }).then(res => {
        if(res.status) {
            setAtendimentos(res.healthAttendances.patient);
            setPagination(res.healthAttendances.paginate);
        }
    });
  }

  const handleOrder = (type) => {

      var new_order = {...order}

      switch(type) {
        case "atendimento":
          new_order.atendimento = order.atendimento === 'asc' ? 'desc' : 'asc';
          break;
        default:
          new_order.data = order.data === 'asc' ? 'desc' : 'asc';
            break;
      }      

      setOrder(new_order);

      getHealthAttendance(localStorage.getItem('token'), { page: 1, patient_id: patient.id, atendimento_order: new_order.atendimento, data_order: new_order.data }).then(res => {
        if(res.status) {
            setAtendimentos(res.healthAttendances.patient);
            setPagination(res.healthAttendances.paginate);
        }
    });
  }

  const getAnamneseFormName = (anamneseForm) => {

    var name = "-";

    if(anamneseForm && anamneseForm.length > 0) {
        name = (anamneseForm.filter(form => form.anamnese_form !== null).map(form => form.anamnese_form.name)).join(",");
    }

    return name;
  }

  console.log(atendimentos)

  return (
    <div className='tabela_ultimos_atendimentos'>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Protocolo</StyledTableCell>
              <StyledTableCell align="left">Atendimento <Button className='icon_resultado_tabela_utimos_atendimentos' onClick={ e => handleOrder('atendimento') } startIcon={ order.atendimento === "desc" ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }> </Button></StyledTableCell>
              <StyledTableCell align="left">Data <Button className='icon_resultado_tabela_utimos_atendimentos' onClick={ e => handleOrder('data') } startIcon={ order.data === "desc" ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }> </Button></StyledTableCell>
              <StyledTableCell align="left">Unidade</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {atendimentos.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell align="left">{ getAnamneseFormName(row.health_attendance_anamnese_form) }</StyledTableCell>
                <StyledTableCell align="left">{ row.requested_at ? getFormatDate(row.requested_at, "dd/MM/yyyy") : null}</StyledTableCell>
                <StyledTableCell align="left">{row.medical_unit ? row.medical_unit.company_name : "-"}</StyledTableCell>
                <StyledTableCell align="center">
                    <Button onClick={ e => props.onSelect(row.id) } className='icon_resultado_tabela_utimos_atendimentos' startIcon={<LaunchIcon />} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={ `flex_alinha_paginacao ${classes.pagination}` }>
        <Stack spacing={2}>          
          <Pagination
              color='primary'
              count={pagination.last_page}
              page={pagination.current_page}
              onChange={(event, page) => handlePage(page)}
          />
        </Stack>
      </div>
    </div>
  );
}
