import {Grid, Typography} from "@material-ui/core";
import {CheckCircle, ErrorRounded, InfoRounded as Warning} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

function PasswordInstrucions(props) {

    const useStylesBootstrap = makeStyles((theme) => ({
        arrow: {
            color: '#67738B',
        },
        tooltip: {
            maxWidth: 150,
            fontSize: 14,
            lineHeight: '21px',
            backgroundColor: '#67738B',
        }
    }));

    function BootstrapTooltip(props) {
        const classes = useStylesBootstrap();

        return <Tooltip arrow classes={classes} {...props} />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className="text-sm text-regular">Sua senha deve conter no mínimo:</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.checkMinCaractere ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    8 caracteres
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.checkCaixa ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Uma letra maíuscula e uma minúscula
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.checkNumero ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Um número
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="validacao-senha-item text-st text-regular">
                    { props.checkCaractere ? <CheckCircle style={{width: '20px', height: '20px'}} className="text-success icon-left mr-2" /> : <Warning style={{width: '20px', height: '20px'}} className="text-error mr-2" /> }
                    Um caractere especial
                    <BootstrapTooltip title="Caracteres especiais: ! @ # $ % & * ( ) - + | \ / ? { } [ ]" placement="right-start">
                        <Warning style={{width: '16px', height: '16px'}} className="color-mid-gray icon-right" />
                    </BootstrapTooltip>
                </Typography>
            </Grid>
        </Grid>
    );
}

PasswordInstrucions.defaultProps = {
    checkCaractere: false,
    senhasIguais: false,
    checkNumero: false,
    checkCaixa: false,
    checkMinCaractere: false
}

export default PasswordInstrucions;