import { useCallback, useEffect, useMemo, useState } from "react";
import { useContainerDimensions } from "../../../hooks/useContainerDimensions";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import Title from "./components/Title";
import ToolBar from "./components/ToolBar";
import Guests, { Guest } from "./components/Guests";
import { useStyles } from "./theme";
import { OpenInBrowserButtom } from "./components/Buttons";
import FloatingChat from "../../AtendimentoImediato/components/floatingChat";
import useVideoCall from "./useVideoCall";
import { Participant } from "./useVideoCall";
import { useNewHealthAttendance } from "../../../context/NewHealthAttendance";
import { joinRoomPatient } from "../../../api/videocall";
import { toast } from "react-toastify";

const DebugVideo = (props) => {
  return (
    <div>
      <Participant
        key={props.activeRoom.localParticipant.sid}
        participant={props.activeRoom.localParticipant}
      />
    </div>
  );
};

export default function VideoCall(props) {
  const classes = useStyles();
  const [chat, setChat] = useState(false);
  const [browser, setBrowser] = useState(false);
  const [timer, setTimer] = useState(0);
  const [featuredSID, setFeaturedSID] = useState(null);
  const { patient, healthAttendance } = useNewHealthAttendance();

  const { setFeaturedVideo } = props;

  const {
    activeRoom,
    participants,
    onMute,
    onUnmute,
    leaveCall,
    disableVideo,
    enableVideo,
    roomInfo,
    dominantSpeaker,
  } = useVideoCall();

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [setTimer]);

  const localParticipant = useMemo(() => {
    if (!activeRoom) return;

    const element = (
      <Participant
        key={activeRoom.localParticipant.sid}
        participant={activeRoom.localParticipant}
      />
    );

    setFeaturedVideo(activeRoom.localParticipant);

    return element;
  }, [activeRoom, setFeaturedVideo]);

  const remoteFeaturedParticipant = useMemo(() => {
    if (!activeRoom) return;

    if (
      participants.filter((p) => p.sid !== activeRoom.localParticipant.sid)
        .length === 0
    ) {
      setFeaturedSID(activeRoom.localParticipant.sid);
      
      return <Participant key={activeRoom.localParticipant.sid} participant={activeRoom.localParticipant} />;
    }

    const featured = participants.filter(
      (p) => p.sid !== activeRoom.localParticipant.sid
    )[0];

    setFeaturedSID(featured.sid);

    const element = <Participant key={featured.sid} participant={featured} />;

    return element;
  }, [activeRoom, participants]);

  const participantsJsx = useMemo(() => {
    if (!activeRoom) return;


    // Filter out the featured user from the Guests.
    var tracks = participants.filter(p => p.sid !== featuredSID).map((participant) => {

      return <Participant key={participant.sid} participant={participant} />;
    });


    return [localParticipant, ...tracks];
  }, [participants, localParticipant, activeRoom, featuredSID]);

  const callPatient = () => {
    if (patient && roomInfo && roomInfo.conferenceId) {
      joinRoomPatient(localStorage.getItem("token"), {
        roomName: healthAttendance && healthAttendance.id,
        patient_id: patient.id,
        health_attendance_id: healthAttendance && healthAttendance.id,
        conference_id: roomInfo.conferenceId,
      })
        .then((res) => {
          toast.success("Convite reenviado ao paciente.");
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      {browser ? (
          <Box
            width={369}
            height={187}
            overflow="hidden"
            top={window.innerHeight - (187 + 38)}
            right={38}
            left={false}
            position="fixed"
            zIndex="tooltip"
            borderRadius={8}
            bgcolor="rgba(39, 39, 39, 1)"
          >
          <Guest guest={remoteFeaturedParticipant} />

            <Box position="absolute" bottom={15} left={21}>
              <OpenInBrowserButtom onClick={(e) => setBrowser(false)} />
            </Box>

            <Box
              position="absolute"
              overflow="hidden"
              borderRadius={8}
              width={94}
              height={57}
              bottom={8}
              right={8}
            >
              {localParticipant}
            </Box>
          </Box>
      ) : (
        <Box
          width={!browser ? (!chat ? "100%" : "70%") : 369}
          height={!browser ? "100%" : 187}
          overflow="hidden"
          top={browser ? window.innerHeight - (187 + 38) : 0}
          right={browser ? 38 : false}
          left={browser ? false : 0}
          position="fixed"
          zIndex="tooltip"
          borderRadius={browser ? 8 : 0}
          bgcolor="rgba(39, 39, 39, 1)"
        >
          <Guest guest={remoteFeaturedParticipant} />

          <Title solicitante={props.solicitante} />
          <ToolBar
            duration={props.duration}
            onFinish={(e) => {
              leaveCall();
              props.onFinish(true);
            }}
            onAddUser={(e) => {
              // Chamar paciente

              callPatient();
            }}
            onBrowser={(e) => setBrowser(true)}
            onChat={(e) => {
              setChat(!chat);
              // props.onChat(true);
            }}
            onVideo={(e) => {
              enableVideo();
            }}
            onNotVideo={(e) => {
              disableVideo();
            }}
            onMute={(e) => onMute()}
            onUnmute={(e) => onUnmute()}
            timer={timer}
            setTimer={setTimer}
          />
          {participantsJsx && <Guests guests={participantsJsx} />}
        </Box>
      )}

      {/* Chat da videochamada */}
      {chat && !browser && (
        <Box
          width="30%"
          height="100%"
          top={0}
          right={0}
          overflow="auto"
          position="fixed"
          zIndex="tooltip"
          bgcolor="rgba(39, 39, 39, 1)"
        >
          {chat && healthAttendance ? (
            <FloatingChat
              openChat={chat}
              idUser={localStorage.getItem("uid")}
              videocall
              notFlutuant
              idAtendimento={healthAttendance.id}
            />
          ) : (
            <Grid
              classes={{ root: classes.boxTitleContent }}
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <CircularProgress
                  classes={{ colorPrimary: classes.textWhite }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  classes={{ root: classes.textWhite }}
                  align="center"
                >
                  Carregando chat
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  );
}

VideoCall.defaultProps = {
  duration: "0:00",
  solicitante: "João da Silva",
  guest: [],
  featuredGuest: null,
  chat: true,
  patientId: null,
  healthAttendanceId: null,
  onChat: function () {
    console.log("onChat");
  },
  onAddUser: function () {
    console.log("onAddUser");
  },
  onFinish: function () {
    console.log("onFinish");
  },
};
