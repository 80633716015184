import {Card} from "@material-ui/core";
import styled from 'styled-components';

function DCard(props)
{
    const StyledCard = styled(Card)`
        margin-bottom: 15px;
    `;

    return (
        <StyledCard {...props} >{props.children}</StyledCard>
    )
}

export default DCard;