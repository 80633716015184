import React, { useState, useEffect } from 'react';
import "../index.scss";
import { TextField } from "@material-ui/core";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DButton from '../../DButton';
import { Button, Grid, Divider } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import DownloadIcon from '@mui/icons-material/Download';
import { useNewHealthAttendance } from "./../../../../context/NewHealthAttendance";
import { listHealthAttendanceFiles } from "./../../../../api/healthAttendance";


export default function DadosEnviadosNewTab(props) {

  const {healthAttendance, setHealthAttendance, patient, setPatient} = useNewHealthAttendance();
  const [symptom, setSymptom] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if(healthAttendance){
        if(healthAttendance.anamnese_pre_form.length > 0) {
          var pre_symptom = healthAttendance.anamnese_pre_form.filter(pre_form => pre_form.anamnese_pre_symptom.length > 0).map(pre_form => pre_form.anamnese_pre_symptom.map(pre_symptom => {
              return {
                days: pre_symptom.days > 1 ? `${pre_symptom.days} dias` : `${pre_symptom.days} dia`,
                description: pre_symptom.symptom ? pre_symptom.symptom.description : "-"
              }
          }));

          setSymptom(pre_symptom[0] ? pre_symptom[0] : []);
        }

        listHealthAttendanceFiles(localStorage.getItem('token'), healthAttendance.id).then(res => {
          if(res.status)
            setFiles(res.healthAttendanceFile)
        });
    }
  }, [healthAttendance]);

  return (
    <div className='fundo_new_tab'>
      <div className='main_flex_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Dados enviados pelo paciente
        </div>
      </div>
      <div className='mt_35_new_tab'>
        <div className='main_div_tag_paciente_tab'>
          { symptom.map((s, index) => {
            return <>
              <Grid item md={4} className="alinha_centro">
                <div className='fonte_paciente_tab'>
                  Sintomas - { s.days }
                </div>
                <div className='main_div_tag_paciente_tab mt10_new_tabs justifica_centro'>
                  <div className='tag_paciente_tab'>
                    { s.description }
                  </div>
                </div>
              </Grid>
              { index < (symptom.length - 1) && <Divider orientation="vertical" flexItem className='divide_new_tab' /> }              
            </>
          }) }
        </div>
      </div>
      <div className='main_flex_new_tab mt_35_new_tab'>
        <div className='fonte_new_tab text-primary'>
          Arquivos anexados no atendimento
        </div>
      </div>
      <div className='mt_35_new_tab'>
        <div className='main_arquivos_anexados_new_tab'>
          { files.map((file, index) => <Button onClick={ e => window.open(file.link, '_blank') } className="botao_download_new_tab" startIcon={<DownloadIcon />}>              
                {
                  `Arquivo-${index + 1}`
                }
          </Button> ) }

        </div>
      </div>
    </div>
  )
}