import React from "react";

export const PatientContext = React.createContext({});

export const PatientProvider = (props) => {
  const [patient, setPatient] = React.useState(null);
  const [sideBarInternal, setSideBarInternal] = React.useState(true);

  const [dadosPrincipais, setDadosPrincipais] = React.useState({
    nacionalidade: null,
    cpf: null,
    NR: null,
    dataNascimento: null,
    inputDataNascimento: null,
    idade: null,
    nomeCompleto: null,
    sexo: null,
    orientacaoSexual: null,
    genero: null,
    nomeSocial: null,
    email: null,
    seIdentificaSexo: null,
    anoEstimadoDeNascimento: null,
    dadosIncompletos: false,
    justificativaDadoIncompleto: null,
    pronomes: null,
  });

  const [dadosIdentidadeGeral, setDadosIdentidadeGeral] = React.useState({
    nomeMae: null,
    maeDesconhecida: false,
    nomePai: null,
    paiDesconhecido: false,
    paisNascimento: null,
  });

  const [dadosIdentidadeBrasileiro, setDadosIdentidadeBrasileiro] =
    React.useState({
      identidadeNumero: null,
      identidadeDataEmissao: null,
      identidadeOrgaoEmissor: null,
      identidadeUF: null,
    });

  const [dadosIdentidadeNaturalizado, setDadosIdentidadeNaturalizado] =
    React.useState({
      identidadeNumero: null,
      identidadeDataEmissao: null,
      identidadeOrgaoEmissor: null,
      identidadeUF: null,
      paisNascimentoNaturalizado: null,
    });

  const [dadosIdentidadeEstrangeiro, setDadosIdentidadeEstrangeiro] =
    React.useState({
      numeroPassaporte: null,
      dataEmissaoPassaporte: null,
      dataValidadePassaporte: null,
      paisPassaporte: null,
      passaportePaisNascimento: null,
    });

  const [dadosCns, setDadosCns] = React.useState([]);

  const [dadosDemograficos, setDadosDemograficos] = React.useState({
    raca: null,
    etnia: null,
    estadoNascimento: null,
    municipioNascimento: null,
  });

  const [dadosGravidez, setDadosGravidez] = React.useState({
    qtdVezesGravidez: null,
    filhosVivos: null,
    dtUltimaMenstruacao: null,
    teveAborto: false,
    qtdVezesAborto: null,
    dtUltimoAborto: null,
    dtUltimaMenstruacaoValida: null,
  });

  const [dadosComplementares, setDadosComplementares] = React.useState({
    numeroPis: null,
    seriePis: null,
    ufPis: null,
    temDeficiencia: null,
    deficiencia: [],
    populacaoEspec: null,
    grauDeRelacionamento: null,
    responsavelLegalDocumento: null,
    responsavelLegalDocumentoEmissao: null,
    responsavelLegal: null,
  });

  const [dadosTelefones, setDadosTelefones] = React.useState([]);
  const [dadosUnidades, setDadosUnidades] = React.useState({
    unidadeRef: [],
    unidadeVinculada: [],
  });

  const [dadosEndereco, setDadosEndereco] = React.useState({
    cep: null,
    endereco: null,
    numero: null,
    complemento: null,
    bairro: null,
    logradouro: null,
    estado: null,
    municipio: null,
    idMunicipio: null,
  });

  return (
    <PatientContext.Provider
      value={{
        patient,
        setPatient,
        setDadosPrincipais,
        dadosPrincipais,
        dadosIdentidadeGeral,
        setDadosIdentidadeGeral,
        dadosIdentidadeBrasileiro,
        setDadosIdentidadeBrasileiro,
        dadosIdentidadeNaturalizado,
        setDadosIdentidadeNaturalizado,
        dadosIdentidadeEstrangeiro,
        setDadosIdentidadeEstrangeiro,
        dadosCns,
        setDadosCns,
        setDadosDemograficos,
        dadosDemograficos,
        setDadosGravidez,
        dadosGravidez,
        setDadosComplementares,
        dadosComplementares,
        dadosTelefones,
        setDadosTelefones,
        dadosUnidades,
        setDadosUnidades,
        dadosEndereco,
        setDadosEndereco,
        sideBarInternal, 
        setSideBarInternal
      }}
    >
      {props.children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => React.useContext(PatientContext);
