import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme
} from "@material-ui/core";
import { Close, Person, Save } from "@material-ui/icons";
import InfoIcon from "../../../../../../assets/img-icon/Info.svg";
import SuccessIcon from "../../../../../../assets/img-icon/SuccessIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import DButton from "../../../../../shared/DButton";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.snow,
    border: `1px solid ${theme.palette.neutral.midgrey}`,
    borderRadius: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headermodal: {
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 15px",
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
    marginBottom: 20,
  },
  footermodal: {
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "3px 15px",
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    marginTop: 20,
  },
  medicoescard: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.cloud,
    padding: "10px",
    borderRadius: "6px",
    boxShadow: "2px 4px 1px -2px rgba(0, 0, 0, 0.27)",
  },
  medicoescards: {
    width: 460,
  },
  content: {
    width: 498,
    height: 303,
    paddingTop: 16,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.neutral.midgrey}`,
  },
  dialog: {
    borderRadius: 8,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral.midgrey}`,
    paddingTop: 16,
    paddingBottom: 16,
  },
  headerTypography: {
    color: theme.palette.neutral.black,
    fontWeight: 700,
    lineHeight: 30,
    fontSize: 20,
  },
  centerTypography: {
    color: theme.palette.neutral.midgrey,
    fontWeight: 400,
    lineHeight: 24,
    fontSize: 16,
  },
  actionButtons: {
    width: 147,
    height: "33px !important",
    borderRadius: "100px !important",
    padding: "6px 18px !important",
    minWidth: "max-content !important",
    gap: 6,
  },
}));

export function GetOutModal(props) {
  const classes = useStyles();

  const handleClose = () => props.onClose();

  const body = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 28 }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="h2" align="center">
          <img
            src={InfoIcon}
            alt="Info icon"
            style={{ width: 24, height: 24 }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 8 }}>
        <Typography
          variant="h2"
          align="center"
          className={classes.headerTypography}
        >
          Deseja sair?
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 24 }}>
        <Typography
          variant="h5"
          align="center"
          className={classes.centerTypography}
        >
          Ao voltar para a lista de pacientes, você perderá as informações
          cadastradas.
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 48 }}>
        <Grid
          container
          style={{ display: "flex", marginLeft: 15 }}
          justifyContent="center"
          alignItems="center"
        >
          <DButton
            variant="secondary"
            size="sm"
            onClick={handleClose}
            className={classes.actionButtons}
            style={{ marginRight: 32 }}
          >
            Voltar
          </DButton>
          <NavLink
          style={{textDecoration: 'none'}}
          to="/pacientes">
            <DButton
              variant="primary"
              size="sm"
              className={classes.actionButtons}
            >
              Sair mesmo assim
            </DButton>
          </NavLink>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      aria-labelledby="form-construct-edit"
      scroll="body"
      // onClose={(e) => props.onClose(true)}
      onClose={(e) => null}
      open={props.open}
    >
      <DialogContent classes={{ root: classes.content }}>{body}</DialogContent>
    </Dialog>
  );
}

export function SuccessfulRegistratonPatient(props) {
  const classes = useStyles();
  const theme = useTheme()

  const onCreateAnotherPatient = () => props.onCreateAnotherPatient();

  const body = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 28 }}
    >
      <Grid item xs={12} md={12}>
        <Typography variant="h2" align="center">
          <img
            src={SuccessIcon}
            alt="Success icon"
            style={{ width: 24, height: 24 }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 8 }}>
        <Typography
          variant="h2"
          align="center"
          className={classes.headerTypography}
        >
          Paciente cadastrado com sucesso
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 24 }}>
        <Typography
          variant="h5"
          align="center"
          className={classes.centerTypography}
        >
          O Paciente{" "}
          <p style={{ color: theme.palette.primary.light, display: "inline" }}>
            {props.patientName || "NOME NÃO INFORMADO"}
          </p>{" "}
          foi cadastrado com sucesso!
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 48 }}>
        <Grid
          container
          style={{ display: "flex", marginLeft: 15 }}
          justifyContent="center"
          alignItems="center"
        >
          
          <NavLink
          style={{textDecoration: 'none'}}
          to="/pacientes">
            <DButton
              variant="secondary"
              size="sm"
              className={classes.actionButtons}
              style={{ marginRight: 32 }}
            >
              Voltar para lista
            </DButton>
          </NavLink>
          
            <DButton
              variant="primary"
              size="sm"
              className={classes.actionButtons}
              onClick={() => onCreateAnotherPatient()}
              >
              Cadastrar outro paciente
            </DButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      aria-labelledby="form-construct-edit"
      scroll="body"
      // onClose={(e) => props.onClose(true)}
      onClose={(e) => null}
      open={props.open}
    >
      <DialogContent classes={{ root: classes.content }}>{body}</DialogContent>
    </Dialog>
  );
}