import axios from 'axios';
import env from "react-dotenv";

export async function GetMyHealthList(token, healthAttendanceId){
    const res = await axios.get(`${env.API_URL}/above/${healthAttendanceId}/myHealthList?token=${token}`);
    return res.data;
}

export async function includeHealtDrugList(token, healthAttendanceId, drugs){
    // Example body
    // {
    //     itens: [
    //         {
    //             "name": "Tylenol",
    //             "active_principle": "Paracetamol"
    //         },
    //         {
    //             "name": "Novalgina",
    //             "active_principle": "Dipirona"
    //          }
    //     ]
    // }

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludeDrugList?token=${token}`, {itens: drugs});
    return res.data;
}

export async function includeHealthAllergyList(token, healthAttendanceId, allergies){
    // Example body
    // {
    //     itens: [
    //         {
    //             "name": "Poeira",
    //             "type": "1"
    //         },
    //         {
    //             "name": "Novalgina",
    //             "type": "2"
    //          }
    //     ]
    // }

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludeAllergyList?token=${token}`, {itens: allergies});
    return res.data;
}

/**
* @param {*} token
* @param {string | number} healthAttendanceId
* @param {object}
* @typedef Diseases
* @type {object} Diseases
* @property {string} name
* @property {string} obs
* @param {Array<Diseases>} diseases
*/
export async function includeHealthChronicDiseases(token, healthAttendanceId, diseases){

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludeChronicDiseases?token=${token}`, {itens: diseases});
    return res.data;
}

export async function includeHealthPressureMeasurement(token, healthAttendanceId, medicalPressure){
    // Example body
    // {
    //     "pa_systolic_mmhg":12.00,
    //     "pa_diastolica_mmhg":8.00
    // }

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludePressureMeasurement?token=${token}`, medicalPressure);
    return res.data;
}

export async function includeCapillaryBloodGlucose(token, healthAttendanceId, capillaryBloodGlucose){
    // Example body
    // {
    //     "mgdl": 102.00,
    //     "In_fasting": 1
    // }

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludeCapillaryBloodGlucose?token=${token}`, capillaryBloodGlucose);
    return res.data;
}

export async function includeBMIMeasurement(token, healthAttendanceId, BMIMeasurement){
    // Example body
    // {
    //     "weight_kg": 80.00,
    //     "height_cm": 180.00
    // }

    const res = await axios.post(`${env.API_URL}/above/${healthAttendanceId}/myHealthIncludeBMIMeasurement?token=${token}`, BMIMeasurement);
    return res.data;
}

export default {
    GetMyHealthList,
    includeHealtDrugList,
    includeHealthAllergyList,
    includeHealthChronicDiseases,
    includeHealthPressureMeasurement,
    includeCapillaryBloodGlucose,
    includeBMIMeasurement
}