import { makeStyles, TextField, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../../../../../../context/FormBuilderContext";
import DAutoComplete from "../../../../../../../shared/DAutoComplete";
import DInput from "../../../../../../../shared/DInput";

const useStyles = makeStyles({
    grid: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        
    },
    div: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        margin: 0,
        '&:first-child': {
          marginRight: "30px",
        }
    },
    textField: {
      '& .MuiFormHelperText-root.Mui-error': {
        color: '#D13D2E  !important',
      }
    },
  });

const EBSymptom = ({ id, label, title, placeholder, value, additional_data, helper_text, erro, condicional, readOnly, answer, is_required }) => {
  const classes = useStyles();
  const { handleInputSubmit } = useContext(FormBuilderContext);
  const [symptom, setSymptom] = useState();
  const [daysSymptom, setDaysSymptom] = useState();
  const [optionsSymptoms, setOptionsSymptoms] = useState(null);

 useEffect(() => {
    setOptionsSymptoms(additional_data.options.map(function (item) {
      return { id: item.value, value: item.label };
    }));

    const data = {
      value: 
        { 
          symptom: symptom,
          daysSymptom: daysSymptom
        }
          
    }
    if(data.value.daysSymptom !== undefined){
      handleInputSubmit({[id]: data.value})
    }

  },[symptom, daysSymptom])

  useEffect(() => {
    if(answer.length > 0){
      setDaysSymptom(answer[0].daysSymptom)
    }
  },[])

  const idSymptom = answer.length ? answer[0].symptom : ''

  return (
    <section  style={{ display: `${condicional[0] ? condicional[0].action === 'esconder' ? "none" : "block" : ''}` }}>
      <div className="mb-3">
        <Typography
          variant="body1"
          className="text-bold text-primary"
          style={{ fontSize: "1em", display: "flex"}}
        >
          {/* {title ? title : "Sintomas e Duração"} */}
          <div dangerouslySetInnerHTML={{ __html: `${label ? label : ""}` }} />
          <span style={{ fontSize: 16, color: '#f44336', paddingLeft: 5 }}>{is_required === 1 ? '*' : ''}</span>
        </Typography>
        <Box className={classes.grid}>
            <Box className={classes.div}>
                {additional_data.options.length > 0 && optionsSymptoms != null
                ? 
                <DAutoComplete
                    id="select-form"
                    label="Sintomas"
                    placeholder="Sintomas"
                    labelColor="black"
                    error={ erro[0] ? erro[0].value : '' }
                    helperText={ erro[0] ? erro[0].value : '' }
                    fullWidth={true}
                    defaultValue={ optionsSymptoms.filter( res => res.id == idSymptom )[0] }
                    options={additional_data.options.map(function (item) {
                    return { id: item.value, value: item.label };
                    })}
                    getOptionLabel={(option) => option.value}
                    getOptionSelected={(option, value) =>
                    value && option.id === value.id
                    }
                    // disabled={readOnly}
                    onChange={(event, newValue) => {
                      if(erro !== null && erro.length > 0){
                        delete erro[0].value
                      }
                      setSymptom(newValue ? newValue.id : '')
                  }}
                />
                
                : "Não existe dados cadastrados"}
            </Box>
            <Box className={classes.div}>
                <TextField
                    id={id}
                    // style={{ margin: 8 }}
                    label={additional_data.construct.showTitle ? additional_data.construct.showTitle : ""}
                    placeholder={placeholder ? placeholder : ""}
                    error={ erro[0] ? erro[0].value : '' }
                    helperText={ erro[0] ? erro[0].value : '' }
                    className={classes.textField}
                    value={daysSymptom}
                    fullWidth
                    type="text"
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                      readOnly: readOnly,
                      maxLength: 15,
                    }}
                    onChange={ e => {
                      // delete error.daysSymptom
                      setDaysSymptom(e.target.value)
                  } }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      /[^a-zA-Z 0-9]+/g,
                      ""
                    );
                  }}
                />
            </Box>
         </Box>
        <span style={{ fontSize: 12 }} className="text-light">{helper_text ? helper_text : ""}</span>
      </div>
    </section>
  );
};

export default EBSymptom;
